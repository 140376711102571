import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../../../../../global/Loading/Loading'
import { AppMarketLink } from '../../../../../global/appMarketLink/mobile/AppMarketLink'
import { Button } from '../../../../../global/button/Button'
import { useEntitySettings } from '../../../../../global/context/EntityContext'
import { useSignup } from '../../../../../global/context/SignupContext'
import { ToastContext, successToast } from '../../../../../global/toast/Toast'
import { AccountType } from '../../../../../model/AccountType'
import { PhoneVerificationWorkflowEnum } from '../../../../../model/CreatePhoneNumberVerification'
import { LeadDto } from '../../../../../model/LeadDto'
import { PhoneNumberTypeEnum } from '../../../../../model/PhoneNumberDto'
import { Text } from '../../../../../ui/Typography/Typography'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../../../../utils/companyName.utils'
import { useRedirectToSupport } from '../../../../../utils/useRedirectToSupport'
import { LeftSection } from '../../../Components/LeftSection'
import { SignupFormWrapper } from '../../../Components/SignupFormWrapper'
import { SignupSections, SignupStep } from '../../../Components/SignupStep'
import { SignupWrapper } from '../../../Components/SignupWrapper'
import { PersonalDetailsStepMobileValidationForm } from './PersonalDetailsStepMobileValidation'

import personalInfoStyles from '../PersonalDetailsPage.module.scss'
import styles from './VerifyPhoneNumberPage.module.scss'

const PHONE_VERIFICATION_LIMIT = 3

export const VerifyPhoneNumberPage: React.FC = () => {
  const navigate = useNavigate()

  const [urlParams] = useSearchParams()
  const { leadId } = useParams<{ leadId?: string }>()

  const { t } = useTranslation()
  const setToast = useContext(ToastContext)
  const apiClient = useApiClient(ClientApiClient)
  const [, setEntity] = useEntitySettings()
  const { signupData } = useSignup()

  const [lead, setLead] = useState<LeadDto>()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const verificationAttempted = useRef(false)

  const [phoneVerificationLimit, setPhoneVerificationLimit] = useState(0)
  const { supportUrl } = useRedirectToSupport()

  const getParam = useCallback(
    (param: 'hash' | 'token' | 'phoneVerificationId') => urlParams.get(param) || '',
    [urlParams]
  )

  const [phoneVerifyId, setPhoneVerifyId] = useState(getParam('phoneVerificationId'))

  const determineWorkflowId = (phoneNumberTypeId: number) => {
    switch (phoneNumberTypeId) {
      case PhoneNumberTypeEnum.LANDLINE:
        return PhoneVerificationWorkflowEnum.WorkFlowTTS
      case PhoneNumberTypeEnum.MOBILE:
        return PhoneVerificationWorkflowEnum.WorkflowSMS
      default:
        return PhoneVerificationWorkflowEnum.WorkFlowTTS
    }
  }

  const refreshLead = async () => {
    setLoading(true)
    const token = getParam('token')
    if (!token) {
      setError(t('Sign up.Something went wrong, please contact support.'))
      setLoading(false)
      return undefined
    }

    try {
      if (verificationAttempted.current) {
        return
      }
      verificationAttempted.current = true
      const leadDto = await apiClient.getLeadByIdAndToken(leadId || '', token)
      setLead(leadDto)
      const workflowId = determineWorkflowId(leadDto.phoneNumberTypeId)

      const createdPhoneVerification = await apiClient.createPhoneNumberVerification(leadDto.id, {
        phoneNumber: leadDto.phoneNumber,
        leadId: leadDto.id,
        countryCode: leadDto.countryCode,
        countryId: leadDto.country.id,
        workflowId,
      })
      setPhoneVerifyId(createdPhoneVerification.phoneVerificationId)
    } catch (error: unknown) {
      setError(t('Sign up.Something went wrong, please contact support.'))
    } finally {
      setLoading(false)
    }
  }

  const createPhoneNumberValidationOrPushToTheNextStep = async () => {
    const token = getParam('token')
    const phoneVerificationId = getParam('phoneVerificationId')

    if (!token) {
      setError(t('Sign up.Something went wrong, please contact support.'))
      setLoading(false)
      return undefined
    }

    try {
      const leadDto = await apiClient.getLeadByIdAndToken(leadId || '', token)
      setLead(leadDto)

      if (phoneVerificationId) {
        setPhoneVerifyId(phoneVerificationId)
        setLoading(false)
        return undefined
      }

      const workflowId = determineWorkflowId(leadDto.phoneNumberTypeId)
      const createdPhoneVerification = await apiClient.createPhoneNumberVerification(leadDto.id, {
        phoneNumber: leadDto.phoneNumber,
        leadId: leadDto.id,
        countryCode: leadDto.countryCode,
        countryId: leadDto.country.id,
        workflowId,
      })
      setPhoneVerifyId(createdPhoneVerification.phoneVerificationId)

      setEntity({ entity: leadDto.tickmillCompany.id })
      setPhoneVerificationLimit(0)
    } catch (error: unknown) {
      if (phoneVerificationId && lead) {
        await apiClient.cancelLeadPhoneNumberVerification(lead.id, phoneVerificationId)
      }
      console.error(error, 'error')
      setError(t('Sign up.Something went wrong, please contact support.'))
      handleNextStep()
    } finally {
      setLoading(false)
    }
  }

  const handleNextStep = async () => {
    const token = getParam('token')
    return navigate(`/users/register2/${leadId}?token=${token}`)
  }

  const resendCode = async () => {
    if (phoneVerificationLimit >= PHONE_VERIFICATION_LIMIT) {
      handleNextStep()
    } else if (lead) {
      setPhoneVerificationLimit((value) => ++value)
      const phoneVerificationId = await apiClient.sendNewVerificationCodeToPhoneNumber(
        lead.id,
        getPhoneVerificationId(),
        {
          workflow: PhoneVerificationWorkflowEnum.WorkflowSMS,
        }
      )

      setPhoneVerifyId(phoneVerificationId.phoneVerificationId)
      setToast(
        successToast(
          t('Sign up.Code was sent', {
            phoneNumber: lead.phoneNumber,
          })
        )
      )
    }
  }

  const onReceivePhoneCall = async () => {
    if (phoneVerificationLimit >= PHONE_VERIFICATION_LIMIT) {
      handleNextStep()
    } else if (lead) {
      setPhoneVerificationLimit((value) => ++value)
      const phoneVerificationId = await apiClient.sendNewVerificationCodeToPhoneNumber(
        lead.id,
        getPhoneVerificationId(),
        {
          workflow: PhoneVerificationWorkflowEnum.WorkFlowTTS,
        }
      )
      setPhoneVerifyId(phoneVerificationId.phoneVerificationId)
      setToast(
        successToast(
          t('Sign up.Expect phone call', {
            phoneNumber: lead.phoneNumber,
          })
        )
      )
    }
  }

  const getPhoneVerificationId = useCallback(() => {
    return phoneVerifyId
  }, [phoneVerifyId])

  useEffect(() => {
    createPhoneNumberValidationOrPushToTheNextStep()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!lead && !loading) {
    return (
      <div className={styles.box}>
        <div className='pt-6 pb-6'>
          <div className='pb-5'>
            <h1 className='text-align-center'>{error}</h1>
          </div>
          <div className='pb-4'>
            <div className='text-align-center'>
              <Text isParagraph>
                <Link to={supportUrl} target='_blank' rel='noreferrer'>
                  <Button appearance='primary' size='L' type='button'>
                    {t('Contact support')}
                  </Button>
                </Link>
              </Text>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <SignupFormWrapper label={t('Sign up.Sign up')}>
        <SignupStep
          subStep={{ current: 4, total: signupData.totalSteps.personalDetails }}
          section={SignupSections.PersonalDetails}
          accountTypeId={lead?.type.id || AccountType.Individual}
        />
        <SignupWrapper reversed>
          <LeftSection
            className='is-hidden-mobile'
            title={t('Sign up.Personal Details')}
            description={() => (
              <React.Fragment>
                {lead?.type.id === AccountType.Individual &&
                  (lead?.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_UK ? (
                    <Text isParagraph>
                      {t(
                        'Sign up.Next up, we would like to know a little bit more about you. Please complete the'
                      )}
                    </Text>
                  ) : (
                    <Text isParagraph>
                      {t(
                        'Sign up.Next up, we would like to know a little bit more about you. It will take you about 10'
                      )}
                    </Text>
                  ))}
                {lead?.type.id === AccountType.Corporate &&
                  (lead?.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_UK ? (
                    <Text isParagraph>
                      {t(
                        'Sign up.Next up, we would like to know a little bit more about you. Please complete the'
                      )}
                    </Text>
                  ) : (
                    <Text isParagraph>
                      {t(
                        "Sign up.Next up, we like to know a little bit more about you. It'll take you about 20"
                      )}
                    </Text>
                  ))}
              </React.Fragment>
            )}
          />
          {lead && (
            <section
              className={classNames(
                personalInfoStyles.wrapper,
                personalInfoStyles.formSection,
                personalInfoStyles.reversed
              )}
            >
              <Loading showLoadingIcon isLoading={loading}>
                <PersonalDetailsStepMobileValidationForm
                  lead={lead}
                  refreshLead={refreshLead}
                  onReceivePhoneCall={onReceivePhoneCall}
                  resendCode={resendCode}
                  phoneVerificationId={getPhoneVerificationId()}
                  handleNextStep={handleNextStep}
                />
              </Loading>
            </section>
          )}
        </SignupWrapper>
        <AppMarketLink />
      </SignupFormWrapper>
    </React.Fragment>
  )
}
