import React from 'react'
import classNames from 'classnames'

import { FormatPDFIcon } from '../../icons/FormatPDFIcon'
import { LogoAndroidIcon } from '../../icons/LogoAndroidIcon'
import { LogoAppleIcon } from '../../icons/LogoAppleIcon'
import { LogoWindowsIcon } from '../../icons/LogoWindowsIcon'
import { UserBookIcon } from '../../icons/UserBookIcon'
import { WebIcon } from '../../icons/WebIcon'
import { OperatingSystemEnum } from '../../model/PlatformTypeEnum'
import { TextSmallStrong } from '../../ui/Typography/Typography'

import styles from './PlatformIcon.module.scss'

interface PlatformIconFactoryProps {
  os: number
  disabled?: boolean
}

export const PlatformIconFactory: React.FC<PlatformIconFactoryProps> = ({
  os,
  disabled = false,
}) => {
  switch (os) {
    case OperatingSystemEnum.WINDOWS:
      return (
        <MTDownloadIcon title='Windows' icon={<LogoWindowsIcon size={36} />} disabled={disabled} />
      )
    case OperatingSystemEnum.OSX:
      return <MTDownloadIcon title='macOS' icon={<LogoAppleIcon size={36} />} disabled={disabled} />
    case OperatingSystemEnum.WEB_TRADER:
      return <MTDownloadIcon title='Web Trader' icon={<WebIcon size={36} />} disabled={disabled} />
    case OperatingSystemEnum.ANDROID_APK:
      return (
        <MTDownloadIcon
          title='Android.APK'
          icon={<LogoAndroidIcon size={36} />}
          disabled={disabled}
        />
      )
    case OperatingSystemEnum.ANDROID:
      return (
        <MTDownloadIcon title='Android' icon={<LogoAndroidIcon size={36} />} disabled={disabled} />
      )

    default:
      return <MTDownloadIcon title='iOS' icon={<LogoAppleIcon size={36} />} disabled={disabled} />
  }
}

interface ManualIconFactoryProps {
  icon: string
}

export const ManualIconFactory = (props: ManualIconFactoryProps): JSX.Element => {
  const { icon } = props

  if (icon.includes('fa-globe')) {
    return <FormatPDFIcon size={40} />
  }

  if (icon.includes('fa-book-user')) {
    return <UserBookIcon size={40} />
  }

  return <FormatPDFIcon size={40} />
}

interface PlatformDownloadButtonProps {
  disabled?: boolean
  to?: string
  onClick?: () => void
  children?: React.ReactNode
  className?: string
}

export const PlatformDownloadButton: React.FC<PlatformDownloadButtonProps> = (props) => {
  const { to, disabled, children, onClick, className } = props
  const handleClick = () => {
    if (onClick) {
      return onClick()
    }
    if (to) {
      window.open(to, '_blank', 'noreferrer')
    }
  }

  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={classNames(styles.platformButton, className, {
        [styles.disabled]: disabled,
      })}
    >
      {React.isValidElement(children)
        ? React.cloneElement(children as React.ReactElement<any>, {
            disabled,
          })
        : children}
    </button>
  )
}

interface MTDownloadIconProps {
  title: string
  icon: React.ReactNode
  disabled?: boolean
}

export const MTDownloadIcon: React.FC<MTDownloadIconProps> = (props) => {
  const { icon, title, disabled } = props

  return (
    <div
      className={classNames(styles.platformIcon, {
        [styles.disabled]: disabled,
      })}
    >
      <div>
        <div className={styles.icon}>{icon}</div>
        <TextSmallStrong className={styles.title}>{title}</TextSmallStrong>
      </div>
    </div>
  )
}
