import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../global/button/Button'
import { CloseIcon } from '../../icons/CloseIcon'
import { Text, TextH3 } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import IconButton from '../iconButton/IconButton'
import { Modal } from './Modal'

import styles from './ProductServiceModal.module.scss'

type ProductServiceModalProps = {
  data?: {
    name?: string
    imageUrl?: string
    imageUrlMobile?: string
    description?: ReactNode
    url?: string
  }
  closeModal: () => void
}

export const ProductServiceModal: React.FC<ProductServiceModalProps> = (props) => {
  const { data, closeModal } = props
  const { t } = useTranslation()

  const isMobile = useWindowResize()

  return data?.name ? (
    <Modal
      size='large'
      closeModal={closeModal}
      render={() => (
        <div className={styles.modal}>
          <div className={styles.serviceModal}>
            <img
              src={isMobile ? data?.imageUrlMobile || data.imageUrl : data.imageUrl}
              className={styles.image}
              alt={data.name}
            />
            <div className={styles.text}>
              <TextH3>{data.name}</TextH3>
              <Text>{data.description}</Text>
              <Button
                appearance='primary'
                size='L'
                onClick={() => window.open(data.url)}
                type='button'
              >
                {t('Platform Vendors.action')}
              </Button>
            </div>
            <IconButton onClick={closeModal} className={styles.close}>
              <CloseIcon size={12} />
            </IconButton>
          </div>
        </div>
      )}
    />
  ) : null
}
