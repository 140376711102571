import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Tabs, defineTab } from '../global/tabs/Tabs'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { isTickmillPartner } from '../utils/companyName.utils'
import { isNoTabsPath } from '../utils/path'
import { GettingStartedPage } from './GettingStarted/GettingStartedPage'
import { TrainingPage } from './Trainings/TrainingPage'

import styles from './Learning.module.scss'

export type Tab = 'getting-started' | 'trainings'

export const Learning: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { tab } = useParams<{ tab?: Tab }>()
  const { account } = useAccountReadContext()

  function* getTabs() {
    if (!isTickmillPartner(account)) {
      yield {
        ...defineTab('getting-started', t('Learning.Getting Started')),
      }
    }

    yield {
      ...defineTab('trainings', t('Learning.Video Tutorials')),
    }
  }

  const TabsDefinition = [...getTabs()]

  return (
    <div>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={tab || ''}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/learning/${tab}`)}
          />
        </div>
      )}
      {tab === 'trainings' && <TrainingPage />}
      {!isTickmillPartner(account) && tab === 'getting-started' && <GettingStartedPage />}
    </div>
  )
}
