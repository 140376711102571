import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useProductReadContext } from '../../../global/context/ProductContext'
import { useScrollToElementIds } from '../../../hooks/useScrollToElementIds'
import { ChevronDownIcon } from '../../../icons/ChevronDownIcon'
import { Text, TextH3 } from '../../../ui/Typography/Typography'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { TickmillCompaniesEnum } from '../../../utils/companyName.utils'

import styles from './ProfessionalClassificationTestForm.module.scss'

export const ClassificationText = (): JSX.Element => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const { isDefaultCFDProductType } = useProductReadContext()

  const isProductTypeCFD = isDefaultCFDProductType()

  if (isProductTypeCFD) {
    return (
      <>
        <Accordion
          title={t('Profile.Benefits of becoming a professional client')}
          id={'professional-client-benefits-accordion'}
        >
          {[
            {
              name: t('Profile.Segregated Account'),
              text: t('Profile.We will continue to segregate your funds'),
            },
            {
              name: t('Profile.Flexible Leverage'),
              text: t(
                'Profile.You will continue to benefit from the flexible leverage on our FX and CFD products'
              ),
            },
          ].map(({ name, text }, i) => {
            return (
              <div key={i}>
                <Text isParagraph>{`${i + 1}. ${name}`}</Text>
                <p className={styles.paddedText}>{`${text}`}</p>
              </div>
            )
          })}
          {account?.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_EU && (
            <div>
              <Text isParagraph>
                {'3. '}
                {t('Profile.Right to request a different classification')}
              </Text>
              <p className={styles.paddedText}>
                {t(
                  'Profile.A professional client can request to be categorised as a Retail client and obtain higher level of protection.'
                )}
              </p>
            </div>
          )}
          {account?.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_UK && (
            <div>
              <Text isParagraph>
                {'3. '}
                {t('Profile.FSCS Protection')}
              </Text>
              <p className={styles.paddedText}>
                {t(
                  'Profile.You will continue to be covered by the Financial Services Compensation Scheme up to the value of £85,000 per person.'
                )}
              </p>
            </div>
          )}
          <div>
            <Text isParagraph>
              {'4. '}
              {t('Profile.Use of FOS')}
            </Text>
            <p className={styles.paddedText}>
              {t('Profile.Access to the Financial Ombudsman Service (the “FOS”) is')}
            </p>
          </div>
        </Accordion>
        <Accordion
          title={t('Profile.Protections that you will lose by becoming a Professional Client')}
          id={'protection-losses-accordion'}
        >
          {[
            {
              name: t('Profile.Risk Warnings'),
              text: t(
                'Profile.We will not be obliged to restrict your account with standardised risk warnings and rules that protect Retail Clients.'
              ),
            },
            {
              name: t('Profile.Communications'),
              text: t(
                'Profile.When we communicate with you we may assume that your level of experience'
              ),
            },
            {
              name: t('Profile.Experience'),
              text: t(
                'Profile.We may assume your level of experience when determining if our products and services are suitable for you.'
              ),
            },
            {
              name: t('Profile.Best Execution'),
              text: t('Profile.We owe you a duty when executing your orders. As a Professional'),
            },
            {
              name: t('Profile.FOS'),
              text: t('Profile.If you are a Professional Client who is not defined as'),
            },
            {
              name: t('Profile.No Leverage Restrictions'),
              text: t(
                'Profile.It means that you might be exposed to higher leverage that can amplify losses.'
              ),
            },
          ].map(({ name, text }, i) => {
            return (
              <div key={i}>
                <Text isParagraph>{`${i + 1}. ${name}`}</Text>
                <p className={styles.paddedText}>{`${text}`}</p>
              </div>
            )
          })}
          {account?.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_EU && (
            <div>
              <Text isParagraph>
                {'7. '}
                {t('Profile.Investors Compensation Fund (ICF)')}
              </Text>
              <p className={styles.paddedText}>
                {t(
                  'Profile.Professional clients under Tickmill Europe (regulated by CySEC) are not entitled to a compensation by the ICF.'
                )}
              </p>
            </div>
          )}
        </Accordion>
      </>
    )
  }

  return (
    <>
      <Accordion
        title={t('Profile.Benefits of becoming a professional client')}
        id={'professional-client-benefits-accordion'}
      >
        {[
          {
            name: t('Profile.Segregated Account'),
            text: t('Profile.We will continue to segregate your funds'),
          },
          {
            name: t('Profile.Leverage & Margin'),
            text: t(
              'Profile.You will continue to benefit from the leverage / margin on our CFD and ETD products'
            ),
          },
          {
            name: t('Profile.Negative Balance Protection'),
            text: t(
              'Profile.Your account will continue to beneift from negative balance protection'
            ),
          },
        ].map(({ name, text }, i) => {
          return (
            <div key={i}>
              <Text isParagraph>{`${i + 1}. ${name}`}</Text>
              <p className={styles.paddedText}>{`${text}`}</p>
            </div>
          )
        })}
      </Accordion>
      <Accordion
        title={t('Profile.Protections that you will lose by becoming a Professional Client')}
        id={'protection-losses-accordion'}
      >
        {[
          {
            name: t('Profile.Risk Warnings'),
            text: t(
              'Profile.We will not be obliged to restrict your account with standardised risk warnings and rules that protect Retail Clients.'
            ),
          },
          {
            name: t('Profile.Communications'),
            text: t(
              'Profile.When we communicate with you we may assume that your level of experience'
            ),
          },
          {
            name: t('Profile.Experience'),
            text: t(
              'Profile.We may assume your level of experience when determining if our products and services are suitable for you.'
            ),
          },
          {
            name: t('Profile.Best Execution'),
            text: t('Profile.We owe you a duty when executing your orders. As a Professional'),
          },

          {
            name: t('Profile.No Leverage Restrictions'),
            text: t(
              'Profile.It means that you might be exposed to higher leverage that can amplify losses.'
            ),
          },
        ].map(({ name, text }, i) => {
          return (
            <div key={i}>
              <Text isParagraph>{`${i + 1}. ${name}`}</Text>
              <p className={styles.paddedText}>{`${text}`}</p>
            </div>
          )
        })}
      </Accordion>
    </>
  )
}

interface AccordionProps {
  title: string

  hasIconRight?: true
  id?: string
}

const Accordion: React.FunctionComponent<PropsWithChildren<AccordionProps>> = ({
  title,
  hasIconRight,
  children,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { scrollIntoView } = useScrollToElementIds()

  return (
    <div className={classNames(styles.accordion)} id={id}>
      <div
        className={classNames(styles.title, {
          [styles.justify]: hasIconRight,
        })}
        onClick={() => {
          if (!isOpen && id) {
            scrollIntoView([id])
          }
          setIsOpen(!isOpen)
        }}
      >
        <TextH3>{title}</TextH3>
        <div className={styles.chevron}>
          <div className={isOpen ? styles.chevronUp : styles.chevronDown}>
            <ChevronDownIcon />
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.content, {
          [styles.open]: isOpen,
        })}
      >
        {children}
      </div>
    </div>
  )
}
