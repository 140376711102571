import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getDocUploadFailMessage } from '../../../../../Profile/DocumentManagementUpload/documentManagementUploadStatuses'
import { InformationModal } from '../../../../../global/modal/InformationModal'
import { Modal } from '../../../../../global/modal/Modal'
import { ClientNciPriorityDto } from '../../../../../model/ClientNciPriorityDto'
import { CreateClientDocument } from '../../../../../model/CreateClientDocuments'
import { ClientCreateNciFinalHistory } from '../../../../../model/CreateNciHistory'
import { UploadDocumentCategoryEnum } from '../../../../../model/UploadDocumentCategoryEnum'
import { Text } from '../../../../../ui/Typography/Typography'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { DocumentInfoNCIPageStep1Form } from './DocumentInfoNCIPageStep1Form'

interface OuterProps {
  accountNciAvailablePriorities: ClientNciPriorityDto

  onSkip(): void

  onSkipDocuments(): void

  handleSubmitReason(reason: string): void

  handleChoosePreviousDoc(doc: ClientNciPriorityDto, count: number): void

  handleSubmit(): void
}

export const DocumentInfoNCIPageStep1Page: React.FC<OuterProps> = (props) => {
  const apiClient = useApiClient(ClientApiClient)

  const [uploadErrorModal, setUploadErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = async (
    values: ClientCreateNciFinalHistory,
    documents: CreateClientDocument[]
  ) => {
    await apiClient.accountAcceptFinalNciHistory(values)
    try {
      await apiClient.uploadDocuments({ documents })
      props.handleSubmit()
    } catch (error: unknown) {
      setUploadErrorModal(true)
      setErrorMessage((error as any).response?.response?.data?.code)
    }
  }
  const handleCloseErrorModal = () => setUploadErrorModal(false)

  const { t } = useTranslation()

  return (
    <>
      {uploadErrorModal && (
        <Modal
          closeModal={handleCloseErrorModal}
          render={() => (
            <InformationModal
              onCancel={handleCloseErrorModal}
              onCancelText={t('Got It')}
              title={t('Upload failed')}
            >
              <Text isParagraph>{t(getDocUploadFailMessage(errorMessage))}</Text>
            </InformationModal>
          )}
        />
      )}
      <DocumentInfoNCIPageStep1Form
        {...props}
        handleSubmit={handleSubmit}
        documentCategory={UploadDocumentCategoryEnum.Personal}
      />
    </>
  )
}
