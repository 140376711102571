import React, { useEffect } from 'react'

import { NotificationType } from '../../model/Notification'
import {
  useHideNotifications,
  useNotificationAlerts,
  useNotificationFunctionality,
  useNotificationUnread,
} from '../../utils/notifications'
import { useProductReadContext } from '../context/ProductContext'
import { useSessionLanguage } from '../context/SessionSettingsContext'
import { NotificationSlider } from './NotificationSlider'

export const NotificationBlock: React.FC = () => {
  const { generalNotifications, promotionalNotifications } = useNotificationAlerts()
  const { hideNotifications: hide } = useHideNotifications()
  const { showNotifications, enableNotifications } = useNotificationFunctionality()
  const { refreshAllNotifications } = useNotificationUnread()
  const { product: tickmillProduct } = useProductReadContext()
  const locale = useSessionLanguage()

  useEffect(() => {
    if (enableNotifications) {
      refreshAllNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickmillProduct, locale, enableNotifications])

  if (!showNotifications) {
    return null
  }

  return (
    <>
      <NotificationSlider
        hideNotifications={() => hide([NotificationType.Promotional])}
        notifications={promotionalNotifications}
      />
      <NotificationSlider
        hideNotifications={() => hide([NotificationType.General])}
        notifications={generalNotifications}
      />
    </>
  )
}
