import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { CheckOutlinedIcon } from '../../../icons/CheckOutlinedIcon'
import { MasterTransactionDto } from '../../../model/MasterTransactionDto'
import { TransferTypeEnum } from '../../../model/TransactionDto'
import { WalletTypeEnum } from '../../../model/WalletDto'
import { TextH3, TextStrong, TextTiny } from '../../../ui/Typography/Typography'

import styles from '../WalletSuccessModal.module.scss'

interface Props {
  masterTransaction: MasterTransactionDto | undefined
  transferType: number
  isConversion?: boolean

  onCancel(): void
}

interface BodyProps {
  masterTransaction: MasterTransactionDto | undefined
  transferType: number
  isConversion?: boolean
}

const TransferSuccessModalBody: React.FC<BodyProps> = (props) => {
  const { masterTransaction, transferType, isConversion } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const location = useLocation()

  const isIbWallet = location.pathname.includes('introducing-broker')

  if (!masterTransaction) {
    return null
  }

  const { walletTransactions, tradingAccountTransactions } = masterTransaction
  const [fromWallet, toWallet] = walletTransactions
  const [fromTa, toTa] = tradingAccountTransactions
  const from = fromWallet || fromTa
  const to = toWallet || toTa

  const fromCurrency = fromWallet?.wallet?.currency?.id || fromTa?.currency?.id
  const toCurrency = from?.otherCurrency?.id

  const fromAmount = from?.amount
  const otherAmount = from?.otherAmount
  const otherCurrency = from?.otherCurrency?.id

  const isToIbWallet = to?.wallet?.walletType?.id === WalletTypeEnum.IB

  return (
    <section className={classNames(styles.body, 'pt-3')}>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <div className={styles.icon}>
            <CheckOutlinedIcon />
          </div>
          {isConversion && <TextH3>{t('Conversion Successful')}</TextH3>}
          {!isConversion && <TextH3>{t('Transfer Successful')}</TextH3>}
        </div>
        <div className={styles.column}>
          <TextTiny isParagraph>{t('Wallet.From')}</TextTiny>
          <TextStrong isParagraph>
            {isIbWallet && t('IB.IB')} {fromCurrency} {t(getTransferNameByType(transferType).from)}
          </TextStrong>
        </div>
        <div className={styles.column}>
          <TextTiny isParagraph>{t('Wallet.Amount Sent')}</TextTiny>
          <TextStrong isParagraph>{formatMoney(fromAmount, fromCurrency)}</TextStrong>
        </div>
        <div className={styles.column}>
          <TextTiny isParagraph>{t('To')}</TextTiny>
          <TextStrong isParagraph>
            {isToIbWallet && t('IB.IB')} {otherCurrency} {t(getTransferNameByType(transferType).to)}
          </TextStrong>
        </div>
        <div className={styles.column}>
          <TextTiny isParagraph>{t('Amount Received')}</TextTiny>
          <TextStrong isParagraph>{formatMoney(otherAmount, toCurrency)}</TextStrong>
        </div>

        <div className={classNames(styles.column, 'mt-3')}>
          {from?.currencyRate && from.currencyRate !== 1 && (
            <>
              <TextTiny isParagraph>{t('Wallet.Conversion Rate')}</TextTiny>
              <TextStrong isParagraph>{from.currencyRate}</TextStrong>
            </>
          )}
        </div>
      </div>
    </section>
  )
}

const getTransferNameByType = (transferType: number): { from: string; to: string } => {
  if (transferType === TransferTypeEnum.TA_TO_WALLET) {
    return { from: 'Trading Account.Trading Account', to: 'Wallet.Wallet' }
  }
  if (transferType === TransferTypeEnum.WALLET_TO_TA) {
    return { from: 'Wallet.Wallet', to: 'Trading Account.Trading Account' }
  }
  if (transferType === TransferTypeEnum.WALLET_TO_WALLET) {
    return { from: 'Wallet.Wallet', to: 'Wallet.Wallet' }
  }
  if (transferType === TransferTypeEnum.TA_TO_TA) {
    return {
      from: 'Trading Account.Trading Account',
      to: 'Trading Account.Trading Account',
    }
  }
  return { from: '', to: '' }
}

export const TransferSuccessModal: React.FC<Props> = ({
  onCancel,
  masterTransaction,
  isConversion,
  transferType,
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <TransferSuccessModalBody
        masterTransaction={masterTransaction}
        isConversion={isConversion}
        transferType={transferType}
      />
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <button className='button' onClick={onCancel}>
          {t('OK')}
        </button>
      </footer>
    </React.Fragment>
  )
}
