import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '../../global/button/Button'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { LinkIcon } from '../../icons/LinkIcon'
import { LogoIcon } from '../../icons/LogoIcon'
import { QRCodeIcon } from '../../icons/QRCodeIcon'
import { StarIcon } from '../../icons/StarIcon'
import { Grid } from '../../ui/Layouts/Grid/Grid'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { Text, TextH3 } from '../../ui/Typography/Typography'
import { useScrollToTop } from '../../utils/useScrollToTop'

import styles from './ReferralMaterialsPage.module.scss'

export const ReferralMaterialsPage: React.FC = () => {
  useScrollToTop()
  const { t } = useTranslation()

  return (
    <div>
      <PageHeader
        title={t('Referral Materials.Referral Materials')}
        id={ScrollToIds.IBReferralMaterialsHeader}
      />
      <Grid>
        <MaterialCard
          icon={<LinkIcon size={80} />}
          title={t('Referral Materials.Get Link')}
          text={t(
            'Referral Materials.Get your personal IB links to all of our important pages by clicking below. We’ve even included embedding links to seamlessly integrate into your pages.'
          )}
          link='/dashboard/introducing-broker/referral-materials/links'
        />
        <MaterialCard
          icon={<StarIcon size={80} />}
          title={t('Referral Materials.Banners')}
          text={t(
            'Referral Materials.You can find all the Tickmill banners you need here; whether they’re from special campaigns or just promoting our services.  '
          )}
          link='/dashboard/introducing-broker/referral-materials/banners'
        />
        <MaterialCard
          icon={<LogoIcon size={80} />}
          title={t('Referral Materials.Logo')}
          text={t(
            'Referral Materials.Looking for our Logo? We’ve got all the different formats here for you to simply copy, paste and use.'
          )}
          link='/dashboard/introducing-broker/referral-materials/logos'
        />
        <MaterialCard
          icon={<QRCodeIcon size={80} />}
          title={t('Referral Materials.QR Code')}
          text={t(
            'Referral Materials.Generate a QR code with your unique IB link effortlessly, perfect for online or offline sharing with ease'
          )}
          link='/dashboard/introducing-broker/referral-materials/qr-code'
        />
      </Grid>
    </div>
  )
}

interface MaterialCardProps {
  title: string
  text: string
  link: string
  icon: JSX.Element
}

const MaterialCard = ({ title, text, link, icon }: MaterialCardProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.card}>
      {icon}
      <TextH3 className={styles.title}>{title}</TextH3>
      <Text isParagraph className={styles.text}>
        {text}
      </Text>

      <Link className={styles.startButtonLink} to={link}>
        <Button size='M' appearance='primary' type='button' className={styles.startButton}>
          {t('Referral Materials.Start')}
        </Button>
      </Link>
    </div>
  )
}
