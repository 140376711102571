import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { BankAccount } from '../../model/BankAccount'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { BankBadgeStatus } from './BankBadgeStatus'

import styles from './BankAccountsCard.module.scss'

interface BankAccountsCardProps {
  bankAccounts?: BankAccount[]
}

export const BankAccountsCard: React.FC<BankAccountsCardProps> = ({ bankAccounts }) => {
  const { t } = useTranslation()
  const { scrollIntoView } = useScrollToElementIds()
  const [expandedRow, setExpandedRow] = useState('')

  const handleSetExpandedRow = (id: string) => {
    if (id && id !== expandedRow) {
      scrollIntoView([`bank-account-${id}`])
    }
    setExpandedRow(id === expandedRow ? '' : id)
  }

  return (
    <React.Fragment>
      {bankAccounts?.map((bank) => (
        <div className={styles.card} id={`bank-account-${bank.id}`}>
          <div className={styles.cardHead} onClick={() => handleSetExpandedRow(bank.id)}>
            <div className={styles.title}>
              <TextSmallStrong className={styles.name}>{bank.accountName}</TextSmallStrong>
            </div>
            <div className={styles.subtitle}>
              <TextSmall className={classNames(styles.label, 'is-align-items-center')}>
                {/* Add here if a sub-label is needed */}
              </TextSmall>
              <div className='has-cursor-pointer'>
                <span>
                  <BankBadgeStatus {...bank.status} />
                </span>
              </div>
            </div>
          </div>
          {expandedRow === bank.id && (
            <Fragment>
              <ExpandedField label={t('IBAN/Account Number')} value={bank.bankAccount} />
              <ExpandedField label={t('Bank Name')} value={bank.bankName} />
              <ExpandedField label={t('Bank Address')} value={bank.bankAddress} />
              <ExpandedField label={t('Swift')} value={bank.bankCode} />
              <ExpandedField label={t('Sort Code')} value={bank.bankCodeSort} />
              <ExpandedField label={t('Documents')} value={bank.document?.name} />
            </Fragment>
          )}
        </div>
      ))}
    </React.Fragment>
  )
}

export const ExpandedField: React.FC<{
  label: string
  value?: string
  remove?: () => void
}> = ({ label, value, remove }) => {
  if (!value && !remove) {
    return null
  }

  if (remove) {
    return (
      <div className={styles.subtitle}>
        <TextSmall className={styles.remove}>{label}</TextSmall>
      </div>
    )
  }

  return (
    <div className={styles.subtitle}>
      <span className={styles.label}>{label}</span>
      <TextSmallStrong className={styles.value}>{value}</TextSmallStrong>
    </div>
  )
}
