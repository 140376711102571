import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { PlayIcon } from '../../icons/PlayIcon'
import { VideoTutorial } from '../../model/LearningVideoDto'
import { Modal } from './Modal'

import styles from './VideoSelectorModal.module.scss'

interface VideoSelectorBodyModalProps {
  items: VideoTutorial[]
  onSelect(video: VideoTutorial): void
  selectedItem: VideoTutorial | undefined

  onCancel(): void
}

const VideoSelectorBodyModal: React.FC<VideoSelectorBodyModalProps> = ({
  items,
  selectedItem,
  onSelect,
  onCancel,
}) => {
  const handleOnSelect = (item: VideoTutorial) => {
    onSelect(item)
    onCancel()
  }
  return (
    <section className={classNames('modal-card-body', styles.body)}>
      <ul>
        {items.map((item, idx) => {
          const isSelected = item.tutorialLink === selectedItem?.tutorialLink
          return (
            <li
              onClick={() => handleOnSelect(item)}
              key={idx}
              className={classNames(styles.itemWrapper, 'mt-3 is-clickable', {
                'text-strong': isSelected,
              })}
            >
              <div className={classNames('is-flex  is-justify-content-space-between')}>
                <div className='is-flex'>
                  {isSelected && (
                    <div className='mr-2'>
                      <PlayIcon />
                    </div>
                  )}
                  <div className={isSelected ? 'text-strong' : ''}>{item.title}</div>
                </div>
                <div
                  className={classNames(
                    'has-text-right',
                    { 'text-secondary': !isSelected },
                    { 'text-strong': isSelected }
                  )}
                >
                  {item.duration}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

interface VideoSelectorModalProps {
  items: VideoTutorial[]
  onCancel(): void
  onSelect(video: VideoTutorial): void
  selectedItem?: VideoTutorial
}

export const VideoSelectorModal: React.FC<VideoSelectorModalProps> = ({
  items,
  onCancel,
  onSelect,
  selectedItem,
}) => {
  const handleCancel = () => {
    onCancel?.()
  }
  const { t } = useTranslation()

  return (
    <Modal
      cardClassName={styles.card}
      closeModal={handleCancel}
      render={() => (
        <React.Fragment>
          <header className='modal-card-head'>
            <p className='text-strong'>{t('Choose Chapter')}</p>
          </header>
          <VideoSelectorBodyModal
            onSelect={onSelect}
            items={items}
            selectedItem={selectedItem}
            onCancel={onCancel}
          ></VideoSelectorBodyModal>
          <footer className={classNames('modal-card-foot', styles.footer)}>
            <button className='button' onClick={onCancel}>
              {t('Cancel')}
            </button>
          </footer>
        </React.Fragment>
      )}
    />
  )
}
