import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { IntroducingBrokerReportDto } from '../../model/IntroducingBrokerReportDto'
import { Text, TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'

import styles from './IBReportCard.module.scss'

interface IBReportsCardsProps {
  sortedData: IntroducingBrokerReportDto[]
  isLoading: boolean
}

interface IBReportCardProps {
  data: IntroducingBrokerReportDto
  cardId: string
}

export const IBReportCards: React.FC<IBReportsCardsProps> = ({ sortedData, isLoading }) => {
  const { t } = useTranslation()

  return (
    <Loading showLoadingIcon isLoading={isLoading}>
      {!sortedData.length ? (
        <Text isParagraph className='text-align-center pt-4'>
          {t('No results')}
        </Text>
      ) : (
        sortedData.map((report, index) => (
          <IBReportCard data={report} cardId={`ib-report-card-${index + 1}`} />
        ))
      )}
    </Loading>
  )
}

const IBReportCard: React.FC<IBReportCardProps> = ({ data, cardId }) => {
  const [auth] = useContext(AuthSessionContext)

  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const { formatNumber, formatMoney } = useFormatNumber()
  const { scrollIntoView } = useScrollToElementIds()

  const dateFormat = auth?.dateFormatType?.name

  return (
    <div
      onClick={() => {
        if (!expanded && cardId) {
          scrollIntoView([cardId])
        }
        setExpanded((prevState) => !prevState)
      }}
      className={styles.card}
      id={cardId}
    >
      <div className={styles.title}>
        <TextSmallStrong className={styles.mainLabel}>{data.clientName}</TextSmallStrong>
        <TextSmallStrong className={styles.mainLabel}>{data.accountType}</TextSmallStrong>
      </div>
      {!expanded && (
        <div className={styles.title}>
          <TextSmall className={styles.subLabel}>{data.country}</TextSmall>
          <TextSmallStrong className={styles.mainLabel}>
            {formatMoney(data.balance, data.currency)}
          </TextSmallStrong>
        </div>
      )}
      {expanded && (
        <Fragment>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{data.email}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('Status')}</span>
            <span>{data.status}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('Trading Account.Trading Account')}</span>
            <span>{data.login}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Registration Date')}</span>
            <span>{formatDate(data.registrationDate, dateFormat)}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('Trading Account.Balance')}</span>
            <TextSmallStrong className={styles.mainLabel}>
              {formatMoney(data.balance, data.currency)}
            </TextSmallStrong>
          </div>

          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Volume Since 2019')}</span>
            <span>{formatNumber(data.volumeSince2019)}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Volume Current Month')}</span>
            <span>{formatNumber(data.volumeCurrentMonth)}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Volume Previous Month')}</span>
            <span>{formatNumber(data.volumePreviousMonth)}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Swap Free')}</span>
            <span>{data.swapFree ? t('Yes') : t('No')}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.textSecondary}>{t('IB.Entity')}</span>
            <span>{data.clientEntity}</span>
          </div>
        </Fragment>
      )}
    </div>
  )
}
