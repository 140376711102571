import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { PaymentProcessingIcon } from '../icons/PaymentProcessingIcon'
import { TextH3 } from '../ui/Typography/Typography'
import { useWindowResize } from '../utils/domUtils'

import styles from './PaymentDoneModal.module.scss'

interface Props {
  onCancel(): void
}

const PaymentDoneModalBody: React.FC = () => {
  const { t } = useTranslation()

  return (
    <section className={classNames('modal-card-body', styles.body)}>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <div className={styles.icon}>
            <PaymentProcessingIcon />
          </div>
          <TextH3>{t('Payment Processing')}</TextH3>
        </div>
        <div className={styles.column}>
          <div className={styles.text}>
            {t('Your payment is being processed. We will inform you via email')}
          </div>
        </div>
      </div>
    </section>
  )
}

export const PaymentDoneModal: React.FC<Props> = ({ onCancel }) => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()

  return (
    <React.Fragment>
      <PaymentDoneModalBody />
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <button className='button' onClick={onCancel}>
          {isMobile ? t('OK') : t('Got It')}
        </button>
      </footer>
    </React.Fragment>
  )
}
