import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Badge } from '../../global/badge/Badge'
import { Button } from '../../global/button/Button'
import IconButton from '../../global/iconButton/IconButton'
import { ProductServiceModal } from '../../global/modal/ProductServiceModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../icons/ChevronUpIcon'
import { SubscriptionBundleDto } from '../../model/SubscriptionBundleDto'
import { SubscriptionCartItem } from '../../model/SubscriptionCart'
import { SubscriptionRequestState } from '../../model/SubscriptionRequestState'
import { SubscriptionRequestTypeEnum } from '../../model/SubscriptionRequestTypeEnum'
import { Subscription, SubscriptionDetail } from '../../model/SubscriptionTypes'
import {
  SubscriptionRequestType,
  SubscriptionTimeInForce,
  UpdateSubscriptionRequest,
} from '../../model/UpdateSubscriptionRequest'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { isLastBusinessDay } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { SubscriptionTimeInForceModal } from '../SubscriptionTimeInForceTypeModal'
import { PriceRange } from '../SubscriptionsPriceRange'
import { UnsubscribeAttentionConfirmationModal } from '../UnsubscribeAttentionModal'
import { ConfirmCancelSubscriptionModal } from './ConfirmCancelSubscriptionModal'
import { PendingUnsubscriptionModal } from './PendingUnsubscriptionModal'
import { CancelOrProcessingButton } from './ProductActions'
import { ResubscribeModal } from './ResubscribeModal'
import { SubscriptionDetailMessage } from './SubscriptionDetailMessage'
import { SubscriptionDetailModal } from './SubscriptionDetailModal'
import { ProductStatusIcon } from './SubscriptionGroup'
import { UnsubscribeConfirmationModal } from './UnsubscribeConfirmationModal'
import {
  allowSubDowngrade,
  allowSubUpgrade,
  getBadgeSubscriptionLevel,
  getNextSubLevel,
  getPreviousSubLevel,
  getSubscriptionDefaultLevel,
  hasActivatingSubscription,
  hasLevelInCart,
  hasPendingDowngrade,
  hasPendingUnsubscriptionRequest,
  hasPendingUpgrade,
  isLevelDowngrade,
  isSubscriptionTimeInForceImmediate,
} from './subscriptionUtils'

import styles from './MarketDataPage.module.scss'

const isCancellationRequested = (requestType: string | undefined) =>
  requestType === SubscriptionRequestTypeEnum.CancelExistingSubscription

interface BundleSubscriptionDetailProps {
  showUpgrade?: boolean
  showDowngrade?: boolean
  selectedSubscription: Subscription
  handleOnSubscribeLevelChange(
    subscription: Subscription,
    subscriptionLevel: SubscriptionDetail
  ): void
  selectedSubscriptionDetail: SubscriptionDetail
  setSelectedSubscriptionDetail(SubscriptionDetail: SubscriptionDetail | undefined): void

  setPendingUnsubscriptionInfoModal(visible: boolean): void
}

const BundleSubscriptionDetail: React.FC<BundleSubscriptionDetailProps> = ({
  selectedSubscription,
  handleOnSubscribeLevelChange,
  selectedSubscriptionDetail,
  setPendingUnsubscriptionInfoModal,
  showUpgrade,
  showDowngrade,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const [newLevel, setNewLevel] = useState<SubscriptionDetail>()

  const handleOnSelectSubscriptionDetail = (subscriptionDetail?: SubscriptionDetail) => {
    if (subscriptionDetail) {
      handleOnSubscribeLevelChange(selectedSubscription, subscriptionDetail)
    }
    setNewLevel(undefined)
  }

  const defaultSubscriptionLevel = useMemo(
    () => getSubscriptionDefaultLevel(selectedSubscription) || selectedSubscription.details[0],
    [selectedSubscription]
  )

  const currentValue = selectedSubscriptionDetail
    ? `${selectedSubscriptionDetail.name} - ${formatMoney(selectedSubscriptionDetail.amount)}/${t(
        'Subscriptions.mth'
      )}`
    : `${defaultSubscriptionLevel?.name} - ${formatMoney(defaultSubscriptionLevel?.amount)}/${t(
        'Subscriptions.mth'
      )}`

  return (
    <>
      {newLevel && (
        <SubscriptionDetailModal
          subscription={selectedSubscription}
          handleOnSelectSubscriptionDetail={handleOnSelectSubscriptionDetail}
          selectedSubscriptionDetail={newLevel}
          closeModal={() => setNewLevel(undefined)}
        />
      )}
      {selectedSubscriptionDetail && <span>{currentValue}</span>}
      {showUpgrade && (
        <Button
          className={styles.levelButton}
          onClick={() => setNewLevel(getNextSubLevel(selectedSubscription))}
          appearance='plain'
        >
          {t('Upgrade')}
        </Button>
      )}
      {showDowngrade && (
        <Button
          className={styles.levelButton}
          onClick={() => setNewLevel(getPreviousSubLevel(selectedSubscription))}
          appearance='plain'
        >
          {t('Downgrade')}
        </Button>
      )}
      <SubscriptionDetailMessage
        selectedSubscription={selectedSubscription}
        onSetPendingUnsubscriptionInfoModal={setPendingUnsubscriptionInfoModal}
      />
    </>
  )
}

interface BundleStatusProps {
  subscribedBundles: SubscriptionBundleDto[]
  bundle: SubscriptionBundleDto
  onSetIsCollapsed(visible: boolean): void
  isCollapsed: boolean
}

const getSubscriptionStatus = (
  bundle: SubscriptionBundleDto,
  subscribedBundles: SubscriptionBundleDto[]
) => {
  const isBundleSubscribed = subscribedBundles?.find(
    (subscribedBundle) =>
      subscribedBundle.id === bundle.id &&
      subscribedBundle?.subscriptions?.some(
        (subBundle) =>
          subBundle?.activeSubscriptionRequest &&
          subBundle?.subscriptionRequest?.state.name !== SubscriptionRequestState.Pending
      )
  )
  if (isBundleSubscribed) {
    return 'subscribed'
  }

  if (bundle.subscriptionRequest) {
    return bundle.subscriptionRequest.state.name
  }
  return 'not_subscribed'
}
const BundleStatus: React.FC<BundleStatusProps> = ({
  bundle,
  subscribedBundles,
  onSetIsCollapsed,
  isCollapsed,
}) => {
  const { t } = useTranslation()

  const subscriptionStatus = getSubscriptionStatus(bundle, subscribedBundles)

  switch (subscriptionStatus) {
    case 'subscribed':
      return <Badge appearance='success' text={t('Subscriptions.Active')} />

    case SubscriptionRequestState.DeductingFunds:
    case SubscriptionRequestState.InProcess:
      return isCollapsed ? null : (
        <Badge appearance='warning' text={t('Subscriptions.Processing')} />
      )
    case SubscriptionRequestState.Failed:
      return <Badge appearance='danger' text={t('Subscriptions.Failed')} />

    case SubscriptionRequestState.Pending:
      const subscriptionRequest = bundle.subscriptionRequest

      if (!subscriptionRequest) {
        return null
      }

      const isSubscriptionRequestImmediate = isSubscriptionTimeInForceImmediate(subscriptionRequest)
      if (isSubscriptionRequestImmediate) {
        return <Badge appearance='warning' text={t('Subscriptions.Processing')} />
      }

      if (
        subscriptionRequest?.type?.name === SubscriptionRequestTypeEnum.CancelExistingSubscription
      ) {
        return <Badge appearance='warning' text={t('Subscriptions.Pending')} />
      }

      const isCancellationRequest = isCancellationRequested(subscriptionRequest?.type.name)
      return (
        <Badge
          appearance='warning'
          text={t(
            isCancellationRequest ? 'Subscriptions.Pending Cancellation' : 'Subscriptions.Pending'
          )}
        />
      )

    case 'not_subscribed':
    default:
      return isCollapsed ? null : (
        <Button size='XS' appearance='primary' onClick={() => onSetIsCollapsed(true)}>
          {t('Subscriptions.Subscribe')}
        </Button>
      )
  }
}

interface LevelActionProps {
  onSetUnsubscribeConfirmationModal(visible: boolean): void
  onSetUnsubscribeAttentionConfirmationModal(visible: boolean): void
  onSetSubscriptionTypeModal(visible: boolean): void
  onResubscribeBundle(): void
  selectedSubscriptionDetail: SubscriptionDetail | undefined
  onCancelBundleSubscription(): void
  scrollToCart?: () => void
  showUpgrade?: boolean
  showDowngrade?: boolean
  isSubscriptionInCart: boolean
}

const getSubscriptionDetailStatus = (subscriptionDetail: SubscriptionDetail) => {
  if (
    subscriptionDetail?.activeSubscriptionRequest &&
    subscriptionDetail?.subscriptionRequest?.state.name !== SubscriptionRequestState.Pending
  ) {
    return 'subscribed'
  }
  if (subscriptionDetail.subscriptionRequest) {
    return subscriptionDetail.subscriptionRequest.state.name
  }
  return 'not_subscribed'
}

const LevelAction: React.FC<LevelActionProps> = ({
  onSetUnsubscribeConfirmationModal,
  onSetUnsubscribeAttentionConfirmationModal,
  onSetSubscriptionTypeModal,
  onResubscribeBundle,
  selectedSubscriptionDetail,
  onCancelBundleSubscription,
  scrollToCart,
  showUpgrade,
  showDowngrade,
  isSubscriptionInCart,
}) => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  if (!selectedSubscriptionDetail) {
    return null
  }

  const subscriptionDetailStatus = getSubscriptionDetailStatus(selectedSubscriptionDetail)

  const renderSubscribeButton = () => {
    return (
      <Button
        size={isMobile ? 'S' : 'XS'}
        appearance='primary'
        onClick={() => onSetSubscriptionTypeModal(true)}
        className={styles.button}
        disabled={isSubscriptionInCart}
      >
        {t('Market Data.Subscribe')}
      </Button>
    )
  }

  const renderUnsubscribeButton = () => {
    return (
      <Button
        appearance='secondary'
        size={isMobile ? 'S' : 'XS'}
        onClick={() => {
          if (isLastBusinessDay()) {
            onSetUnsubscribeAttentionConfirmationModal(true)
          } else {
            onSetUnsubscribeConfirmationModal(true)
          }
        }}
        className={styles.button}
      >
        {t('Subscriptions.Unsubscribe')}
      </Button>
    )
  }

  const requestIsInProcess =
    subscriptionDetailStatus === SubscriptionRequestState.DeductingFunds ||
    subscriptionDetailStatus === SubscriptionRequestState.InProcess

  const requestIsPending = subscriptionDetailStatus === SubscriptionRequestState.Pending

  if (
    ((subscriptionDetailStatus !== 'subscribed' &&
      selectedSubscriptionDetail?.subscriptionRequest?.type.name ===
        SubscriptionRequestTypeEnum.AddNewSubscription) ||
      selectedSubscriptionDetail?.subscriptionRequest?.type.name ===
        SubscriptionRequestTypeEnum.UpdateExistingSubscription) &&
    !requestIsInProcess &&
    !requestIsPending
  ) {
    return (
      <div className='is-flex is-justify-content-center'>
        <Button
          appearance='secondary'
          onClick={() => onCancelBundleSubscription()}
          size={isMobile ? 'S' : 'XS'}
        >
          {t('Cancel')}
        </Button>
      </div>
    )
  } else if (subscriptionDetailStatus === 'subscribed' && (showUpgrade || showDowngrade)) {
    return renderUnsubscribeButton()
  } else if (requestIsInProcess) {
    return <Badge appearance='warning' text={t('Subscriptions.Processing')} />
  } else if (subscriptionDetailStatus === SubscriptionRequestState.Pending) {
    if (isCancellationRequested(selectedSubscriptionDetail?.subscriptionRequest?.type.name)) {
      return (
        <Button
          appearance='secondary'
          onClick={onResubscribeBundle}
          size={isMobile ? 'S' : 'XS'}
          className={styles.button}
        >
          {t('Subscriptions.Resubscribe')}
        </Button>
      )
    }

    return (
      <CancelOrProcessingButton
        onCancel={() => onCancelBundleSubscription()}
        scrollToCart={scrollToCart}
        subscriptionRequest={selectedSubscriptionDetail?.subscriptionRequest}
      />
    )
  } else {
    return renderSubscribeButton()
  }
}

interface BundlePriceProps {
  showUpgrade?: boolean
  showDowngrade?: boolean
  bundle: SubscriptionBundleDto
  selectedSubscription: Subscription | undefined
  selectedSubscriptionDetail: SubscriptionDetail | undefined
  isCollapsed: boolean
  handleOnSubscribeDetailChange: (subscription: Subscription, detail: SubscriptionDetail) => void
  setSelectedSubscriptionDetail: (detail: SubscriptionDetail) => void
  setPendingUnsubscriptionInfoModal: (isOpen: boolean) => void
}

const BundlePrice: React.FC<BundlePriceProps> = ({
  bundle,
  selectedSubscription,
  selectedSubscriptionDetail,
  isCollapsed,
  handleOnSubscribeDetailChange,
  setSelectedSubscriptionDetail,
  setPendingUnsubscriptionInfoModal,
  showUpgrade,
  showDowngrade,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  if (!selectedSubscription || !selectedSubscriptionDetail) {
    return null
  }

  if (selectedSubscription.details.length > 1 && isCollapsed) {
    return (
      <BundleSubscriptionDetail
        showUpgrade={showUpgrade}
        showDowngrade={showDowngrade}
        selectedSubscription={selectedSubscription}
        handleOnSubscribeLevelChange={handleOnSubscribeDetailChange}
        setSelectedSubscriptionDetail={setSelectedSubscriptionDetail}
        selectedSubscriptionDetail={selectedSubscriptionDetail}
        setPendingUnsubscriptionInfoModal={setPendingUnsubscriptionInfoModal}
      />
    )
  }

  return (
    <>
      <TextSmall>{`${formatMoney(bundle.maxAmount)}/${t('Subscriptions.mth')}`} </TextSmall>
      <SubscriptionDetailMessage
        selectedSubscription={selectedSubscription}
        onSetPendingUnsubscriptionInfoModal={setPendingUnsubscriptionInfoModal}
      />
    </>
  )
}

interface SubscriptionBundleProps {
  bundle: SubscriptionBundleDto
  subscribedBundles: SubscriptionBundleDto[]
  scrollToCart?: () => void
  onUpdateSubscription(data: UpdateSubscriptionRequest, selectedItem?: SubscriptionDetail): void
  onAddCartItem(
    subscriptionDetailId: string,
    subscriptionRequestTypeId: SubscriptionRequestType
  ): void
  nonActiveSubscriptions: SubscriptionCartItem[]
}
export const SubscriptionBundle: React.FC<SubscriptionBundleProps> = ({
  bundle,
  subscribedBundles,
  scrollToCart,
  onUpdateSubscription,
  onAddCartItem,
  nonActiveSubscriptions,
}) => {
  const isMobile = useWindowResize()
  const { scrollIntoView } = useScrollToElementIds()
  const [productDescriptionModal, setProductDescriptionModal] = useState<SubscriptionBundleDto>()

  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | undefined>()
  const [selectedSubscriptionDetail, setSelectedSubscriptionDetail] = useState<SubscriptionDetail>()
  const [previousSelectedSubscriptionDetail, setPreviousSelectedSubscriptionDetail] =
    useState<SubscriptionDetail>()
  const [subscriptionDetailModal, setSubscriptionDetailModal] = useState<Subscription | null>(null)
  const [subscriptionTimeInForceTypeModal, setSubscriptionTimeInForceTypeModal] = useState(false)
  const [subscriptionTimeInForceType, setSubscriptionTimeInForceType] = useState<
    number | undefined
  >()

  useEffect(() => {
    const [subscription] = bundle.subscriptions
    setSelectedSubscriptionDetail(getBadgeSubscriptionLevel(bundle))
    setSelectedSubscription(subscription)
  }, [bundle, subscribedBundles])

  const handleOnSubscribeDetailChange = (
    subscription: Subscription,
    subscriptionDetail: SubscriptionDetail
  ) => {
    setPreviousSelectedSubscriptionDetail(selectedSubscriptionDetail)
    setSelectedSubscriptionDetail(subscriptionDetail)
    setSelectedSubscription(subscription)
    setLevelDowngrade(isLevelDowngrade(subscription, subscriptionDetail))
    setSubscriptionTimeInForceTypeModal(true)
  }

  const getSubscriptionRequestType = (): SubscriptionRequestType =>
    selectedSubscription?.activeSubscriptionRequest ||
    selectedSubscription?.subscriptionRequest?.state.name === SubscriptionRequestState.Pending
      ? SubscriptionRequestType.Update
      : SubscriptionRequestType.Add

  const canAddSubscription = () => {
    return (
      !selectedSubscriptionDetail?.activeSubscriptionRequest &&
      selectedSubscriptionDetail?.subscriptionRequest?.state.name !==
        SubscriptionRequestState.Pending
    )
  }

  const handleOnSubscribeBundle = (subscriptionTimeInForce: SubscriptionTimeInForce) => {
    if (!canAddSubscription() || !selectedSubscription || !selectedSubscriptionDetail) {
      return
    }

    if (isCollapsed) {
      if (
        subscriptionTimeInForce === SubscriptionTimeInForce.BeginningOfNextMonth &&
        canAddSubscription()
      ) {
        onUpdateSubscription({
          subscriptions: [
            {
              subscriptionDetailId: selectedSubscriptionDetail?.id,
              subscriptionRequestTypeId: getSubscriptionRequestType(),
            },
          ],
          subscriptionTimeInForceId: SubscriptionTimeInForce.BeginningOfNextMonth,
        })
      } else {
        onAddCartItem(selectedSubscriptionDetail.id, getSubscriptionRequestType())
      }
    }
  }

  const handleOnSelectSubscriptionTimeInForceType = (type: SubscriptionTimeInForce) => {
    handleOnSubscribeBundle(type)
    setSubscriptionTimeInForceTypeModal(false)
    setSubscriptionTimeInForceType(type)
  }

  const [unsubscribeAttentionConfirmationModal, setUnsubscribeAttentionConfirmationModal] =
    useState(false)
  const [unsubscribeConfirmationModal, setUnsubscribeConfirmationModal] = useState(false)
  const [resubscribeBundleModal, setResubscribeBundleModal] = useState(false)

  const handleOnSetConfirmCancelSubscriptionModal = () => {
    setConfirmCancelSubscriptionModal(true)
  }

  const handleOnCancelBundleSubscription = () => {
    setConfirmCancelSubscriptionModal(false)
    onUpdateSubscription({
      subscriptions: [
        {
          subscriptionDetailId: selectedSubscriptionDetail?.id,
          subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
        },
      ],
      overrideOpenPositionsForCancel: true,
      subscriptionTimeInForceId: SubscriptionTimeInForce.Immediate,
    })
  }

  const handleOnClickUnsubscribe = () => {
    setUnsubscribeConfirmationModal(false)
    setUnsubscribeAttentionConfirmationModal(false)

    onUpdateSubscription(
      {
        subscriptions: [
          {
            subscriptionDetailId: selectedSubscriptionDetail?.id,
            subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
          },
        ],
        subscriptionTimeInForceId: SubscriptionTimeInForce.BeginningOfNextMonth,
      },
      selectedSubscriptionDetail
    )
  }

  const [pendingUnsubscriptionInfoModal, setPendingUnsubscriptionInfoModal] = useState(false)

  const handleOnProductDescriptionClose = () => setProductDescriptionModal(undefined)

  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleOnBundleSelected = (bundle: SubscriptionBundleDto) => {
    setIsCollapsed(true)
    setProductDescriptionModal(bundle)
  }

  const handleOnSetSelectedBundleToggle = (subscriptionId: string) => {
    if (!isCollapsed) {
      scrollIntoView([`market-data-subscription-${subscriptionId}`])
    }
    setIsCollapsed((prev) => !prev)
  }

  const handleOnResubscribeBundle = () => {
    setResubscribeBundleModal(true)
  }

  const handleOnClosePendingUnsubscriptionModal = () => setPendingUnsubscriptionInfoModal(false)

  const [confirmCancelSubscriptionModal, setConfirmCancelSubscriptionModal] = useState(false)

  const handleOnCloseCancelConfirmationModal = () => setConfirmCancelSubscriptionModal(false)

  const [levelDowngrade, setLevelDowngrade] = useState(false)

  const isLevelSelectionDisabled = useMemo(
    () =>
      selectedSubscription &&
      (hasPendingUnsubscriptionRequest(selectedSubscription) ||
        hasActivatingSubscription(selectedSubscription) ||
        hasLevelInCart(selectedSubscription, nonActiveSubscriptions)),
    [selectedSubscription, nonActiveSubscriptions]
  )
  const allowUpgrade = useMemo(
    () =>
      selectedSubscription && !isLevelSelectionDisabled && allowSubUpgrade(selectedSubscription),
    [isLevelSelectionDisabled, selectedSubscription]
  )
  const allowDowngrade = useMemo(
    () =>
      selectedSubscription && !isLevelSelectionDisabled && allowSubDowngrade(selectedSubscription),
    [isLevelSelectionDisabled, selectedSubscription]
  )

  const pendingUpgrade = useMemo(
    () =>
      selectedSubscription && hasPendingUpgrade(selectedSubscription, selectedSubscriptionDetail),
    [selectedSubscription, selectedSubscriptionDetail]
  )
  const pendingDowngrade = useMemo(
    () =>
      selectedSubscription && hasPendingDowngrade(selectedSubscription, selectedSubscriptionDetail),
    [selectedSubscription, selectedSubscriptionDetail]
  )

  const showUpgrade = (allowUpgrade && !pendingUpgrade) || pendingDowngrade
  const showDowngrade = (allowDowngrade && !pendingDowngrade) || pendingUpgrade

  const handleOnClickResubscribeConfirm = () => {
    handleOnCancelBundleSubscription()
    setResubscribeBundleModal(false)
  }

  const cancelTimeInForceSelection = () => {
    if (previousSelectedSubscriptionDetail) {
      setSelectedSubscriptionDetail(previousSelectedSubscriptionDetail)
    }
    setSubscriptionTimeInForceTypeModal(false)
  }

  const isSubscriptionInCart = useMemo(
    () => hasLevelInCart(selectedSubscription, nonActiveSubscriptions),
    [selectedSubscription, nonActiveSubscriptions]
  )

  return (
    <>
      {confirmCancelSubscriptionModal && (
        <ConfirmCancelSubscriptionModal
          handleOnCancelPendingSubscription={handleOnCancelBundleSubscription}
          handleOnCloseCancelConfirmationModal={handleOnCloseCancelConfirmationModal}
        />
      )}
      {pendingUnsubscriptionInfoModal && (
        <PendingUnsubscriptionModal
          handleOnClosePendingUnsubscriptionModal={handleOnClosePendingUnsubscriptionModal}
          dueDate={selectedSubscriptionDetail?.subscriptionRequest?.dueDate}
        />
      )}
      {resubscribeBundleModal && (
        <ResubscribeModal
          handleOnClickResubscribeConfirm={handleOnClickResubscribeConfirm}
          setResubscribeModal={setResubscribeBundleModal}
          amount={selectedSubscriptionDetail?.amount}
        />
      )}
      {unsubscribeConfirmationModal && (
        <UnsubscribeConfirmationModal
          onClickUnsubscribe={handleOnClickUnsubscribe}
          setUnsubscribeConfirmationModal={() => setUnsubscribeConfirmationModal(false)}
          dueDate={selectedSubscriptionDetail?.subscriptionRequest?.dueDate}
        />
      )}
      {unsubscribeAttentionConfirmationModal && (
        <UnsubscribeAttentionConfirmationModal
          onConfirm={handleOnClickUnsubscribe}
          onCancel={() => setUnsubscribeAttentionConfirmationModal(false)}
        />
      )}

      {!!subscriptionDetailModal && (
        <SubscriptionDetailModal
          subscription={subscriptionDetailModal}
          selectedSubscriptionDetail={selectedSubscription?.details.find(
            (detail) => !!detail.activeViaBundleSubscriptionRequest
          )}
          handleOnSelectSubscriptionDetail={(subscriptionDetail) => {
            setSelectedSubscriptionDetail(subscriptionDetail)
            setSubscriptionTimeInForceTypeModal(true)
            setSubscriptionDetailModal(null)
          }}
          closeModal={() => setSubscriptionDetailModal(null)}
          isSelectEnabled
        />
      )}

      {subscriptionTimeInForceTypeModal && (
        <SubscriptionTimeInForceModal
          handleOnSelectSubscriptionTimeInForceType={handleOnSelectSubscriptionTimeInForceType}
          levelDowngrade={levelDowngrade}
          subscriptionTimeInForceType={subscriptionTimeInForceType}
          cancelTimeInForceSelection={cancelTimeInForceSelection}
        />
      )}

      <ProductServiceModal
        data={productDescriptionModal}
        closeModal={handleOnProductDescriptionClose}
      />

      <div
        className={classNames(styles.product)}
        key={bundle.id}
        id={`market-data-subscription-${bundle.id}`}
      >
        <div className={classNames(styles.subHeader)}>
          <div className={styles.name}>
            <span className='is-link' onClick={() => handleOnBundleSelected(bundle)}>
              <TextSmallStrong>{bundle.name} 🔥</TextSmallStrong>
            </span>
            <div>
              <TextSmall className='text-secondary'>{bundle.subName}</TextSmall>
            </div>
            {!isCollapsed && isMobile && (
              <div className={styles.price}>
                <PriceRange minAmount={bundle.minAmount} maxAmount={bundle.maxAmount} />
              </div>
            )}
          </div>

          {!isMobile && (
            <div className={styles.price}>
              {!isCollapsed && (
                <PriceRange minAmount={bundle.minAmount} maxAmount={bundle.maxAmount} />
              )}
            </div>
          )}

          <div className={styles.status}>
            <BundleStatus
              bundle={bundle}
              subscribedBundles={subscribedBundles}
              onSetIsCollapsed={setIsCollapsed}
              isCollapsed={isCollapsed}
            />
          </div>

          <div className={styles.control}>
            <IconButton onClick={() => handleOnSetSelectedBundleToggle(bundle.id)}>
              {isCollapsed ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </IconButton>
          </div>
        </div>

        {isCollapsed &&
          bundle.subscriptions.map((subscription) => (
            <>
              <hr className={styles.separator} />
              <div className={classNames(styles.dataItem, 'mt-0')}>
                <div className={styles.titleWrapper}>
                  <div className={classNames('is-flex', styles.name)}>
                    <ProductStatusIcon className={styles.statusIcon} subscription={subscription} />
                    <TextSmallStrong>{bundle.name}</TextSmallStrong>
                    <TextSmall className={styles.dataDescription}>
                      {selectedSubscriptionDetail?.description}
                    </TextSmall>
                  </div>

                  <TextSmall className={styles.mobileDataDescription}>
                    {subscription?.description}
                  </TextSmall>
                  <div className={classNames(styles.price)}>
                    <BundlePrice
                      bundle={bundle}
                      handleOnSubscribeDetailChange={handleOnSubscribeDetailChange}
                      isCollapsed={isCollapsed}
                      selectedSubscription={selectedSubscription}
                      setPendingUnsubscriptionInfoModal={setPendingUnsubscriptionInfoModal}
                      selectedSubscriptionDetail={selectedSubscriptionDetail}
                      setSelectedSubscriptionDetail={setSelectedSubscriptionDetail}
                      showUpgrade={showUpgrade}
                      showDowngrade={showDowngrade}
                    />
                  </div>
                  <div className={styles.status}>
                    <LevelAction
                      isSubscriptionInCart={isSubscriptionInCart}
                      onResubscribeBundle={handleOnResubscribeBundle}
                      onSetUnsubscribeAttentionConfirmationModal={
                        setUnsubscribeAttentionConfirmationModal
                      }
                      onSetUnsubscribeConfirmationModal={setUnsubscribeConfirmationModal}
                      onSetSubscriptionTypeModal={(state) => {
                        if (state && subscription.details.length > 1) {
                          return setSubscriptionDetailModal(subscription)
                        }

                        setSubscriptionTimeInForceTypeModal(state)
                      }}
                      selectedSubscriptionDetail={selectedSubscriptionDetail}
                      onCancelBundleSubscription={handleOnSetConfirmCancelSubscriptionModal}
                      scrollToCart={scrollToCart}
                      showUpgrade={showUpgrade}
                      showDowngrade={showDowngrade}
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>
    </>
  )
}
