import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckIcon } from '../../icons/CheckIcon'
import { CloseIcon } from '../../icons/CloseIcon'
import { DocGeneralIcon } from '../../icons/DocGeneralIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { TextSmall, TextStrong } from '../../ui/Typography/Typography'
import IconButton from '../iconButton/IconButton'
import { createFileData } from './FileUpload'
import { isImageFileExtensions } from './types'

import styles from './FileUpload.module.scss'

interface FileUploadResultProps {
  title?: string
  file: FileData
  error?: string
  disabled?: boolean

  onUpload(data: FileData): void

  onRemove?(): void
}

interface FileData {
  fileName: string
  fileType: string
  fileSize: number
  fileSizeBytes: number
  fileSizeKb: string
  fileSizeMb: string
  data: File
}

export const FileUploadResult: React.FC<FileUploadResultProps> = (props) => {
  const { title, file, error, disabled, onUpload, onRemove } = props
  const { t } = useTranslation()

  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [newFile] = event.target.files || []
    onUpload({
      ...file,
      ...createFileData(newFile),
    })
  }

  const handleClick = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()
    if (hiddenFileInput?.current?.click) {
      hiddenFileInput.current.click()
    }
  }

  const getFileSize = (sizeInMb: string, sizeInKb: string) => {
    if (sizeInMb !== '0.00') {
      return `(${sizeInMb} MB)`
    }
    return `(${sizeInKb} KB)`
  }

  return (
    <div>
      {!error && (
        <div className={styles.content}>
          <div className={styles.fileClose}>
            <IconButton onClick={onRemove}>
              <CloseIcon color={'error'} />
            </IconButton>
          </div>
          {title && <TextStrong isParagraph>{title}</TextStrong>}
          <React.Fragment>
            {isImageFileExtensions(file.fileType) && (
              <img src={URL.createObjectURL(file.data)} alt={file.fileName} />
            )}
            {!isImageFileExtensions(file.fileType) && (
              <span className={styles.fileGeneralIcon}>
                <DocGeneralIcon size={16} />
              </span>
            )}
          </React.Fragment>
          <div className={styles.fileResultInfo}>
            <p>
              <span className='is-flex is-align-items-center is-justify-content-center'>
                <CheckIcon size={18} color={'success'} />{' '}
                <span className='ml-1'>{`${file.fileName} ${getFileSize(
                  file.fileSizeMb,
                  file.fileSizeKb
                )}`}</span>
              </span>
            </p>
          </div>
        </div>
      )}
      {error && (
        <div className={styles.content}>
          <div className={styles.fileClose}>
            <IconButton onClick={onRemove}>
              <CloseIcon color={'error'} />
            </IconButton>
          </div>
          {title && <TextStrong isParagraph>{title}</TextStrong>}
          <div className={styles.fileWarningInfo}>
            <TextSmall isParagraph>
              <span>
                <WarningIcon size={20} color={'error'} />{' '}
                {`${file.fileName} ${getFileSize(file.fileSizeMb, file.fileSizeKb)}`}
              </span>
              <span className='pl-4'>
                <button className={styles.tryAgainButton} onClick={handleClick}>
                  {t('Try again')}
                </button>
              </span>
            </TextSmall>
            <TextSmall isParagraph>
              <span className={styles.error}>{error}</span>
            </TextSmall>
          </div>
        </div>
      )}
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={handleUpload}
        disabled={disabled}
        className={styles.hiddenFileInput}
      />
    </div>
  )
}
