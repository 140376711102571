import { useEffect, useRef } from 'react'

export const useDropdownHeight = (): {
  headerRef: React.RefObject<HTMLDivElement>
  dropdownRef: React.RefObject<HTMLDivElement>
} => {
  const headerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const currentHeaderRef = headerRef.current
    const handleResize = () => {
      if (!currentHeaderRef || !dropdownRef.current) {
        return
      }
      const headerHeight = currentHeaderRef.offsetTop + currentHeaderRef.offsetHeight
      dropdownRef.current.style.maxHeight = `calc(100vh - ${headerHeight}px)`
    }

    handleResize()

    const resizeObserver = new ResizeObserver(handleResize)
    if (!currentHeaderRef) {
      return
    }
    if (currentHeaderRef.parentElement) {
      resizeObserver.observe(currentHeaderRef.parentElement)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      if (!currentHeaderRef) {
        return
      }
      if (currentHeaderRef.parentElement) {
        resizeObserver.unobserve(currentHeaderRef.parentElement)
      }
    }
  }, [dropdownRef, headerRef])

  return { headerRef, dropdownRef }
}
