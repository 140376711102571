import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { ProductAppropriatenessTestProfilePage } from '../ProductRegistration/AppropriatenessTest/ProductAppropriatenessTestPage'
import { Loading } from '../global/Loading/Loading'
import { BackButton } from '../global/backButton/BackButton'
import { useProductReadContext } from '../global/context/ProductContext'
import { PageNotFound } from '../global/notFound/PageNotFound'
import { Tabs } from '../global/tabs/Tabs'
import {
  hasProductActivatedStatus,
  hasProductAppropriatenessTestSubmittedStatus,
  hasProductPendingDocumentVerificationStatus,
  isAccountDetailedActivatedStatus,
  isAccountDocumentAllowedUpload,
  isProductAppropriatenessValid,
} from '../model/AccountDetailedDto'
import { isAccountCorporate, showMarketDataClassification } from '../model/AccountType'
import { DocumentCategoryLinkMap, DocumentCategoryType } from '../model/DocumentCategories'
import { isTickmillProductTypeETD } from '../model/TickmillProductType'
import { PageHeader } from '../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { isTickmillUK } from '../utils/companyName.utils'
import { isNoTabsPath } from '../utils/path'
import { isTradersRoomRouteAllowed } from '../utils/permission.utils'
import { AccountSettingsPage } from './AccountSettings/AccountSettingsPage'
import { SecuritySettingsPage } from './AccountSettings/SecuritySettingsPage'
import { AppropriatenessTestPage } from './AppropriatenessTest/AppropriatenessTestPage'
import { BankAccountsPage } from './BankAccounts/BankAccountsPage'
import { ClientClassificationPage } from './ClientClassification/ClientClassificationPage'
import {
  DocumentManagementNotification,
  DocumentManagementPage,
  isDocumentManagementNotificationNeeded,
} from './DocumentManagement/DocumentManagementPage'
import { DocumentManagementAdditionalDocumentsPage } from './DocumentManagementUpload/DocumentManagementAdditionalDocumentsPage'
import { DocumentManagementCompanyPage } from './DocumentManagementUpload/DocumentManagementCompanyPage'
import { DocumentManagementIdentificationPage } from './DocumentManagementUpload/DocumentManagementIdentificationPage'
import { DocumentManagementPaymentsPage } from './DocumentManagementUpload/DocumentManagementPaymentsPage'
import { DocumentManagementProofOfAddressPage } from './DocumentManagementUpload/DocumentManagementProofOfAddressPage'
import { DocumentVerificationPage } from './DocumentVerification/DocumentVerificationPage'
import { IntroducingBrokerPage } from './IntroducingBroker/IntroducingBrokerPage'
import { MarketDataClassificationPage } from './MarketDataClassification/MarketDataClassificationPage'
import { PersonalInfoPage } from './PersonalInfo/PersonalInfoPage'
import { PhoneNumbersPage } from './PhoneNumbers/PhoneNumbersPage'
import { W8BeneficialEPage } from './W8Beneficial/W8BeneficialEPage'
import { W8BeneficialPage } from './W8Beneficial/W8BeneficialPage'

import styles from './ProfileRoutes.module.scss'

export type Tab =
  | 'personal-info'
  | 'document-management'
  | 'client-classification'
  | 'market-data-classification'
  | 'tax-form'
  | 'introducing-broker'
  | 'account-settings'
  | 'security-settings'
  | 'bank-accounts'
  | 'phone-numbers'
  | 'logout'

const useTabs = () => {
  const { tab } = useParams<{ tab?: string }>()
  const { t } = useTranslation()

  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()

  const isAccountPending =
    hasProductAppropriatenessTestSubmittedStatus(account, product) ||
    hasProductPendingDocumentVerificationStatus(account, product)
  const isAccountAppropriatenessTest = isProductAppropriatenessValid(product, account)

  const isTickmillUKType = isTickmillUK(account)

  const isAccountActivated = isTickmillUKType
    ? hasProductActivatedStatus(account, product)
    : isAccountDetailedActivatedStatus(account?.status.id || -1)

  const tabs = [
    {
      name: t('Profile Overview'),
      id: 'personal-info',
      path: '/profile/personal-info',
      disabled: false,
      hidden: false,
    },
    {
      name: t('Profile.Appropriateness Test'),
      id: 'appropriateness-test',
      path: '/profile/appropriateness-test',
      disabled: false,
      required: isAccountAppropriatenessTest,
      hidden: !isAccountAppropriatenessTest,
    },
    {
      name: t('Profile.Document Management'),
      id: 'document-management',
      path: '/profile/document-management',
      disabled: false,
      required: isAccountDocumentAllowedUpload(account),
      hidden: isAccountAppropriatenessTest,
    },
    {
      name: t('Profile.Client Classification'),
      id: 'client-classification',
      path: '/profile/client-classification',
      disabled: false,
      hidden:
        !hasProductActivatedStatus(account, product) ||
        !account?.companyConfiguration.hasClassification,
    },
    {
      name: t('Profile.tax form'),
      id: 'tax-form',
      path: '/profile/tax-form',
      disabled: false,
      hidden:
        !account?.companyConfiguration.hasStocks ||
        !isAccountActivated ||
        isTickmillProductTypeETD(product),
    },
    {
      name: t('Profile.W-8BEN-e Form'),
      id: 'e-tax-form',
      path: '/profile/e-tax-form',
      disabled: false,
      hidden: !isAccountActivated || !isAccountCorporate(account),
    },
    {
      name: t('Profile.Introducing Broker'),
      id: 'introducing-broker',
      path: '/profile/introducing-broker',
      disabled: false,
      hidden: !account?.companyConfiguration.hasIBRoom || isAccountPending || !isAccountActivated,
    },
    {
      name: t('Profile.Account Settings'),
      id: 'account-settings',
      path: '/profile/account-settings',
      disabled: false,
      hidden: false,
    },
    {
      name: t('Profile.Security Settings'),
      id: 'security-settings',
      path: '/profile/security-settings',
      disabled: false,
      hidden: false,
    },
    {
      name: t('Profile.Market Data Classification'),
      id: 'market-data-classification',
      path: '/profile/market-data-classification',
      disabled: false,
      hidden: !showMarketDataClassification(account, product),
    },
    {
      name: t('My Bank Accounts'),
      id: 'bank-accounts',
      path: '/profile/bank-accounts',
      disabled: false,
      hidden: !isAccountActivated,
    },
    {
      name: t('My Phone Numbers'),
      id: 'phone-numbers',
      path: '/profile/phone-numbers',
      disabled: false,
    },
    {
      name: t('Profile.Logout'),
      path: '/profile/logout',
      id: 'logout',
      disabled: false,
      hidden: false,
    },
  ]

  const tabName = tabs.find((x) => x.id === tab)?.name

  return {
    TabsDefinition: tabs,
    tabName,
    isAccountPending,
    isAccountAppropriatenessTest,
    isAccountActivated,
  }
}

const useTabsPermissions = () => {
  const { tab } = useParams<{ tab?: string }>()

  const { product } = useProductReadContext()
  const { account } = useAccountReadContext()

  const isTickmillUKType = isTickmillUK(account)

  const isProductActivated = hasProductActivatedStatus(account, product)

  const isAccountActivated = isTickmillUKType
    ? isProductActivated
    : isAccountDetailedActivatedStatus(account?.status.id || -1)

  const routes = [
    'personal-info',
    'appropriateness-test',
    ...(isAccountActivated ? ['document-management'] : []),
    'client-classification',
    'market-data-classification',
    'introducing-broker',
    'account-settings',
    'security-settings',
    'bank-accounts',
    'phone-numbers',
    'tax-form',
    'e-tax-form',
  ]

  const isTabsAllowed = tab && routes.includes(tab)

  return { routes, isTabsAllowed, isAccountActivated }
}

export const ProfileRoutes: React.FC = () => {
  const { t } = useTranslation()
  const { tab } = useParams<{ tab?: string }>()
  const navigate = useNavigate()
  const location = useLocation()

  const { isDefaultCFDProductType } = useProductReadContext()
  const { product } = useProductReadContext()
  const { account } = useAccountReadContext()

  const { TabsDefinition, tabName } = useTabs()
  const { isTabsAllowed, isAccountActivated } = useTabsPermissions()
  const isProductTypeCFD = isDefaultCFDProductType()

  useEffect(() => {
    if (tab === 'logout') {
      handleLogOut()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, tab])

  const getBackUrl = useMemo(() => {
    if (!isProductTypeCFD) {
      return '/dashboard/traders-room/balances'
    }

    return isTradersRoomRouteAllowed(account)
      ? '/dashboard/traders-room/wallets'
      : '/dashboard/introducing-broker/wallets'
  }, [isProductTypeCFD, account])

  const handleLogOut = () => {
    navigate('/logout')
  }

  if (tab === 'logout') {
    return <Loading text={`${t('Logging out')}...`} isLoading={true} showLoadingIcon />
  }

  if (!tab) {
    return <PageNotFound />
  }

  return (
    <>
      {isDocumentManagementNotificationNeeded(account) && <DocumentManagementNotification />}
      {isTabsAllowed ? (
        <>
          <div className={classNames(styles.backButton)}>
            <BackButton section={t('My Profile')} backUrl={getBackUrl} />
          </div>
          {!isNoTabsPath(location.pathname) && (
            <div className={classNames('section', styles.adminRoutes)}>
              <Tabs
                tabs={TabsDefinition.filter((x) => !x.hidden)}
                currentTab={tab as Tab}
                appearance='light'
                onTabChanged={(tab) => navigate(tab)}
              />
            </div>
          )}
        </>
      ) : (
        <div className={styles.emptySpace} />
      )}
      {['personal-info', 'account-settings', 'security-settings'].includes(tab) && tabName && (
        <PageHeader title={tabName} />
      )}
      {tab === 'personal-info' && (
        <Routes>
          <Route index element={<PersonalInfoPage />} />
        </Routes>
      )}
      {tab === 'appropriateness-test' && (
        <Routes>
          <Route path='take-test' element={<ProductAppropriatenessTestProfilePage />} />
          <Route index element={<AppropriatenessTestPage />} />
        </Routes>
      )}
      {tab === 'product-appropriateness-test' && (
        <Routes>
          <Route index element={<AppropriatenessTestPage />} />
        </Routes>
      )}
      {tab === 'document-verification' && (
        <Routes>
          <Route index element={<DocumentVerificationPage />} />
        </Routes>
      )}
      {tab === 'document-management' && (
        <Routes>
          <Route
            path={DocumentCategoryLinkMap[DocumentCategoryType.Personal]}
            element={<DocumentManagementIdentificationPage />}
          />
          <Route
            path={DocumentCategoryLinkMap[DocumentCategoryType.Address]}
            element={<DocumentManagementProofOfAddressPage />}
          />
          <Route
            path={DocumentCategoryLinkMap[DocumentCategoryType.Corporate]}
            element={<DocumentManagementCompanyPage />}
          />
          <Route
            path={DocumentCategoryLinkMap[DocumentCategoryType.Additional]}
            element={<DocumentManagementAdditionalDocumentsPage />}
          />
          <Route
            path={DocumentCategoryLinkMap[DocumentCategoryType.Payment]}
            element={<DocumentManagementPaymentsPage />}
          />
          <Route index element={<DocumentManagementPage />} />
        </Routes>
      )}
      {tab === 'client-classification' && account?.companyConfiguration.hasClassification && (
        <Routes>
          <Route index element={<ClientClassificationPage />} />
        </Routes>
      )}
      {tab === 'tax-form' && account?.companyConfiguration.hasStocks && (
        <Routes>
          <Route index element={<W8BeneficialPage />} />
        </Routes>
      )}
      {tab === 'e-tax-form' && isAccountCorporate(account) && (
        <Routes>
          <Route index element={<W8BeneficialEPage />} />
        </Routes>
      )}
      {tab === 'introducing-broker' && account?.companyConfiguration.hasIBRoom && (
        <Routes>
          <Route index element={<IntroducingBrokerPage />} />
        </Routes>
      )}
      {tab === 'account-settings' && (
        <Routes>
          <Route index element={<AccountSettingsPage />} />
        </Routes>
      )}
      {tab === 'security-settings' && (
        <Routes>
          <Route index element={<SecuritySettingsPage />} />
        </Routes>
      )}
      {isAccountActivated && (
        <>
          {tab === 'bank-accounts' && (
            <Routes>
              <Route index element={<BankAccountsPage />} />
            </Routes>
          )}
          {showMarketDataClassification(account, product) &&
            tab === 'market-data-classification' && (
              <Routes>
                <Route index element={<MarketDataClassificationPage />} />
              </Routes>
            )}
        </>
      )}
      {tab === 'phone-numbers' && (
        <Routes>
          <Route index element={<PhoneNumbersPage />} />
        </Routes>
      )}
    </>
  )
}
