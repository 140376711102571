import React from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmationModal } from '../../../../../global/modal/ConfirmationModal'
import { Modal } from '../../../../../global/modal/Modal'

import styles from '../DocumentsInfoPage.module.scss'

interface DocumentReasonModalProps {
  onConfirm(): void
  onCancel(): void
}

export const DocumentConfirmationModal: React.FC<DocumentReasonModalProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onCancel}
      cardClassName={styles.modal}
      render={() => (
        <ConfirmationModal
          title={t('Sign up.Skip document upload?')}
          renderFooter={() => (
            <React.Fragment>
              <button className='button' onClick={onCancel} type='button'>
                {t('Sign up.Back to Upload')}
              </button>
              <button className='submit' onClick={onConfirm} type='button'>
                {t('Sign up.Skip upload')}
              </button>
            </React.Fragment>
          )}
          onConfirm={onConfirm}
          onCancel={onCancel}
        >
          <p>
            {t(
              'Sign up.You can skip document upload, but uploading documents is required step to become Tickmill customer.'
            )}
          </p>
        </ConfirmationModal>
      )}
    />
  )
}
