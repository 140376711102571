import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { SignupFormValues, useSignup } from '../../../../global/context/SignupContext'
import { AccountType } from '../../../../model/AccountType'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { isNumber } from '../../../../utils/validations'
import { useCreateClient } from '../../hooks/useCreateClient'
import { FormValuesLegalDocs } from './PersonalDetailsDocumentsFactory'
import { PersonalDetailsStep1Page } from './PersonalDetailsStep1/PersonalDetailsStep1Page'
import { PersonalDetailsStep2Page } from './PersonalDetailsStep2/PersonalDetailsStep2Page'
import { PersonalDetailsStep3Page } from './PersonalDetailsStep3/PersonalDetailsStep3Page'
import { LeadCreationPage } from './PersonalDetailsStep4/LeadCreationPage'
import { PersonalDetailsStep4Page } from './PersonalDetailsStep4/PersonalDetailsStep4Page'
import { VerifyEmailPage } from './PersonalDetailsStep4/VerifyEmailPage'
import { PersonalDetailsStep5FormValues } from './PersonalDetailsStep5/PersonalDetailsStep5Form'
import { PersonalDetailsStep5Page } from './PersonalDetailsStep5/PersonalDetailsStep5Page'
import { PersonalDetailsStep6Page } from './PersonalDetailsStep6/PersonalDetailsStep6Page'

export const PersonalDetailsOrchestrator: React.FC = () => {
  const { step } = useParams<{ step: string }>()
  const location = useLocation()
  const { leadId } = location.state || {}
  const navigate = useNavigate()
  const { appendValues, signupData } = useSignup()
  const apiClient = useApiClient(ClientApiClient)
  const { denormalize, signInAndMoveToFinancialInfo } = useCreateClient()

  const [subStep, setSubStep] = useState(Number(step ?? 1) ?? 1)
  const [error, setError] = useState<string | undefined>()

  const totalSteps = signupData.totalSteps.personalDetails

  const nextStep = async (values?: Partial<SignupFormValues>) => {
    if (values) {
      await appendValues(values)
    }

    if (values?.isUsCitizen) {
      await appendValues({ ...signupData.values, isUsCitizen: undefined })
      return navigate('/sign-up/personal-details/us-citizen')
    }
    return setSubStep(subStep + 1)
  }

  useEffect(() => {
    if (step && !isNumber(step)) {
      setSubStep(1)
    }
    if (step && Number(step) > totalSteps) {
      setSubStep(1)
    }
    const searchParams = location.search
    if (step) {
      navigate(`/sign-up/personal-details/${searchParams}`, { state: location.state })
    }
  }, [location.search, location.state, navigate, signupData.values, step, totalSteps])

  const goBack = () => setSubStep(subStep - 1)

  const handleSubmit = async (values: FormValuesLegalDocs) => {
    const data = {
      ...signupData.values,
      agreedLegalDocuments: values?.agreedLegalDocuments || [],
      marketDataClassificationId: values?.readDocumentMarketData?.marketDataClassificationId,
      shouldReceiveNewsletter: signupData.lead?.optIn,
    } as SignupFormValues
    appendValues(data)

    if (!signupData.lead) {
      return
    }
    if (signupData.lead.type.id === AccountType.Individual) {
      await apiClient.createClient(denormalize(signupData.lead, data))
      await signInAndMoveToFinancialInfo(data)
    } else {
      appendValues(data)
      navigate('/sign-up/company-details/')
    }
  }

  const handleExpeditSubmit = async (values: PersonalDetailsStep5FormValues) => {
    const data = {
      ...signupData.values,
      ...values,
      shouldReceiveNewsletter: signupData.lead?.optIn,
    } as SignupFormValues
    appendValues(data)

    if (!signupData.lead) {
      return
    }
    if (signupData.lead.type.id === AccountType.Individual) {
      await apiClient.createClient(denormalize(signupData.lead, data))
      await signInAndMoveToFinancialInfo(data)
    } else {
      appendValues(data)
      navigate('/sign-up/company-details/')
    }
  }

  switch (subStep) {
    case 1:
      return (
        <PersonalDetailsStep1Page
          step={subStep}
          totalSteps={totalSteps}
          onSubmit={nextStep}
          goBack={() => navigate('/sign-up')}
        />
      )
    case 2:
      return (
        <PersonalDetailsStep2Page
          step={subStep}
          totalSteps={totalSteps}
          onSubmit={nextStep}
          goBack={goBack}
        />
      )
    case 3:
      return (
        <PersonalDetailsStep3Page
          step={subStep}
          setErrorMessage={setError}
          totalSteps={totalSteps}
          onSubmit={nextStep}
          goBack={goBack}
        />
      )
    case 4:
      if (signupData.lead) {
        return (
          <PersonalDetailsStep4Page
            step={subStep}
            totalSteps={totalSteps}
            onSubmit={nextStep}
            goBack={goBack}
          />
        )
      }
      if (leadId) {
        return <VerifyEmailPage />
      }
      return (
        <LeadCreationPage step={subStep} error={error} totalSteps={totalSteps} goBack={goBack} />
      )
    case 5:
      return (
        <PersonalDetailsStep5Page
          step={subStep}
          totalSteps={totalSteps}
          onSubmit={totalSteps < 6 ? handleExpeditSubmit : nextStep}
          goBack={goBack}
        />
      )
    case 6:
      return (
        <PersonalDetailsStep6Page
          totalSteps={totalSteps}
          goBack={goBack}
          step={subStep}
          onStepChange={setSubStep}
          onSubmit={handleSubmit}
        />
      )
  }
}
