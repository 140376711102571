import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../../../../global/button/Button'
import { useSessionEntity } from '../../../../global/context/EntityContext'
import { FileData } from '../../../../global/fileUpload/FileUpload'
import { CancelActionModal } from '../../../../global/modal/CancleActionModal'
import { Modal } from '../../../../global/modal/Modal'
import { FormTemplate } from '../../../../global/templates/FormTemplate'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'
import { ClientNciPriorityDto } from '../../../../model/ClientNciPriorityDto'
import { ClientNciHistoryStatus } from '../../../../model/CreateNciHistory'
import { DocumentCategoryType } from '../../../../model/DocumentCategories'
import { NameDto } from '../../../../model/NameDto'
import { DocumentChooseAnotherModal } from '../../../../pages/Signup/Pages/DocumentsInfo/DocumentInfoPageStep1/DocumentChooseAnotherModal'
import { DocumentReasonModal } from '../../../../pages/Signup/Pages/DocumentsInfo/DocumentInfoPageStep1/DocumentReasonModal'
import { Text } from '../../../../ui/Typography/Typography'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../../../utils/companyName.utils'
import { isDoubleSidedDocument } from '../../../../utils/uploadDocument'
import { useFetchOne } from '../../../../utils/useFetch'
import { scrollToTop } from '../../../../utils/useScrollToTop'
import { removeEmojis } from '../../../../utils/validations'
import { DocumentFileUploadsFactory } from '../../DocumentFileUploadsFactory'
import { DocumentNumberFieldFactory } from './DocumentNumberFieldFactory'

import styles from './DocumentManagementNCIForm.module.scss'

export interface DocumentManagementNCIFormValues {
  nciHistory: DocumentManagementNciHistory
  documents: DocumentManagementDocument[]
  reason?: string
  accountNciPriorities: ClientNciPriorityDto[]
  accountNciAvailablePriorities: ClientNciPriority
}

interface DocumentManagementNciHistory {
  statusId: number
  nciPriorityId: number
  documentTypeId: number
  nciDocumentNumber: string
  countryId: number
}

interface DocumentManagementDocument {
  categoryId: number
  typeId: number
  file: string
  filename: string
  error: boolean
}

interface ClientNciPriority extends Omit<ClientNciPriorityDto, 'nciRule' | 'documentTypes'> {
  nciRule: {
    id: string
    name: string
    shortName: string
    nciType: string
  }
  documentTypes: {
    name: string
    documentTypeId: number
  }[]
}

export const DocumentManagementNCIFormUI: React.FC<
  FormikProps<DocumentManagementNCIFormValues> & OuterProps
> = (props) => {
  const { values, onCancel, documentCategory, handleChooseAnotherDoc } = props

  const { t } = useTranslation()
  const [isPageExitConfirmation, setPageExitConfirmation] = useState(false)

  const handlePageExitConfirmation = () => {
    onCancel()
  }

  const handleExitPageConfirmationModalOpen = () => {
    setPageExitConfirmation(true)
  }

  const handleExitPageConfirmationModalClose = () => {
    setPageExitConfirmation(false)
    scrollToTop()
  }

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.documents])

  return (
    <FormTemplate title={t('Sign up.Add Document')} goBack={handleExitPageConfirmationModalOpen}>
      {isPageExitConfirmation && (
        <Modal
          closeModal={handleExitPageConfirmationModalClose}
          render={() => (
            <CancelActionModal
              children={t('Do you really like to cancel document adding process')}
              title={t('Cancel Adding Document')}
              cancelLabel={t('Back to Action')}
              confirmLabel={t('Yes, Cancel')}
              onConfirm={handlePageExitConfirmation}
              onCancel={handleExitPageConfirmationModalClose}
            />
          )}
        />
      )}
      <Form>
        <FormFields
          documentTypeId={values.nciHistory.documentTypeId}
          documentCategory={documentCategory.id}
          onCancel={handleExitPageConfirmationModalOpen}
          handleChooseAnotherDoc={handleChooseAnotherDoc}
        />
      </Form>
    </FormTemplate>
  )
}

interface FormFieldsFactoryProps {
  documentTypeId: number
  documentCategory: number
  handleChooseAnotherDoc(doc: ClientNciPriorityDto): void
  onCancel(): void
}

const FormFields: React.FC<FormFieldsFactoryProps> = (props) => {
  const { t } = useTranslation()
  const { documentTypeId, documentCategory, onCancel, handleChooseAnotherDoc } = props

  const apiClient = useApiClient(ClientApiClient)
  const { values, isValid, setValues, setFieldValue } =
    useFormikContext<DocumentManagementNCIFormValues>()
  const [isChooseAnotherModalOpen, setChooseAnotherModalOpen] = useState(false)

  const [isReasonModalOpen, setReasonModalOpen] = useState(false)
  const [needFileUploadRefresh, setNeedFileUploadRefresh] = useState(false)
  const [showChooseAnotherDoc, setShowChooseAnotherDoc] = useState(false)
  const [documentOptions, setDocumentOptions] = useState<ClientNciPriorityDto[]>([])
  const [goBackDocumentOptions, setGoBackDocumentOptions] = useState<ClientNciPriorityDto[]>([])

  const documentTypesCallback = useCallback(async () => {
    return apiClient.getAccountNciPriorities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: documents } = useFetchOne(documentTypesCallback)

  const handleChooseAnotherDocument = () => {
    const isFinal = values.accountNciAvailablePriorities.final
    if (isFinal && documentOptions.length) {
      if (documentOptions.length > 2) {
        setChooseAnotherModalOpen(true)
      } else {
        handleChooseAnotherDoc(documentOptions[0])
      }
    } else {
      setReasonModalOpen(true)
    }
  }

  const handleSubmitReason = async (reason?: string) => {
    if (reason) {
      await apiClient.accountAcceptNciHistory({
        statusId: ClientNciHistoryStatus.INACTIVE,
        nciPriorityId: values.accountNciAvailablePriorities?.id || '',
        reason,
      })
      setReasonModalOpen(false)
      handleChooseAnotherDoc(
        documentOptions[
          documentOptions.findIndex((doc) => doc.id === values.accountNciAvailablePriorities.id) + 1
        ]
      )
    }
  }

  useEffect(() => {
    setFieldValue('nciHistory.nciDocumentNumber', removeEmojis(values.nciHistory.nciDocumentNumber))
  }, [setFieldValue, values.nciHistory.nciDocumentNumber])

  useEffect(() => {
    if (documents) {
      setGoBackDocumentOptions(documents.filter((doc) => !doc.final))
    }
    if (documents) {
      setDocumentOptions(documents)
    }
  }, [documents])

  useEffect(() => {
    setFieldValue('documents', [])
    setNeedFileUploadRefresh((prevState) => !prevState)
  }, [setFieldValue, values.nciHistory.documentTypeId])

  const handleDocumentUploads = (fileData: FileData[]) => {
    setValues({
      ...values,
      documents: fileData.map((x) => ({
        ...x,
        categoryId: DocumentCategoryType.Personal,
        typeId: values.nciHistory.documentTypeId,
        filename: x.fileName,
        file: x.base64Content,
      })),
    })
  }

  const handleDocumentTypeChange = (doc: ClientNciPriorityDto) => {
    handleChooseAnotherDoc(doc)
    setChooseAnotherModalOpen(false)
  }

  const isEUEntity = useSessionEntity() === TickmillCompaniesEnum.TICKMILL_EU

  useEffect(() => {
    if (goBackDocumentOptions.length) {
      setShowChooseAnotherDoc(true)
    } else {
      setShowChooseAnotherDoc(false)
    }
  }, [values.accountNciAvailablePriorities.final, goBackDocumentOptions])

  return (
    <React.Fragment>
      {isReasonModalOpen && (
        <DocumentReasonModal
          onSubmit={handleSubmitReason}
          onCancel={() => setReasonModalOpen(false)}
          documentType={values.accountNciAvailablePriorities.nciRule.shortName || ''}
        />
      )}
      {isChooseAnotherModalOpen && (
        <DocumentChooseAnotherModal
          documentOptions={goBackDocumentOptions}
          onCancel={() => setChooseAnotherModalOpen(false)}
          onConfirm={(doc: ClientNciPriorityDto) => handleDocumentTypeChange(doc)}
        />
      )}
      <div className='pb-2'>
        <Text className='pb-5'>
          {t('Sign up.Please be informed that your Primary Nationality is')}{' '}
          {values.accountNciAvailablePriorities.country.name}.{' '}
          {t('Sign up.If you have any reservations, please contact', {
            entity: isEUEntity ? 'eu' : 'co.uk',
          })}
        </Text>
        <Text isParagraph>
          {t('Sign up.In accordance with the new regulations of the Markets in', {
            entity: isEUEntity ? t('Europe') : t('UK'),
          })}
        </Text>
      </div>

      <div className='pb-5'>
        <DocumentNumberFieldFactory
          showChooseAnotherDoc={showChooseAnotherDoc}
          handleChooseAnotherDocument={handleChooseAnotherDocument}
        />
      </div>
      <div className='pb-5'>
        <div className='pb-3'>
          <h4 className={styles.textStrong}>{t('Sign up.Proof of ID-Document')}</h4>
        </div>
        <div className='pb-4'>
          <Text isParagraph>
            {t('Sign up.Please upload an identification document displaying your full name')}
          </Text>
        </div>
        <div>
          <div className='pb-3'>
            <DocumentFileUploadsFactory
              documentTypeId={documentTypeId}
              documentCategory={documentCategory}
              onChange={handleDocumentUploads}
              needFileUploadRefresh={needFileUploadRefresh}
            />
          </div>
        </div>
      </div>
      <TransactionFormActionButtonTemplate>
        <Button appearance='secondary' size='L' type='button' onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button appearance='primary' size='L' type='submit' disabled={!isValid}>
          {t('Confirm')}
        </Button>
      </TransactionFormActionButtonTemplate>
    </React.Fragment>
  )
}

interface OuterProps {
  documentCategory: NameDto
  documentCategoryType: NameDto
  accountNciPriorities: ClientNciPriorityDto[]
  accountNciAvailablePriorities: ClientNciPriorityDto
  onSubmit(values: DocumentManagementNCIFormValues): void
  onCancel(): void
  handleChooseAnotherDoc(doc: ClientNciPriorityDto): void
}

interface StateProps {
  documentCategory: NameDto
  documentCategoryType: NameDto
  accountNciPriorities: ClientNciPriorityDto[]
  accountNciAvailablePriorities: ClientNciPriorityDto
}

const initialState = (props: StateProps): DocumentManagementNCIFormValues => {
  const {
    documentCategory,
    documentCategoryType,
    accountNciPriorities,
    accountNciAvailablePriorities,
  } = props || {}
  const [documentType] = accountNciAvailablePriorities.documentTypes

  return {
    nciHistory: {
      statusId: 0,
      nciPriorityId: 0,
      documentTypeId: documentCategoryType?.id || documentType?.documentTypeId || 0,
      nciDocumentNumber: '',
      countryId: documentCategory?.id || 0,
    },
    documents: [],
    accountNciPriorities,
    accountNciAvailablePriorities: {
      ...accountNciAvailablePriorities,
      nciRule: {
        id: accountNciAvailablePriorities.nciRule.id,
        name: accountNciAvailablePriorities.nciRule.name || '',
        shortName: accountNciAvailablePriorities.nciRule.shortName || '',
        nciType: accountNciAvailablePriorities.nciRule.nciType || '',
      },
      documentTypes: accountNciAvailablePriorities.documentTypes.map((x) => ({
        name: x.name || '',
        documentTypeId: x.documentTypeId,
      })),
    },
  }
}

export const DocumentManagementNCIForm = withFormik<OuterProps, DocumentManagementNCIFormValues>({
  mapPropsToValues: (props) => {
    return initialState(props)
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, props) => {
    const errors: FormikErrors<DocumentManagementNCIFormValues> = {}

    if (!values.nciHistory.nciDocumentNumber) {
      errors.documents = t('Validation.Required')
    }
    const documents = values.documents.filter((x) => !!x?.filename).filter((x) => !x.error)

    const doubleSidedDocumentSize = isDoubleSidedDocument(
      props.documentCategory?.id,
      Number(values.nciHistory.documentTypeId)
    )
      ? 1
      : 0

    if (documents.length <= doubleSidedDocumentSize) {
      errors.documents = t('Validation.Required')
    }

    if (values.documents.some((doc) => doc.error)) {
      errors.documents = t('Validation.Required')
    }

    return errors
  },
  enableReinitialize: true,
  validateOnMount: true,
})(DocumentManagementNCIFormUI)
