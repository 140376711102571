import { Dispatch, ReactNode, createContext, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { getTickmillCompanyByHostname } from '../../utils/companyName.utils'
import { dispatchEntitySelected } from '../../utils/cookie.utils'
import { Locale, OverrideLocaleForZHLanguage, storeLocaleClientSide } from '../locale/Locale'
import { SessionSettings } from '../settings/SessionSettings'
import {
  AllThemes,
  Theme,
  applyThemeClientSide,
  defaultTheme,
  detectThemeClientSide,
  storeThemeClientSide,
} from '../theme/Theme'
import { useGuardedContext } from '../useGuardedContext'

type SessionSettingsContextType = [SessionSettings, Dispatch<Partial<SessionSettings>>] | undefined

const SessionSettingsContext = createContext<SessionSettingsContextType>(undefined)
SessionSettingsContext.displayName = 'SessionSettingsContext'

export const getEffectiveTheme = (theme: Theme): Exclude<Theme, 'auto'> => {
  if (theme !== 'auto') {
    return theme
  }

  const dataTheme = document.body.getAttribute('data-theme') as Exclude<Theme, 'auto'>
  if (AllThemes.includes(dataTheme)) {
    return dataTheme
  }

  return defaultTheme
}

export function useArabicSessionLanguage(): boolean {
  const [settings] = useSessionSettings()
  return settings.locale === 'ar'
}

export function useSessionSettings(): [SessionSettings, Dispatch<Partial<SessionSettings>>] {
  return useGuardedContext(SessionSettingsContext)
}

export function useSessionTheme(): Theme {
  const [settings] = useSessionSettings()
  return settings.theme
}

export function useSessionLanguage(): Locale {
  const [settings] = useSessionSettings()
  return settings.locale
}

export function overrideForZHToCNLanguage(
  lang: Locale | OverrideLocaleForZHLanguage
): OverrideLocaleForZHLanguage {
  if (lang === 'zh' || lang === 'cn') {
    return 'cn'
  }
  return lang
}

export function useOverrideLocaleForENorTR(lang: Locale): boolean {
  return lang === 'en' || lang === 'tr'
}

interface Props {
  initialSettings: SessionSettings
  children: ReactNode
  applyTheme?: boolean
}

export const SessionSettingsContextProvider: React.FC<Props> = ({
  initialSettings,
  children,
  applyTheme = false,
}) => {
  const { i18n } = useTranslation()

  const { refreshAccount } = useAccountWriteContext()
  const { account } = useAccountReadContext()

  const [settings, _setSettings] = useState(initialSettings)
  useEffect(() => {
    const detectedTheme = detectThemeClientSide()
    applyThemeClientSide(detectedTheme, applyTheme)
    _setSettings((settings) => ({ ...settings, theme: detectedTheme }))
  }, [applyTheme]) // eslint-disable-line react-hooks/exhaustive-deps

  const setSettings = useCallback(
    (newSettings: Partial<SessionSettings>) => {
      const { locale, theme } = newSettings

      if (locale && locale !== settings.locale) {
        storeLocaleClientSide(i18n, locale)
        if (account) {
          refreshAccount(locale)
        }
        dispatchEntitySelected(
          account?.tickmillCompany.id || getTickmillCompanyByHostname(),
          locale,
          account?.emails[0]?.address || '',
          account?.firstname
        )
      }
      if (theme && theme !== settings.theme) {
        storeThemeClientSide(theme, applyTheme)
      }

      _setSettings({ ...settings, ...newSettings })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings, i18n, applyTheme]
  )

  return (
    <SessionSettingsContext.Provider value={[settings, setSettings]}>
      {children}
    </SessionSettingsContext.Provider>
  )
}
