import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import * as XLSX from 'xlsx'

import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import IconButton from '../../global/iconButton/IconButton'
import { ExportModal } from '../../global/modal/ExportModal'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { SortHeaderAlignType } from '../../global/sortHeader/SortHeader'
import { NoNilField } from '../../global/types/DeepRequired'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollToElementIds,
} from '../../hooks/useScrollToElementIds'
import { InfoIcon } from '../../icons/InfoIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import {
  isCampaignCalculationNumberOfClosedTradesType,
  isCampaignCalculationNumberOfProfitableTradesType,
  isCampaignCalculationPnlUsdType,
  isCampaignCalculationRoiPercentageType,
} from '../../model/CampaignCalculationType'
import { ClientRebateCampaignDto } from '../../model/CampaignResultDto'
import { ClientCampaignRebateResultDto } from '../../model/ClientCampaignRebateResultDto'
import {
  ActiveTradingDaysPerCampaignDto,
  ClientContestCampaignDetailDto,
  isCampaignBusinessRuleMaxDrawdown,
  isCampaignBusinessRuleMinActiveTradingDaysPerCampaign,
} from '../../model/ClientContestCampaignDetailDto'
import { ClientContestCampaignResultDto } from '../../model/ClientContestCampaignResultDto'
import { TooltipDirection, Tooltip as TooltipView } from '../../ui/Popups/Tooltip/Tooltip'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextLarge } from '../../ui/Typography/Typography'
import { PageQuery, SortOrder, initialPageData, useApiClient } from '../../utils/ApiClient'
import { useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { IAppendableFetchResult, useFetchAppendablePage, useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { CampaignContestCards } from './CampaignContestCards'
import { CampaignContestTable } from './CampaignContestTable'

import styles from './CampaignContestPage.module.scss'

const initialQuery = {}

interface Props {
  campaign: ClientRebateCampaignDto
  onGoBack(): void
}

interface GenerateDocumentFileProps {
  query: IAppendableFetchResult<ClientContestCampaignResultDto[]>
  campaign: ClientRebateCampaignDto
}

const useGenerateDocumentFile = (props: GenerateDocumentFileProps) => {
  const { query, campaign } = props

  const { t } = useTranslation()

  const exportFilename = 'campaigns_rebate_report'

  const [isOptionsModalOpen, setOptionsModalOpen] = useState<boolean>(false)

  const handleGenerateExcel = () => {
    const table = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(table)

    XLSX.utils.book_append_sheet(wb, ws, exportFilename)
    XLSX.writeFile(wb, `${exportFilename}.xlsx`)

    setOptionsModalOpen(false)
  }

  const handleGeneratePDF = () => {
    const data = tableBody()
    generatePDFTable({
      data,
      title: `Campaigns - ${campaign.name}`,
      fileName: exportFilename,
    })
    setOptionsModalOpen(false)
  }

  const tableBody = () => {
    const headerCsvData = [
      [t('Campaigns.Ranking'), t('Campaigns.Account Number'), t('Campaigns.ROI')],
    ]
    return query.data.reduce((previousValue: string[][] = [], currentValue) => {
      return previousValue.concat([
        [
          !isDisqualifiedRanking(currentValue.ranking) ? currentValue.ranking.toString() : 'N/A',
          currentValue.name || '',
          `${currentValue.points}%`.toString(),
        ],
      ])
    }, headerCsvData)
  }

  return {
    handleGenerateExcel,
    handleGeneratePDF,
    tableBody,
    isOptionsModalOpen,
    setOptionsModalOpen,
    exportFilename,
  }
}

interface CampaignResultsFetchProps {
  search: SearchResponseProps
  campaign: ClientRebateCampaignDto
}

const useCampaignFetch = (props: CampaignResultsFetchProps) => {
  const { campaign, search } = props

  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  const { callback: campaignCallback } = useCallbackWithForceRefresh(
    async () => {
      if (campaign?.account) {
        return apiClient.getTradingAccountContestCampaignById(campaign.account, campaign.id)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, campaign?.account]
  )
  const campaignData = useFetchOne(campaignCallback)

  const { callback: daysCallback } = useCallbackWithForceRefresh(
    async () => {
      if (campaign?.account) {
        return apiClient.getTradingAccountContestCampaignDays(campaign.account, campaign.id)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, campaign?.account]
  )
  const days = useFetchOne(daysCallback)

  const { callback: resultsCallback } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      if (!campaign?.account) {
        return Promise.resolve(initialPageData)
      }

      return apiClient.getContestCampaignResults(campaign.account, campaign.id, {
        ...query,
        ...search.pageQuery,
        search: search.search,
        caller: 'ca',
        languageId: locale,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search.search, search.pageQuery, campaign?.account, locale]
  )
  const results = useFetchAppendablePage(resultsCallback)

  return { campaignData, days, results }
}

interface SearchResponseProps {
  clearSearch(callback: () => void): void
  search: FilterQueryProps
  setSearch(value: FilterQueryProps): void
  pageQuery: PageQuery | undefined
  setPageQuery(value: PageQuery): void
}

const useSearch = (props: SortResponseProps): SearchResponseProps => {
  const { sort, sortOrder } = props

  const location = useLocation()
  const { clearFilter } = useDateFilterWriteContext()

  const [search, setSearch] = useState<FilterQueryProps>(initialQuery)
  const [pageQuery, setPageQuery] = useState<PageQuery>({ sort, sortOrder })

  useEffect(() => {
    setSearch(initialQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const clearSearch = (callback: () => void) => {
    callback()
    setSearch(initialQuery)
    clearFilter()
  }

  return {
    clearSearch,
    search,
    setSearch,
    pageQuery,
    setPageQuery,
  }
}

interface SortResponseProps {
  getCampaignContestPointsValue(): string
  getCampaignContestPointsCurrentValue(): string
  getSortName(): string | undefined
  closeSortModal(): void
  isSortModalOpen: boolean
  setSort(value: string): void
  sort: string | undefined
  sortOrder: SortOrder | undefined
  setSortOrder(value: SortOrder): void
  setSortModalOpen(value: boolean): void
  sortOptions: SortOptionsType
}

type SortOptionsType = { id: string; name: string; align: SortHeaderAlignType }[]

interface SortProps {
  campaign: ClientRebateCampaignDto
}

const useSort = (props: SortProps): SortResponseProps => {
  const { campaign } = props

  const { t } = useTranslation()

  const [sort, setSort] = useState<string>()
  const [sortOrder, setSortOrder] = useState<SortOrder>()
  const [isSortModalOpen, setSortModalOpen] = useState(false)

  const getCampaignContestPointsValue = () => {
    if (isCampaignCalculationRoiPercentageType(campaign.calculationType.id)) {
      return t('Campaigns.ROI')
    }

    if (isCampaignCalculationNumberOfProfitableTradesType(campaign.calculationType.id)) {
      return t('Campaigns.Number of profitable trades')
    }

    if (isCampaignCalculationNumberOfClosedTradesType(campaign.calculationType.id)) {
      return t('Campaigns.Number of closed trades')
    }

    if (isCampaignCalculationPnlUsdType(campaign.calculationType.id)) {
      return t('Campaigns.PnL')
    }

    return campaign.calculationType.name || ''
  }

  const getCampaignContestPointsCurrentValue = () => {
    if (isCampaignCalculationRoiPercentageType(campaign.calculationType.id)) {
      return t('Campaigns.Current ROI')
    }

    if (isCampaignCalculationNumberOfProfitableTradesType(campaign.calculationType.id)) {
      return t('Campaigns.Number of profitable trades')
    }

    if (isCampaignCalculationNumberOfClosedTradesType(campaign.calculationType.id)) {
      return t('Campaigns.Number of closed trades')
    }

    if (isCampaignCalculationPnlUsdType(campaign.calculationType.id)) {
      return t('Campaigns.Current PnL')
    }

    return campaign.calculationType.name || ''
  }

  const sortOptions: SortOptionsType = [
    {
      id: 'Rank ',
      name: t('Campaigns.Ranking'),
      align: 'left',
    },
    {
      id: 'ClientTradingAccount.Name',
      name: t('Campaigns.Account Number'),
      align: 'left',
    },
    {
      id: 'ContestPoints',
      name: getCampaignContestPointsValue(),
      align: 'left',
    },
  ]

  const getSortName = () => sortOptions.find((x) => x.id === sort)?.name

  const closeSortModal = () => setSortModalOpen(false)

  return {
    getSortName,
    closeSortModal,
    isSortModalOpen,
    setSort,
    sort,
    sortOrder,
    setSortOrder,
    setSortModalOpen,
    sortOptions: sortOptions || [],
    getCampaignContestPointsValue,
    getCampaignContestPointsCurrentValue,
  }
}

export const CampaignContestPage: React.FC<Props> = (props) => {
  const { campaign, onGoBack } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()

  const sort = useSort({ campaign })
  const search = useSearch(sort)
  const campaignDetails = useCampaignFetch({ search, campaign })
  const { formatMoney } = useFormatNumber()

  const query = campaignDetails.results
  const { meta, pageQuery, setPageQuery } = query

  const generateDocumentFile = useGenerateDocumentFile({ query, campaign })

  const [isInfoModal, setInfoModal] = useState(false)

  const { scrollIntoView } = useScrollToElementIds()

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useEffect(() => {
    if (
      isPaginationEntrySelected &&
      !campaignDetails.campaignData.isLoading &&
      !campaignDetails.results.isLoading
    ) {
      scrollIntoView([ScrollToIds.CampaignResultsHeader])
      setIsPaginationEntrySelected(false)
    }
  }, [
    campaignDetails.campaignData.isLoading,
    campaignDetails.results.isLoading,
    isPaginationEntrySelected,
    scrollIntoView,
  ])

  return (
    <>
      {generateDocumentFile.isOptionsModalOpen && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              fileName={generateDocumentFile.exportFilename}
              onCloseModal={closeModal}
              csvData={generateDocumentFile.tableBody()}
              onExportToCSV={closeModal}
              onExportToPdf={generateDocumentFile.handleGeneratePDF}
              onExportToExcel={generateDocumentFile.handleGenerateExcel}
            />
          )}
          closeModal={() => generateDocumentFile.setOptionsModalOpen(false)}
        />
      )}
      {isInfoModal && (
        <Modal
          closeModal={() => setInfoModal(false)}
          render={() => (
            <InformationModal
              onCancel={() => setInfoModal(false)}
              title={t('Campaigns.Paid Out Rebate')}
              onCancelText={t('Got It')}
            >
              <p>
                {t('Campaigns.amounts under', {
                  amount: formatMoney(1, campaign.currency.id),
                })}
              </p>
            </InformationModal>
          )}
        />
      )}
      {isMobile && sort.isSortModalOpen && (
        <Modal
          closeModal={() => sort.setSortModalOpen(false)}
          render={({ closeModal }) => (
            <SortByModal
              onCancel={closeModal}
              options={sort.sortOptions}
              onConfirm={(option, sortBy) => {
                search.setPageQuery?.({
                  ...search.pageQuery,
                  sort: option,
                  sortOrder: sortBy,
                })
                closeModal()
              }}
            />
          )}
        />
      )}
      <div className='mb-4'>
        <Loading showLoadingIcon isLoading={campaignDetails.results.isLoading}>
          <Header
            campaign={campaign}
            query={campaignDetails.results}
            pageQuery={search.pageQuery}
            sort={sort}
            onGoBack={onGoBack}
            setOptionsModalOpen={() => generateDocumentFile.setOptionsModalOpen(true)}
          />
        </Loading>
      </div>
      {campaignDetails?.campaignData?.data && (
        <Loading
          showLoadingIcon
          isLoading={campaignDetails.days.isLoading || campaignDetails.campaignData.isLoading}
        >
          <CampaignsRules
            days={campaignDetails.days.data}
            campaignDetails={campaignDetails.campaignData.data}
            campaignContestPointsCurrentValue={sort.getCampaignContestPointsCurrentValue()}
          />
        </Loading>
      )}
      {campaignDetails.campaignData?.data && campaignDetails.campaignData?.data?.disqualified && (
        <CampaignsWarningMessage />
      )}
      {isMobile && campaignDetails.campaignData?.data && (
        <Loading
          isLoading={campaignDetails.campaignData.isLoading || campaignDetails.results.isLoading}
          showLoadingIcon
        >
          <CampaignContestCards
            data={campaignDetails.results.data}
            campaignDetails={campaignDetails.campaignData?.data}
            campaignContestPointsValue={sort.getCampaignContestPointsValue()}
          />
        </Loading>
      )}

      {!isMobile && campaignDetails.campaignData?.data && (
        <Loading
          showLoadingIcon
          isLoading={campaignDetails.campaignData.isLoading || campaignDetails.results.isLoading}
        >
          <CampaignContestTable
            sortOptions={sort.sortOptions}
            data={campaignDetails.results.data}
            campaignDetails={campaignDetails.campaignData?.data}
            pageQuery={search.pageQuery}
            setPageQuery={search.setPageQuery}
          />
        </Loading>
      )}
      {meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.CampaignResultsHeader}
          pageData={meta}
          isLoading={campaignDetails.campaignData.isLoading || campaignDetails.results.isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsPaginationEntrySelected(true)
            }
            return setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </>
  )
}

interface HeaderProps {
  campaign: ClientRebateCampaignDto
  query: IAppendableFetchResult<ClientCampaignRebateResultDto[]>
  pageQuery: PageQuery | undefined
  sort: SortResponseProps
  campaignDetails?: ClientContestCampaignDetailDto
  onGoBack(): void
  setOptionsModalOpen(value: boolean): void
}

const Header: React.FC<HeaderProps> = (props) => {
  useScrollToTop()
  const { campaign, query, sort, pageQuery, onGoBack, setOptionsModalOpen } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()

  if (query.hasInitialResults) {
    return (
      <>
        <PageHeader
          id={ScrollToIds.CampaignResultsHeader}
          title={campaign.name}
          darkTitle={!isMobile ? `${t('Tabs.Campaign Results')} / ` : undefined}
          backButton={onGoBack}
          optionsToggle={() => setOptionsModalOpen(true)}
          filterToggles={{
            openSortModal: () => sort.setSortModalOpen(true),
            sortLabel: normalizeSortLabel(t, sort?.sortOptions || [], pageQuery?.sort),
          }}
        />
      </>
    )
  }

  return (
    <PageHeader
      id={ScrollToIds.CampaignResultsHeader}
      title={campaign.name}
      darkTitle={!isMobile ? `${t('Tabs.Campaign Results')} / ` : undefined}
      backButton={onGoBack}
    />
  )
}

interface CampaignsRulesProps {
  days?: ActiveTradingDaysPerCampaignDto
  campaignDetails: ClientContestCampaignDetailDto
  campaignContestPointsCurrentValue: string
}

const CampaignsRules: React.FC<CampaignsRulesProps> = (props) => {
  const { campaignDetails, days, campaignContestPointsCurrentValue } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  return (
    <div className='mb-4'>
      <ul className={styles.rules}>
        <CampaignsRule
          label={t('Campaigns.My Ranking')}
          value={!isDisqualifiedRanking(campaignDetails.ranking) ? campaignDetails.ranking : 'N/A'}
          tooltip={
            <Tooltip
              label={t('Campaigns.My Ranking')}
              content={t(
                'Campaigns.Your current position in the Trading Challenge ranking. Updates hourly'
              )}
              direction={'topRight'}
            />
          }
          campaignDetails={campaignDetails}
        />
        {isMaxDrawdown(campaignDetails) && (
          <CampaignsRule
            label={t('Campaigns.Max Drawdown')}
            value={`${formatMoney(
              campaignDetails.drawdown.maxDrawdown,
              campaignDetails.tradingAccount.currencyId
            )} (${campaignDetails.drawdown.maxDrawdownPercentage}%)`}
            tooltip={
              <Tooltip
                label={t('Campaigns.Max Drawdown')}
                content={t(
                  `Campaigns.Maximum drop in equity value you can afford before you get disqualified. ${campaignDetails.drawdown.maxDrawdownPercentage}% is the maximum drawdown allowed`
                )}
              />
            }
            campaignDetails={campaignDetails}
          />
        )}
        {isCurrentROI(campaignDetails) && (
          <CampaignsRule
            label={campaignContestPointsCurrentValue}
            value={`${campaignDetails.points}%`}
            tooltip={
              <Tooltip
                label={campaignContestPointsCurrentValue}
                content={t(
                  'Campaigns.Return on Investment (ROI) is the percentage of return you made on the amount(s) you deposited in the campaign account. Updates hourly'
                )}
              />
            }
            campaignDetails={campaignDetails}
          />
        )}
        {isCurrentDrawdown(campaignDetails) && (
          <CampaignsRule
            label={t('Campaigns.Current Drawdown')}
            value={`${formatMoney(
              campaignDetails.drawdown.currentDrawdown,
              campaignDetails.tradingAccount.currencyId
            )} (${campaignDetails.drawdown.currentDrawdownPercentage}%)`}
            tooltip={
              <Tooltip
                label={t('Campaigns.Current Drawdown')}
                content={t(
                  'Campaigns.Your current drawdown, if you cross the max drawdown, you will be disqualified'
                )}
                direction={getCampaignsRulesSize(campaignDetails) === 4 ? 'topLeft' : 'top'}
              />
            }
            campaignDetails={campaignDetails}
            error={
              campaignDetails.disqualified &&
              isCampaignBusinessRuleMaxDrawdown(campaignDetails.disqualificationRule)
            }
          />
        )}
        {isActiveTradingDays(campaignDetails) && (
          <CampaignsRule
            label={t('Campaigns.Trading Days')}
            value={days?.currentTradingDays}
            tooltip={
              <Tooltip
                label={t('Campaigns.Trading Days')}
                content={t('Campaigns.Number of days in which you opened at least one trade', {
                  days: days?.minTradingDays,
                })}
                direction={'topLeft'}
              />
            }
            campaignDetails={campaignDetails}
            error={
              campaignDetails.disqualified &&
              isCampaignBusinessRuleMinActiveTradingDaysPerCampaign(
                campaignDetails.disqualificationRule
              )
            }
          />
        )}
      </ul>
    </div>
  )
}

interface CampaignsRuleProps {
  label: string
  value?: string | number
  tooltip: React.ReactNode
  campaignDetails: ClientContestCampaignDetailDto
  error?: boolean
  isLoading?: boolean
}

const CampaignsRule: React.FC<CampaignsRuleProps> = (props) => {
  const { tooltip, value, label, campaignDetails, isLoading, error } = props

  return (
    <li
      className={classNames(styles.ruleBlock, {
        [styles.isTwoRules]: getCampaignsRulesSize(campaignDetails) === 2,
        [styles.isThreeRules]: getCampaignsRulesSize(campaignDetails) === 3,
        [styles.isFourRules]: getCampaignsRulesSize(campaignDetails) === 4,
        [styles.isFiveRules]: getCampaignsRulesSize(campaignDetails) === 5,
      })}
    >
      <div
        className={classNames(styles.rule, {
          [styles.ruleError]: error,
        })}
      >
        <div className={styles.ruleLabel}>
          <span className='mr-2'>{label}</span>
          <span className='is-flex'>
            <IconButton>{tooltip}</IconButton>
          </span>
        </div>
        <div className={styles.ruleValue}>{value}</div>
      </div>
    </li>
  )
}

interface TooltipProps {
  label: string
  content: string
  direction?: TooltipDirection
}

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { label, content, direction = 'top' } = props

  const isMobile = useWindowResize()
  const { t } = useTranslation()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  if (isMobile) {
    return (
      <>
        {isModalOpen && (
          <Modal
            render={({ closeModal }) => (
              <InformationModal title={label} onCancel={closeModal} onCancelText={t('Got it')}>
                <p>{content}</p>
              </InformationModal>
            )}
            closeModal={() => setModalOpen(false)}
          />
        )}
        <IconButton onClick={() => setModalOpen(true)}>
          <InfoIcon />
        </IconButton>
      </>
    )
  }

  return (
    <TooltipView autoHide={2000} content={content} direction={direction}>
      <InfoIcon />
    </TooltipView>
  )
}

const CampaignsWarningMessage = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.warningMessage}>
      <span className='mr-2'>
        <WarningIcon size={18} color='error' />
      </span>
      <TextLarge isParagraph className='has-error'>
        {t("Campaigns.You've been disqualified from the competition")}
      </TextLarge>
    </div>
  )
}

const getCampaignsRulesSize = (campaignDetails: ClientContestCampaignDetailDto) => {
  return [
    true,
    isMaxDrawdown(campaignDetails),
    isCurrentROI(campaignDetails),
    isCurrentDrawdown(campaignDetails),
    isActiveTradingDays(campaignDetails),
  ].filter((x) => !!x).length
}

const isMaxDrawdown = (
  campaignDetails: ClientContestCampaignDetailDto
): campaignDetails is NoNilField<ClientContestCampaignDetailDto> => {
  return !!(
    campaignDetails?.drawdown &&
    campaignDetails?.drawdown?.maxDrawdownPercentage >= 0 &&
    campaignDetails?.tradingAccount?.currencyId
  )
}

const isCurrentROI = (
  campaignDetails: ClientContestCampaignDetailDto
): campaignDetails is NoNilField<ClientContestCampaignDetailDto> => {
  return !!campaignDetails?.calculationType
}

const isCurrentDrawdown = (
  campaignDetails: ClientContestCampaignDetailDto
): campaignDetails is NoNilField<ClientContestCampaignDetailDto> => {
  return !!(
    campaignDetails?.drawdown &&
    campaignDetails?.drawdown?.currentDrawdownPercentage >= 0 &&
    campaignDetails?.tradingAccount?.currencyId
  )
}

const isActiveTradingDays = (
  campaignDetails: ClientContestCampaignDetailDto
): campaignDetails is NoNilField<ClientContestCampaignDetailDto> => {
  return !!campaignDetails?.activeTradingDays
}

const isDisqualifiedRanking = (ranking: number) => ranking === -1
