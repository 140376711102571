import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { Paging, PagingEventType } from '../../../global/Paging/Paging'
import { InformationModal } from '../../../global/modal/InformationModal'
import { Modal } from '../../../global/modal/Modal'
import { RestrictionActionModal } from '../../../global/modal/RestrictionActionModal'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../../hooks/useScrollToElementIds'
import { AccountGroupType } from '../../../model/AccountGroupType'
import { PlatformTypeEnum } from '../../../model/PlatformTypeEnum'
import { TradingAccount } from '../../../model/TradingAccount'
import { CurrencyType } from '../../../model/WalletDto'
import { PageHeader } from '../../../ui/Table/Header/PageHeader'
import { Operator, PageQuery, useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useWindowResize } from '../../../utils/domUtils'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage } from '../../../utils/useFetch'
import { TradingAccountBalanceCard } from './TradingAccountBalanceCard'
import { TradingAccountOptionsModal } from './TradingAccountOptionsModal'
import { TradingAccountsTable } from './TradingAccountsTable'
import { MobileHeader, RightHeader, TitleHeader } from './TradingAcountPageHeaderParts'

export const TradingAccountBalances: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useWindowResize()
  const apiClient = useApiClient(ClientApiClient)

  const [TAOptionsData, setTAOptionsData] = useState<{
    tradingAccount: TradingAccount
    currency: CurrencyType
  }>()
  const [isBalanceModalOpen, setBalanceModalOpen] = useState(false)
  const [isAvailableBalanceModalOpen, setAvailableBalanceModalOpen] = useState(false)
  const [isRestrictionModalOpen, setRestrictionModalOpen] = useState(false)

  const { callback } = useCallbackWithForceRefresh(
    async (query?: PageQuery) =>
      apiClient.getTradingAccounts({
        ...query,
        search: {
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: PlatformTypeEnum.CQGCAST, // it's for ETD
            operator: Operator.EQUAL,
          },
          Status: {
            value: 1,
            operator: Operator.EQUAL,
          },
        },
      }),
    []
  )
  const {
    data: tradingAccounts,
    isLoading,
    setPageQuery,
    meta: metaActiveTAs,
  } = useFetchAppendablePage(callback)

  const tradingAccount = tradingAccounts.length > 0 ? tradingAccounts[0] : null

  const intiateTransfer = ({
    tradingAccountId,
    currency,
    balance,
  }: {
    tradingAccountId: string
    currency?: CurrencyType
    balance?: number
  }) => {
    if (Number(balance) >= 0) {
      navigate(`/dashboard/traders-room/balances/${tradingAccountId}/transfer`, {
        state: {
          currency,
        },
      })
    } else {
      navigate('/dashboard/traders-room/wallets/transfer')
    }
  }

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.TradingAccountBalancesHeader,
    isPaginationEntrySelected,
    isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(ScrollToIds.TradingAccountBalancesHeader, isLoading, metaActiveTAs?.pageSize)

  return (
    <div>
      {!!TAOptionsData && (
        <Modal
          closeModal={() => setTAOptionsData(undefined)}
          render={({ closeModal }) => (
            <TradingAccountOptionsModal
              tradingAccount={TAOptionsData.tradingAccount}
              currency={TAOptionsData.currency}
              onCancel={closeModal}
            />
          )}
        />
      )}
      {isBalanceModalOpen && (
        <Modal
          closeModal={() => setBalanceModalOpen(false)}
          render={({ closeModal }) => (
            <InformationModal
              title={t('Trading Account.Trading Accounts Balance')}
              onCancel={closeModal}
              onCancelText={t('Got It')}
            >
              <Trans
                i18nKey='Wallet.Your current balance is the total of your {{ value }} balance(s)'
                components={{
                  1: <Link title={t('Currency you selected')} to={`/profile/account-settings`} />,
                }}
                values={{ value: 'trading account' }}
              />
            </InformationModal>
          )}
        />
      )}
      {isAvailableBalanceModalOpen && (
        <Modal
          closeModal={() => setAvailableBalanceModalOpen(false)}
          render={({ closeModal }) => (
            <InformationModal
              title={t('Wallet.Withdrawable Balance')}
              onCancel={closeModal}
              onCancelText={t('Got It')}
            >
              <Trans
                i18nKey='Wallet.Your withdrawable balance is the total amount you may withdraw, including your current open-position obligations'
                components={{
                  1: <Link title={t('Currency you selected')} to={`/profile/account-settings`} />,
                }}
              />
            </InformationModal>
          )}
        />
      )}
      {isRestrictionModalOpen && (
        <Modal
          closeModal={() => setRestrictionModalOpen(false)}
          render={({ closeModal }) => (
            <RestrictionActionModal
              onCancel={closeModal}
              onConfirm={() => {
                setRestrictionModalOpen(false)
              }}
            />
          )}
        />
      )}

      <PageHeader
        id={ScrollToIds.TradingAccountBalancesHeader}
        renderSubtitle={() =>
          isMobile ? (
            <MobileHeader
              setBalanceModalOpen={setBalanceModalOpen}
              setAvailableBalanceModal={setAvailableBalanceModalOpen}
              tradingAccounts={tradingAccounts}
            />
          ) : (
            <TitleHeader
              setBalanceModalOpen={setBalanceModalOpen}
              tradingAccounts={tradingAccounts}
            />
          )
        }
        rightRender={
          !isMobile && (
            <RightHeader
              setAvailableBalanceModal={setAvailableBalanceModalOpen}
              tradingAccounts={tradingAccounts}
            />
          )
        }
      />

      {isMobile && (
        <div className='is-flex is-flex-direction-column'>
          {tradingAccount?.platformOverview.balances.map((balance, id) => (
            <TradingAccountBalanceCard
              key={id}
              initiateTransfer={() =>
                intiateTransfer({
                  tradingAccountId: tradingAccount.id,
                  currency: balance.currency,
                  balance: balance.balance,
                })
              }
              handleSetSelectedTradingAccount={() =>
                setTAOptionsData({
                  tradingAccount,
                  currency: balance.currency,
                })
              }
              balance={balance}
              restrictions={tradingAccount?.restrictions}
              setModalVisible={setRestrictionModalOpen}
            />
          ))}
          {metaActiveTAs && (
            <Paging
              scrollToHeaderId={ScrollToIds.TradingAccountBalancesHeader}
              pageData={metaActiveTAs}
              isLoading={isLoading}
              onPageChanged={(pageIndex, pageSize, pagingEventType) => {
                if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
                  setIsPaginationEntrySelected(true)
                }
                setPageQuery!({
                  pageIndex,
                  pageSize,
                })
              }}
            />
          )}
        </div>
      )}

      {tradingAccount && (
        <TradingAccountsTable
          initiateTransfer={(currency) =>
            intiateTransfer({
              tradingAccountId: tradingAccount.id,
              currency,
              balance: tradingAccount.platformOverview.balances.find((b) => b.currency === currency)
                ?.balance,
            })
          }
          balances={tradingAccount?.platformOverview.balances}
          restrictions={tradingAccount?.restrictions}
          setModalVisible={setRestrictionModalOpen}
          isLoading={isLoading}
          handleSetSelectedTradingAccount={(currency) =>
            setTAOptionsData({ tradingAccount, currency })
          }
        />
      )}
    </div>
  )
}
