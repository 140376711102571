import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import * as XLSX from 'xlsx'

import { TransactionStatusHistoryModal } from '../../Traders-Room/Transaction-History/TransactionStatusHistoryModal'
import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { useProductReadContext } from '../../global/context/ProductContext'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../../global/context/SessionSettingsContext'
import { SelectableModal } from '../../global/field/SelectableModal'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { ConfirmationModal } from '../../global/modal/ConfirmationModal'
import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../hooks/useScrollToElementIds'
import { CloseIcon } from '../../icons/CloseIcon'
import { TransactionDto } from '../../model/TransactionDto'
import { WalletTypeEnum, isWalletETDType, isWalletFX_CFDType } from '../../model/WalletDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { PageQuery, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { SharedContext } from '../../utils/SharedContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { getAppliedFiltersLength, prefixFilter, setQuery } from '../../utils/filter.utils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { getScrollToCardId } from '../../utils/getItemId'
import { filterObjectByKeys } from '../../utils/object-filter'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import {
  getIBTransactionFieldsByTransactionType,
  getTransactionType,
} from '../../utils/transaction.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage, useFetchOne } from '../../utils/useFetch'
import { scrollToTop, useScrollToTop } from '../../utils/useScrollToTop'
import { isOne } from '../../utils/validations'
import { IntroducingBrokerTransactionFilterModal } from './IntroducingBrokerTransactionFilterModal'
import { IntroducingBrokerTransactionHistoryCard } from './IntroducingBrokerTransactionHistoryCard'
import { IntroducingBrokerTransactionHistoryTable } from './IntroducingBrokerTransactionHistoryTable'

import styles from './IntroducingBrokerTransactionHistoryPage.module.scss'

interface SearchProps extends FilterQueryProps {
  ca_search_ClientAmountFrom?: string
  ca_search_ClientAmountTo?: string
}

export const IntroducingBrokerTransactionHistoryPage: React.FC<{
  walletId?: string
  tradingAccountId?: string
}> = () => {
  useScrollToTop()
  const location = useLocation()
  const isArabic = useArabicSessionLanguage()
  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const isMobile = useWindowResize()
  const dateFormat = auth?.dateFormatType?.name
  const { formatNumber } = useFormatNumber()

  const sortOptions = [
    {
      id: 'TransactionType.name',
      name: t('Transactions.Transaction Type'),
    },
    { id: 'CreatedDate', name: t('Created Date') },
    {
      id: 'TransactionState.name',
      name: t('Transactions.Transaction State'),
    },
  ]

  const [historyModal, setHistoryModal] = useState<boolean>(false)
  const [filterModal, setFilterModalOpen] = useState<boolean>(false)
  const [sortModalOpen, setSortModalOpen] = useState<boolean>(false)
  const [optionsModal, setOptionsModal] = useState<boolean>(false)

  // TODO in free time create context for QUERY
  const initialQuery = {
    TransactionStateId: '',
    TransactionTypeId: '',
    ca_search_ClientAmountFrom: '',
    ca_search_ClientAmountTo: '',
    DateCreated: undefined,
    DateTo: undefined,
    DateFrom: undefined,
  }

  const [search, setSearch] = useState<SearchProps>(initialQuery)
  const [searchTerm, showSearchTerm] = useState<SearchTermState>({
    show: false,
    searchTerm: undefined,
  })
  const [optionModal, setOptionModal] = useState<{
    step: number
    visible: boolean
    data?: TransactionDto
  }>({ step: 1, visible: false })

  const [selectedTransaction, setSelectedTransaction] = useState('')
  const { walletId, tradingAccountId } = location.state || {}
  const apiClient = useApiClient(ClientApiClient)
  const [sharedState, setSharedState] = useContext(SharedContext)
  const { account } = useAccountReadContext()
  const { isDefaultCFDProductType, product } = useProductReadContext()

  const initialSearchValue = {
    TransactionStateId: '',
    TransactionTypeId: '',
    DateCreated: undefined,
    DateTo: undefined,
    DateFrom: undefined,
  }
  useEffect(() => {
    setSearch(initialQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletId, tradingAccountId])

  const locale = useSessionLanguage()
  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      const { ca_search_ClientAmountFrom, ca_search_ClientAmountTo, ...clearedSearch } = search

      return await apiClient.getTransactions({
        ...query,
        search: { ...clearedSearch },
        caller: 'ca',
        ca_search_ClientAmountFrom: prefixFilter({
          prefix: account?.id,
          value: ca_search_ClientAmountFrom,
        }),
        ca_search_ClientAmountTo: prefixFilter({
          prefix: account?.id,
          value: ca_search_ClientAmountTo,
        }),
        ca_search_ClientWalletType: prefixFilter({
          prefix: account?.id,
          value: WalletTypeEnum.IB.toString(),
        }),
        ca_search_WalletId: walletId || search?.WalletId || '',
        ca_search_TaId: tradingAccountId || '',
        languageId: locale,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, locale]
  )

  const callbackForFilters = useCallback(
    async () => {
      if (!account?.id) {
        return
      }
      const clientWalletType = prefixFilter({
        prefix: account.id,
        value: WalletTypeEnum.IB.toString(),
      })
      if (!clientWalletType) {
        return
      }
      return await apiClient.getTransactionHistoryFilterOptions(clientWalletType, locale)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )

  const { data: filterOptions } = useFetchOne(callbackForFilters)

  const { data, meta, setPageQuery, pageQuery, isLoading, hasInitialResults } =
    useFetchAppendablePage(callback)

  const onCancelTransaction = async (transaction: TransactionDto) => {
    await apiClient.cancelTransaction(transaction.id)
    setOptionModal({ data: undefined, visible: false, step: 1 })
    forceRefresh()
  }

  const onGenerateExcel = () => {
    const data = prepareCSVData()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(data)

    XLSX.utils.book_append_sheet(wb, ws, 'transactions_report')

    XLSX.writeFile(wb, 'transactions_report.xlsx')
  }

  const onGeneratePDF = () => {
    const data = prepareCSVData()
    generatePDFTable({ data, title: t('Wallet.Transaction History') })
    setOptionsModal(false)
  }

  const prepareCSVData = () => {
    const headerCsvData = [
      [
        t('Type'),
        t('Date'),
        t('From'),
        t('To'),
        t('Wallet.Reference number'),
        t('Status'),
        t('Amount'),
        t('IB.Transaction exchange rate'),
      ],
    ]
    return data.reduce((previousValue, currentValue) => {
      const transactionType = getTransactionType(currentValue.type.id)
      const fields = getIBTransactionFieldsByTransactionType(
        transactionType,
        currentValue,
        isArabic,
        auth?.id
      )
      return previousValue.concat([
        [
          currentValue.type.name,
          formatDate(currentValue.createdDate, dateFormat),
          fields.from,
          fields.to,
          currentValue.referenceId,
          currentValue.state.name,
          formatNumber(fields.amount),
          formatNumber(fields.rate),
        ],
      ])
    }, headerCsvData)
  }

  useEffect(() => {
    ;(async () => {
      let { wallets, transactionStates } = sharedState
      if (!sharedState.wallets.length) {
        const response = await apiClient.getWallets()
        wallets = response
          .filter((x) =>
            isDefaultCFDProductType()
              ? isWalletFX_CFDType(x.walletType.id)
              : isWalletETDType(x.walletType.id)
          )
          .map((w) => ({
            id: w.id,
            name: w.name,
            currency: w.currency,
          }))
      }

      transactionStates = await apiClient.getTransactionStates(locale)
      setSharedState((currentState) => ({
        ...currentState,
        transactionStates,
        wallets,
      }))
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, product])

  const { clearFilter } = useDateFilterWriteContext()

  const clearSearch = () => {
    setPageQuery?.(undefined)
    showSearchTerm({ searchTerm: '', show: false })
    setSearch(initialSearchValue)
    clearFilter()
  }

  const activeFilters = getAppliedFiltersLength({
    ...search,
    Client: undefined,
    WalletTypeId: undefined,
  })
  useEffect(
    () => {
      const timer = setTimeout(
        () =>
          searchTerm?.searchTerm !== undefined &&
          setPageQuery!({
            ...pageQuery,
            pageIndex: 1,
            ca_search_TextSearch: searchTerm.searchTerm,
          }),
        500
      )
      return () => clearTimeout(timer)
    },
    [searchTerm?.searchTerm] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useLayoutEffect(() => {
    if (location.state?.walletId) {
      scrollToTop()
    }
  }, [location, isLoading])

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.IBTransactionHistoryHeader,
    isPaginationEntrySelected,
    isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(ScrollToIds.IBTransactionHistoryHeader, isLoading, meta?.pageSize)

  return (
    <React.Fragment>
      {historyModal && (
        <Modal
          closeModal={() => setHistoryModal(false)}
          render={({ closeModal }) => (
            <TransactionStatusHistoryModal id={selectedTransaction} onCancel={closeModal} />
          )}
        />
      )}
      {filterModal && (
        <Modal
          closeModal={() => setFilterModalOpen(false)}
          render={({ closeModal }) => (
            <IntroducingBrokerTransactionFilterModal
              transactionStates={sharedState.transactionStates}
              filterOptions={filterOptions}
              currentQuery={search}
              onConfirm={({ searchFilters = initialSearchValue, currentFilter }) => {
                let q: FilterQueryProps = {
                  ...searchFilters,
                  ...setQuery(currentFilter),
                }
                if (!currentFilter) {
                  q = filterObjectByKeys(q)
                }
                setSearch({ ...q })
                setPageQuery?.({ ...pageQuery, pageIndex: 1 })
                closeModal()
              }}
              onCancel={closeModal}
            />
          )}
        />
      )}

      {optionsModal && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              onCloseModal={closeModal}
              csvData={prepareCSVData()}
              onExportToCSV={closeModal}
              onExportToPdf={onGeneratePDF}
              onExportToExcel={onGenerateExcel}
            />
          )}
          closeModal={() => setOptionsModal(false)}
        />
      )}
      {optionModal.visible && (
        <Modal
          closeModal={() =>
            setOptionModal({
              visible: false,
              data: undefined,
              step: 1,
            })
          }
          render={({ closeModal }) => {
            if (isOne(optionModal.step)) {
              return (
                <SelectableModal
                  title={t('Transaction')}
                  onCancel={closeModal}
                  renderOptions={() => (
                    <button
                      onClick={() =>
                        setOptionModal({
                          ...optionModal,
                          step: 2,
                        })
                      }
                      className={classNames(
                        styles.errorButton,
                        'radio column is-full-desktop radio'
                      )}
                    >
                      <div className={styles.error}>
                        <CloseIcon color={'error'} /> {t('Transactions.Cancel Transaction')}
                      </div>
                    </button>
                  )}
                />
              )
            }
            return (
              <ConfirmationModal
                title={t('Transactions.Cancel Confirmation')}
                onCancel={closeModal}
                onConfirm={() => {}}
                renderFooter={() => (
                  <React.Fragment>
                    <button
                      className='button'
                      onClick={() =>
                        setOptionModal({
                          visible: false,
                          step: 1,
                          data: undefined,
                        })
                      }
                      type='button'
                    >
                      {t('Transactions.Back to Transaction History')}
                    </button>
                    <button
                      className='button'
                      onClick={() => onCancelTransaction(optionModal.data as TransactionDto)}
                      type='button'
                    >
                      {t('Transactions.Yes, Cancel Transaction')}
                    </button>
                  </React.Fragment>
                )}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('Transactions.Cancel Confirmation'),
                  }}
                />
              </ConfirmationModal>
            )
          }}
        />
      )}
      {sortModalOpen && (
        <Modal
          closeModal={() => setSortModalOpen(false)}
          render={({ closeModal }) => (
            <SortByModal
              onCancel={closeModal}
              options={sortOptions}
              onConfirm={(option, sortBy) => {
                setPageQuery?.({
                  ...pageQuery,
                  sort: option,
                  sortOrder: sortBy,
                })
                closeModal()
              }}
            />
          )}
        />
      )}
      {hasInitialResults ? (
        <PageHeader
          title={t('Wallet.Transaction History')}
          id={ScrollToIds.IBTransactionHistoryHeader}
          optionsToggle={() => setOptionsModal(true)}
          search={{
            show: searchTerm.show,
            placeholder: t('Search reference'),
            searchTerm: searchTerm.searchTerm,
            setShow: (v) => showSearchTerm({ ...searchTerm, show: v }),
            setSearchTerm: (v) => showSearchTerm({ ...searchTerm, searchTerm: v }),
          }}
          filterToggles={{
            openFilterModal: () => setFilterModalOpen(true),
            openSortModal: () => setSortModalOpen(true),
            resetFilters: clearSearch,
            activeFilters,
            sortLabel: normalizeSortLabel(t, sortOptions, pageQuery?.sort),
          }}
        />
      ) : (
        <PageHeader
          title={t('Wallet.Transaction History')}
          id={ScrollToIds.IBTransactionHistoryHeader}
        />
      )}
      <Loading showLoadingIcon isLoading={isLoading}>
        {isMobile ? (
          data.length ? (
            data.map((x, index) => (
              <IntroducingBrokerTransactionHistoryCard
                data={x}
                isLoading={isLoading}
                setPageQuery={setPageQuery}
                setSelectedTransaction={setSelectedTransaction}
                setHistoryModal={setHistoryModal}
                forceRefresh={forceRefresh}
                key={x.id}
                cardId={getScrollToCardId(index, data.length, 'ib-transaction-history')}
              />
            ))
          ) : (
            <NoResults
              subtitle={activeFilters > 0 ? t('No results') : undefined}
              hideLink={activeFilters > 0}
              link={{
                state: { wallets: true },
              }}
            />
          )
        ) : (
          <IntroducingBrokerTransactionHistoryTable
            data={data}
            isLoading={isLoading}
            setPageQuery={setPageQuery}
            pageQuery={pageQuery}
            activeFilters={activeFilters}
            setOptionModal={setOptionModal}
            setHistoryModal={setHistoryModal}
            setSelectedTransaction={setSelectedTransaction}
          />
        )}
      </Loading>
      {meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.IBTransactionHistoryHeader}
          maxPageSize={500}
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsPaginationEntrySelected(true)
            }
            setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </React.Fragment>
  )
}
