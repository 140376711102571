import React from 'react'
import classNames from 'classnames'

import { SortHeader, SortHeaderAlignType } from '../../global/sortHeader/SortHeader'
import { Table, TableNoResults } from '../../global/table/Table'
import { ClientContestCampaignDetailDto } from '../../model/ClientContestCampaignDetailDto'
import { ClientContestCampaignResultDto } from '../../model/ClientContestCampaignResultDto'
import { PageQuery } from '../../utils/ApiClient'
import { getCentralRowIdConditionally } from '../../utils/getItemId'

import styles from '../../IntroducingBroker/Contest/IBContestPage.module.scss'

interface CampaignContestTableProps {
  sortOptions: SortOptionsType
  data: ClientContestCampaignResultDto[]
  campaignDetails: ClientContestCampaignDetailDto
  pageQuery?: PageQuery
  setPageQuery(value: PageQuery): void
}

type SortOptionsType = { id: string; name: string; align: SortHeaderAlignType }[]

export const CampaignContestTable: React.FC<CampaignContestTableProps> = (props) => {
  const { sortOptions, data, campaignDetails, pageQuery, setPageQuery } = props

  return (
    <Table>
      <thead>
        <tr>
          {sortOptions.map((x) => (
            <SortHeader
              id={x.id}
              sort={pageQuery?.sort || undefined}
              sortOrder={pageQuery?.sortOrder}
              setSort={(sort, sortOrder) => {
                setPageQuery!({
                  ...pageQuery,
                  sort,
                  sortOrder,
                })
              }}
              align={x.align || 'center'}
              key={x.id}
            >
              {x.name}
            </SortHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {!data.length ? (
          <TableNoResults />
        ) : (
          data.map((roundResult, index) => (
            <CampaignRebateTableItem
              {...props}
              data={roundResult}
              campaignDetails={campaignDetails}
              key={index}
              {...getCentralRowIdConditionally(index, data.length, 'campaign-results')}
            />
          ))
        )}
      </tbody>
    </Table>
  )
}

interface CampaignRebateTableItemProps {
  data: ClientContestCampaignResultDto
  campaignDetails: ClientContestCampaignDetailDto
  id?: string
}

const CampaignRebateTableItem: React.FC<CampaignRebateTableItemProps> = (props) => {
  const { data, campaignDetails, id } = props

  return (
    <tr
      className={classNames({
        [styles.resultCurrentRanking]: data.name === campaignDetails.tradingAccount.name,
      })}
      id={id}
    >
      <td>
        <span className='is-flex is-justify-content-flex-start'>
          {!isDisqualifiedRanking(data) ? `#${data.ranking}` : 'N/A'}
        </span>
      </td>
      <td>
        <span className='is-flex is-justify-content-flex-start'>{data.name}</span>
      </td>
      <td>
        <span className='is-flex is-justify-content-flex-start'>{data.points}%</span>
      </td>
    </tr>
  )
}

const isDisqualifiedRanking = (data: ClientContestCampaignResultDto) => {
  return data.ranking === -1
}
