import { useEffect } from 'react'

import { Locale } from '../global/locale/Locale'
import { TickmillCompaniesEnum } from './companyName.utils'

export const dispatchCloseCookieBanner = (): void => {
  const event = document.createEvent('Event')
  event.initEvent('close-cookie-banner', true, true)
  document.dispatchEvent(event)
}

export const dispatchOpenChat = (): void => {
  const event = document.createEvent('Event')
  event.initEvent('open-live-chat', true, true)
  document.dispatchEvent(event)
}

export const dispatchCloseModalEvent = (): void => {
  const event = document.createEvent('Event')
  event.initEvent('close-modal', true, true)
  document.dispatchEvent(event)
}

export const dispatchOpenModalEvent = (): void => {
  const event = document.createEvent('Event')
  event.initEvent('open-modal', true, true)
  document.dispatchEvent(event)
}

export const useOnOpenChat = (onCloseBottomBanner: () => void): void => {
  useEffect(() => {
    document.addEventListener('open-live-chat', onCloseBottomBanner)

    return () => {
      document.removeEventListener('open-live-chat', onCloseBottomBanner)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const dispatchEntitySelected = (
  entity: TickmillCompaniesEnum,
  locale: Locale,
  email?: string,
  firstName?: string
): void => {
  const event = new CustomEvent('entity-selected', {
    detail: { entity, email, firstName, locale },
    bubbles: true,
    cancelable: true,
  })
  document.dispatchEvent(event)
}

export const useOnEntitySelected = (
  callback: (
    entity: TickmillCompaniesEnum,
    language: Locale,
    email?: string,
    firstName?: string
  ) => void
): void => {
  useEffect(() => {
    const handleEntitySelected: EventListener = (event) => {
      const customEvent = event as CustomEvent
      callback(
        customEvent.detail.entity,
        customEvent.detail.locale,
        customEvent.detail.email,
        customEvent.detail.firstName
      )
    }
    document.addEventListener('entity-selected', handleEntitySelected)

    return () => {
      document.removeEventListener('entity-selected', handleEntitySelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useOnCloseModal = (onCloseModal: () => void): void => {
  useEffect(() => {
    document.addEventListener('close-modal', onCloseModal)

    return () => {
      document.removeEventListener('close-modal', onCloseModal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useOnOpenModal = (onOpenModal: () => void): void => {
  useEffect(() => {
    document.addEventListener('open-modal', onOpenModal)

    return () => {
      document.removeEventListener('open-modal', onOpenModal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function getDomainName(noDot?: boolean) {
  const hostName = window.location.hostname
  const localhostDomainName = 'localhost'
  const hostNameLength = hostName.split('.').length
  const domainName = hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - hostNameLength) + 1
  )
  if (domainName === localhostDomainName) {
    return domainName
  }
  if (noDot) {
    return hostName
  }
  return '.' + domainName
}

export function getLeadReferrer(referrer: string) {
  const hostName = window.location.hostname

  if (referrer.includes(hostName)) {
    return ''
  }

  return referrer
}
