import aboutDesktop from '../images/TickmillTrader/Images/aboutDesktop.webp'
import aboutMobile from '../images/TickmillTrader/Images/aboutMobile.webp'
import buysellDesktop from '../images/TickmillTrader/Images/buysellDesktop.webp'
import buysellMobile from '../images/TickmillTrader/Images/buysellMobile.webp'
import chartDesktop from '../images/TickmillTrader/Images/chartDesktop.webp'
import chartMobile from '../images/TickmillTrader/Images/chartMobile.webp'
import managementDesktop from '../images/TickmillTrader/Images/managementDesktop.webp'
import managementMobile from '../images/TickmillTrader/Images/managementMobile.webp'
import watchlistDesktop from '../images/TickmillTrader/Images/watchlistDesktop.webp'
import watchlistMobile from '../images/TickmillTrader/Images/watchlistMobile.webp'

export interface TickmillTraderVideoData {
  imgDesktop: string
  imgMobile: string
  title: string
  text: string
  androidLink: string
  iOSLink: string
}

interface StoreData {
  appStore: { link: string }
  googlePlay: { link: string }
  apk: { link: string }
}

export const tickmillTraderVideosData: TickmillTraderVideoData[] = [
  {
    imgDesktop: aboutDesktop,
    imgMobile: aboutMobile,
    title: 'ToolsPlatforms.About the app',
    text: 'ToolsPlatforms.Keep control of your trading with easy access all in one place',
    androidLink:
      'https://www.youtube.com/watch?v=UVlWq0CTSw0&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    iOSLink: 'https://www.youtube.com/watch?v=sj8eNldEgZE',
  },
  {
    imgDesktop: managementDesktop,
    imgMobile: managementMobile,
    title: 'ToolsPlatforms.Account Management',
    text: 'ToolsPlatforms.Get easy and secure login with face recognition and fingerprint',
    androidLink: 'https://www.youtube.com/watch?v=az7CAsN25Sg',
    iOSLink:
      'https://www.youtube.com/watch?v=GhSmUMHwaN4&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
  },
  {
    imgDesktop: buysellDesktop,
    imgMobile: buysellMobile,
    title: 'ToolsPlatforms.Buy and Sell',
    text: 'ToolsPlatforms.Seamless trading with low spreads at your fingertips',
    androidLink:
      'https://www.youtube.com/watch?v=wrZIOsauEz8&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    iOSLink:
      'https://www.youtube.com/watch?v=t3Bx7zkk5Vs&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
  },
  {
    imgDesktop: watchlistDesktop,
    imgMobile: watchlistMobile,
    title: 'ToolsPlatforms.Watchlist',
    text: 'ToolsPlatforms.Create a watchlist with your favourite assets',
    androidLink:
      'https://www.youtube.com/watch?v=02gt_VrE680&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    iOSLink:
      'https://www.youtube.com/watch?v=BcpVnqyQPJI&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
  },
  {
    imgDesktop: chartDesktop,
    imgMobile: chartMobile,
    title: 'ToolsPlatforms.Chart Analysis',
    text: 'ToolsPlatforms.Navigate charts with a smooth pinch to zoom feature',
    androidLink:
      'https://www.youtube.com/watch?v=w-Mv0oA5oo0&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
    iOSLink:
      'https://www.youtube.com/watch?v=klYKgp6tsag&embeds_referring_euri=https%3A%2F%2Fstaging.tickmill.com%2Ftrading-platforms%2Ftickmill-trader&source_ve_path=Mjg2NjY&feature=emb_logo',
  },
]
