import { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { useWindowResize } from '../../../utils/domUtils'

import styles from './Grid.module.scss'

interface GridProps {
  className?: string
  mobileSameSize?: boolean
  maxCardWidth?: number
}

const useGridStyleRule = (maxCardWidth: number) => {
  const isMobile = useWindowResize()

  if (isMobile) {
    return { gridTemplateColumns: `repeat(auto-fill, minmax(${maxCardWidth * 0.6}, 1fr))` }
  }
  return { gridTemplateColumns: `repeat(auto-fill, minmax(${maxCardWidth}px, 1fr))` }
}

export const Grid: React.FC<PropsWithChildren<GridProps>> = (props) => {
  const { children, maxCardWidth = 290, className, mobileSameSize } = props

  return (
    <div
      style={useGridStyleRule(maxCardWidth)}
      className={classNames(styles.grid, className, {
        [styles.mobileResize]: !mobileSameSize,
      })}
    >
      {children}
    </div>
  )
}

interface GridItemProps {
  className?: string
}

export const GridItem: React.FC<PropsWithChildren<GridItemProps>> = (props) => {
  const { children, className } = props
  return <div className={classNames(styles.gridItem, className)}>{children}</div>
}
