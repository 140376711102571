import React from 'react'
import { useTranslation } from 'react-i18next'

import { APKQRCode } from '../../../icons/APKQRCodeIcon'
import { AppleQRCode } from '../../../icons/AppleQRCodeIcon'
import { GooglePlayQRCode } from '../../../icons/GooglePlayQRCodeIcon'
import { TextH3, TextLarge } from '../../../ui/Typography/Typography'
import { isTickmillUKType } from '../../../utils/companyName.utils'
import { useSessionEntity } from '../../context/EntityContext'

import styles from './AppMarketQRCode.module.scss'

export const AppMarketQRCode = (): JSX.Element => {
  const { t } = useTranslation()

  const entity = useSessionEntity()

  return (
    <div className={styles.appMarket}>
      <h1>{t('Download the Tickmill Mobile App')}</h1>
      <div className={styles.codeWrapper}>
        <div>
          <TextH3 className={styles.title}>{t('iOS')}</TextH3>
          <div className={styles.code}>
            <AppleQRCode />
          </div>
        </div>
        <div>
          <TextH3 className={styles.title}>{t('Android')}</TextH3>
          <div className={styles.code}>
            <GooglePlayQRCode />
          </div>
        </div>
        <div>
          <TextH3 className={styles.title}>{t('AndroidApk')}</TextH3>
          <div className={styles.code}>
            <APKQRCode />
          </div>
        </div>
      </div>
      <div>
        <TextLarge isParagraph>
          {t(
            'Download the Tickmill Mobile app to get the best trading experience right in your pocket'
          )}
        </TextLarge>
      </div>
      {isTickmillUKType(entity) && (
        <div className='mt-5'>
          <p className='text-strong'>* {t('Only available for services related to CFD')}</p>
        </div>
      )}
    </div>
  )
}
