import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TextLargeStrong } from '../../ui/Typography/Typography'
import { Button } from '../button/Button'
import { useProductReadContext } from '../context/ProductContext'

import styles from './ProductContextSwitchModal.module.scss'

interface ProductContextSwitchModalProps {
  onConfirm(persistedClose?: boolean): void
  onCancel(): void
}

export const ProductContextSwitchModal: React.FC<ProductContextSwitchModalProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation()
  const { isDefaultCFDProductType } = useProductReadContext()

  return (
    <React.Fragment>
      <header className={classNames('modal-card-head')}>
        <TextLargeStrong className={styles.headerTitle}>
          {t('Switch from', {
            to: isDefaultCFDProductType() ? 'Futures' : 'CFD',
            from: isDefaultCFDProductType() ? 'CFD' : 'Futures',
          })}
        </TextLargeStrong>
      </header>

      <div className={classNames('modal-card-body', styles.body)}>
        <div className='is-flex is-flex-direction-column'>
          {t('Are you sure you want to switch', {
            to: isDefaultCFDProductType() ? 'Futures' : 'CFD',
          })}
        </div>
        <div className='my-5'>
          {t('Note: Your funds are not transferable between Futures and CFDs Client Areas')}
        </div>
      </div>

      <div className={classNames('modal-card-foot', styles.footer)}>
        <Button appearance='plain' onClick={onCancel} type='button'>
          {t('Cancel')}
        </Button>
        <Button appearance='plain' onClick={() => onConfirm(false)} type='button'>
          {t('Switch Client Area')}
        </Button>
      </div>
    </React.Fragment>
  )
}
