import { t } from 'i18next'

import { TransactionDto } from '../model/TransactionDto'
import { CurrencyType, WalletTypeEnum } from '../model/WalletDto'
import { isOne } from './validations'

export enum TransactionType {
  WalletTransfer = 100,
  DepositFromPaymentAgent = 112,
  WithdrawToPaymentAgent = 114,
  WalletDeposit = 200,
  WalletWithdrawal = 300,
  TransferFromTradingAccount = 400,
  TransferToTradingAccount = 500,
  TradingAccountBalanceConversion = 520,
  ManualAdjustment = 610,
  Refund = 630,
  WelcomeAccountReward = 640,
  TradingIssueCompensation = 650,
  BonusTransfer = 660,
  IBCommission = 670,
  MIBCommission = 675,
  Reversed = 680,
  IBCashBacks = 682,
  BankFeesRefund = 685,
  CopyTraderFeesWallet = 690,
  NegativeEquityRefund = 710,
  ZuluTradeManagementFee = 720,
  ZuluTradeSubscriptionFee = 730,
  ManualTradingAccountAdjustment = 740,
}

const getWalletText = () => ` ${t('Wallet.Wallet')}`

export function getTransactionType(id: number): TransactionType {
  switch (id) {
    case TransactionType.WalletTransfer:
      return TransactionType.WalletTransfer
    case TransactionType.DepositFromPaymentAgent:
      return TransactionType.DepositFromPaymentAgent
    case TransactionType.WithdrawToPaymentAgent:
      return TransactionType.WithdrawToPaymentAgent
    case TransactionType.WalletDeposit:
      return TransactionType.WalletDeposit
    case TransactionType.WalletWithdrawal:
      return TransactionType.WalletWithdrawal
    case TransactionType.TransferFromTradingAccount:
      return TransactionType.TransferFromTradingAccount
    case TransactionType.TransferToTradingAccount:
      return TransactionType.TransferToTradingAccount
    case TransactionType.TradingAccountBalanceConversion:
      return TransactionType.TradingAccountBalanceConversion
    case TransactionType.IBCashBacks:
      return TransactionType.IBCashBacks
    default:
      return TransactionType.BonusTransfer
  }
}

export function getCurrencySymbol(type: CurrencyType): string {
  switch (type) {
    case 'GBP':
      return '£'
    case 'EUR':
      return '€'
    case 'PLN':
      return 'zł'
    case 'USD':
      return '$'
    case 'CHF':
      return '₣'
    case 'HKD':
      return 'HK$'
    case 'SGD':
      return 'S$'
    case 'AUD':
      return 'AU$'
    case 'CAD':
      return 'C$'
    case 'JPY':
      return 'J¥'
    case 'CNH':
      return 'C¥'
    case 'MYR':
      return 'RM'
    case 'ZAR':
      return 'R'
    case 'BTC':
      return '₿'
    case 'CRC':
      return '₡'
    case 'CLP':
      return 'CLP$'
    default:
      return type
  }
}

export interface TransactionFields {
  from: string
  to: string
  currency: CurrencyType | ''
  amount: number
  rate: number
}

const getCurrency = (currencyId: CurrencyType | undefined, isArabic: boolean): string => {
  if (!currencyId) {
    return ''
  }
  return isArabic ? getCurrencySymbol(currencyId as CurrencyType) : currencyId
}

// REFERENCE - CAR-65 (https://jira.tickmill.com/browse/CAR-65)
export function getClientTransactionFieldsByTransactionType(
  type: TransactionType,
  transaction: TransactionDto,
  isDefaultCFDProductType: boolean,
  isArabic: boolean,
  clientId?: string
): TransactionFields {
  switch (type) {
    case TransactionType.WalletTransfer:
      const fromWallet = transaction.walletTransactions.find(
        (x) => x.directionType.name === 'Debit'
      )
      const toWallet = transaction.walletTransactions.find((x) => x.directionType.name === 'Credit')
      return {
        from:
          fromWallet?.wallet.walletType.id === WalletTypeEnum.IB
            ? t('Wallet.IB Currency Wallet', {
                walletCurrencyId: getCurrency(fromWallet?.wallet.currency.id, isArabic),
              })
            : t('Wallet.Currency Wallet', {
                walletCurrencyId: getCurrency(fromWallet?.wallet.currency.id, isArabic),
              }),

        to:
          t('Wallet.Currency Wallet', {
            walletCurrencyId: getCurrency(toWallet?.wallet.currency.id, isArabic),
          }) || '',
        currency: toWallet?.wallet.currency.id || '',
        amount: toWallet?.amount || 0,
        rate: fromWallet?.currencyRate || 0,
      }
    case TransactionType.DepositFromPaymentAgent:
      const paWalletTransaction = transaction.walletTransactions.find(
        (x) => !!x.paymentAgentTransaction
      )
      const clientWalletTransaction = transaction.walletTransactions.find(
        (x) => !x.paymentProviderTransaction
      )
      return {
        from: paWalletTransaction?.paymentAgentTransaction?.paymentAgent.name || '',
        to: t('Wallet.Currency Wallet', {
          walletCurrencyId: getCurrency(clientWalletTransaction?.wallet.currency.id, isArabic),
        }),
        currency: clientWalletTransaction?.wallet.currency.id || '',
        amount: clientWalletTransaction?.amount || 0,
        rate: clientWalletTransaction?.currencyRate || 0,
      }
    case TransactionType.WithdrawToPaymentAgent:
      const paWalletTransactionWithdrawalType = transaction.walletTransactions.find(
        (x) => !!x.paymentAgentTransaction
      )
      const clientWalletTransactionWithdrawalType = transaction.walletTransactions.find(
        (x) => !x.paymentProviderTransaction
      )
      return {
        from: t('Wallet.Currency Wallet', {
          walletCurrencyId: getCurrency(
            clientWalletTransactionWithdrawalType?.wallet.currency.id,
            isArabic
          ),
        }),
        to: paWalletTransactionWithdrawalType?.paymentAgentTransaction?.paymentAgent.name || '',
        currency: clientWalletTransactionWithdrawalType?.wallet.currency.id || '',
        amount: clientWalletTransactionWithdrawalType?.amount || 0,
        rate: clientWalletTransactionWithdrawalType?.currencyRate || 0,
      }
    case TransactionType.WalletDeposit:
      return {
        from:
          transaction.walletTransactions[0].paymentProviderTransaction?.paymentProvider
            .externalName || 'unknown',
        to: t('Wallet.Currency Wallet', {
          walletCurrencyId: getCurrency(
            transaction.walletTransactions[0].wallet.currency.id,
            isArabic
          ),
        }),
        currency: transaction.walletTransactions[0].wallet.currency.id,
        amount: transaction.walletTransactions[0].amount,
        rate: transaction.walletTransactions[0].currencyRate,
      }
    case TransactionType.WalletWithdrawal:
      return {
        from:
          transaction.walletTransactions[0].wallet.walletType.id === WalletTypeEnum.IB
            ? t('Wallet.IB Currency Wallet', {
                walletCurrencyId: getCurrency(
                  transaction.walletTransactions[0].wallet.currency.id,
                  isArabic
                ),
              })
            : t('Wallet.Currency Wallet', {
                walletCurrencyId: getCurrency(
                  transaction.walletTransactions[0].wallet.currency.id,
                  isArabic
                ),
              }),
        to:
          transaction.walletTransactions[0].paymentProviderTransaction?.paymentProvider.name || '',
        currency: transaction.walletTransactions[0].wallet.currency.id,
        amount: transaction.walletTransactions[0].amount,
        rate: transaction.walletTransactions[0].currencyRate,
      }
    case TransactionType.TransferFromTradingAccount:
      const fromTA = isDefaultCFDProductType
        ? transaction.tradingAccountTransactions[0].tradingAccount.name
        : getCurrency(transaction.walletTransactions[0].wallet.currency.id, isArabic) +
          ' ' +
          t('Trading Account.Trading Account')
      return {
        from: fromTA,
        to: t('Wallet.Currency Wallet', {
          walletCurrencyId: getCurrency(
            transaction.walletTransactions[0].wallet.currency.id,
            isArabic
          ),
        }),
        currency: transaction.walletTransactions[0].wallet.currency.id,
        amount: transaction.walletTransactions[0].amount,
        rate: transaction.walletTransactions[0].currencyRate,
      }
    case TransactionType.TransferToTradingAccount:
      const toTA = isDefaultCFDProductType
        ? transaction.tradingAccountTransactions[0].tradingAccount.name
        : getCurrency(transaction.walletTransactions[0].wallet.currency.id, isArabic) +
          ' ' +
          t('Trading Account.Trading Account')

      return {
        from: t('Wallet.Currency Wallet', {
          walletCurrencyId: getCurrency(
            transaction.walletTransactions[0].wallet.currency.id,
            isArabic
          ),
        }),
        to: toTA,
        currency: transaction.walletTransactions[0].wallet.currency.id,
        amount: transaction.walletTransactions[0].amount,
        rate: transaction.walletTransactions[0].currencyRate,
      }
    case TransactionType.TradingAccountBalanceConversion:
      const transactions = transaction.tradingAccountTransactions
      const fromTradingAccount = transactions.find((x) => x.directionType.name === 'Debit')
      const toTradingAccount = transactions.find((x) => x.directionType.name === 'Credit')

      const fromCurrency = getCurrency(fromTradingAccount?.currency.id, isArabic)
      const toCurrency = getCurrency(toTradingAccount?.currency.id, isArabic)

      return {
        from: fromCurrency + ' ' + t('Trading Account.Trading Account'),
        to: toCurrency + ' ' + t('Trading Account.Trading Account'),
        currency: toTradingAccount?.currency.id || '',
        amount: toTradingAccount?.amount || 0,
        rate: fromTradingAccount?.currencyRate || 0,
      }
    case TransactionType.IBCashBacks:
      const cWalletTransaction = transaction.walletTransactions.find(
        (x) => x.wallet.client.id === clientId
      )
      const externalWalletTran = transaction.walletTransactions.find(
        (x) => x.ibCommisionTransaction
      )
      return {
        from: externalWalletTran?.ibCommisionTransaction?.introducingBroker.name || '',
        to: t('Wallet.Currency Wallet', {
          walletCurrencyId: getCurrency(cWalletTransaction?.wallet.currency.id, isArabic),
        }),
        currency: cWalletTransaction?.wallet.currency.id || '',
        amount: cWalletTransaction?.amount || 0,
        rate: cWalletTransaction?.currencyRate || 1,
      }
    default:
      const tradingAccountTransactionObject = isOne(transaction.tradingAccountTransactions.length)
        ? transaction.tradingAccountTransactions[0]
        : undefined
      if (tradingAccountTransactionObject) {
        const isDebit = tradingAccountTransactionObject.directionType.name === 'Debit'
        const isCredit = tradingAccountTransactionObject.directionType.name === 'Credit'
        if (isDebit) {
          return {
            from: tradingAccountTransactionObject.tradingAccount.name,
            to: 'Tickmill',
            currency: tradingAccountTransactionObject.currency.id,
            amount: tradingAccountTransactionObject.amount,
            rate: tradingAccountTransactionObject.currencyRate,
          }
        }
        if (isCredit) {
          return {
            from: 'Tickmill',
            to: tradingAccountTransactionObject.tradingAccount.name,
            currency: tradingAccountTransactionObject.currency.id,
            amount: tradingAccountTransactionObject.amount,
            rate: tradingAccountTransactionObject.currencyRate,
          }
        }
      }
      const walletTransaction = transaction.walletTransactions.find(
        (x) => x.wallet.client.id === clientId
      )

      const externalWalletTransaction = transaction.walletTransactions.find(
        (x) => x.id !== walletTransaction?.id
      )

      const isDebit =
        (walletTransaction || externalWalletTransaction)?.directionType.name === 'Debit'
      const isCredit =
        (walletTransaction || externalWalletTransaction)?.directionType.name === 'Credit'

      if (isDebit) {
        return {
          from:
            getCurrency(
              (walletTransaction || externalWalletTransaction)?.wallet.currency.id,
              isArabic
            ) + getWalletText(),
          to: 'Tickmill',
          currency: (walletTransaction || externalWalletTransaction)?.wallet.currency.id ?? '',
          amount: (walletTransaction || externalWalletTransaction)?.amount ?? 0,
          rate: (walletTransaction || externalWalletTransaction)?.currencyRate ?? 0,
        }
      } else if (isCredit) {
        return {
          from: 'Tickmill',
          to: t('Wallet.Currency Wallet', {
            walletCurrencyId: getCurrency(
              (walletTransaction || externalWalletTransaction)?.wallet.currency.id,
              isArabic
            ),
          }),
          currency: (walletTransaction || externalWalletTransaction)?.wallet.currency.id ?? '',
          amount: (walletTransaction || externalWalletTransaction)?.amount ?? 0,
          rate: (walletTransaction || externalWalletTransaction)?.currencyRate ?? 0,
        }
      }
      return {
        from: '',
        to: '',
        currency: 'USD',
        amount: 0,
        rate: 0,
      }
  }
}

// REFERENCE - CAR-103 (https://jira.tickmill.com/browse/CAR-103)
export function getIBTransactionFieldsByTransactionType(
  type: TransactionType,
  transaction: TransactionDto,
  isArabic: boolean,
  clientId?: string
): TransactionFields {
  const clientWalletTransaction = transaction.walletTransactions.find(
    (x) => !x.paymentProviderTransaction
  )

  switch (type) {
    case TransactionType.WalletTransfer:
      const fromWallet = transaction.walletTransactions.find(
        (x) => x.directionType.name === 'Debit'
      )
      const toWallet = transaction.walletTransactions.find((x) => x.directionType.name === 'Credit')
      return {
        from: t('Wallet.IB Currency Wallet', {
          walletCurrencyId: getCurrency(fromWallet?.wallet.currency.id, isArabic),
        }),
        to:
          toWallet?.wallet.walletType.id === WalletTypeEnum.IB
            ? t('Wallet.IB Currency Wallet', {
                walletCurrencyId: getCurrency(toWallet?.wallet.currency.id, isArabic),
              })
            : t('Wallet.Currency Wallet', {
                walletCurrencyId: getCurrency(toWallet?.wallet.currency.id, isArabic),
              }),
        currency: toWallet?.wallet.currency.id || '',
        amount: toWallet?.amount || 0,
        rate: toWallet?.currencyRate || 0,
      }

    case TransactionType.DepositFromPaymentAgent:
      const paWalletTransaction = transaction.walletTransactions.find(
        (x) => !!x.paymentAgentTransaction
      )

      const isIB = clientWalletTransaction?.wallet.walletType.id === WalletTypeEnum['FX/CFD']

      return {
        from: paWalletTransaction?.paymentAgentTransaction?.paymentAgent.name || '',
        to: isIB
          ? t('Wallet.IB Currency Wallet', {
              walletCurrencyId: getCurrency(clientWalletTransaction?.wallet.currency.id, isArabic),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(clientWalletTransaction?.wallet.currency.id, isArabic),
            }),
        currency: clientWalletTransaction?.wallet.currency.id || '',
        amount: clientWalletTransaction?.amount || 0,
        rate: clientWalletTransaction?.currencyRate || 0,
      }
    case TransactionType.WithdrawToPaymentAgent:
      const paWalletTransactionWithdrawalType = transaction.walletTransactions.find(
        (x) => !!x.paymentAgentTransaction
      )
      const clientWalletTransactionWithdrawalType = transaction.walletTransactions.find(
        (x) => !x.paymentProviderTransaction
      )

      const isIBWithdrawal =
        clientWalletTransaction?.wallet.walletType.id === WalletTypeEnum['FX/CFD']

      return {
        from: paWalletTransactionWithdrawalType?.paymentAgentTransaction?.paymentAgent.name || '',
        to: isIBWithdrawal
          ? t('Wallet.IB Currency Wallet', {
              walletCurrencyId: getCurrency(
                clientWalletTransactionWithdrawalType?.wallet.currency.id,
                isArabic
              ),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(
                clientWalletTransactionWithdrawalType?.wallet.currency.id,
                isArabic
              ),
            }),
        currency: clientWalletTransactionWithdrawalType?.wallet.currency.id || '',
        amount: clientWalletTransactionWithdrawalType?.amount || 0,
        rate: clientWalletTransactionWithdrawalType?.currencyRate || 0,
      }
    case TransactionType.WalletDeposit:
      const walletDepositTo = transaction.walletTransactions[0]

      const isIBDeposit = walletDepositTo.wallet.walletType.id === WalletTypeEnum['FX/CFD']
      return {
        from:
          transaction.walletTransactions[0].paymentProviderTransaction?.paymentProvider
            .externalName || 'unknown',
        to: isIBDeposit
          ? t('Wallet.IB Currency Wallet', {
              walletCurrencyId: getCurrency(walletDepositTo.wallet.currency.id, isArabic),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(walletDepositTo.wallet.currency.id, isArabic),
            }),
        currency: walletDepositTo.wallet.currency.id,
        amount: walletDepositTo.amount,
        rate: walletDepositTo.currencyRate,
      }
    case TransactionType.WalletWithdrawal:
      const walletWithdrawalFrom = transaction.walletTransactions[0]
      return {
        from: t('Wallet.IB Currency Wallet', {
          walletCurrencyId: getCurrency(walletWithdrawalFrom.wallet.currency.id, isArabic),
        }),
        to: walletWithdrawalFrom.paymentProviderTransaction?.paymentProvider.name || '',
        currency: walletWithdrawalFrom.wallet.currency.id,
        amount: walletWithdrawalFrom.amount,
        rate: walletWithdrawalFrom.currencyRate,
      }
    case TransactionType.TransferFromTradingAccount:
      const transferFromTAWalletTransaction = transaction.walletTransactions[0]

      const isIBTransferFromTAWalletTransaction =
        transferFromTAWalletTransaction.wallet.walletType.id === WalletTypeEnum['FX/CFD']

      return {
        from: transaction.tradingAccountTransactions[0].tradingAccount.name,
        to: isIBTransferFromTAWalletTransaction
          ? t('Wallet.IB Currency Wallet', {
              walletCurrencyId: getCurrency(
                transferFromTAWalletTransaction.wallet.currency.id,
                isArabic
              ),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(
                transferFromTAWalletTransaction.wallet.currency.id,
                isArabic
              ),
            }),
        currency: transferFromTAWalletTransaction.wallet.currency.id,
        amount: transferFromTAWalletTransaction.amount,
        rate: transferFromTAWalletTransaction.currencyRate,
      }
    case TransactionType.TransferToTradingAccount:
      const transferToTAWalletTransaction = transaction.walletTransactions[0]

      const isIBTransferToTAWalletTransaction =
        transferToTAWalletTransaction.wallet.walletType.id === WalletTypeEnum['FX/CFD']

      return {
        from: isIBTransferToTAWalletTransaction
          ? t('Wallet.IB Currency Wallet', {
              walletCurrencyId: getCurrency(
                transferToTAWalletTransaction.wallet.currency.id,
                isArabic
              ),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(
                transferToTAWalletTransaction.wallet.currency.id,
                isArabic
              ),
            }),
        to: transaction.tradingAccountTransactions[0].tradingAccount.name,
        currency: transferToTAWalletTransaction.wallet.currency.id,
        amount: transferToTAWalletTransaction.amount,
        rate: transaction.walletTransactions[0].currencyRate,
      }
    case TransactionType.IBCashBacks:
      const cWalletTransaction = transaction.walletTransactions.find(
        (x) => x.wallet.client.id === clientId
      )

      return {
        from: t('Wallet.IB Currency Wallet', {
          walletCurrencyId: getCurrency(cWalletTransaction?.wallet.currency.id, isArabic),
        }),
        to: t('IB.Clients'),
        currency: cWalletTransaction?.wallet.currency.id || '',
        amount: cWalletTransaction?.amount || 0,
        rate: cWalletTransaction?.currencyRate || 1,
      }
    default:
      // If the item.walletTransactions contains more than 1 object, find the one walletTransaction object which the
      // walletTransaction.wallet.client.id == <your client id>.
      // This one becomes ClientWalletTransaction.
      // The other one becomes ExternalWalletTransaction.
      // ClientWalletPrefix = if ClientWalletTransaction->wallet->walletType->id == 10 then "IB " otherwise empty string
      // a. ClientWalletTransaction.directionType.name == "Debit"
      // From = "(ClientWalletPrefix)(ClientWalletTransaction.wallet.currency.id) Wallet"
      // To = "Tickmill"
      // Amount = ClientWalletTransaction.amount
      // Rate = ClientWalletTransaction.currencyRate
      const isWalletTransaction = transaction.walletTransactions.length > 0
      if (isWalletTransaction) {
        const clientWalletTransaction = transaction.walletTransactions.find(
          (x) => x.wallet.client.id === clientId
        )
        const isDebit = clientWalletTransaction?.directionType.name === 'Debit'
        const isCredit = clientWalletTransaction?.directionType.name === 'Credit'
        const clientWalletPrefix =
          clientWalletTransaction?.wallet.walletType.id === WalletTypeEnum.IB
        if (isDebit) {
          const from = clientWalletPrefix
            ? t('Wallet.IB Currency Wallet', {
                walletCurrencyId: getCurrency(clientWalletTransaction.wallet.currency.id, isArabic),
              })
            : t('Wallet.Currency Wallet', {
                walletCurrencyId: getCurrency(clientWalletTransaction.wallet.currency.id, isArabic),
              })
          return {
            from,
            to: 'Tickmill',
            currency: clientWalletTransaction.wallet.currency.id,
            amount: clientWalletTransaction.amount,
            rate: clientWalletTransaction.currencyRate,
          }
        }
        if (isCredit) {
          return {
            from: 'Tickmill',
            to: t('Wallet.IB Currency Wallet', {
              walletCurrencyId: getCurrency(clientWalletTransaction.wallet.currency.id, isArabic),
            }),
            currency: clientWalletTransaction.wallet.currency.id,
            amount: clientWalletTransaction.amount,
            rate: clientWalletTransaction.currencyRate,
          }
        }
      }
      return {
        from: '',
        to: '',
        currency: 'USD',
        amount: 0,
        rate: 0,
      }
  }
}

// REFERENCE - CAR-104 (https://jira.tickmill.com/browse/CAR-104)
export function getPATransactionFieldsByTransactionType(
  type: TransactionType,
  transaction: TransactionDto,
  isArabic: boolean,
  clientId?: string,
  isReversed?: boolean
): TransactionFields {
  switch (type) {
    case TransactionType.WalletTransfer:
      const fromWallet = transaction.walletTransactions.find(
        (x) => x.directionType.name === 'Debit'
      )
      const toWallet = transaction.walletTransactions.find((x) => x.directionType.name === 'Credit')
      const hasFromIBPrefix = fromWallet?.wallet.walletType.id === WalletTypeEnum['PAYMENT_AGENT']
      const hasToIBPrefix = toWallet?.wallet.walletType.id === WalletTypeEnum['PAYMENT_AGENT']

      return {
        from: hasFromIBPrefix
          ? t('Wallet.PA Currency Wallet', {
              walletCurrencyId: getCurrency(fromWallet?.wallet.currency.id, isArabic),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(fromWallet?.wallet.currency.id, isArabic),
            }),
        to: hasToIBPrefix
          ? t('Wallet.PA Currency Wallet', {
              walletCurrencyId: getCurrency(toWallet?.wallet.currency.id, isArabic),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(toWallet?.wallet.currency.id, isArabic),
            }),
        currency: toWallet?.wallet.currency.id || '',
        amount: toWallet?.amount || 0,
        rate: toWallet?.currencyRate || 0,
      }

    case TransactionType.DepositFromPaymentAgent:
      const paWalletTransaction = transaction.walletTransactions.find(
        (x) => !!x.paymentAgentTransaction
      )
      const clientWalletTransaction = transaction.walletTransactions.find(
        (x) => !x.paymentAgentTransaction
      )

      return {
        from: paWalletTransaction?.paymentAgentTransaction?.paymentAgent.name || '',
        to: clientWalletTransaction?.wallet.name || '',
        currency: clientWalletTransaction?.wallet.currency.id || '',
        amount: clientWalletTransaction?.amount || 0,
        rate: clientWalletTransaction?.currencyRate || 0,
      }
    case TransactionType.WithdrawToPaymentAgent:
      const paWalletTransactionWithdrawalType = transaction.walletTransactions
        .filter((x) => x.directionType.name === (isReversed ? 'Debit' : 'Credit'))
        .find((x) => !!x.paymentAgentTransaction)
      const clientWalletTransactionWithdrawalType = transaction.walletTransactions
        .filter((x) => x.directionType.name === (isReversed ? 'Credit' : 'Debit'))
        .find((x) => !x.paymentProviderTransaction)

      return {
        from: clientWalletTransactionWithdrawalType?.wallet.name || '',
        to: paWalletTransactionWithdrawalType?.paymentAgentTransaction?.paymentAgent.name || '',
        currency: clientWalletTransactionWithdrawalType?.wallet.currency.id || '',
        amount: clientWalletTransactionWithdrawalType?.amount || 0,
        rate: clientWalletTransactionWithdrawalType?.currencyRate || 0,
      }
    case TransactionType.WalletDeposit:
      const walletDepositTo = transaction.walletTransactions[0]
      const hasWalletDepositPrefix =
        walletDepositTo.wallet.walletType.id === WalletTypeEnum['PAYMENT_AGENT']

      return {
        from:
          transaction.walletTransactions[0].paymentProviderTransaction?.paymentProvider
            .externalName || 'unknown',
        to: hasWalletDepositPrefix
          ? t('Wallet.PA Currency Wallet', {
              walletCurrencyId: getCurrency(walletDepositTo.wallet.currency.id, isArabic),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(walletDepositTo.wallet.currency.id, isArabic),
            }),

        currency: walletDepositTo.wallet.currency.id,
        amount: walletDepositTo.amount,
        rate: walletDepositTo.currencyRate,
      }
    case TransactionType.WalletWithdrawal:
      const walletWithdrawalFrom = transaction.walletTransactions[0]
      const hasWalletWithdrawalFromPrefix =
        walletWithdrawalFrom.wallet.walletType.id === WalletTypeEnum['PAYMENT_AGENT']

      return {
        from: hasWalletWithdrawalFromPrefix
          ? t('Wallet.PA Currency Wallet', {
              walletCurrencyId: getCurrency(walletWithdrawalFrom.wallet.currency.id, isArabic),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(walletWithdrawalFrom.wallet.currency.id, isArabic),
            }),
        to: walletWithdrawalFrom.paymentProviderTransaction?.paymentProvider.name || '',
        currency: walletWithdrawalFrom.wallet.currency.id,
        amount: walletWithdrawalFrom.amount,
        rate: walletWithdrawalFrom.currencyRate,
      }
    case TransactionType.TransferFromTradingAccount:
      const transferFromTAWalletTransaction = transaction.walletTransactions[0]
      const hasTransferFromTAWalletTransactionPrefix =
        transferFromTAWalletTransaction.wallet.walletType.id === WalletTypeEnum['PAYMENT_AGENT']

      return {
        from: transaction.tradingAccountTransactions[0].tradingAccount.name,
        to: hasTransferFromTAWalletTransactionPrefix
          ? t('Wallet.PA Currency Wallet', {
              walletCurrencyId: getCurrency(
                transferFromTAWalletTransaction.wallet.currency.id,
                isArabic
              ),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(
                transferFromTAWalletTransaction.wallet.currency.id,
                isArabic
              ),
            }),
        currency: transferFromTAWalletTransaction.wallet.currency.id,
        amount: transferFromTAWalletTransaction.amount,
        rate: transferFromTAWalletTransaction.currencyRate,
      }
    case TransactionType.TransferToTradingAccount:
      const transferToTAWalletTransaction = transaction.walletTransactions[0]
      const hasTransferToTAWalletTransactionPrefix =
        transferToTAWalletTransaction.wallet.walletType.id === WalletTypeEnum['PAYMENT_AGENT']

      return {
        from: hasTransferToTAWalletTransactionPrefix
          ? t('Wallet.PA Currency Wallet', {
              walletCurrencyId: getCurrency(
                transferToTAWalletTransaction.wallet.currency.id,
                isArabic
              ),
            })
          : t('Wallet.Currency Wallet', {
              walletCurrencyId: getCurrency(
                transferToTAWalletTransaction.wallet.currency.id,
                isArabic
              ),
            }),
        to: transaction.tradingAccountTransactions[0].tradingAccount.name,
        currency: transferToTAWalletTransaction.wallet.currency.id,
        amount: transferToTAWalletTransaction.amount,
        rate: transaction.walletTransactions[0].currencyRate,
      }
    default:
      const tradingAccountTransactionObject = isOne(transaction.tradingAccountTransactions.length)
        ? transaction.tradingAccountTransactions[0]
        : undefined
      if (tradingAccountTransactionObject) {
        const isDebit = tradingAccountTransactionObject.directionType.name === 'Debit'
        const isCredit = tradingAccountTransactionObject.directionType.name === 'Credit'
        if (isDebit) {
          return {
            from: tradingAccountTransactionObject.tradingAccount.name,
            to: 'Tickmill',
            currency: tradingAccountTransactionObject.currency.id,
            amount: tradingAccountTransactionObject.amount,
            rate: tradingAccountTransactionObject.currencyRate,
          }
        }
        if (isCredit) {
          return {
            from: 'Tickmill',
            to: tradingAccountTransactionObject.tradingAccount.name,
            currency: tradingAccountTransactionObject.currency.id,
            amount: tradingAccountTransactionObject.amount,
            rate: tradingAccountTransactionObject.currencyRate,
          }
        }
      }
      const walletTransaction =
        transaction.walletTransactions.length > 1
          ? transaction.walletTransactions.find((x) => x.wallet.client.id === clientId)
          : undefined
      const externalWalletTransaction = transaction.walletTransactions.find(
        (x) => x.id !== walletTransaction?.id
      )
      if (walletTransaction) {
        const hasPaPrefix =
          walletTransaction.wallet.walletType.id === WalletTypeEnum['PAYMENT_AGENT']

        return {
          from: hasPaPrefix
            ? t('Wallet.PA Currency Wallet', {
                walletCurrencyId: getCurrency(walletTransaction.wallet.currency.id, isArabic),
              })
            : t('Wallet.Currency Wallet', {
                walletCurrencyId: getCurrency(walletTransaction.wallet.currency.id, isArabic),
              }),
          to: 'Tickmill',
          currency: walletTransaction.wallet.currency.id,
          amount: walletTransaction.amount,
          rate: walletTransaction.currencyRate,
        }
      } else if (externalWalletTransaction) {
        const hasPaPrefix =
          externalWalletTransaction.wallet.walletType.id === WalletTypeEnum['PAYMENT_AGENT']

        return {
          from: 'Tickmill',
          to: hasPaPrefix
            ? t('Wallet.PA Currency Wallet', {
                walletCurrencyId: getCurrency(
                  externalWalletTransaction.wallet.currency.id,
                  isArabic
                ),
              })
            : t('Wallet.Currency Wallet', {
                walletCurrencyId: getCurrency(
                  externalWalletTransaction.wallet.currency.id,
                  isArabic
                ),
              }),
          currency: externalWalletTransaction.wallet.currency.id,
          amount: externalWalletTransaction.amount,
          rate: externalWalletTransaction.currencyRate,
        }
      }
      return {
        from: '',
        to: '',
        currency: 'USD',
        amount: 0,
        rate: 0,
      }
  }
}

export const isTransactionTradingAccountBalanceConversionType = (
  value: TransactionType
): boolean => {
  return value === TransactionType.TradingAccountBalanceConversion
}

export const isTradingAccountTransactionType = (value: TransactionType): boolean => {
  return (
    value === TransactionType.TradingAccountBalanceConversion ||
    value === TransactionType.TransferToTradingAccount ||
    value === TransactionType.TransferFromTradingAccount
  )
}

export const sleep = <T>(interval = 3000): Promise<T> =>
  new Promise((resolve) => setTimeout(resolve, interval))
