import { InitWalletToPspWithdrawTransactionCommand } from './InitWalletToPspWithdrawTransactionCommand'
import {
  FormItemDto,
  isFormItemInputDto,
  isFormItemSelectDto,
} from './WalletPaymentProviderParametersDto'

export interface CreateWalletToPspWithdrawTransaction {
  fromWallet: Wallet
  toPaymentProvider: Wallet
  amount: number | undefined
  comment: string
  terms: boolean
  fields?: { [key: string]: string | boolean }
  form: FormItemDto[]
}

interface Wallet {
  id: string | undefined
  currency: { id: string | undefined }
}

export const denormalizeCreateWalletToPspWithdrawTransaction = (
  values: CreateWalletToPspWithdrawTransaction,
  locale: string
): Omit<InitWalletToPspWithdrawTransactionCommand, 'caller' | 'clientIpAddress'> => {
  return {
    fromWalletId: getFromWallet(values),
    toPspId: getToPspId(values),
    pspCurrencyId: getCurrencyValue(values),
    amount: getAmount(values),
    comment: getComment(values),
    details: getDetails(values),
    languageId: locale,
  }
}

const getAmount = (values: CreateWalletToPspWithdrawTransaction): string => {
  return values.amount?.toString() || '0'
}

const getToPspId = (values: CreateWalletToPspWithdrawTransaction): string => {
  return values.toPaymentProvider.id || ''
}

const getFromWallet = (values: CreateWalletToPspWithdrawTransaction): string => {
  return values.fromWallet.id || ''
}

const getComment = (values: CreateWalletToPspWithdrawTransaction): string => {
  return values.comment || ''
}

const getTerms = (values: CreateWalletToPspWithdrawTransaction): string => {
  return values.terms ? '1' : '0'
}

const getCurrencyValue = (values: CreateWalletToPspWithdrawTransaction): string => {
  const paymentCurrencyField = 'payment_currency_id'
  const paymentCurrencyForm = (values.form || []).find((x) => x.field === paymentCurrencyField)

  if (isFormItemSelectDto(paymentCurrencyForm)) {
    return values.toPaymentProvider.currency.id || ''
  }

  if (isFormItemInputDto(paymentCurrencyForm)) {
    return paymentCurrencyForm.value
  }

  return values.fromWallet.currency.id || ''
}

const getDetails = (
  values: CreateWalletToPspWithdrawTransaction
): { name: string; value: string }[] => {
  const details = Object.entries(values?.fields || []).map(([name, value]) => ({ name, value }))

  return [
    { name: 'amount', value: getAmount(values) },
    { name: 'additionalComment', value: getComment(values) },
    { name: 'terms', value: getTerms(values) },
    ...details.map((x) => {
      if (x.name === 'externalAccount') {
        return { name: x.name, value: x?.value?.toString().trim() }
      }
      return { name: x.name, value: x?.value?.toString() }
    }),
  ]
}
