import React from 'react'
import { useTranslation } from 'react-i18next'

import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table, TableHeadRow } from '../../global/table/Table'
import { ClientIbContestCampaignResultDto } from '../../model/ClientIbContestCampaignResultDto'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { PageQuery } from '../../utils/ApiClient'
import { getCentralRowIdConditionally } from '../../utils/getItemId'

interface Props {
  data: ClientIbContestCampaignResultDto[]
  pageQuery?: PageQuery
  setPageQuery(value: PageQuery): void
}

export const IBContestResultsTable: React.FC<Props> = (props) => {
  const { data, pageQuery, setPageQuery } = props

  const { t } = useTranslation()
  const { account } = useAccountReadContext()

  return (
    <Table>
      <thead>
        <tr>
          <SortHeader
            id='Rank'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.Ranking')}
          </SortHeader>
          <SortHeader
            id='IntroducingBroker.Name'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.IB Username')}
          </SortHeader>
          <SortHeader
            id='ClientCount'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.Number of Clients')}
          </SortHeader>
          <SortHeader
            id='ContestPoints'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
          >
            {t('IB.Contest Points')}
          </SortHeader>
        </tr>
      </thead>
      <tbody>
        {!data.length ? (
          <td align='center' colSpan={4}>
            {t('No results')}
          </td>
        ) : (
          data.map((x, index) => {
            return (
              <TableHeadRow
                key={x.ibCode}
                selected={account?.clientIntroducingBroker?.name === x.ibCode}
                {...getCentralRowIdConditionally(index, data.length, 'ib-contest-results')}
              >
                <td>#{x.ranking}</td>
                <td>{x.ibCode}</td>
                <td>{x.numberOfClients}</td>
                <td>{x.points}</td>
              </TableHeadRow>
            )
          })
        )}
      </tbody>
    </Table>
  )
}
