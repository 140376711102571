import { Ref, useEffect, useLayoutEffect, useRef, useState } from 'react'

const ClickableElementTagNames = ['A', 'BUTTON', 'INPUT']

export const isClickable = (element: Element): boolean => {
  if (ClickableElementTagNames.includes(element.tagName)) {
    return true
  }
  if (!element.parentElement) {
    return false
  }

  return isClickable(element.parentElement)
}

export const isContainedWithin = (element: Element, parentElement: Element): boolean => {
  if (element === parentElement) {
    return true
  }
  if (!element.parentElement) {
    return false
  }

  return isContainedWithin(element.parentElement, parentElement)
}

export const isContainedWithinSelector = (element: Element, selector: string): boolean => {
  if (element.matches(selector)) {
    return true
  }
  if (!element.parentElement) {
    return false
  }

  return isContainedWithinSelector(element.parentElement, selector)
}

export const childContainingDataAttr = (
  element: Element,
  dataAttr: string
): HTMLElement | undefined => {
  const htmlElement = element as HTMLElement

  if (htmlElement.dataset && htmlElement.dataset[dataAttr]) {
    return htmlElement
  }

  for (const child of element.children as any) {
    const found = childContainingDataAttr(child, dataAttr)
    if (found) {
      return found
    }
  }
}

export const isMobile = (): boolean => {
  const mediaQueryList = window.matchMedia('(max-width: 768px)')
  return mediaQueryList.matches
}

export function useWindowResize(): boolean {
  const [mobile, setMobile] = useState(isMobile)

  useLayoutEffect(() => {
    function update() {
      setMobile(isMobile())
    }

    window.addEventListener('resize', update)
    update()

    return () => window.removeEventListener('resize', update)
  }, [])

  return mobile
}

export interface ElementResizeProperty<T extends HTMLElement> {
  elementRef: Ref<T>
  elementWidth: number
}

export function useElementResize<T extends HTMLElement>(): ElementResizeProperty<T> {
  const elementRef = useRef<T>(null)
  const [elementWidth, setElementWidth] = useState(0)

  useEffect(() => {
    const updateElementWidth = () => {
      if (elementRef.current) {
        const width = elementRef.current.getBoundingClientRect().width
        setElementWidth(width)
      }
    }

    updateElementWidth()

    window.addEventListener('resize', updateElementWidth)

    return () => {
      window.removeEventListener('resize', updateElementWidth)
    }
  }, [])

  return { elementRef, elementWidth }
}
