import { CurrencyType } from '../model/WalletDto'

export const formatAmountToNumber = (amount?: number | null, decimalPlaces = 2): number => {
  if (amount === null || amount === undefined) {
    return 0
  }
  const multiplier = Math.pow(10, decimalPlaces)
  return Math.round(amount * multiplier) / multiplier
}

export const currencyToSymbol = (currency?: CurrencyType): string | null => {
  const dummyDigit = 0
  if (!currency) {
    return null
  }
  return dummyDigit
    .toLocaleString('en', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace('0.00', '')
}
