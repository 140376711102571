import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { CourseDto } from '../../model/Course'
import { LessonDto } from '../../model/Lesson'
import { Grid } from '../../ui/Layouts/Grid/Grid'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextSmallStrong } from '../../ui/Typography/Typography'
import { Tel3Client } from '../../utils/Tel3Client'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'
import { scrollToTop, useScrollToTop } from '../../utils/useScrollToTop'

import styles from './Training.module.scss'

export const TrainingVideosPage = (): JSX.Element => {
  useScrollToTop()
  const [selectedCourse, setSelectedCourse] = React.useState<CourseDto | null>(null)
  const [videos, setVideos] = React.useState<LessonDto[]>([])
  const [selectedVideo, setSelectedVideo] = React.useState<LessonDto | null>(null)
  const [isLoading, setIslLoading] = React.useState<boolean>(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const apiClient = new Tel3Client()
  const isMobile = useWindowResize()
  const locale = useSessionLanguage()

  const { t } = useTranslation()

  const callback = useCallback(async () => {
    setIslLoading(true)
    const response = await apiClient.get<CourseDto[]>('/product/level-items/course')
    const filtered = response.filter((plan) => plan.isOpen)
    setIslLoading(false)
    return filtered
  }, [locale])

  const onSelectCourse = async (course: CourseDto) => {
    const response = await apiClient.get<LessonDto[]>(
      `/product/level-children/course?id=${course.id}`
    )
    setVideos(response)
    setSelectedVideo(response[0])
    setSelectedCourse(course)
    if (isMobile) {
      setTimeout(() => {
        if (videoRef.current) {
          const elementPosition = videoRef.current.getBoundingClientRect().top + window.scrollY
          const offset = 100
          scrollToTop({ top: elementPosition - offset })
        }
      }, 100)
    }
  }

  useEffect(() => {
    if (selectedCourse) {
      setSelectedCourse(null)
      setSelectedVideo(null)
    }
  }, [locale])

  const { data } = useFetchOne(callback)

  if (selectedCourse && selectedVideo) {
    return (
      <section>
        <PageHeader
          title={
            <span>
              {selectedCourse.name} / {selectedVideo.name}
            </span>
          }
          backButton={() => setSelectedVideo(null)}
        />
        <div className={styles.listContainer}>
          <div className={styles.list}>
            {videos.map((video, index) => (
              <div
                onClick={() => setSelectedVideo(video)}
                key={index}
                className={classNames(styles.item, {
                  [styles.isSelected]: selectedVideo.id === video.id,
                })}
              >
                <span className={styles.name}>{video.name}</span>
                <TextSmallStrong className={styles.duration}>{video.videoDuration}</TextSmallStrong>
              </div>
            ))}
          </div>
          <div>
            <video className={styles.video} src={selectedVideo.videoUrl} ref={videoRef} controls />
          </div>
        </div>
      </section>
    )
  }

  return (
    <Loading isLoading={isLoading}>
      <PageHeader title={t('Learning.Video Tutorials')} />
      <Grid className={styles.courses}>
        {data &&
          data.map((course, index) => (
            <div onClick={() => onSelectCourse(course)} key={index} className={styles.card}>
              <h4>{course.name}</h4>
              <img src={course.image} alt={course.name} />
            </div>
          ))}
      </Grid>
    </Loading>
  )
}
