import { IconProps, iconColor } from './IconProps'

export function BasketCircledIcon(props: IconProps): JSX.Element {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.75'
        y='0.75'
        width='38.5'
        height='38.5'
        rx='19.25'
        stroke={iconColor(props)}
        strokeWidth='1.5'
      />
      <path
        d='M11.0275 18.4904C10.9935 18.3135 10.991 18.129 11.0202 17.9507C11.0494 17.7724 11.1096 17.605 11.1962 17.4614C11.2828 17.3177 11.3935 17.2015 11.5199 17.1216C11.6463 17.0416 11.785 17.0001 11.9257 17H28.0743C28.215 17.0001 28.3537 17.0416 28.4801 17.1216C28.6065 17.2015 28.7172 17.3177 28.8038 17.4614C28.8904 17.605 28.9506 17.7724 28.9798 17.9507C29.009 18.129 29.0065 18.3135 28.9725 18.4904L27.2956 27.182C27.1954 27.7012 26.9643 28.1622 26.6388 28.4916C26.3133 28.821 25.9122 28.9999 25.4992 29H14.5008C14.0878 28.9999 13.6867 28.821 13.3612 28.4916C13.0357 28.1622 12.8046 27.7012 12.7044 27.182L11.0275 18.4916V18.4904Z'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path d='M17 21V24' stroke={iconColor(props)} strokeWidth='1.5' strokeLinecap='round' />
      <path d='M23 21V24' stroke={iconColor(props)} strokeWidth='1.5' strokeLinecap='round' />
      <path
        d='M13.7197 17L15.7197 11'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M25.7197 17L23.7197 11'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
