export const getFileExtensions = () => {
  return [...getImageFileExtensions(), ...getDocumentFileExtensions(), ...getExcelFileExtensions()]
}

export enum ImageFileExtensionType {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
}

export const getImageFileExtensions = () => {
  return [ImageFileExtensionType.JPG, ImageFileExtensionType.JPEG, ImageFileExtensionType.PNG]
}

export const isImageFileExtensions = (fileType?: string) => {
  if (!fileType) {
    return false
  }
  return getImageFileExtensions()
    .map((x) => x.toString())
    .includes(fileType?.toLowerCase())
}

export enum DocumentFileExtensionType {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
}

export const getDocumentFileExtensions = () => {
  return [
    DocumentFileExtensionType.PDF,
    DocumentFileExtensionType.DOC,
    DocumentFileExtensionType.DOCX,
  ]
}

export const isDocumentFileExtensions = (fileType?: string) => {
  if (!fileType) {
    return false
  }
  return getDocumentFileExtensions()
    .map((x) => x.toString())
    .includes(fileType?.toLowerCase())
}

export enum ExcelFileExtensionType {
  XLS = 'xls',
  XLSX = 'xlsx',
  CSV = 'csv',
}

export const getExcelFileExtensions = () => {
  return [ExcelFileExtensionType.XLS, ExcelFileExtensionType.XLSX, ExcelFileExtensionType.CSV]
}

export const isExcelFileExtensions = (fileType?: string) => {
  if (!fileType) {
    return false
  }
  return getExcelFileExtensions()
    .map((x) => x.toString())
    .includes(fileType?.toLowerCase())
}

export type FileExtension = ImageFileExtension | DocumentExtension | ExcelFileExtension

type ImageFileExtension = 'jpg' | 'jpeg' | 'png'

type DocumentExtension = 'pdf' | 'doc' | 'docx'

type ExcelFileExtension = 'xls' | 'xlsx' | 'csv'
