import React from 'react'

import { NameDto } from '../../../../model/NameDto'
import { SimplifyWallet } from '../../../../model/WalletDto'
import { TextSmallStrong } from '../../../../ui/Typography/Typography'
import { TickmillCompany } from '../../../../utils/companyName.utils'
import { Chip } from '../../../chip/Chip'
import { FilterOptions, FilterQueryProps } from '../../FilterQueryModal'

import styles from './FilterModalChipFilter.module.scss'

interface FilterModalChipFilterProps {
  name: string
  options: NameDto<string | number>[] | SimplifyWallet[] | TickmillCompany[]
  filterType: FilterOptions
  currencies?: NameDto<string | number>[]
  setState: (value: React.SetStateAction<FilterQueryProps | undefined>) => void
  multiSelect?: boolean
  state?: FilterQueryProps
}

export const FilterModalChipFilter: React.FC<FilterModalChipFilterProps> = (props) => {
  const { options, setState, filterType, state, name, currencies, multiSelect } = props

  const handleUpdate = (option: NameDto<string | number> | SimplifyWallet | TickmillCompany) => {
    const currentSelection = state?.[filterType]?.toString().split(' ') || []
    const isOptionSelected = currentSelection.includes(option.id.toString())

    const newState = multiSelect
      ? {
          ...state,
          [filterType]: isOptionSelected
            ? currentSelection.filter((id: string) => id !== option.id.toString()).join(' ')
            : [...currentSelection, option.id].join(' '),
        }
      : {
          ...state,
          [filterType]: state?.[filterType] === option.id ? undefined : option.id,
        }

    setState(newState)
  }

  return (
    <div className={styles.chipFilter}>
      {name && <TextSmallStrong className={styles.name}>{name}</TextSmallStrong>}
      <div className={styles.chipsWrapper}>
        {options.map((option, i) => {
          const text = currencies ? currencies[i].id : option.name
          return (
            <span className={styles.buttonItem} key={option.id}>
              <Chip
                text={text}
                onClick={() => handleUpdate(option)}
                isActive={state?.[filterType]?.toString().split(' ').includes(option.id.toString())}
                key={text}
              />
            </span>
          )
        })}
      </div>
    </div>
  )
}
