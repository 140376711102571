import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format, subDays } from 'date-fns'
import { useFormikContext } from 'formik'

import { TextSmallStrong } from '../../../../ui/Typography/Typography'
import { dateTimeFormat } from '../../../../utils/config'
import { DateFilterPresets, IDateFilter, getCustomDateTime } from '../../../../utils/filter.utils'
import { Chip } from '../../../chip/Chip'
import { DatePicker } from '../../../datePicker/DatePicker'
import { FilterQueryProps } from '../../FilterQueryModal'
import { FromToI } from '../fromTo/FilterModalFromToFilter'

import styles from './FilterModalCustomDateFilter.module.scss'

interface FilterModalCustomDateFilterProps {
  name: string
  setState: (value: React.SetStateAction<FilterQueryProps | undefined>) => void
  keys: FromToI<string>
  allowFuture?: boolean
  state?: FilterQueryProps
  limits?: { from: Date; to: Date }
  presets?: DateFilterPresets[]
}

export const FilterModalCustomDateFilter: React.FC<FilterModalCustomDateFilterProps> = ({
  name,
  setState,
  keys,
  allowFuture,
  limits,
  state,
  presets,
}) => {
  const { t } = useTranslation()
  const dateFilters = useMemo(() => getCustomDateTime(presets), [presets])
  const [isCustomDate, setIsCustomDate] = useState(false)
  const [isCustomDateOpen, setIsCustomDateOpen] = useState(false)

  const checkIfCustomDate = (): boolean => {
    const stateFrom = state?.[keys.from]
    const stateTo = state?.[keys.to]

    if (!stateFrom || !stateTo) {
      return false
    }

    for (let i = 0; i < dateFilters.length; i++) {
      const filter = dateFilters[i]
      const filterValue = filter.value

      if (
        Array.isArray(filterValue) &&
        stateFrom === filterValue[0] &&
        stateTo === filterValue[1]
      ) {
        return false
      }

      if (!Array.isArray(filterValue) && stateFrom === filterValue) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    setIsCustomDate(checkIfCustomDate())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, keys, dateFilters])

  useEffect(() => {
    setIsCustomDateOpen(isCustomDate)
  }, [isCustomDate])

  const checkIfDateChipIsActive = (d: IDateFilter): boolean => {
    if (isCustomDateOpen && d.id === DateFilterPresets.Custom) {
      return true
    }
    if (!isCustomDateOpen) {
      if (typeof d.value === 'string') {
        if (d.value === state?.[keys.from]) {
          return true
        }
        if (d.value === state?.[keys.from] && d.value === state?.[keys.to]) {
          return true
        }
      } else {
        if (d.value[0] === state?.[keys.from] && d.value[1] === state?.[keys.to]) {
          return true
        }
      }
    }

    return false
  }
  const clearFields = () => {
    setState({
      ...state,
      [keys.from]: undefined,
      [keys.to]: undefined,
    })
    setValues({ ...values, [keys.from]: undefined, [keys.to]: undefined })
  }

  const handleChipDate = (d: IDateFilter) => {
    if (d.id === DateFilterPresets.Custom) {
      if (isCustomDateOpen) {
        clearFields()
      }
      setIsCustomDateOpen((prev) => !prev)
      return
    } else {
      setIsCustomDateOpen(false)
    }

    if (checkIfDateChipIsActive(d)) {
      return clearFields()
    }

    if (typeof d.value === 'string') {
      setState({
        ...state,
        [keys.from]: d.value,
        [keys.to]: undefined,
      })
    } else {
      setState({
        ...state,
        [keys.from]: d.value[0],
        [keys.to]: d.value[1],
      })
    }
  }
  const fromDate = state?.[keys.from] as string

  const { setValues, setFieldValue, errors, values } = useFormikContext<FilterQueryProps>()

  return (
    <div className={styles.dateFilter}>
      <TextSmallStrong>{name}</TextSmallStrong>
      <div className={styles.chipsWrapper}>
        {dateFilters.map((d) => (
          <span className={styles.buttonItem} key={d.id}>
            <Chip
              text={d.name}
              onClick={() => handleChipDate(d)}
              isActive={checkIfDateChipIsActive(d)}
            />
          </span>
        ))}
      </div>
      {isCustomDateOpen && (
        <>
          <div className={styles.columns}>
            <div className={styles.column}>
              <div className='field'>
                <DatePicker
                  label={t('Filter Modal.From')}
                  minDate={limits?.from}
                  maxDate={limits?.to ?? allowFuture ? undefined : subDays(new Date(), 1)}
                  value={fromDate}
                  onChange={(value) => {
                    // BE require to set it to start of the day
                    value?.setHours(0, 0, 0)
                    const dateString = format(new Date(value || ''), dateTimeFormat)
                    setState({
                      ...state,
                      [keys.from]: dateString,
                    })
                    setFieldValue(keys.from, dateString)
                  }}
                  disabled={!isCustomDateOpen}
                  showLabel
                />
              </div>
            </div>
            <div className={styles.column}>
              <div className='field'>
                <DatePicker
                  label={t('Filter Modal.To')}
                  minDate={limits?.from ?? new Date(fromDate)}
                  maxDate={limits?.to ?? allowFuture ? undefined : new Date()}
                  value={(state?.[keys.to] as string) || null}
                  onChange={(value) => {
                    // BE require to set it to start of the day
                    value?.setHours(23, 59, 59)
                    const dateString = format(new Date(value || ''), dateTimeFormat)
                    setState({
                      ...state,
                      [keys.to]: dateString,
                    })
                    setFieldValue(keys.to, dateString)
                  }}
                  disabled={!isCustomDateOpen}
                  showLabel
                />
              </div>
            </div>
          </div>
          {errors[keys.to] && <span className={styles.error}>{errors[keys.to]}</span>}
        </>
      )}
    </div>
  )
}
