import React, { useCallback } from 'react'

import { getReferralCookies } from '../../../../../Referral/helpers'
import { Loading } from '../../../../../global/Loading/Loading'
import { SignupFormValues, useSignup } from '../../../../../global/context/SignupContext'
import { CreateLead } from '../../../../../model/CreateLead'
import { isCannotCreateLeadError } from '../../../../../routing/RouterToaster'
import { ResponseError, useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { getTickmillPublicDomain } from '../../../../../utils/companyName.utils'
import { getLeadReferrer } from '../../../../../utils/cookie.utils'
import { useFetchOne } from '../../../../../utils/useFetch'
import { minTwoDigits } from '../../../../../utils/validations'
import { SignupWarningMessages } from '../../../Components/SignupWarningMessages'
import { PersonalDetailsTemplate } from '../PersonalDetailsTemplate'
import {
  PersonalDetailsStep3Form,
  PersonalDetailsStep3FormValues,
} from './PersonalDetailsStep3Form'

interface OuterProps {
  step: number
  onSubmit(values: PersonalDetailsStep3FormValues): void
  totalSteps: number
  setErrorMessage: (error?: string) => void
  goBack(): void
}

export const PersonalDetailsStep3Page: React.FC<OuterProps> = (props) => {
  const { totalSteps, step, goBack, onSubmit, setErrorMessage } = props
  const { signupData, setLeadResponse, resetValues } = useSignup()

  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => {
    return Promise.all([
      apiClient.getCountries(),
      apiClient.getClientAreaLanguages({ forSignIn: true, clientAreaVisible: true }),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { data = [], isLoading } = useFetchOne(callback)
  const [countries = [], languages = []] = data

  const filteredLanguages = languages.filter((language) => language.clientAreaVisible)

  const handleSubmit = async (values: PersonalDetailsStep3FormValues) => {
    try {
      const formData = { ...signupData.values, ...values }

      const resp = await apiClient.createLead(dataToSubmit(formData))

      setLeadResponse(resp)
      setErrorMessage(undefined)
      resetValues()
      return
    } catch (err: unknown) {
      const error = err as ResponseError
      const code = error?.response?.response?.data?.code
      if (isCannotCreateLeadError(code)) {
        resetValues()
      }
      setErrorMessage(code)
      throw error
    } finally {
      onSubmit(values)
    }
  }

  return (
    <PersonalDetailsTemplate totalSteps={totalSteps} reversed step={step} goBack={goBack}>
      <SignupWarningMessages />

      <Loading showLoadingIcon isLoading={isLoading}>
        <PersonalDetailsStep3Form
          countries={countries}
          countryId={signupData.values?.countryId}
          languages={filteredLanguages}
          phoneCodes={countries.map((x) => x.phoneCode)}
          onSubmit={handleSubmit}
        />
      </Loading>
    </PersonalDetailsTemplate>
  )
}

const dataToSubmit = (values: SignupFormValues): CreateLead => {
  const referral = getReferralCookies()

  return {
    countryId: values.countryId,
    birthDay:
      values.yearOfBirth +
      '-' +
      minTwoDigits(values.monthOfBirth) +
      '-' +
      minTwoDigits(values.dayOfBirth),
    languageId: values.languageId,
    genderId: values.genderId,
    firstName: values.firstName,
    lastName: values.lastName,
    nativeName: values.nativeName,
    middleName: values.middleName,
    email: values.email,
    countryCode: values.countryCode,
    optIn: values.optIn,
    productIds: values.productIds,
    phoneNumber: values.phoneNumber,
    source: 'Live Registration',
    tickmillCompanyId: values.tickmillCompanyId,
    tickmillPublicDomain: getTickmillPublicDomain(),
    typeId: values.typeId.toString(),
    referrer: getLeadReferrer(document.referrer || referral.referrer),
    campaign: referral.utm_campaign,
    campaignSource: referral.utm_source,
    campaignMedium: referral.utm_medium,
    campaignTerm: referral.utm_term,
    campaignContent: referral.utm_content,
    referralCode: referral.referral_code || '',
    affToken: referral.aff_token || '',
    agreedLegalDocuments: values.agreedLegalDocuments || [],
  }
}
