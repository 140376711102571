import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { APKIcon } from '../../../icons/APKIcon'
import { AppStoreIcon } from '../../../icons/AppStoreIcon'
import { PlayStoreIcon } from '../../../icons/PlayStoreIcon'
import { TextLarge, TextTinyStrong } from '../../../ui/Typography/Typography'
import { isTickmillUKType } from '../../../utils/companyName.utils'
import { ExternalLink } from '../../ExternalLink'
import { useSessionEntity } from '../../context/EntityContext'

import styles from './AppMarketLink.module.scss'

interface AppMarketLinkProps {
  justIcons?: boolean
  wrapperClassName?: string
  hideCFDMessage?: boolean
  spacing?: boolean
}

export const AppMarketLink = (props: AppMarketLinkProps): JSX.Element => {
  const { t } = useTranslation()
  const { justIcons, spacing = true, wrapperClassName, hideCFDMessage } = props

  const entity = useSessionEntity()

  return (
    <div
      className={classNames(styles.wrapper, wrapperClassName, {
        [styles.justIcons]: justIcons,
        [styles.spacing]: spacing,
      })}
    >
      {!justIcons && (
        <TextLarge isParagraph className={styles.title}>
          {t('Download the Tickmill Mobile App')}
        </TextLarge>
      )}
      <span className={styles.codeWrapper}>
        <ExternalLink url='https://apps.apple.com/app/tickmill/id1548644102'>
          <AppStoreIcon />
        </ExternalLink>
      </span>

      <span className={styles.codeWrapper}>
        <ExternalLink url='https://play.google.com/store/apps/details?id=com.tickmill'>
          <PlayStoreIcon />
        </ExternalLink>
      </span>

      <span className={styles.codeWrapper}>
        <ExternalLink url='https://tickmill.link/AppReleaseLatest_apk'>
          <APKIcon />
        </ExternalLink>
      </span>

      {!hideCFDMessage && isTickmillUKType(entity) && (
        <div className='mt-5'>
          <TextTinyStrong isParagraph>
            * {t('Only available for services related to CFD')}
          </TextTinyStrong>
        </div>
      )}
    </div>
  )
}
