import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { CheckIcon } from '../../icons/CheckIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { ValidationOperationId, ValidationRuleId } from '../../model/ValidationDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'

import styles from './PasswordValidation.module.scss'

interface Validation {
  isValid: boolean
  message: string
}

const ValidationLine = ({ validation, i }: { validation: Validation; i: number }) => {
  return (
    <li key={i} className={styles.validationPoint}>
      <div className={styles.validationPointBox}>
        <div>
          {validation.isValid ? (
            <div className={styles.iconBox}>
              <CheckIcon size={18} color={'success'} />
            </div>
          ) : (
            <div className={styles.iconBox}>
              <WarningIcon color={'error'} size={20} />
            </div>
          )}
        </div>
        <div>{validation.message}</div>
      </div>
    </li>
  )
}

interface PasswordValidationReturn {
  validationComponent: JSX.Element | null
  isPasswordValid: boolean
}

interface PasswordValidationProps {
  password: string
  confirmPassword?: string
  showOneValidation?: boolean
  validationOperationId?: ValidationOperationId
  passwordResetData?: {
    email: string
    token: string
  }
  leadData?: {
    id: string
    token: string
  }
}

export const usePasswordValidation = ({
  validationOperationId = ValidationOperationId.ValidateClientPlainPassword,
  password,
  confirmPassword,
  showOneValidation,
  leadData,
  passwordResetData,
}: PasswordValidationProps): PasswordValidationReturn => {
  const { t } = useTranslation()
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)
  const passwordValidationCallback = useCallback(
    async () => {
      if (leadData) {
        return apiClient.getLeadPasswordValidationRules(leadData, locale)
      }
      if (passwordResetData) {
        return apiClient.getResetPasswordValidationRules({
          email: passwordResetData.email,
          token: passwordResetData.token,
          languageId: locale,
        })
      }
      return apiClient.getPasswordValidationRules(validationOperationId, locale)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )

  const { data: passwordValidationRules } = useFetchOne(passwordValidationCallback)
  const passwordValidations: Validation[] = []

  if (passwordValidationRules?.validationRules.length) {
    const filteredValidationRules = passwordValidationRules.validationRules.filter(
      (vRule) => vRule.shouldValidateOnFrontEnd
    )

    const newPasswordValidations = filteredValidationRules.map((rule) => {
      const flags = rule.id === ValidationRuleId.StringSplitElementCheck ? 'i' : ''
      const regex = new RegExp(rule.regex, flags)
      return { isValid: regex.test(password), message: rule.ruleText }
    })

    passwordValidations.push(...newPasswordValidations)
  }

  if (confirmPassword === '' || !!confirmPassword) {
    passwordValidations.push({
      isValid: password === confirmPassword,
      message: t('Validation.Please make sure your password match'),
    })
  }

  const validationComponent = showOneValidation ? (
    passwordValidations.filter((pV) => !pV.isValid)[0] ? (
      <ul>
        <ValidationLine i={0} validation={passwordValidations.filter((pV) => !pV.isValid)[0]} />
      </ul>
    ) : null
  ) : (
    <ul>
      {passwordValidations.map((validation, i) => (
        <ValidationLine i={i} validation={validation} key={i} />
      ))}
    </ul>
  )

  return {
    validationComponent,
    isPasswordValid: passwordValidations.every((pV) => pV.isValid),
  }
}
