import { IconProps, iconColor } from '../../../../icons/IconProps'

export function AndroidLogo(props: IconProps): JSX.Element {
  const width = props.width ?? 24
  const height = props.height ?? 24
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M17.8552 9.32823L19.8459 5.87964C19.8993 5.78707 19.9139 5.67714 19.8865 5.57386C19.8591 5.47057 19.7919 5.38233 19.6997 5.32839C19.6541 5.30164 19.6037 5.2842 19.5513 5.27708C19.499 5.26996 19.4458 5.2733 19.3947 5.2869C19.3436 5.3005 19.2958 5.3241 19.2539 5.35633C19.2121 5.38855 19.177 5.42877 19.1508 5.47464L17.1352 8.96776C15.5962 8.26464 13.8642 7.87323 12.0014 7.87323C10.1386 7.87323 8.40656 8.26511 6.86766 8.96776L4.85203 5.47464C4.79833 5.38214 4.71008 5.31477 4.6067 5.28735C4.50332 5.25992 4.39328 5.27468 4.30078 5.32839C4.20829 5.38209 4.14092 5.47034 4.11349 5.57372C4.08606 5.6771 4.10082 5.78714 4.15453 5.87964L6.14062 9.32823C2.70938 11.1864 0.383906 14.6612 0 18.729H24C23.6161 14.6612 21.2906 11.1864 17.8552 9.32823ZM6.48984 15.3587C6.29089 15.3587 6.0964 15.2997 5.93097 15.1892C5.76555 15.0786 5.63662 14.9215 5.56048 14.7377C5.48434 14.5539 5.46442 14.3516 5.50324 14.1565C5.54205 13.9614 5.63786 13.7821 5.77854 13.6415C5.91922 13.5008 6.09846 13.405 6.2936 13.3662C6.48873 13.3273 6.69099 13.3473 6.8748 13.4234C7.05861 13.4995 7.21572 13.6285 7.32625 13.7939C7.43678 13.9593 7.49578 14.1538 7.49578 14.3528C7.49566 14.6195 7.38963 14.8753 7.20101 15.0639C7.01239 15.2526 6.7566 15.3586 6.48984 15.3587ZM17.5055 15.3587C17.3067 15.3579 17.1126 15.2982 16.9478 15.1871C16.7829 15.0761 16.6546 14.9187 16.5791 14.7348C16.5036 14.5509 16.4843 14.3488 16.5236 14.1539C16.5629 13.9591 16.659 13.7802 16.7999 13.64C16.9407 13.4997 17.12 13.4043 17.315 13.3659C17.51 13.3274 17.712 13.3475 17.8956 13.4238C18.0792 13.5001 18.236 13.629 18.3464 13.7943C18.4567 13.9597 18.5156 14.154 18.5156 14.3528C18.5156 14.485 18.4894 14.616 18.4387 14.7382C18.388 14.8604 18.3137 14.9713 18.22 15.0648C18.1264 15.1582 18.0152 15.2322 17.8929 15.2827C17.7707 15.3331 17.6396 15.3589 17.5073 15.3587H17.5055Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
