import { useTranslation } from 'react-i18next'

import { WarningIcon } from '../../../icons/WarningIcon'
import { AccountType, isAccountTypeCorporate } from '../../../model/AccountType'
import { TextSmall } from '../../../ui/Typography/Typography'

interface SignupWarningMessagesProps {
  leadTypeId?: AccountType
}

export const SignupWarningMessages: React.FC<SignupWarningMessagesProps> = (props) => {
  const { leadTypeId } = props
  const { t } = useTranslation()

  return (
    <>
      <TextSmall className='is-flex text-secondary'>
        <WarningIcon size={16} color='warning' className='mr-2 mt-1' />
        {t('Sign up.Please complete the following form using Latin letters only')}
      </TextSmall>

      {leadTypeId && isAccountTypeCorporate(leadTypeId) && (
        <TextSmall className='is-flex text-secondary pt-4'>
          <WarningIcon size={16} color='warning' className='mr-2 mt-1' />
          {t(
            'Sign up.The corporate account needs to be completed with details of the corporate entity'
          )}
        </TextSmall>
      )}
    </>
  )
}
