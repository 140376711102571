import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { BackIcon } from '../../icons/BackIcon'
import { ForwardIcon } from '../../icons/ForwardIcon'
import { TextLargeStrong } from '../../ui/Typography/Typography'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'

import styles from './BackButton.module.scss'

export interface BackButtonProps {
  section?: string
  className?: string
  backUrl?: string
  backLabel?: string
  fillColor?: string
  onClick?: () => void
}

export const BackButton: React.FC<BackButtonProps> = ({ section, className, backUrl, onClick }) => {
  const navigate = useNavigate()
  const isArabic = useArabicSessionLanguage()

  return (
    <nav className={classNames(styles.wrapper, className)}>
      {backUrl && (
        <Link to={backUrl}>
          <button data-test='back-button' type='button' className='button'>
            {isArabic ? <ForwardIcon /> : <BackIcon />}
          </button>
        </Link>
      )}
      {!backUrl && (
        <button
          data-test='back-button'
          type='button'
          className='button'
          onClick={() => {
            onClick ? onClick() : navigate(-1)
          }}
        >
          {isArabic ? <ForwardIcon /> : <BackIcon />}
        </button>
      )}
      <TextLargeStrong className={styles.backLabel}>{section}</TextLargeStrong>
    </nav>
  )
}
