import React, { useRef } from 'react'
import classNames from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import type { FieldConfig as FormikFieldConfig } from 'formik/dist/Field'

import { useDynamicTextArea } from '../../hooks/useDynamicTextArea'
import { TooltipIcon } from '../../ui/Popups/Tooltip/TooltipIcon'
import { TextTiny } from '../../ui/Typography/Typography'
import { removeRestrictedSpecialCharacters } from '../../utils/validations'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'
import { DeepNonNullable } from '../types/DeepNonNullable'
import { DeepRequired } from '../types/DeepRequired'
import type { TypedFieldProps } from '../types/TypedFieldProps'
import { ValidateField } from '../types/ValidateField'
import {
  getError,
  getFieldValid,
  getLabel,
  getName,
  getPlaceholder,
  getValue,
  isFocus,
} from './helpers'

import styles from './FormField.module.scss'

type FieldConfig = Omit<FormikFieldConfig, 'name'>
interface FormFieldProps extends FieldConfig {
  namePrefix?: string
  helpText?: string
  highlighted?: boolean
  icon?: React.ReactNode
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  hint?: string | React.ReactNode
  renderLabel?: React.ReactNode
  tooltip?: string
  fullWidth?: boolean
  onChange?(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
  onFocus?(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
  onKeyDown?({ key, preventDefault }: { key: string; preventDefault: () => any }): void
  onClick?(): void
  dynamicHeight?: boolean
  as?: 'input' | 'textarea'
  wrapperClassname?: string
  validateOnType?: boolean
}

export function RawFormField<FormValues>(
  props: FormFieldProps & TypedFieldProps<FormValues>
): JSX.Element {
  const {
    namePrefix = '',
    name = '',
    icon,
    leftIcon,
    dynamicHeight,
    rightIcon,
    hint,
    renderLabel,
    type = 'text',
    showLabel = false,
    className,
    wrapperClassname,
    required,
    tooltip,
    fullWidth,
    as,
    validateOnType,
    ...inputProps
  } = props

  const dynamicHeightRef = useDynamicTextArea()

  const fieldName = getName(namePrefix, props)
  const fieldRef = useRef<HTMLDivElement>(null)
  const context = useFormikContext<FormValues>()

  const isTextArea = as === 'textarea'
  const value = getValue(context.values, props.value, name)

  const error = getError(context.errors, props.error, name)
  const touched = getIn(context.touched, name)

  const label = getLabel(props)
  const placeholder = getPlaceholder(props, value)
  const isValid = getFieldValid(error, touched, !!validateOnType && !!value)
  const isArabic = useArabicSessionLanguage()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.onChange) {
      props.onChange(event)
    } else {
      context.setFieldValue(fieldName, removeRestrictedSpecialCharacters(event.target.value))
    }
  }

  return (
    <div
      onClick={!props.disabled ? props.onClick : undefined}
      className={classNames('field', wrapperClassname, styles.field, {
        [styles.isClickable]: !props.disabled ? !!props.onClick : undefined,
        [styles.fullWidth]: fullWidth,
      })}
      ref={fieldRef}
    >
      <div className={classNames('control')}>
        <Field
          name={fieldName}
          {...inputProps}
          label={label}
          placeholder={placeholder}
          value={value}
          type={type}
          component={as}
          innerRef={dynamicHeight && isTextArea ? dynamicHeightRef : undefined}
          required={required}
          onChange={handleChange}
          className={classNames('input', styles.input, className, {
            [styles.inputFocus]: isFocus({ value, label }),
            [styles.inputError]: !isValid,
            [styles.isIconLeft]: !!leftIcon,
            [styles.isIconRight]: !!(icon || rightIcon),
            [styles.dynamicHeight]: dynamicHeight && isTextArea,
            textarea: isTextArea,
          })}
        />

        {(showLabel || isFocus({ value, label })) && !!label && (
          <label
            dir={isArabic ? 'rtl' : ''}
            className={classNames(styles.labelSecondary, {
              'has-text-danger': !isValid,
              [styles.disabled]: props.disabled,
              [styles.textareaLabel]: isTextArea,
            })}
          >
            {label}
            {tooltip && (
              <React.Fragment>
                <TooltipIcon title={tooltip} />
              </React.Fragment>
            )}
          </label>
        )}

        {renderLabel && (
          <label
            dir={isArabic ? 'rtl' : ''}
            className={classNames('is-flex', styles.labelSecondary, {
              'has-text-danger': !isValid,
              [styles.disabled]: props.disabled,
              [styles.textareaLabel]: isTextArea,
            })}
          >
            {renderLabel}
          </label>
        )}

        <span
          dir={isArabic ? 'rtl' : ''}
          className={classNames(styles.icon, styles.iconLeft, {
            [styles.inputFocus]: isFocus({ value, label }),
          })}
        >
          {leftIcon}
        </span>
        <span
          dir={isArabic ? 'rtl' : ''}
          className={classNames({ 'is-clickable': !props.disabled }, styles.icon, styles.iconRight)}
        >
          {icon || rightIcon}
        </span>
      </div>

      {hint && <TextTiny className={styles.hint}>{hint}</TextTiny>}

      {!isValid && (
        <TextTiny isParagraph className={classNames(styles.errorMessage, 'is-danger')}>
          {error}
        </TextTiny>
      )}
    </div>
  )
}

export function createFormField<FormValues>() {
  return function TypedField<Name extends ValidateField<DeepNonNullable<DeepRequired<FormValues>>>>(
    props: FormFieldProps & TypedFieldProps<DeepNonNullable<DeepRequired<FormValues>>, Name>
  ): JSX.Element {
    return <RawFormField {...props} />
  }
}
