import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ClientLandingPageDto, ClientLandingPageSettingDto } from '../../model/LandingPageDto'
import { TickmillProductType } from '../../model/TickmillProductType'
import { SelectFieldProps } from '../field/SelectField'
import { SelectModal, SelectModalOption } from '../field/SelectModal'
import { Modal } from './Modal'

interface LandingPageProductModalProps {
  title: string
  products: ClientLandingPageSettingDto[]
  onClose(): void
  onSubmit(): void
  renderItem?({ item }: { item: ClientLandingPageSettingDto }): React.ReactNode
}

export const LandingPageProductModal: React.FC<LandingPageProductModalProps> = (props) => {
  const { products, onClose, renderItem, title, onSubmit } = props
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={title}
          renderOptions={() => (
            <div className='control'>
              {products.map((x) =>
                renderItem?.({
                  item: x,
                })
              )}
            </div>
          )}
          renderFooter={() => (
            <React.Fragment>
              <button className='button' onClick={closeModal} type='button'>
                {t('Back')}
              </button>
              <button className='button' onClick={onSubmit} type='button'>
                {t('Select')}
              </button>
            </React.Fragment>
          )}
        />
      )}
    />
  )
}

interface LandingPageModalProps {
  title: string
  landingPages: ClientLandingPageDto[]
  onClose(): void
  renderItem?({ item }: { item: ClientLandingPageDto }): React.ReactNode
}

export const LandingPageModal: React.FC<LandingPageModalProps> = (props) => {
  const { landingPages, onClose, renderItem, title } = props

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={title}
          renderOptions={() => (
            <div className='control'>
              {landingPages.map((x) =>
                renderItem?.({
                  item: x,
                })
              )}
            </div>
          )}
          renderFooter={() => (
            <React.Fragment>
              <button className='button' onClick={closeModal} type='button'>
                <span className='has-text-weight-normal'>{t('Cancel')}</span>
              </button>
            </React.Fragment>
          )}
        />
      )}
    />
  )
}

interface LandingPageProductModalItemProps {
  selected: boolean
  product: ClientLandingPageSettingDto
  subModal?: SelectFieldProps
  onSelectOption(option: ClientLandingPageSettingDto): void
  label: string
}

export const LandingPageProductModalItem: React.FC<LandingPageProductModalItemProps> = (props) => {
  const { selected, product, onSelectOption, subModal, label } = props

  return (
    <SelectModalOption
      subModal={subModal}
      label={label}
      value={selected}
      onClick={() => onSelectOption(product)}
      key={product.tickmillProductId}
    />
  )
}

interface LandingPageModalItemProps {
  selected: boolean
  landingPage?: ClientLandingPageDto
  subModal?: SelectFieldProps
  onSelectOption(option: ClientLandingPageDto): void
}

export const LandingPageModalItem: React.FC<LandingPageModalItemProps> = (props) => {
  const { selected, landingPage, onSelectOption, subModal } = props
  if (!landingPage) {
    return null
  }

  return (
    <SelectModalOption
      subModal={subModal}
      label={landingPage.name}
      value={selected}
      onClick={() => onSelectOption(landingPage)}
      key={landingPage.id}
    />
  )
}
