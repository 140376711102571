import { Primitive } from '../global/types/Primitive'
import { isEmpty } from './isEmpty'

export const InputTextPattern = /[a-zA-Z0-9\\/!"#$%&'()*+,-.:;<=>?@^_`{|}\\[/\]~\\/ ]/gi

export const EmailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/

export const PasswordPattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@_.-])[a-zA-Z0-9@_.-]{1,}$/
export const PasswordSymbolPattern = /^(?=.*[@_.-])[a-zA-Z0-9@_.-]{1,}$/

export const UpperCasePattern = /[A-Z]/
export const LowerCasePattern = /[a-z]/

export const NumberPattern = /^[0-9]+$/g
export const NumberPatternWith13Length = /^[0-9_-]{13}$/
export const NumberPatternFrom0To9 = /^[0-9]*$/g
export const IntegerFrom1To8Pattern = /^0*(?:[1-8]?|8)$/
export const TwoDecimalPlacesPattern = /^\d*(\.\d{0,2})?$/
export const NonDigitPattern = /\D/g

export const SymbolPattern = /[!@#$%^&*()_+=[\]{};:<>|./?,-]/
export const SpecialCharacterSlug = /[^a-zA-Z0-9.]/g

export const ImageTypePattern = /.(?:jpg|jpeg|gif|png)/

export const FullNameLettersPattern = /^((?:Test_)*(?:TEST_)*(?:test_)*[A-Za-z-\s.@/]+)$/
export const EnglishLettersPattern = /^[A-Za-z0-9-\s./*@!#%&()^~{}+%$-]+$/
export const AddressLettersPattern = /^[A-Za-z0-9\s.,/&#-]*$/
export const CityStatePattern = /^[A-Za-z0-9\s]+$/
export const ZipCodePattern = /^[A-Za-z0-9\s-]+$/
export const RestrictedSpecialCharacters = /[㋡㋛☻〠¤¶¬¦±°•…†‡‰Œ™¨©®¹²³¼½¾ºªˉ]/g

export const EmojiPattern =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
export const UrlPattern =
  /^(?:(ftp|http|https):\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/
export const UrlHttpPattern = /^(f|ht)tps?:\/\//i
export const UUIDExpressionPattern =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
export const IBPattern = /^IB.*$/i

export const isPasswordValid = (value: string): boolean => {
  return PasswordPattern.test(value)
}

export const isPasswordSymbolValid = (value: string): boolean => {
  return PasswordSymbolPattern.test(value)
}

export type FormSubmitValues<T> = {
  [K in keyof T]: T[K] | null
}

export const validateRequired =
  (trans: string) =>
  (value: Primitive): string | undefined => {
    if (isEmpty(value)) {
      return trans
    }
    return undefined
  }

export const validateIsTrue =
  (trans: string) =>
  (value: Primitive): string | undefined => {
    if (isEmpty(value)) {
      return trans
    }

    if (value === false) {
      return trans
    }

    return undefined
  }

export const validateIsNumberPositive =
  (trans: string) =>
  (value: number): string | undefined => {
    if (isEmpty(value)) {
      return trans
    }

    if (value <= 0) {
      return trans
    }

    return undefined
  }

export const extractDigitsFromString = (value: string): string => {
  return value.replace(NonDigitPattern, '')
}
