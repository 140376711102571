import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../icons/ChevronUpIcon'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { SearchIcon } from '../../icons/SearchIcon'
import {
  TickmillProductType,
  getTickmillProductName,
  isTickmillProductTypeCFD,
} from '../../model/TickmillProductType'
import { Text, TextH3, TextStrong } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { addQuestionMark } from '../../utils/text'
import { useOnClickedOutside } from '../../utils/useOnClickedOutside'
import { useFaqQuestionsAndAnswers } from './questions'
import { faqRoutesData } from './routes'

import styles from '../SupportPage.module.scss'

type QuestionsAndAnswersType = {
  question: string
  category: string
  slug: string
  answer: ReactNode | string
  expand?: boolean
  type?: number
}

type FAQRoutesType = {
  slug: string
  label: ReactNode | string
  type: TickmillProductType
}

export const SupportFaq = (): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()
  const { product } = useProductReadContext()
  const locale = useSessionLanguage()
  const isMobile = useWindowResize()

  const faqDropdownRef = useOnClickedOutside<HTMLDivElement>(() => {
    closeSearchList()
  })

  const questionsAndAnswers: {
    [key: number]: QuestionsAndAnswersType[]
  } = useFaqQuestionsAndAnswers()

  const [searchInput, setSearchInput] = useState<string>('')
  const [selectedQuestionsAndAnswer, setSelectedQuestionsAndAnswer] =
    useState<QuestionsAndAnswersType>()
  const [searchedQuestionsAndAnswers, setSearchedQuestionsAndAnswers] = useState<
    QuestionsAndAnswersType[]
  >([])

  const faqRoutes: { [key: number]: FAQRoutesType[] } = faqRoutesData()
  const data = questionsAndAnswers[product]

  const getRouteViewData = faqRoutes?.[product]

  const slug = location.pathname.replace(/\/$/, '')

  const searchQuestionsAndAnswers = (value?: string) => {
    if (value && selectedRoute?.type) {
      const searchData = questionsAndAnswers[selectedRoute.type].filter((item) =>
        item.question?.toLowerCase().includes(value.toLowerCase())
      )

      return setSearchedQuestionsAndAnswers(searchData)
    }
  }

  const handleOnChange = (evt?: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt?.target?.value || ''

    setSearchInput(value)

    if (value) {
      return searchQuestionsAndAnswers(value)
    }

    return setSearchedQuestionsAndAnswers(
      selectedRoute?.type ? questionsAndAnswers[selectedRoute.type] : []
    )
  }

  const closeSearchList = () => {
    setSearchInput('')
    setSearchedQuestionsAndAnswers([])
  }

  useEffect(() => {
    searchQuestionsAndAnswers(searchInput)
  }, [searchInput]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    closeSearchList()
  }, [locale])

  const expandItem = (item: QuestionsAndAnswersType) => {
    const selectedAnswer = getFilteredQuestionAndAnswer()?.find((value) => {
      return value.slug === item.slug
    })
    if (selectedAnswer?.question === selectedQuestionsAndAnswer?.question) {
      setSelectedQuestionsAndAnswer(undefined)
    } else {
      selectedAnswer && setSelectedQuestionsAndAnswer(selectedAnswer)
    }
  }

  const getDefaultFAQRoute = (product: TickmillProductType) => {
    return {
      slug: `/contact-us/faq${isTickmillProductTypeCFD(product) ? '' : `/account-opening`}`,
      label: t('Support.FAQ Code'),
      type: product,
    }
  }

  const getFilteredQuestionsAndAnswers = (route: FAQRoutesType) => {
    const filterBySlug = data?.filter((item: QuestionsAndAnswersType) => slug === item.slug)

    const filterByCategory = data?.filter(
      (item: QuestionsAndAnswersType) => item.category === route?.slug
    )

    const filterByCategoryAndSlug = filterBySlug?.length ? filterBySlug : filterByCategory

    return filterByCategoryAndSlug.map((item) => ({
      ...item,
      question: addQuestionMark(item.question, locale),
    }))
  }

  const getFAQData = () => {
    const findSelectedRoute = getRouteViewData.find((item) => slug === item.slug)

    const getSelectedRoute = findSelectedRoute ? findSelectedRoute : getDefaultFAQRoute(product)

    const filteredQuestionAndAnswer = getFilteredQuestionsAndAnswers(getSelectedRoute)

    const findRouteByCategory =
      getRouteViewData.find((item) => item.slug === filteredQuestionAndAnswer?.[0]?.category) ||
      getSelectedRoute

    const faqSelectedRoute = findSelectedRoute ? getSelectedRoute : findRouteByCategory

    return faqSelectedRoute
  }

  const getFilteredQuestionAndAnswer = () => {
    const findSelectedRoute = getRouteViewData.find((item) => slug === item.slug)

    const getSelectedRoute = findSelectedRoute ? findSelectedRoute : getDefaultFAQRoute(product)

    const filteredQuestionAndAnswer = getFilteredQuestionsAndAnswers(getSelectedRoute)

    return filteredQuestionAndAnswer
  }

  const selectedRoute = getFAQData()

  return (
    <div className='columns pt-5 mt-4 container'>
      <div className='column is-one-quarter has-text-centered-mobile'>
        <div className='mb-6'>
          <TextH3 className='mb-5'>
            {getTickmillProductName(product)} {t('Support.FAQ Code')}
          </TextH3>
          <div>
            {faqRoutes[product]?.map((route: FAQRoutesType) => (
              <Link key={route.slug} to={route.slug} className={styles.link}>
                <div className='mb-2'>
                  {selectedRoute?.slug === route.slug ? (
                    <TextStrong>{route.label}</TextStrong>
                  ) : (
                    <Text>{route.label}</Text>
                  )}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className={classNames(styles.faq, 'column py-0 px-3 pt-0')}>
        <div className='mb-5 is-flex is-justify-content-space-between'>
          <TextH3 className='is-hidden-mobile is-flex is-align-items-center'>
            {getTickmillProductName(selectedRoute?.type)} / {selectedRoute?.label}
          </TextH3>
          <div
            className={classNames('dropdown is-active ', { 'is-flex-grow-5': isMobile })}
            ref={faqDropdownRef}
          >
            <div
              className={classNames('dropdown-trigger', { 'is-flex-grow-4': isMobile })}
              aria-controls='dropdown-menu'
            >
              <div className='field'>
                {!isMobile ? (
                  <div className='control has-icons-left is-fullwidth'>
                    <input
                      type='text'
                      value={searchInput}
                      onClick={() => handleOnChange()}
                      onChange={handleOnChange}
                      placeholder={t('Support.Ask your question')}
                      className={classNames(styles.search, 'input')}
                    />
                    <span className='icon is-small is-left'>
                      <SearchIcon color='textSecondary' />
                    </span>
                  </div>
                ) : (
                  <div className='control has-icons-right is-fullwidth'>
                    <input
                      type='text'
                      value={searchInput}
                      onClick={() => handleOnChange()}
                      onChange={handleOnChange}
                      placeholder={t('Support.Ask your question')}
                      className={classNames(styles.search, 'input mr-6')}
                    />
                    <span className='icon is-small is-right'>
                      <DropArrowDownIcon color='textSecondary' />
                    </span>
                  </div>
                )}
              </div>
            </div>

            {searchedQuestionsAndAnswers?.length ? (
              <div
                className={classNames('dropdown-menu faq-dropdown', styles.menu)}
                id='dropdown-menu'
                role='menu'
              >
                <div className={classNames('dropdown-content', styles.content)}>
                  {searchedQuestionsAndAnswers?.map((questionsAndAnswer) => {
                    return (
                      <Link
                        key={questionsAndAnswer.slug}
                        onClick={closeSearchList}
                        to={questionsAndAnswer.slug}
                        className={classNames('dropdown-item', styles.dropdownitem)}
                      >
                        {questionsAndAnswer?.question}
                      </Link>
                    )
                  })}
                </div>
              </div>
            ) : null}
          </div>
          {isMobile && (
            <div className='is-flex is-align-items-center pl-4'>
              <SearchIcon color='textSecondary' />
            </div>
          )}
        </div>

        {getFAQData()?.type &&
          getFilteredQuestionAndAnswer()?.map((item) => (
            <div key={item.slug} className={classNames('card p-5 mb-2', styles.faqcard)}>
              <header
                className={classNames('is-flex', {
                  'px-0 pt-0 pb-4': selectedQuestionsAndAnswer?.question === item.question,
                  'p-0': !(selectedQuestionsAndAnswer?.question === item.question),
                })}
                onClick={() => expandItem(item)}
              >
                <span className={classNames('card-header-title p-0', styles.header)}>
                  {item.question}
                </span>
                <Button
                  className='card-header-icon p-0'
                  aria-label='more options'
                  onClick={() => expandItem(item)}
                >
                  {selectedQuestionsAndAnswer?.question === item.question ? (
                    <ChevronUpIcon color='textSecondary' />
                  ) : (
                    <ChevronDownIcon color='textSecondary' />
                  )}
                </Button>
              </header>
              {selectedQuestionsAndAnswer?.question === item.question && (
                <div className='card-content p-0 text-secondary'>
                  <div className='content'>
                    <Text>{item.answer}</Text>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}
