import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { CopyButton } from '../../../global/button/CopyButton'
import { SelectableModal } from '../../../global/field/SelectableModal'
import IconButton from '../../../global/iconButton/IconButton'
import { InfoModal } from '../../../global/modal/InfoModal'
import { InformationModal } from '../../../global/modal/InformationModal'
import { Modal } from '../../../global/modal/Modal'
import { RestrictionActionModal } from '../../../global/modal/RestrictionActionModal'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { ScrollToIds, useScrollToElementIds } from '../../../hooks/useScrollToElementIds'
import { InfoIcon } from '../../../icons/InfoIcon'
import { TotalWalletBalanceDto } from '../../../model/TotalWalletBalanceDto'
import {
  WalletCurrency,
  WalletDto,
  WalletStateEnum,
  WalletTypeEnum,
} from '../../../model/WalletDto'
import { PageHeader } from '../../../ui/Table/Header/PageHeader'
import { Text, TextH3, TextSmall } from '../../../ui/Typography/Typography'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { SharedContext } from '../../../utils/SharedContext'
import { ClientApiClient } from '../../../utils/clientApi'
import { useWindowResize } from '../../../utils/domUtils'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../utils/useFetch'
import { useRedirectToSupport } from '../../../utils/useRedirectToSupport'
import { useScrollToTop } from '../../../utils/useScrollToTop'
import { isZero } from '../../../utils/validations'
import { WalletRestrictions, isRestricted } from '../../../utils/wallet.utils'
import { WalletBalancesCard } from './WalletBalancesCard'
import { WalletBalancesTable } from './WalletBalancesTable'
import { WalletOptionsModal } from './WalletOptionsModal'

import modalStyles from '../../../global/modal/CancelActionModal.module.scss'
import styles from './WalletBalances.module.scss'

interface WalletBalancesProps {
  walletType: WalletTypeEnum
}

export const WalletBalances: React.FC<WalletBalancesProps> = (props) => {
  useScrollToTop()
  const { walletType } = props
  const { t } = useTranslation()
  const location = useLocation()
  const isMobile = useWindowResize()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()
  const [sharedState, setSharedState] = useContext(SharedContext)

  const [isRestrictionModalOpen, setRestrictionModalOpen] = useState(false)
  const [hasRestrictionsModalOpen, setHasRestrictrionsModalOpen] = useState(false)
  const [isAddWalletModalOpen, setAddWalletModalOpen] = useState(false)
  const [isBalanceModalOpen, setBalanceModalOpen] = useState(false)
  const [isWalletInformationModalOpen, setWalletInformationModalOpen] = useState(false)
  const [walletOptionsData, setWalletOptionsData] = useState<WalletDto>()

  const { callback, forceRefresh } = useCallbackWithForceRefresh(async () => {
    const result = await Promise.all([
      account?.displayCurrency?.id &&
        apiClient.getTotalBalanceByCurrency(account.displayCurrency.id, WalletTypeEnum.ETD),
      apiClient.getAvailableWallets(walletType),
      apiClient.getWallets({ walletTypeId: walletType }),
      !sharedState.walletTypes.length && apiClient.getWalletTypes(),
    ])

    const [, , , walletTypes = []] = result
    setSharedState((currentState) => ({
      ...currentState,
      walletTypes: walletTypes || [],
    }))

    return result
  }, [])
  const { data = [], isLoading } = useFetchOne(callback)
  const [totalBalance, availableWalletTypes = [], wallets = []] = data

  const isWalletCreationRestricted =
    isRestricted(WalletRestrictions.CREATE_WALLET, account?.restrictions) ||
    isZero(availableWalletTypes.length)

  const clearNewWalletCreationState = () => {
    setAddWalletModalOpen(false)
    forceRefresh()
  }

  const handleNewWallet = async (data: WalletCurrency) => {
    if (data) {
      try {
        await apiClient.addWallet({
          walletTypeId: walletType,
          walletStateId: WalletStateEnum.Active,
          currencyId: data.id,
        })
      } finally {
        clearNewWalletCreationState()
      }
    }
    clearNewWalletCreationState()
  }

  const getWalletCreationRestrictionInfo = useMemo(() => {
    if (isRestricted(WalletRestrictions.CREATE_WALLET, account?.restrictions)) {
      return {
        title: 'Restricted Access',
        description: 'errors.Client is restricted to create a wallet',
      }
    }
    if (isZero(availableWalletTypes.length)) {
      return {
        title: 'Wallet.Maximum limit of Wallets reached!',
        description:
          'Wallet.You have reached the maximum limit of Wallets! Please contact our Support team for more information.',
      }
    }
    return {
      title: 'Restricted Access',
      description: 'errors.Client is restricted to create a wallet',
    }
  }, [account, availableWalletTypes])

  const { scrollIntoView } = useScrollToElementIds()

  useEffect(() => {
    if (location.state?.walletId || location.state?.wallets) {
      scrollIntoView([ScrollToIds.BalancesWalletsHeader])
    }
  }, [location])

  const handleCancelWalletInfoModal = () => {
    setWalletInformationModalOpen(false)
    !isMobile && setWalletOptionsData(undefined)
  }

  return (
    <div>
      {isRestrictionModalOpen && (
        <Modal
          closeModal={() => setRestrictionModalOpen(false)}
          render={({ closeModal }) => (
            <RestrictionActionModal
              onCancel={closeModal}
              onConfirm={() => {
                setRestrictionModalOpen(false)
              }}
            />
          )}
        />
      )}
      {walletOptionsData && !isWalletInformationModalOpen && (
        <WalletOptionsModal
          wallet={walletOptionsData}
          onWalletInformationModal={() => setWalletInformationModalOpen(true)}
          setWalletOptionsData={setWalletOptionsData}
        />
      )}

      {isWalletInformationModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={handleCancelWalletInfoModal}
          render={({ closeModal }) => (
            <WalletInformationModal
              wallet={walletOptionsData}
              onBack={closeModal}
              onCancel={handleCancelWalletInfoModal}
            />
          )}
        />
      )}
      {isAddWalletModalOpen && (
        <Modal
          closeModal={() => setAddWalletModalOpen(false)}
          render={({ closeModal }) => (
            <SelectNewWalletModal
              walletTypes={availableWalletTypes}
              openWallet={handleNewWallet}
              closeModal={closeModal}
            />
          )}
        />
      )}
      {hasRestrictionsModalOpen && (
        <Modal
          closeModal={() => setHasRestrictrionsModalOpen(false)}
          render={({ closeModal }) => (
            <WalletInfoModal
              closeModal={closeModal}
              restrictionTitle={getWalletCreationRestrictionInfo.title}
              restrictionDescription={getWalletCreationRestrictionInfo.description}
            />
          )}
        />
      )}
      {isBalanceModalOpen && (
        <Modal
          closeModal={() => setBalanceModalOpen(false)}
          render={() => (
            <InformationModal
              title={t('Wallet.Wallets Balance')}
              onCancel={() => setBalanceModalOpen(false)}
              onCancelText={t('Got It')}
            >
              <Trans
                i18nKey='Wallet.Your current balance is the total of your {{ value }} balance(s)'
                components={{
                  1: <Link title={t('Currency you selected')} to={`/profile/account-settings`} />,
                }}
                values={{ value: 'wallet' }}
              />
            </InformationModal>
          )}
        />
      )}
      <WalletPageHeader
        totalBalance={totalBalance}
        setAddWalletModalOpen={setAddWalletModalOpen}
        setBalanceModalOpen={setBalanceModalOpen}
        setHasRestrictrionsModalOpen={setHasRestrictrionsModalOpen}
        isWalletCreationRestricted={isWalletCreationRestricted}
        id={ScrollToIds.BalancesWalletsHeader}
      />
      <Loading showLoadingIcon isLoading={isLoading}>
        <div>
          {isMobile && (
            <div>
              {wallets.map((wallet) => (
                <WalletBalancesCard
                  wallet={wallet}
                  setWalletOptionsModal={setWalletOptionsData}
                  setModalVisible={setRestrictionModalOpen}
                />
              ))}
            </div>
          )}

          <WalletBalancesTable
            wallets={wallets}
            setModalVisible={setRestrictionModalOpen}
            setWalletInformationModalOpen={setWalletInformationModalOpen}
            setWalletOptionsData={setWalletOptionsData}
          />
        </div>
      </Loading>
    </div>
  )
}

interface WalletPageHeaderProps {
  isWalletCreationRestricted: boolean
  totalBalance?: TotalWalletBalanceDto
  setAddWalletModalOpen(value: boolean): void
  setHasRestrictrionsModalOpen(value: boolean): void
  setBalanceModalOpen(value: boolean): void
  id: string
}

const WalletPageHeader: React.FC<WalletPageHeaderProps> = (props) => {
  const {
    id,
    totalBalance,
    isWalletCreationRestricted,
    setAddWalletModalOpen,
    setHasRestrictrionsModalOpen,
    setBalanceModalOpen,
  } = props
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  return (
    <PageHeader
      id={id}
      className={styles.walletHeaderWrapper}
      renderTitle={() => (
        <div className={styles.header}>
          <TextSmall isParagraph className={styles.title}>
            {t('Wallet.Wallets')}
          </TextSmall>{' '}
          <IconButton onClick={() => setBalanceModalOpen(true)} className={styles.infoIcon}>
            <InfoIcon size={14} />
          </IconButton>
        </div>
      )}
      renderSubtitle={() => (
        <div className={styles.header}>
          <TextH3 className={styles.subtitle}>
            {totalBalance?.currency?.id && (
              <span>≈{formatMoney(totalBalance?.balance, totalBalance.currency.id)}</span>
            )}
          </TextH3>
        </div>
      )}
      rightRender={
        <div className={styles.actionWrapper}>
          <Button
            className={styles.action}
            appearance={'secondary'}
            disabled={isWalletCreationRestricted}
            fullWidth
            size={'S'}
            onClick={() => setAddWalletModalOpen(true)}
          >
            + {t('Wallet.Add Wallet')}
          </Button>
          {isWalletCreationRestricted && (
            <IconButton
              className={styles.infoIcon}
              onClick={() => setHasRestrictrionsModalOpen(true)}
            >
              <InfoIcon />
            </IconButton>
          )}
        </div>
      }
    />
  )
}

interface SelectNewWalletModalProps {
  walletTypes: WalletCurrency[]
  openWallet(data: WalletCurrency): void
  closeModal(): void
}

export const SelectNewWalletModal: React.FC<SelectNewWalletModalProps> = (props) => {
  const { walletTypes, openWallet, closeModal } = props

  const { t } = useTranslation()

  const [wallet, setWallet] = useState<WalletCurrency>()

  const handleAddWallet = () => {
    wallet && openWallet(wallet)
  }

  return (
    <SelectableModal
      onCancel={closeModal}
      title={t('Add New Wallet')}
      renderOptions={() => (
        <div className='control'>
          <label className={classNames('column is-full-desktop radio is-center', styles.text)}>
            <span>{t('A new wallet will be created for the specified currency.')}</span>
          </label>
          {walletTypes.map((x) => {
            return (
              <label
                key={x.id}
                className={classNames(styles.text, 'radio column is-full-desktop radio')}
              >
                <input
                  className={styles.radio}
                  onClick={() => setWallet(x)}
                  type='radio'
                  value={x.id}
                  name='option'
                />{' '}
                {x.id}
              </label>
            )
          })}
        </div>
      )}
      renderFooter={() => (
        <React.Fragment>
          <button className='button' onClick={closeModal}>
            <span className='text-small'>{t('Cancel')}</span>
          </button>
          <button className='button' disabled={!wallet} onClick={handleAddWallet}>
            <span className='text-small-strong'>{t('Add')}</span>
          </button>
        </React.Fragment>
      )}
    />
  )
}

interface WalletInfoModalProps {
  closeModal(): void
  restrictionTitle: string
  restrictionDescription: string
}

export const WalletInfoModal: React.FC<WalletInfoModalProps> = (props) => {
  const { closeModal, restrictionTitle, restrictionDescription } = props

  const { t } = useTranslation()
  const { supportUrl } = useRedirectToSupport()

  return (
    <InfoModal
      onCancel={closeModal}
      title={t(restrictionTitle)}
      renderBody={() => (
        <section className={classNames('modal-card-body', modalStyles.body)}>
          <Text
            isParagraph
            dangerouslySetInnerHTML={{
              __html: t(restrictionDescription),
            }}
          />
        </section>
      )}
      onConfirm={closeModal}
      renderFooter={() => (
        <>
          <Link to={supportUrl}>
            <button className='button has-text-weight-normal' type='button'>
              {t('Contact Support')}
            </button>
          </Link>
          <button className='button' onClick={closeModal} type='button'>
            {t('Got It')}
          </button>
        </>
      )}
    />
  )
}

interface WalletInformationModalProps {
  wallet?: WalletDto
  onBack(): void
  onCancel(): void
}

const WalletInformationModal: React.FC<WalletInformationModalProps> = (props) => {
  const { wallet, onBack, onCancel } = props

  const { t } = useTranslation()

  return (
    <InformationModal
      title={t('Wallet.Wallet Information')}
      onCancelText={t('Close')}
      onBack={onBack}
      onCancel={onCancel}
      className={styles.modal}
    >
      {wallet && (
        <div className={styles.content}>
          <p className={styles.walletNumber}>
            <span className={styles.textLabel}>{t('Wallet.Wallet number')}:</span> {wallet.name}
          </p>
          <CopyButton value={wallet.name} />
        </div>
      )}
    </InformationModal>
  )
}
