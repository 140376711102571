import { ReactNode, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { isNoTabsPath } from '../../utils/path'

import styles from './Tabs.module.scss'

export interface TabProps<T extends string> {
  id: T
  name: string
  disabled?: boolean
  required?: boolean
  path?: string
  to?: string
  className?: string
  highlight?: boolean
  onTabClicked?: () => void
}

export interface NotiifcationTabProps {
  label: string
  id: string
}

export interface TabsProps<T extends string> {
  tabs: TabProps<T>[]
  appearance?: 'large' | 'light' | 'inverse'
  currentTab: T
  onTabChanged: (newTab: T) => void
  renderTab?: (name: string, highlight?: boolean) => ReactNode
  renderIcon?: (id: string) => ReactNode
  renderDisabledTooltip?: (name: string) => string
  className?: string
  wrapperClassName?: string
}

export function defineTab<T extends string>(
  id: T,
  name: string,
  disabled = false,
  required = false,
  path?: string,
  className?: string
): TabProps<T> {
  return { id, name, disabled, required, path, className }
}

export function defineMiniTab(label: string, id: string): NotiifcationTabProps {
  return { label, id }
}

export const Tabs = <T extends string>(props: TabsProps<T>): JSX.Element => {
  const location = useLocation()
  const { tabs, appearance, currentTab, onTabChanged, className } = props
  const { renderIcon, wrapperClassName, renderTab, renderDisabledTooltip } = props

  useEffect(() => {}, [tabs])

  if (isNoTabsPath(location.pathname)) {
    return <></>
  }

  if (tabs.length < 2) {
    return <></>
  }

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <fieldset
        className={classNames(styles.tabs, className, {
          [styles.isDefault]: !appearance,
          [styles.isLight]: appearance === 'light',
          [styles.isLarge]: appearance === 'large',
          [styles.isInverse]: appearance === 'inverse',
        })}
      >
        {tabs.map((tab) => (
          <Tab
            {...tab}
            key={tab.id}
            currentTab={currentTab}
            onTabChanged={onTabChanged}
            renderIcon={renderIcon}
            renderTab={renderTab}
            renderDisabledTooltip={renderDisabledTooltip}
          />
        ))}
      </fieldset>
    </div>
  )
}

function Tab<T extends string>(
  props: TabProps<T> & {
    currentTab: T
    onTabChanged: (newTab: T) => void
    renderTab?: (name: string, highlight?: boolean) => ReactNode
    renderIcon?: (id: string) => ReactNode
    renderDisabledTooltip?: (name: string) => string
  }
) {
  const {
    id,
    name,
    path,
    to,
    required,
    disabled,
    highlight,
    currentTab,
    renderTab,
    renderIcon,
    renderDisabledTooltip,
    onTabClicked,
    onTabChanged,
    className,
  } = props

  return (
    <label
      key={id}
      className={classNames('button', 'is-small', styles.label, className, {
        disabled: disabled,
        ['selected ' + styles.selected]:
          currentTab && (currentTab === id || (path || '').includes(currentTab)),
      })}
    >
      <div
        className={classNames({
          'has-tooltip-top': disabled && renderDisabledTooltip?.(name),
        })}
        data-tooltip={(disabled && renderDisabledTooltip?.(name)) || undefined}
      >
        <input
          type='radio'
          name='tabs'
          value={id}
          defaultChecked={currentTab === id}
          onClick={(event) => {
            // TODO improve that, the reason for that is when we click on icon the tabChanged is also triggered.
            // we need move renderIcon outside label, but that breaks all of the styling, so postpone it for later on.
            setTimeout(() => {
              const value = (event.target as HTMLInputElement).value as T
              if (onTabClicked) {
                onTabClicked()
              } else {
                const p = path as T
                onTabChanged(p || value)
              }
            }, 100)
          }}
          disabled={disabled}
        />
        {!renderTab ? (
          <span className={className}>
            <span
              className={classNames(styles.tabItem, {
                [styles.isRequired]: required,
              })}
            >
              {to && (
                <span>
                  <NavLink to={to}>{name}</NavLink>
                </span>
              )}
              {!to && <span>{name}</span>}
            </span>
          </span>
        ) : (
          renderTab(name, highlight)
        )}
      </div>
      {renderIcon?.(id)}
    </label>
  )
}
