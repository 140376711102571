import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField } from '../../../global/field/TextField'
import { Modal } from '../../../global/modal/Modal'
import {
  AssignedCommissionPlan,
  CommissionPlan,
  PlanValues,
  PlanValuesKeys,
  UpdateCommissionPlan,
} from '../../../model/MultiTierDto'
import { commissionTiers } from '../../../utils/MultiTier'
import { useWindowResize } from '../../../utils/domUtils'

import styles from './MultiTierModals.module.scss'

interface AddNewPlanModalProps {
  onCancel(): void
  onSubmit(commissionPlans: UpdateCommissionPlan[], commissionPlanId?: string): void
  summary: PlanValues
  commissionPlan?: AssignedCommissionPlan
  commissionPlans: CommissionPlan[]
}

const minRebate = 0.01

export const AddEditNewPlanModal: React.FC<AddNewPlanModalProps> = (props) => {
  const { onCancel, onSubmit, summary, commissionPlan, commissionPlans } = props
  const currentTiers = commissionTiers(commissionPlan?.commissionPlans)
  const [values, setValues] = useState<PlanValues>({ ...currentTiers })
  const isMobile = useWindowResize()
  const { t } = useTranslation()

  const getFieldErrors = useCallback(
    (fieldName: keyof PlanValues) => {
      if ((values[fieldName] ?? 0) < (currentTiers[fieldName] ?? 0)) {
        return t('IB.MultiTier.Rebate cannot be decreased')
      }
      if ((values[fieldName] ?? 0) > (summary[fieldName] ?? 0)) {
        return t('IB.MultiTier.Rebate cannot be more than max rebate available')
      }
      if (!!values[fieldName] && values[fieldName]! < minRebate) {
        return t('Validation.Amount cannot be below allowed minimum of', { amount: minRebate })
      }
    },
    [currentTiers, summary, t, values]
  )

  const isValid = useMemo(
    () =>
      commissionPlans.every(
        (key) =>
          !!values[key.tradingAccountType.name as PlanValuesKeys]?.toString() &&
          !getFieldErrors(key.tradingAccountType.name as PlanValuesKeys) &&
          (values[key.tradingAccountType.name as PlanValuesKeys] ?? 0) >= 0.01
      ),
    [values, getFieldErrors, commissionPlans]
  )

  const handleSubmitForm = () => {
    const updatedCommissionPlans: UpdateCommissionPlan[] = commissionPlans?.map((plan) => ({
      tradingAccountTypeId: plan.tradingAccountType.id,
      amount: values[plan.tradingAccountType.name as keyof PlanValues] ?? 0,
    }))

    onSubmit(updatedCommissionPlans, commissionPlan?.id)
  }

  return (
    <Modal
      closeModal={onCancel}
      render={() => (
        <React.Fragment>
          <header className='modal-card-head'>
            <p className='modal-card-title'>
              {commissionPlan ? t('IB.MultiTier.Edit IB Plan') : t('IB.MultiTier.Add new Plan')}
            </p>
          </header>
          <div className={styles.addNewPlanModal}>
            <table className={styles.modalTable}>
              {!isMobile && (
                <thead>
                  <tr>
                    <th />
                    {commissionPlans?.map((plan) => {
                      return <th className={styles.headerLabel}>{plan.tradingAccountType.name}</th>
                    })}
                  </tr>
                </thead>
              )}
              <tbody>
                {isMobile && (
                  <th colSpan={3}>
                    <span>{t('IB.MultiTier.Max Rebate available')}</span>
                  </th>
                )}
                <tr>
                  {!isMobile && (
                    <td className={styles.tableLabel}>
                      <span>{t('IB.MultiTier.Max Rebate available')}</span>
                    </td>
                  )}
                  {commissionPlans?.map((plan) => (
                    <td className={styles.valueColumn}>
                      <TextField
                        label={plan.tradingAccountType.name}
                        type='number'
                        value={plan.amount}
                        readOnly
                      />
                    </td>
                  ))}
                </tr>
                {isMobile && (
                  <th colSpan={3}>
                    <span>{commissionPlan?.name || t('IB.MultiTier.New Plan')}</span>
                  </th>
                )}
                <tr>
                  {!isMobile && (
                    <td className={styles.tableLabel}>
                      <span>{commissionPlan?.name || t('IB.MultiTier.New Plan')}</span>
                    </td>
                  )}
                  {commissionPlans?.map((plan) => (
                    <td className={styles.valueColumn}>
                      <TextField
                        error={getFieldErrors(plan.tradingAccountType.name as keyof PlanValues)}
                        label={plan.tradingAccountType.name}
                        decimalPlaces={2}
                        maxDigits={2}
                        zerosLimitter
                        lockOnLimit
                        min={0.0}
                        onChange={(planAmount) => {
                          setValues((prev) => ({
                            ...prev,
                            [plan.tradingAccountType.name]: planAmount,
                          }))
                        }}
                        value={values[plan.tradingAccountType.name as keyof PlanValues]}
                        type='number'
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <footer className='modal-card-foot'>
            <button className='button' onClick={onCancel} type='button'>
              {t('Cancel')}
            </button>
            <button className='button' type='button' disabled={!isValid} onClick={handleSubmitForm}>
              {t('Submit')}
            </button>
          </footer>
        </React.Fragment>
      )}
    />
  )
}
