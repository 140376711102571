import React, { useCallback } from 'react'

import { useDocumentMarketDataIdState } from '../../../../../ProductRegistration/LegalDocuments/ReadDocumentMarketDataPage'
import { useSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { useSignup } from '../../../../../global/context/SignupContext'
import { LegalDocumentDto } from '../../../../../model/CompanyLegalDocumentDto'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { useFetchOne } from '../../../../../utils/useFetch'
import { useProducts } from '../../../hooks/useProducts'
import { FormValuesLegalDocs } from '../PersonalDetailsDocumentsFactory'
import { PersonalDetailsStep6Form } from './PersonalDetailsStep6Form'

interface OuterProps {
  shouldReceiveNewsletter?: boolean
  goBack(): void
  totalSteps: number
  onStepChange(value: number): void
  onSubmit: (values: FormValuesLegalDocs) => Promise<void>
  reversed?: boolean
  step: number
}

export const PersonalDetailsStep6Page: React.FC<OuterProps> = (props) => {
  const { totalSteps, onSubmit } = props
  const { signupData } = useSignup()
  const { marketDataClassificationId } = useDocumentMarketDataIdState()
  const { selectedProductIds } = useProducts()
  const locale = useSessionLanguage()

  const apiClient = useApiClient(ClientApiClient)

  const legalDocumentsCallback = useCallback(async () => {
    return apiClient.getLegalDocumentsQuery(
      locale,
      signupData.lead?.tickmillCompany.id,
      selectedProductIds,
      signupData.lead?.country?.id
    )
  }, [
    apiClient,
    locale,
    signupData.lead?.tickmillCompany.id,
    signupData.lead?.country?.id,
    selectedProductIds,
  ])

  const { data: documents = [], isLoading } = useFetchOne(legalDocumentsCallback)

  return (
    <PersonalDetailsStep6Form
      {...props}
      onSubmit={onSubmit}
      lead={signupData.lead}
      totalSteps={totalSteps}
      marketDataClassificationId={marketDataClassificationId}
      groupReferences={groupReferences(documents)}
      products={selectedProductIds}
      isLoading={isLoading}
      documents={documents
        .filter(filterDocumentsByClient)
        .filter(filterDuplicateDocuments)
        .sort((a, b) => a.order - b.order)}
    />
  )
}

const groupReferences = (documents: LegalDocumentDto[] = []): (string | null)[] => {
  const groupReferences = documents.map((x) => x.groupReference)

  return groupReferences.filter(function (item, pos) {
    return groupReferences.indexOf(item) === pos
  })
}

export const filterDocumentsByClient = (document: LegalDocumentDto): boolean => {
  return document.step === 'client'
}

export const filterDuplicateDocuments = (
  value: LegalDocumentDto,
  index: number,
  self: LegalDocumentDto[]
): boolean => index === self.findIndex((t) => t.id === value.id)
