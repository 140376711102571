import { ReactElement } from 'react'

import { iconColor } from './IconProps'

export const TwoCircularArrowsIcon = (props: { size?: number }): ReactElement => {
  const size = props.size || 24
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M20.3149 11C20.3149 9.30048 19.7448 7.64511 18.6873 6.27386C17.6299 4.90262 16.1399 3.88674 14.4335 3.37351C12.7271 2.86027 10.8929 2.87634 9.19673 3.41939C7.50057 3.96243 6.03064 5.00423 5 6.39377M20.3149 11L22.5 8.5M20.3149 11L17.5 9.5'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M3.68514 13C3.68514 14.6995 4.25519 16.3549 5.31266 17.7261C6.37012 19.0974 7.86006 20.1133 9.56647 20.6265C11.2729 21.1397 13.1071 21.1237 14.8033 20.5806C16.4994 20.0376 17.9694 18.9958 19 17.6062M3.68514 13L1.5 15.5M3.68514 13L6.5 14.5'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
