import React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { IntroducingBroker } from '../IntroducingBroker/IntroducingBroker'
import { Learning } from '../Learning/Learning'
import { PaymentAgent } from '../PaymentAgent/PaymentAgent'
import {
  DocumentManagementNotification,
  isDocumentManagementNotificationNeeded,
} from '../Profile/DocumentManagement/DocumentManagementPage'
import {
  YearlyKycUpdateDetailsNotification,
  isKycUpdateDetailsNotificationNeeded,
} from '../Profile/YearlyKycUpdateDetails/YearlyKycUpdateDetailsPage'
import { Tools } from '../Tools/Tools'
import { TradersRoom } from '../Traders-Room/TradersRoom'
import { SessionTimeout } from '../global/SessionTimeout/SessionTimeout'
import { DashboardTabs } from '../global/dashboard/DashboardTabs'
import { ScrollToIds } from '../hooks/useScrollToElementIds'
import { useAccountReadContext } from '../utils/AccountContextContext'
import {
  isIBRouteAllowed,
  isLearningRouteAllowed,
  isTradersRoomRouteAllowed,
} from '../utils/permission.utils'
import { useLandingPage } from '../utils/useLandingPage'

import styles from './Dashboard.module.scss'

export const DashboardRoutes: React.FC = () => {
  const { tab } = useParams<{ tab?: string }>()
  const { account } = useAccountReadContext()
  const { getLandingPage } = useLandingPage()

  if (!account) {
    return null
  }

  return (
    <div>
      <SessionTimeout />
      {isKycUpdateDetailsNotificationNeeded(account) && <YearlyKycUpdateDetailsNotification />}
      {isDocumentManagementNotificationNeeded(account) && <DocumentManagementNotification />}

      <div className={classNames('section', styles.adminRoutes)} id={ScrollToIds.DashboardTabs}>
        <DashboardTabs currentTab={tab} />
      </div>

      <Routes>
        {isTradersRoomRouteAllowed(account) && tab === 'traders-room' && (
          <Route path='/:tab/*' element={<TradersRoom />} />
        )}
        {isIBRouteAllowed(account) && tab === 'introducing-broker' && (
          <Route path='/:tab/*' element={<IntroducingBroker />} />
        )}
        {tab === 'payment-agent' && <Route path='/:tab/*' element={<PaymentAgent />} />}
        {tab === 'tools' && <Route path='/:tab/*' element={<Tools />} />}
        {isLearningRouteAllowed(account) && tab === 'learning' && (
          <Route path='/:tab/*' element={<Learning />} />
        )}

        <Route path='*' element={<Navigate to={getLandingPage()} />} />
      </Routes>
    </div>
  )
}
