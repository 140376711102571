import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'
import { v4 as uuid } from 'uuid'

import { FieldGroup } from '../../../global/form/FieldGroup'
import { createFormCheckBoxField } from '../../../global/formField/FormCheckBoxField'
import { createFormField } from '../../../global/formField/FormField'
import { Modal } from '../../../global/modal/Modal'
import { PhoneCodeSelectModal } from '../../../global/modal/PhoneCodeModal'
import { DropArrowDownIcon } from '../../../icons/DropArrowDownIcon'
import { AccountDetailedDto } from '../../../model/AccountDetailedDto'
import { CountryPreferenceStrippedDto } from '../../../model/CountryDto'
import { CountryCodeFlagIcon } from '../../../ui/CountryCodeFlagIcon/CountryCodeFlagIcon'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'

import styles from './PhoneNumberModal.module.scss'

export interface PhoneNumberAddFormValues {
  countryCode: string
  number: string
  isPrimary: boolean
}

interface OuterProps {
  account: AccountDetailedDto
  countries: CountryPreferenceStrippedDto[]
  countryId?: string
  passValues: (values: PhoneNumberAddFormValues) => void
  setIsValid: (isValid?: boolean) => void
}

const FormField = createFormField<PhoneNumberAddFormValues>()
const FormCheckBoxField = createFormCheckBoxField<PhoneNumberAddFormValues>()

const PhoneNumberAddFormUI: React.FC<FormikProps<PhoneNumberAddFormValues> & OuterProps> = (
  props
) => {
  const apiClient = useApiClient(ClientApiClient)
  const { values, passValues, setIsValid, countryId, countries, errors } = props
  const [phoneNumberError, setPhoneNumberError] = useState<string>()
  const [isCountryModalOpen, setIsCountryModelOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<CountryPreferenceStrippedDto>()

  const { t } = useTranslation()

  useEffect(() => {
    passValues(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (countryId) {
      const country = countries.find((x) => x.country.id === countryId)
      if (country) {
        !values.countryCode && props.setFieldValue('countryCode', country.phoneCode)
        setSelectedCountry(country)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId])

  const validatePhoneNumber = async () => {
    try {
      const phoneNumber = values.countryCode + values.number
      await apiClient.validateClientPhoneNumber(phoneNumber)
      setPhoneNumberError(undefined)
      setIsValid(true)
    } catch {
      setPhoneNumberError(t('Sign up.Please check your phone number'))
      setIsValid(false)
    }
  }

  const handleOnChangeOption = (country: CountryPreferenceStrippedDto) => {
    props.setFieldValue('countryCode', country.phoneCode)
    setSelectedCountry(country)
    setIsCountryModelOpen(false)
    setIsValid(undefined)
  }

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout
    setIsValid(false)

    if (!values.number || errors.number) {
      setPhoneNumberError(undefined)
      return
    }
    if (values.number.toString().length >= 4) {
      delayDebounceFn = setTimeout(async () => validatePhoneNumber(), 1000)
    }

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.number, values.countryCode, errors.number])

  return (
    <>
      {isCountryModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setIsCountryModelOpen(false)}
          render={() => (
            <PhoneCodeSelectModal
              title={t('Sign up.Phone Code')}
              options={props.countries}
              onCancel={() => setIsCountryModelOpen(false)}
              handleOnChangeOption={handleOnChangeOption}
              selectedCountry={values.countryCode}
            />
          )}
        />
      )}

      <Form autoComplete='off' className={styles.addForm}>
        <FieldGroup>
          <div className={styles.countryCode}>
            <FormField
              showLabel
              editable={false}
              onClick={() => setIsCountryModelOpen(true)}
              label={t('Sign up.Country Code')}
              autoComplete={`countryCode-${uuid()}`}
              name={'countryCode'}
              leftIcon={
                <CountryCodeFlagIcon
                  countries={props.countries}
                  countryCode={values.countryCode}
                  selectedCountry={selectedCountry}
                />
              }
              rightIcon={<DropArrowDownIcon />}
              required
            />
          </div>
          <FormField
            error={phoneNumberError}
            type='number'
            min='0'
            required
            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
            name='number'
            label={t('PhoneNumbers.Phone number')}
            placeholder={t('PhoneNumbers.Phone number')}
            className={styles.phoneNumber}
            validateOnType
          />
        </FieldGroup>

        <div className={styles.primaryCheckboxWrapper}>
          <FormCheckBoxField name='isPrimary'>
            <span className={styles.primaryCheckbox}>
              {t('PhoneNumbers.Set as primary number')}
            </span>
          </FormCheckBoxField>
        </div>
      </Form>
    </>
  )
}

export const PhoneNumberAddForm = withFormik<OuterProps, PhoneNumberAddFormValues>({
  mapPropsToValues: ({ account }) => {
    return {
      countryCode: account?.phoneNumbers.find((x) => x.isPrimary)?.countryCode || '+1',
      number: '',
      isPrimary: true,
    }
  },
  handleSubmit: () => {},
  validate: (values) => {
    const errors: FormikErrors<PhoneNumberAddFormValues> = {}
    if (!values.countryCode) {
      errors.countryCode = t('Validation.Required')
    }
    if (!values.number) {
      errors.number = t('Validation.Required')
    } else if (values.number.toString().includes('.')) {
      errors.number = t('Validation.Invalid phone number. Numbers from 0 to 9.')
    }

    return errors
  },
  enableReinitialize: true,
})(PhoneNumberAddFormUI)
