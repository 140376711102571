import React from 'react'
import { useTranslation } from 'react-i18next'

import { DropArrowDownIcon } from '../../../icons/DropArrowDownIcon'
import { DropArrowUpIcon } from '../../../icons/DropArrowUpIcon'
import { IBNetworkPlans } from '../../../model/MultiTierDto'
import { TextSmall, TextSmallStrong } from '../../../ui/Typography/Typography'

import styles from './MultiTierCards.module.scss'

interface MultiTierNetworkCardProps {
  data: IBNetworkPlans
  cardId: string
}

export const MultiTierNetworkCard: React.FC<MultiTierNetworkCardProps> = ({ data, cardId }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const { t } = useTranslation()

  return (
    <div className={styles.card} id={cardId}>
      <header className={styles.cardHeader}>
        <TextSmallStrong className={styles.cardTitle}>
          {data.introducingBroker.name}
        </TextSmallStrong>
        {!isExpanded && (
          <div className={styles.expandIcon}>
            <DropArrowDownIcon
              className={styles.expandableRowIcon}
              onClick={() => setIsExpanded(true)}
            />
          </div>
        )}
        {isExpanded && (
          <div className={styles.expandIcon}>
            <DropArrowUpIcon
              className={styles.expandableRowIcon}
              onClick={() => setIsExpanded(false)}
            />
          </div>
        )}
      </header>

      <TextSmall isParagraph className={styles.title}>
        <span className={styles.label}>{t('IB.MultiTier.Level')}</span>
        <TextSmallStrong className={styles.value}>{data.level}</TextSmallStrong>
      </TextSmall>
      <TextSmall isParagraph className={styles.title}>
        <span className={styles.label}>{t('IB.MultiTier.Clients')}</span>
        <TextSmallStrong className={styles.value}>{data.numberOfClients}</TextSmallStrong>
      </TextSmall>

      {isExpanded &&
        data.subAccounts.map((subAccount) => (
          <div className={styles.subAccount}>
            <header className={styles.cardHeader}>
              <TextSmallStrong className={styles.cardTitle}>
                {subAccount.introducingBroker.name}
              </TextSmallStrong>
            </header>

            <TextSmall isParagraph className={styles.title}>
              <span className={styles.label}>{t('IB.MultiTier.Level')}</span>
              <span className={styles.value}>{subAccount.level}</span>
            </TextSmall>
            <TextSmall isParagraph className={styles.title}>
              <span className={styles.label}>{t('IB.MultiTier.Clients')}</span>
              <TextSmallStrong className={styles.value}>
                {subAccount.numberOfClients}
              </TextSmallStrong>
            </TextSmall>
          </div>
        ))}
    </div>
  )
}
