import Cookies from 'js-cookie'

import { getDomainName } from '../utils/cookie.utils'

interface ReferralParamsOrCookieForRedirect {
  utm_campaign: string
  utm_content: string
  utm_medium: string
  utm_source: string
  utm_term: string
  referrer: string
}

export interface ReferralParams extends ReferralParamsOrCookieForRedirect {
  aff_token: string
  sgl_link: string
  referral_code: string
  landingPageUrl: string
}

export const isReferralLink = (params: ReferralParams): boolean => {
  return !!(params.utm_campaign === 'ib_link' && params.utm_content)
}

export const isSGLLink = (params: Partial<ReferralParams>): boolean => {
  return !!(params.utm_campaign === 'sgl_link' && !!params.utm_content)
}

export const isLandingPage = (params: ReferralParams): boolean => {
  return !!params?.landingPageUrl
}

export const isReferralLandingPage = (params: ReferralParams): boolean => {
  return (
    params.landingPageUrl?.includes('tickmill.') ||
    params.landingPageUrl?.includes('tickmill-id.') ||
    params.landingPageUrl?.includes('tick-mill.') ||
    params.landingPageUrl?.includes('tickmill-cn.')
  )
}

export const isReferralDefault = (params: Partial<ReferralParams>): params is ReferralParams => {
  return params.utm_campaign !== 'ib_link'
}

export const isAffiliatesParams = (params: Partial<ReferralParams>): params is ReferralParams => {
  return !!params.aff_token
}

export const isReferralCode = (params: Partial<ReferralParams>): params is ReferralParams => {
  return !!params.referral_code
}

export const isReferralCodeIB = (params: Partial<ReferralParams>): params is ReferralParams => {
  const referralCodeIB = 'ib'
  return !!params?.referral_code?.toLowerCase().includes(referralCodeIB)
}

export const isAffTokenAndReferralCode = (
  params: Partial<ReferralParams>
): params is ReferralParams => {
  return isAffiliatesParams(params) && isReferralCode(params)
}

export const isAffTokenAndReferralCodeEmpty = (
  params: Partial<ReferralParams>
): params is ReferralParams => {
  return !isAffiliatesParams(params) && !isReferralCode(params)
}

export const getReferralParamsOrCookie = (search: string): ReferralParams => {
  const referralCookieParams = getReferralCookies()
  const referralParams = getReferralParams(search)

  return {
    utm_campaign: referralCookieParams.utm_campaign || referralParams.utm_campaign,
    utm_content: referralCookieParams.utm_content || referralParams.utm_content,
    utm_medium: referralCookieParams.utm_medium || referralParams.utm_medium,
    utm_source: referralCookieParams.utm_source || referralParams.utm_source,
    utm_term: referralCookieParams.utm_term || referralParams.utm_term,
    aff_token: referralCookieParams.aff_token || referralParams.aff_token,
    sgl_link: referralCookieParams.sgl_link || referralParams.sgl_link,
    referrer: referralCookieParams.referrer || referralParams.referrer || document.referrer,
    referral_code: referralCookieParams.referral_code || referralParams.referral_code,
    landingPageUrl: referralCookieParams.landingPageUrl || referralParams.landingPageUrl,
  }
}

export const getReferralCookies = (): ReferralParams & { referrer: string } => {
  const utm_campaign = Cookies.get('utm_campaign') || ''
  const utm_content = Cookies.get('utm_content') || ''
  const utm_medium = Cookies.get('utm_medium') || ''
  const utm_source = Cookies.get('utm_source') || ''
  const utm_term = Cookies.get('utm_term') || ''
  const aff_token = Cookies.get('aff_token') || ''
  const sgl_link = Cookies.get('sgl_link') || ''
  const referrer = Cookies.get('referrer') || ''
  const referral_code = Cookies.get('referralCode') || Cookies.get('referral_code') || ''
  const landingPageUrl = Cookies.get('lp') || ''

  return {
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
    aff_token,
    sgl_link,
    referrer,
    referral_code,
    landingPageUrl,
  }
}

export const getReferralParams = (search: string): ReferralParams => {
  const params = new URLSearchParams(search)
  const utm_campaign = params.get('utm_campaign') || ''
  const utm_content = params.get('utm_content') || ''
  const utm_medium = params.get('utm_medium') || ''
  const utm_source = params.get('utm_source') || ''
  const utm_term = params.get('utm_term') || ''
  const aff_token = params.get('aff_token') || params.get('afftoken') || ''
  const sgl_link = params.get('sgl_link') || ''
  const landingPageUrl = params.get('lp') || ''
  const referrer = params.get('referrer') || ''
  const referral_code = Cookies.get('referralCode') || Cookies.get('referral_code') || ''

  return {
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
    aff_token,
    sgl_link,
    referrer,
    referral_code,
    landingPageUrl,
  }
}

export const getReferralParamsOrCookieForRedirect = (
  search: string
): ReferralParamsOrCookieForRedirect => {
  const referralParams = getReferralParamsOrCookie(search)

  return {
    utm_campaign: referralParams.utm_campaign,
    utm_content: referralParams.utm_content,
    utm_medium: referralParams.utm_medium,
    utm_source: referralParams.utm_source,
    utm_term: referralParams.utm_term,
    referrer: referralParams.referrer,
  }
}

export const setReferralParams = (params: ReferralParams): void => {
  Cookies.set('utm_campaign', params.utm_campaign, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_content', params.utm_content, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_medium', params.utm_medium, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_source', params.utm_source, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_term', params.utm_term, { domain: getDomainName(), expires: 365 })
  Cookies.set('aff_token', params.aff_token, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('sgl_link', params.sgl_link, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('lp', params.landingPageUrl, { domain: getDomainName(), expires: 365 })
  Cookies.set('referrer', document.referrer, {
    domain: getDomainName(),
    expires: 365,
  })
  if (isAffiliatesParams(params)) {
    removeReferralCookie()
  }
}

export const setAffiliatesParamsOrCookie = (params: ReferralParams): void => {
  Cookies.set('aff_token', params.aff_token, {
    domain: getDomainName(),
    expires: 365,
  })
  if (isAffiliatesParams(params)) {
    removeReferralCookie()
  }
}

export const setSGLParamsOrCookie = (params: ReferralParams): void => {
  Cookies.set('utm_campaign', params.utm_campaign, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_content', params.utm_content, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_medium', params.utm_medium, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_source', params.utm_source, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_term', params.utm_term, { domain: getDomainName(), expires: 365 })
  Cookies.set('aff_token', params.aff_token, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('sgl_link', params.sgl_link, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('lp', params.landingPageUrl, { domain: getDomainName(), expires: 365 })
  Cookies.set('referralCode', params.utm_content, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('referrer', document.referrer, {
    domain: getDomainName(),
    expires: 365,
  })
  if (isAffiliatesParams(params)) {
    removeAffiliatesCookie()
  }
}

export const setReferralParamsOrCookie = (params: ReferralParams): void => {
  const referralCookieParams = getReferralCookies()
  Cookies.set('utm_campaign', referralCookieParams?.utm_campaign || params.utm_campaign, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_content', referralCookieParams?.utm_content || params.utm_content, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_medium', referralCookieParams?.utm_medium || params.utm_medium, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_source', referralCookieParams?.utm_source || params.utm_source, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('utm_term', referralCookieParams?.utm_term || params.utm_term, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('lp', referralCookieParams?.landingPageUrl || params.landingPageUrl, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('referrer', referralCookieParams?.referrer || document.referrer, {
    domain: getDomainName(),
    expires: 365,
  })
  Cookies.set('aff_token', referralCookieParams?.aff_token || params.aff_token, {
    domain: getDomainName(),
    expires: 365,
  })
  if (isAffiliatesParams(params)) {
    removeReferralCookie()
  }
}

export const setReferralCode = (params: ReferralParams): void => {
  Cookies.set('referralCode', params.utm_content, {
    domain: getDomainName(),
    expires: 365,
  })
}

const removeReferralCookie = () => {
  Cookies.remove('referralCode', {
    path: '/',
    domain: getDomainName(),
  })
  Cookies.remove('referral_code', {
    path: '/',
    domain: getDomainName(),
  })
}

const removeAffiliatesCookie = () => {
  Cookies.remove('aff_token', {
    path: '/',
    domain: getDomainName(),
  })
}

export const hasIBCodeParameter = (search: string): boolean => {
  const parameters = getReferralParams(search)
  return !!parameters.utm_content
}
