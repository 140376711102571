import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useSessionLanguage } from './context/SessionSettingsContext'

export const LocaleModule = () => {
  const appLocale = useSessionLanguage()
  const [pathName, setPathName] = useState<string>(window.location.pathname)

  useEffect(() => {
    if (pathName.substring(1, 3) !== appLocale) {
      setPathName('/' + appLocale + pathName.slice(3))
    }
    if (window.location.pathname) {
      window.history.replaceState(
        null,
        '',
        '/' + appLocale + window.location.pathname.slice(3) + window.location.search
      )
    }
  }, [appLocale, pathName])

  function useLocationEffect(callback: (location?: ReturnType<typeof useLocation>) => void) {
    const location = useLocation()

    useEffect(() => {
      callback(location)
    }, [location, callback])
  }

  useLocationEffect(() => setPathName(window.location.pathname))

  return null
}
