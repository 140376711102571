import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TransactionBadgeStatus } from '../../Traders-Room/Transaction-History/TransactionBadgeStatus'
import { getCreditTransaction } from '../../Traders-Room/Transaction-History/TransactionHistoryTable'
import { Loading } from '../../global/Loading/Loading'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import IconButton from '../../global/iconButton/IconButton'
import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table, TableBody, TableCell } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../icons/ChevronUpIcon'
import { DotsIcon } from '../../icons/DotsIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import {
  TransactionDto,
  isTransactionTradingAccountBalanceConversion,
} from '../../model/TransactionDto'
import { TransactionStatus } from '../../model/TransactionStatus'
import { CurrencyType } from '../../model/WalletDto'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { Text, TextSmall } from '../../ui/Typography/Typography'
import { PageQuery } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import {
  TransactionType,
  getIBTransactionFieldsByTransactionType,
  getTransactionType,
} from '../../utils/transaction.utils'
import { isOne, isZero } from '../../utils/validations'

import styles from './IntroducingBrokerTransactionHistoryPage.module.scss'

interface Props {
  data: TransactionDto[]
  isLoading: boolean
  activeFilters: number
  pageQuery?: PageQuery
  setPageQuery: ((pageQuery?: PageQuery | undefined) => void) | undefined

  setHistoryModal(v: boolean): void

  setSelectedTransaction(id: string): void

  setOptionModal({
    data,
    visible,
    step,
  }: {
    data?: TransactionDto
    visible: boolean
    step: number
  }): void
}

export const IntroducingBrokerTransactionHistoryTable: React.FC<Props> = ({
  data,
  activeFilters,
  pageQuery,
  setPageQuery,
  setHistoryModal,
  setSelectedTransaction,
  isLoading,
  setOptionModal,
}) => {
  const [expandedRow, setExpandedRow] = useState('')
  const [infoModal, setInfoModal] = useState(false)
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const { formatMoney } = useFormatNumber()
  const { scrollIntoView } = useScrollToElementIds()

  const handleSetExpandedRow = (id: string, rowIndex: number) => {
    if (id === expandedRow) {
      id = ''
    }
    id && scrollIntoView([`ib-transaction-history-item-${rowIndex + 1}`])
    setExpandedRow(id)
  }

  const handleSetTransactionHistoryModal = (id: string) => {
    setSelectedTransaction(id)
    setHistoryModal(true)
  }
  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()

  return (
    <React.Fragment>
      {infoModal && (
        <Modal
          closeModal={() => setInfoModal(false)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('IB.Transaction exchange rate')}
              renderBody={() => (
                <section className='modal-card-body'>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'IB.Amounts based on currencies which are denominated in foreign currencies'
                      ),
                    }}
                  />
                </section>
              )}
              onConfirm={() => {
                setInfoModal(false)
              }}
            />
          )}
        />
      )}
      <Table spacing='none' className={styles.table}>
        <Loading showLoadingIcon isLoading={isLoading}>
          <thead>
            <tr>
              <SortHeader
                id='TransactionType.name'
                sort={(pageQuery && pageQuery!.sort) || undefined}
                sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
                setSort={(sort, sortOrder) =>
                  setPageQuery!({
                    ...pageQuery,
                    sort,
                    sortOrder,
                  })
                }
                className={styles.customRow}
              >
                {t('Type')}
              </SortHeader>
              <SortHeader
                id='CreatedDate'
                sort={(pageQuery && pageQuery!.sort) || undefined}
                sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
                setSort={(sort, sortOrder) =>
                  setPageQuery!({
                    ...pageQuery,
                    sort,
                    sortOrder,
                  })
                }
              >
                {t('Date')}
              </SortHeader>
              <th className={styles.dateRow}>{t('From')}</th>
              <th className={styles.dateRow}>{t('To')}</th>
              <th>{t('Wallet.Reference number')}</th>
              <SortHeader
                id='TransactionState.name'
                align='center'
                sort={(pageQuery && pageQuery!.sort) || undefined}
                sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
                setSort={(sort, sortOrder) =>
                  setPageQuery!({
                    ...pageQuery,
                    sort,
                    sortOrder,
                  })
                }
                className='is-flex is-justify-content-center'
              >
                {t('Status')}
              </SortHeader>
              <th className={styles.amountRow}>{t('Amount')}</th>
              <th />
            </tr>
          </thead>
          {!data.length && (
            <tr>
              <td align='center' colSpan={8}>
                <NoResults
                  subtitle={activeFilters > 0 ? t('No results') : undefined}
                  hideLink={activeFilters > 0}
                />
              </td>
            </tr>
          )}

          {data?.map((transaction, index) => {
            const transactionType = getTransactionType(transaction.type.id)
            const fields = getIBTransactionFieldsByTransactionType(
              transactionType,
              transaction,
              isArabic,
              auth?.id
            )
            const showMoreMenu =
              (transaction.type.id === TransactionType.WithdrawToPaymentAgent ||
                transaction.type.id === TransactionType.WalletWithdrawal) &&
              transaction.state.id === TransactionStatus.AwaitingApproval

            const rate = Math.round(fields.rate * 100) / 100

            const isCrypto = fields.currency === 'BTC'

            const creditTransaction = transaction.walletTransactions.find(
              (x) => x.directionType.name === 'Credit'
            )
            const cryptoCurrencyName = creditTransaction?.otherCurrency.name

            const showCurrency =
              isCrypto && cryptoCurrencyName && transaction.state.id === TransactionStatus.Completed

            const shouldHideRate = isOne(rate) || isZero(rate)

            const isDebitTransaction = transactionType === TransactionType.WalletWithdrawal

            const isConvertedFromShown =
              transactionType === TransactionType.TradingAccountBalanceConversion ||
              transactionType === TransactionType.WalletTransfer ||
              transactionType === TransactionType.WalletDeposit ||
              transactionType === TransactionType.WalletWithdrawal

            const isExpandable = !shouldHideRate

            const isExpanded = expandedRow === transaction.id && isExpandable

            return (
              <TableBody
                margined
                spacing='none'
                key={transaction.id}
                id={`ib-transaction-history-item-${index + 1}`}
              >
                <tr
                  className={classNames({
                    [styles.expandableParent]: isExpanded,
                  })}
                >
                  <td
                    className={classNames(styles.nameRow, {
                      ['has-cursor-pointer']: isExpandable,
                    })}
                    onClick={() => handleSetExpandedRow(transaction.id, index)}
                  >
                    <span className='is-flex is-align-center'>
                      {isExpandable && (
                        <span className='is-flex pr-2'>
                          {isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
                        </span>
                      )}
                      <strong className={styles.trimMid} title={transaction.type.name}>
                        {transaction.type.name}
                      </strong>
                    </span>
                  </td>
                  <td className={styles.dateRow}>
                    {formatDate(transaction.createdDate, dateFormat)}
                  </td>
                  <td
                    className={classNames(styles.trimShort, styles.dateRow)}
                    title={fields.from}
                    dir={isArabic ? 'ltr' : ''}
                  >
                    {fields.from}
                  </td>
                  <td className={styles.trimShort} title={fields.to} dir={isArabic ? 'ltr' : ''}>
                    {fields.to}
                  </td>
                  <td className={styles.trimLong}>{transaction.referenceId}</td>
                  <td align='center' className={classNames('has-cursor-pointer', styles.customRow)}>
                    <span onClick={() => handleSetTransactionHistoryModal(transaction.id)}>
                      <TransactionBadgeStatus {...transaction.state} />
                    </span>
                  </td>
                  <td className={styles.amountRow}>
                    {formatMoney(fields.amount, fields.currency as CurrencyType)}
                  </td>
                  {showMoreMenu ? (
                    <td>
                      <span
                        className='has-cursor-pointer'
                        onClick={() =>
                          setOptionModal({
                            step: 1,
                            visible: true,
                            data: transaction,
                          })
                        }
                      >
                        <DotsIcon />
                      </span>
                    </td>
                  ) : (
                    <td />
                  )}
                </tr>
                {isExpanded && (
                  <tr className={styles.expandable}>
                    <TableCell expanded colSpan={8}>
                      <div className={classNames('px-4 py-4', styles.container)}>
                        {isConvertedFromShown && (
                          <div className={styles.convertedFrom}>
                            <TextSmall className={styles.secondaryTextSmall}>
                              {t('IB.Converted from')}:{' '}
                            </TextSmall>
                            <span className={styles.textSmall}>
                              {isDebitTransaction
                                ? formatMoney(fields.amount, fields.currency as CurrencyType)
                                : formatMoney(
                                    getCreditTransaction(transactionType, transaction)?.otherAmount,
                                    getCreditTransaction(transactionType, transaction)
                                      ?.otherCurrency?.id
                                  )}
                            </span>
                          </div>
                        )}
                        {!shouldHideRate && (
                          <div className={styles.exchangeRate}>
                            <TextSmall className={styles.secondaryTextSmall}>
                              {t('IB.Transaction exchange rate')}:{' '}
                            </TextSmall>
                            <span className={styles.textSmall}>
                              {rate}
                              {!isTransactionTradingAccountBalanceConversion(transaction) && (
                                <IconButton
                                  className={styles.infoIcon}
                                  onClick={() => setInfoModal(true)}
                                >
                                  <InfoIcon />
                                </IconButton>
                              )}
                            </span>
                          </div>
                        )}
                        {showCurrency && (
                          <div>
                            <span className='p-2 text-secondary'>{t('Currency')}:</span>{' '}
                            {cryptoCurrencyName}
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </tr>
                )}
              </TableBody>
            )
          })}
        </Loading>
      </Table>
    </React.Fragment>
  )
}
