import React from 'react'
import { useTranslation } from 'react-i18next'

import { InfoModal } from '../../../global/modal/InfoModal'
import { Modal } from '../../../global/modal/Modal'
import { PlanValuesKeys } from '../../../model/MultiTierDto'
import { Text, TextStrong } from '../../../ui/Typography/Typography'

import styles from './MultiTierModals.module.scss'

interface CommissionTableInfoModalProps {
  setInfoModal: React.Dispatch<React.SetStateAction<boolean>>
  availablePlans?: PlanValuesKeys[]
}

export const CommissionTableInfoModal: React.FC<CommissionTableInfoModalProps> = ({
  setInfoModal,
  availablePlans,
}) => {
  const { t } = useTranslation()

  const renderListItem = (level: string) => (
    <>
      <TextStrong>{level}</TextStrong>
      <ul>
        <li className={styles.infoList}>
          {t('IB.MultiTier.Commission that will be assigned for 1 lot', {
            level: level,
          })}
        </li>
      </ul>
      <br />
    </>
  )

  return (
    <Modal
      closeModal={() => setInfoModal(false)}
      render={({ closeModal }) => (
        <InfoModal
          onCancel={closeModal}
          title={t('IB.MultiTier.IB Commission Plans')}
          renderBody={() => (
            <section className='modal-card-body'>
              <TextStrong>{t('IB.MultiTier.IB Commission Plan')}</TextStrong>
              <ul>
                <li className={styles.infoList}>
                  {t('IB.MultiTier.Create and manage up to 3 Commission Plans')}
                </li>
              </ul>
              <br />
              {availablePlans?.map((plan) => renderListItem(t(`IB.MultiTier.${plan}`)))}
              <Text>{t('IB.MultiTier.After you create an IB Commission Plan')}</Text>
            </section>
          )}
          renderFooter={() => (
            <button className='button' onClick={closeModal} type='button'>
              {t('Got It')}
            </button>
          )}
          onConfirm={closeModal}
        />
      )}
    />
  )
}
