import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import IconButton from '../../global/iconButton/IconButton'
import { SortHeader, SortHeaderAlignType } from '../../global/sortHeader/SortHeader'
import { Table, TableNoResults } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { InfoIcon } from '../../icons/InfoIcon'
import { ClientRebateCampaignDto } from '../../model/CampaignResultDto'
import { ClientCampaignRebateResultDto } from '../../model/ClientCampaignRebateResultDto'
import {
  ClientRebateCampaignDetailDto,
  isCampaignCalculationAccount,
  isCampaignClientRebate,
} from '../../model/ClientRebateCampaignDetailDto'
import { PageQuery } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { getCentralRowIdConditionally } from '../../utils/getItemId'

interface CampaignRebateCardsProps {
  sortOptions: SortOptionsType
  campaign: ClientRebateCampaignDto
  campaignDetails?: ClientRebateCampaignDetailDto

  data: ClientCampaignRebateResultDto[]
  currentRound?: Date | null

  pageQuery?: PageQuery
  setPageQuery(value: PageQuery): void

  setInfoModal(v: boolean): void
}

type SortOptionsType = { id: string; name: string; align: SortHeaderAlignType }[]

export const CampaignRebateTable: React.FC<CampaignRebateCardsProps> = (props) => {
  const { sortOptions, data, pageQuery, campaignDetails, setPageQuery } = props

  return (
    <Table>
      <thead>
        <tr>
          {sortOptions.map((x) => (
            <SortHeader
              id={x.id}
              sort={pageQuery?.sort || undefined}
              sortOrder={pageQuery?.sortOrder}
              setSort={(sort, sortOrder) => {
                setPageQuery!({
                  ...pageQuery,
                  sort,
                  sortOrder,
                })
              }}
              align={x.align || 'center'}
              key={x.id}
            >
              {x.name}
            </SortHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {!data.length ? (
          <TableNoResults />
        ) : (
          data.map((roundResult, index) => (
            <CampaignRebateTableItem
              {...props}
              item={roundResult}
              campaignDetails={campaignDetails}
              key={index}
              {...getCentralRowIdConditionally(index, data.length, 'campaign-total-rebate')}
            />
          ))
        )}
      </tbody>
    </Table>
  )
}

interface CampaignRebateTableItemProps {
  item: ClientCampaignRebateResultDto
  campaign: ClientRebateCampaignDto
  campaignDetails?: ClientRebateCampaignDetailDto
  currentRound?: Date | null
  id?: string

  setInfoModal(v: boolean): void
}

const CampaignRebateTableItem: React.FC<CampaignRebateTableItemProps> = (props) => {
  const { item, campaign, campaignDetails, currentRound, setInfoModal, id } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const [auth] = useContext(AuthSessionContext)

  const dateFormat = auth?.dateFormatType?.name

  return (
    <tr id={id}>
      <td>
        <span className='is-flex is-justify-content-flex-start'>
          {new Date(item.round).getTime() === currentRound?.getTime()
            ? 'Current Round'
            : formatDate(item.round, dateFormat)}
        </span>
      </td>
      <td>
        <span className='is-flex is-justify-content-center'>
          {item.lots} {t('Campaigns.Lots')}
        </span>
      </td>
      {hasRebateValueColumn(campaignDetails) && (
        <td>
          <span className='is-flex is-justify-content-center'>{item.rebateValue || '0'}</span>
        </td>
      )}
      <td>
        <span className={'is-flex is-justify-content-center'}>
          {formatMoney(item.rebate, campaign.currency.id)}
          {item.rebate > 0 && item.rebate < 1 && (
            <span className='ml-2'>
              <IconButton onClick={() => setInfoModal(true)}>
                <InfoIcon />
              </IconButton>
            </span>
          )}
        </span>
      </td>
    </tr>
  )
}

const hasRebateValueColumn = (campaignDetails?: ClientRebateCampaignDetailDto) => {
  return isCampaignClientRebate(campaignDetails) && !isCampaignCalculationAccount(campaignDetails)
}
