import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useSessionEntity } from '../../global/context/EntityContext'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { isTickmillEUType, isTickmillUKType } from '../../utils/companyName.utils'

import styles from './W8BeneficialPage.module.scss'

export const W8BeneficialEPage = () => {
  const { t } = useTranslation()
  const entity = useSessionEntity()

  const getEntityEmail = (entity: number): string => {
    if (isTickmillUKType(entity)) {
      return 'backoffice@tickmill.co.uk'
    }
    if (isTickmillEUType(entity)) {
      return 'backoffice@tickmill.eu'
    }
    return 'backoffice@tickmill.com'
  }

  return (
    <>
      <PageHeader title={t('Profile.W-8BEN-e')} />

      <div className={classNames(styles.container, 'box mt-4')}>
        <div className={classNames(styles.info, 'p-5')}>
          {t('Profile.Corporate clients are required to complete the W-8BEN-E form')}{' '}
          <a href='https://www.irs.gov/pub/irs-pdf/fw8bene.pdf' className='is-link' target='_blank'>
            {t('here')}
          </a>{' '}
          {`${t('Profile.Document management in Client Area')}`}{' '}
          <a
            title={getEntityEmail(entity)}
            href={`mailto:${getEntityEmail(entity)}`}
            className='is-link'
          >
            {getEntityEmail(entity)}
          </a>
        </div>
      </div>
    </>
  )
}
