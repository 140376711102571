import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../global/button/Button'
import { Text } from '../../ui/Typography/Typography'

import styles from './TermsConditions.module.scss'

interface Props {
  terms: string[]
  onCancel(): void
}
export const TermsConditions: FC<Props> = ({ terms = [], onCancel }) => {
  const { t } = useTranslation()

  return (
    <>
      <ul className={styles.termsAndConditions}>
        {terms.map((term, index) => (
          <li key={index}>
            <Text>{term}</Text>
          </li>
        ))}
      </ul>
      <div className='container is-flex is-justify-content-center mt-6'>
        <Button appearance='primary' size='L' className={styles.button} onClick={onCancel}>
          {t('Okay')}{' '}
        </Button>
      </div>
    </>
  )
}
