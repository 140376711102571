import { AxiosResponse } from 'axios'

import { ForgotPasswordFormValues } from '../ForgotPassword/ForgotPasswordForm'
import { ResetPasswordCompleteFormValues } from '../ForgotPassword/ResetPasswordPage'
import { LoginFormValues } from '../Login/LoginForm'
import { ChangePasswordFormValues } from '../Profile/AccountSettings/ChangePasswordForm'
import { CommunicationLanguageFormValues } from '../Profile/AccountSettings/CommunicationLanguageForm'
import { DateFormatFormValues } from '../Profile/AccountSettings/DateFormatsForm'
import { PhoneNumberAddFormValues } from '../Profile/PhoneNumbers/Modals/PhoneNumberAddForm'
import { ChangeTradingAccountPasswordValues } from '../Traders-Room/Trading-Accounts/ChangeTradingAccountPasswordForm'
import { DemoTradingAccountDepositFormValues } from '../Traders-Room/Trading-Accounts/TradingAccountDeposit/DemoTradingAccountDeposit/DemoTradingAccountDepositForm'
import { Locale } from '../global/locale/Locale'
import { AccountDetailedDto, ClientPhoneNumberDto } from '../model/AccountDetailedDto'
import { AccountGroupType } from '../model/AccountGroupType'
import { AccountSummaryDto } from '../model/AccountSummaryDto'
import { AgreedClientDocumentDto } from '../model/AgreedClientDocumentDto'
import { AutochartistDto } from '../model/AutochartistDto'
import { BankAccount, BankAccountFormValues } from '../model/BankAccount'
import { BonusTransactionDto } from '../model/BonusTransactionDto'
import { BrokerSummaryDto } from '../model/BrokerSummaryDto'
import { CQGEducationVideosDto } from '../model/CQGEducationVideosDto'
import { ClientRebateCampaignDto } from '../model/CampaignResultDto'
import { ClientAccountsConfigurationDto } from '../model/ClientAccountsConfigurationDto'
import { ClientAreaLanguageDto } from '../model/ClientAreaLanguageDto'
import { ClientCampaignDto } from '../model/ClientCampaignDto'
import { ClientCampaignRebateResultDto } from '../model/ClientCampaignRebateResultDto'
import {
  ActiveTradingDaysPerCampaignDto,
  ClientContestCampaignDetailDto,
} from '../model/ClientContestCampaignDetailDto'
import { ClientContestCampaignResultDto } from '../model/ClientContestCampaignResultDto'
import { ClientGuideDto } from '../model/ClientGuideDto'
import { ClientIbContestCampaignDetailDto } from '../model/ClientIbContestCampaignDetailDto'
import { ClientIbContestCampaignDto } from '../model/ClientIbContestCampaignDto'
import { ClientIbContestCampaignResultDto } from '../model/ClientIbContestCampaignResultDto'
import { ClientNciPriorityDto } from '../model/ClientNciPriorityDto'
import { ClientRebateCampaignDetailDto } from '../model/ClientRebateCampaignDetailDto'
import { TestAnswersDto } from '../model/ClientTestAnswerDto'
import { AdditionalInfoEMIRDto, ClientTestDto } from '../model/ClientTestDto'
import { CompanyLegalDocumentDto, LegalDocumentDto } from '../model/CompanyLegalDocumentDto'
import { CorporateActionDto } from '../model/CorporateActionDto'
import { CountryPreferenceStrippedDto, TruliooEIdData } from '../model/CountryDto'
import { CreateClientDocuments } from '../model/CreateClientDocuments'
import { CreateClientTest } from '../model/CreateClientTest'
import { CreateDormantUpdateTicket } from '../model/CreateDormantUpdateTicket'
import { CreateKycUpdateTicket } from '../model/CreateKycUpdateTicket'
import { CreateLead } from '../model/CreateLead'
import { ClientCreateNciFinalHistory, ClientCreateNciHistory } from '../model/CreateNciHistory'
import {
  CreatePhoneNumberVerification,
  PhoneVerificationWorkflowEnum,
  RestartPhoneNumberVerification,
} from '../model/CreatePhoneNumberVerification'
import {
  CreateCampaignTradingAccount,
  CreateDemoTradingAccount,
  CreateTradingAccount,
} from '../model/CreateTradingAccount'
import { CreateTradingAccountBalanceConversionTransaction } from '../model/CreateTradingAccountBalanceConversionTransaction'
import { CreateWalletDto } from '../model/CreateWalletDto'
import { DealDto } from '../model/DealDto'
import { DocumentCategorySizeDto } from '../model/DocumentCategories'
import { DocumentClientAreaDto } from '../model/DocumentClientAreaDto'
import { DocumentCategoryDto, DocumentDto } from '../model/DocumentDto'
import { DocumentGeneralStatusDto } from '../model/DocumentGeneralStatusDto'
import { EntityConfigurationDto } from '../model/EntityConfigurationDto'
import { ExchangeRateResponseDto } from '../model/ExchangeRateResponseDto'
import { FilterOptionsDto } from '../model/FilterOptionsDto'
import { FilterOptionsForCampaignsDto } from '../model/FilterOptionsForCampaignsDto'
import { GeolocationResponse } from '../model/GeolocationResponse'
import { InitOrCreatePspToWalletDepositTransactionCommand } from '../model/InitOrCreatePspToWalletDepositTransactionCommand'
import { InitWalletToPspWithdrawTransactionCommand } from '../model/InitWalletToPspWithdrawTransactionCommand'
import { IntroducingBrokerCampaignResultDto } from '../model/IntroducingBrokerCampaignResultDto'
import {
  IntroducingBrokerDetailsDto,
  IntroducingBrokerReferralDetailsDto,
  LoyaltyTierDto,
} from '../model/IntroducingBrokerDetailsDto'
import { IntroducingBrokerReportDto } from '../model/IntroducingBrokerReportDto'
import { IntroducingBrokerRewardDto } from '../model/IntroducingBrokerRewardDto'
import { IntroducingBrokerSignUpPayloadDto } from '../model/IntroducingBrokerSignUpPayloadDto'
import { IntroducingBrokerValidateCode } from '../model/IntroducingBrokerValidateCode'
import { ClientLandingPageSettingDto, LandingPageDto } from '../model/LandingPageDto'
import { LeadDto } from '../model/LeadDto'
import { LeadResponseDto } from '../model/LeadResponseDto'
import { LearningVideoDto } from '../model/LearningVideoDto'
import {
  LegalDocumentByTMCompanyAndCountry,
  LegalDocumentsWithTMCompany,
  OldLegalDocumentDto,
} from '../model/LegalDocumentDto'
import { LeverageTypeDto } from '../model/LeverageTypeDto'
import { LossPercentagesDto } from '../model/LossPercentagesDto'
import { LuckyDrawCampaign, LuckyDrawPrize } from '../model/LuckyDrawDto'
import { MasterTransaction, normalizeMasterTransaction } from '../model/MasterTransaction'
import { MasterTransactionDto } from '../model/MasterTransactionDto'
import {
  AssignedCommissionPlan,
  IBNetworkPlans,
  SubIBAccount,
  SubIBAccountOption,
  UpdateCommissionPlan,
} from '../model/MultiTierDto'
import { NameDto } from '../model/NameDto'
import { Notification, NotificationPreferences } from '../model/Notification'
import { PaymentAgentDto } from '../model/PaymentAgentDto'
import { PhoneNumberDto } from '../model/PhoneNumberDto'
import { PlatformInfoDto } from '../model/PlatformInfoDto'
import { PlatformType } from '../model/PlatformTypeDto'
import { PlatformTypeEnum } from '../model/PlatformTypeEnum'
import { PromoMaterialDto } from '../model/PromoMaterialDto'
import { LandingPagePromoOptionDto, PromoOptionDto } from '../model/PromoOptionDto'
import { PublicDomainDto } from '../model/PublicDomainDto'
import { ReferralLinkDto } from '../model/ReferralLinkDto'
import { RegulatorOptionDto } from '../model/RegulatorOptionDto'
import { RequiredDocument } from '../model/RequiredDocument'
import { StatementFile, StatementType } from '../model/Statements'
import { SubscriptionBundleDto } from '../model/SubscriptionBundleDto'
import { AddSubscriptionItemToCartData, SubscriptionCartItem } from '../model/SubscriptionCart'
import { SubscriptionGroupDto } from '../model/SubscriptionGroupDto'
import { SubscriptionHistoryItem, SubscriptionStateHistoryDto } from '../model/SubscriptionsDto'
import { SupportContactsDto } from '../model/SupportContactsDto'
import { TaxHistoryDto } from '../model/TaxHistoryDto'
import { IBTestSubCategory, TestSectionsDto } from '../model/TestSectionsDto'
import { TickmillProductType } from '../model/TickmillProductType'
import { TotalWalletBalanceDto } from '../model/TotalWalletBalanceDto'
import {
  AddDemoTradingAccount,
  DemoTradingAccount,
  ResetTradingAccountPassword,
  TradingAccount,
  UpdateDemoTradingAccount,
  UpdateTradingAccountLeverage,
} from '../model/TradingAccount'
import { TradingAccountCampaignDto } from '../model/TradingAccountCampaignDto'
import { TradingAccountCreationOptions } from '../model/TradingAccountCreationOptions'
import { TradingAccountDetailDto } from '../model/TradingAccountDetailDto'
import { TradingAccountInfo } from '../model/TradingAccountInfo'
import { TradingAccountToWalletTransferDto } from '../model/TradingAccountToWalletTransferDto'
import { TradingPlaformsDataProps } from '../model/TradingPlatformsDto'
import { TransactionDto, TransactionStateHistoryDto } from '../model/TransactionDto'
import { TransactionLimitDto } from '../model/TransactionLimitDto'
import { TutorialVideosDto } from '../model/TutorialVideosDto'
import {
  GoogleAuthenticator2FAProviderDataDto,
  TwoFAProviderItem,
  TwoFactorAuthRecoveryCodesDto,
  Update2FAProvidersData,
  VerifyFistTimeUseData,
} from '../model/TwoFactorAuthentication'
import { UpdateSubscriptionRequest } from '../model/UpdateSubscriptionRequest'
import { LoginResponse, TrustDeviceResponse, TwoFactor } from '../model/User'
import { ValidationOperation, ValidationOperationId } from '../model/ValidationDto'
import { VerifyAccountValues } from '../model/VerifyAccountValues'
import { WalletCreateDepositFromPaymentAgentTransaction } from '../model/WalletCreateDepositFromPaymentAgentTransaction'
import {
  CurrencyType,
  WalletCurrency,
  WalletDto,
  WalletType,
  WalletTypeEnum,
} from '../model/WalletDto'
import { WalletPaymentAgentTransferInformationDto } from '../model/WalletPaymentAgentTransferInformationDto'
import { WalletPaymentProvider, normalizePaymentProviders } from '../model/WalletPaymentProvider'
import { WalletPaymentProviderMethodResponseDto } from '../model/WalletPaymentProviderDto'
import {
  WalletPaymentProviderParameters,
  normalizeWalletPaymentProviderParameters,
} from '../model/WalletPaymentProviderParameters'
import { WalletPaymentProviderMethodParametersResponseDto } from '../model/WalletPaymentProviderParametersDto'
import { WalletToTradingAccountTransferDto } from '../model/WalletToTradingAccountTransferDto'
import { WalletToWalletTransferDto } from '../model/WalletToWalletTransferDto'
import { CreateQRParams } from '../model/createQR'
import { ClientRegisterValues } from '../pages/Signup/types'
import { ApiClient, PageData, PageQuery } from './ApiClient'
import { TickmillCompaniesEnum } from './companyName.utils'
import { isEmpty } from './isEmpty'
import { preparePageQueryParams } from './queryParser'

export const emptyPaginationResponse = {
  items: [],
  itemsCount: 0,
  pageIndex: 0,
  pageSize: 0,
  totalPages: 0,
  totalResults: 0,
}

export class ClientApiClient {
  constructor(private apiClient: ApiClient) {}

  async login(values: LoginFormValues): Promise<LoginResponse> {
    return this.apiClient.post<LoginResponse>('/sign-in', values)
  }

  async logOut(userId: string): Promise<void> {
    return this.apiClient.post<void>('/sign-out', { userId })
  }

  async verify2FACode(values: {
    typeId: number
    code: string
    token: string
  }): Promise<LoginResponse> {
    return this.apiClient.post('/2fa/codes/verify', values)
  }

  async trustDevice2FA(values: { token: string; sessionId: string }): Promise<TrustDeviceResponse> {
    return this.apiClient.post('/2fa/trustdevice', values)
  }

  async verifyRecoveryCode(values: {
    email: string
    recoveryCode: string
  }): Promise<LoginResponse> {
    return this.apiClient.post('/2fa/recoverycodes/verify', values)
  }

  async resend2FACode(values: { typeId: number; token: string }): Promise<TwoFactor[]> {
    return this.apiClient.post(`/2fa/codes/resend`, values)
  }

  async getAccount(locale: Locale): Promise<AccountDetailedDto> {
    return this.apiClient.get<AccountDetailedDto>(`/account?languageId=${locale}`)
  }

  async getLossPercentages(): Promise<PageData<LossPercentagesDto>> {
    return this.apiClient.get<PageData<LossPercentagesDto>>(`/loss-percentages`)
  }

  async getVisitorGeolocation(): Promise<GeolocationResponse> {
    const resp = await this.apiClient.rawFetch('https://ipapi.co/json', {})
    return resp.data as GeolocationResponse
  }

  async getAccountWithAppTestResults(locale: Locale): Promise<AccountDetailedDto> {
    const account = await this.getAccount(locale)
    return {
      ...account,
      isAppropriatenessTestAccountFailed: {
        [TickmillProductType.CFD]:
          !account.clientProductStates?.find(
            (x) => x.tickmillProduct.id === TickmillProductType.CFD
          )?.passedAppropriatenessTest ?? false,
        [TickmillProductType.ETD]:
          !account.clientProductStates?.find(
            (x) => x.tickmillProduct.id === TickmillProductType.CFD
          )?.passedAppropriatenessTest ?? false,
      },
    }
  }

  async resetPassword(values: ForgotPasswordFormValues): Promise<void> {
    return this.apiClient.post<void>('/password-reset/start', values)
  }

  async getResetPasswordAttempts(
    params: URLSearchParams
  ): Promise<{ attempts: number; attemptsLeft: number }> {
    return this.apiClient.get(`/password-reset/attempts?${new URLSearchParams(params)}`)
  }

  async resetPasswordComplete(
    values: Omit<ResetPasswordCompleteFormValues, 'confirmPassword'>
  ): Promise<void> {
    return this.apiClient.post<void>('/password-reset/complete', values)
  }

  async createClient(data: ClientRegisterValues): Promise<any> {
    return this.apiClient.post('/client', data)
  }

  getCountries: () => Promise<CountryPreferenceStrippedDto[]> = () => {
    return this.apiClient.get(`/countries/preferences`)
  }

  async getCompanyTypes(locale: string): Promise<NameDto[]> {
    return this.apiClient.get(`/company-types?languageId=${locale}`)
  }

  async getLegalBases(locale: string): Promise<NameDto[]> {
    return this.apiClient.get(`/legalbases?languageId=${locale}`)
  }

  async getWallets(query?: PageQuery): Promise<WalletDto[]> {
    return this.apiClient.get(`/wallets?${preparePageQueryParams(query)}`)
  }

  async getTransactionLimits(): Promise<TransactionLimitDto[]> {
    return this.apiClient.get<TransactionLimitDto[]>(`/wallets/limits/min`)
  }

  async addWallet(data: CreateWalletDto): Promise<WalletType[]> {
    return this.apiClient.post<WalletType[]>(`/wallets`, data)
  }

  async getWalletById(walletId: string): Promise<WalletDto> {
    return this.apiClient.get<WalletDto>(`/wallets/${walletId}`)
  }

  async getTradingAccounts(query?: PageQuery): Promise<PageData<TradingAccount>> {
    return this.apiClient.get<PageData<TradingAccount>>(
      `/trading-accounts?${preparePageQueryParams(query)}`
    )
  }

  async getTradingAccountById(tradingAccountId: string): Promise<TradingAccountDetailDto> {
    return this.apiClient.get<TradingAccountDetailDto>(`/trading-accounts/${tradingAccountId}`)
  }

  async addTradingAccount(values: CreateTradingAccount): Promise<void> {
    return this.apiClient.post<void>(`/trading-accounts`, values)
  }
  async addCampaignTradingAccount(values: CreateCampaignTradingAccount): Promise<void> {
    return this.apiClient.post<void>(`/trading-accounts/create-for-campaign`, values)
  }
  async addDemoTradingAccount(values: CreateDemoTradingAccount): Promise<AddDemoTradingAccount> {
    return this.apiClient.post<AddDemoTradingAccount>(`/demo-trading-accounts`, values)
  }

  async getClientEntityConfiguration(): Promise<EntityConfigurationDto> {
    return this.apiClient.get(`/trading-accounts/entity-configuration`)
  }

  async getTradingAccountsConfiguration(): Promise<ClientAccountsConfigurationDto> {
    return this.apiClient.get(`/trading-accounts/accounts-configuration`)
  }

  async getDemoTradingAccounts(query?: PageQuery): Promise<PageData<DemoTradingAccount>> {
    return this.apiClient.get(`/demo-trading-accounts?${preparePageQueryParams(query)}`)
  }

  async addBalanceToDemoTradingAccount(
    values: DemoTradingAccountDepositFormValues
  ): Promise<PageData<DemoTradingAccount>> {
    return this.apiClient.post(`/demo-trading-accounts/deposit`, {
      tradingAccountId: values.transferTo.id,
      amount: values.amount,
    })
  }

  async getProductPlatformTypes(tickmillProductId: TickmillProductType): Promise<PlatformType[]> {
    return this.apiClient.get(`/trading-accounts/products/${tickmillProductId}/platform-types`)
  }

  async getPlatformTypes(tickmillProductId: TickmillProductType): Promise<PlatformType[]> {
    return this.apiClient.get(`/trading-accounts/products/${tickmillProductId}/platform-types`)
  }

  async getCurrencies(platformTypeId?: PlatformTypeEnum): Promise<WalletCurrency[]> {
    return this.apiClient.get<WalletCurrency[]>(
      `/trading-accounts/platform-types/${platformTypeId}/currencies`
    )
  }

  async getDemoCurrencies(props: {
    platformTypeId: number
    tickmillCompanyId: number
  }): Promise<WalletCurrency[]> {
    const params = {
      platformTypeId: props.platformTypeId.toString(),
      tickmillCompanyId: props.tickmillCompanyId.toString(),
    }
    return this.apiClient.get<WalletCurrency[]>(
      `/demo-trading-accounts/currencies?${new URLSearchParams(params)}`
    )
  }

  async getAllCurrencies(): Promise<WalletCurrency[]> {
    return this.apiClient.get<WalletCurrency[]>('/all-currencies')
  }

  async getPreferredCurrencies(
    tickmillCompanyId: number,
    countryId: string
  ): Promise<WalletCurrency[]> {
    return this.apiClient.get<WalletCurrency[]>(
      `/preferred-currencies-list/${tickmillCompanyId}/${countryId}`
    )
  }

  async updateDisplayCurrency(currencyId: string): Promise<void> {
    return this.apiClient.put(`/me/display-currency/${currencyId}`, {})
  }

  async addTickmillProduct(productId: TickmillProductType): Promise<void> {
    return this.apiClient.put(`/me/product/${productId}`, {})
  }

  async getIntroducingBrokerCurrenciesByPlatformId(
    introducingBrokerId: string,
    platformTypeId: number
  ): Promise<WalletCurrency[]> {
    return this.apiClient.get<WalletCurrency[]>(
      `/introducing-brokers/${introducingBrokerId}/platform-types/${platformTypeId}/currencies`
    )
  }

  async getIBReports(
    introducingBrokerId: string,
    query?: PageQuery
  ): Promise<IntroducingBrokerReportDto[]> {
    return this.apiClient.get<IntroducingBrokerReportDto[]>(
      `/introducing-brokers/${introducingBrokerId}/reports/clients?${preparePageQueryParams(query)}`
    )
  }

  async validateAccountIntroducingBrokerByCode(
    introducingBrokerCode: string
  ): Promise<IntroducingBrokerValidateCode> {
    return this.apiClient.get<IntroducingBrokerValidateCode>(
      `/account/introducing-brokers/valid/${introducingBrokerCode}`
    )
  }

  async getIntroducingBrokerSummary(): Promise<BrokerSummaryDto> {
    return this.apiClient.get<BrokerSummaryDto>(`/introducing-broker/summary/incoming-reserved`)
  }

  async getIntroducingBrokerTransactionsSummary(
    walletId: string
  ): Promise<IntroducingBrokerRewardDto> {
    return this.apiClient.get<IntroducingBrokerRewardDto>(
      `/introducing-broker/wallets/${walletId}/ib-all-time-rewards`
    )
  }

  async getIBCommissionPlansSummary(introducingBrokerId: string): Promise<AssignedCommissionPlan> {
    return this.apiClient.get(
      `/introducing-brokers/${introducingBrokerId}/commission-plan-groups/assigned`
    )
  }

  async getIBCommissionPlans(introducingBrokerId: string): Promise<AssignedCommissionPlan[]> {
    return this.apiClient.get(`/introducing-brokers/${introducingBrokerId}/commission-plan-groups`)
  }

  async addCommissionPlan(
    introducingBrokerId: string,
    commissionPlans: UpdateCommissionPlan[]
  ): Promise<void> {
    return this.apiClient.post(
      `/introducing-brokers/${introducingBrokerId}/commission-plan-groups`,
      {
        CommissionPlans: commissionPlans,
      }
    )
  }

  async updateCommissionPlan(
    introducingBrokerId: string,
    commissionPlanId: string,
    commissionPlans: UpdateCommissionPlan[]
  ): Promise<void> {
    return this.apiClient.put(
      `/introducing-brokers/${introducingBrokerId}/commission-plan-groups/${commissionPlanId}`,
      {
        CommissionPlans: commissionPlans,
      }
    )
  }

  async getIBSubAccounts(
    introducingBrokerId: string,
    query?: PageQuery
  ): Promise<PageData<SubIBAccount>> {
    return this.apiClient.get(
      `/introducing-brokers/${introducingBrokerId}/multi-tier-network/sub-accounts?${preparePageQueryParams(
        query
      )}`
    )
  }

  async updateIBSubAccount(
    introducingBrokerId: string,
    subAccountId: number,
    commissionPlanGroupId: string
  ): Promise<void> {
    return this.apiClient.put(
      `/introducing-brokers/${introducingBrokerId}/multi-tier-network/sub-accounts/${subAccountId}`,
      {
        CommissionPlanGroupId: commissionPlanGroupId,
      }
    )
  }

  async getIBNetworkData(
    introducingBrokerId: string,
    query?: PageQuery
  ): Promise<PageData<IBNetworkPlans>> {
    return this.apiClient.get(
      `/introducing-brokers/${introducingBrokerId}/multi-tier-network?${preparePageQueryParams(
        query
      )}`
    )
  }

  async getIBSubAccountPlanOptions(introducingBrokerId: string): Promise<SubIBAccountOption[]> {
    return this.apiClient.get(`/introducing-brokers/${introducingBrokerId}/commission-plan-groups`)
  }

  async updateTradingAccountLeverage(
    tradingAccountId: string,
    data: UpdateTradingAccountLeverage
  ): Promise<void> {
    return this.apiClient.put<void>(`/trading-accounts/${tradingAccountId}/leverage`, data)
  }

  async updateDemoTradingAccount(
    tradingAccountId: string,
    data: UpdateDemoTradingAccount
  ): Promise<void> {
    return this.apiClient.put<void>(`/demo-trading-accounts/${tradingAccountId}`, data)
  }

  async getClientCampaigns(): Promise<ClientCampaignDto[]> {
    return this.apiClient.get(`/campaigns`)
  }

  async getClientCampaignsList(): Promise<PageData<ClientCampaignDto>> {
    return this.apiClient.get(`/campaigns/list`)
  }

  async getClientAppTests(locale: string): Promise<ClientTestDto> {
    return this.apiClient.get(`/account/ap-test?languageId=${locale}`)
  }

  async getEMIRTestQuestions(locale: string): Promise<AdditionalInfoEMIRDto> {
    return this.apiClient.get(`/account/corporate_test?SubcategoryId=9&languageId=${locale}`)
  }

  async createClientTests(values: CreateClientTest): Promise<void> {
    return this.apiClient.post(`/account/tests`, values)
  }

  async getRegulators(
    tickmillCompanyId: number,
    languageId: string,
    country?: string | undefined | null
  ): Promise<RegulatorOptionDto[]> {
    const params = Object.fromEntries(
      Object.entries({
        Fcountry: country || '',
        languageId,
      }).filter(([_, v]) => !!v)
    )
    const searchParams = new URLSearchParams(params).toString()

    return this.apiClient.get(`/regulators/${tickmillCompanyId}?${searchParams}`)
  }

  async getLeverageTypes(platformTypeId?: number): Promise<LeverageTypeDto[]> {
    if (!platformTypeId) {
      return this.apiClient.get<LeverageTypeDto[]>(`/trading-accounts/leverage-types`)
    }
    return this.apiClient.get<LeverageTypeDto[]>(
      `/trading-accounts/leverage-types-for-platform/${platformTypeId}`
    )
  }

  // accountGroupTypeId = 2 means live account
  async getAccountType(
    platformTypeId: number,
    currency: string,
    accountGroupTypeId = 2
  ): Promise<NameDto[]> {
    return this.apiClient.get<NameDto[]>(
      `/trading-accounts/platform-types/${platformTypeId}/currencies/${currency}/account-group-types/${accountGroupTypeId}/account-types`
    )
  }

  async getAccountTypeForIntroducingBroker(
    platformTypeId: number,
    currency: string,
    ibId: string,
    accountGroupTypeId = AccountGroupType.Live
  ): Promise<NameDto[]> {
    return this.apiClient.get<NameDto[]>(
      `/introducing-brokers/${ibId}/platform-types/${platformTypeId}/currencies/${currency}/account-group-types/${accountGroupTypeId}/account-types`
    )
  }

  async getIBReferralCodes(
    introducingBrokerId: string
  ): Promise<IntroducingBrokerReferralDetailsDto[]> {
    return this.apiClient.get(`/introducing-brokers/${introducingBrokerId}/referral-codes`)
  }

  async getAccountTypeOptions(tickmillCompanyId: number): Promise<NameDto[]> {
    return this.apiClient.get<NameDto[]>(`/accounts-types?TickmillProductId=${tickmillCompanyId}`)
  }

  async getAvailableCurrencies(
    tickmillCompanyId: TickmillProductType,
    walletTypeId: WalletTypeEnum = WalletTypeEnum['FX/CFD']
  ): Promise<WalletCurrency[]> {
    return this.apiClient.get<WalletCurrency[]>(
      `/available-currencies?TickmillCompanyId=${tickmillCompanyId}&WalletTypeId=${walletTypeId}`
    )
  }

  async getTradingAccountsAccountInfo(): Promise<TradingAccountInfo[]> {
    return this.apiClient.get<TradingAccountInfo[]>(`/trading-accounts/accounts-info`)
  }

  async getAvailableWallets(
    walletTypeId: number = WalletTypeEnum['FX/CFD']
  ): Promise<WalletCurrency[]> {
    // 1 means FX/CFD
    return this.apiClient.get<WalletCurrency[]>(
      `/wallets/types/${walletTypeId}/currencies/available`
    )
  }

  async getWalletTypes(): Promise<WalletType[]> {
    return this.apiClient.get<WalletType[]>(`/wallets/types`)
  }

  getTransactions: (query?: PageQuery) => Promise<PageData<TransactionDto>> = (query) => {
    return this.apiClient.get(`/transactions?${preparePageQueryParams(query)}`)
  }

  getCorporateActions: (query?: PageQuery) => Promise<PageData<CorporateActionDto>> = (query) => {
    return this.apiClient.get(`/corporate-actions?${preparePageQueryParams(query)}`)
  }

  async getTransactionStates(locale: string): Promise<NameDto[]> {
    return this.apiClient.get(`/transactions/states?languageId=${locale}`)
  }

  async getTransactionStatusHistory(
    transactionId: string,
    languageId: string
  ): Promise<TransactionStateHistoryDto> {
    return this.apiClient.get(
      `/transactions/${transactionId}/transaction-state-history?languageId=${languageId}`
    )
  }

  async getSubscriptionStatusHistory(
    id: string,
    locale: string
  ): Promise<SubscriptionStateHistoryDto[]> {
    return this.apiClient.get(`/subscriptions/requests/${id}/state-history?languageId=${locale}`)
  }

  async changeTradingAccountPassword(values: ChangeTradingAccountPasswordValues): Promise<void> {
    return this.apiClient.put(`trading-accounts/update-password`, values)
  }

  async resetTradingAccountPassword(values: ResetTradingAccountPassword): Promise<void> {
    return this.apiClient.put('/trading-accounts/reset-password', values)
  }

  async resetDemoTradingAccountPassword(values: ResetTradingAccountPassword): Promise<void> {
    return this.apiClient.put('/demo-trading-accounts/reset-password', values)
  }

  async transactionDeposit(
    data: Omit<InitOrCreatePspToWalletDepositTransactionCommand, 'caller' | 'clientIpAddress'>
  ): Promise<MasterTransaction> {
    // TODO: backend should improve the "endpoint" "language logic"
    const referrerUrl = window.location.origin
    const referrerUrlDetail = [{ name: 'referrer_url', value: referrerUrl }]

    const response = await this.apiClient.post<MasterTransactionDto>('/transactions/deposit', {
      ...data,
      // TODO: need to send this data through headers, and backend should catch the data
      caller: 'ca',
      // TODO: backend should get the client ip.
      clientIpAddress: '127.0.0.1',
      details: data.details.concat(referrerUrlDetail),
      referrer_url: referrerUrl,
    })

    return normalizeMasterTransaction(response)
  }

  async transactionWithdraw(
    data: Omit<InitWalletToPspWithdrawTransactionCommand, 'caller' | 'clientIpAddress'>
  ): Promise<MasterTransaction> {
    // TODO: backend should improve the "endpoint" "language logic"
    const referrerUrl = window.location.origin
    const referrerUrlDetail = [{ name: 'referrer_url', value: referrerUrl }]

    const response = await this.apiClient.post<MasterTransactionDto>('/transactions/withdraw', {
      ...data,
      // TODO: need to send this data through headers, and backend should catch the data
      caller: 'ca',
      // TODO: backend should get the client ip.
      clientIpAddress: '127.0.0.1',
      details: data.details.concat(referrerUrlDetail),
      referrer_url: referrerUrl,
    })

    return normalizeMasterTransaction(response)
  }

  async cancelTransaction(transactionId: string): Promise<void> {
    return this.apiClient.post('/transactions/cancel', {
      transactionId,
      transactionStateReasonId: 610,
      caller: 'ca',
      clientIpAddress: '127.0.0.1',
    })
  }

  async getWalletDepositMethods(
    locale: string,
    walletId: string
  ): Promise<WalletPaymentProvider[]> {
    // TODO: backend should improve the "endpoint" "language logic"
    // TODO: front-end should store "language" in localstorage
    const response = await this.apiClient.get<WalletPaymentProviderMethodResponseDto>(
      `/wallets/${walletId}/languages/${locale}/deposit-methods`
    )
    return normalizePaymentProviders(response)
  }

  async getWalletDepositMethodParameters(
    locale: string,
    walletId: string,
    methodId: string
  ): Promise<WalletPaymentProviderParameters> {
    // TODO: backend should improve the "endpoint" "language logic"
    const response = await this.apiClient.get<WalletPaymentProviderMethodParametersResponseDto>(
      `/wallets/${walletId}/languages/${locale}/deposit-methods/${methodId}/parameters`
    )
    return normalizeWalletPaymentProviderParameters(response)
  }

  async getWalletWithdrawalMethods(
    locale: string,
    walletId: string
  ): Promise<WalletPaymentProvider[]> {
    // TODO: backend should improve the "endpoint" "language logic"
    const response = await this.apiClient.get<WalletPaymentProviderMethodResponseDto>(
      `/wallets/${walletId}/languages/${locale}/withdrawal-methods`
    )
    return normalizePaymentProviders(response)
  }

  async getWalletWithdrawalMethodParameters(
    locale: string,
    walletId: string,
    methodId: string
  ): Promise<WalletPaymentProviderParameters> {
    // TODO: backend should improve the "endpoint" "language logic"
    const response = await this.apiClient.get<WalletPaymentProviderMethodParametersResponseDto>(
      `/wallets/${walletId}/languages/${locale}/withdrawal-methods/${methodId}/parameters`
    )
    return normalizeWalletPaymentProviderParameters(response)
  }

  async currencyConverterFromTo(
    fromCurrency: string,
    toCurrency: string
  ): Promise<ExchangeRateResponseDto> {
    return this.apiClient.get<ExchangeRateResponseDto>(
      `/exchange-rates/latest?BaseCcy=${fromCurrency}&QuoteCcy=${toCurrency}&direction=sel`
    )
  }

  async getTotalBalanceByCurrency(
    currencyId: CurrencyType,
    walletType: WalletTypeEnum = WalletTypeEnum.ETD
  ): Promise<TotalWalletBalanceDto> {
    return this.apiClient.get<TotalWalletBalanceDto>(
      `/wallets/total-balance/${currencyId}?search=WalletTypeId == ${walletType}`
    )
  }

  async getExchangeRate(query?: PageQuery): Promise<ExchangeRateResponseDto> {
    return this.apiClient.get<ExchangeRateResponseDto>(
      `/exchange-rates/latest?${preparePageQueryParams(query)}`
    )
  }

  async convertTradingAccountBalance(
    values: CreateTradingAccountBalanceConversionTransaction
  ): Promise<MasterTransactionDto> {
    return this.apiClient.post(`/transactions/trading-account-balance-conversion`, values)
  }

  async transferWalletToWallet(values: WalletToWalletTransferDto): Promise<MasterTransactionDto> {
    return this.apiClient.post(`/transactions/transfer`, values)
  }

  async transferWalletToTradingAccount(
    values: WalletToTradingAccountTransferDto
  ): Promise<MasterTransactionDto> {
    return this.apiClient.post(`/transactions/transfer-to-trading-account`, values)
  }

  async transferTradingAccountToWallet(
    values: TradingAccountToWalletTransferDto
  ): Promise<MasterTransactionDto> {
    return this.apiClient.post(`/transactions/transfer-from-trading-account`, values)
  }

  async getActiveBonus(walletId: string): Promise<PageData<BonusTransactionDto>> {
    return this.apiClient.get(`/transactions/${walletId}/active-bonus-with-required-lots`)
  }

  async getPaymentAgentsTransferInformation(
    walletId: string,
    paymentAgentId: string
  ): Promise<WalletPaymentAgentTransferInformationDto> {
    return this.apiClient.get(
      `/wallets/${walletId}/payment-agents/${paymentAgentId}/transfer-information`
    )
  }

  async paymentAgentAcceptTerms(
    paymentAgentId: string
  ): Promise<WalletPaymentAgentTransferInformationDto> {
    return this.apiClient.post(`/payment-agents/${paymentAgentId}/accept-terms`, {})
  }

  async getIntroducingBrokerById(
    locale: string,
    id: string | undefined
  ): Promise<IntroducingBrokerDetailsDto> {
    return this.apiClient.get(`/introducing-brokers/${id}?languageId=${locale}`)
  }

  async getLoyaltyTiers(locale: string): Promise<LoyaltyTierDto[]> {
    return this.apiClient.get(`/introducing-brokers/loyalty-tiers?languageId=${locale}`)
  }

  async consumeLoyaltyReward(rewardId: string): Promise<void> {
    return this.apiClient.post(`/consume-loyalty-reward`, { rewardId })
  }

  async getIntroducingBrokerLegalDocuments(
    locale: string,
    legalDocumentsSchemes: string
  ): Promise<OldLegalDocumentDto[]> {
    if (!legalDocumentsSchemes) {
      return []
    }
    return this.apiClient.get(
      `/introducing-broker/legal-documents?languageId=${locale}${legalDocumentsSchemes}`
    )
  }

  async getOldLegalDocuments(
    locale: string,
    tickmillCompanyId?: number,
    countryId?: string
  ): Promise<LegalDocumentsWithTMCompany[] | LegalDocumentByTMCompanyAndCountry> {
    if (tickmillCompanyId && countryId) {
      return this.apiClient.get<LegalDocumentByTMCompanyAndCountry>(
        `/clients/legal-documents/${tickmillCompanyId}/${countryId}?languageId=${locale}`
      )
    }
    return this.apiClient.get(`/legal-documents?languageId=${locale}`)
  }

  async getLegalDocuments(locale: string): Promise<CompanyLegalDocumentDto[]> {
    return await this.apiClient.get(`/new-legal-documents?languageId=${locale}`)
  }

  async getLegalDocumentsQuery(
    locale: string,
    tickmillCompanyId?: number,
    tickmillProductIds: number[] = [],
    countryId?: string
  ): Promise<LegalDocumentDto[]> {
    const productLegalDocuments: CompanyLegalDocumentDto[] = await this.apiClient.get(
      `/new-legal-documents?languageId=${locale}`
    )

    if (tickmillCompanyId && tickmillProductIds?.length >= 1 && countryId) {
      const [productCountryLegalDocument] = productLegalDocuments.filter(
        (x) => x.tickmillCompanyId === tickmillCompanyId
      )
      const productCountryLegalDocuments =
        productCountryLegalDocument?.productCountryLegalDocuments || []

      return productCountryLegalDocuments
        .filter((x) => tickmillProductIds.includes(x.tickmillProductId))
        .reduce((previousValue: LegalDocumentDto[], currentValue) => {
          return previousValue.concat(currentValue.legalDocuments)
        }, [])
        .filter((x) => {
          if (!x.showForAllCountries && !x.requiredForAllCountries) {
            return !!x.availableCountries.find((x) => x.country.id === countryId)
          }

          if (!x.showForAllCountries && x.requiredForAllCountries) {
            return !!x.availableCountries.find((x) => x.country.id === countryId)
          }

          return x
        })
    }

    if (tickmillCompanyId && tickmillProductIds?.length >= 1) {
      const [productCountryLegalDocument] = productLegalDocuments.filter(
        (x) => x.tickmillCompanyId === tickmillCompanyId
      )
      const productCountryLegalDocuments =
        productCountryLegalDocument?.productCountryLegalDocuments || []

      return productCountryLegalDocuments
        .filter((x) => tickmillProductIds.includes(x.tickmillProductId))
        .reduce((previousValue: LegalDocumentDto[], currentValue) => {
          return previousValue.concat(currentValue.legalDocuments)
        }, [])
    }

    if (tickmillCompanyId) {
      const [productCountryLegalDocument] = productLegalDocuments.filter(
        (x) => x.tickmillCompanyId === tickmillCompanyId
      )
      const productCountryLegalDocuments =
        productCountryLegalDocument?.productCountryLegalDocuments || []

      return productCountryLegalDocuments.reduce(
        (previousValue: LegalDocumentDto[], currentValue) => {
          return previousValue.concat(currentValue.legalDocuments)
        },
        []
      )
    }

    return []
  }

  async getLeadById(id: string): Promise<LeadDto> {
    return this.apiClient.get(`/leads/${id}`)
  }

  async getLeadByIdAndToken(leadId: string, token: string): Promise<LeadDto> {
    return this.apiClient.get(`/leads/by-token/${leadId}/${token}`)
  }

  async getNationalities(): Promise<NameDto<string>[]> {
    return this.apiClient.get('/nationalities')
  }

  async getTaxUnavailableReasons(locale: string): Promise<NameDto[]> {
    return this.apiClient.get(`/tin-unavailable-reasons?languageId=${locale}`)
  }

  async verifyEmail(values: VerifyAccountValues): Promise<void> {
    return this.apiClient.put('/leads/verify', values)
  }

  async validateEmailDomain(email: string): Promise<boolean> {
    return this.apiClient.get(`/restricted-email-domains/${email}/validate`)
  }

  async getResetPasswordValidationRules(query?: PageQuery): Promise<ValidationOperation> {
    return this.apiClient.get(
      `/password-reset/validation-rules/${
        ValidationOperationId.ValidateClientPlainPassword
      }?${preparePageQueryParams(query)}`
    )
  }

  async getLeadPasswordValidationRules(
    leadData: {
      id: string
      token: string
    },
    locale: string
  ): Promise<ValidationOperation> {
    return this.apiClient.get(
      `/validation-rules/${ValidationOperationId.ValidateClientPlainPassword}?languageId=${locale}&LeadId=${leadData.id}&Token=${leadData.token}`
    )
  }

  async getPasswordValidationRules(
    validationOperationId: string,
    locale: string
  ): Promise<ValidationOperation> {
    return this.apiClient.get(`/validation-rules/${validationOperationId}?languageId=${locale}`)
  }

  async cancelLeadPhoneNumberVerification(
    leadId: string,
    verificationId: string
  ): Promise<{ id: string }> {
    return this.apiClient.put(
      `/lead/${leadId}/cancel-phone-number-verification/${verificationId}`,
      {
        leadId,
        verificationId,
      }
    )
  }

  async createPhoneNumberVerification(
    leadId: string,
    values: CreatePhoneNumberVerification
  ): Promise<{ leadId: string; phoneVerificationId: string }> {
    return this.apiClient.post(`/lead/${leadId}/create-phone-number-verification`, values)
  }

  async updateLeadPhoneNumber(
    leadId: string,
    phoneNumber: string,
    countryCode: string
  ): Promise<LeadDto> {
    return this.apiClient.put(`/lead/${leadId}/phone-number`, {
      PhoneNumber: phoneNumber,
      CountryCode: countryCode,
    })
  }

  async sendNewVerificationCodeToPhoneNumber(
    leadId: string,
    verificationId: string,
    values: RestartPhoneNumberVerification
  ): Promise<{ leadId: string; phoneVerificationId: string }> {
    return this.apiClient.put(`/lead/${leadId}/verifications/${verificationId}/send-new-code`, {
      leadId,
      verificationId,
      ...values,
    })
  }

  async getPromoOptions(): Promise<PromoOptionDto> {
    return this.apiClient.get('/promo-materials/options')
  }

  async getReferralLink(
    locale: string,
    domain: string,
    referralCode?: string
  ): Promise<ReferralLinkDto> {
    if (referralCode) {
      return this.apiClient.get(
        `/promo-materials/referral-url?languageId=${locale}&domain=${domain}&introducingBrokerCode=${referralCode}`
      )
    }
    return this.apiClient.get(`/promo-materials/referral-url?languageId=${locale}&domain=${domain}`)
  }

  async getReferralLandingPageLink(
    locale: string,
    landingPageId: string,
    domain: string,
    referralCode?: string,
    generateShortenUrl?: boolean
  ): Promise<ReferralLinkDto> {
    if (referralCode) {
      return this.apiClient.get(
        `/promo-materials/referral-url?languageId=${locale}&landingPageId=${landingPageId}&domain=${domain}&introducingBrokerCode=${referralCode}&generateShortenUrl=${generateShortenUrl}`
      )
    }
    return this.apiClient.get(
      `/promo-materials/referral-url?languageId=${locale}&landingPageId=${landingPageId}&domain=${domain}`
    )
  }

  async getLanguages(params?: { clientAreaVisible?: boolean }): Promise<ClientAreaLanguageDto[]> {
    let url = '/languages'
    if (!isEmpty(params?.clientAreaVisible)) {
      url += `?clientAreaVisible=${params?.clientAreaVisible}`
    }

    return this.apiClient.get(url)
  }

  async getClientAreaLanguages({
    promoMaterialCategoryId,
    forSignIn,
    clientAreaVisible,
  }: {
    promoMaterialCategoryId?: number
    forSignIn?: boolean
    clientAreaVisible?: boolean
  }): Promise<ClientAreaLanguageDto[]> {
    if (forSignIn) {
      return this.getLanguages({ clientAreaVisible })
    }
    return this.apiClient.get(`/landing-pages/languages/${promoMaterialCategoryId}`)
  }

  async getClientDateFormats(): Promise<NameDto<number>[]> {
    return this.apiClient.get('/date-format-types')
  }

  async getLandingPages({
    promoMaterialCategoryId,
    query,
  }: {
    promoMaterialCategoryId?: number
    query?: PageQuery
  }): Promise<PageData<LandingPageDto>> {
    return this.apiClient.get(
      `/landing-pages/${promoMaterialCategoryId}?${preparePageQueryParams(query)}`
    )
  }

  async getPromoMaterials(id: string, query?: PageQuery): Promise<PageData<PromoMaterialDto>> {
    return this.apiClient.get(
      `/landing-pages/${id}/promo-materials?${preparePageQueryParams(query)}`
    )
  }

  async getPromoOptionsByLandingPageAndCategory(
    landingPageId: string,
    categoryId: number
  ): Promise<LandingPagePromoOptionDto> {
    return this.apiClient.get(`/landing-pages/${landingPageId}/category/${categoryId}/options`)
  }

  async getIntroducingBrokerIncomeSummary(query?: PageQuery): Promise<BrokerSummaryDto> {
    return this.apiClient.get(
      `/introducing-broker/overall-summary?${preparePageQueryParams(query)}`
    )
  }

  async getIntroducingBrokerIncomeSummaryPerAccount(
    introducingBrokerId: string,
    query?: PageQuery
  ): Promise<PageData<AccountSummaryDto>> {
    return this.apiClient.get(
      `/introducing-broker/${introducingBrokerId}/summary-per-trading-account?${preparePageQueryParams(
        query
      )}`
    )
  }

  async getIBClientFilterOptions(introducingBrokerId: string): Promise<FilterOptionsDto> {
    return this.apiClient.get(
      `/introducing-broker/${introducingBrokerId}/summary-per-trading-account/filter-options`
    )
  }

  async getIBIncomeFilterOptions(): Promise<FilterOptionsDto> {
    return this.apiClient.get('/introducing-broker/income/filter-options')
  }

  async getTransactionHistoryFilterOptions(
    clientWalletType: string,
    languageId: string
  ): Promise<FilterOptionsDto> {
    return this.apiClient.get(
      `/transactions/filter-options?ca_search_ClientWalletType=${clientWalletType}&languageId=${languageId}`
    )
  }

  async getPATransactionHistoryFilterOptions(
    clientId: string,
    walletId: string
  ): Promise<FilterOptionsDto> {
    return this.apiClient.get(
      `/transactions/filter-options?ca_search_Client=${clientId}&ca_search_WalletId=${walletId}`
    )
  }

  async getIntroducingBrokerIncomeSummaryPerAccountPortalForFilters(
    introducingBrokerId: string
  ): Promise<{
    data: AccountSummaryDto[]
    countryFilters: NameDto<string>[]
  }> {
    return this.apiClient.get(
      `/introducing-broker/${introducingBrokerId}/summary-per-account/portal`
    )
  }

  async getIntroducingBrokerIncome(query?: PageQuery): Promise<PageData<DealDto>> {
    return this.apiClient.get(`/introducing-broker/income?${preparePageQueryParams(query)}`)
  }

  async getPaymentAgent(paymentAgentId: string): Promise<PaymentAgentDto> {
    return this.apiClient.get(`/payment-agents/${paymentAgentId}`)
  }

  getCampaignFilterOptions: () => Promise<FilterOptionsForCampaignsDto> = () => {
    return this.apiClient.get('/campaigns/filter-options')
  }

  getCampaigns: (query?: PageQuery) => Promise<PageData<ClientRebateCampaignDto>> = (query) => {
    return this.apiClient.get(`/campaigns/list?${preparePageQueryParams(query)}`)
  }

  getTradingAccountRebateCampaignById: (
    tradingAccountId: string,
    campaignId: string
  ) => Promise<ClientRebateCampaignDetailDto> = (tradingAccountId, campaignId) => {
    return this.apiClient.get(
      `/trading-accounts/${tradingAccountId}/client-rebate-campaigns/${campaignId}`
    )
  }

  getRebateCampaignResults: (
    tradingAccountId: string,
    campaignId: string,
    query?: PageQuery
  ) => Promise<PageData<ClientCampaignRebateResultDto>> = (
    tradingAccountId,
    campaignId,
    query?: PageQuery
  ) => {
    return this.apiClient.get(
      `/trading-accounts/${tradingAccountId}/client-rebate-campaigns/${campaignId}/results?${preparePageQueryParams(
        query
      )}`
    )
  }

  getTradingAccountContestCampaignById: (
    tradingAccountId: string,
    campaignId: string
  ) => Promise<ClientContestCampaignDetailDto> = (tradingAccountId, campaignId) => {
    return this.apiClient.get(
      `/trading-accounts/${tradingAccountId}/client-contest-campaigns/${campaignId}`
    )
  }
  getTradingAccountContestCampaignDays: (
    tradingAccountId: string,
    campaignId: string
  ) => Promise<ActiveTradingDaysPerCampaignDto> = (tradingAccountId, campaignId) => {
    return this.apiClient.get(
      `trading-accounts/${tradingAccountId}/client-contest-campaigns/${campaignId}/active-trading-days`
    )
  }

  getContestCampaignResults: (
    tradingAccountId: string,
    campaignId: string,
    query?: PageQuery
  ) => Promise<PageData<ClientContestCampaignResultDto>> = (
    tradingAccountId,
    campaignId,
    query?: PageQuery
  ) => {
    return this.apiClient.get(
      `/trading-accounts/${tradingAccountId}/client-contest-campaigns/${campaignId}/results?${preparePageQueryParams(
        query
      )}`
    )
  }

  getCampaignByIdResults: (
    campaignId: string,
    query?: PageQuery
  ) => Promise<PageData<ClientCampaignRebateResultDto>> = (campaignId, query?: PageQuery) => {
    return this.apiClient.get(`/campaigns/${campaignId}/results?${preparePageQueryParams(query)}`)
  }

  getIbContestCampaigns: (query?: PageQuery) => Promise<PageData<ClientIbContestCampaignDto>> = (
    query
  ) => {
    return this.apiClient.get(`/ib-campaigns/list?${preparePageQueryParams(query)}`)
  }

  getIbContestByIdCampaign: (campaignId: string) => Promise<ClientIbContestCampaignDetailDto> = (
    campaignId
  ) => {
    return this.apiClient.get(`/ib-campaigns/${campaignId}`)
  }

  getIbContestByIdCampaignResults: (
    campaignId: string,
    query?: PageQuery
  ) => Promise<PageData<ClientIbContestCampaignResultDto>> = (campaignId, query) => {
    return this.apiClient.get(
      `/ib-campaigns/${campaignId}/results?${preparePageQueryParams(query)}`
    )
  }

  async getLuckyDrawPrizes(campaignId: string, locale: string): Promise<LuckyDrawPrize[]> {
    return this.apiClient.get(`/campaigns/${campaignId}/lucky-draw-rewards?languageId=${locale}`)
  }

  async getLuckyDrawChances(campaignId: string): Promise<number> {
    return this.apiClient.get(`/campaigns/${campaignId}/lucky-draw-chances-count`)
  }

  async getLuckDrawPrize(campaignId: string): Promise<LuckyDrawPrize> {
    return this.apiClient.get(`/campaigns/${campaignId}/lucky-draw-chances-consume-next`)
  }

  async getLuckyDrawCampaign(): Promise<LuckyDrawCampaign[]> {
    return this.apiClient.get('/campaigns/lucky-draw')
  }

  async getIBSignUpTest(
    locale: string,
    subCategoryId: IBTestSubCategory
  ): Promise<TestSectionsDto> {
    return this.apiClient.get(`/ib-signup-test?languageId=${locale}&subCategoryId=${subCategoryId}`)
  }

  async signUpIntroducingBroker(values: IntroducingBrokerSignUpPayloadDto): Promise<void> {
    return await this.apiClient.post('/account/tests', values)
  }

  async getIntroducingBrokerTestInfo(locale: string): Promise<ClientTestDto> {
    return this.apiClient.get(`/account/ibinfo-answers?languageId=${locale}`)
  }

  async paymentAgentValidateClientDetails(
    paymentAgentWalletId: string,
    clientName: string,
    clientWalletName: string
  ): Promise<WalletDto> {
    return this.apiClient.get(
      `/payment-agents/validate-client-details?paymentAgentWalletId=${paymentAgentWalletId}&clientName=${clientName}&clientWalletName=${clientWalletName}`
    )
  }

  async tradingAccountValidateCampaignAccount(
    walletId: string,
    campaignName: string
  ): Promise<TradingAccountCampaignDto> {
    return this.apiClient.get(
      `/validate-campaign-account?walletId=${walletId}&tradingAccountLogin=${campaignName}`
    )
  }

  async transactionDepositFromPaymentAgent(
    data: Omit<WalletCreateDepositFromPaymentAgentTransaction, 'caller' | 'clientIpAddress'>
  ): Promise<WalletCreateDepositFromPaymentAgentTransaction> {
    // TODO: backend should improve the "endpoint" "language logic"

    return this.apiClient.post(`/transactions/deposit-from-payment-agent`, {
      ...data,
      base64Content: (data.base64Content || '')
        ?.replace('data:image/png;base64,', '')
        ?.replace('data:image/jpeg;base64,', '')
        ?.replace('data:application/pdf;base64,', ''),
      // TODO: need to send this data through headers, and backend should catch the data
      caller: 'ca',
      // TODO: backend should get the client ip.
      clientIpAddress: '127.0.0.1',
    })
  }

  async createLead(values: CreateLead): Promise<LeadResponseDto> {
    return this.apiClient.post(`/leads`, values)
  }

  async resendCreateLeadEmail(id: string): Promise<void> {
    return this.apiClient.put(`/leads/${id}/resend-email`, {})
  }

  async resendCreateLeadEmailByEmail(email: string): Promise<void> {
    return this.apiClient.put(`/leads/${email}/resend-email-by-email`, {})
  }

  async getLandingPageOptions(locale: string): Promise<ClientLandingPageSettingDto[]> {
    return this.apiClient.get(`/client-area/landing-pages/options?languageId=${locale}`)
  }

  async updateLandingPage(landingPageId: number): Promise<void> {
    return this.apiClient.put(`/me/preferred-landing-page/${landingPageId}`, {})
  }

  async updatePassword(values: ChangePasswordFormValues): Promise<void> {
    return this.apiClient.put<void>('/me/password', values)
  }
  async updateNewsletter(newsletterChoice: boolean): Promise<void> {
    return this.apiClient.put<void>(`/me/newsletter/${newsletterChoice}`, {})
  }

  async updateCommunicationLanguage(
    id: string,
    communicationLanguage: CommunicationLanguageFormValues
  ): Promise<void> {
    return this.apiClient.put<void>(
      `/me/communication-language/${communicationLanguage.communicationLanguageId}`,
      {}
    )
  }

  async updateClientDateFormat(id: string, values: DateFormatFormValues): Promise<void> {
    return this.apiClient.put<void>(`/me/date-format`, {
      id,
      dateFormatTypeId: values.dateFormatTypeId,
    })
  }

  async updateMarketDataClassifications(marketDataClassificationId: number): Promise<void> {
    return this.apiClient.put<void>(`/me/market-data-classification`, {
      marketDataClassificationId,
    })
  }

  async getDocuments(query?: PageQuery): Promise<PageData<DocumentClientAreaDto>> {
    return this.apiClient.get(`/documents?${preparePageQueryParams(query)}`)
  }

  async getAccountNciPriorities(): Promise<ClientNciPriorityDto[]> {
    return this.apiClient.get('/account/nci/priorities')
  }

  async getAccountNciAvailablePriorities(locale: string): Promise<ClientNciPriorityDto> {
    return this.apiClient.get(`/account/nci/available-priorities?languageId=${locale}`)
  }

  async accountAcceptNciHistory(data: ClientCreateNciHistory): Promise<void> {
    return this.apiClient.post('/account/nci/history', data)
  }

  async accountAcceptFinalNciHistory(data: ClientCreateNciFinalHistory): Promise<void> {
    return this.apiClient.post('/account/nci/history', data)
  }

  async uploadDocuments(data: CreateClientDocuments): Promise<DocumentDto[]> {
    const cleanedDocs = data.documents.filter((x) => !!x?.filename)
    const uploadPromises = cleanedDocs.map((document) =>
      this.apiClient.post('/documents', {
        documents: [
          {
            ...document,
            file: document.file
              ?.replace('data:image/png;base64,', '')
              ?.replace('data:image/jpeg;base64,', '')
              ?.replace('data:application/pdf;base64,', '')
              ?.replace(
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                ''
              ),
          },
        ],
      })
    )
    const uploadedDocs = await Promise.all(uploadPromises)
    return uploadedDocs.flat() as DocumentDto[]
  }

  async deleteDocument(documentId: string): Promise<void> {
    return this.apiClient.delete(`/documents/${documentId}`)
  }

  async getDocumentCategories(
    documentCategoryId: number,
    locale: string
  ): Promise<DocumentCategoryDto> {
    return this.apiClient.get(
      `/documents/categories/${documentCategoryId}/types?languageId=${locale}`
    )
  }

  async getAllDocumentCategories(locale: string): Promise<DocumentCategorySizeDto[]> {
    return this.apiClient.get(`/documents/categories?languageId=${locale}`)
  }

  async validateIntroducingBrokerByCode(
    introducingBrokerCode: string,
    tickmillCompanyId?: TickmillCompaniesEnum
  ): Promise<{ id: string; name: string }> {
    let url = `introducing-brokers/valid/${introducingBrokerCode}`
    if (tickmillCompanyId) {
      url = url += `/${tickmillCompanyId}`
    }
    return this.apiClient.get(url)
  }

  async validatePhoneNumber(number: string, countryCode: string): Promise<PhoneNumberDto> {
    return this.apiClient.get(
      `/phone-number-lookup?PhoneNumber=${encodeURIComponent(countryCode)}${number}`
    )
  }

  async validateBasePhoneNumber(number: string, countryCode: string): Promise<PhoneNumberDto> {
    return this.apiClient.get(
      `/phone-number-lookup?phoneNumber=${encodeURIComponent(countryCode)}${number}`
    )
  }

  async validatePhoneCode(
    leadId: string,
    verificationId: string,
    code: string
  ): Promise<PhoneNumberDto> {
    return this.apiClient.put(
      `/lead/${leadId}/complete-phone-number-verification/${verificationId}`,
      { code }
    )
  }

  async getClientClassificationTestAnswers(query?: PageQuery): Promise<TestAnswersDto> {
    return this.apiClient.get(`/account/classification-answers?${preparePageQueryParams(query)}`)
  }

  async getClassificationTest(query?: PageQuery): Promise<ClientTestDto> {
    return this.apiClient.get(`/account/classification-test?${preparePageQueryParams(query)}`)
  }

  async getAccountLegalDocuments(locale: string): Promise<LegalDocumentsWithTMCompany> {
    return this.apiClient.get(`/account/legal-documents?languageId=${locale}`)
  }

  async addClientClassificationTest(values: CreateClientTest): Promise<AxiosResponse> {
    return await this.apiClient.fetch('/account/tests', {
      method: 'POST',
      data: values,
    })
  }

  async getPlatformInfo(query: PageQuery): Promise<PlatformInfoDto[]> {
    return await this.apiClient.get(`/platforms-info?${preparePageQueryParams(query)}`)
  }

  async getAccountKycTest(locale: string): Promise<TestSectionsDto> {
    return await this.apiClient.get(`/account/kyc-test?languageId=${locale}`)
  }

  async updateAccountKycDetails(values: CreateKycUpdateTicket): Promise<TestSectionsDto[]> {
    return await this.apiClient.post(`/account/kyc-update-details`, values)
  }

  async updateAccountKycDetailsResponse(values: CreateKycUpdateTicket): Promise<AxiosResponse> {
    return await this.apiClient.fetch('/account/kyc-update-details', {
      method: 'POST',
      data: values,
    })
  }

  async updateAccountDormantDetails(values: CreateDormantUpdateTicket): Promise<TestSectionsDto[]> {
    return await this.apiClient.post(`/account/dormant-update-details`, values)
  }

  async updateAccountDormantDetailsResponse(
    values: CreateDormantUpdateTicket
  ): Promise<AxiosResponse> {
    return await this.apiClient.fetch(`/account/dormant-update-details`, {
      method: 'POST',
      data: values,
    })
  }

  async getTaxFormQuestions(query: PageQuery): Promise<ClientTestDto> {
    return await this.apiClient.get(`/account/tax-form-test?${preparePageQueryParams(query)}`)
  }

  async getTaxFormAnswers(query: PageQuery): Promise<TestAnswersDto> {
    return await this.apiClient.get(`/account/tax-form-answers?${preparePageQueryParams(query)}`)
  }
  async getAutochartist(locale: string): Promise<AutochartistDto> {
    return this.apiClient.get(`/autochartist-info?languageId=${locale}`)
  }
  async getAccountAppTestAnswers(
    locale: Locale,
    tickmillProduct: TickmillProductType = TickmillProductType.CFD
  ): Promise<TestAnswersDto> {
    return await this.apiClient.get(
      `/account/ap-test-answers?languageId=${locale}&tickmillProduct=${tickmillProduct}`
    )
  }
  async getAccountAppTest(locale: string): Promise<TestSectionsDto> {
    return await this.apiClient.get(`/account/ap-test?languageId=${locale}`)
  }

  async getMultiTierQuestionnaire(locale: string): Promise<TestSectionsDto> {
    return await this.apiClient.get(`/multitier_ib_questionnaire?languageId=${locale}`)
  }

  async getIBQuestionnaire(locale: string): Promise<TestSectionsDto> {
    return await this.apiClient.get(`/ib_questionnaire?languageId=${locale}`)
  }

  async getApTestAttempts(productId?: TickmillProductType): Promise<number> {
    return await this.apiClient.get(
      `/account/ap-test-attempts${productId ? `?productId=${productId}` : ''}`
    )
  }

  async getIBCampaignsResults(): Promise<PageData<IntroducingBrokerCampaignResultDto>> {
    return await this.apiClient.get('/ib-campaigns/list')
  }

  async getRequiredDocuments(): Promise<RequiredDocument[]> {
    return await this.apiClient.get(`/required-documents`)
  }

  async getTaxHistory(locale: string): Promise<TaxHistoryDto[]> {
    return this.apiClient.get(`/account/tax-form-history?languageId=${locale}`)
  }

  async getOwnershipStructures(locale: string): Promise<NameDto[]> {
    return this.apiClient.get(`/ownership-structures?languageId=${locale}`)
  }

  async addPhoneNumber(values: PhoneNumberAddFormValues): Promise<ClientPhoneNumberDto> {
    return this.apiClient.post('/client/phone-numbers', values)
  }

  async updatePhoneNumber(phoneNumberId: string, values: PhoneNumberAddFormValues): Promise<void> {
    return this.apiClient.put(`/client/phone-numbers/${phoneNumberId}`, values)
  }

  async resendPhoneNumberVerification(
    verificationId: string,
    phoneNumberId: string,
    workflow: PhoneVerificationWorkflowEnum
  ): Promise<{ phoneVerificationId: string }> {
    return this.apiClient.put(`/client/verifications/${verificationId}/send-new-code`, {
      workflow,
      phoneNumberId,
      verificationId,
    })
  }

  async validateClientPhoneNumber(phoneNumber: string): Promise<void> {
    return this.apiClient.get(
      `/client/phone-number-lookup?phoneNumber=${encodeURIComponent(phoneNumber)}`
    )
  }

  async deletePhoneNumber(phoneNumberId: string): Promise<void> {
    return this.apiClient.delete(`/client/phone-numbers/${phoneNumberId}`)
  }

  async requestPhoneNumberVerification(phoneNumberId: string): Promise<{
    clientId: string
    phoneVerificationId: string
  }> {
    return this.apiClient.post(`/client/phone-numbers/${phoneNumberId}/verifications`, {})
  }

  async verifyPhoneNumber(
    phoneNumberId: string,
    verificationId: string,
    code: string
  ): Promise<void> {
    return this.apiClient.put(
      `/client/phone-numbers/${phoneNumberId}/verifications/${verificationId}`,
      { code }
    )
  }

  async cancelPhoneNumberVerification(
    phoneNumberId: string,
    verificationId: string
  ): Promise<void> {
    return this.apiClient.put(
      `/client/phone-numbers/${phoneNumberId}/verifications/${verificationId}/cancel`,
      {}
    )
  }

  async getTradingAccountClassificationType(
    platformTypeId: number,
    currencyId: CurrencyType,
    accountGroupTypeId: AccountGroupType,
    accountTypeId: number
  ): Promise<NameDto[]> {
    return this.apiClient.get(
      `/trading-accounts/platform-types/${platformTypeId}/currencies/${currencyId}/account-group-types/${accountGroupTypeId}/account-types/${accountTypeId}/classification-types`
    )
  }
  async getNotifications(query?: PageQuery): Promise<PageData<Notification>> {
    return await this.apiClient.get(`/notifications?${preparePageQueryParams(query)}`)
  }

  async getNotification(id: string, query: PageQuery): Promise<Notification> {
    return await this.apiClient.get(`/notifications/${id}?${preparePageQueryParams(query)}`)
  }

  async readAllNotifications(NotificationList: string[]): Promise<void> {
    return await this.apiClient.put('/notifications/mark-all-as-read', {
      NotificationList,
    })
  }

  async getNotificationPreferences(): Promise<NotificationPreferences> {
    return await this.apiClient.get('/clients/preferences')
  }

  async getTruliooCountries(
    countryId: string,
    languageId: string,
    clientTypeId: number
  ): Promise<TruliooEIdData[]> {
    return await this.apiClient.get(
      `/countries/registration-fields?countryId=${countryId}&languageId=${languageId}&clientTypeId=${clientTypeId}`
    )
  }

  async updateNotificationPreferences(values: NotificationPreferences): Promise<void> {
    return await this.apiClient.put('/notifications/clients/preferences', values)
  }

  async deleteNotification(id: string): Promise<void> {
    return await this.apiClient.put(`/notifications/mark-as-deleted/${id}`, {})
  }

  async readNotification(id: string): Promise<void> {
    return await this.apiClient.put(`/notifications/mark-as-read/${id}`, {})
  }

  async getSupportContacts(companyId: number): Promise<SupportContactsDto> {
    return await this.apiClient.get(`/support-contacts/${companyId}`)
  }

  async getEducationalVideos(languageId: string): Promise<LearningVideoDto[]> {
    return this.apiClient.get(
      `/educational-videos?productId=${TickmillProductType.ETD}&languageId=${languageId}`
    )
  }

  async getTutorialVideos(languageId: string): Promise<LearningVideoDto[]> {
    return this.apiClient.get(
      `/tutorial-videos?productId=${TickmillProductType.ETD}&languageId=${languageId}`
    )
  }

  async getSubscriptionBundles(): Promise<SubscriptionBundleDto[]> {
    return this.apiClient.get('subscriptions/subscription-bundles')
  }

  async getActiveSubscriptions(
    query: PageQuery
  ): Promise<{ items: SubscriptionHistoryItem[]; itemsCount: number }> {
    return this.apiClient.get(`/subscriptions/requests?${preparePageQueryParams(query)}`)
  }

  async getSubscriptionGroups(): Promise<SubscriptionGroupDto[]> {
    return this.apiClient.get('subscriptions/subscription-groups')
  }

  async updateSubscriptionRequest(data: UpdateSubscriptionRequest): Promise<void> {
    return this.apiClient.post('/subscriptions/requests', data)
  }

  async getSubscriptionsHistory(query: PageQuery): Promise<PageData<SubscriptionHistoryItem>> {
    return this.apiClient.get(`/subscriptions/requests?${preparePageQueryParams(query)}`)
  }

  async getBankAccounts(): Promise<BankAccount[]> {
    return this.apiClient.get('/bank-account-details')
  }

  async addBankAccount(values: BankAccountFormValues): Promise<BankAccount> {
    return this.apiClient.post('/bank-account-details', values)
  }

  async getGuidesTypes(): Promise<NameDto[]> {
    return this.apiClient.get(`/guides/types`)
  }

  async getGuideByTypeId(guideTypeId: number, query?: PageQuery): Promise<ClientGuideDto> {
    return this.apiClient.get(`/client/guide/${guideTypeId}?${preparePageQueryParams(query)}`)
  }

  async updateClientGuideRead(guideTypeId: number, query?: PageQuery): Promise<void> {
    return this.apiClient.put(
      `/client/guide/${guideTypeId}/read?${preparePageQueryParams(query)}`,
      {}
    )
  }

  async updateClientGuideDismiss(guideTypeId: number, query?: PageQuery): Promise<void> {
    return this.apiClient.put(
      `/client/guide/${guideTypeId}/dismiss?${preparePageQueryParams(query)}`,
      {}
    )
  }

  async getClientProductSubscriptions(
    type: string,
    languageId: string
  ): Promise<TradingPlaformsDataProps[]> {
    return this.apiClient.get(
      `/subscriptions/subscription-platforms/${type}?languageId=${languageId}`
    )
  }

  async getClientProductsByToken(leadId: string, token: string): Promise<NameDto[]> {
    return this.apiClient.get(`/products/by-token/${leadId}/${token}`)
  }

  async getTradingAccountStatement(
    tradingAccountId: string,
    StatementType: StatementType,
    query: PageQuery
  ): Promise<StatementFile> {
    return this.apiClient.get(
      `/statements/${tradingAccountId}/${StatementType}?${preparePageQueryParams(query).toString()}`
    )
  }

  async checkTradingAccountStatement(
    tradingAccountId: string,
    StatementType: StatementType,
    query: PageQuery
  ): Promise<{ dates: string[] }> {
    return this.apiClient.get(
      `/statements/${tradingAccountId}/${StatementType}/dates/?${preparePageQueryParams(
        query
      ).toString()}`
    )
  }

  async getSubscriptionCartItems(): Promise<SubscriptionCartItem[]> {
    return this.apiClient.get('subscriptions/cart/items')
  }

  async getSubscriptionCartItem(id: string): Promise<SubscriptionCartItem> {
    return this.apiClient.get(`subscriptions/cart/items/${id}`)
  }

  async deleteSubscriptionCartItem(id: string): Promise<void> {
    return this.apiClient.delete(`subscriptions/cart/items/${id}`)
  }

  async addSubscriptionItemToCart(values: AddSubscriptionItemToCartData): Promise<void> {
    return this.apiClient.post('subscriptions/cart', values)
  }

  async getDisplayCurrencies(): Promise<WalletCurrency[]> {
    return this.apiClient.get<WalletCurrency[]>('/display-currencies')
  }

  async get2FAProvidersTypes(): Promise<NameDto<number>[]> {
    return this.apiClient.get<NameDto<number>[]>('2fa/providers/types')
  }

  async enable2FAProviders(value: Update2FAProvidersData): Promise<void> {
    return this.apiClient.post('2fa/providers', value)
  }

  async enableGoogleAuthenticator2FAProviders(
    value: Pick<Update2FAProvidersData, 'typeId'>
  ): Promise<GoogleAuthenticator2FAProviderDataDto> {
    return this.apiClient.post('2fa/providers', value)
  }

  async verify2FACodesFistTimeUse(value: VerifyFistTimeUseData): Promise<void> {
    return this.apiClient.post('2fa/codes/verify-fist-time-use', value)
  }

  async get2FAProviders(): Promise<TwoFAProviderItem[]> {
    return this.apiClient.get<TwoFAProviderItem[]>('2fa/providers')
  }

  async disable2FAProviders(payload: Update2FAProvidersData): Promise<void> {
    return this.apiClient.delete(`2fa/providers`, payload)
  }

  async get2FARecoveryCodesGenerate(): Promise<TwoFactorAuthRecoveryCodesDto> {
    return this.apiClient.get(`/2fa/recoverycodes/generate`)
  }

  async get2FARecoveryCodesRegenerate(): Promise<TwoFactorAuthRecoveryCodesDto> {
    return this.apiClient.get(`/2fa/recoverycodes/regenerate`)
  }

  async isRetakeClassificationTestEnabled(subCategoryId: number): Promise<boolean> {
    return this.apiClient.get(`/account/retake-classification-test?subcategoryId=${subCategoryId}`)
  }

  async getPublicDomainByTickmillCompany(
    tickmillCompany: number,
    domain: string
  ): Promise<PublicDomainDto> {
    return this.apiClient.get(`/public-domain/${tickmillCompany}?domain=${domain}`)
  }

  async updateClientLegalDocuments(values: AgreedClientDocumentDto): Promise<void> {
    return this.apiClient.put<void>('/account/legal-documents', values)
  }

  async getMarketDataClassifications(locale: string): Promise<NameDto[]> {
    return this.apiClient.get<NameDto[]>(`/market-data-classifications?languageId=${locale}`)
  }

  async getCampaignCurrency(campaignId: string): Promise<CurrencyType[]> {
    return this.apiClient.get(`/campaigns/${campaignId}/currencies/for-create-trading-account`)
  }

  async getTradingAccountCreationOptions(
    campaignId: string,
    params?: {
      platformTypeId?: string
    }
  ): Promise<TradingAccountCreationOptions> {
    if (params?.platformTypeId) {
      return this.apiClient.get(
        `/campaigns/${campaignId}/for-create-trading-account?${new URLSearchParams(params)}`
      )
    } else {
      return this.apiClient.get(`/campaigns/${campaignId}/for-create-trading-account`)
    }
  }

  async getGeneralStatus(params: {
    clientId?: string
    languageId?: string
  }): Promise<DocumentGeneralStatusDto> {
    return this.apiClient.get(`/documents/general-status?${new URLSearchParams(params)}`)
  }

  async getCreateQR(params: CreateQRParams): Promise<File> {
    const urlSearchParams = new URLSearchParams({
      url: params.url,
      foregroundColor: params.foregroundColor,
      backgroundColor: params.backgroundColor,
      includeLogoInQR: params.includeLogoInQR.toString(),
      imageFormat: params.imageFormat,
      resolution: params.resolution,
      shortenUrl: params.shortenUrl.toString(),
    })

    const response = await this.apiClient.fetch<File>(`/create-qr?${urlSearchParams}`, {
      method: 'GET',
      responseType: 'blob',
    })

    return response.data
  }
}
