import { TFunction } from 'i18next'

import { Theme } from '../../global/theme/Theme'
import { NotificationPreferences } from '../../model/Notification'

export const themeOptions = (t: TFunction<'translation', undefined, 'translation'>) => [
  {
    id: 1,
    option: 'auto' as Theme,
    name: t('Automatic'),
  },
  {
    id: 2,
    option: 'light' as Theme,
    name: t('Light Mode'),
  },
  {
    id: 3,
    option: 'dark' as Theme,
    name: t('Dark Mode'),
  },
]

export const initialNotificationPreferences: NotificationPreferences = {
  isGeneralSilent: false,
  isPlatformRelatedSilent: false,
  isPromotionalSilent: false,
}

export const notificationOptions = (
  t: TFunction<'translation', undefined, 'translation'>,
  notificationPreferences?: NotificationPreferences
) => {
  const options = [
    {
      id: 'isGeneralSilent' as keyof NotificationPreferences,
      name: t('Notifications.Settings.General'),
      label: t('Notifications.Settings.Warnings, alerts, tips, etc'),
    },
    {
      id: 'isPlatformRelatedSilent' as keyof NotificationPreferences,
      name: t('Notifications.Settings.Platform Related'),
      label: t('Notifications.Settings.Maintenance, upgrade, etc'),
    },
    {
      id: 'isPromotionalSilent' as keyof NotificationPreferences,
      name: t('Notifications.Settings.Promotional'),
      label: t('Notifications.Settings.New products, deals, etc'),
    },
  ]

  if (!notificationPreferences) {
    return options.map((option) => ({ ...option, value: false }))
  }

  return options.map((option) => ({
    ...option,
    value: notificationPreferences[option.id],
  }))
}
