import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ExternalLink } from '../../../../../global/ExternalLink'
import { Button } from '../../../../../global/button/Button'
import { DocGeneralIcon } from '../../../../../icons/DocGeneralIcon'
import {
  LegalDocumentDto,
  isLegalDocumentWidgetCheckbox,
  isLegalDocumentWidgetReadAndAgree,
  isLegalDocumentsWidgetCheckbox,
} from '../../../../../model/CompanyLegalDocumentDto'
import { DocumentFieldItem as DocumentCheckBoxFieldItem } from '../../../Components/DocumentCheckBoxField'
import {
  DocumentFieldItem,
  DocumentFieldItem as DocumentReadAndAgreeFieldItem,
} from '../../../Components/DocumentReadAndAgreeField'

import styles from './DocumentItemFactory.module.scss'

export const DocumentItemFactory: React.FC<
  DocumentCheckBoxFieldItem | DocumentReadAndAgreeFieldItem
> = (props) => {
  const { documents = [], component } = props

  if (isLegalDocumentWidgetReadAndAgree(documents)) {
    const { title, isAccepted, onClick } = props as DocumentReadAndAgreeFieldItem

    return (
      <div className={classNames(styles.read, styles.mb)}>
        <ReadAndAgree
          documents={documents}
          title={title}
          isAccepted={isAccepted}
          onClick={onClick}
        />
      </div>
    )
  }

  if (isLegalDocumentsWidgetCheckbox(documents)) {
    return (
      <div className={classNames(styles.read, styles.mb)}>
        <DocumentCheckBoxGroup documents={documents} />
      </div>
    )
  }

  if (isLegalDocumentWidgetCheckbox(documents)) {
    return (
      <div className={classNames(styles.read, styles.mb)}>
        <DocumentCheckBox documents={documents} />
      </div>
    )
  }

  return <React.Fragment>{component}</React.Fragment>
}

export const ReadAndAgree: React.FC<Omit<DocumentFieldItem, 'component'>> = (props) => {
  const { t } = useTranslation()

  const { title, isAccepted, onClick } = props

  return (
    <div className='is-flex is-align-items-flex-start is-justify-content-space-between'>
      <span className='is-flex is-align-items-center'>
        <span className='pr-3'>
          <DocGeneralIcon />
        </span>
        <span className='pr-2'>{title}</span>
      </span>
      {isAccepted ? (
        <Button appearance='link' type='button'>
          <span className='has-text-success'>{t('Sign up.Accepted')}</span>
        </Button>
      ) : (
        <Button appearance='link' type='button' onClick={onClick}>
          {t('Sign up.Read & Accept')}
        </Button>
      )}
    </div>
  )
}

interface DocumentCheckBoxProps {
  documents: LegalDocumentDto[]
}

export const DocumentCheckBoxGroup: React.FC<DocumentCheckBoxProps> = (props) => {
  const { documents } = props

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <span className='text'>
        {t('Sign up.I confirm that I have read, consent and agree to the')}
      </span>
      {documents
        .map<React.ReactNode>((x) => <DocumentLink document={x} key={x.id} />)
        .reduce((prev, curr) => [prev, ', ', curr])}{' '}
      <span className='text'>*</span>
    </React.Fragment>
  )
}

export const DocumentCheckBox: React.FC<DocumentCheckBoxProps> = (props) => {
  const { documents = [] } = props
  const [document] = documents

  const { t } = useTranslation()

  return (
    <React.Fragment>
      {document?.url && <span className='text'>{t('I agree with')}</span>}
      <DocumentLink document={document} /> <span className='text'>*</span>
    </React.Fragment>
  )
}

interface DocumentLinkProps {
  document: LegalDocumentDto
}

const DocumentLink: React.FC<DocumentLinkProps> = (props) => {
  const { document } = props

  return (
    <ExternalLink
      title={document?.title || undefined}
      url={document?.url || undefined}
      className={classNames('text pl-1', {
        'is-link': !!document?.url,
      })}
    >
      {document.title}
    </ExternalLink>
  )
}
