import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { PromoOptionCategoryEnum } from '../model/PromoOptionCategoryEnum'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { useIBPrograms } from '../utils/useIBPrograms'
import { IBClientsPage } from './Clients/IBClientsPage'
import { IBContestPage } from './Contest/IBContestPage'
import { DateFilterContextWrapper } from './DateFilterContextWrapper'
import { IBIncomePage } from './Income/IBIncomePage'
import { LegalDocumentsPage } from './LegalDocuments/LegalDocumentsPage'
import { LoyaltyPage } from './Loyalty/LoyaltyPage'
import { MultiTierPage } from './MultiTier/MultiTierPage'
import { LinksPage } from './Referrals/Links/LinksPage'
import { PromoMaterialsPage } from './Referrals/PromoMaterials/PromoMaterialsPage'
import { QRCodePage } from './Referrals/QRCode/QRCodePage'
import { ReferralMaterialsPage } from './Referrals/ReferralMaterialsPage'
import { ReportsPage } from './Reports/ReportsPage'
import { IntroducingBrokerTransactionHistoryPage } from './TransactionHistory/IntroducingBrokerTransactionHistoryPage'
import { IBWalletTransferPage } from './Wallets/IBWalletTransferPage'
import { IBWalletWithdrawPage } from './Wallets/IBWalletWithdrawPage'
import { IBWalletWithdrawTransactionCompletedPage } from './Wallets/IBWalletWithdrawTransactionCompletedPage'
import { IBWalletWithdrawTransactionFailedPage } from './Wallets/IBWalletWithdrawTransactionFailedPage'
import { IBWalletWithdrawTransactionSuccessPage } from './Wallets/IBWalletWithdrawTransactionSuccessPage'
import { IBWalletsPage } from './Wallets/IBWalletsPage'

interface IntroducingBrokerRoutesProps {
  tab?: string
}

export const IntroducingBrokerRoutes: React.FunctionComponent<IntroducingBrokerRoutesProps> = ({
  tab,
}) => {
  const { account } = useAccountReadContext()
  const { multiTierProgram } = useIBPrograms()

  if (tab === 'wallets') {
    return (
      <Routes>
        <Route path=':walletId/withdrawal' element={<IBWalletWithdrawPage />} />
        <Route path=':walletId/transfer' element={<IBWalletTransferPage />} />
        <Route
          path=':walletId/withdraw/transaction/succeed'
          element={<IBWalletWithdrawTransactionSuccessPage />}
        />
        <Route
          path=':walletId/withdraw/transaction/completed'
          element={<IBWalletWithdrawTransactionCompletedPage />}
        />
        <Route
          path=':walletId/withdraw/transaction/failed'
          element={<IBWalletWithdrawTransactionFailedPage />}
        />
        <Route index element={<IBWalletsPage />} />
      </Routes>
    )
  }

  if (tab === 'clients') {
    return (
      <DateFilterContextWrapper>
        <Routes>
          <Route index element={<IBClientsPage />} />
        </Routes>
      </DateFilterContextWrapper>
    )
  }

  if (tab === 'legal-documents') {
    return (
      <Routes>
        <Route index element={<LegalDocumentsPage />} />
      </Routes>
    )
  }

  if (tab === 'income') {
    return (
      <DateFilterContextWrapper>
        <Routes>
          <Route index element={<IBIncomePage />} />
        </Routes>
      </DateFilterContextWrapper>
    )
  }

  if (tab === 'transaction-history') {
    return (
      <DateFilterContextWrapper>
        <Routes>
          <Route index element={<IntroducingBrokerTransactionHistoryPage />} />
        </Routes>
      </DateFilterContextWrapper>
    )
  }

  if (tab === 'ib-contest') {
    return (
      <DateFilterContextWrapper>
        <Routes>
          <Route index element={<IBContestPage />} />
        </Routes>
      </DateFilterContextWrapper>
    )
  }

  if (tab === 'loyalty-program') {
    return (
      <Routes>
        <Route index element={<LoyaltyPage />} />
      </Routes>
    )
  }

  if (tab === 'reports') {
    return (
      <Routes>
        <Route index element={<ReportsPage />} />
      </Routes>
    )
  }

  if (tab === 'referral-materials') {
    return (
      <Routes>
        <Route path='links' element={<LinksPage />} />
        <Route
          path='logos'
          element={
            <PromoMaterialsPage promoCategoryType={PromoOptionCategoryEnum.LOGO} name={'Logo'} />
          }
        />
        <Route
          path='banners'
          element={
            <PromoMaterialsPage
              promoCategoryType={PromoOptionCategoryEnum.BANNER}
              name={'Banner'}
            />
          }
        />
        <Route path='qr-code' element={<QRCodePage />} />
        <Route index element={<ReferralMaterialsPage />} />
      </Routes>
    )
  }
  if (tab === 'multi-tier' && account?.companyConfiguration.hasMultiTier && multiTierProgram) {
    return (
      <Routes>
        <Route index element={<MultiTierPage />} />
      </Routes>
    )
  }

  if (!tab) {
    return null
  }

  return null
}
