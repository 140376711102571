import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { PasswordIcon } from '../../icons/PasswordIcon'
import { TransferIcon } from '../../icons/TransferIcon'
import { NameDto } from '../../model/NameDto'
import { isTMTPlatformType } from '../../model/PlatformTypeEnum'
import {
  DemoTradingAccount,
  TradingAccount,
  TradingAccountCampaign,
} from '../../model/TradingAccount'
import { TradingAccountRestrictions, isRestricted } from '../../utils/trading-account.utils'

import styles from './TradingAccountActionModal.module.scss'

interface DemoTradingAccountProps {
  onCancel: () => void
  tradingAccount: TradingAccount | DemoTradingAccount
  setTradingAccountModal: (visible: boolean) => void
  setManagePasswordsModal: (visible: boolean) => void
}

interface BodyProps {
  restrictions: NameDto[]
  campaigns: TradingAccountCampaign[]
  tradingAccount: TradingAccount | DemoTradingAccount
  setTradingAccountModal: (visible: boolean) => void
  setManagePasswordsModal: (visible: boolean) => void
}

const TradingAccountActionModalBody: React.FC<BodyProps> = ({
  setTradingAccountModal,
  setManagePasswordsModal,
  tradingAccount,
  restrictions,
  campaigns,
}) => {
  const navigate = useNavigate()
  const hasTransferFromRestriction = isRestricted(
    TradingAccountRestrictions.TRANSFER_FROM,
    restrictions
  )
  const hasCampaign = campaigns?.length > 0

  const { t } = useTranslation()

  return (
    <section className={classNames(styles.body, 'modal-card-body')}>
      <ul>
        <li
          onClick={() => {
            setTradingAccountModal(false)
            setManagePasswordsModal(true)
          }}
        >
          <div className={styles.wrapper}>
            <div>
              <PasswordIcon />
            </div>
            <div>{t('Trading Account.Manage Trading Account Passwords')}</div>
          </div>
        </li>
        {hasCampaign && !hasTransferFromRestriction && (
          <li
            onClick={() =>
              navigate(
                `/dashboard/traders-room/trading-accounts/${tradingAccount.id}/transfer-from-campaign`
              )
            }
          >
            <div className={styles.wrapper}>
              <TransferIcon color={'text'} strokeWidth={1.5} />
              <div>{t('Trading Account.Transfer Funds To Wallet')}</div>
            </div>
          </li>
        )}
      </ul>
    </section>
  )
}

export const TradingAccountActionModal: React.FC<DemoTradingAccountProps> = ({
  onCancel,
  tradingAccount,
  setTradingAccountModal,
  setManagePasswordsModal,
}) => {
  const { t } = useTranslation()

  const hasRestrictions = (
    account?: TradingAccount | DemoTradingAccount
  ): account is TradingAccount => {
    if (!account) {
      return false
    }
    return 'restrictions' in account
  }

  const hasCampaigns = (
    account?: TradingAccount | DemoTradingAccount
  ): account is TradingAccount => {
    if (!account) {
      return false
    }
    return 'restrictions' in account
  }
  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{tradingAccount?.name}</p>
      </header>
      <TradingAccountActionModalBody
        restrictions={hasRestrictions(tradingAccount) ? tradingAccount.restrictions : []}
        campaigns={hasCampaigns(tradingAccount) ? tradingAccount.campaigns : []}
        tradingAccount={tradingAccount}
        setManagePasswordsModal={setManagePasswordsModal}
        setTradingAccountModal={setTradingAccountModal}
      />
      <footer className='modal-card-foot'>
        <button className='button' onClick={onCancel}>
          {t('Cancel')}
        </button>
      </footer>
    </>
  )
}
