import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { WalletTransferPage } from '../../Traders-Room/Wallets/WalletTransfer/WalletTransferPage'
import { Loading } from '../../global/Loading/Loading'
import { WalletTypeEnum } from '../../model/WalletDto'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { isTickmillPartner } from '../../utils/companyName.utils'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'

import styles from './IBWalletTransferPage.module.scss'

export const useIBWalletTransferFetch = () => {
  const apiClient = useApiClient(ClientApiClient)
  const callback = useCallback(async () => {
    return apiClient.getWallets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data = [], isLoading } = useFetchOne(callback)

  return { wallets: data, isLoading }
}

export const IBWalletTransferPage: React.FC = () => {
  useScrollToTop()
  const goBackPath = '/dashboard/introducing-broker/wallets'
  const { account } = useAccountReadContext()

  const navigate = useNavigate()

  const { wallets, isLoading } = useIBWalletTransferFetch()

  const handleSubmit = () => {
    navigate('/dashboard/introducing-broker/transaction-history')
  }

  const handlePageExitConfirmation = () => {
    navigate(goBackPath)
  }

  const { t } = useTranslation()

  const ibOrPartnerTitle = isTickmillPartner(account)
    ? t('Wallet.Partner')
    : t('Wallet.Introducing Broker')

  return (
    <Loading isLoading={isLoading}>
      <WalletTransferPage
        limits={[]}
        title={`${ibOrPartnerTitle} ${t('Transfer')}`}
        description={
          <>
            <span className={styles.title}>{t('Wallet.Important!')}</span>{' '}
            {t('Wallet.You can transfer funds only to your main wallets (EUR, USD, GBP).')}
          </>
        }
        wallets={wallets}
        walletTypeFrom={WalletTypeEnum.IB}
        walletTypeTo={[WalletTypeEnum.IB, WalletTypeEnum['FX/CFD']]}
        onSubmit={handleSubmit}
        onCancel={handlePageExitConfirmation}
      />
    </Loading>
  )
}
