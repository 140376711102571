export function MakeDepositIcon() {
  return (
    <svg
      width='540'
      height='195'
      viewBox='0 0 540 195'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_8430_97623)'>
        <path
          d='M141.152 151.267V104.09C141.152 103.226 140.452 102.526 139.589 102.526H27.6257C26.7621 102.526 26.062 103.226 26.062 104.09V151.267C26.062 152.131 26.7621 152.831 27.6257 152.831H139.589C140.452 152.831 141.152 152.131 141.152 151.267Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M135.895 146.263V109.094C135.895 108.23 135.194 107.53 134.331 107.53H32.8918C32.0282 107.53 31.3281 108.23 31.3281 109.094V146.263C31.3281 147.127 32.0282 147.827 32.8918 147.827H134.331C135.194 147.827 135.895 147.127 135.895 146.263Z'
          fill='#C0E3FA'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M131.584 113.8C132.62 113.8 133.46 112.96 133.46 111.924C133.46 110.887 132.62 110.047 131.584 110.047C130.547 110.047 129.707 110.887 129.707 111.924C129.707 112.96 130.547 113.8 131.584 113.8Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M126.58 113.8C127.616 113.8 128.456 112.96 128.456 111.924C128.456 110.887 127.616 110.047 126.58 110.047C125.543 110.047 124.703 110.887 124.703 111.924C124.703 112.96 125.543 113.8 126.58 113.8Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M121.58 113.8C122.616 113.8 123.456 112.96 123.456 111.924C123.456 110.887 122.616 110.047 121.58 110.047C120.543 110.047 119.703 110.887 119.703 111.924C119.703 112.96 120.543 113.8 121.58 113.8Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M83.6104 138.938C89.8285 138.938 94.8692 133.897 94.8692 127.679C94.8692 121.461 89.8285 116.42 83.6104 116.42C77.3923 116.42 72.3516 121.461 72.3516 127.679C72.3516 133.897 77.3923 138.938 83.6104 138.938Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M121.58 113.8C122.616 113.8 123.456 112.96 123.456 111.924C123.456 110.887 122.616 110.047 121.58 110.047C120.543 110.047 119.703 110.887 119.703 111.924C119.703 112.96 120.543 113.8 121.58 113.8Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M49.021 145.7C50.0574 145.7 50.8975 144.86 50.8975 143.824C50.8975 142.787 50.0574 141.947 49.021 141.947C47.9847 141.947 47.1445 142.787 47.1445 143.824C47.1445 144.86 47.9847 145.7 49.021 145.7Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M152.102 116.083V68.9055C152.102 68.0419 151.401 67.3418 150.538 67.3418H38.5749C37.7113 67.3418 37.0112 68.0419 37.0112 68.9055V116.083C37.0112 116.947 37.7113 117.647 38.5749 117.647H150.538C151.401 117.647 152.102 116.947 152.102 116.083Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M146.844 111.079V73.9094C146.844 73.0458 146.144 72.3457 145.28 72.3457H43.841C42.9774 72.3457 42.2773 73.0458 42.2773 73.9094V111.079C42.2773 111.943 42.9774 112.643 43.841 112.643H145.28C146.144 112.643 146.844 111.943 146.844 111.079Z'
          fill='#C0E3FA'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M142.533 78.6162C143.569 78.6162 144.409 77.7761 144.409 76.7398C144.409 75.7034 143.569 74.8633 142.533 74.8633C141.496 74.8633 140.656 75.7034 140.656 76.7398C140.656 77.7761 141.496 78.6162 142.533 78.6162Z'
          fill='white'
        />
        <path
          d='M137.529 78.6162C138.565 78.6162 139.405 77.7761 139.405 76.7398C139.405 75.7034 138.565 74.8633 137.529 74.8633C136.492 74.8633 135.652 75.7034 135.652 76.7398C135.652 77.7761 136.492 78.6162 137.529 78.6162Z'
          fill='white'
        />
        <path
          d='M94.5596 103.753C100.778 103.753 105.818 98.7123 105.818 92.4942C105.818 86.2761 100.778 81.2354 94.5596 81.2354C88.3415 81.2354 83.3008 86.2761 83.3008 92.4942C83.3008 98.7123 88.3415 103.753 94.5596 103.753Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M54.9595 110.516C55.9958 110.516 56.8359 109.676 56.8359 108.64C56.8359 107.603 55.9958 106.763 54.9595 106.763H46.2026C45.1662 106.763 44.3261 107.603 44.3261 108.64C44.3261 109.676 45.1662 110.516 46.2026 110.516H54.9595Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M132.529 78.6162C133.565 78.6162 134.405 77.7761 134.405 76.7398C134.405 75.7034 133.565 74.8633 132.529 74.8633C131.492 74.8633 130.652 75.7034 130.652 76.7398C130.652 77.7761 131.492 78.6162 132.529 78.6162Z'
          fill='white'
        />
        <path
          d='M59.9702 110.516C61.0066 110.516 61.8467 109.676 61.8467 108.64C61.8467 107.603 61.0066 106.763 59.9702 106.763C58.9339 106.763 58.0938 107.603 58.0938 108.64C58.0938 109.676 58.9339 110.516 59.9702 110.516Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M164.605 132.503V85.3254C164.605 84.4618 163.905 83.7617 163.042 83.7617H51.0788C50.2152 83.7617 49.5151 84.4618 49.5151 85.3254V132.503C49.5151 133.367 50.2152 134.067 51.0788 134.067H163.042C163.905 134.067 164.605 133.367 164.605 132.503Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M159.348 127.499V90.3294C159.348 89.4657 158.648 88.7656 157.784 88.7656H56.3449C55.4813 88.7656 54.7812 89.4657 54.7812 90.3294V127.499C54.7812 128.363 55.4813 129.063 56.3449 129.063H157.784C158.648 129.063 159.348 128.363 159.348 127.499Z'
          fill='#C0E3FA'
        />
        <path
          d='M155.037 95.0361C156.073 95.0361 156.913 94.196 156.913 93.1597C156.913 92.1233 156.073 91.2832 155.037 91.2832C154 91.2832 153.16 92.1233 153.16 93.1597C153.16 94.196 154 95.0361 155.037 95.0361Z'
          fill='white'
        />
        <path
          d='M150.033 95.0361C151.069 95.0361 151.909 94.196 151.909 93.1597C151.909 92.1233 151.069 91.2832 150.033 91.2832C148.996 91.2832 148.156 92.1233 148.156 93.1597C148.156 94.196 148.996 95.0361 150.033 95.0361Z'
          fill='white'
        />
        <path
          d='M107.064 120.173C113.282 120.173 118.322 115.132 118.322 108.914C118.322 102.696 113.282 97.6553 107.064 97.6553C100.845 97.6553 95.8047 102.696 95.8047 108.914C95.8047 115.132 100.845 120.173 107.064 120.173Z'
          fill='white'
        />
        <path
          d='M67.4634 126.936C68.4997 126.936 69.3398 126.096 69.3398 125.06C69.3398 124.023 68.4997 123.183 67.4634 123.183H58.7065C57.6701 123.183 56.83 124.023 56.83 125.06C56.83 126.096 57.6701 126.936 58.7065 126.936H67.4634Z'
          fill='white'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
        <path
          d='M145.033 95.0361C146.069 95.0361 146.909 94.196 146.909 93.1597C146.909 92.1233 146.069 91.2832 145.033 91.2832C143.996 91.2832 143.156 92.1233 143.156 93.1597C143.156 94.196 143.996 95.0361 145.033 95.0361Z'
          fill='white'
        />
        <path
          d='M72.4741 126.936C73.5105 126.936 74.3506 126.096 74.3506 125.06C74.3506 124.023 73.5105 123.183 72.4741 123.183C71.4378 123.183 70.5977 124.023 70.5977 125.06C70.5977 126.096 71.4378 126.936 72.4741 126.936Z'
          stroke='#424244'
          stroke-width='0.781864'
          stroke-miterlimit='10'
        />
      </g>
      <path
        d='M271.018 13.4639H483.487C489.646 13.4639 494.65 18.4619 494.65 24.6264V169.921H259.855V24.6264C259.855 18.4619 264.86 13.4639 271.018 13.4639Z'
        fill='#D9D9D9'
        stroke='#3C3C3C'
        stroke-width='0.86525'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M486.631 17H267.193C265.432 17 264 18.4434 264 20.218V163.346C264 164.233 264.713 164.958 265.6 164.958H488.231C489.111 164.958 489.83 164.239 489.83 163.346V20.218C489.83 18.4434 488.398 17 486.637 17H486.631Z'
        fill='white'
      />
      <path
        d='M236.512 171.474C236.512 170.616 237.207 169.921 238.071 169.921H516.44C517.298 169.921 517.999 170.616 517.999 171.48V172.078C517.999 177.137 513.895 181.241 508.837 181.241H245.668C240.609 181.241 236.512 177.137 236.512 172.078V171.48V171.474Z'
        fill='#D9D9D9'
        stroke='#3C3C3C'
        stroke-width='0.86525'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M356.087 133.582H339.38C336.963 133.582 335.004 135.537 335.004 137.948C335.004 140.36 336.963 142.315 339.38 142.315H356.087C358.504 142.315 360.463 140.36 360.463 137.948C360.463 135.537 358.504 133.582 356.087 133.582Z'
        fill='#BAEBC8'
      />
      <path
        d='M384.329 133.582H367.622C365.205 133.582 363.246 135.537 363.246 137.948C363.246 140.36 365.205 142.315 367.622 142.315H384.329C386.746 142.315 388.705 140.36 388.705 137.948C388.705 135.537 386.746 133.582 384.329 133.582Z'
        fill='#EBBABA'
      />
      <circle cx='349.709' cy='55.7092' r='14.7092' fill='#3C3C3C' />
      <mask
        id='mask0_8430_97623'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='336'
        y='42'
        width='28'
        height='28'
      >
        <path
          d='M362.6 55.8968C362.6 62.9299 356.904 68.6306 349.879 68.6306C342.855 68.6306 337.159 62.9299 337.159 55.8968C337.159 48.8638 342.855 43.1631 349.879 43.1631C356.904 43.1631 362.6 48.8638 362.6 55.8968Z'
          fill='white'
          stroke='white'
          stroke-width='0.86525'
        />
      </mask>
      <g mask='url(#mask0_8430_97623)'>
        <path
          d='M349.879 69.4958C357.383 69.4958 363.465 63.4069 363.465 55.8968C363.465 48.3867 357.383 42.2978 349.879 42.2978C342.376 42.2978 336.294 48.3867 336.294 55.8968C336.294 63.4069 342.376 69.4958 349.879 69.4958Z'
          fill='#3C3C3C'
          stroke='#3C3C3C'
          stroke-width='0.86525'
        />
        <path
          d='M349.878 55.7425C352.828 55.7425 355.22 53.3494 355.22 50.3974C355.22 47.4453 352.828 45.0522 349.878 45.0522C346.927 45.0522 344.535 47.4453 344.535 50.3974C344.535 53.3494 346.927 55.7425 349.878 55.7425Z'
          fill='white'
        />
        <path
          d='M348.301 56.3105H351.449C356.911 56.3105 361.349 60.7508 361.349 66.2151V69.3086H338.395V66.2151C338.395 60.7508 342.833 56.3105 348.294 56.3105H348.301Z'
          fill='white'
        />
      </g>
      <rect x='335.004' y='109.355' width='6.922' height='6.922' rx='3.461' fill='#C6C6C7' />
      <rect x='354.039' y='106.759' width='7.78725' height='9.51775' rx='3.89362' fill='#C6C6C7' />
      <rect x='373.938' y='96.376' width='6.05675' height='19.9007' rx='3.02837' fill='#C6C6C7' />
      <rect x='392.109' y='99.8374' width='7.78725' height='16.4397' rx='3.89362' fill='#C6C6C7' />
      <rect x='412.008' y='84.2627' width='6.922' height='32.0142' rx='3.461' fill='#C6C6C7' />
      <path
        d='M337.164 105.461L357.065 100.703L376.533 90.3196L395.568 95.9437C403.002 88.8478 409.766 81.4084 417.2 74.3125'
        stroke='#C6C6C7'
        stroke-width='1.7305'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M100.319 33.5967H52.5836C46.4321 33.5967 41.4453 38.5728 41.4453 44.7111V169.352C41.4453 175.49 46.4321 180.466 52.5836 180.466H100.319C106.471 180.466 111.457 175.49 111.457 169.352V44.7111C111.457 38.5728 106.471 33.5967 100.319 33.5967Z'
        fill='#D9D9D9'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M100.368 35.6582H52.7795C47.523 35.6582 43.2617 39.8959 43.2617 45.1234V168.959C43.2617 174.187 47.523 178.424 52.7795 178.424H100.368C105.625 178.424 109.886 174.187 109.886 168.959V45.1234C109.886 39.8959 105.625 35.6582 100.368 35.6582Z'
        fill='white'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M96.6741 86.6652C96.2614 86.6652 95.9056 86.5673 95.6068 86.3713C95.308 86.1738 95.0778 85.8907 94.9161 85.522C94.7543 85.1517 94.6743 84.7066 94.6758 84.1867C94.6773 83.6667 94.7582 83.2255 94.9184 82.8629C95.0801 82.4988 95.3096 82.2219 95.6068 82.0321C95.9056 81.8408 96.2614 81.7451 96.6741 81.7451C97.0869 81.7451 97.4426 81.8408 97.7414 82.0321C98.0417 82.2219 98.2728 82.4988 98.4345 82.8629C98.5962 83.227 98.6763 83.6683 98.6747 84.1867C98.6747 84.7081 98.5939 85.154 98.4322 85.5243C98.2705 85.8946 98.0402 86.1777 97.7414 86.3736C97.4442 86.568 97.0884 86.6652 96.6741 86.6652ZM96.6741 85.728C96.9205 85.728 97.12 85.6022 97.2725 85.3507C97.4249 85.0977 97.5004 84.7097 97.4989 84.1867C97.4989 83.8441 97.4642 83.5618 97.3949 83.3396C97.3256 83.1159 97.2293 82.9493 97.1061 82.8398C96.9829 82.7302 96.8389 82.6754 96.6741 82.6754C96.4292 82.6754 96.2313 82.7996 96.0804 83.048C95.9295 83.2949 95.8532 83.6744 95.8517 84.1867C95.8501 84.5338 95.8833 84.8215 95.951 85.0499C96.0203 85.2782 96.1174 85.4487 96.2421 85.5613C96.3669 85.6724 96.5109 85.728 96.6741 85.728Z'
        fill='#424244'
      />
      <path
        d='M92.1311 86.6652C91.7184 86.6652 91.3626 86.5673 91.0638 86.3713C90.7651 86.1738 90.5348 85.8907 90.3731 85.522C90.2114 85.1517 90.1313 84.7066 90.1328 84.1867C90.1344 83.6667 90.2152 83.2255 90.3754 82.8629C90.5371 82.4988 90.7666 82.2219 91.0638 82.0321C91.3626 81.8408 91.7184 81.7451 92.1311 81.7451C92.5439 81.7451 92.8997 81.8408 93.1985 82.0321C93.4988 82.2219 93.7298 82.4988 93.8915 82.8629C94.0532 83.227 94.1333 83.6683 94.1318 84.1867C94.1318 84.7081 94.0509 85.154 93.8892 85.5243C93.7275 85.8946 93.4972 86.1777 93.1985 86.3736C92.9012 86.568 92.5454 86.6652 92.1311 86.6652ZM92.1311 85.728C92.3776 85.728 92.577 85.6022 92.7295 85.3507C92.882 85.0977 92.9574 84.7097 92.9559 84.1867C92.9559 83.8441 92.9212 83.5618 92.8519 83.3396C92.7826 83.1159 92.6864 82.9493 92.5632 82.8398C92.4399 82.7302 92.2959 82.6754 92.1311 82.6754C91.8863 82.6754 91.6884 82.7996 91.5374 83.048C91.3865 83.2949 91.3103 83.6744 91.3087 84.1867C91.3072 84.5338 91.3403 84.8215 91.4081 85.0499C91.4774 85.2782 91.5744 85.4487 91.6991 85.5613C91.8239 85.6724 91.9679 85.728 92.1311 85.728Z'
        fill='#424244'
      />
      <path
        d='M89.1007 86.6164C88.9343 86.6164 88.7919 86.5578 88.6733 86.4405C88.5562 86.3233 88.4985 86.1813 88.5 86.0147C88.4985 85.8511 88.5562 85.7115 88.6733 85.5958C88.7919 85.4785 88.9343 85.4199 89.1007 85.4199C89.2578 85.4199 89.3964 85.4785 89.5165 85.5958C89.6382 85.7115 89.6998 85.8511 89.7013 86.0147C89.6998 86.1258 89.6705 86.2268 89.6135 86.3179C89.5581 86.4089 89.4849 86.4814 89.3941 86.5354C89.3047 86.5894 89.2069 86.6164 89.1007 86.6164Z'
        fill='#424244'
      />
      <path
        d='M86.1175 86.6143C85.7571 86.6143 85.4375 86.5526 85.1588 86.4292C84.8816 86.3042 84.6629 86.1322 84.5027 85.9131C84.3425 85.694 84.2609 85.4418 84.2578 85.1563H85.4083C85.4129 85.2597 85.446 85.3515 85.5076 85.4317C85.5692 85.5104 85.6532 85.5721 85.7594 85.6169C85.8657 85.6616 85.9866 85.684 86.1221 85.684C86.2577 85.684 86.377 85.6601 86.4802 85.6122C86.5849 85.5629 86.6666 85.4958 86.7251 85.4109C86.7836 85.3245 86.8121 85.2258 86.8106 85.1147C86.8121 85.0036 86.7805 84.9049 86.7159 84.8185C86.6512 84.7321 86.5595 84.6649 86.4409 84.6171C86.3239 84.5693 86.1853 84.5454 86.0251 84.5454H85.5654V83.7308H86.0251C86.1653 83.7308 86.2885 83.7076 86.3947 83.6613C86.5025 83.615 86.5865 83.5502 86.6465 83.4669C86.7066 83.3821 86.7359 83.2849 86.7343 83.1753C86.7359 83.0689 86.7105 82.9755 86.6581 82.8953C86.6073 82.8135 86.5357 82.7503 86.4432 82.7055C86.3524 82.6608 86.2469 82.6384 86.1268 82.6384C86.0005 82.6384 85.8857 82.6608 85.7825 82.7055C85.6809 82.7503 85.6 82.8135 85.54 82.8953C85.4799 82.9771 85.4483 83.072 85.4452 83.18H84.3525C84.3556 82.8976 84.4342 82.6492 84.5882 82.4348C84.7422 82.2188 84.9516 82.0498 85.2165 81.9279C85.483 81.8061 85.7864 81.7451 86.1268 81.7451C86.4656 81.7451 86.7636 81.8045 87.0208 81.9233C87.278 82.0421 87.4782 82.2041 87.6214 82.4093C87.7647 82.613 87.8363 82.8436 87.8363 83.1013C87.8378 83.3682 87.7508 83.588 87.5752 83.7608C87.4012 83.9336 87.1771 84.0401 86.903 84.0802V84.1172C87.2695 84.1604 87.546 84.2792 87.7323 84.4736C87.9202 84.668 88.0134 84.911 88.0119 85.2026C88.0119 85.4772 87.931 85.721 87.7693 85.9339C87.6091 86.1453 87.3858 86.3119 87.0993 86.4338C86.8144 86.5541 86.4871 86.6143 86.1175 86.6143Z'
        fill='#424244'
      />
      <path
        d='M79.8438 85.7628V84.8695L81.782 81.8101H82.5744V83.0227H82.1147L80.9804 84.8232V84.8603H83.7872V85.7628H79.8438ZM82.1285 86.5497V85.4897L82.1516 85.0986V81.8101H83.2212V86.5497H82.1285Z'
        fill='#424244'
      />
      <path
        d='M77.3672 81.7451C77.6259 81.7451 77.8731 81.7876 78.1087 81.8724C78.3444 81.9557 78.5546 82.0892 78.7394 82.2728C78.9242 82.4548 79.0698 82.6948 79.176 82.9925C79.2823 83.2903 79.3354 83.6536 79.3354 84.0825C79.337 84.4775 79.2908 84.8316 79.1968 85.1448C79.1029 85.4564 78.9681 85.7218 78.7925 85.9409C78.6185 86.16 78.4091 86.3274 78.1642 86.4431C77.9193 86.5573 77.6459 86.6143 77.3441 86.6143C77.0114 86.6143 76.718 86.5503 76.4639 86.4223C76.2113 86.2927 76.0088 86.1183 75.8563 85.8992C75.7054 85.6802 75.6153 85.4348 75.586 85.1633H76.7111C76.7465 85.3207 76.8212 85.4395 76.9352 85.5197C77.0491 85.5984 77.1854 85.6377 77.3441 85.6377C77.6336 85.6377 77.85 85.512 77.9932 85.2605C78.1365 85.009 78.2081 84.6673 78.2081 84.2353H78.1803C78.1141 84.3772 78.0202 84.4991 77.8985 84.6009C77.7784 84.7028 77.639 84.7807 77.4804 84.8347C77.3217 84.8887 77.1546 84.9157 76.979 84.9157C76.6957 84.9157 76.4454 84.8509 76.2282 84.7213C76.0126 84.5917 75.8432 84.4135 75.72 84.1867C75.5983 83.9599 75.5367 83.7007 75.5352 83.4091C75.5336 83.082 75.6099 82.7935 75.7639 82.5436C75.9179 82.2921 76.1327 82.0961 76.4084 81.9557C76.6841 81.8138 77.0037 81.7436 77.3672 81.7451ZM77.3741 82.6246C77.2324 82.6246 77.1061 82.6577 76.9952 82.7241C76.8859 82.7904 76.7996 82.8799 76.7365 82.9925C76.6733 83.1052 76.6425 83.2317 76.6441 83.3721C76.6456 83.5125 76.6772 83.639 76.7388 83.7516C76.8019 83.8642 76.8874 83.9537 76.9952 84.0201C77.1046 84.0864 77.2293 84.1196 77.3695 84.1196C77.4742 84.1196 77.5712 84.1003 77.6606 84.0617C77.7499 84.0231 77.8277 83.9699 77.8939 83.902C77.9601 83.8326 78.0117 83.7524 78.0487 83.6613C78.0872 83.5703 78.1056 83.4731 78.1041 83.3698C78.1026 83.2324 78.0702 83.1075 78.0071 82.9948C77.9439 82.8822 77.8569 82.7927 77.746 82.7264C77.6367 82.6585 77.5127 82.6246 77.3741 82.6246Z'
        fill='#424244'
      />
      <path
        d='M74.9001 85.9019L74.8793 86.1587C74.8609 86.3716 74.8231 86.5792 74.7661 86.7813C74.7107 86.9849 74.6514 87.1678 74.5883 87.3298C74.5251 87.4918 74.4751 87.6175 74.4381 87.707H73.7266C73.7512 87.6175 73.7843 87.4918 73.8259 87.3298C73.869 87.1678 73.9091 86.9849 73.946 86.7813C73.983 86.5776 74.0038 86.3709 74.0084 86.1611L74.013 85.9019H74.9001Z'
        fill='#424244'
      />
      <path
        d='M69.6513 86.5495V85.7256L71.377 84.2121C71.5064 84.0949 71.6165 83.9876 71.7074 83.8904C71.7983 83.7917 71.8676 83.693 71.9153 83.5942C71.9631 83.4939 71.9869 83.3852 71.9869 83.2679C71.9869 83.1368 71.9584 83.0249 71.9015 82.9323C71.8445 82.8382 71.7659 82.7657 71.6658 82.7148C71.5657 82.6639 71.451 82.6384 71.3216 82.6384C71.1907 82.6384 71.076 82.6654 70.9774 82.7194C70.8788 82.7719 70.8018 82.8482 70.7464 82.9485C70.6925 83.0488 70.6655 83.1707 70.6655 83.3142H69.582C69.582 82.9917 69.6544 82.7132 69.7992 82.4787C69.944 82.2442 70.1473 82.0637 70.4091 81.9372C70.6724 81.8091 70.9782 81.7451 71.3262 81.7451C71.6851 81.7451 71.9969 81.8053 72.2618 81.9256C72.5268 82.046 72.7316 82.2141 72.8764 82.4301C73.0227 82.6446 73.0958 82.8938 73.0958 83.1776C73.0958 83.3582 73.0596 83.5371 72.9872 83.7146C72.9149 83.892 72.7847 84.0879 72.5968 84.3024C72.4105 84.5168 72.1456 84.7737 71.8021 85.073L71.2361 85.596V85.6261H73.1536V86.5495H69.6513Z'
        fill='#424244'
      />
      <path
        d='M68.6684 81.8101V86.5497H67.5272V82.8792H67.4995L66.4414 83.5272V82.5367L67.6081 81.8101H68.6684Z'
        fill='#424244'
      />
      <path
        d='M84.1235 38.5889H69.0072C67.4694 38.5889 66.2227 39.8329 66.2227 41.3675C66.2227 42.9021 67.4694 44.1461 69.0072 44.1461H84.1235C85.6614 44.1461 86.9081 42.9021 86.9081 41.3675C86.9081 39.8329 85.6614 38.5889 84.1235 38.5889Z'
        fill='#424244'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M84.1263 42.9553C85.0051 42.9553 85.7175 42.2445 85.7175 41.3676C85.7175 40.4907 85.0051 39.7798 84.1263 39.7798C83.2476 39.7798 82.5352 40.4907 82.5352 41.3676C82.5352 42.2445 83.2476 42.9553 84.1263 42.9553Z'
        fill='white'
      />
      <path
        d='M41.9584 62.8027C41.9584 62.3642 41.6022 62.0088 41.1628 62.0088C40.7234 62.0088 40.3672 62.3642 40.3672 62.8027V68.3599C40.3672 68.7984 40.7234 69.1538 41.1628 69.1538C41.6022 69.1538 41.9584 68.7984 41.9584 68.3599V62.8027Z'
        fill='#424244'
      />
      <path
        d='M41.9584 74.7109C41.9584 74.2724 41.6022 73.917 41.1628 73.917C40.7234 73.917 40.3672 74.2724 40.3672 74.7109V84.2375C40.3672 84.676 40.7234 85.0314 41.1628 85.0314C41.6022 85.0314 41.9584 84.676 41.9584 84.2375V74.7109Z'
        fill='#424244'
      />
      <path
        d='M41.9584 89.0009C41.9584 88.5625 41.6022 88.207 41.1628 88.207C40.7234 88.207 40.3672 88.5625 40.3672 89.0009V98.5276C40.3672 98.966 40.7234 99.3215 41.1628 99.3215C41.6022 99.3215 41.9584 98.966 41.9584 98.5276V89.0009Z'
        fill='#424244'
      />
      <path
        d='M112.767 81.8559C112.767 81.4174 112.411 81.062 111.971 81.062C111.532 81.062 111.176 81.4174 111.176 81.8559V99.3214C111.176 99.7599 111.532 100.115 111.971 100.115C112.411 100.115 112.767 99.7599 112.767 99.3214V81.8559Z'
        fill='#424244'
      />
      <path
        d='M97.4896 98.7852H50.8599C50.2473 98.7852 49.7461 99.2853 49.7461 99.8966C49.7461 100.508 50.2473 101.008 50.8599 101.008H97.4896C98.1022 101.008 98.6034 100.508 98.6034 99.8966C98.6034 99.2853 98.1022 98.7852 97.4896 98.7852Z'
        fill='#C6C6C7'
      />
      <path
        d='M87.1469 103.477H50.8599C50.2473 103.477 49.7461 103.977 49.7461 104.588C49.7461 105.2 50.2473 105.7 50.8599 105.7H87.1469C87.7595 105.7 88.2607 105.2 88.2607 104.588C88.2607 103.977 87.7595 103.477 87.1469 103.477Z'
        fill='#C6C6C7'
      />
      <path
        d='M68.172 108.249H50.8599C50.2473 108.249 49.7461 108.749 49.7461 109.36C49.7461 109.971 50.2473 110.471 50.8599 110.471H68.172C68.7846 110.471 69.2858 109.971 69.2858 109.36C69.2858 108.749 68.7846 108.249 68.172 108.249Z'
        fill='#C6C6C7'
      />
      <path
        d='M54.0945 127.521H70.8019C72.9806 127.521 74.7451 129.284 74.7451 131.455C74.7451 133.627 72.9806 135.389 70.8019 135.389H54.0945C51.9159 135.389 50.1514 133.627 50.1514 131.455C50.1514 129.284 51.9159 127.521 54.0945 127.521Z'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M99.0441 127.089H82.3367C79.92 127.089 77.9609 129.044 77.9609 131.455C77.9609 133.867 79.92 135.822 82.3367 135.822H99.0441C101.461 135.822 103.42 133.867 103.42 131.455C103.42 129.044 101.461 127.089 99.0441 127.089Z'
        fill='#424244'
      />
      <path
        d='M56.7586 91.25C60.4935 91.25 63.5212 88.2288 63.5212 84.502C63.5212 80.7751 60.4935 77.7539 56.7586 77.7539C53.0238 77.7539 49.9961 80.7751 49.9961 84.502C49.9961 88.2288 53.0238 91.25 56.7586 91.25Z'
        fill='#424244'
      />
      <path
        d='M134.113 109.023C134.113 115.135 139.057 120.09 145.156 120.09C151.255 120.09 156.199 115.135 156.199 109.023C156.199 102.911 151.255 97.9565 145.156 97.9565C139.057 97.9565 134.113 102.911 134.113 109.023Z'
        fill='white'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M136.623 109.023C136.623 113.747 140.444 117.576 145.157 117.576C149.87 117.576 153.691 113.747 153.691 109.023C153.691 104.3 149.87 100.471 145.157 100.471C140.444 100.471 136.623 104.3 136.623 109.023Z'
        fill='#FAEAC0'
      />
      <path
        d='M139.304 77.2896C139.304 82.1229 143.214 86.0411 148.037 86.0411C152.86 86.0411 156.77 82.1229 156.77 77.2896C156.77 72.4563 152.86 68.5381 148.037 68.5381C143.214 68.5381 139.304 72.4563 139.304 77.2896Z'
        fill='white'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M141.289 77.2899C141.289 81.0247 144.31 84.0524 148.037 84.0524C151.764 84.0524 154.785 81.0247 154.785 77.2899C154.785 73.555 151.764 70.5273 148.037 70.5273C144.31 70.5273 141.289 73.555 141.289 77.2899Z'
        fill='#FAEAC0'
      />
      <path
        d='M177.374 103.247C177.374 108.08 181.284 111.999 186.107 111.999C190.93 111.999 194.84 108.08 194.84 103.247C194.84 98.4138 190.93 94.4956 186.107 94.4956C181.284 94.4956 177.374 98.4138 177.374 103.247Z'
        fill='white'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M179.359 103.247C179.359 106.982 182.381 110.01 186.107 110.01C189.834 110.01 192.855 106.982 192.855 103.247C192.855 99.5126 189.834 96.4849 186.107 96.4849C182.381 96.4849 179.359 99.5126 179.359 103.247Z'
        fill='#FAEAC0'
      />
      <path
        d='M164.395 90.4775C164.395 93.9927 167.239 96.8423 170.747 96.8423C174.254 96.8423 177.098 93.9927 177.098 90.4775C177.098 86.9624 174.254 84.1128 170.747 84.1128C167.239 84.1128 164.395 86.9624 164.395 90.4775Z'
        fill='white'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M165.844 90.4778C165.844 93.1932 168.04 95.3946 170.75 95.3946C173.46 95.3946 175.656 93.1932 175.656 90.4778C175.656 87.7623 173.46 85.561 170.75 85.561C168.04 85.561 165.844 87.7623 165.844 90.4778Z'
        fill='#FAEAC0'
      />
      <path
        d='M201.599 98.2646C201.599 101.78 204.442 104.629 207.95 104.629C211.457 104.629 214.301 101.78 214.301 98.2646C214.301 94.7495 211.457 91.8999 207.95 91.8999C204.442 91.8999 201.599 94.7495 201.599 98.2646Z'
        fill='white'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M203.047 98.2649C203.047 100.98 205.244 103.182 207.953 103.182C210.663 103.182 212.859 100.98 212.859 98.2649C212.859 95.5495 210.663 93.3481 207.953 93.3481C205.244 93.3481 203.047 95.5495 203.047 98.2649Z'
        fill='#FAEAC0'
      />
      <path
        d='M224.163 71.0919C223.587 70.733 222.84 71.1474 222.84 71.8263V73.0196C222.84 73.4975 222.453 73.8848 221.975 73.8848H202.467C201.989 73.8848 201.602 74.2722 201.602 74.7501V81.361C201.602 81.8388 201.989 82.2262 202.467 82.2262H221.975C222.453 82.2262 222.84 82.6136 222.84 83.0915V84.2847C222.84 84.9637 223.587 85.3781 224.163 85.0192L234.167 78.79C234.711 78.4514 234.711 77.6597 234.167 77.321L224.163 71.0919Z'
        fill='#BAEBC8'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M163.489 163.952C162.912 164.285 162.191 163.869 162.191 163.203V160.315C162.191 159.837 161.803 159.45 161.325 159.45H129.787C129.309 159.45 128.922 159.062 128.922 158.584V148.201C128.922 147.723 129.309 147.336 129.787 147.336H161.325C161.803 147.336 162.191 146.949 162.191 146.471V143.583C162.191 142.917 162.912 142.5 163.489 142.833L180.482 152.643C181.059 152.976 181.059 153.809 180.482 154.142L163.489 163.952Z'
        fill='#BAEBC8'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M199.034 130.034C198.457 130.385 197.718 129.97 197.718 129.295V128.888C197.718 128.41 197.331 128.023 196.853 128.023H181.701C181.223 128.023 180.836 127.635 180.836 127.158V122.4C180.836 121.922 181.223 121.535 181.701 121.535H196.853C197.331 121.535 197.718 121.148 197.718 120.67V120.263C197.718 119.587 198.457 119.172 199.034 119.524L206.446 124.04C206.999 124.377 206.999 125.181 206.446 125.518L199.034 130.034Z'
        fill='#BAEBC8'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <path
        d='M175.67 65.1401C175.094 65.4914 174.355 65.0764 174.355 64.4012V63.994C174.355 63.5161 173.967 63.1287 173.49 63.1287H158.338C157.86 63.1287 157.473 62.7413 157.473 62.2635V57.5063C157.473 57.0284 157.86 56.641 158.338 56.641H173.49C173.967 56.641 174.355 56.2536 174.355 55.7758V55.3685C174.355 54.6933 175.094 54.2783 175.67 54.6296L183.083 59.146C183.636 59.4832 183.636 60.2866 183.083 60.6238L175.67 65.1401Z'
        fill='#BAEBC8'
        stroke='#424244'
        stroke-width='0.86525'
        stroke-miterlimit='10'
      />
      <defs>
        <clipPath id='clip0_8430_97623'>
          <rect width='115' height='86' fill='white' transform='translate(50 67)' />
        </clipPath>
      </defs>
    </svg>
  )
}
