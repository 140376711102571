import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ExternalLink } from '../../global/ExternalLink'
import {
  overrideForZHToCNLanguage,
  useSessionLanguage,
} from '../../global/context/SessionSettingsContext'
import { useHomePageUrl } from '../../hooks/useHomePageUrl'
import { TickmillProductType } from '../../model/TickmillProductType'
import { useSupportContacts } from '../../utils/SupportContactsContext'

interface FaqQuestionAndAnswer {
  question: string
  category: string
  slug: string
  expand: boolean
  type: TickmillProductType
  answer: React.ReactNode
}

export const useFaqQuestionsAndAnswers = (): {
  [key: string]: FaqQuestionAndAnswer[]
} => {
  const { t } = useTranslation()

  const locale = useSessionLanguage()

  const homePageUrl = useHomePageUrl()

  const { supportEmail, supportPhoneNumber } = useSupportContacts()

  const localePath = locale === 'en' ? '' : `/${overrideForZHToCNLanguage(locale)}`

  return {
    [TickmillProductType.CFD]: [
      {
        question: t('Support.FAQ Question.Does Tickmill offer Cash or Future CFDs'),
        category: '/contact-us/faq',
        slug: '/contact-us/faq/does-tickmill-offer-cash-or-future-cfds-fca',
        expand: false,
        type: TickmillProductType.CFD,
        answer: <Trans i18nKey='Support.FAQ Answer.Does Tickmill offer Cash or Future CFDs' />,
      },
      {
        question: t('Support.FAQ Question.How do I deposit funds to my account'),
        category: '/contact-us/faq',
        slug: '/contact-us/faq/how-do-i-deposit-funds-to-my-account-fca',
        expand: false,
        type: TickmillProductType.CFD,
        answer: <Trans i18nKey='Support.FAQ Answer.How do I deposit funds to my account' />,
      },
      {
        question: t('Support.FAQ Question.How do I open a MetaTrader account'),
        category: '/contact-us/faq',
        slug: '/contact-us/faq/how-do-i-open-a-metatrader-account-fca',
        expand: false,
        type: TickmillProductType.CFD,
        answer: <Trans i18nKey='Support.FAQ Answer.How do I open a MetaTrader account' />,
      },
    ],
    [TickmillProductType.ETD]: [
      {
        question: t(
          'Support.FAQ Question.Can I open a corporate and a private account at the same time'
        ),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/can-i-open-a-corporate-and-a-private-account-at-the-same-time',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Can I open a corporate and a private account at the same time' />
        ),
      },
      {
        question: t('Support.FAQ Question.Can I open a joint account'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/can-i-open-a-joint-account',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.FAQ Answer.Can I open a joint account'
            components={{
              emailLink: <a title={t('Support.FAQ Keys.Email')} href={`mailto:${supportEmail}`} />,
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.Can I open an account with Tickmill UK as a US resident'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/can-i-open-an-account-with-tickmill-uk-as-a-us-resident',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Can I open an account with Tickmill UK as a US resident' />
        ),
      },
      {
        question: t('Support.FAQ Question.Close a trading account'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/close-a-trading-account',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.Close a trading account'
            values={{ email: supportEmail }}
            components={{
              1: <a title={supportEmail} href={`mailto:${supportEmail}`} />,
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.Difference Between live And demo Accounts'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/difference-between-live-and-demo-accounts',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.Difference Between live And demo Accounts' />,
      },
      {
        question: t(
          'Support.FAQ Question.Do I need to provide translated documents for account opening'
        ),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/do-i-need-to-provide-translated-documents-for-account-opening',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Do I need to provide translated documents for account opening' />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.Do I retain access to my Demo account after opening a live account'
        ),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/do-i-retain-access-to-my-demo-account-after-opening-a-live-account',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.Do I retain access to my Demo account after opening a live account'
            values={{ email: supportEmail, phone: supportPhoneNumber }}
            components={{
              1: <a title={t('Support.FAQ Keys.Email')} href={`mailto:${supportEmail}`} />,
              2: (
                <a title={supportPhoneNumber} href={`tel:${supportPhoneNumber}`}>
                  {supportPhoneNumber}
                </a>
              ),
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.Do you have a referral program'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/do-you-have-a-referral-program',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.Do you have a referral program' />,
      },
      {
        question: t('Support.FAQ Question.How can I open a Futures Account'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/how-can-i-open-a-futures-account',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.How can I open a Futures Account'>
            Our trading account provide access to exchange traded Futures on the award winning CQG
            platform. Please click{' '}
            <a
              title={t('Support.FAQ Keys.Here')}
              target='_blank'
              rel='noopener noreferrer'
              href={`https://my.tickmill.com/${locale}/sign-up`}
            >
              here
            </a>
            to open an account.
          </Trans>
        ),
      },
      {
        question: t('Support.FAQ Question.How do I change my Client Area Password'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/how-do-i-change-my-client-portal-password',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.How do I change my Client Area Password'>
            Please navigate to the
            <a href='/profile/security-settings'>Security Settings</a>
            page in your Client Area
          </Trans>
        ),
      },
      {
        question: t('Support.FAQ Question.How Do I Request Support'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/how-do-i-request-support',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.How Do I Request Support'
            values={{ email: supportEmail, phone: supportPhoneNumber }}
            components={{
              1: <a title={supportEmail} href={`mailto:${supportEmail}`} />,
              2: <a title={supportPhoneNumber} href={`tel:${supportPhoneNumber}`} />,
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.How do I update my contact details'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/how-do-i-update-my-contact-details',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.How do I update my contact details'
            values={{ email: supportEmail, phone: supportPhoneNumber }}
            components={{
              1: <a title={`${supportEmail}`} href={`mailto:${supportEmail}`} />,
              2: <a title={`${supportPhoneNumber}`} href={`tel:${supportPhoneNumber}`} />,
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.How long does it take to open an account'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/how-long-does-it-take-to-open-an-account',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.How long does it take to open an account' />,
      },
      {
        question: t('Support.FAQ Question.How many Futures accounts can I have'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/how-many-futures-accounts-can-i-have',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.How many Futures accounts can I have' />,
      },
      {
        question: t(
          'Support.FAQ Question.I am trying to open an account. Why is it still not approved'
        ),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/i-am-trying-to-open-an-account-why-is-it-still-not-approved',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.I am trying to open an account. Why is it still not approved' />
        ),
      },
      {
        question: t('Support.FAQ Question.I cannot remember my Tickmill ID'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/i-cannot-remember-my-tickmill-id',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.I cannot remember my Tickmill ID'
            values={{ email: supportEmail }}
            components={{
              1: (
                <a
                  title={supportEmail || 'support@tickmill.co.uk'}
                  href={`mailto:${supportEmail}`}
                />
              ),
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.What is the minimum amount to open an account'),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/what-is-the-minimum-amount-to-open-an-account',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.What is the minimum amount to open an account' />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.What is the difference between a Retail and Professional client'
        ),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/whats-the-difference-between-a-retail-and-professional-client',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.What is the difference between a Retail and Professional client' />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.Which countries can be accepted for Futures trading at Tickmill'
        ),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/which-countries-can-be-accepted-for-futures-trading-at-tickmill',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.Which countries can be accepted for Futures trading at Tickmill'
            values={{ supportEmail }}
            components={{
              1: <a title={`${supportEmail}`} href={`mailto:${supportEmail}`} />,
            }}
          />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.Who can I talk to if I have specific questions around your offering'
        ),
        category: '/contact-us/faq/account-opening',
        slug: '/contact-us/faq/who-can-i-talk-to-if-i-have-specific-questions-around-your-offering',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.Who can I talk to if I have specific questions around your offering'
            values={{ email: supportEmail, phone: supportPhoneNumber }}
            components={{
              1: <a title={`${supportEmail}`} href={`mailto:${supportEmail}`} />,
              2: <a title={`${supportPhoneNumber}`} href={`tel:${supportPhoneNumber}`} />,
            }}
          />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.Can I fund my account by transfering funds from another broker'
        ),
        category: '/contact-us/faq/deposit-and-withdrawal',
        slug: '/contact-us/faq/can-i-fund-my-account-by-transfering-funds-from-another-broker',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Can I fund my account by transfering funds from another broker' />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.Can I send money from an account which is not in my name'
        ),
        category: '/contact-us/faq/deposit-and-withdrawal',
        slug: '/contact-us/faq/can-i-send-money-from-an-account-which-is-not-in-my-name',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Can I send money from an account which is not in my name' />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.Can I withdraw my funds into a different bank account from the one used to initially deposit'
        ),
        category: '/contact-us/faq/deposit-and-withdrawal',
        slug: '/contact-us/faq/can-i-withdraw-funds-into-a-different-bank-account-from-the-one-i-used-to-initially-deposit',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Can I withdraw my funds into a different bank account from the one used to initially deposit' />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.Can I withdraw my funds in a currency different to that of my deposit currency'
        ),
        category: '/contact-us/faq/deposit-and-withdrawal',
        slug: '/contact-us/faq/can-i-withdraw-my-funds-in-a-currency-different-to-that-of-my-deposit-currency',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Can I withdraw my funds in a currency different to that of my deposit currency' />
        ),
      },
      {
        question: t('Support.FAQ Question.How can I deposit funds into my wallet'),
        category: '/contact-us/faq/deposit-and-withdrawal',
        slug: '/contact-us/faq/how-can-i-deposit-funds-into-my-wallet',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.How can I deposit funds into my wallet' />,
      },
      {
        question: t('Support.FAQ Question.How can I withdraw funds from my wallet'),
        category: '/contact-us/faq/deposit-and-withdrawal',
        slug: '/contact-us/faq/how-can-i-withdraw-funds-from-my-wallet',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.How can I withdraw funds from my wallet'>
            For withdrawals please navigate to your
            <a href='/dashboard/traders-room/balances'>Trader's Room</a>.
          </Trans>
        ),
      },
      {
        question: t(
          'Support.FAQ Question.How long does it take for funds to be reflected in my wallet following a deposit transaction'
        ),
        category: '/contact-us/faq/deposit-and-withdrawal',
        slug: '/contact-us/faq/how-long-does-it-take-for-funds-to-to-be-reflected-in-my-wallet-following-a-deposit-transaction',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.How long does it take for funds to be reflected in my wallet following a deposit transaction' />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.What determines how much can be withdrawn from a my trading account'
        ),
        category: '/contact-us/faq/deposit-and-withdrawal',
        slug: '/contact-us/faq/what-determines-how-much-can-be-withdrawn-from-a-my-trading-account',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.What determines how much can be withdrawn from a my trading account' />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.When will my deposited funds be credited to my trading account'
        ),
        category: '/contact-us/faq/deposit-and-withdrawal',
        slug: '/contact-us/faq/when-will-my-deposited-funds-be-credited-to-my-trading-account',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.When will my deposited funds be credited to my trading account' />
        ),
      },
      {
        question: t('Support.FAQ Question.Do you have any charges on deposits and withdrawals'),
        category: '/contact-us/faq/deposit-and-withdrawal',
        slug: '/contact-us/faq/do-you-have-any-charges-on-deposits-and-withdrawals',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Do you have any charges on deposits and withdrawals' />
        ),
      },
      {
        question: t('Support.FAQ Question.Are my funds protected'),
        category: '/contact-us/faq/legal-and-compliance',
        slug: '/contact-us/faq/are-my-funds-protected',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.Are my funds protected' />,
      },
      {
        question: t(
          'Support.FAQ Question.Do you have a dedicated support team to help me if I need it'
        ),
        category: '/contact-us/faq/legal-and-compliance',
        slug: '/contact-us/faq/do-you-have-a-dedicated-support-team-to-help-me-if-i-need-it',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.Do you have a dedicated support team to help me if I need it'
            values={{ email: supportEmail, phone: supportPhoneNumber }}
            components={{
              1: <a title={`${supportEmail}`} href={`mailto:${supportEmail}`} />,
              2: <a title={`${supportPhoneNumber}`} href={`tel:${supportPhoneNumber}`} />,
            }}
          />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.What does Tickmill report to my local tax authorities about my account'
        ),
        category: '/contact-us/faq/legal-and-compliance',
        slug: '/contact-us/faq/what-does-tickmill-report-to-my-local-tax-authorities-about-my-account',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.What does Tickmill report to my local tax authorities about my account' />
        ),
      },
      {
        question: t('Support.FAQ Question.Where can I find my financial reports'),
        category: '/contact-us/faq/legal-and-compliance',
        slug: '/contact-us/faq/where-can-i-find-my-financial-reports',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.Where can I find my financial reports' />,
      },
      {
        question: t('Support.FAQ Question.Are Futures considered margin products'),
        category: '/contact-us/faq/product',
        slug: '/contact-us/faq/are-futures-considered-margin-products',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.Are Futures considered margin products' />,
      },
      {
        question: t('Support.FAQ Question.How can I download the platform'),
        category: '/contact-us/faq/product',
        slug: '/contact-us/faq/how-can-i-download-the-platform',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.How can I download the platform'>
            As soon as your account has been approved and deposited your funds you will have the
            opportunity to review the trading platforms and the associated costs. Once you have
            selected your choice of platform we will send all relevant information to start trading.
            If you would like to review the CQG trading platform choices please click{' '}
            <a
              title={t('Support.FAQ Keys.Here')}
              rel='noopener noreferrer'
              href='https://www.cqg.com/products/product-comparison'
              target='_blank'
            >
              here
            </a>
            . Please note that Tickmill clients do not pay the $0.25 execution cost
          </Trans>
        ),
      },
      {
        question: t('Support.FAQ Question.How do I subscribe to live prices'),
        category: '/contact-us/faq/product',
        slug: '/contact-us/faq/how-do-i-subscribe-to-live-prices',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.How do I subscribe to live prices new'>
            You can select your trading platform and live market data from
            <a href='/dashboard/subscriptions/trading-platforms'>Trading Platforms</a> and
            <a href='/dashboard/subscriptions/market-data'>Market Data</a> pages.
          </Trans>
        ),
      },
      {
        question: t('Support.FAQ Question.What are the CQG platform fees'),
        category: '/contact-us/faq/product',
        slug: '/contact-us/faq/what-are-the-cqg-platform-fees',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.What are the CQG platform fees'>
            The CQG platform cost and feaures can be found by clicking
            <a
              title={t('Support.FAQ Keys.Here')}
              rel='noopener noreferrer'
              href='https://www.cqg.com/products/product-comparison'
              target='_blank'
            >
              here. Please note that clients of Tickmill do not pay the $0.25 per lot execution
              fees.
            </a>
          </Trans>
        ),
      },
      {
        question: t('Support.FAQ Question.What are the main differences between CFDs and Futures'),
        category: '/contact-us/faq/product',
        slug: '/contact-us/faq/what-are-the-main-differences-between-cfds-and-futures',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <>
            <p>
              <Trans i18nKey='Support.FAQ Answer.What are the main differences between CFDs and Futures' />
            </p>
            <p>
              <Trans i18nKey='Support.FAQ Answer.What are the main differences between CFDs and Futures (CFD)' />
            </p>
            <p>
              <Trans i18nKey='Support.FAQ Answer.What are the main differences between CFDs and Futures (Financial)' />
            </p>
          </>
        ),
      },
      {
        question: t('Support.FAQ Question.What does ETD mean'),
        category: '/contact-us/faq/product',
        slug: '/contact-us/faq/what-does-etd-mean',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <>
            <p>
              <Trans i18nKey='Support.FAQ Answer.What does ETD mean' />
            </p>
            <p>
              <Trans i18nKey='Support.FAQ Answer.What does ETD mean (ETD contracts)' />
            </p>
            <p>
              <Trans i18nKey='Support.FAQ Answer.What does ETD mean (ETD contracts payments)' />
            </p>
          </>
        ),
      },
      {
        question: t('Support.FAQ Question.What exchanges and products will I have access to'),
        category: '/contact-us/faq/product',
        slug: '/contact-us/faq/what-exchanges-and-products-will-have-access-to',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <p>
            <Trans i18nKey='Support.FAQ Answer.What exchanges and products will I have access to'>
              When you trade Futures with Tickmill you have access to 5 Global Exchanges providing
              access to products from asset classes including Agriculture, Energy, FX, Indices,
              Metals and Interest Rates. With exceptional depth of liquidity available you can
              access further details for each instrument by clicking{' '}
              <ExternalLink url={`${homePageUrl}/conditions/futures-commissions`}>
                here
              </ExternalLink>
              .
            </Trans>
          </p>
        ),
      },
      {
        question: t('Support.FAQ Question.What is a Future'),
        category: '/contact-us/faq/product',
        slug: '/contact-us/faq/what-is-a-future',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.What is a Future' />,
      },
      {
        question: t('Support.FAQ Question.Can I upload my own custom charts to your platforms'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/can-i-upload-my-own-custom-charts-to-your-platforms',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.Can I upload my own custom charts to your platforms'
            values={{ email: supportEmail }}
            components={{
              1: <a title={`${supportEmail}`} href={`mailto:${supportEmail}`} />,
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.Does Tickmill support Physical Delivery'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/does-tickmill-support-physical-delivery',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.Does Tickmill support Physical Delivery' />,
      },
      {
        question: t('Support.FAQ Question.How can I change a language in my platform'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/how-can-i-change-a-language-in-my-platform',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.How can I change a language in my platform' />,
      },
      {
        question: t('Support.FAQ Question.How can I request CQG Technical assistance'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/how-can-i-request-cqg-technical-assistance',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.How can I request CQG Technical assistance'
            values={{ supportEmail, supportPhoneNumber }}
            components={{
              1: <a title={supportEmail} href={`mailto:${supportEmail}`} />,
              4: <a title={supportPhoneNumber} href={`tel:${supportPhoneNumber}`} />,
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.How Can I See If An Exchange Is Open'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/how-can-i-see-if-an-exchange-is-open',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.How Can I See If An Exchange Is Open'>
            Information about exchange opening times can be found
            <a
              title={t('Support.FAQ Keys.Here')}
              rel='noopener noreferrer'
              href='https://www.cmegroup.com/tools-information/holiday-calendar.html'
              target='_blank'
            >
              here
            </a>
            for CME group and
            <a
              title={t('Support.FAQ Keys.Here')}
              rel='noopener noreferrer'
              href='https://www.eurexchange.com/exchange-en/trade/trading-calendar'
              target='_blank'
            >
              here
            </a>
            for Eurex.
          </Trans>
        ),
      },
      {
        question: t('Support.FAQ Question.How Do I Close A Futures Position'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/how-do-i-close-a-futures-position',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <>
            <p>
              <Trans i18nKey='Support.FAQ Answer.How Do I Close A Futures Position' />
            </p>
            <ol>
              <li>
                <Trans i18nKey='Support.FAQ Answer.How Do I Close A Futures Position (Offsetting)' />
              </li>{' '}
              <li>
                <Trans i18nKey='Support.FAQ Answer.How Do I Close A Futures Position (Roll the position)' />
              </li>
            </ol>
          </>
        ),
      },
      {
        question: t('Support.FAQ Question.How do I unsubscribe from an exchange'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/how-do-i-unsubscribe-from-an-exchange',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.How do I unsubscribe from an exchange new'>
            This can be done within the
            <a href='/dashboard/subscriptions/trading-platforms'>Trading Platforms</a> and
            <a href='/dashboard/subscriptions/market-data'>Market Data</a> pages.
          </Trans>
        ),
      },
      {
        question: t('Support.FAQ Question.How to enable Trading Products on the Platform'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/how-to-enable-trading-products-on-the-platform',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.How to enable Trading Products on the Platform' />
        ),
      },
      {
        question: t('Support.FAQ Question.How to partially close a position'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/how-to-partially-close-a-position',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.How to partially close a position' />,
      },
      {
        question: t('Support.FAQ Question.How to roll over Futures positions with spreads'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/how-to-roll-over-futures-positions-with-spreads',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.How to roll over Futures positions with spreads' />
        ),
      },
      {
        question: t('Support.FAQ Question.What are the commission rates'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/what-are-the-commission-rates',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.What are the commission rates'
            values={{ supportEmail }}
            components={{
              1: (
                <a
                  title={t('Support.FAQ Keys.Here')}
                  rel='noopener noreferrer'
                  href={`${homePageUrl}/futures-and-options-pricing`}
                  target='_blank'
                />
              ),
              4: <a title={`${supportEmail}`} href={`mailto:${supportEmail}`} />,
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.What does Trading Volume actually mean'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/what-does-trading-volume-actually-mean',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.What does Trading Volume actually mean' />,
      },
      {
        question: t('Support.FAQ Question.What Is open Interest'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/what-is-open-interest',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.What Is open Interest' />,
      },
      {
        question: t('Support.FAQ Question.What Is Clearing House'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/what-is-a-clearing-house',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.What Is Clearing House' />,
      },
      {
        question: t('Support.FAQ Question.What is a Futures exchange'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/what-is-a-futures-exchange',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.What is a Futures exchange' />,
      },
      {
        question: t('Support.FAQ Question.What is Level 1 and Level 2 Access'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/what-is-level-1-and-level-2-access',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.What is Level 1 and Level 2 Access' />,
      },

      {
        question: t('Support.FAQ Question.What is the Lot Size'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/what-is-the-lot-size',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.What is the Lot Size' />,
      },
      {
        question: t(
          'Support.FAQ Question.What is the difference between hedgers and speculators in Futures trading'
        ),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/what-is-the-difference-between-hedgers-and-speculators-in-futures-trading',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.What is the difference between hedgers and speculators in Futures trading' />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.What is the difference between initial and maintenance margin'
        ),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/what-is-the-difference-between-initial-and-maintenance-margin',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.What is the difference between initial and maintenance margin' />
        ),
      },
      {
        question: t(
          'Support.FAQ Question.What market data package do I need to see pricing on CME futures'
        ),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/what-market-data-package-do-i-need-to-see-pricing-on-cme-futures',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.What market data package do I need to see pricing on CME futures'>
            You're able to find the cost of market data by clicking{' '}
            <a
              title={t('Support.FAQ Keys.Here')}
              href='https://www.cqg.com/partners/exchanges/monthly-fees'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
            .
          </Trans>
        ),
      },
      {
        question: t('Support.FAQ Question.When do Futures expire'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/when-do-futures-expire',
        expand: false,
        type: TickmillProductType.ETD,
        answer: <Trans i18nKey='Support.FAQ Answer.When do Futures expire' />,
      },
      {
        question: t(
          'Support.FAQ Question.Where can I find information about margin requirements on each contract'
        ),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/where-can-i-find-information-about-margin-requirements-on-each-contract',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.Where can I find information about margin requirements on each contract'
            values={{ supportEmail, supportPhoneNumber }}
            components={{
              1: <a title={supportEmail} href={`mailto:${supportEmail}`} />,
              5: <a title={supportPhoneNumber} href={`tel:${supportPhoneNumber}`} />,
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.Where can I see the costs of a Future trade'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/where-can-i-see-the-costs-of-a-future-trade',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Where can I see the costs of a Future trade'>
            All commissions are available on the Tickmill website by clicking
            <a
              title={t('Support.FAQ Keys.Here')}
              rel='noopener noreferrer'
              href={`${homePageUrl}/conditions/futures-commissions`}
              target='_blank'
            >
              here
            </a>
          </Trans>
        ),
      },
      {
        question: t(
          'Support.FAQ Question.Where can I see the trading hours for a particular exchange or instrument'
        ),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/where-can-i-see-the-trading-hours-for-a-particular-exchange-or-instrument',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Where can I see the trading hours for a particular exchange or instrument'>
            Trading hours and holidays can be found
            <a
              title={t('Support.FAQ Keys.Here')}
              href='https://www.cmegroup.com/trading-hours.html'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>{' '}
            for the CME Group and{' '}
            <a
              title={t('Support.FAQ Keys.Here')}
              href='https://www.eurex.com/ex-en/trade/trading-hours'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>{' '}
            for Eurex.
          </Trans>
        ),
      },
      {
        question: t('Support.FAQ Question.Which exchange should I subscribe to'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/which-exchange-should-i-subscribe-to',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.Which exchange should I subscribe to'
            values={{ email: supportEmail }}
            components={{
              1: <a title={`${supportEmail}`} href={`mailto:${supportEmail}`} />,
            }}
          />
        ),
      },
      {
        question: t('Support.FAQ Question.Why do the Margin Requirements for Futures change'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/why-do-the-margin-requirements-for-futures-change',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans i18nKey='Support.FAQ Answer.Why do the Margin Requirements for Futures change' />
        ),
      },
      {
        question: t('Support.FAQ Question.Why was my order rejected'),
        category: '/contact-us/faq/trading-conditions-etd',
        slug: '/contact-us/faq/why-was-my-order-rejected',
        expand: false,
        type: TickmillProductType.ETD,
        answer: (
          <Trans
            i18nKey='Support.dev.Why was my order rejected'
            values={{ phone: supportPhoneNumber, email: supportEmail }}
            components={{
              1: <a title={t('Support.FAQ Keys.Email')} href={`mailto:${supportEmail}`} />,
              2: (
                <a title={supportPhoneNumber} href={`tel:${supportPhoneNumber}`}>
                  {supportPhoneNumber}
                </a>
              ),
            }}
          />
        ),
      },
    ],
  }
}
