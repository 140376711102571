import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { AppMarketQRCode } from '../../../global/appMarketLink/desktop/AppMarketQRCode'
import { AppMarketLink } from '../../../global/appMarketLink/mobile/AppMarketLink'
import { useSessionEntity } from '../../../global/context/EntityContext'
import { TextH1, TextStrong } from '../../../ui/Typography/Typography'
import { TickmillCompaniesEnum } from '../../../utils/companyName.utils'
import { useWindowResize } from '../../../utils/domUtils'
import { isSignUpFirstStepsPath } from '../../../utils/path'

import styles from './LeftSection.module.scss'

interface LeftSectionProps {
  title: string
  description: () => ReactNode
  hasQRCodeSection?: boolean
  className?: string

  hideTitle?: boolean

  hideInfo?: boolean
}

export const LeftSection: React.FC<LeftSectionProps> = ({
  title,
  description,
  hasQRCodeSection,
  className,
  hideTitle,
  hideInfo,
}) => {
  const { t } = useTranslation()
  const entity = useSessionEntity()
  const location = useLocation()
  const isMobile = useWindowResize()
  const isSignupFirstSteps = isSignUpFirstStepsPath(location.pathname)

  return (
    <section
      className={classNames(styles.leftSection, className, {
        [styles.hasAdditionalGap]: !isSignupFirstSteps,
      })}
    >
      {!hideTitle && <TextH1 className={styles.title}>{title}</TextH1>}
      {entity === TickmillCompaniesEnum.TICKMILL_UK && !hideInfo && (
        <TextStrong isParagraph>
          {t('Sign up.Please answer all questions with accurate information that reflect')}
        </TextStrong>
      )}
      {description()}
      {hasQRCodeSection && !isMobile && (
        <div className={styles.qrCode}>
          <AppMarketQRCode />
        </div>
      )}
      {hasQRCodeSection && isMobile && <AppMarketLink spacing={false} />}
    </section>
  )
}
