import React from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import { useSessionEntity } from '../../global/context/EntityContext'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSignup } from '../../global/context/SignupContext'
import { hasProductEmailVerifiedStatus } from '../../model/AccountDetailedDto'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { AccountTypeStep } from './Pages/AccountType/AccountType'
import { CompanyDetailsOrchestrator } from './Pages/CompanyDetails/CompanyDetailsOrchestrator'
import { ConfirmPage } from './Pages/ConfirmPage/ConfirmPage'
import { DocumentsInfoPage } from './Pages/DocumentsInfo/DocumentsInfoPage'
import { FinancialInfoPage } from './Pages/FinancialInfo/FinancialInfoPage'
import { PersonalDetailsOrchestrator } from './Pages/PersonalDetails/PersonalDetailsOrchestrator'
import { PersonalDetailsStepUSPage } from './Pages/PersonalDetails/PersonalDetailsStep1/PersonalDetailsStepUSPage'
import { VerifyPhoneNumberPage } from './Pages/PersonalDetails/VerifyPhoneNumber/VerifyPhoneNumberPage'

export const SignupPage: React.FC = () => {
  const { signupData, appendValues } = useSignup()
  const navigate = useNavigate()
  const { product } = useProductReadContext()
  const { account } = useAccountReadContext()

  return (
    <Routes>
      <Route
        index
        element={
          <AccountTypeStep
            values={signupData.values}
            accountType={signupData.values.typeId}
            onAccountChange={(typeId) => appendValues({ typeId })}
            onSubmit={() => navigate('personal-details')}
          />
        }
      />
      {!!account &&
        !hasProductEmailVerifiedStatus(account, product) &&
        ['financial-documents-info/*', 'documents-info/*'].map((path) => (
          <Route key={path} path={path} element={<Navigate to='/dashboard/traders-room' />} />
        ))}

      <Route path='personal-details'>
        <Route path='verify-phone/:leadId' element={<VerifyPhoneNumberPage />} />
        <Route path='us-citizen' element={<PersonalDetailsStepUSPage />} />
        <Route path=':step?' element={<PersonalDetailsOrchestrator />} />
      </Route>
      <Route path='company-details/:step?' element={<CompanyDetailsOrchestrator />} />
      <Route path='financial-info/:clientId' element={<FinancialInfoPage />} />
      {/*<Route path='first-time-deposit' element={<FirstTimeDepositOrDocumentsResolver />} />*/}
      <Route path='documents-info/:clientId' element={<DocumentsInfoPage />} />
      <Route path='confirm' element={<ConfirmPage />} />
      <Route path='*' element={<Navigate to='/sign-up' />} />
    </Routes>
  )
}
