import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { Loading } from '../global/Loading/Loading'
import { AppMarketQRCode } from '../global/appMarketLink/desktop/AppMarketQRCode'
import { AppMarketLink } from '../global/appMarketLink/mobile/AppMarketLink'
import { Button } from '../global/button/Button'
import { useSessionEntity } from '../global/context/EntityContext'
import { useLogout } from '../hooks/useLogout'
import { TickmillLogoIcon } from '../icons/TickmillLogoIcon'
import { TextLargeStrong, TextStrong } from '../ui/Typography/Typography'
import { useApiClient } from '../utils/ApiClient'
import { AuthSessionContext } from '../utils/AuthContext'
import { useIBCampaignsResultsWriteContext } from '../utils/IBCampaignsResultsContext'
import { ClientApiClient } from '../utils/clientApi'
import { isTickmillPartnerType } from '../utils/companyName.utils'

import styles from './LogoutPage.module.scss'

export const LogoutPage = (): JSX.Element => {
  const navigate = useNavigate()
  const entity = useSessionEntity()
  const { t } = useTranslation()
  const [isLoggingOut, setIsLoggingOut] = useState(true)
  const hasLoggedOutRef = useRef(false)

  const apiClient = useApiClient(ClientApiClient)
  const { logout } = useLogout()
  const [auth] = useContext(AuthSessionContext)

  const { refreshIBCampaigns } = useIBCampaignsResultsWriteContext()

  const performLogout = useCallback(async () => {
    if (!auth) {
      return setIsLoggingOut(false)
    }
    try {
      await apiClient.logOut(auth.id)
      setIsLoggingOut(false)
      refreshIBCampaigns()
      logout()
    } catch (error: unknown) {
      console.error('Failed to logout:', error)
    }
  }, [apiClient, auth, logout, refreshIBCampaigns])

  useEffect(() => {
    if (hasLoggedOutRef.current) {
      return
    }
    hasLoggedOutRef.current = true
    performLogout()
  }, [auth, performLogout])

  return (
    <Loading isLoading={isLoggingOut} showLoadingIcon text={t('Logging out...')}>
      <div className={styles.topWrapper}>
        <div className={styles.wrapper}>
          <div className={styles.qrCode}>
            <AppMarketQRCode />
          </div>
          <div className={styles.inner}>
            <TickmillLogoIcon />
            <TextLargeStrong className={styles.title}>
              {t('You have been successfully logged out')}
            </TextLargeStrong>
            <Button
              type='button'
              onClick={() => navigate('/login')}
              size='L'
              appearance='secondary'
            >
              <TextStrong>{t('Login')}</TextStrong>
            </Button>
            {!isTickmillPartnerType(entity) && (
              <div className={styles.signUpBox}>
                <span>{t('Not a member')}</span>
                <Link to='/sign-up'>{t('Sign up.Sign up')}</Link>
              </div>
            )}
          </div>
          <AppMarketLink />
        </div>
      </div>
    </Loading>
  )
}
