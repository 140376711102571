import React, { useRef } from 'react'
import classNames from 'classnames'
import { getIn, useFormikContext } from 'formik'
import type { FieldConfig as FormikFieldConfig } from 'formik/dist/Field'

import { TextTiny } from '../../ui/Typography/Typography'
import { DeepNonNullable } from '../types/DeepNonNullable'
import { DeepRequired } from '../types/DeepRequired'
import type { TypedFieldProps } from '../types/TypedFieldProps'
import { ValidateField } from '../types/ValidateField'
import { getLabel } from './helpers'

import styles from './FormField.module.scss'

type FieldConfig = Omit<FormikFieldConfig, 'name'>

interface FormFieldProps extends FieldConfig {
  namePrefix?: string
  hint?: string | React.ReactNode
}

export function RawFormTextField<FormValues>(props: FormFieldProps & TypedFieldProps<FormValues>) {
  const { name, hint } = props

  const fieldRef = useRef<HTMLDivElement>(null)
  const context = useFormikContext<FormValues>()

  const label = getLabel(props)
  const value = getValue(context.values, name, props)

  return (
    <div className={classNames('field', styles.field, styles.formTextField)} ref={fieldRef}>
      {!!label && (
        <label className={classNames(styles.offsetlessLabel, styles.labelSecondary)}>{label}</label>
      )}

      <div className={classNames('control')}>
        <div className={classNames('input', styles.input, styles.inputFocus)}>{value}</div>
      </div>

      {hint && <TextTiny className={styles.hint}>{hint}</TextTiny>}
    </div>
  )
}

const getValue = <FormValues,>(values: FormValues, name: string, props: FormFieldProps) => {
  return name ? getIn(values, name) : props.value
}

export function createFormTextField<FormValues>() {
  return function TypedField<Name extends ValidateField<DeepNonNullable<DeepRequired<FormValues>>>>(
    props: FormFieldProps & TypedFieldProps<DeepNonNullable<DeepRequired<FormValues>>, Name>
  ) {
    return <RawFormTextField {...props} />
  }
}
