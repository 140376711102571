import React, { useEffect } from 'react'

import { useTraderUrlByPlatform } from '../hooks/useTraderUrlByPlatform'

export const TradingAccountTradeRedirect: React.FC = () => {
  const url = useTraderUrlByPlatform()

  useEffect(() => {
    if (url) {
      setTimeout(() => {
        window.location.href = url
      }, 1000)
    }
  }, [url])

  return null
}
