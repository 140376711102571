import { useMemo } from 'react'
import ReactCountryFlag from 'react-country-flag'
import classNames from 'classnames'

import { CountryPreferenceStrippedDto } from '../../model/CountryDto'

import styles from './CountryCodeFlagIcon.module.scss'

interface CountryCodeFlagProps {
  countries: CountryPreferenceStrippedDto[]
  selectedCountry?: CountryPreferenceStrippedDto
  countryCode?: string
  className?: string
}

export const CountryCodeFlagIcon: React.FC<CountryCodeFlagProps> = (props) => {
  const { countries, selectedCountry, countryCode, className } = props

  const findCountryCode = useMemo(() => {
    if (selectedCountry) {
      const countryShortName =
        countries.find((x) => x === selectedCountry)?.country?.shortName || ''
      return countryShortName === 'LN' ? 'MY' : countryShortName ?? ''
    } else if (countryCode) {
      const flagCountryCode = countries.find((x) => x.phoneCode === countryCode)?.country.shortName
      return flagCountryCode === 'LN' ? 'MY' : flagCountryCode ?? ''
    }

    return ''
  }, [countryCode, countries, selectedCountry])

  return (
    <ReactCountryFlag
      className={classNames(styles.countryCodeFlagIcon, className)}
      svg
      countryCode={findCountryCode}
    />
  )
}
