import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ClientContestCampaignDetailDto } from '../../model/ClientContestCampaignDetailDto'
import { ClientContestCampaignResultDto } from '../../model/ClientContestCampaignResultDto'
import { Text } from '../../ui/Typography/Typography'
import { getScrollToCardId } from '../../utils/getItemId'

import styles from './CampaignRebateCard.module.scss'

interface CampaignRebateCardsProps {
  data: ClientContestCampaignResultDto[]
  campaignDetails: ClientContestCampaignDetailDto
  campaignContestPointsValue: string
}

export const CampaignContestCards: React.FC<CampaignRebateCardsProps> = (props) => {
  const { data, campaignDetails, campaignContestPointsValue } = props

  const { t } = useTranslation()

  if (!data.length) {
    return (
      <Text isParagraph className='text-align-center pt-4'>
        {t('No results')}
      </Text>
    )
  }

  return (
    <React.Fragment>
      {data.map((contestCampaign, index) => (
        <CampaignContestCard
          data={contestCampaign}
          campaignDetails={campaignDetails}
          campaignContestPointsValue={campaignContestPointsValue}
          key={`contestCampaign_${index}`}
          cardId={getScrollToCardId(index, data.length, 'campaign-results')}
        />
      ))}
    </React.Fragment>
  )
}

interface CampaignContestCardProps {
  data: ClientContestCampaignResultDto
  campaignDetails: ClientContestCampaignDetailDto
  campaignContestPointsValue: string
  cardId?: string
}

const CampaignContestCard: React.FC<CampaignContestCardProps> = (props) => {
  const { data, campaignDetails, campaignContestPointsValue, cardId } = props

  const { t } = useTranslation()

  return (
    <div
      className={classNames(styles.card, {
        [styles.resultCurrentRanking]: data.name === campaignDetails.tradingAccount.name,
      })}
      id={cardId}
    >
      <div className='is-flex is-justify-content-space-between'>
        <span className='has-text-weight-bold'>{t('Campaigns.Ranking')}</span>
        <span className='has-text-weight-bold'>
          {!isDisqualifiedRanking(data) ? `#${data.ranking}` : 'N/A'}
        </span>
      </div>

      <div className={styles.subtitle}>
        <span className='text-secondary'>{t('Campaigns.Account Number')}</span>
        <span className='text-primary'>{data.name}</span>
      </div>

      <div className={styles.subtitle}>
        <span className='text-secondary'> {campaignContestPointsValue}</span>
        <span className='text-primary'>{data.points}%</span>
      </div>
    </div>
  )
}

const isDisqualifiedRanking = (data: ClientContestCampaignResultDto) => {
  return data.ranking === -1
}
