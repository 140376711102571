import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { CancelActionModal } from '../global/modal/CancleActionModal'
import { InformationModal } from '../global/modal/InformationModal'
import { Modal } from '../global/modal/Modal'
import { Text } from '../ui/Typography/Typography'

interface UnableToCheckoutModalProps {
  onCancel(): void
}

interface InsufficientFundsModalProps {
  onCancel(): void
  navigationUrl: string
}

export const UnableToCheckoutModal: React.FC<UnableToCheckoutModalProps> = ({ onCancel }) => {
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onCancel}
      render={() => (
        <InformationModal
          onCancel={onCancel}
          onCancelText={t('Got It')}
          title={t('Subscriptions.Why I can’t checkout?')}
        >
          <Text>
            {t(
              'Subscriptions.To start trading, you need to subscribe to at least one Trading Platform'
            )}
          </Text>
        </InformationModal>
      )}
    />
  )
}

export const InsufficientFundsModal: React.FC<InsufficientFundsModalProps> = ({
  onCancel,
  navigationUrl,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal
      closeModal={onCancel}
      render={() => (
        <CancelActionModal
          title={t('Subscriptions.Insufficient Funds!')}
          footer={
            <>
              <button className='button' type='button' onClick={onCancel}>
                {t('Cancel')}
              </button>
              <button className='button' type='button' onClick={() => navigate(navigationUrl)}>
                {t('Subscriptions.Add Funds')}
              </button>
            </>
          }
        >
          <Text>
            {t(
              'Subscriptions.You need to have funds in your trading account to fund the subscription'
            )}
          </Text>
        </CancelActionModal>
      )}
    />
  )
}
