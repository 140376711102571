import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { createFormField } from '../../../../global/formField/FormField'
import { CancelActionModal } from '../../../../global/modal/CancleActionModal'
import { Modal } from '../../../../global/modal/Modal'
import { FormTemplate } from '../../../../global/templates/FormTemplate'
import { FormatMoney, useFormatNumber } from '../../../../hooks/useFormatNumber'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import { NameDto } from '../../../../model/NameDto'
import { PaymentProvider } from '../../../../model/PaymentProviderDto'
import {
  PaymentProviderType,
  isPaymentProviderBankType,
  isPaymentProviderCardProviderType,
  isPaymentProviderNoneType,
  isPaymentProviderPSPType,
  isPaymentProviderPaymentAgentType,
} from '../../../../model/PaymentProviderType'
import { TradingAccountDetailDto } from '../../../../model/TradingAccountDetailDto'
import { WalletPaymentProvider } from '../../../../model/WalletPaymentProvider'
import { WalletPaymentProviderMethodCurrency } from '../../../../model/WalletPaymentProviderParameters'
import { TextStrong } from '../../../../ui/Typography/Typography'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../../../utils/companyName.utils'
import { useCallbackWithForceRefresh } from '../../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../../utils/useFetch'
import { useScrollToTop } from '../../../../utils/useScrollToTop'
import { isZero } from '../../../../utils/validations'
import { TradingAccountDepositPaymentProviderModal } from '../TradingAccountDepositPaymentProviderModal'
import { TradingAccountDepositTermsConditions } from '../TradingAccountDepositTermsConditions'
import { useTradingAccountPaymentProviderFactory } from '../useTradingAccountPaymentProviderFactory'
import { TradingAccountDepositPSPFields } from './TradingAccountDepositPSPFields'
import { TradingAccountDepositPaymentAgentFields } from './TradingAccountDepositPaymentAgentFields'
import { TradingAccountDepositProviderNoneFields } from './TradingAccountDepositProviderNoneFields'

import styles from './TradingAccountDepositForm.module.scss'

export interface TradingAccountDepositFormValues {
  tradingAccount: TradingAccount
  paymentProvider: PaymentProvider
  amount: number | undefined
  comment: string
  terms: {
    responsibility: boolean
    depositing: boolean
    paymentOperations: boolean
    powerOfAttorney?: boolean
  }
  campaign: Campaign
  minCampaignDepositAmountInUSD: number
  fields: { [key: string]: string }
}

export interface TradingAccount {
  id: string | undefined
  name: string | undefined
  wallet: { id: string; name: string }
  currency: { id: string; name: string }
  campaign: { id: string; name: string }
  balance: number | undefined
}

interface Campaign extends NameDto<string> {
  minDepositAmount: number
  defaultCurrencyId: string
  tradingAccountCurrencyMinDepositAmount?: number
}

const FormField = createFormField<TradingAccountDepositFormValues>()

const TradingAccountDepositFormUI: React.FC<
  FormikProps<TradingAccountDepositFormValues> & OuterProps
> = (props) => {
  useScrollToTop()
  const { tradingAccount, values, onCancel, setValues } = props
  const { tradingAccount: tradingAccountFromValues, paymentProvider } = values
  const [walletPaymentProvider, setWalletPaymentProvider] = useState<
    WalletPaymentProvider | undefined
  >()

  const selectedTradingAccount = tradingAccountFromValues || tradingAccount

  const [isOpenTermsCondition, setIsOpenTermsCondition] = useState(false)

  const { t } = useTranslation()

  const [isPageExitConfirmation, setPageExitConfirmation] = useState(false)
  const { createPaymentProvider } = useTradingAccountPaymentProviderFactory()
  const locale = useSessionLanguage()

  useCampaignDepositAmountInUSD()

  const handlePageExitConfirmation = () => {
    onCancel()
  }

  const handleExitPageConfirmationModalOpen = () => {
    setPageExitConfirmation(true)
  }

  const handleExitPageConfirmationModalClose = () => {
    setPageExitConfirmation(false)
  }
  const onClickTermsConditions = () => setIsOpenTermsCondition(true)

  const handleSetWalletPaymentProvider = (
    walletPaymentProvider: WalletPaymentProvider | undefined
  ) => setWalletPaymentProvider(walletPaymentProvider)

  useEffect(() => {
    ;(async () => {
      if (tradingAccount && walletPaymentProvider) {
        const paymentProvider = await createPaymentProvider(walletPaymentProvider, tradingAccount)
        setValues({ ...values, paymentProvider })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  if (isOpenTermsCondition && paymentProvider && selectedTradingAccount) {
    return (
      <TradingAccountDepositTermsConditions
        paymentProvider={paymentProvider}
        onCancel={() => setIsOpenTermsCondition(false)}
      />
    )
  }

  return (
    <FormTemplate title={t('Deposit')} goBack={handleExitPageConfirmationModalOpen}>
      {isPageExitConfirmation && (
        <Modal
          closeModal={handleExitPageConfirmationModalClose}
          render={() => (
            <CancelActionModal
              onConfirm={handlePageExitConfirmation}
              onCancel={handleExitPageConfirmationModalClose}
            />
          )}
        />
      )}
      <Form>
        {isPaymentProviderPaymentAgentType(values.paymentProvider.providerCategory.id) && (
          <div className={styles.notice}>
            <span className={styles.title}>{t('Wallet.Important!').toUpperCase()} </span>
            {t(
              'Wallet.Please make sure to let the agent know that you want to make a campaign deposit to the campaign account'
            )}
            <TextStrong> {tradingAccount?.name}.</TextStrong>
          </div>
        )}
        <FormFieldsFactory
          tradingAccount={tradingAccount}
          onCancel={handleExitPageConfirmationModalOpen}
          onClickTermsConditions={onClickTermsConditions}
          onSetWalletPaymentProvider={handleSetWalletPaymentProvider}
        />
      </Form>
    </FormTemplate>
  )
}

interface FormFieldsFactoryProps {
  tradingAccount?: TradingAccountDetailDto
  onCancel(): void
  onClickTermsConditions(): void

  onSetWalletPaymentProvider(walletPaymentProvider: WalletPaymentProvider): void
}

const FormFieldsFactory: React.FC<FormFieldsFactoryProps> = (props) => {
  const { tradingAccount, onCancel, onClickTermsConditions, onSetWalletPaymentProvider } = props

  const { formatMoney } = useFormatNumber()
  const { values, setValues, resetForm } = useFormikContext<TradingAccountDepositFormValues>()

  const [isPaymentProviderModalOpen, setPaymentProviderModalOpen] = useState(false)

  const providerCategoryId = values.paymentProvider.providerCategory.id

  const handleExitPageConfirmationModalClose = () => {
    onCancel()
  }

  const handlePaymentProvider = (
    paymentProvider: PaymentProvider,
    walletPaymentProvider: WalletPaymentProvider
  ) => {
    resetForm()
    setValues({
      ...initialState({ tradingAccount }),
      tradingAccount: values.tradingAccount,
      paymentProvider,
    })

    setPaymentProviderModalOpen(false)
    onSetWalletPaymentProvider(walletPaymentProvider)
  }

  const handlePaymentProviderOpen = () => {
    setPaymentProviderModalOpen(true)
  }

  const handlePaymentProviderClose = () => {
    setPaymentProviderModalOpen(false)
  }

  const { t } = useTranslation()

  return (
    <React.Fragment>
      {isPaymentProviderModalOpen && (
        <TradingAccountDepositPaymentProviderModal
          onSelectOption={handlePaymentProvider}
          onClose={handlePaymentProviderClose}
        />
      )}
      <FormField
        name='tradingAccount.currency.id'
        label={t('Trading Account.Deposit To Campaign Account')}
        placeholder={t('Trading Account.Deposit To Campaign Account')}
        value={
          values.tradingAccount?.currency?.id &&
          `${values.tradingAccount.currency.id}-${values.tradingAccount.name}`
        }
        rightIcon={<DropArrowDownIcon />}
        hint={
          values.tradingAccount?.id &&
          `${t('Trading Account.Balance')} ${formatMoney(
            values.tradingAccount.balance,
            values.tradingAccount.currency.id
          )}`
        }
        required
        disabled
      />
      <FormField
        name='paymentProvider.name'
        value={values.paymentProvider.currency?.description}
        label={t('Trading Account.Payment Method')}
        placeholder={t('Trading Account.Payment Method')}
        rightIcon={<DropArrowDownIcon />}
        disabled={!values.tradingAccount?.wallet?.id}
        required
        readOnly
        onClick={handlePaymentProviderOpen}
      />
      {values.paymentProvider.id && isPaymentProviderPSPType(providerCategoryId) && (
        <TradingAccountDepositPSPFields
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {values.paymentProvider.id && isPaymentProviderCardProviderType(providerCategoryId) && (
        <TradingAccountDepositPSPFields
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {values.paymentProvider.id && isPaymentProviderBankType(providerCategoryId) && (
        <TradingAccountDepositPSPFields
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {values.paymentProvider.id && isPaymentProviderPaymentAgentType(providerCategoryId) && (
        <TradingAccountDepositPaymentAgentFields
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {values.paymentProvider.id && isPaymentProviderNoneType(providerCategoryId) && (
        <TradingAccountDepositProviderNoneFields
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
    </React.Fragment>
  )
}

const useCampaignDepositAmountInUSD = () => {
  const apiClient = useApiClient(ClientApiClient)
  const { values, setFieldValue } = useFormikContext<TradingAccountDepositFormValues>()

  const fromCurrency = 'USD'
  const toCurrency = values.paymentProvider.currency.id
  const minCampaignDepositAmount = values.campaign.minDepositAmount
  const isSameCurrency = fromCurrency === toCurrency

  const { callback } = useCallbackWithForceRefresh(async () => {
    if (toCurrency && !isSameCurrency) {
      return apiClient.currencyConverterFromTo(fromCurrency, toCurrency)
    }
  }, [isSameCurrency, fromCurrency, toCurrency])
  const { data: value } = useFetchOne(callback)

  useEffect(() => {
    const rate = value?.rate
    if (rate) {
      const minCampaignDepositAmountInUSD = minCampaignDepositAmount * rate
      setFieldValue('minCampaignDepositAmountInUSD', minCampaignDepositAmountInUSD)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
}

interface OuterProps {
  tradingAccount?: TradingAccountDetailDto
  paymentProvider?: PaymentProvider
  entity: TickmillCompaniesEnum

  onSubmit(values: TradingAccountDepositFormValues): void
  formatMoney: FormatMoney
  onCancel(): void
}

interface StateProps {
  tradingAccount?: TradingAccountDetailDto
  paymentProvider?: PaymentProvider
}

const initialState = (props?: StateProps): TradingAccountDepositFormValues => {
  const { tradingAccount, paymentProvider } = props || {}
  const campaign = tradingAccount?.campaign

  return {
    tradingAccount: {
      id: tradingAccount?.id || undefined,
      name: tradingAccount?.name?.toString() || undefined,
      wallet: {
        id: tradingAccount?.wallet?.id || '',
        name: tradingAccount?.wallet?.name || '',
      },
      currency: {
        id: tradingAccount?.accountGroup.currency?.id || '',
        name: tradingAccount?.accountGroup.currency?.name || '',
      },
      campaign: {
        id: campaign?.id?.toString() || '',
        name: campaign?.name || '',
      },
      balance: tradingAccount?.balance || 0,
    },
    paymentProvider: {
      id: paymentProvider?.id || undefined,
      name: paymentProvider?.name || undefined,
      providerCategory: paymentProvider?.providerCategory || { id: PaymentProviderType.None },
      currency: paymentProvider?.currency || { id: '', name: '' },
      parameters: paymentProvider?.parameters || {
        currencies: [],
        currenciesLimits: [],
        messages: [{ title: [], list: [], top: [], bottom: [] }],
        termsConditions: [],
        fields: [],
        form: [],
      },
      method_name: '',
    },
    fields: {},
    amount: undefined,
    comment: '',
    terms: {
      responsibility: true,
      depositing: false,
      paymentOperations: false,
    },
    campaign: {
      id: campaign?.id || '',
      name: campaign?.name || '',
      minDepositAmount: campaign?.minDepositAmount || 0,
      defaultCurrencyId: campaign?.defaultCurrencyId || '',
      tradingAccountCurrencyMinDepositAmount: campaign?.tradingAccountCurrencyMinDepositAmount || 0,
    },
    minCampaignDepositAmountInUSD: 0,
  }
}

export const TradingAccountDepositForm = withFormik<OuterProps, TradingAccountDepositFormValues>({
  mapPropsToValues: ({ tradingAccount, paymentProvider }) => {
    return initialState({ tradingAccount, paymentProvider })
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      props.onSubmit(values)
      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, { formatMoney }) => {
    const errors: FormikErrors<TradingAccountDepositFormValues> = {}
    const isPaymentAgentProviderType =
      values.paymentProvider.providerCategory.id === PaymentProviderType.PaymentAgent

    if (!values.tradingAccount) {
      errors.tradingAccount = {
        name: t('Validation.Required'),
      }
    }

    if (!values?.paymentProvider?.name) {
      errors.paymentProvider = {}
      errors.paymentProvider.name = t('Validation.Required')
    }

    const providerCategoryId = values.paymentProvider?.providerCategory?.id
    if (
      isPaymentProviderPSPType(providerCategoryId) ||
      isPaymentProviderCardProviderType(providerCategoryId) ||
      isPaymentProviderBankType(providerCategoryId)
    ) {
      const { maxAmount } = getErrorAmount(values)

      if (isMaxAmountError(values)) {
        errors.amount = `${t('Wallet.Maximum deposit')} ${formatMoney(
          maxAmount,
          values.paymentProvider.currency.id
        )}`
      }

      if (!values.terms.paymentOperations) {
        errors.terms = {}
        errors.terms.paymentOperations = t('Validation.Required')
      }
    }

    if (isMinAmountCampaign(values)) {
      const minCampaignAmount = Math.max(
        values.campaign.tradingAccountCurrencyMinDepositAmount || 0,
        values?.paymentProvider?.currency?.minAmount || 0
      )

      errors.amount = `${t('Wallet.Minimum deposit')} ${minCampaignAmount} ${
        values.paymentProvider.currency.id
      }`
    }

    if (isPaymentProviderPaymentAgentType(providerCategoryId)) {
      if (!values.terms.paymentOperations) {
        errors.terms = {}
        errors.terms.paymentOperations = t('Validation.Required')
      }
    }

    if (isPaymentAgentProviderType) {
      if (!values.terms.powerOfAttorney) {
        errors.terms = {}
        errors.terms.powerOfAttorney = t('Validation.Required')
      }
    }

    if (!values.terms.responsibility) {
      errors.terms = {}
      errors.terms.responsibility = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
})(TradingAccountDepositFormUI)

const getErrorAmount = (values: TradingAccountDepositFormValues) => {
  const currencyLimit = getCurrencyLimits(values.paymentProvider)

  const maxAmount = currencyLimit?.maxAmount || 0

  return {
    maxAmount,
  }
}

const isMaxAmountError = (values: TradingAccountDepositFormValues): boolean => {
  const amount = values?.amount || 0
  const { maxAmount } = getErrorAmount(values)

  return amount > maxAmount
}

const isMinAmountCampaign = (values: TradingAccountDepositFormValues): boolean => {
  const minAmount = Math.max(
    values.campaign.tradingAccountCurrencyMinDepositAmount || 0,
    values?.paymentProvider?.currency?.minAmount || 0
  )

  const amount = values?.amount || 0

  return values.campaign && amount < minAmount
}

export const getCurrencyLimits = (
  paymentProvider: PaymentProvider
): WalletPaymentProviderMethodCurrency | undefined => {
  return paymentProvider.parameters.currenciesLimits.find(
    (x) => x.id === paymentProvider.currency.id
  )
}
