import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'

import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { Operator, PageQuery, SortOrder, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { isTickmillPartner } from '../../utils/companyName.utils'
import { dateComparator, formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import stringComparator from '../../utils/stringComparator'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { IBReportCards } from './IBReportCard'
import { IBReportsTable } from './IBReportsTable'

export interface IBReportsQuery {
  [key: string]: string | { value: string; operator: Operator } | undefined

  DateFrom: { value: string; operator: Operator } | undefined
  DateTo: { value: string; operator: Operator } | undefined
}

export type ReportsPageSort =
  | 'login'
  | 'clientName'
  | 'email'
  | 'accountType'
  | 'registrationDate'
  | 'status'
  | 'balance'
  | 'volumeSince2019'
  | 'volumeCurrentMonth'
  | 'volumePreviousMonth'
  | 'swapFree'
  | 'clientEntity'
  | 'country'
  | 'currency'

const initialQuery = {
  DateTo: {
    value: new Date().toDateString(),
    operator: Operator.LESS_OR_EQUAL,
  },
  DateFrom: undefined,
}

const useTitle = () => {
  const { t } = useTranslation()

  const { account } = useAccountReadContext()
  const isPartner = isTickmillPartner(account)

  const renderHeaderTitle = () => {
    if (isPartner) {
      return t('Tabs.Reports')
    }
    return t('IB.IB Reports')
  }

  return { headerTitle: renderHeaderTitle() }
}

export const ReportsPage: React.FC = () => {
  useScrollToTop()
  const { account } = useAccountReadContext()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const apiClient = useApiClient(ClientApiClient)
  const isMobile = useWindowResize()
  const { t } = useTranslation()

  const { headerTitle } = useTitle()

  const [sort, setSort] = useState<ReportsPageSort>('login')
  const [sortOrder, setSortOrder] = useState<SortOrder>('DESC')
  const [sortModalOpen, setSortModalOpen] = useState<boolean>(false)
  const [optionsModal, setOptionsModal] = useState<boolean>(false)

  const [search] = useState<IBReportsQuery>(initialQuery)

  const callback = useCallback(
    async (query?: PageQuery) => {
      if (account?.clientIntroducingBroker?.id) {
        return apiClient.getIBReports(account.clientIntroducingBroker.id, {
          ...query,
          search: search,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  )

  const { data = [], isLoading } = useFetchOne(callback)

  const sortedData = useCallback(() => {
    return data.sort((a, b) => {
      if (
        sort === 'clientName' ||
        sort === 'accountType' ||
        sort === 'country' ||
        sort === 'currency' ||
        sort === 'email' ||
        sort === 'status' ||
        sort === 'clientEntity'
      ) {
        if (sortOrder === 'DESC') {
          return stringComparator(a[sort], b[sort])
        }
        return stringComparator(b[sort], a[sort])
      }
      if (
        sort === 'login' ||
        sort === 'volumeSince2019' ||
        sort === 'volumeCurrentMonth' ||
        sort === 'volumePreviousMonth' ||
        sort === 'balance'
      ) {
        if (sortOrder === 'DESC') {
          return b[sort] - a[sort]
        }
        return a[sort] - b[sort]
      }
      if (sort === 'registrationDate') {
        if (sortOrder === 'DESC') {
          return dateComparator(a.registrationDate, b.registrationDate)
        }
        return dateComparator(b.registrationDate, a.registrationDate)
      }
      return b.login - a.login
    })
  }, [data, sort, sortOrder])

  const prepareCSVData = () => {
    const csvData = [
      [
        t('Trading Account.Trading Account'),
        t('IB.Client Name'),
        t('IB.Email'),
        t('Account Type'),
        t('IB.Registration Date'),
        t('Status'),
        t('Trading Account.Balance'),
        t('IB.Volume Since 2019'),
        t('IB.Volume Current Month'),
        t('IB.Volume Previous Month'),
        t('IB.Swap Free'),
        t('IB.Entity'),
        t('IB.Currency'),
        t('IB.Country'),
      ],
    ]
    sortedData().forEach((report) => {
      csvData.push([
        report.login.toString(),
        report.clientName,
        report.email,
        report.accountType,
        formatDate(report.registrationDate, dateFormat),
        report.status,
        report.balance.toString(),
        report.volumeSince2019.toString(),
        report.volumeCurrentMonth.toString(),
        report.volumePreviousMonth.toString(),
        report.swapFree.toString(),
        report.clientEntity,
        report.currency,
        report.country,
      ])
    })
    return csvData
  }

  const onGenerateExcel = () => {
    const data = prepareCSVData()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(data)

    XLSX.utils.book_append_sheet(wb, ws, 'ib_report')

    XLSX.writeFile(wb, 'ib_report.xlsx')
  }

  const onGeneratePDF = () => {
    const data = prepareCSVData()
    generatePDFTable({ title: t('IB.IB Reports'), data })
    setOptionsModal(false)
  }

  const sortOptions = [
    { id: 'login', name: t('Trading Account.Trading Account') },
    { id: 'clientName', name: t('IB.Client Name') },
    { id: 'email', name: t('Sign up.Email') },
    { id: 'accountType', name: t('IB.Account Type') },
    { id: 'registrationDate', name: t('IB.Registration Date') },
    { id: 'status', name: t('Status') },
    { id: 'balance', name: t('Trading Account.Balance') },
    { id: 'volumeSince2019', name: t('Volume Since 2019') },
    { id: 'volumeCurrentMonth', name: t('Volume Current Month') },
    { id: 'volumePreviousMonth', name: t('Volume Previous Month') },
    { id: 'swapFree', name: t('IB.Swap Free') },
    { id: 'clientEntity', name: t('IB.Entity') },
    { id: 'currency', name: t('Currency') },
    { id: 'country', name: t('IB.Country') },
  ]

  const sortLabel = sortOptions.find((option) => option.id === sort)?.name || ''

  return (
    <React.Fragment>
      {optionsModal && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              onCloseModal={closeModal}
              csvData={prepareCSVData()}
              onExportToCSV={closeModal}
              onExportToPdf={onGeneratePDF}
              onExportToExcel={onGenerateExcel}
            />
          )}
          closeModal={() => setOptionsModal(false)}
        />
      )}
      {sortModalOpen && (
        <Modal
          closeModal={() => setSortModalOpen(false)}
          render={({ closeModal }) => (
            <SortByModal
              onCancel={closeModal}
              options={sortOptions}
              onConfirm={(option, sortBy) => {
                setSort(option as ReportsPageSort)
                setSortOrder(sortBy)
                closeModal()
              }}
            />
          )}
        />
      )}
      <PageHeader
        title={headerTitle}
        optionsToggle={() => setOptionsModal(true)}
        filterToggles={{
          sortLabel: `${t('SortBy')}: ${sortLabel}`,
          openSortModal: () => setSortModalOpen(true),
        }}
      />
      {isMobile ? (
        <IBReportCards sortedData={sortedData()} isLoading={isLoading} />
      ) : (
        <IBReportsTable
          sortedData={sortedData()}
          isLoading={isLoading}
          sort={sort}
          sortOrder={sortOrder}
          setSort={setSort}
          setSortOrder={setSortOrder}
        />
      )}
    </React.Fragment>
  )
}
