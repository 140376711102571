import React from 'react'
import { useTranslation } from 'react-i18next'

import { InfoModal } from '../../../global/modal/InfoModal'
import { Modal } from '../../../global/modal/Modal'
import { Text } from '../../../ui/Typography/Typography'

interface NetworkTableInfoModalProps {
  setInfoModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const NetworkTableInfoModal: React.FC<NetworkTableInfoModalProps> = ({ setInfoModal }) => {
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={() => setInfoModal(false)}
      render={({ closeModal }) => (
        <InfoModal
          onCancel={closeModal}
          title={t('IB.MultiTier.Multi-tier Network')}
          renderBody={() => (
            <section className='modal-card-body'>
              <Text>{t('IB.MultiTier.This is a break-down of your network')}</Text>
            </section>
          )}
          renderFooter={() => (
            <button className='button' onClick={closeModal} type='button'>
              {t('Got It')}
            </button>
          )}
          onConfirm={() => {
            setInfoModal(false)
          }}
        />
      )}
    />
  )
}
