import { SearchQuery, SearchQueryKeys } from '../utils/ApiClient'
import { isOne, isZero } from '../utils/validations'
import { NameDto } from './NameDto'

export enum NotificationType {
  General = 1,
  Platform = 2,
  Promotional = 3,
  Warning = 4,
  Error = 5,
}

export interface Notification {
  ctaText: string
  ctaUrl: string
  endDateTime: string
  id: string
  imageUrl: string | null
  isEnabled: boolean
  isPinned: boolean
  isRead: boolean
  isSilent: boolean
  name: string
  notificationType: NameDto<NotificationType>
  startDateTime: string
  text: string
  title: string
}

export interface NotificationPreferences {
  clientId?: string
  isGeneralSilent: boolean
  isPlatformRelatedSilent: boolean
  isPromotionalSilent: boolean
}

const trueKeys: SearchQueryKeys = {
  NotificationTypeGeneral: NotificationType.General,
  NotificationTypePlatform: NotificationType.Platform,
  NotificationTypePromo: NotificationType.Promotional,
  NotificationTypeWarning: NotificationType.Warning,
  NotificationTypeError: NotificationType.Error,
}

export const getNotificationSearchQuery = (
  pref: NotificationPreferences,
  otherKeys?: SearchQuery
) => {
  // We need to do it this way because on API level, the query is with NotificationType
  // So we build the object with NotificationType keys and then delete the ones that are silent
  // And later on query parsing we remove the type from the key
  const keys: SearchQueryKeys = { ...trueKeys }
  if (pref.isGeneralSilent) {
    delete keys.NotificationTypeGeneral
  }
  if (pref.isGeneralSilent) {
    delete keys.NotificationTypeWarning
  }
  if (pref.isGeneralSilent) {
    delete keys.NotificationTypeError
  }
  if (pref.isPlatformRelatedSilent) {
    delete keys.NotificationTypePlatform
  }
  if (pref.isPromotionalSilent) {
    delete keys.NotificationTypePromo
  }
  if (isZero(Object.keys(keys).length)) {
    return otherKeys
  }
  if (isOne(Object.keys(keys).length)) {
    return { ...otherKeys, ...keys }
  }
  return {
    ...otherKeys,
    nested: [
      {
        queries: keys,
        operator: 'OR' as 'OR' | 'AND',
      },
    ],
  }
}

export const getNotificationSearchQueryById = (selectedTab: string, otherKeys?: SearchQuery) => {
  const id = parseInt(selectedTab, 10) as NotificationType
  // We need to do it this way because on API level, the query is with NotificationType
  // So we build the object with NotificationType keys and then delete the ones that are silent
  // And later on query parsing we remove the type from the key
  const keys: SearchQueryKeys = { ...trueKeys }
  if (id !== NotificationType.General) {
    delete keys.NotificationTypeGeneral
  }
  if (id !== NotificationType.General) {
    delete keys.NotificationTypeWarning
  }
  if (id !== NotificationType.General) {
    delete keys.NotificationTypeError
  }
  if (id !== NotificationType.Platform) {
    delete keys.NotificationTypePlatform
  }
  if (id !== NotificationType.Promotional) {
    delete keys.NotificationTypePromo
  }
  if (isZero(Object.keys(keys).length)) {
    return otherKeys
  }
  if (isOne(Object.keys(keys).length)) {
    return { ...otherKeys, ...keys }
  }
  return {
    ...otherKeys,
    nested: [
      {
        queries: keys,
        operator: 'OR' as 'OR' | 'AND',
      },
    ],
  }
}

export const parsedNotificationKey = (key: string) => {
  if (key.includes('NotificationType')) {
    return 'NotificationType'
  }
  return key
}

export function detectHidePlatformNotificationsClientSide(): boolean {
  const storedStatus = localStorage.getItem('hidePlatformNotifications')
  return storedStatus === 'true'
}

export function storeHidePlatformNotificationsClientSide(value: boolean) {
  localStorage.setItem('hidePlatformNotifications', value.toString())
}

export function detectHideGeneralNotificationsClientSide(): boolean {
  const storedStatus = localStorage.getItem('hideGeneralNotifications')
  return storedStatus === 'true'
}

export function storeHideGeneralNotificationsClientSide(value: boolean) {
  localStorage.setItem('hideGeneralNotifications', value.toString())
}

export function detectHidePromoNotificationsClientSide(): boolean {
  const storedStatus = localStorage.getItem('hidePromoNotifications')
  return storedStatus === 'true'
}

export function storeHidePromoNotificationsClientSide(value: boolean) {
  localStorage.setItem('hidePromoNotifications', value.toString())
}

export const clearNotificationHiding = () => {
  localStorage.removeItem('hidePlatformNotifications')
  localStorage.removeItem('hideGeneralNotifications')
  localStorage.removeItem('hidePromoNotifications')
}
