import { RefObject, useCallback, useRef } from 'react'

import { useResizeEffect } from './useResizeEffect'

export const useDynamicTextArea = (): RefObject<HTMLTextAreaElement> => {
  const ref = useRef<HTMLTextAreaElement>(null)

  const handleChange = useCallback(() => {
    if (!ref.current) {
      return
    }
    ref.current.style.height = '0px'
    const labelOffset = 3

    ref.current.style.height = ref.current.scrollHeight + labelOffset + 'px'
  }, [ref])

  useResizeEffect(handleChange)

  return ref
}
