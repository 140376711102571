import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { useDeviceReferral } from '../../../../../Referral/DeviceReferral'
import { Loading } from '../../../../../global/Loading/Loading'
import { Button } from '../../../../../global/button/Button'
import { useEntitySettings } from '../../../../../global/context/EntityContext'
import { useSignup } from '../../../../../global/context/SignupContext'
import { PhoneVerificationWorkflowEnum } from '../../../../../model/CreatePhoneNumberVerification'
import { LeadDto, isLeadSourceMobileRegistration } from '../../../../../model/LeadDto'
import { Text } from '../../../../../ui/Typography/Typography'
import { ResponseError, instanceOfAxiosError, useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'

import styles from './VerifyEmailPage.module.scss'

export const VerifyEmailRedirect: React.FC = () => {
  const { leadId } = useParams()
  const [searchParams] = useSearchParams()

  if (leadId) {
    return (
      <Navigate to={`/sign-up/personal-details/4?${searchParams.toString()}`} state={{ leadId }} />
    )
  } else {
    return <Navigate to='/login' />
  }
}

export const VerifyEmailPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const [, setEntity] = useEntitySettings()

  const location = useLocation()
  const { leadId } = location.state || {}

  const { setLead, signupData } = useSignup()
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const verificationAttempted = useRef(false)

  const { t } = useTranslation()

  const verifyEmail = useCallback(async () => {
    const hash = searchParams.get('hash')
    const token = searchParams.get('token')

    try {
      if (verificationAttempted.current) {
        return
      }
      verificationAttempted.current = true
      if (!token) {
        return setError(t('Sign up.Something went wrong, please contact support.'))
      } else {
        setError('')
      }

      const leadDto = await apiClient.getLeadByIdAndToken(leadId || '', token)

      try {
        if (hash) {
          await apiClient.verifyEmail({ email: leadDto.email, token: hash })
        }
      } catch (e: unknown) {
        if (instanceOfAxiosError((e as ResponseError)?.response)) {
          const errorResponse = (e as ResponseError).response.response?.data
          if (errorResponse?.code === 'lead_already_verified') {
            await setLead(leadDto)
            navigate(`/sign-up/personal-details/4?${searchParams.toString()}`, {
              state: { leadId },
            })
            return
          }
          if (errorResponse?.code) {
            setError(errorResponse?.code)
          }
        }
      }

      setEntity({ entity: leadDto.tickmillCompany.id })
      if (isLeadSourceMobileRegistration(leadDto?.leadSource)) {
        return setLead(leadDto)
      }

      if (leadDto.phoneVerificationRequired) {
        try {
          const createdPhoneVerification = await apiClient.createPhoneNumberVerification(
            leadDto.id,
            {
              phoneNumber: leadDto.phoneNumber,
              leadId: leadDto.id,
              countryCode: leadDto.countryCode,
              countryId: leadDto.country.id,
              workflowId: PhoneVerificationWorkflowEnum.WorkflowSMS,
            }
          )
          setLead(leadDto)
          return navigate(
            `/sign-up/personal-details/verify-phone/${leadId}?token=${token}&phoneVerificationId=${createdPhoneVerification.phoneVerificationId}`
          )
        } catch (e: unknown) {
          setError(t('Sign up.Something went wrong, please contact support.'))
        } finally {
          setLead(leadDto)
        }
      } else {
        setLead(leadDto)
      }
    } catch (err: unknown) {
      if ((err as ResponseError).response.response?.data.code) {
        const errorResponse = (err as ResponseError).response.response?.data
        if (errorResponse?.code) {
          setError(errorResponse?.code)
        }
      }
    } finally {
      setLoading(false)
    }
  }, [apiClient, leadId, navigate, searchParams, setEntity, setLead, t])

  useEffect(() => {
    verifyEmail()
  }, [verifyEmail])

  if (error && !loading) {
    const getErrorBody = () => {
      if (error === 'verifiable_action_unavailable') {
        return [
          t(
            'Sign up.Your link has expired and is not valid anymore. You can start a new registration right away.'
          ),
        ]
      }
      if (error === 'lead_already_verified') {
        return [
          t('Sign up.To continue your registration with Tickmill, please check your inbox at', {
            email: signupData.lead?.email,
          }) +
            ' ' +
            t(
              'Sign up.You will find an email with subject ‘Email verified’ containing a link to complete your registration.'
            ),
          t('Sign up.Should you require any assistance, please contact'),
          t('Sign up.Tickmill’s Customer Support'),
        ]
      }
      return []
    }

    const getErrorHeader = () => {
      if (error === 'verifiable_action_unavailable') {
        return t('Sign up.Looks like your link has expired')
      }
      if (error === 'lead_already_verified') {
        return t('Sign up.Looks like you’ve clicked your verification link twice!')
      }
      return error
    }

    const getErrorButton = () => {
      if (error === 'verifiable_action_unavailable') {
        return {
          link: '/sign-up',
          text: t('Sign up.Start Registration'),
        }
      }
      return { link: '/support', text: t('Contact support') }
    }

    return (
      <div className={styles.box}>
        <h1>{getErrorHeader()}</h1>
        <div>
          {getErrorBody().map((text) => (
            <Text isParagraph>{text}</Text>
          ))}
        </div>
        <Button
          appearance='primary'
          size='L'
          type='button'
          onClick={() => navigate(getErrorButton().link)}
        >
          {getErrorButton().text}
        </Button>
      </div>
    )
  }

  return (
    <React.Fragment>
      {!isLeadSourceMobileRegistration(signupData.lead?.leadSource) && (
        <Loading isLoading={loading} />
      )}
      {isLeadSourceMobileRegistration(signupData.lead?.leadSource) && (
        <MobileAppRedirectSuccessPage lead={signupData.lead} />
      )}
    </React.Fragment>
  )
}

interface MobileAppRedirectSuccessPageProps {
  lead?: LeadDto
}

const MobileAppRedirectSuccessPage: React.FC<MobileAppRedirectSuccessPageProps> = (props) => {
  const { lead } = props
  const { referralAppLink } = useDeviceReferral()

  const { t } = useTranslation()

  const goToMobileApp = () => {
    if (lead) {
      window.location.href = referralAppLink({
        id: lead.id,
        token: lead.token,
        type: 'register',
      })
    }
  }

  return (
    <div className={styles.box}>
      <h1>{t('Sign up.Your email has been verified!')}</h1>
      <Text isParagraph>
        {t('Sign up.Please continue your registration by going back to the mobile app.')}
      </Text>
      <Button appearance='primary' size='L' type='button' onClick={goToMobileApp}>
        {t('Sign up.Back to the app')}
      </Button>
    </div>
  )
}
