import { TFunction } from 'i18next'

import { errorToast, infoToast, isToastSilent, isToastTypeWarning } from '../global/toast/Toast'
import { ResponseError } from '../utils/ApiClient'
import { getTickmillCompanyByHostname, getTickmillSupportEmail } from '../utils/companyName.utils'
import { getErrorResponseData } from './Session/ErrorResponse'

interface errorContent {
  type: 'danger' | 'success' | 'warning'
  title?: string | string[]
  variant?: 'silent' | 'normal'
  dangerouslySetInnerHTML?: {
    __html: string
  }
  skipScroll?: boolean
  text?: string
  props?: {}
}

type errorObject = {
  [key in errorOptions]: errorContent
}

export enum errorOptions {
  default = 'default',
  user_locked = 'user_locked',
  trading_accounts_max_number_reached = 'trading_accounts_max_number_reached',
  max_number_of_demo_accounts_reached = 'max_number_of_demo_accounts_reached',
  email_in_use = 'email_in_use',
  invalid_file = 'invalid_file',
  invalid_gender = 'invalid_gender',
  invalid_email = 'invalid_email',
  invalid_title = 'invalid_title',
  invalid_password = 'invalid_password',
  client_test_resubmission_on_cooldown = 'client_test_resubmission_on_cooldown',
  invalid_subscription_requests = 'invalid_subscription_requests',
  invalid_password_strength = 'invalid_password_strength',
  invalid_document = 'invalid_document',
  invalid_document_status = 'invalid_document_status',
  invalid_document_status_reason = 'invalid_document_status_reason',
  invalid_document_category = 'invalid_document_category',
  incorrect_amount = 'incorrect_amount',
  invalid_ipheader = 'invalid_ipheader',
  invalid_last_name = 'invalid_last_name',
  missing_gender_id = 'missing_gender_id',
  invalid_first_name = 'invalid_first_name',
  wallet_not_found = 'wallet_not_found',
  cannot_make_deposit = 'cannot_make_deposit',
  not_enough_reserved = 'not_enough_reserved',
  invalid_birth_day = 'invalid_birth_day',
  invalid_file_name = 'invalid_file_name',
  not_enough_incoming = 'not_enough_incoming',
  invalid_credentials = 'invalid_credentials',
  wallet_does_not_exist = 'wallet_does_not_exist',
  invalid_new_password = 'invalid_new_password',
  not_enough_balance = 'not_enough_balance',
  client_not_activated = 'client_not_activated',
  refresh_token_not_found = 'refresh_token_not_found',
  invalid_user_status = 'invalid_user_status',
  lead_already_exists = 'lead_already_exists',
  lead_source_unavailable_for_tickmill_company = 'lead_source_unavailable_for_tickmill_company',
  unsupported_country = 'unsupported_country',
  incorrect_wallet_type = 'incorrect_wallet_type',
  wallet_already_exists = 'wallet_already_exists',
  document_country_required = 'document_country_required',
  invalid_date_format_type = 'invalid_date_format_type',
  documents_not_provided = 'documents_not_provided',
  document_filename_exists = 'document_filename_exists',
  upload_file_max_size_limit = 'upload_file_max_size_limit',
  client_not_found = 'client_not_found',
  recently_used_password = 'recently_used_password',
  lead_already_converted = 'lead_already_converted',
  leverage_exceeds_client_threshold = 'leverage_exceeds_client_threshold',
  password_not_validates = 'password_not_validates',
  cannot_make_withdrawal = 'cannot_make_withdrawal',
  invalid_user_status_pending = 'invalid_user_status_pending',
  document_countries_differ = 'document_countries_differ',
  document_with_tag_exists = 'document_with_tag_exists',
  not_enough_wallet_balance = 'not_enough_wallet_balance',
  wallet_by_name_not_found = 'wallet_by_name_not_found',
  account_group_not_exists = 'account_group_not_exists',
  invalid_document_country = 'invalid_document_country',
  too_many_active_sessions = 'too_many_active_sessions',
  wallet_currency_incorrect = 'wallet_currency_incorrect',
  verifiable_action_not_found = 'verifiable_action_not_found',
  invalid_phone_number_length = 'invalid_phone_number_length',
  duplicate_phone_number = 'duplicate_phone_number',
  campaign_deposit_does_not_meet_minimum_amount_requirement = 'campaign_deposit_does_not_meet_minimum_amount_requirement',
  invalid_user_status_denied = 'invalid_user_status_denied',
  invalid_user_status_blocked = 'invalid_user_status_blocked',
  trading_account_not_approved = 'trading_account_not_approved',
  payment_provider_can_not_deposit = 'payment_provider_can_not_deposit',
  too_many_invalid_sign_in_attempts = 'too_many_invalid_sign_in_attempts',
  mandatory_field_not_supplied = 'mandatory_field_not_supplied',
  tickmill_company_restriction = 'tickmill_company_restriction',
  invalid_refresh_token_session = 'invalid_refresh_token_session',
  ta_does_not_belong_to_wallet = 'ta_does_not_belong_to_wallet',
  trading_account_already_exists = 'trading_account_already_exists',
  trading_account_id_duplication = 'trading_account_id_duplication',
  transaction_cannot_be_reversed = 'transaction_cannot_be_reversed',
  trading_account_has_restriction = 'trading_account_has_restriction',
  phone_number_verification_failed = 'phone_number_verification_failed',
  phone_verification_not_supported = 'phone_verification_not_supported',
  too_many_reset_password_attempts = 'too_many_reset_password_attempts',
  payment_agent_cannot_deposit_to_self = 'payment_agent_cannot_deposit_to_self',
  psp_transaction_details_not_found = 'psp_transaction_details_not_found',
  phone_number_cancellation_failed = 'phone_number_cancellation_failed',
  wallet_has_restriction_to_deposit = 'wallet_has_restriction_to_deposit',
  invalid_wallet_client_combination = 'invalid_wallet_client_combination',
  payment_provider_can_not_withdraw = 'payment_provider_can_not_withdraw',
  ta_has_restriction_to_transfer_to = 'ta_has_restriction_to_transfer_to',
  client_restriction_to_create_wallet = 'client_restriction_to_create_wallet',
  ta_has_restriction_to_transfer_from = 'ta_has_restriction_to_transfer_from',
  trading_account_opening_restriction = 'trading_account_opening_restriction',
  wallet_has_restriction_to_withdraw = 'wallet_has_restriction_to_withdraw',
  not_enough_trading_account_balance = 'not_enough_trading_account_balance',
  lead_phone_number_already_verified = 'lead_phone_number_already_verified',
  trading_account_max_number_reached = 'trading_account_max_number_reached',
  phone_number_request_verification_failed = 'phone_number_request_verification_failed',
  not_enough_balance_with_bonus_reserved = 'not_enough_balance_with_bonus_reserved',
  wallet_has_restriction_to_transfer_to = 'wallet_has_restriction_to_transfer_to',
  payment_agent_by_client_id_not_found = 'payment_agent_by_client_id_not_found',
  wallet_has_restriction_to_transfer_from = 'wallet_has_restriction_to_transfer_from',
  transaction_for_these_wallet_types_not_allowed = 'transaction_for_these_wallet_types_not_allowed',
  client_payment_agent_link_does_not_exist = 'client_payment_agent_link_does_not_exist',
  request_verification_invalid_phone_number = 'request_verification_invalid_phone_number',
  already_started_another_lead_verification_process = 'already_started_another_lead_verification_process',
  unable_to_connect_account_under_own_introducing_broker = 'unable_to_connect_account_under_own_introducing_broker',
  converted_transaction_amount_is_zero = 'converted_transaction_amount_is_zero',
  invalid_user_status_vulnerable_client = 'invalid_user_status_vulnerable_client',
  invalid_user_status_aptest_limit = 'invalid_user_status_aptest_limit',
  name_contains_illegal_characters = 'name_contains_illegal_characters',
  invalid_lead_type = 'invalid_lead_type',
  invalid_country = 'invalid_country',
  invalid_language = 'invalid_language',
  invalid_number = 'invalid_number',
  number_cannot_be_empty = 'number_cannot_be_empty',
  address_street_client_contains_illegal_characters = 'address_street_client_contains_illegal_characters',
  address_city_client_contains_illegal_characters = 'address_city_client_contains_illegal_characters',
  address_county_client_contains_illegal_characters = 'address_county_client_contains_illegal_characters',
  address_postcode_client_contains_illegal_characters = 'address_postcode_client_contains_illegal_characters',
  address_street_company_contains_illegal_characters = 'address_street_company_contains_illegal_characters',
  address_city_company_contains_illegal_characters = 'address_city_company_contains_illegal_characters',
  address_county_company_contains_illegal_characters = 'address_county_company_contains_illegal_characters',
  address_postcode_company_contains_illegal_characters = 'address_postcode_company_contains_illegal_characters',
  already_awaiting_lead_verification = 'already_awaiting_lead_verification',
  duplicate_email_tickmill_company = 'duplicate_email_tickmill_company',
  no_verified_lead_for_tickmill_company = 'no_verified_lead_for_tickmill_company',
  invalid_client_password = 'invalid_client_password',
  invalid_phone_number_country_code = 'invalid_phone_number_country_code',
  invalid_birthday = 'invalid_birthday',
  invalid_primary_address_city = 'invalid_primary_address_city',
  invalid_primary_address_street = 'invalid_primary_address_street',
  invalid_primary_address_state = 'invalid_primary_address_state',
  invalid_primary_address_postal_code = 'invalid_primary_address_postal_code',
  invalid_primary_emal = 'invalid_primary_emal',
  invalid_company_address_city = 'invalid_company_address_city',
  invalid_company_address_country = 'invalid_company_address_country',
  invalid_company_address_postal_code = 'invalid_company_address_postal_code',
  invalid_company_address_state = 'invalid_company_address_state',
  invalid_company_address_street = 'invalid_company_address_street',
  invalid_company_address_email = 'invalid_company_address_email',
  invalid_company_name = 'invalid_company_name',
  invalid_company_phone_number = 'invalid_company_phone_number',
  invalid_company_phone_number_country_code = 'invalid_company_phone_number_country_code',
  invalid_company_registration_date = 'invalid_company_registration_date',
  invalid_company_registration_number = 'invalid_company_registration_number',
  invalid_company_legal_representation_basis = 'invalid_company_legal_representation_basis',
  invalid_company_owners = 'invalid_company_owners',
  invalid_tax_id = 'invalid_tax_id',
  invalid_tax_id_unavailable_reason = 'invalid_tax_id_unavailable_reason',
  primary_nationality_missing = 'primary_nationality_missing',
  invalid_nationality = 'invalid_nationality',
  invalid_country_tickmill_company = 'invalid_country_tickmill_company',
  deposit_amount_is_less_than_minimum_allowed_exception = 'deposit_amount_is_less_than_minimum_allowed_exception',
  deposit_does_not_meet_initial_funding_requirement = 'deposit_does_not_meet_initial_funding_requirement',
  client_bank_account_details_already_exists = 'client_bank_account_details_already_exists',
  document_already_uploaded = 'document_already_uploaded',
  statements_not_found = 'statements_not_found',
  client_tickmill_product_is_funding_requirement_met_not = 'client_tickmill_product_is_funding_requirement_met_not',
  subscription_detail_is_excluded = 'subscription_detail_is_excluded',
  client_has_subscription_requests_not_cancelled_or_failed = 'client_has_subscription_requests_not_cancelled_or_failed',
  client_tickmill_company_not_supported_by_subscription = 'client_tickmill_company_not_supported_by_subscription',
  subscription_detail_not_found = 'subscription_detail_not_found',
  subscription_request_has_no_previous_active_subscription_request = 'subscription_request_has_no_previous_active_subscription_request',
  invalid_subscriptions_platform_types = 'invalid_subscriptions_platform_types',
  trading_account_has_open_positions = 'trading_account_has_open_positions',
  client_has_subscription_requests_not_cancelled_or_failed_or_active = 'client_has_subscription_requests_not_cancelled_or_failed_or_active',
  subscription_detail_invalid_detail_types = 'subscription_detail_invalid_detail_types',
  verifiable_action_unavailable = 'verifiable_action_unavailable',
  two_factor_authentication_invalid_code = 'two_factor_authentication_invalid_code',
  two_factor_authentication_invalid_code_first_time_use = 'two_factor_authentication_invalid_code_first_time_use',
  two_factor_authentication_disabled_for_county_of_residence_of_user = 'two_factor_authentication_disabled_for_county_of_residence_of_user',
  two_factor_auth_too_many_resend_code_attempts = 'two_factor_auth_too_many_resend_code_attempts',
  two_factor_authentication_invalid_token = 'two_factor_authentication_invalid_token',
  two_factor_authentication_invalid_recovery_code = 'two_factor_authentication_invalid_recovery_code',
  two_factor_auth_too_many_insert_code_attempts = 'two_factor_auth_too_many_insert_code_attempts',
  two_factor_auth_too_many_insert_resend_code_attempts = 'two_factor_auth_too_many_insert_resend_code_attempts',
  two_factor_auth_resend_code_cannot_be_executed = 'two_factor_auth_resend_code_cannot_be_executed',
  two_factor_authentication_expired_token = 'two_factor_authentication_expired_token',
}

const getErrorDictionary = (t: TFunction, supportEmail: string): errorObject => ({
  default: {
    type: 'danger',
    title: 'errors.Something went wrong! Please try again later',
  },
  user_locked: {
    type: 'danger',
    title: 'errors.User has been locked. Please contact support',
  },
  trading_accounts_max_number_reached: {
    type: 'danger',
    title: 'errors.You have reached the maximum limit of',
  },
  max_number_of_demo_accounts_reached: {
    type: 'danger',
    title: 'errors.Maximum number of demo trading accounts has been reached',
  },
  email_in_use: {
    type: 'danger',
    title: 'errors.This email is already in use',
  },
  invalid_file: {
    type: 'danger',
    title: 'errors.File is invalid. Please upload another file',
  },
  invalid_gender: {
    type: 'danger',
    title: 'Sign up.Invalid gender.',
  },
  invalid_email: {
    type: 'danger',
    title: 'Sign up.Email provided is invalid.',
  },
  invalid_title: {
    type: 'danger',
    title: 'errors.errors.Invalid title',
  },
  invalid_subscription_requests: {
    type: 'danger',
    title: 'errors.Invalid subscription requests',
  },
  invalid_password: {
    type: 'danger',
    title: 'errors.Invalid password',
  },
  client_test_resubmission_on_cooldown: {
    type: 'danger',
    title: 'errors.Client test cannot be resubmitted in less than 300 seconds',
  },
  invalid_password_strength: {
    type: 'danger',
    text: "Invalid password. Password strength doesn't meet the criteria.",
  },
  invalid_document: {
    type: 'danger',
    title: 'errors.Document is invalid',
  },
  invalid_document_status: {
    type: 'danger',
    title: "errors.This document has been removed and can't be updated.",
  },
  invalid_document_status_reason: {
    type: 'danger',
    title: "errors.This document has been removed and can't be updated.",
  },
  invalid_document_category: {
    type: 'danger',
    title: "errors.This document has been removed and can't be updated.",
  },
  incorrect_amount: {
    type: 'danger',
    title: 'errors.Incorrect amount',
  },
  invalid_ipheader: {
    type: 'danger',
    title: "errors.Can't detect location automatically",
  },
  invalid_last_name: {
    type: 'danger',
    title: 'Sign up.Please use latin characters only for last name.',
  },
  missing_gender_id: {
    type: 'danger',
    title: 'Sign up.Gender was not specified.',
  },
  invalid_first_name: {
    type: 'danger',
    title: 'Sign up.Please use latin characters only for first name.',
  },
  wallet_not_found: {
    type: 'danger',
    title: 'errors.Payment Agent wallet does not exist',
  },
  cannot_make_deposit: {
    type: 'danger',
    title: "errors.Transaction couldn't make a deposit",
  },
  not_enough_reserved: {
    type: 'danger',
    title: 'errors.Not enough reserved amount',
  },
  invalid_birth_day: {
    type: 'danger',
    title: 'Sign up.Selected birthday is invalid.',
  },
  invalid_file_name: {
    type: 'danger',
    title: 'errors.File name is invalid',
  },
  not_enough_incoming: {
    type: 'danger',
    title: 'errors.Not enough incoming value',
  },
  invalid_credentials: {
    type: 'danger',
    title: 'errors.Invalid credentials',
  },
  wallet_does_not_exist: {
    type: 'danger',
    title: 'errors.Wallet does not exist',
  },
  invalid_new_password: {
    type: 'danger',
    title: 'errors.New password cannot be the same as the old password',
  },
  not_enough_balance: {
    type: 'danger',
    title: 'errors.Not enough balance',
  },
  client_not_activated: {
    type: 'danger',
    title: 'errors.Client is not activated',
  },
  refresh_token_not_found: {
    type: 'danger',
    title: 'errors.Refresh token was not found',
  },
  invalid_user_status: {
    type: 'danger',
    title: 'errors.Invalid user status',
  },
  lead_already_exists: {
    type: 'danger',
    title: 'Sign up.We already have a record with this e-mail account. Please verify your e-mail.',
    variant: 'silent',
  },
  lead_source_unavailable_for_tickmill_company: {
    type: 'danger',
    title: 'Sign up.Email provided is invalid.',
  },
  leverage_exceeds_client_threshold: {
    type: 'danger',
    title: 'errors.The selected leverage exceeds maximum limit',
  },
  unsupported_country: {
    type: 'danger',
    title: 'errors.Selected country is not supported',
  },
  incorrect_wallet_type: {
    type: 'danger',
    title: 'errors.Wallet type is incorrect',
  },
  wallet_already_exists: {
    type: 'danger',
    title: 'errors.Wallet already exists',
  },
  document_country_required: {
    type: 'danger',
    title: 'errors.Document country is required',
  },
  invalid_date_format_type: {
    type: 'danger',
    title: 'errors.Invalid date format provided',
  },
  documents_not_provided: {
    type: 'danger',
    title: 'errors.Documents were not provided',
  },
  document_filename_exists: {
    type: 'danger',
    title:
      'errors.Document name has already been uploaded. Please upload a document with a different document name.',
  },
  upload_file_max_size_limit: {
    type: 'danger',
    title: 'errors.Cannot upload file because its size is over allowed limit.',
  },
  client_not_found: {
    type: 'danger',
    title: 'errors.Client not found.',
  },
  recently_used_password: {
    type: 'danger',
    title: 'errors.Provided password was recently used already',
  },
  lead_already_converted: {
    type: 'danger',
    title: 'Sign up.We already have a record with this e-mail account.',
    variant: 'silent',
  },
  password_not_validates: {
    type: 'danger',
    title: 'errors.Password does not meet the requirements',
  },
  cannot_make_withdrawal: {
    type: 'danger',
    title: "errors.Transaction couldn't make a withdrawal",
  },
  invalid_user_status_pending: {
    type: 'danger',
    title: 'errors.Pending email verification',
  },
  document_countries_differ: {
    type: 'danger',
    title: 'errors.Document countries are different',
  },
  document_with_tag_exists: {
    type: 'danger',
    title: 'errors.There is already another document with the same type',
  },
  not_enough_wallet_balance: {
    type: 'danger',
    title: 'errors.There is not enough balance on wallet',
  },
  wallet_by_name_not_found: {
    type: 'danger',
    title: 'errors.Wallet not found',
  },
  account_group_not_exists: {
    type: 'danger',
    title: 'errors.Trading Account could not be created with these parameters',
  },
  invalid_document_country: {
    type: 'danger',
    title: 'errors.Document country is invalid',
  },
  too_many_active_sessions: {
    type: 'danger',
    title: 'errors.Too many active sessions for the user',
  },
  wallet_currency_incorrect: {
    type: 'danger',
    title: 'errors.Wallet currency is incorrect',
  },
  verifiable_action_not_found: {
    type: 'danger',
    title: 'errors.Verifiable action was not found',
  },
  invalid_number: {
    type: 'danger',
    text: 'Sign up.Invalid phone number',
    skipScroll: true,
    variant: 'silent',
  },
  number_cannot_be_empty: {
    type: 'danger',
    text: 'Sign up.Empty phone number',
    skipScroll: true,
  },
  invalid_phone_number_length: {
    type: 'danger',
    title: 'Sign up.Phone number needs to be 5 or more digits.',
  },
  duplicate_phone_number: {
    type: 'danger',
    title: 'errors.Phone number already exists',
  },
  campaign_deposit_does_not_meet_minimum_amount_requirement: {
    type: 'danger',
    title: 'errors.Campaign deposit does not meet the minimum amount requirement.',
  },
  invalid_user_status_denied: {
    type: 'danger',
    dangerouslySetInnerHTML: {
      __html: t('errors.You have been denied access to the Client', {
        email: supportEmail,
      }),
    },
  },
  invalid_user_status_blocked: {
    type: 'danger',
    dangerouslySetInnerHTML: {
      __html: t('errors.You have been blocked from accessing the Client Area', {
        email: supportEmail,
      }),
    },
  },
  trading_account_not_approved: {
    type: 'danger',
    title: 'errors.Trading account not approved',
  },
  payment_provider_can_not_deposit: {
    type: 'danger',
    title: 'errors.Payment provider cannot deposit',
  },
  too_many_invalid_sign_in_attempts: {
    type: 'danger',
    title: 'errors.Too many invalid sign in attempts for the user',
  },
  mandatory_field_not_supplied: {
    type: 'danger',
    title: 'errors.Mandatory field not supplied',
  },
  tickmill_company_restriction: {
    type: 'danger',
    title: 'errors.This function is not available for this Tickmill company',
  },
  invalid_refresh_token_session: {
    type: 'danger',
    title: 'errors.Invalid refresh token session',
  },
  ta_does_not_belong_to_wallet: {
    type: 'danger',
    title: 'errors.Trading account does not belong to the wallet',
  },
  trading_account_already_exists: {
    type: 'danger',
    title: 'errors.Trading account already exists',
  },
  trading_account_id_duplication: {
    type: 'danger',
    title: 'errors.Trading account id duplication',
  },
  transaction_cannot_be_reversed: {
    type: 'danger',
    title: 'errors.Transaction cannot be reserved',
  },
  trading_account_has_restriction: {
    type: 'danger',
    title: 'errors.Trading account has restriction that prevents this operation',
  },
  phone_number_verification_failed: {
    type: 'danger',
    title: 'errors.Phone verification check has failed',
  },
  phone_verification_not_supported: {
    type: 'danger',
    title: 'errors.Phone verification for this country is not supported',
  },
  too_many_reset_password_attempts: {
    type: 'danger',
    title: 'errors.Too many reset attempts for the email',
  },
  payment_agent_cannot_deposit_to_self: {
    type: 'danger',
    title: 'errors.Payment agent cannot deposit to self',
  },
  psp_transaction_details_not_found: {
    type: 'danger',
    title: 'errors.Payment transaction details not found',
  },
  phone_number_cancellation_failed: {
    type: 'danger',
    title: 'errors.Phone verification cancellation has failed',
  },
  wallet_has_restriction_to_deposit: {
    type: 'danger',
    title: 'errors.Wallet has restriction to deposit',
  },
  invalid_wallet_client_combination: {
    type: 'danger',
    title: 'errors.The Wallet name and Client name combination is not valid',
  },
  payment_provider_can_not_withdraw: {
    type: 'danger',
    title: 'errors.Payment provider cannot withdraw',
  },
  ta_has_restriction_to_transfer_to: {
    type: 'danger',
    title: 'errors.Trading account has restriction to transfer to',
  },
  client_restriction_to_create_wallet: {
    type: 'danger',
    title: 'errors.Client is restricted to create a wallet',
  },
  ta_has_restriction_to_transfer_from: {
    type: 'danger',
    title: 'errors.Trading account has restriction to transfer from',
  },
  trading_account_opening_restriction: {
    type: 'danger',
    title: 'errors.Wallet has trading account opening restriction.',
  },
  wallet_has_restriction_to_withdraw: {
    type: 'danger',
    title: 'errors.Wallet has restriction to withdraw',
  },
  not_enough_trading_account_balance: {
    type: 'danger',
    title: 'errors.There is not enough balance on trading account',
  },
  lead_phone_number_already_verified: {
    type: 'danger',
    title: 'errors.Phone number was already verified',
  },
  trading_account_max_number_reached: {
    type: 'danger',
    title: 'errors.Maximum number of trading accounts reached',
  },
  phone_number_request_verification_failed: {
    type: 'danger',
    title: 'errors.Phone verification requesting has failed',
  },
  not_enough_balance_with_bonus_reserved: {
    type: 'danger',
    title: 'errors.Minimum trading volume required to use reserved bonus',
  },
  wallet_has_restriction_to_transfer_to: {
    type: 'danger',
    title: 'errors.Wallet has restriction to transfer to',
  },
  payment_agent_by_client_id_not_found: {
    type: 'danger',
    title: 'errors.Payment agent not found',
  },
  wallet_has_restriction_to_transfer_from: {
    type: 'danger',
    title: 'errors.Wallet has restriction to transfer from',
  },
  transaction_for_these_wallet_types_not_allowed: {
    type: 'danger',
    title: 'errors.Transaction for these wallet types not allowed',
  },
  client_payment_agent_link_does_not_exist: {
    type: 'danger',
    title: 'errors.Client has not accepted power of attorney',
  },
  request_verification_invalid_phone_number: {
    type: 'danger',
    title: 'errors.Invalid phone number for request verification',
  },
  already_started_another_lead_verification_process: {
    type: 'danger',
    title: 'errors.This e-mail address has started registering in web',
  },
  unable_to_connect_account_under_own_introducing_broker: {
    type: 'danger',
    title: 'errors.Cannot open trading account under your own IB account',
  },
  converted_transaction_amount_is_zero: {
    type: 'danger',
    title: 'errors.Converted transaction amount cannot be 0.',
  },
  deposit_amount_is_less_than_minimum_allowed_exception: {
    type: 'danger',
    title: 'errors.Deposit Amount is less than minimum amount allowed',
  },
  deposit_does_not_meet_initial_funding_requirement: {
    type: 'danger',
    title: 'errors.Deposit Amount is less than minimum amount allowed',
  },
  client_bank_account_details_already_exists: {
    type: 'danger',
    title: 'errors.Bank details already exist',
  },
  document_already_uploaded: {
    type: 'danger',
    title: 'errors.This document has already been uploaded',
  },
  invalid_user_status_vulnerable_client: {
    type: 'danger',
    title:
      'Sign up.From the information you have provided, we do not think this product is appropriate for you at this time. If you would like to discuss further, please contact {{email}}.',
    props: {
      email: supportEmail,
    },
  },
  invalid_user_status_aptest_limit: {
    type: 'danger',
    title:
      'Sign up.Maximum attempts reached. You will be able to re-take the appropriateness test in 1 year. Please contact {{email}} for further assistance.',
    props: {
      email: supportEmail,
    },
  },
  name_contains_illegal_characters: {
    type: 'danger',
    title: 'Sign up.Please use latin characters only for name.',
  },
  invalid_lead_type: {
    type: 'danger',
    title: 'Sign up.Account type selected is invalid.',
  },
  invalid_country: {
    type: 'danger',
    title: 'Sign up.This country is not available for this Tickmill entity.',
  },
  invalid_language: {
    type: 'danger',
    title: 'Sign up.Language selected is invalid.',
  },
  address_street_client_contains_illegal_characters: {
    type: 'danger',
    title: 'Sign up.Address street contains unsupported characters',
  },
  address_city_client_contains_illegal_characters: {
    type: 'danger',
    title: 'Sign up.Address city contains unsupported characters',
  },
  address_county_client_contains_illegal_characters: {
    type: 'danger',
    title: 'Sign up.Address state contains unsupported characters',
  },
  address_postcode_client_contains_illegal_characters: {
    type: 'danger',
    title: 'Sign up.Address postcode contains unsupported characters',
  },
  address_street_company_contains_illegal_characters: {
    type: 'danger',
    title: 'Sign up.Company address street contains unsupported characters',
  },
  address_city_company_contains_illegal_characters: {
    type: 'danger',
    title: 'Sign up.Company address city contains unsupported characters',
  },
  address_county_company_contains_illegal_characters: {
    type: 'danger',
    title: 'Sign up.Company address state contains unsupported characters',
  },
  address_postcode_company_contains_illegal_characters: {
    type: 'danger',
    title: 'Sign up.Company address postcode contains unsupported characters',
  },
  already_awaiting_lead_verification: {
    type: 'danger',
    title:
      'Sign up.Verification email has already been sent to you, please verify your email before proceeding.',
  },
  duplicate_email_tickmill_company: {
    type: 'danger',
    title: 'Sign up.Client already exists with this email.',
  },
  no_verified_lead_for_tickmill_company: {
    type: 'danger',
    title: 'Sign up.Please verify your email address to continue.',
  },
  invalid_client_password: {
    type: 'danger',
    title: 'Sign up.Password is invalid.',
  },
  invalid_phone_number_country_code: {
    type: 'danger',
    title: 'Sign up.Phone number country code is invalid.',
  },
  invalid_birthday: {
    type: 'danger',
    title: 'Sign up.Birthday is invalid.',
  },
  invalid_primary_address_city: {
    type: 'danger',
    title: 'Sign up.City cannot be empty.',
  },
  invalid_primary_address_street: {
    type: 'danger',
    title: 'Sign up.Street cannot be empty.',
  },
  invalid_primary_address_state: {
    type: 'danger',
    title: 'Sign up.State cannot be empty.',
  },
  invalid_primary_address_postal_code: {
    type: 'danger',
    title: 'Sign up.Postal Code cannot be empty.',
  },
  invalid_primary_emal: {
    type: 'danger',
    title: 'Sign up.Email cannot be empty.',
  },
  invalid_company_address_city: {
    type: 'danger',
    title: 'Sign up.Company city cannot be empty.',
  },
  invalid_company_address_country: {
    type: 'danger',
    title: 'Sign up.Company country cannot be empty.',
  },
  invalid_company_address_postal_code: {
    type: 'danger',
    title: 'Sign up.Company postal Code cannot be empty.',
  },
  invalid_company_address_state: {
    type: 'danger',
    title: 'Sign up.Company state cannot be empty.',
  },
  invalid_company_address_street: {
    type: 'danger',
    title: 'Sign up.Company street cannot be empty.',
  },
  invalid_company_address_email: {
    type: 'danger',
    title: 'Sign up.Company email cannot be empty.',
  },
  invalid_company_name: {
    type: 'danger',
    title: 'Sign up.Company name cannot be empty.',
  },
  invalid_company_phone_number: {
    type: 'danger',
    title: 'Sign up.Company phone number is invalid.',
  },
  invalid_company_phone_number_country_code: {
    type: 'danger',
    title: 'Sign up.Company phone number country code is invalid.',
  },
  invalid_company_registration_date: {
    type: 'danger',
    title: 'Sign up.Company registration date cannot be empty.',
  },
  invalid_company_registration_number: {
    type: 'danger',
    title: 'Sign up.Company registration number cannot be empty.',
  },
  invalid_company_legal_representation_basis: {
    type: 'danger',
    title: 'Sign up.Company legal representation basis cannot be empty.',
  },
  invalid_company_owners: {
    type: 'danger',
    title: 'Sign up.Company owners cannot be empty.',
  },
  invalid_tax_id: {
    type: 'danger',
    title: 'Sign up.Tax Id cannot be empty.',
  },
  invalid_tax_id_unavailable_reason: {
    type: 'danger',
    title: 'Sign up.Tax Id unavailable reason cannot be empty.',
  },
  primary_nationality_missing: {
    type: 'danger',
    title: 'Sign up.Primary nationality is missing.',
  },
  invalid_nationality: {
    type: 'danger',
    title: 'Sign up.Selected  nationality is invalid.',
  },
  invalid_country_tickmill_company: {
    type: 'danger',
    title: 'Sign up.Selected country is not allowed for onboarding with this Tickmill Entity.',
  },
  statements_not_found: {
    type: 'warning',
    title: 'errors.No statements matching your criteria were found',
  },
  client_tickmill_product_is_funding_requirement_met_not: {
    type: 'danger',
    title: 'errors.Client funding requirement not met',
  },
  subscription_detail_is_excluded: {
    type: 'danger',
    title: 'errors.Subscription detail is excluded by active subscription detail',
  },
  client_has_subscription_requests_not_cancelled_or_failed: {
    type: 'danger',
    title: 'errors.Client has subscription requests that are not Cancelled or Failed',
  },
  client_tickmill_company_not_supported_by_subscription: {
    type: 'danger',
    title: 'errors.Clients tickmill company Tickmill UK Company not supported by subscription.',
  },
  subscription_detail_not_found: {
    type: 'danger',
    title: 'errors.Subscription Detail was not found',
  },
  subscription_request_has_no_previous_active_subscription_request: {
    type: 'danger',
    title: 'errors.Subscription request has no previous active subscription',
  },
  invalid_subscriptions_platform_types: {
    type: 'danger',
    title: 'errors.Subscription types are invalid',
  },
  trading_account_has_open_positions: {
    type: 'danger',
    title: 'errors.Your trading account has open positions',
    variant: 'silent',
  },
  two_factor_authentication_invalid_code: {
    type: 'danger',
    title: 'errors.Code is not valid',
    variant: 'silent',
  },
  two_factor_authentication_invalid_code_first_time_use: {
    type: 'danger',
    title: 'errors.Code is not valid',
    variant: 'silent',
  },
  two_factor_authentication_disabled_for_county_of_residence_of_user: {
    type: 'warning',
    variant: 'silent',
  },
  client_has_subscription_requests_not_cancelled_or_failed_or_active: {
    type: 'danger',
    title: 'errors.You have no subscription requests for selected subscription.',
  },
  subscription_detail_invalid_detail_types: {
    type: 'danger',
    title: 'errors.Subscription request(s) details are invalid',
  },
  verifiable_action_unavailable: {
    type: 'danger',
    title: 'errors.verifiable action unavailable',
  },
  two_factor_authentication_invalid_token: {
    type: 'danger',
    title: 'errors.2FA token is invalid or has expired please try to login again',
  },
  two_factor_auth_too_many_resend_code_attempts: {
    type: 'danger',
    variant: 'silent',
  },
  two_factor_auth_too_many_insert_code_attempts: {
    type: 'danger',
    variant: 'silent',
  },
  two_factor_authentication_invalid_recovery_code: {
    type: 'danger',
    title: 'errors.Wrong code verification failed',
  },
  two_factor_auth_too_many_insert_resend_code_attempts: {
    type: 'danger',
    variant: 'silent',
  },
  two_factor_auth_resend_code_cannot_be_executed: {
    type: 'danger',
    title: 'errors.Request for a new code will be available in few seconds',
  },
  two_factor_authentication_expired_token: {
    type: 'danger',
    title: 'errors.Code has expired please try to login again or use recovery code',
  },
})

export const handleToastType = (error: ResponseError, t: TFunction) => {
  const { response, confirmationCode } = getErrorResponseData(error)

  const confirmationData = handleApiConfirmations(confirmationCode as errorOptions, t)
  if (isToastSilent(confirmationData.variant)) {
    return console.info(confirmationData.text || confirmationData.title)
  }

  const toastType = isToastTypeWarning(confirmationData?.type) ? infoToast : errorToast
  if (confirmationData.dangerouslySetInnerHTML || confirmationData.text) {
    return toastType({
      skipScroll: confirmationData.skipScroll,
      type: confirmationData.type,
      title: confirmationData.text ? t(confirmationData.text) : undefined,
      dangerouslySetInnerHTML: confirmationData.dangerouslySetInnerHTML,
    })
  }

  if (confirmationData?.title) {
    const isUserStatusValidationDenied =
      response?.response?.data.code === 'invalid_user_status_denied'
    return toastType({
      skipScroll: confirmationData.skipScroll,
      type: confirmationData.type,
      title: t(confirmationData.title, confirmationData.props!),
      requireInteraction: isUserStatusValidationDenied,
    })
  }
}

export const handleApiConfirmations = (code: errorOptions, t: TFunction): errorContent => {
  const supportEmail = getTickmillSupportEmail(getTickmillCompanyByHostname(true))

  const errorDictionary = getErrorDictionary(t, supportEmail)
  return errorDictionary[code] ?? errorDictionary.default
}

export const isInvalidPassword = (error: ResponseError): boolean => {
  const { response, message } = getErrorResponseData(error)

  return (
    response?.response?.data.code === 'invalid_password' || message?.code === 'invalid_password'
  )
}

export const isInvalidUserStatus = (error: ResponseError): boolean => {
  const { response } = getErrorResponseData(error)
  return response?.response?.data.code === 'invalid_user_status_denied'
}

export const isPspServiceCheckError = (error: ResponseError): boolean => {
  const { response } = getErrorResponseData(error)
  return response?.response?.data?.code === 'psp_service_check_error'
}

export const isSubscriptionCartForClientExists = (error: ResponseError): boolean => {
  const { response } = getErrorResponseData(error)
  return (
    response?.response?.data?.code ===
    'subscription_cart_item_for_client_and_subscription_detail_exists'
  )
}

export const isSkipErrorCode = (error: ResponseError) => {
  const code = error?.response?.response?.data?.code
  return (
    code &&
    [
      'lead_already_verified',
      'already_started_another_lead_verification_process',
      'subscription_detail_is_excluded',
      'subscription_detail_is_excluded_by_bundle',
    ].includes(code)
  )
}

export const isInvalidLeadError = (error: string | undefined): boolean => {
  const code = error?.toLocaleLowerCase() || ''
  return (
    code === 'missing_gender_id' ||
    code === 'invalid_gender' ||
    code === 'name_contains_illegal_characters' ||
    code === 'invalid_first_name' ||
    code === 'invalid_last_name' ||
    code === 'invalid_birth_day' ||
    code === 'invalid_email' ||
    code === 'invalid_lead_type' ||
    code === 'invalid_phone_number_length' ||
    code === 'invalid_country' ||
    code === 'invalid_language' ||
    code === 'invalid_user_status_aptest_limit' ||
    code === 'invalid_user_status_vulnerable_client' ||
    code === 'address_street_client_contains_illegal_characters' ||
    code === 'address_city_client_contains_illegal_characters' ||
    code === 'address_county_client_contains_illegal_characters' ||
    code === 'address_postcode_client_contains_illegal_characters' ||
    code === 'address_street_company_contains_illegal_characters' ||
    code === 'address_city_company_contains_illegal_characters' ||
    code === 'address_county_company_contains_illegal_characters' ||
    code === 'address_postcode_company_contains_illegal_characters'
  )
}

export const isCannotCreateLeadError = (error: string | undefined): boolean => {
  const code = error?.toLocaleLowerCase() || ''
  return (
    code === 'already_awaiting_lead_verification' ||
    code === 'lead_already_verified' ||
    code === 'already_started_another_lead_verification_process' ||
    code === 'lead_already_converted'
  )
}
