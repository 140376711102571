import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../../../../../global/button/Button'
import { useSessionEntity } from '../../../../../global/context/EntityContext'
import { FileData } from '../../../../../global/fileUpload/FileUpload'
import { removeUploadedFile } from '../../../../../global/fileUpload/FileUploads'
import { ClientNciPriorityDto } from '../../../../../model/ClientNciPriorityDto'
import { CreateClientDocument } from '../../../../../model/CreateClientDocuments'
import {
  ClientCreateNciFinalHistory,
  ClientNciHistoryStatus,
} from '../../../../../model/CreateNciHistory'
import { DocumentCategoryType } from '../../../../../model/DocumentCategories'
import { UploadDocumentCategoryEnum } from '../../../../../model/UploadDocumentCategoryEnum'
import { Text, TextH3 } from '../../../../../ui/Typography/Typography'
import { useAccountReadContext } from '../../../../../utils/AccountContextContext'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../../../../utils/companyName.utils'
import { isDoubleSidedDocument } from '../../../../../utils/uploadDocument'
import { useFetchOne } from '../../../../../utils/useFetch'
import { DocumentFileUploadsFactory } from '../DocumentFileUploadsFactory'
import { DocumentChooseAnotherModal } from './DocumentChooseAnotherModal'
import { DocumentConfirmationModal } from './DocumentConfirmationModal'
import { DocumentFileConfirmationModal } from './DocumentFileConfirmationModal'
import { DocumentNumberFieldFactory } from './DocumentNumberFieldFactory'
import { DocumentReasonModal } from './DocumentReasonModal'

import styles from '../DocumentsInfoPage.module.scss'

export interface DocumentInfoPageFormValuesStep1 {
  documentTypeId: string
  documents: DocumentManagementDocument[]
  reason?: string
  files: FileData[]
  nciDocumentNumber: string
}

interface DocumentManagementDocument {
  categoryId: number
  typeId: number
  file: string
  filename: string
  error: boolean
}

const DocumentInfoNCIPageStep1FormUI: React.FC<
  FormikProps<DocumentInfoPageFormValuesStep1> & OuterProps
> = (props) => {
  const {
    setValues,
    values,
    onSkipDocuments,
    isValid,
    handleSubmit,
    documentCategory,
    handleChoosePreviousDoc,
    accountNciAvailablePriorities,
  } = props

  const { t } = useTranslation()
  const entity = useSessionEntity()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [isReasonModalOpen, setReasonModalOpen] = useState(false)
  const [showChooseAnotherDoc, setShowChooseAnotherDoc] = useState(false)
  const [isChooseAnotherModalOpen, setChooseAnotherModalOpen] = useState(false)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [isFileConfirmationModalOpen, setFileConfirmationModalOpen] = useState(false)
  const [documentOptions, setDocumentOptions] = useState<ClientNciPriorityDto[]>([])

  const documentTypesCallback = useCallback(async () => {
    return apiClient.getAccountNciPriorities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: documents } = useFetchOne(documentTypesCallback)

  useEffect(() => {
    if (documents) {
      setDocumentOptions(documents.filter((doc) => !doc.final))
    }
  }, [documents])

  const [file, setFile] = useState<FileData>()

  const handleSubmitClick = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsFormSubmitting(true)
    try {
      await handleSubmit()
    } catch (error: unknown) {
      console.log(error)
      setIsFormSubmitting(false)
    }
  }

  const handleSubmitReason = async (reason?: string) => {
    if (reason) {
      props.handleSubmitReason(reason)
      setReasonModalOpen(false)
    }
  }

  const handleChooseAnotherDocument = () => {
    if (accountNciAvailablePriorities.final && documentOptions.length) {
      if (documentOptions.length > 1) {
        setChooseAnotherModalOpen(true)
      } else {
        handleChoosePreviousDoc(documentOptions[0], 0)
      }
    } else {
      setReasonModalOpen(true)
    }
  }

  const handleDocumentUploads = (filesData: FileData[]) => {
    setValues({
      ...values,
      files: filesData,
      documents: filesData.map((x) => ({
        ...x,
        categoryId: DocumentCategoryType.Personal,
        typeId: Number.parseInt(values.documentTypeId, 10),
        filename: x.fileName,
        file: x.base64Content,
      })),
    })
  }

  const handleDocumentRemove = () => {
    const files = removeUploadedFile(values.files, file)
    setValues({
      ...values,
      files,
      documents: files.map((x) => ({
        ...x,
        categoryId: DocumentCategoryType.Address,
        typeId: Number.parseInt(values.documentTypeId, 10),
        filename: x.fileName,
        file: x.base64Content,
      })),
    })
    setFileConfirmationModalOpen(false)
  }

  const handleDocumentBeforeRemove = (file: FileData) => {
    setFile(file)
    setFileConfirmationModalOpen(true)
  }

  const handleFileConfirmationModalClose = () => {
    setFileConfirmationModalOpen(false)
  }

  const handleDocumentTypeChange = (doc: ClientNciPriorityDto, count: number) => {
    handleChoosePreviousDoc(doc, count)
    setChooseAnotherModalOpen(false)
  }

  useEffect(() => {
    setValues({
      ...values,
      files: [],
      documents: [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.documentTypeId])

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.documents])

  useEffect(() => {
    if (documentOptions.length) {
      setShowChooseAnotherDoc(true)
    } else {
      setShowChooseAnotherDoc(false)
    }
  }, [accountNciAvailablePriorities.final, documentOptions])

  const isEUEntity = useSessionEntity() === TickmillCompaniesEnum.TICKMILL_EU
  const isNationalityUK = account?.nationalities.map((x) => x.id).includes('GBR')

  return (
    <React.Fragment>
      {isReasonModalOpen && (
        <DocumentReasonModal
          onSubmit={handleSubmitReason}
          onCancel={() => setReasonModalOpen(false)}
          documentType={props.accountNciAvailablePriorities.nciRule.shortName || ''}
        />
      )}
      {isConfirmationModalOpen && (
        <DocumentConfirmationModal
          onConfirm={onSkipDocuments}
          onCancel={() => setConfirmationModalOpen(false)}
        />
      )}
      {isFileConfirmationModalOpen && (
        <DocumentFileConfirmationModal
          onConfirm={handleDocumentRemove}
          onCancel={handleFileConfirmationModalClose}
        />
      )}
      {isChooseAnotherModalOpen && (
        <DocumentChooseAnotherModal
          documentOptions={documentOptions}
          onCancel={() => setChooseAnotherModalOpen(false)}
          onConfirm={(doc: ClientNciPriorityDto, count: number) =>
            handleDocumentTypeChange(doc, count)
          }
        />
      )}
      <Form onSubmit={handleSubmit}>
        <TextH3>{t('Sign up.NCI: Process')}</TextH3>
        <Text isParagraph>
          {t('Sign up.Please be informed that your Primary Nationality is')}{' '}
          {props.accountNciAvailablePriorities.country.name}.{' '}
          {t('Sign up.If you have any reservations, please contact', {
            entity: isEUEntity ? 'eu' : 'co.uk',
          })}
        </Text>
        <br />
        <Text isParagraph className='pb-5'>
          {t('Sign up.In accordance with the new regulations of the Markets in', {
            entity: isEUEntity ? t('Europe') : t('UK'),
          })}
        </Text>
        <div className='pb-5'>
          {entity === TickmillCompaniesEnum.TICKMILL_UK && isNationalityUK && (
            <div className='pb-3'>
              <h4 className={styles.textStrong}>
                {t('Sign up.Please provide your UK National Insurance Number')}
              </h4>
            </div>
          )}
          <DocumentNumberFieldFactory
            accountNciAvailablePriorities={props.accountNciAvailablePriorities}
            handleChooseAnotherDocument={
              showChooseAnotherDoc ? handleChooseAnotherDocument : undefined
            }
          />
          <div className='pb-5'>
            <div className='pb-3'>
              <h4 className={styles.textStrong}>{t('Sign up.Proof of ID-Document')}</h4>
            </div>
            <div className='pb-4'>
              <Text isParagraph>{t('Sign up.An identification document')}</Text>
            </div>
            <div>
              <div className='pb-3'>
                <DocumentFileUploadsFactory
                  files={values.files}
                  documentTypeId={values.documentTypeId}
                  documentCategory={documentCategory}
                  onChange={handleDocumentUploads}
                  onBeforeRemove={handleDocumentBeforeRemove}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='is-flex is-justify-content-center'>
          <Button
            onClick={() => setConfirmationModalOpen(true)}
            className={styles.button}
            type='button'
            appearance='secondary'
            size='L'
            fullWidth
          >
            {t('Skip')}
          </Button>
          <Button
            type='submit'
            appearance='primary'
            size='L'
            disabled={!isValid || isFormSubmitting}
            onClick={handleSubmitClick}
            className={styles.button}
            fullWidth
          >
            {t('Submit')}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  )
}

interface OuterProps {
  accountNciAvailablePriorities: ClientNciPriorityDto

  documentCategory: UploadDocumentCategoryEnum

  onSkip(): void

  onSkipDocuments(): void

  handleSubmitReason(reason: string): void

  handleChoosePreviousDoc(doc: ClientNciPriorityDto, count: number): void

  handleSubmit(values: ClientCreateNciFinalHistory, documents: CreateClientDocument[]): void
}

function dataToSubmit(
  values: DocumentInfoPageFormValuesStep1,
  accountNciAvailablePriorities: ClientNciPriorityDto
): ClientCreateNciFinalHistory {
  return {
    documentTypeId: Number(values.documentTypeId),
    nciDocumentNumber: values.nciDocumentNumber,
    nciPriorityId: accountNciAvailablePriorities.id,
    countryId: accountNciAvailablePriorities.country.id,
    statusId: ClientNciHistoryStatus.PENDING,
  }
}

export const DocumentInfoNCIPageStep1Form = withFormik<OuterProps, DocumentInfoPageFormValuesStep1>(
  {
    mapPropsToValues: ({ accountNciAvailablePriorities }) => {
      const [documentType] = accountNciAvailablePriorities.documentTypes
      return {
        nciDocumentNumber: '',
        files: [],
        documentTypeId: documentType?.documentTypeId.toString() || '',
        documents: [],
      }
    },
    handleSubmit: async (values, { props, setSubmitting }) => {
      try {
        await props.handleSubmit(
          dataToSubmit(values, props.accountNciAvailablePriorities),
          values.documents
        )
      } finally {
        setSubmitting(false)
      }
    },
    validate: (values, props) => {
      const errors: FormikErrors<DocumentInfoPageFormValuesStep1> = {}

      if (values.nciDocumentNumber === '') {
        errors.nciDocumentNumber = t('Validation.Required')
      }

      const documents = values.documents.filter((x) => !!x?.filename).filter((x) => !x.error)
      if (
        documents.length <=
        (isDoubleSidedDocument(props.documentCategory, Number(values.documentTypeId)) ? 1 : 0)
      ) {
        errors.documents = t('Validation.Required')
      }

      if (values.documents.some((doc) => doc.error)) {
        errors.documents = t('Validation.Required')
      }

      return errors
    },
    enableReinitialize: true,
    isInitialValid: false,
  }
)(DocumentInfoNCIPageStep1FormUI)
