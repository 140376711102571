import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage, useSessionSettings } from '../../global/context/SessionSettingsContext'
import { CalendarIcon } from '../../icons/CalendarIcon'
import { DarkLightModeIcon } from '../../icons/DarkLightModeIcon'
import { EmailIcon } from '../../icons/EmailIcon'
import { LandingPageIcon } from '../../icons/LandingPageIcon'
import { NotificationIcon } from '../../icons/NotificationIcon'
import { TradingAccountsEuroIcon } from '../../icons/TradingAccountsEuroIcon'
import { WebIcon } from '../../icons/WebIcon'
import {
  AccountDetailedStatus,
  isAccountDetailedActivatedStatus,
} from '../../model/AccountDetailedDto'
import { WalletCurrency } from '../../model/WalletDto'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { isTickmillUK } from '../../utils/companyName.utils'
import { useNotificationFunctionality } from '../../utils/notifications'
import { useFetchOne } from '../../utils/useFetch'
import { wait } from '../../utils/wait'
import { DocumentsPending } from '../DocumentManagement/DocumentsPending'
import { AccountSettingsBox } from './AccountSettingsBox'
import { ChangeEmailForm, ChangeEmailFormValues } from './ChangeEmailForm'
import {
  CommunicationLanguageForm,
  CommunicationLanguageFormValues,
} from './CommunicationLanguageForm'
import { DateFormatForm, DateFormatFormValues } from './DateFormatsForm'
import {
  CurrencyInfoModal,
  DisplayCurrencyForm,
  DisplayCurrencyFormValues,
} from './DisplayCurrencyForm'
import { LandingPageForm, LandingPageFormValues } from './LandingPageForm'
import { NotificationPreferencesBlock } from './NotificationPreferences'
import { themeOptions } from './SettingConsts'

import styles from './AccountSettingsPage.module.scss'

enum LoadingKey {
  Newsletter,
  Language,
  DateFormat,
  Currency,
  LandingPage,
}

export const AccountSettingsPage: React.FC = () => {
  const { t } = useTranslation()

  const apiClient = useApiClient(ClientApiClient)
  const [auth, setAuth] = useContext(AuthSessionContext)
  const locale = useSessionLanguage()

  const { refreshAccount } = useAccountWriteContext()
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()
  const [settings, setSettings] = useSessionSettings()
  const { enableNotifications } = useNotificationFunctionality()
  const [currencyModal, setCurrencyModal] = useState(false)
  const [componentLoading, setComponentLoading] = useState<LoadingKey[]>([])

  const languagesCallback = useCallback(async () => {
    return apiClient.getClientAreaLanguages({ forSignIn: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const landingPageCallback = useCallback(async () => {
    return apiClient.getLandingPageOptions(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])
  const dateFormatsCallback = useCallback(async () => {
    return apiClient.getClientDateFormats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: languages = [] } = useFetchOne(languagesCallback)

  const filteredLanguages = languages.filter(
    (language) => language.clientAreaVisible || language.id === account?.communicationLanguage.id
  )

  const { data: landingPages = [], isLoading: isLandingPageLoading } =
    useFetchOne(landingPageCallback)
  const { data: dateFormats = [] } = useFetchOne(dateFormatsCallback)

  const currenciesCallback = useCallback(() => apiClient.getDisplayCurrencies(), [apiClient])

  const { data: currencies = [], isLoading: isCurrenciesLoading } =
    useFetchOne<WalletCurrency[]>(currenciesCallback)

  const loadingCall = async (calls: (() => Promise<void>)[], key: LoadingKey) => {
    try {
      setComponentLoading((currentLoading) => [...currentLoading, key])
      for (const call of calls) {
        await call()
      }
    } finally {
      setComponentLoading((currentLoading) => currentLoading.filter((x) => x !== key))
    }
  }

  const handleSubmitNewsletter = async (values: ChangeEmailFormValues) => {
    await loadingCall(
      [() => apiClient.updateNewsletter(values.newsletterChoice), () => refreshAccount(locale)],
      LoadingKey.Newsletter
    )
  }

  const handleSubmitCommunicationLanguage = async (values: CommunicationLanguageFormValues) => {
    if (account) {
      await loadingCall(
        [
          () => apiClient.updateCommunicationLanguage(account?.id, values),
          () => refreshAccount(locale),
        ],
        LoadingKey.Language
      )
    }
  }

  const handleSubmitDateFormat = async (values: DateFormatFormValues) => {
    if (account) {
      await loadingCall(
        [() => apiClient.updateClientDateFormat(account?.id, values)],
        LoadingKey.DateFormat
      )
    }
    const newDateFormat = dateFormats.find(
      (dateFormat) => dateFormat.id.toString() === values.dateFormatTypeId
    )
    if (auth && newDateFormat) {
      const updatedAuth = {
        ...auth,
        dateFormatType: newDateFormat,
      }
      setAuth(updatedAuth)
      localStorage.setItem('auth', JSON.stringify(updatedAuth))
    }
  }

  const handleSubmitDisplayCurrency = async (values: DisplayCurrencyFormValues) => {
    await loadingCall(
      [
        () => apiClient.updateDisplayCurrency(values.displayCurrencyId),
        () => refreshAccount(locale),
      ],
      LoadingKey.Currency
    )
  }

  const handleSubmitLandingPage = async (values: LandingPageFormValues) => {
    if (values.landingPageId) {
      await loadingCall(
        [
          () => apiClient.updateLandingPage(values.landingPageId!),
          () => wait(),
          () => refreshAccount(locale),
        ],
        LoadingKey.LandingPage
      )
    }
  }

  const accountStatus = account?.status.id || -1
  const isAccountActivated = isAccountDetailedActivatedStatus(accountStatus)
  const isAPTestFailed = account?.isAppropriatenessTestAccountFailed[product]
  const isAccountClosed = accountStatus === AccountDetailedStatus.Closed
  const isAccountDormant = accountStatus === AccountDetailedStatus.Dormant
  const showDocPending =
    !isAccountActivated &&
    !isAPTestFailed &&
    !isAccountClosed &&
    !isAccountDormant &&
    !account?.personalId

  return (
    <Loading isLoading={isCurrenciesLoading}>
      <CurrencyInfoModal isOpen={currencyModal} close={() => setCurrencyModal(false)} />
      {showDocPending && <DocumentsPending />}
      <div className={styles.boxWrapper}>
        {isTickmillUK(account) && (
          <AccountSettingsBox
            title={t('Profile.Landing Page')}
            icon={<LandingPageIcon size={28} />}
          >
            <Loading
              asOverlay
              showLoadingIcon
              isLoading={isLandingPageLoading || componentLoading.includes(LoadingKey.LandingPage)}
            >
              <LandingPageForm
                landingPageOptions={landingPages}
                landingPage={account?.preferredLandingPage?.id}
                onSubmit={handleSubmitLandingPage}
              />
            </Loading>
          </AccountSettingsBox>
        )}

        <AccountSettingsBox
          title={t('Display Currency')}
          icon={<TradingAccountsEuroIcon />}
          infoToggle={() => setCurrencyModal(true)}
        >
          <Loading
            asOverlay
            showLoadingIcon
            isLoading={componentLoading.includes(LoadingKey.Currency)}
          >
            <DisplayCurrencyForm
              currencies={currencies}
              displayCurrency={account?.displayCurrency?.id}
              onSubmit={handleSubmitDisplayCurrency}
            />
          </Loading>
        </AccountSettingsBox>

        <AccountSettingsBox
          title={t('Profile.Color Mode')}
          icon={<DarkLightModeIcon size={28} />}
          isList
        >
          {themeOptions(t).map((x) => (
            <li className={styles.themeOption} key={x.name}>
              <label className='radio'>{x.name}</label>
              <input
                type='radio'
                value={x.option}
                defaultChecked={settings.theme === x.option}
                onClick={() =>
                  setSettings({
                    theme: x.option,
                  })
                }
                name='sessionTheme'
              />
            </li>
          ))}
        </AccountSettingsBox>

        <AccountSettingsBox
          title={t('Profile.Newsletters')}
          icon={<EmailIcon size={28} color={'text'} />}
        >
          <Loading
            asOverlay
            showLoadingIcon
            isLoading={componentLoading.includes(LoadingKey.Newsletter)}
          >
            <ChangeEmailForm
              newsletterChoice={account?.shouldReceiveNewsletter}
              onSubmit={handleSubmitNewsletter}
            />
          </Loading>
        </AccountSettingsBox>

        <AccountSettingsBox
          title={t('Profile.Communication Language')}
          icon={<WebIcon size={28} />}
        >
          <Loading
            asOverlay
            showLoadingIcon
            isLoading={componentLoading.includes(LoadingKey.Language)}
          >
            <CommunicationLanguageForm
              communicationLanguageId={account?.communicationLanguage.id}
              languages={filteredLanguages}
              onSubmit={handleSubmitCommunicationLanguage}
            />
          </Loading>
        </AccountSettingsBox>

        <AccountSettingsBox title={t('Profile.Date Format')} icon={<CalendarIcon size={28} />}>
          <Loading
            asOverlay
            showLoadingIcon
            isLoading={componentLoading.includes(LoadingKey.DateFormat)}
          >
            <DateFormatForm
              dateFormatTypeId={auth?.dateFormatType?.id}
              dateFormats={dateFormats}
              onSubmit={handleSubmitDateFormat}
            />
          </Loading>
        </AccountSettingsBox>

        {enableNotifications && (
          <AccountSettingsBox
            title={t('Notifications.Notifications')}
            icon={<NotificationIcon size={28} />}
          >
            <NotificationPreferencesBlock />
          </AccountSettingsBox>
        )}
      </div>
    </Loading>
  )
}
