import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Modal } from '../../global/modal/Modal'
import { VideoSelectorModal } from '../../global/modal/VideoSelectorModal'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { TMGenericLogo } from '../../icons/Logos/TMGenericLogo'
import { LearningVideoDto, TutorialGroupEnum, VideoTutorial } from '../../model/LearningVideoDto'
import { NameDto } from '../../model/NameDto'
import { Grid } from '../../ui/Layouts/Grid/Grid'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { Text, TextH3, TextSmall, TextStrong } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'
import { scrollToTop, useScrollToTop } from '../../utils/useScrollToTop'

import styles from './LearningVideos.module.scss'

export interface VideoItem {
  id: string
  title: string
  image?: string
  video: string
  duration?: string
}

interface LearningVideosProps {
  fetchVideos: () => Promise<LearningVideoDto[]>
  title: string
  showGroups?: boolean
}

export const LearningVideos: React.FC<LearningVideosProps> = (props) => {
  useScrollToTop()
  const { fetchVideos, title } = props
  const { t } = useTranslation()

  const videoRef = useRef<HTMLDivElement>(null)

  const isMobile = useWindowResize()

  const [selectedVideo, setSelectedVideo] = useState<VideoTutorial>()
  const [selectedGroup, setSelectedGroup] = useState<NameDto<TutorialGroupEnum>>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getVideosCallback = useCallback(async () => await fetchVideos(), [])

  const { data: videoGroups = [] } = useFetchOne(getVideosCallback)

  useEffect(() => {
    if (videoRef.current) {
      const elementRect = videoRef.current.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.scrollY
      if (isMobile) {
        scrollToTop({ top: absoluteElementTop - 80 })
      } else {
        videoRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [selectedVideo, isMobile])

  if (selectedVideo) {
    return (
      <>
        <PageHeader
          title={
            <>
              <span className={styles.textSecondary}>{selectedGroup?.name} / </span>
              <span>{selectedVideo.title}</span>
            </>
          }
          backButton={() => {
            setSelectedVideo(undefined)
            setSelectedGroup(undefined)
          }}
        />
        <InnerLearningVideos
          videos={videoGroups.flatMap((videos) => videos.tutorials || [])}
          onSelectVideo={(video) => setSelectedVideo(video)}
          selectedVideo={selectedVideo}
        />
      </>
    )
  }

  return (
    <div>
      <PageHeader title={title} />
      {videoGroups?.map(({ tutorials, tutorialGroup }) => (
        <>
          {videoGroups.length > 1 && (
            <div className={styles.groupTitle}>
              <TextH3>{tutorialGroup.name}</TextH3>
              <p className={classNames('text', styles.textSecondary)}>
                {tutorialGroup.id === TutorialGroupEnum.IntroducingToFutures &&
                  t(
                    'Learning.Going from the basics to complex trading techniques, our educational videos equip you to enhance your trading'
                  )}
                {tutorialGroup.id === TutorialGroupEnum.UnderstandingFutures &&
                  t('Learning.Why do people trade Futures')}
                {tutorialGroup.id === TutorialGroupEnum.KeyEconomic &&
                  t(
                    'Learning.Learn about the key drivers of the markets with our in depth video coverage'
                  )}
              </p>
            </div>
          )}
          <Grid>
            {tutorials?.map((item, idx) => (
              <div
                key={idx}
                className={styles.videoBox}
                onClick={() => {
                  setSelectedGroup(tutorialGroup)
                  setSelectedVideo(item)
                }}
              >
                <div className={styles.placeholder}>
                  <div className={styles.innerBox}>
                    <div className={styles.logoBox}>
                      <TMGenericLogo />
                    </div>
                    <hr className={styles.separator} />
                    <Text className={styles.label}>{t('Learning.Tutorial Video')}</Text>
                  </div>
                </div>
                <TextStrong isParagraph>{item.title}</TextStrong>
                {item.duration && (
                  <TextSmall isParagraph className={styles.textSecondary}>
                    {item.duration}
                  </TextSmall>
                )}
              </div>
            ))}
          </Grid>
        </>
      ))}
    </div>
  )
}

interface InnerLearningVideosProps {
  videos: VideoTutorial[]
  onSelectVideo: (video: VideoTutorial) => void
  selectedVideo?: VideoTutorial
}

export const InnerLearningVideos: React.FC<InnerLearningVideosProps> = (props) => {
  const { videos, onSelectVideo, selectedVideo } = props
  const [modal, setModal] = useState(false)
  const isMobile = useWindowResize()

  const OtherVideos = () =>
    isMobile ? (
      <div onClick={() => setModal(true)} className={styles.mobileOtherVideosBox}>
        <TextH3>{selectedVideo?.title}</TextH3>
        <div className={styles.selectorBox}>
          <TextSmall className={styles.duration}>{selectedVideo?.duration}</TextSmall>
          <ChevronDownIcon />
        </div>
      </div>
    ) : (
      <div className={styles.otherVideosBox}>
        {videos.map((item) => (
          <div
            onClick={() => onSelectVideo(item)}
            key={item.tutorialLink}
            className={classNames(styles.otherVideo, {
              [styles.selected]: item.tutorialLink === selectedVideo?.tutorialLink,
            })}
          >
            <div className={styles.nameBox}>
              <TextSmall className={styles.name}>{item.title}</TextSmall>
              <TextSmall className={styles.duration}>{item.duration}</TextSmall>
            </div>
          </div>
        ))}
      </div>
    )

  return (
    <div className={styles.innerLearningVideos}>
      {modal && (
        <Modal
          closeModal={() => setModal(false)}
          render={() => (
            <VideoSelectorModal
              onCancel={() => setModal(false)}
              items={videos}
              onSelect={(item) => onSelectVideo(item)}
              selectedItem={selectedVideo}
            />
          )}
        />
      )}
      <OtherVideos />
      <div className={styles.videoBox}>
        {!isMobile && <TextH3>{selectedVideo?.title}</TextH3>}
        <div className={styles.video}>
          {selectedVideo?.tutorialLink &&
            (selectedVideo.tutorialLink.includes('players.') ? (
              <iframe
                title={selectedVideo.title}
                allow='autoplay'
                allowFullScreen
                width='100%'
                height='100%'
                src={selectedVideo.tutorialLink}
              />
            ) : (
              <video preload='auto' controls autoPlay src={selectedVideo.tutorialLink} />
            ))}
        </div>
      </div>
    </div>
  )
}
