import { NameDto } from './NameDto'
import {
  TickmillProductType,
  isTickmillProductTypeCFD,
  isTickmillProductTypeETD,
} from './TickmillProductType'

export interface RegulatorOptionDto {
  country: {
    id: string
    name: string
    shortName?: string
  }
  regulators: RegulatorDto[]
}

export interface RegulatorDto {
  tickmillCompanyId: number
  title: string
  name: string
  options: RegulatorOptionItemDto[]
  optionsPopup: string[]
  link: string
  popup: boolean
  popupText: string[] | null
  warnings: string[]
  redirect: boolean
  preSelected: boolean
  products: RegulatorProductOption[]
}

export interface RegulatorProductOption {
  id: TickmillProductType
  name: 'FOREX & CFDs' | 'FUTURES & OPTIONS'
  depositLimitText: string
  optionPopup: string[]
}

export interface RegulatorOptionItemDto {
  headerString: string | null
  optionText: string
  optionType: NameDto
  showIcon: boolean
  showHeaderString: boolean
}

export const isRegulatorProductCFD = (regulator: RegulatorDto | RegulatorDto[]): boolean => {
  if (Array.isArray(regulator)) {
    const productIds = regulator.map((x) => x.products.map((x) => x.id))
    return isTickmillProductTypeCFD(productIds.flat())
  }
  return isTickmillProductTypeCFD(regulator.products.map((x) => x.id))
}

export const isRegulatorProductETD = (regulator: RegulatorDto | RegulatorDto[]) => {
  if (Array.isArray(regulator)) {
    const productIds = regulator.map((x) => x.products.map((x) => x.id))
    return isTickmillProductTypeETD(productIds.flat())
  }
  return isTickmillProductTypeETD(regulator.products.map((x) => x.id))
}

export const isRegulatorProduct = (regulator: RegulatorDto[]) => {
  return isRegulatorProductCFD(regulator) && isRegulatorProductETD(regulator)
}

export enum RegulatorOptionType {
  Success = 1,
  Error = 2,
}

export const isRegulatorOptionSuccessType = (option: RegulatorOptionItemDto) => {
  return option.optionType.id === RegulatorOptionType.Success
}

export const isRegulatorOptionErrorType = (option: RegulatorOptionItemDto) => {
  return option.optionType.id === RegulatorOptionType.Error
}
