import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { SelectModal, SelectModalOption } from '../../../../global/field/SelectModal'
import { Modal } from '../../../../global/modal/Modal'
import { NameDto } from '../../../../model/NameDto'
import { DocumentInfoPageProofOfAddressStepFormValues } from './DocumentInfoPageProofOfAddressStepForm'

interface DocumentTypeModalProps {
  onSelectOption(option: NameDto): void

  onClose(): void
}

export const DocumentTypeModal: React.FC<DocumentTypeModalProps> = (props) => {
  const { onSelectOption, onClose } = props

  const { values, setValues } = useFormikContext<DocumentInfoPageProofOfAddressStepFormValues>()

  const handleSelectOption = (document: NameDto) => {
    setValues({
      ...values,
      documentType: {
        id: document.id.toString(),
        name: document.name,
      },
    })
    onSelectOption(document)
  }

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Sign up.Document Type')}
          renderOptions={() => (
            <div className='control'>
              {values.documentTypes?.types.map((x) => (
                <SelectModalOption
                  label={x.name}
                  value={values.documentType.id === x.id.toString()}
                  onClick={() => handleSelectOption(x)}
                  key={x.id}
                />
              ))}
            </div>
          )}
        />
      )}
    />
  )
}
