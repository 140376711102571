import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { format, parse } from 'date-fns'

import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { NameDto } from '../../model/NameDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate, getLastBusinessDayOfMonth } from '../../utils/date.utils'

interface Props {
  dueDate?: string
  handleOnClosePendingUnsubscriptionModal(): void
}

export const PendingUnsubscriptionModal: React.FC<Props> = ({
  handleOnClosePendingUnsubscriptionModal,
  dueDate,
}) => {
  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const formattedDueDate = getDates(auth?.dateFormatType, dueDate)
  return (
    <Modal
      closeModal={handleOnClosePendingUnsubscriptionModal}
      render={() => (
        <InformationModal
          onCancel={handleOnClosePendingUnsubscriptionModal}
          title={t('Subscriptions.Pending Unsubscription')}
          onCancelText={t('Cancel')}
        >
          <p className='text'>{t('Market Data.Pending Unsubscription Message.line1')}</p>
          <p className='text mb-2'>
            <Trans
              i18nKey={'Market Data.Pending Unsubscription Message.line2'}
              values={{ dueDate: formattedDueDate }}
              components={[<strong />]}
            />
          </p>

          <p className='text'>{t('Market Data.Pending Unsubscription Message.line3')}</p>
        </InformationModal>
      )}
    />
  )
}

const getDates = (dateFormatType?: NameDto, dueDate?: string): string => {
  if (!dueDate) {
    return ''
  }

  const dueDateFormatted = formatDate(dueDate || '', 'dd.MM.yyyy')

  const dateFormat = dateFormatType?.name ?? ''
  const parsedDueDate = parse(dueDateFormatted, 'dd.MM.yyyy', new Date())

  const lastBusinessDayOfMonth = getLastBusinessDayOfMonth().getDate(parsedDueDate)
  return format(lastBusinessDayOfMonth, dateFormat || 'yyyy-MM-dd')
}
