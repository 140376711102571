import { useCallback } from 'react'

import { useResizeEffect } from '../hooks/useResizeEffect'

export const useScrollbarWidth = (): void => {
  const handleChange = useCallback(() => {
    const container = document.createElement('div')
    document.body.appendChild(container)

    container.style.overflow = 'scroll'
    container.style.width = '50px'
    container.style.height = '50px'

    const inner = document.createElement('div')
    container.appendChild(inner)

    inner.style.width = '100%'
    inner.style.height = '100%'

    const scrollbarWidth = container.offsetWidth - inner.offsetWidth

    document.body.removeChild(container)
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`)
  }, [])

  useResizeEffect(handleChange)
}
