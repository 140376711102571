import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../global/button/Button'
import { Text } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'

import styles from './DeclinedClassificationTest.module.scss'

interface Props {
  handleOkOnClick: () => void
}

export const DeclinedClassificationTest: React.FC<Props> = ({ handleOkOnClick }) => {
  const { account } = useAccountReadContext()

  const { t } = useTranslation()

  return (
    <div className={styles.card}>
      <h1>{t('Profile.Classification test unsuccessful!')}</h1>
      <Text isParagraph>
        {t(
          'Thank you for submitting your application to be re-classified as a Professional Client',
          { name: account?.classification.name }
        )}
      </Text>
      <Button size='L' appearance='secondary' onClick={handleOkOnClick}>
        {t('OK')}
      </Button>
    </div>
  )
}
