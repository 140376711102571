import { ReactNode, createContext, useEffect, useMemo, useState } from 'react'

import { useGuardedContext } from '../global/useGuardedContext'
import { DateFilterPresets, IDateFilter, getDateTime } from './filter.utils'

interface DateFilterReadContextImpl {
  currentFilter?: IDateFilter
  isCurrentFilterActive: (id: number) => boolean

  isCustomFilter(): boolean
}

interface DateFilterWriteContextImpl {
  addToFilter: (f: number, d1?: string, d2?: string) => void
  clearFilter: () => void
}

const DateFilterReadContext = createContext<DateFilterReadContextImpl | undefined>(undefined)
DateFilterReadContext.displayName = 'DateFilterReadContext'

const DateFilterWriteContext = createContext<DateFilterWriteContextImpl | undefined>(undefined)
DateFilterWriteContext.displayName = 'DateFilterWriteContext'

export function useDateFilterReadContext(): DateFilterReadContextImpl {
  return useGuardedContext(DateFilterReadContext)
}

export function useDateFilterWriteContext(): DateFilterWriteContextImpl {
  return useGuardedContext(DateFilterWriteContext)
}

interface Props {
  filter?: IDateFilter
  children: ReactNode
}

export function DateFilterContextProvider({ filter, children }: Props) {
  const [dateFilterState, setDateFilterState] = useState(filter)
  useEffect(() => {
    setDateFilterState(filter)
  }, [filter]) // reset
  const readContext = useMemo<DateFilterReadContextImpl>(
    () => ({
      currentFilter: Array.isArray(dateFilterState?.value)
        ? dateFilterState?.value.filter((x) => !!x).length
          ? dateFilterState
          : undefined
        : dateFilterState,
      isCurrentFilterActive: (id) => dateFilterState?.id === id,
      isCustomFilter: () => dateFilterState?.id === DateFilterPresets.Custom,
    }),
    [dateFilterState]
  )

  const writeContext = useMemo<DateFilterWriteContextImpl>(() => {
    return {
      addToFilter(id, date1?: string, date2?: string) {
        // means custom
        const custom = getDateTime().find((wi) => wi.id === DateFilterPresets.Custom)
        if (custom && (date1 || date2)) {
          if (date1) {
            return setDateFilterState({
              ...custom,
              value: [date1, dateFilterState?.value[1] || ''],
            })
          } else if (date2) {
            return setDateFilterState({
              ...custom,
              value: [dateFilterState?.value[0] || '', date2],
            })
          }
        }
        if (dateFilterState?.id === id) {
          return setDateFilterState(undefined)
        }
        const filter = getDateTime().find((wi) => wi.id === id)
        if (filter) {
          return setDateFilterState(filter)
        }
      },
      async clearFilter() {
        setDateFilterState(undefined)
      },
    }
  }, [dateFilterState])

  return (
    <DateFilterReadContext.Provider value={readContext}>
      <DateFilterWriteContext.Provider value={writeContext}>
        {children}
      </DateFilterWriteContext.Provider>
    </DateFilterReadContext.Provider>
  )
}
