import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CopyIcon } from '../../icons/CopyIcon'
import { CopyLink } from '../copyLink/CopyLink'
import IconButton, { IconButtonProps } from '../iconButton/IconButton'

import styles from './CopyButton.module.scss'

export type BorderVariant = 'bordered' | 'borderless'

export interface CopyButtonProps extends Omit<IconButtonProps, 'size' | 'appearance'> {
  label?: string
  value: string
  borderVariant?: BorderVariant
}

export const CopyButton: React.FC<CopyButtonProps> = (props) => {
  const { t } = useTranslation()

  const { label = t('Text/link copied!'), value, borderVariant = 'bordered' } = props

  return (
    <CopyLink label={label} value={value}>
      <IconButton
        {...props}
        className={classNames(styles.button, {
          [styles.bordered]: borderVariant === 'bordered',
          [styles.borderless]: borderVariant === 'borderless',
        })}
      >
        <CopyIcon size={20} />
      </IconButton>
    </CopyLink>
  )
}
