import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CheckIcon } from '../../icons/CheckIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { isAccountActivatedStatus } from '../../model/BankAccount'
import { TextSmall } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'

import styles from './PhoneNumberStatus.module.scss'

interface PhoneNumberStatusProps {
  isVerified?: boolean
  verify?: () => void
  showIcon?: boolean
  shouldVerify?: boolean
}

export const PhoneNumberStatus: React.FC<PhoneNumberStatusProps> = ({
  isVerified,
  verify,
  showIcon,
  shouldVerify,
}) => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  if (isVerified) {
    return (
      <TextSmall className={classNames(styles.status, styles.verified)}>
        {showIcon && <CheckIcon size={16} color={'success'} />}
        {t('PhoneNumbers.Verified')}
      </TextSmall>
    )
  } else if (shouldVerify && isAccountActivatedStatus(account)) {
    return (
      <TextSmall onClick={verify} className={classNames(styles.status, styles.pending)}>
        {showIcon && <WarningIcon size={16} color={'textInteractive'} />}
        {t('PhoneNumbers.Verify')}
      </TextSmall>
    )
  } else {
    return null
  }
}
