import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ClientIbContestCampaignResultDto } from '../../model/ClientIbContestCampaignResultDto'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { getScrollToCardId } from '../../utils/getItemId'

import styles from './IBContestPage.module.scss'

interface IBContestResultsCardsProps {
  sortName: string | undefined
  setSortModalOpen(value: boolean): void
  data: ClientIbContestCampaignResultDto[]
}

export const IBContestResultsCards: React.FC<IBContestResultsCardsProps> = (props) => {
  const { data } = props

  const { t } = useTranslation()
  const { account } = useAccountReadContext()

  if (!data.length) {
    return <div className={styles.noResult}>{t('No results')}</div>
  }

  return (
    <>
      <div className='mt-5'>
        {data.map((x, index) => (
          <IBContestResultsCard
            data={x}
            selected={account?.clientIntroducingBroker?.name === x.ibCode}
            key={x.ibCode}
            cardId={getScrollToCardId(index, data.length, 'ib-contest-results')}
          />
        ))}
      </div>
    </>
  )
}

interface IBContestResultsCardProps {
  data: ClientIbContestCampaignResultDto
  selected: boolean
  cardId?: string
}

const IBContestResultsCard: React.FC<IBContestResultsCardProps> = (props) => {
  const { data, selected, cardId } = props

  const { t } = useTranslation()

  return (
    <div
      className={classNames(styles.result, {
        [styles.selected]: selected,
      })}
      id={cardId}
    >
      <div className='is-flex is-justify-content-space-between mb-2'>
        <p className={styles.textSmall}>
          <span className='text-strong'>#{data.ranking}</span>
        </p>
        <p>
          <span className={styles.textSmall}>
            <span className='text-strong'>{data.ibCode}</span>
          </span>
        </p>
      </div>
      <div className='is-flex is-justify-content-space-between'>
        <p className={styles.textSmall}>
          <span className={classNames('text-secondary', styles.label)}>
            <>{t('IB.Clients')}: </>
          </span>
          {data.numberOfClients}
        </p>
        <p>
          <span className={styles.textSmall}>
            <span className={classNames('text-secondary', styles.label)}>
              <>{t('IB.Points')}:</>
            </span>{' '}
            {data.points}
          </span>
        </p>
      </div>
    </div>
  )
}
