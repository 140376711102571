import { useProductReadContext } from '../global/context/ProductContext'
import { isKYCUpdateDue } from '../model/AccountDetailedDto'
import { CFDLandingPageID, ETDLandingPageID, LandingPageMapping } from '../model/LandingPageDto'
import { TickmillProductType } from '../model/TickmillProductType'
import { useAccountReadContext } from './AccountContextContext'
import { useFirstDepositContext } from './FirstDepositContext'
import { isTickmillPartner } from './companyName.utils'
import { isTradersRoomRouteAllowed } from './permission.utils'

export const useLandingPage = (): {
  getLandingPage: (overWriteProduct?: TickmillProductType) => string
} => {
  const { account } = useAccountReadContext()
  const { hasFirstDeposit } = useFirstDepositContext()

  const { product } = useProductReadContext()

  const getLandingPage = (overWriteProduct?: TickmillProductType) => {
    const tMProduct = overWriteProduct ?? product
    const FIRST_DEPOSIT_PAGE = '/dashboard/first-deposit'
    const KYC_UPDATE_PAGE = '/profile/kyc-update-details'
    const DEFAULT_ETD_PAGE = '/dashboard/traders-room/balances'
    const DEFAULT_CFD_PAGE = isTradersRoomRouteAllowed(account)
      ? '/dashboard/traders-room/wallets'
      : '/dashboard/introducing-broker/wallets'

    const hasDismissedFirstDepositPrompt = localStorage.getItem('hasDismissedFirstDepositPrompt')

    if (isKYCUpdateDue(account)) {
      return KYC_UPDATE_PAGE
    }

    if (
      account &&
      !hasFirstDeposit &&
      !hasDismissedFirstDepositPrompt &&
      tMProduct === TickmillProductType.CFD &&
      !isTickmillPartner(account)
    ) {
      return FIRST_DEPOSIT_PAGE
    }

    if (
      !account?.preferredLandingPage ||
      account?.preferredLandingPage.tickmillProductId !== tMProduct
    ) {
      if (tMProduct === TickmillProductType.CFD) {
        return DEFAULT_CFD_PAGE
      }
      return DEFAULT_ETD_PAGE
    }

    const preferredLandingPage: ETDLandingPageID | CFDLandingPageID =
      account.preferredLandingPage.id

    return LandingPageMapping[preferredLandingPage]
  }

  return {
    getLandingPage,
  }
}
