import React, { PropsWithChildren, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Font, Page, StyleSheet, Text, View, pdf } from '@react-pdf/renderer'

import { TestAnswersDto } from '../../model/ClientTestAnswerDto'
import { ClientTestDto } from '../../model/ClientTestDto'
import { RegulatorOptionDto } from '../../model/RegulatorOptionDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { getQuestionAnswer } from './W8BeneficialForm'
import { CheckBoxOffSVG, CheckBoxOnSVG, TMDocLogoPDF } from './icons'

interface Props {
  taxFormAnswers: TestAnswersDto | undefined
  regulators: RegulatorOptionDto[]
  taxFormQuestions: ClientTestDto | undefined
  isArabic: boolean
}

Font.register({
  family: 'ArialUnicodeMs',
  src: `${window.location.origin}/fonts/arial-unicode-ms.ttf`,
})

Font.registerHyphenationCallback((word) => {
  const middle = Math.floor(word.length / 2)
  const parts = word.length === 1 ? [word] : [word.substring(0, middle), word.substring(middle)]

  return parts
})

export const W8BFormDocument: React.FC<Props> = (props) => {
  const { taxFormAnswers, regulators, taxFormQuestions, isArabic } = props
  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const styles = StyleSheet.create({
    page: {
      width: '100%',
      paddingLeft: 38,
      paddingRight: 38,
      paddingBottom: 24,
      paddingTop: 24,
      flex: 1,
      maxWidth: '100%',
      fontFamily: 'ArialUnicodeMs',
      alignItems: isArabic ? 'flex-end' : 'flex-start',
      textAlign: isArabic ? 'right' : 'left',
      fontSize: 12,
      direction: isArabic ? 'rtl' : 'ltr',
    },
    header: {
      fontSize: 20,
      marginBottom: 5,
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      borderBottom: '1px solid #ECECEC',
      paddingBottom: 8,
    },
    sectionTitle: {
      fontSize: 16,
      marginTop: 20,
      marginBottom: 10,
    },
    textGray: {
      color: '#868686',
      direction: isArabic ? 'rtl' : 'ltr',
    },
    text: {
      color: '#000',
      direction: isArabic ? 'rtl' : 'ltr',
    },
    textTiny: {
      fontSize: 10,
      direction: isArabic ? 'rtl' : 'ltr',
    },
    textStrong: {
      fontSize: 16,
      marginTop: 10,
      marginBottom: 10,
    },
    columns: {
      flexDirection: isArabic ? 'row-reverse' : 'row',
      justifyContent: 'flex-start',
      textAlign: isArabic ? 'right' : 'left',
      flexWrap: 'wrap',
      width: '100%',
    },
    leftColumn: {
      alignItems: isArabic ? 'flex-end' : 'flex-start',
      marginRight: isArabic ? 0 : 40,
      marginLeft: isArabic ? 40 : 0,
    },
    rightColumn: {
      alignItems: isArabic ? 'flex-end' : 'flex-start',
    },
    labelValue: {
      flexDirection: isArabic ? 'row-reverse' : 'row',
      direction: isArabic ? 'rtl' : 'ltr',
      marginBottom: 10,
      fontSize: 12,
      flexWrap: 'wrap',
    },
    row: {
      flexDirection: isArabic ? 'row-reverse' : 'row',
      direction: isArabic ? 'rtl' : 'ltr',
      marginBottom: 10,
      fontSize: 12,
    },
    subList: {
      marginLeft: isArabic ? 0 : 22,
      marginRight: isArabic ? 22 : 0,
      marginTop: -12,
      direction: isArabic ? 'rtl' : 'ltr',
      textAlign: isArabic ? 'right' : 'left',
      alignItems: isArabic ? 'flex-end' : 'flex-start',
    },
  })

  const getCountryName = (id: string) => regulators.find((r) => r.country.id === id)?.country.name

  const getQuestion = (widgetName: string) => {
    const questions = taxFormQuestions?.sections.map((section) => section.questions).flat()
    return questions?.find((question) => question.widget.name === widgetName)
  }

  const getTinType = (id: string) =>
    getQuestion('us_tin_type')?.answers.find((a) => a.id === id)?.name

  const qualifyTaxBenefit = () =>
    getQuestionAnswer(taxFormAnswers, 'certify_no_us_tax_benefits') === '1'

  const isCapacityToSign = () =>
    getQuestionAnswer(taxFormAnswers, 'certify_capacity_to_sign') === '1'

  const ListItem: React.FC<PropsWithChildren> = (props) => (
    <View style={styles.row}>
      <Text style={{ marginHorizontal: 8 }}>•</Text>
      <Text style={styles.textTiny}>{props.children}</Text>
    </View>
  )

  const LabelValue: React.FC<{ label: string; value?: string }> = (props) => (
    <View style={styles.labelValue}>
      <Text style={styles.textGray}>{props.label}: </Text>
      {props.value && <Text style={styles.text}>{props.value}</Text>}
    </View>
  )

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.text}>{t('W8.W-8BEN Form')}</Text>
          <TMDocLogoPDF />
        </View>
        <Text style={styles.textStrong}>{t('W8.Identification of Beneficial Owner')}</Text>
        <View style={styles.columns}>
          <View style={styles.leftColumn}>
            <LabelValue
              label={t('W8.Name of Individual')}
              value={getQuestionAnswer(taxFormAnswers, 'name_of_individual')}
            />
          </View>
          <View style={styles.rightColumn}>
            <LabelValue
              label={t('W8.Country of Citizenship')}
              value={getCountryName(getQuestionAnswer(taxFormAnswers, 'residence_country'))}
            />
          </View>
        </View>
        <View style={styles.columns}>
          <View style={styles.leftColumn}>
            <Text style={styles.textStrong}>{t('W8.Permanent Residence Address')}</Text>
            <LabelValue
              label={t('W8.Country')}
              value={getCountryName(getQuestionAnswer(taxFormAnswers, 'residence_country'))}
            />
            <LabelValue
              label={t('W8.Address Line')}
              value={
                getQuestionAnswer(taxFormAnswers, 'residence_city') +
                ' ' +
                getQuestionAnswer(taxFormAnswers, 'residence_street')
              }
            />
            <LabelValue
              label={t('W8.State/Region')}
              value={getQuestionAnswer(taxFormAnswers, 'residence_county')}
            />
            <LabelValue
              label={t('W8.Postal Code')}
              value={getQuestionAnswer(taxFormAnswers, 'residence_postal_code')}
            />
          </View>
          <View style={styles.rightColumn}>
            <Text style={styles.textStrong}>{t('W8.Mailing Address')}</Text>
            <LabelValue
              label={t('W8.Country')}
              value={getCountryName(getQuestionAnswer(taxFormAnswers, 'mailing_country'))}
            />
            <LabelValue
              label={t('W8.Address Line')}
              value={
                getQuestionAnswer(taxFormAnswers, 'mailing_city') +
                ' ' +
                getQuestionAnswer(taxFormAnswers, 'mailing_street')
              }
            />
            <LabelValue
              label={t('W8.State/Region')}
              value={getQuestionAnswer(taxFormAnswers, 'mailing_county')}
            />
            <LabelValue
              label={t('W8.Postal Code')}
              value={getQuestionAnswer(taxFormAnswers, 'mailing_postal_code')}
            />
          </View>
        </View>
        <Text style={styles.textStrong}>{t('W8.Tax Info')}</Text>
        <View style={styles.columns}>
          <View style={styles.leftColumn}>
            <LabelValue
              label={t('W8.Country of Tax Residence')}
              value={getCountryName(getQuestionAnswer(taxFormAnswers, 'tax_country_id'))}
            />
            <LabelValue
              label={t('W8.Country of Tax Residence TIN')}
              value={getQuestionAnswer(taxFormAnswers, 'tax_country_tin')}
            />
            <LabelValue
              label={t('W8.US TIN')}
              value={getQuestionAnswer(taxFormAnswers, 'us_tin')}
            />
            <LabelValue
              label={t('W8.US TIN Type')}
              value={getTinType(getQuestionAnswer(taxFormAnswers, 'us_tin_type'))}
            />
            <LabelValue
              label={t('Profile.Date Of Birth')}
              value={formatDate(getQuestionAnswer(taxFormAnswers, 'birthdate'), dateFormat)}
            />
          </View>
        </View>
        <Text style={styles.textStrong}>{t('W8.Claim of Treaty Benefits')}</Text>
        <View style={styles.columns}>
          <View style={styles.leftColumn}>
            <LabelValue label={t('W8.I certify that the beneficial')} />
          </View>
        </View>
        <View style={styles.columns}>
          <View style={styles.leftColumn}>
            <LabelValue
              label={t('W8.Country of Tax Residence')}
              value={getCountryName(getQuestionAnswer(taxFormAnswers, 'certify_country_id'))}
            />
          </View>
        </View>
        <View style={styles.columns}>
          <View style={styles.leftColumn}>
            <View style={styles.row}>
              {qualifyTaxBenefit() ? <CheckBoxOnSVG /> : <CheckBoxOffSVG />}
              <Text style={{ ...styles.textTiny, marginHorizontal: 10 }}>
                {t('W8.I do not qualify for US tax treaty benefits')}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size='A4' style={styles.page}>
        <Text style={styles.textStrong}>{t('W8.Certification')}</Text>
        <Text style={styles.textTiny}>{t('W8.Under penalties of perjury')}</Text>
        <Text> </Text>
        <ListItem>{t('W8.I am the individual')}</ListItem>
        <ListItem>{t('W8.The beneficial owner is not a US person;')}</ListItem>
        <ListItem>{t('W8.This form relates to')}</ListItem>
        <View style={styles.subList}>
          <Text style={styles.textTiny}>{t('W8.income not effectively connected')}</Text>
          <Text style={styles.textTiny}>{t('W8.income effectively connected')}</Text>
          <Text style={styles.textTiny}>{t("W8.the partner's share")}</Text>
          <Text style={styles.textTiny}>{t("W8.the partner's amount realized")}</Text>
        </View>
        <Text> </Text>
        <ListItem>{t('W8.The beneficial owner listed on this form is a')}</ListItem>
        <ListItem>{t('W8.For broker transactions')}</ListItem>
        <Text style={styles.textTiny}>{t('W8.Furthermore, I authorize')}</Text>
        <Text> </Text>
        <View style={styles.columns}>
          <View style={styles.leftColumn}>
            <Text style={styles.row}>
              {isCapacityToSign() ? <CheckBoxOnSVG /> : <CheckBoxOffSVG />}
              <Text style={{ ...styles.textTiny, marginHorizontal: 10 }}>
                {t('W8.I certify that I have the capacity to sign for')}
              </Text>
            </Text>
          </View>
        </View>
        <Text> </Text>
        <View style={styles.columns}>
          <View style={styles.leftColumn}>
            <Text style={styles.textGray}>{t('W8.Name of Signer')}:</Text>
            <Text>{getQuestionAnswer(taxFormAnswers, 'name_of_individual')}</Text>
            <Text> </Text>
            <Text style={styles.textGray}>{t('W8.Signature')}:</Text>
            <Text>{getQuestionAnswer(taxFormAnswers, 'certification_signature')}</Text>
          </View>
          <View style={styles.rightColumn}>
            <Text> </Text>
            <Text> </Text>
            <Text> </Text>
            <Text style={styles.textGray}>{t('W8.Date of Signing')}:</Text>
            <Text>
              {' ' +
                formatDate(getQuestionAnswer(taxFormAnswers, 'certification_date'), dateFormat)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export const useDownloadW8BForm = (props: Props): (() => Promise<void>) => {
  const { t } = useTranslation()
  const { taxFormAnswers } = props

  const handleDownloadForm = async () => {
    if (!taxFormAnswers) {
      return
    }

    const blob = await pdf(<W8BFormDocument {...props} />).toBlob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = t('W8.W-8BEN FORM') + '.pdf'
    link.click()
  }

  return handleDownloadForm
}
