import React, { FC, useCallback } from 'react'

import { Loading } from '../../global/Loading/Loading'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { AccountDetailedDto, AccountDetailedStatus } from '../../model/AccountDetailedDto'
import { TickmillProductType } from '../../model/TickmillProductType'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { useSupportContacts } from '../../utils/SupportContactsContext'
import { ClientApiClient } from '../../utils/clientApi'
import { FormSubmitValues } from '../../utils/formValidation'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { DocumentsPending } from '../DocumentManagement/DocumentsPending'
import { PersonalInfoFormPage, PersonalInfoFormValues } from './PersonalInfoPageForm'

import styles from './PersonalInfo.module.scss'

export interface PersonalInfoPageProps {
  accountPassed?: AccountDetailedDto
}

const usePersonalInfo = () => {
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const { product } = useProductReadContext()
  const entity = useSessionEntity()

  const appTestAnswersCallback = useCallback(async () => {
    return apiClient.getAccountAppTestAnswers(locale, product)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])
  const appTestAnswers = useFetchOne(appTestAnswersCallback)

  return { appTestAnswers }
}

export const PersonalInfoPage: FC<PersonalInfoPageProps> = ({ accountPassed }) => {
  useScrollToTop()
  const { product } = useProductReadContext()
  const { account } = useAccountReadContext()

  const { appTestAnswers } = usePersonalInfo()
  const { supportEmail, supportPhoneNumber } = useSupportContacts()

  const handleSubmit = async (values: FormSubmitValues<PersonalInfoFormValues>) => {
    alert(JSON.stringify(values))
  }

  return (
    <div className={styles.info}>
      <Loading isLoading={appTestAnswers.isLoading} showLoadingIcon>
        {isDocumentPendingVisible(product, account) && <DocumentsPending />}
        <PersonalInfoFormPage
          appTestAnswers={appTestAnswers.data}
          supportContacts={{ supportEmail, supportPhoneNumber }}
          account={accountPassed || account}
          onSubmit={handleSubmit}
        />
      </Loading>
    </div>
  )
}

const isDocumentPendingVisible = (product: TickmillProductType, account?: AccountDetailedDto) => {
  const accountStatus = account?.status.id || -1
  const isAccountActivated = accountStatus === AccountDetailedStatus.Activated
  const isAccountClosed = accountStatus === AccountDetailedStatus.Closed
  const isAccountDormant = accountStatus === AccountDetailedStatus.Dormant
  const isAPTestFailed = account?.isAppropriatenessTestAccountFailed[product]
  return (
    !isAccountDormant &&
    !isAccountClosed &&
    !isAccountActivated &&
    !isAPTestFailed &&
    !account?.personalId
  )
}
