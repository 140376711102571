import React from 'react'
import { useTranslation } from 'react-i18next'

import { InformationModal } from '../../global/modal/InformationModal'
import { IBTestSubCategory } from '../../model/TestSectionsDto'
import { Text, TextH3 } from '../../ui/Typography/Typography'

import styles from './BecomeIBModal.module.scss'

interface Props {
  onCancel: () => void
  subCategoryId?: IBTestSubCategory
}

export const BecomeIBModal: React.FC<Props> = ({ onCancel, subCategoryId }) => {
  const { t } = useTranslation()
  return (
    <InformationModal
      title={t('IB.Why should I become an IB?')}
      onCancelText={t('Got It')}
      onCancel={onCancel}
    >
      <div className={styles.body}>
        <div className={styles.item}>
          <TextH3>{t('IB.1 Build your client base')}</TextH3>
          <Text isParagraph>{t('IB.Recommend Tickmill to other traders or your friends')}</Text>
        </div>
        <div className={styles.item}>
          <TextH3>{t('IB.2 Earn from trades')}</TextH3>
          <Text isParagraph>
            {t(
              'IB.The more your clients trade, the bigger is your reward. The reward is calculated'
            )}
          </Text>
        </div>
        <div className={styles.item}>
          <TextH3>{t('IB.3 Manage your funds')}</TextH3>
          <Text isParagraph>{t('IB.Access and manage your funds the way you prefer')}</Text>
        </div>
      </div>
    </InformationModal>
  )
}

export default BecomeIBModal
