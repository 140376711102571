import { useTranslation } from 'react-i18next'

import { useArabicSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { BackIcon } from '../../../icons/BackIcon'
import { ForwardIcon } from '../../../icons/ForwardIcon'
import { TextH3 } from '../../../ui/Typography/Typography'

import styles from './SignupErrorStep.module.scss'

interface SignupErrorStepProps {
  goBack(): void
}

export const SignupErrorStep: React.FC<SignupErrorStepProps> = (props) => {
  const { goBack } = props

  const { t } = useTranslation()

  const isArabic = useArabicSessionLanguage()

  return (
    <section className={styles.stepsBox}>
      <span className={styles.backArrow} onClick={goBack}>
        {isArabic ? <ForwardIcon /> : <BackIcon />}
      </span>
      <TextH3>{t('Sign up.Sign up')}</TextH3>
    </section>
  )
}
