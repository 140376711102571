import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { useAccountInfo } from '../../../global/context/AccountInfoContext/AccountInfoContext.Provider'
import { useSessionEntity } from '../../../global/context/EntityContext'
import { createFormField } from '../../../global/formField/FormField'
import { CancelActionModal } from '../../../global/modal/CancleActionModal'
import { Modal } from '../../../global/modal/Modal'
import { FormTemplate } from '../../../global/templates/FormTemplate'
import { TransactionFormActionButtonTemplate } from '../../../global/templates/TransactionFormActionButtonTemplate'
import { DropArrowDownIcon } from '../../../icons/DropArrowDownIcon'
import { AccountDetailedDto } from '../../../model/AccountDetailedDto'
import { AccountGroupType } from '../../../model/AccountGroupType'
import { CreateDemoTradingAccount } from '../../../model/CreateTradingAccount'
import { LeverageTypeDto, isLeverageTypeDynamic } from '../../../model/LeverageTypeDto'
import { NameDto } from '../../../model/NameDto'
import { PlatformTypeEnum } from '../../../model/PlatformTypeEnum'
import { WalletCurrency } from '../../../model/WalletDto'
import { useApiClient } from '../../../utils/ApiClient'
import { usePathHistoryContext } from '../../../utils/PathHistoryContext'
import { ClientApiClient } from '../../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../../utils/companyName.utils'
import { FormSubmitValues } from '../../../utils/formValidation'
import { AccountTypeModal } from '../AccountTypeModal'
import { DynamicLeverageModal } from '../DynamicLeverageModal'
import { LeverageModal } from '../LeverageModal'
import { TradingPlatformModal } from '../TradingPlatformModal'
import { ClassificationTypeModal } from './ClassificationTypeModal'
import { CurrencyTypeModal } from './CurrencyTypeModal'

import styles from './CreateDemoTradingAccountForm.module.scss'

export interface CreateDemoTradingAccountFormValues {
  classificationType: NameDto | undefined
  platformType: NameDto<PlatformTypeEnum> | undefined
  accountType: NameDto | undefined
  leverageType: LeverageTypeDto | undefined
  accountCurrency: WalletCurrency | undefined
  virtualDeposit: number
}

const FormField = createFormField<CreateDemoTradingAccountFormValues>()

const CreateDemoTradingAccountFormUI: React.FC<
  FormikProps<CreateDemoTradingAccountFormValues> & OuterProps
> = (props) => {
  const apiClient = useApiClient(ClientApiClient)
  const { t } = useTranslation()
  const { handleSubmit, values, setFieldValue, isValid, currencies } = props
  const entity = useSessionEntity()
  const { accountInfo } = useAccountInfo()
  const { navigateToPreviousPath } = usePathHistoryContext()

  const navigate = useNavigate()
  const [platformTypeModalOpen, setPlatformTypeModalOpen] = useState(false)

  const [leverageModal, setLeverageModal] = useState(false)
  const [dynamicLeverageModal, setDynamicLeverageModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [accountTypeModal, setAccountTypeModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [accountTypes, setAccountTypes] = useState<NameDto<number>[]>([])
  const [classificationTypes, setClassificationTypes] = useState<NameDto<number>[]>([])

  const [classificationTypeModal, setClassificationTypeModal] = useState(false)
  const [currencyTypeModal, setCurrencyTypeModal] = useState(false)

  const handleSetTradingPlatform = async (platform: NameDto) => {
    setFieldValue('platformType', platform)
    props.onChangePlatform(platform.id)
    setPlatformTypeModalOpen(false)

    if (platform.id === PlatformTypeEnum.TickmillTrader) {
      const dynamicLeverage = (await apiClient.getLeverageTypes(platform.id)).find((leverage) =>
        isLeverageTypeDynamic(leverage.id)
      )
      setFieldValue('virtualDeposit', 100000)
      if (dynamicLeverage) {
        setFieldValue('leverageType', dynamicLeverage)
      }
    } else {
      setFieldValue('leverageType', undefined)
    }
    setFieldValue('accountCurrency', undefined)
    setFieldValue('accountType', undefined)
  }

  const handleSetLeverage = (leverage: LeverageTypeDto) => {
    setFieldValue('leverageType', leverage)
    setLeverageModal(false)
  }

  const handleSetAccountType = (leverage: LeverageTypeDto) => {
    setFieldValue('accountType', leverage)
    setAccountTypeModal(false)
  }

  const handleSetClassificationType = (classificationType: NameDto) => {
    setFieldValue('classificationType', classificationType)
    setClassificationTypeModal(false)
  }

  const handleSetAccountCurrency = (currency: WalletCurrency) => {
    setFieldValue('accountCurrency', currency)
    setCurrencyTypeModal(false)
  }

  const handleSetVirtualDeposit = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFieldValue('virtualDeposit', event.target.value)

  const fetchAccountTypes = async () => {
    setAccountTypes(
      await apiClient.getAccountType(
        values.platformType?.id!,
        values.accountCurrency?.id!,
        AccountGroupType.Demo
      )
    )
  }

  const fetchClassificationTypes = async () => {
    setClassificationTypes(
      await apiClient.getTradingAccountClassificationType(
        values.platformType?.id!,
        values.accountCurrency?.id!,
        AccountGroupType.Demo,
        values.accountType?.id!
      )
    )
  }

  useEffect(() => {
    if (values.accountType?.id && values.platformType?.id && values.accountCurrency?.id) {
      fetchClassificationTypes()
    }
    if (values.platformType?.id && values.accountCurrency?.id) {
      fetchAccountTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.platformType, values.accountCurrency, values.accountType])

  const disabledFields = (): { [key: string]: boolean } => {
    return {
      currency: !values.platformType,
      classification: !values.accountType,
      type: !values.accountCurrency,
      leverage:
        !values.accountType ||
        !values.accountCurrency ||
        values.platformType?.id === PlatformTypeEnum.TickmillTrader,
      deposit:
        !values.leverageType ||
        (!values.classificationType &&
          entity !== TickmillCompaniesEnum.TICKMILL_SC &&
          entity !== TickmillCompaniesEnum.TICKMILL_AS),
    }
  }

  const goBack = () => {
    setCancelModal(true)
  }

  const subtitle = useMemo(() => {
    if (values.platformType?.id === PlatformTypeEnum.TickmillTrader) {
      return t(
        'Trading Account.When you open a Demo trading account a Live Trading account is automatically created'
      )
    } else if (
      values.platformType?.id === PlatformTypeEnum.MT4 ||
      values.platformType?.id === PlatformTypeEnum.MT5
    ) {
      return t('Trading Account.You can open up to seven Demo trading accounts')
    }
  }, [values.platformType?.id, t])

  return (
    <React.Fragment>
      {currencyTypeModal && (
        <CurrencyTypeModal
          onSelectOption={handleSetAccountCurrency}
          onClose={() => setCurrencyTypeModal(false)}
          currencies={currencies}
        />
      )}
      {classificationTypeModal && (
        <ClassificationTypeModal
          onSelectOption={handleSetClassificationType}
          onClose={() => setClassificationTypeModal(false)}
          classificationTypes={classificationTypes}
        />
      )}
      {platformTypeModalOpen && (
        <TradingPlatformModal
          demo
          tradingAccountsAccountInfo={accountInfo}
          onSelectOption={handleSetTradingPlatform}
          onClose={() => setPlatformTypeModalOpen(false)}
        />
      )}
      {dynamicLeverageModal && (
        <DynamicLeverageModal closeModal={() => setDynamicLeverageModal(false)} />
      )}
      {leverageModal && (
        <LeverageModal
          title={t('Trading Account.Select Leverage')}
          platformTypeId={values.platformType?.id}
          restrictions={[]}
          onSelect={handleSetLeverage}
          onClose={() => setLeverageModal(false)}
        />
      )}
      {accountTypeModal && (
        <AccountTypeModal
          isDemo={true}
          accountTypes={accountTypes}
          accountType={values.accountType}
          onSelect={handleSetAccountType}
          onClose={() => setAccountTypeModal(false)}
        />
      )}
      {cancelModal && (
        <Modal
          closeModal={() => setCancelModal(false)}
          render={() => (
            <CancelActionModal
              onConfirm={navigateToPreviousPath}
              onCancel={() => setCancelModal(false)}
            />
          )}
        />
      )}
      <Loading showLoadingIcon isLoading={isLoading}>
        <FormTemplate
          title={t('Trading Account.Add Demo Trading Account')}
          subtitle={<div className={styles.subTitle}>{subtitle}</div>}
          goBack={goBack}
        >
          <Form>
            <FormField
              name='platformType.name'
              label={t('Trading Account.Trading Platform')}
              placeholder={t('Trading Account.Trading Platform')}
              rightIcon={<DropArrowDownIcon />}
              readOnly
              required
              onClick={() => setPlatformTypeModalOpen(true)}
            />
            <FormField
              name='accountCurrency.id'
              label={t('Trading Account.Account Currency')}
              placeholder={t('Trading Account.Account Currency')}
              rightIcon={<DropArrowDownIcon />}
              value={values.accountCurrency?.id}
              readOnly
              required
              disabled={disabledFields().currency}
              onClick={() => setCurrencyTypeModal(true)}
            />
            <FormField
              name='accountType.name'
              label={t('Trading Account.Account Type')}
              placeholder={t('Trading Account.Account Type')}
              rightIcon={<DropArrowDownIcon />}
              readOnly
              required
              disabled={disabledFields().type}
              onClick={() => setAccountTypeModal(true)}
            />
            <FormField
              name='leverageType.name'
              label={t('Trading Account.Leverage')}
              placeholder={t('Trading Account.Leverage')}
              rightIcon={<DropArrowDownIcon />}
              readOnly
              required
              disabled={disabledFields().leverage}
              onClick={() => setLeverageModal(true)}
              hint={
                isLeverageTypeDynamic(values.leverageType?.id) ? (
                  <span onClick={() => setDynamicLeverageModal(true)} className='is-link'>
                    {t('Trading Account.Leverage Information')}
                  </span>
                ) : undefined
              }
            />
            {entity !== TickmillCompaniesEnum.TICKMILL_SC &&
              entity !== TickmillCompaniesEnum.TICKMILL_AS && (
                <FormField
                  name='classificationType.name'
                  label={t('Trading Account.Classification')}
                  placeholder={t('Trading Account.Classification')}
                  required
                  rightIcon={<DropArrowDownIcon />}
                  readOnly
                  disabled={disabledFields().classification}
                  onClick={() => setClassificationTypeModal(true)}
                />
              )}
            <FormField
              name={'virtualDeposit'}
              type='number'
              label={t('Trading Account.Virtual Deposit')}
              placeholder={t('Trading Account.Virtual Deposit')}
              required
              disabled={disabledFields().deposit}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleSetVirtualDeposit(event)
              }
            />

            <TransactionFormActionButtonTemplate>
              <Button appearance='secondary' size='L' type='button' onClick={goBack}>
                {t('Cancel')}
              </Button>

              <Button
                appearance='primary'
                size='L'
                type='button'
                onClick={() => {
                  setIsLoading(true)
                  handleSubmit()
                }}
                disabled={!isValid}
              >
                {t('Confirm')}
              </Button>
            </TransactionFormActionButtonTemplate>
          </Form>
        </FormTemplate>
      </Loading>
    </React.Fragment>
  )
}

function dataToSubmit(
  values: CreateDemoTradingAccountFormValues,
  entity: TickmillCompaniesEnum
): FormSubmitValues<CreateDemoTradingAccount> {
  return {
    leverageTypeId: values.leverageType?.id!,
    platformTypeId: values.platformType?.id!,
    tradingAccountTypeId: values.accountType?.id!,
    accountCurrencyId: values.accountCurrency?.id!,
    classificationTypeId:
      entity !== TickmillCompaniesEnum.TICKMILL_SC && entity !== TickmillCompaniesEnum.TICKMILL_AS
        ? values.classificationType?.id!
        : null,
    deposit: values.virtualDeposit,
  }
}

interface OuterProps {
  currencies: WalletCurrency[]
  entity: TickmillCompaniesEnum
  account: AccountDetailedDto | undefined

  onChangePlatform(platformTypeId: number): void

  onSubmit(values: FormSubmitValues<CreateDemoTradingAccount>): Promise<void>
}

export const CreateDemoTradingAccountForm = withFormik<
  OuterProps,
  CreateDemoTradingAccountFormValues
>({
  mapPropsToValues: () => {
    return {
      leverageType: undefined,
      platformType: undefined,
      accountType: undefined,
      classificationType: undefined,
      accountCurrency: undefined,
      virtualDeposit: 0,
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(dataToSubmit(values, props.entity))
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, { entity }) => {
    const errors: FormikErrors<CreateDemoTradingAccountFormValues> = {}
    if (!values.accountType) {
      errors.accountType = t('Validation.Required')
    }
    if (values.virtualDeposit < 100) {
      errors.virtualDeposit = t(
        'Validation.Virtual Deposit cannot be smaller than 100 or greater than 5,000,000'
      )
    }
    if (values.virtualDeposit > 5000000) {
      errors.virtualDeposit = t(
        'Validation.Virtual Deposit cannot be smaller than 100 or greater than 5,000,000'
      )
    }
    if (!values.platformType) {
      errors.platformType = t('Validation.Required')
    }
    if (!values.leverageType) {
      errors.leverageType = t('Validation.Required')
    }
    if (!values.virtualDeposit) {
      errors.virtualDeposit = t('Validation.Required')
    }
    if (!values.classificationType) {
      if (
        entity !== TickmillCompaniesEnum.TICKMILL_SC &&
        entity !== TickmillCompaniesEnum.TICKMILL_AS
      ) {
        errors.classificationType = t('Validation.Required')
      }
    }
    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(CreateDemoTradingAccountFormUI)
