import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CampaignStatus } from '../../Traders-Room/Campaign-Results/campaign.utils'
import { Button } from '../../global/button/Button'
import { ClientIbContestCampaignDto } from '../../model/ClientIbContestCampaignDto'
import { TextSmall } from '../../ui/Typography/Typography'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { getScrollToCardId } from '../../utils/getItemId'

import styles from './IBContestPage.module.scss'

interface IBContestCardsProps {
  data: ClientIbContestCampaignDto[]
  setSelectedCampaign(campaignResult: ClientIbContestCampaignDto): void
}

export const IBContestCards: React.FC<IBContestCardsProps> = (props) => {
  const { data, setSelectedCampaign } = props

  return (
    <>
      <div className='mt-5'>
        {data.map((x, index) => (
          <IBContestCard
            data={x}
            setSelectedCampaign={setSelectedCampaign}
            key={x.id}
            cardId={getScrollToCardId(index, data.length, 'ib-contest')}
          />
        ))}
      </div>
    </>
  )
}

interface IBContestCard {
  data: ClientIbContestCampaignDto
  setSelectedCampaign(campaignResult: ClientIbContestCampaignDto): void
  cardId: string
}

const IBContestCard: React.FC<IBContestCard> = (props) => {
  const { data, setSelectedCampaign, cardId } = props

  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  return (
    <div className={styles.result} id={cardId}>
      <div className='is-flex is-justify-content-space-between mb-2'>
        <TextSmall isParagraph>{data.name}</TextSmall>
        <p>
          <CampaignStatus {...data} />
        </p>
      </div>

      <div className='is-flex is-justify-content-space-between mb-2'>
        <TextSmall isParagraph className={classNames('text-secondary', styles.label)}>
          <>{t('Campaigns.Start Date')}</>
        </TextSmall>
        <TextSmall isParagraph>{formatDate(data.from, dateFormat)}</TextSmall>
      </div>

      <div className='is-flex is-justify-content-space-between mb-4'>
        <TextSmall isParagraph className={classNames('text-secondary', styles.label)}>
          <>{t('Campaigns.End Date')}</>
        </TextSmall>
        <TextSmall isParagraph>{formatDate(data.to, dateFormat)}</TextSmall>
      </div>

      <div className='is-flex is-justify-content-space-between'>
        <span className='is-flex is-justify-content-flex-end is-flex-grow-1'>
          <Button appearance='link' onClick={() => setSelectedCampaign(data)}>
            <TextSmall>{t('Campaigns.View results')}</TextSmall>
          </Button>
        </span>
      </div>
    </div>
  )
}
