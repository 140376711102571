import React, { useEffect, useState } from 'react'

import { useProductReadContext } from '../../global/context/ProductContext'
import {
  FilterOptions,
  FilterQueryModal,
  FilterQueryProps,
} from '../../global/filter/FilterQueryModal'
import { FilterOptionsDto } from '../../model/FilterOptionsDto'
import { NameDto } from '../../model/NameDto'
import { isTickmillProductTypeCFD } from '../../model/TickmillProductType'
import { TradingAccount } from '../../model/TradingAccount'
import { SharedState } from '../../utils/SharedContext'
import { IDateFilter } from '../../utils/filter.utils'

interface Props {
  currentQuery?: FilterQueryProps
  sharedState: SharedState
  filterOptions?: FilterOptionsDto
  tradingAccounts?: TradingAccount[]
  onConfirm({
    searchFilters,
    currentFilter,
  }: {
    searchFilters?: FilterQueryProps
    currentFilter?: IDateFilter
  }): void
  isLoading?: boolean
  fetchTradingAccounts(): void
  onCancel(): void
}

export const TransactionFilterModal: React.FC<Props> = ({
  onConfirm,
  currentQuery,
  sharedState,
  filterOptions,
  isLoading,
  tradingAccounts,
  fetchTradingAccounts,
}) => {
  const { product } = useProductReadContext()

  const [searchFilters, setSearchFilters] = useState<FilterQueryProps | undefined>(currentQuery)
  const [filteredAccounts, setFilteredAccounts] = useState<NameDto<string>[]>()

  useEffect(() => {
    if (!tradingAccounts?.length) {
      fetchTradingAccounts()
    }

    const platformTypeId = searchFilters?.[FilterOptions.TradingAccountPlatformType]
    const accounts =
      tradingAccounts
        ?.filter((account) => {
          return platformTypeId
            ? platformTypeId.toString().split(',').includes(account.platformType.id.toString())
            : true
        })
        .map((account) => ({ id: account.id, name: account.name })) || []

    setFilteredAccounts(accounts)
  }, [currentQuery, fetchTradingAccounts, searchFilters, tradingAccounts])

  const filterOpts = [
    FilterOptions.Wallet,
    FilterOptions.AccountType,
    FilterOptions.TransactionType,
    FilterOptions.TransactionStatus,
    FilterOptions.Date,
    ...(isTickmillProductTypeCFD(product)
      ? [FilterOptions.TradingAccountPlatformType, FilterOptions.TradingAccount]
      : []),
    FilterOptions.ClientIdAmount,
  ]

  return (
    <FilterQueryModal
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      onConfirm={onConfirm}
      wallets={filterOptions?.wallets}
      tradingAccounts={filteredAccounts}
      transactionTypes={filterOptions?.transactionTypes}
      transactionStates={sharedState?.transactionStates}
      currencyOptions={filterOptions?.walletCurrencies}
      isLoading={isLoading}
      customLabels={{
        [FilterOptions.TradingAccount]: '',
      }}
      filterOptions={filterOpts}
    />
  )
}
