import React, { PropsWithChildren, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { DocumentClientAreaDto } from '../../model/DocumentClientAreaDto'
import { NameDto } from '../../model/NameDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { DocumentBadgeStatus } from './DocumentBadgeStatus'

import styles from './UploadedDocumentStatusHistory.module.scss'

interface UploadedDocumentStatusHistoryModalProps {
  documentClientArea: DocumentClientAreaDto | undefined

  onClose(): void
}

export const UploadedDocumentStatusHistoryModal: React.FC<
  UploadedDocumentStatusHistoryModalProps
> = (props) => {
  const { documentClientArea, onClose } = props
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const { t } = useTranslation()

  return (
    <Modal
      size='small'
      closeModal={onClose}
      render={({ closeModal }) => (
        <InformationModal
          title={t('Profile.Status History')}
          onCancelText={t('Got It')}
          onCancel={closeModal}
        >
          <section className='modal-card-body'>
            {documentClientArea?.statusHistory.map((x, index) => (
              <StatusHistoryItem key={index}>
                <StatusHistoryItemDate
                  name={t('Date')}
                  value={formatDate(x.createdDate, dateFormat)}
                />
                <StatusHistoryItemStatus name={t('Status')} value={x.status} />
              </StatusHistoryItem>
            ))}
          </section>
        </InformationModal>
      )}
    />
  )
}
interface Props {}
const StatusHistoryItem: React.FunctionComponent<PropsWithChildren<Props>> = ({ children }) => {
  return <div className={styles.documentItemWrapper}>{children}</div>
}

interface StatusHistoryItemDateProps {
  name: string
  value: string
}

const StatusHistoryItemDate: React.FC<StatusHistoryItemDateProps> = ({ name, value }) => {
  return (
    <dl className={styles.documentItem}>
      <dt className={styles.documentName}>{name}</dt>
      <dd className={styles.documentValue}>{value}</dd>
    </dl>
  )
}

interface StatusHistoryItemStatusProps {
  name: string
  value: NameDto
}

const StatusHistoryItemStatus: React.FC<StatusHistoryItemStatusProps> = ({ name, value }) => {
  return (
    <dl className={styles.documentItem}>
      <dt className={styles.documentName}>{name}</dt>
      <dd className={styles.documentValue}>
        <DocumentBadgeStatus {...value} />
      </dd>
    </dl>
  )
}
