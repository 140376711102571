import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as XLSX from 'xlsx'

import { IBIncomeQuery } from '../../IntroducingBroker/Income/IBIncomePage'
import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { Button } from '../../global/button/Button'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { ExportModal as ExportModalView } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../hooks/useScrollToElementIds'
import { NameDto } from '../../model/NameDto'
import { SubscriptionHistoryItem } from '../../model/SubscriptionsDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { PageQuery, SortOrder, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { getAppliedFiltersLength, setQuery } from '../../utils/filter.utils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { IAppendableFetchResult, useFetchAppendablePage } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { SubscriptionsHistoryCard } from './SubscriptionsHistoryCard'
import { SubscriptionsHistoryFilterModal } from './SubscriptionsHistoryFilterModal'
import { SubscriptionsHistoryTable } from './SubscriptionsHistoryTable'

import styles from './SubscriptionsHistoryTable.module.scss'

const initialQuery = {
  ca_search_TextSearch: undefined,
}

const initialSearchTermQuery = {
  show: false,
  searchTerm: undefined,
}

interface OuterProps {
  data?: SubscriptionHistoryItem[]
}

export interface ISubscriptionsHistoryQuery extends FilterQueryProps {
  DateFrom?: string
  DateTo?: string
}

interface useSubscriptionsHistoryFetchProps {
  search: ReturnType<typeof useSearch>
}

const useSubscriptionsHistoryFetch = (props: useSubscriptionsHistoryFetchProps) => {
  const { search } = props

  const apiClient = useApiClient(ClientApiClient)
  const initialRender = useRef(true)

  const { callback: subscriptionCallback } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      return await apiClient.getSubscriptionsHistory({
        ...query,
        ...search.pageQuery,
        search: { ...search.search, ca_search_TextSearch: search.searchTerm.searchTerm },
        caller: 'ca',
      })
    },
    [search.search, search.pageQuery, search.searchTerm]
  )
  const result = useFetchAppendablePage(subscriptionCallback)

  useEffect(
    () => {
      if (initialRender.current) {
        initialRender.current = false
      } else {
        const timer = setTimeout(
          () =>
            search.searchTerm?.searchTerm !== undefined &&
            result.setPageQuery!((oldPageQuery: PageQuery) => ({
              ...oldPageQuery,
              pageIndex: 1,
              ca_search_TextSearch: search.searchTerm.searchTerm,
            })),
          500
        )
        return () => clearTimeout(timer)
      }
    },
    [search.searchTerm?.searchTerm] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    ...result,
    subscriptions: result.data,
  }
}

interface GenerateDocumentFileProps {
  query: IAppendableFetchResult<SubscriptionHistoryItem[]>
}

const useGenerateDocumentFile = (props: GenerateDocumentFileProps) => {
  const { query } = props
  const { formatMoney } = useFormatNumber()
  const { t } = useTranslation()

  const exportFilename = 'subscriptions_history_report'

  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const [isOptionsModalOpen, setOptionsModalOpen] = useState<boolean>(false)

  const handleGenerateExcel = () => {
    const table = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(table)

    XLSX.utils.book_append_sheet(wb, ws, exportFilename)
    XLSX.writeFile(wb, `${exportFilename}.xlsx`)

    setOptionsModalOpen(false)
  }

  const handleGeneratePDF = () => {
    const title = 'Subscriptions History Report'
    const data = tableBody()
    generatePDFTable({
      data,
      title,
      fileName: exportFilename,
    })

    setOptionsModalOpen(false)
  }

  const tableBody = () => {
    const headerCsvData = [
      [
        t('Date'),
        t('Subscriptions.Product'),
        t('Subscriptions.Request Type'),
        t('Subscriptions.Price'),
        t('Subscriptions.Status'),
      ],
    ]

    return query.data.reduce((previousValue, currentValue) => {
      return previousValue.concat([
        [
          formatDate(currentValue.createdDate, dateFormat),
          currentValue.subscription.name,
          currentValue.type.name,
          formatMoney(currentValue.amount),
          currentValue.state.name,
        ],
      ])
    }, headerCsvData)
  }

  const openOptionsModal = () => {
    setOptionsModalOpen(true)
  }

  const closeOptionsModal = () => {
    setOptionsModalOpen(false)
  }

  return {
    handleGenerateExcel,
    handleGeneratePDF,
    tableBody,
    isOptionsModalOpen,
    setOptionsModalOpen,
    exportFilename,
    openOptionsModal,
    closeOptionsModal,
  }
}

const useSortSettings = () => {
  const { t } = useTranslation()

  const sortOptions = [
    { id: 'CreatedDate', name: t('Date') },
    {
      id: 'ClientSubscriptionDetail.SubscriptionDetail.Subscription.Name',
      name: t('Subscriptions.Product'),
    },
    {
      id: 'SubscriptionRequestTypeId',
      name: t('Subscriptions.Request Type'),
    },
    {
      id: 'ClientSubscriptionDetail.SubscriptionDetail.Amount',
      name: t('Subscriptions.Price'),
    },
    {
      id: 'state.Order',
      name: t('Subscriptions.Status'),
    },
  ]

  return {
    sortOptions,
  }
}

const useSort = () => {
  const [sort, setSort] = useState<string>()
  const [sortOrder, setSortOrder] = useState<SortOrder>()
  const [isSortModalOpen, setSortModalOpen] = useState(false)

  const openSortModal = () => {
    setSortModalOpen(true)
  }

  const closeSortModal = () => {
    setSortModalOpen(false)
  }

  return {
    isSortModalOpen,
    openSortModal,
    closeSortModal,

    setSort,
    sort,
    sortOrder,
    setSortOrder,
    setSortModalOpen,
  }
}

const useSearch = (props: ReturnType<typeof useSort>) => {
  const { sort, sortOrder } = props

  const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<ISubscriptionsHistoryQuery>(initialQuery)
  const [searchTerm, showSearchTerm] = useState<SearchTermState>(initialSearchTermQuery)
  const [pageQuery, setPageQuery] = useState<PageQuery>({ sort, sortOrder })

  const handleFilterSearch = (q: IBIncomeQuery): void => {
    setSearch(q)
  }

  const handleClearSearch = () => {
    showSearchTerm({ searchTerm: '', show: false })
    setSearch(initialQuery)
  }

  const openFilterModal = () => {
    setFilterModalOpen(true)
  }

  const closeFilterModal = () => {
    setFilterModalOpen(false)
  }

  return {
    handleFilterSearch: handleFilterSearch,
    clearSearch: handleClearSearch,
    filtersLength: getAppliedFiltersLength({ ...search }),
    search,
    setSearch,
    searchTerm,
    showSearchTerm,
    isFilterModalOpen,
    openFilterModal,
    closeFilterModal,
    pageQuery,
    setPageQuery,
  }
}

const useScroll = (props: ReturnType<typeof useSubscriptionsHistoryFetch>) => {
  const { isLoading, meta } = props

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.SubscriptionHistoryETDHeader,
    isPaginationEntrySelected,
    isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(ScrollToIds.SubscriptionHistoryETDHeader, isLoading, meta?.pageSize)

  const handlePaginationEntriesSelected = () => {
    setIsPaginationEntrySelected(true)
  }

  const handlePaginationEntriesUnselected = () => {
    setIsPaginationEntrySelected(false)
  }

  return {
    isPaginationEntrySelected,
    handlePaginationEntriesSelected,
    handlePaginationEntriesUnselected,
  }
}

export const SubscriptionsHistoryPageETD: React.FC<OuterProps> = () => {
  useScrollToTop()

  const isMobile = useWindowResize()
  const sort = useSort()
  const sortSettings = useSortSettings()
  const search = useSearch(sort)

  const query = useSubscriptionsHistoryFetch({ search })
  const generateDocumentFile = useGenerateDocumentFile({ query })
  const { handlePaginationEntriesSelected } = useScroll(query)
  const { pageQuery, setPageQuery, meta, isLoading } = query

  return (
    <React.Fragment>
      {search.isFilterModalOpen && (
        <Modal
          closeModal={search.closeFilterModal}
          render={({ closeModal }) => (
            <FilterModal
              currentQuery={search.search}
              onConfirm={search.handleFilterSearch}
              onClose={closeModal}
            />
          )}
        />
      )}
      {generateDocumentFile.isOptionsModalOpen && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal generateDocumentFile={generateDocumentFile} onClose={closeModal} />
          )}
          closeModal={generateDocumentFile.closeOptionsModal}
        />
      )}
      {isMobile && sort.isSortModalOpen && (
        <Modal
          closeModal={sort.closeSortModal}
          render={() => <SortModal sort={sort} sortSettings={sortSettings} search={search} />}
        />
      )}
      <Header
        query={query}
        search={search}
        sort={sort}
        sortOptions={sortSettings.sortOptions}
        generateDocumentFile={generateDocumentFile}
      />
      <Loading isLoading={isLoading} showLoadingIcon>
        <SubscriptionsHistoryRecords query={query} search={search} sortSettings={sortSettings} />
      </Loading>
      {meta && meta.itemsCount > 0 && (
        <Paging
          scrollToHeaderId={ScrollToIds.SubscriptionHistoryETDHeader}
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              handlePaginationEntriesSelected()
            }
            return setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </React.Fragment>
  )
}

interface SubscriptionsHistoryRecordsProps {
  query: IAppendableFetchResult<SubscriptionHistoryItem[]>
  search: ReturnType<typeof useSearch>
  sortSettings: ReturnType<typeof useSortSettings>
}

const SubscriptionsHistoryRecords: React.FC<SubscriptionsHistoryRecordsProps> = (props) => {
  const { query, search, sortSettings } = props

  const { t } = useTranslation()
  const isMobile = useWindowResize()

  if (hasRecords(query)) {
    if (isMobile) {
      return <SubscriptionsHistoryCard data={query.data} />
    } else {
      return (
        <SubscriptionsHistoryTable
          data={query.data}
          search={search}
          sortOptions={sortSettings.sortOptions}
        />
      )
    }
  }

  if (isSearch(search.searchTerm)) {
    return <NoResults hideLink subtitle={t('No results found')} />
  }

  if (search.filtersLength >= 1) {
    return <NoResults hideLink subtitle={t('No results found')} />
  }

  return (
    <NoResults
      subtitle={t('Subscriptions.You have no Subscriptions yet')}
      hideLink
      children={
        <Link to={'/dashboard/subscriptions/trading-platforms'}>
          <Button className={styles.button} type='button' appearance='primary' size='M'>
            + {t('Subscriptions.Add Subscription')}
          </Button>
        </Link>
      }
    />
  )
}

interface HeaderProps {
  query: IAppendableFetchResult<SubscriptionHistoryItem[]>
  generateDocumentFile: ReturnType<typeof useGenerateDocumentFile>
  search: ReturnType<typeof useSearch>
  sort: ReturnType<typeof useSort>
  sortOptions: NameDto<string>[]
}

const Header: React.FC<HeaderProps> = (props) => {
  const { search: searchResponse, sort, query, generateDocumentFile } = props
  const { sortOptions } = props
  const { searchTerm, showSearchTerm, clearSearch, filtersLength } = searchResponse

  const { t } = useTranslation()

  if (query.hasInitialResults) {
    return (
      <PageHeader
        id={ScrollToIds.SubscriptionHistoryETDHeader}
        title={t('Subscriptions.Subscription History')}
        search={{
          show: searchTerm.show,
          searchTerm: searchTerm.searchTerm,
          placeholder: t('Search Product Name'),
          setShow: (v) => showSearchTerm({ ...searchTerm, show: v }),
          setSearchTerm: (v) => {
            showSearchTerm({ ...searchTerm, searchTerm: v })
          },
        }}
        optionsToggle={generateDocumentFile.openOptionsModal}
        filterToggles={{
          openFilterModal: searchResponse.openFilterModal,
          resetFilters: clearSearch,
          activeFilters: filtersLength,
          openSortModal: sort.openSortModal,
          sortLabel: normalizeSortLabel(t, sortOptions, query?.pageQuery?.sort),
        }}
      />
    )
  }

  return (
    <PageHeader
      id={ScrollToIds.SubscriptionHistoryETDHeader}
      title={t('Subscriptions.Subscription History')}
    />
  )
}

interface FilterModalProps {
  currentQuery?: IBIncomeQuery
  onConfirm(q: IBIncomeQuery): void
  onClose(): void
}

const FilterModal: React.FC<FilterModalProps> = (props) => {
  const { onConfirm, onClose, currentQuery } = props

  return (
    <SubscriptionsHistoryFilterModal
      currentQuery={currentQuery}
      onConfirm={({ searchFilters, currentFilter }) => {
        let q: ISubscriptionsHistoryQuery = {
          ...setQuery(currentFilter),
          ...searchFilters,
        }

        if (!currentFilter) {
          q = Object.keys(q)
            .filter((key) => !['DateCreated', 'DateFrom', 'DateTo'].includes(key))
            .reduce((acc, key) => ({ ...acc, [key]: q[key] }), {})
        }

        onConfirm(q)
        onClose()
      }}
    />
  )
}

interface SortModalProps {
  sort: ReturnType<typeof useSort>
  sortSettings: ReturnType<typeof useSortSettings>
  search: ReturnType<typeof useSearch>
}

const SortModal: React.FC<SortModalProps> = (props) => {
  const { sort, sortSettings, search } = props

  return (
    <SortByModal
      onConfirm={(ibSort: string, sortOrder: SortOrder) => {
        search.setPageQuery?.({
          ...search.pageQuery,
          sort: ibSort,
          sortOrder: sortOrder,
        })
        sort.closeSortModal()
      }}
      options={sortSettings.sortOptions}
      onCancel={sort.closeSortModal}
    />
  )
}

interface ExportModalProps {
  generateDocumentFile: ReturnType<typeof useGenerateDocumentFile>
  onClose(): void
}

const ExportModal: React.FC<ExportModalProps> = (props) => {
  const { generateDocumentFile, onClose } = props

  return (
    <ExportModalView
      fileName={generateDocumentFile.exportFilename}
      onCloseModal={onClose}
      csvData={generateDocumentFile.tableBody()}
      onExportToCSV={onClose}
      onExportToPdf={generateDocumentFile.handleGeneratePDF}
      onExportToExcel={generateDocumentFile.handleGenerateExcel}
    />
  )
}

const hasRecords = (query: IAppendableFetchResult<SubscriptionHistoryItem[]>): boolean => {
  return !!query?.data?.length && !query?.isLoading
}

const isSearch = (searchTerm: SearchTermState) => {
  const isSearchActive = searchTerm?.show
  const isSearching =
    searchTerm?.searchTerm !== undefined && searchTerm?.searchTerm.trim().length > 0
  return isSearching || isSearchActive
}
