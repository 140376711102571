import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { Loading } from '../../../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { useSignup } from '../../../../../global/context/SignupContext'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { getTickmillCompanyByHostname } from '../../../../../utils/companyName.utils'
import { getLocationCountry } from '../../../../../utils/geolocation'
import { useFetchOne } from '../../../../../utils/useFetch'
import { CompanyDetailsStep2Form, CompanyDetailsStep2FormValues } from './CompanyDetailsStep2Form'

interface OuterProps {
  onSubmit(values: CompanyDetailsStep2FormValues): void
}

export const CompanyDetailsStep2Page: React.FC<OuterProps> = (props) => {
  const { onSubmit } = props
  const { signupData } = useSignup()
  const apiClient = useApiClient(ClientApiClient)

  const location = useLocation()
  const locale = useSessionLanguage()
  const country = getLocationCountry(location)

  const callback = useCallback(() => {
    return apiClient.getRegulators(getTickmillCompanyByHostname(true), locale, country)
  }, [apiClient, country, locale])

  const companyLegalBasesCallback = useCallback(() => {
    return apiClient.getLegalBases(locale)
  }, [apiClient, locale])

  const ownershipStructuresCallback = useCallback(() => {
    return apiClient.getOwnershipStructures(locale)
  }, [apiClient, locale])

  const { data: ownershipStructures = [] } = useFetchOne(ownershipStructuresCallback)
  const { data: legalBases = [] } = useFetchOne(companyLegalBasesCallback)
  const { data: regulators = [], isLoading } = useFetchOne(callback)

  return (
    <Loading isLoading={isLoading}>
      {regulators && (
        <CompanyDetailsStep2Form
          initialValues={signupData.corporateValues}
          onSubmit={onSubmit}
          legalBases={legalBases}
          ownershipStructures={ownershipStructures}
          regulators={regulators}
        />
      )}
    </Loading>
  )
}
