import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../../../../../global/button/Button'
import { FileData } from '../../../../../global/fileUpload/FileUpload'
import { removeUploadedFile } from '../../../../../global/fileUpload/FileUploads'
import { createFormSelectField } from '../../../../../global/formField/FormSelectField'
import { ClientNciPriorityDto } from '../../../../../model/ClientNciPriorityDto'
import { CreateClientDocument } from '../../../../../model/CreateClientDocuments'
import {
  ClientCreateNciFinalHistory,
  ClientNciHistoryStatus,
} from '../../../../../model/CreateNciHistory'
import { DocumentCategoryType } from '../../../../../model/DocumentCategories'
import { DocumentCategoryDto } from '../../../../../model/DocumentDto'
import { Text, TextH3 } from '../../../../../ui/Typography/Typography'
import { isDoubleSidedDocument } from '../../../../../utils/uploadDocument'
import { DocumentFileUploadsFactory } from '../DocumentFileUploadsFactory'
import { DocumentConfirmationModal } from './DocumentConfirmationModal'
import { DocumentFileConfirmationModal } from './DocumentFileConfirmationModal'

import styles from '../DocumentsInfoPage.module.scss'

export interface DocumentInfoPageFormValuesStep1 {
  documentTypeId: string
  documentCategoryType: string
  documents: DocumentManagementDocument[]
  reason?: string
  files: FileData[]
  nciDocumentNumber: string
}

interface DocumentManagementDocument {
  categoryId: number
  typeId: number
  file: string
  filename: string
  error: boolean
}

const FormSelectField = createFormSelectField<DocumentInfoPageFormValuesStep1>()

const DocumentInfoNonCiPageStep1FormUI: React.FC<
  FormikProps<DocumentInfoPageFormValuesStep1> & OuterProps
> = (props) => {
  const { setValues, values, onSkipDocuments, isValid, handleSubmit, documentCategoryTypes } = props

  const { t } = useTranslation()

  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [isFileConfirmationModalOpen, setFileConfirmationModalOpen] = useState(false)

  const [file, setFile] = useState<FileData>()

  const handleSubmitClick = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsFormSubmitting(true)
    try {
      await handleSubmit()
    } catch (error: unknown) {
      console.log(error)
      setIsFormSubmitting(false)
    }
  }

  const handleConfirmationModalOpen = () => {
    setConfirmationModalOpen(true)
  }

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const handleDocumentUploads = (filesData: FileData[]) => {
    setValues({
      ...values,
      files: filesData,
      documents: filesData.map((x) => ({
        ...x,
        categoryId: DocumentCategoryType.Personal,
        typeId: Number.parseInt(values.documentCategoryType, 10),
        filename: x.fileName,
        file: x.base64Content,
      })),
    })
  }

  const handleDocumentRemove = () => {
    const files = removeUploadedFile(values.files, file)
    setValues({
      ...values,
      files,
      documents: files.map((x) => ({
        ...x,
        categoryId: DocumentCategoryType.Address,
        typeId: Number.parseInt(values.documentCategoryType, 10),
        filename: x.fileName,
        file: x.base64Content,
      })),
    })
    setFileConfirmationModalOpen(false)
  }

  const handleDocumentBeforeRemove = (file: FileData) => {
    setFile(file)
    setFileConfirmationModalOpen(true)
  }

  const handleFileConfirmationModalClose = () => {
    setFileConfirmationModalOpen(false)
  }

  useEffect(() => {
    props.setValues({ ...values, documents: [], files: [] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.documentTypeId])

  return (
    <React.Fragment>
      {isConfirmationModalOpen && (
        <DocumentConfirmationModal
          onConfirm={onSkipDocuments}
          onCancel={handleConfirmationModalClose}
        />
      )}
      {isFileConfirmationModalOpen && (
        <DocumentFileConfirmationModal
          onConfirm={handleDocumentRemove}
          onCancel={handleFileConfirmationModalClose}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <TextH3>{t('Sign up.Identification')}</TextH3>
        <Text isParagraph>
          {t('Profile.Please upload an identification document displaying your full name Non NCI')}
        </Text>

        <br />

        <div className='pb-4'>
          <FormSelectField
            name='documentTypeId'
            label={t('Sign up.Document Type')}
            placeholder={t('Sign up.Document Type')}
            required
          >
            {documentCategoryTypes.types.map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </FormSelectField>
        </div>

        <div className='pb-5'>
          <div className='pb-5'>
            <div className='pb-3'>
              <DocumentFileUploadsFactory
                files={values.files}
                documentTypeId={values.documentTypeId}
                documentCategory={documentCategoryTypes.category.id}
                onChange={handleDocumentUploads}
                onBeforeRemove={handleDocumentBeforeRemove}
              />
            </div>
          </div>
        </div>

        <div className='is-flex is-justify-content-center'>
          <Button
            onClick={handleConfirmationModalOpen}
            className={styles.button}
            type='button'
            appearance='secondary'
            size='L'
            fullWidth
          >
            {t('Skip')}
          </Button>
          <Button
            type='submit'
            appearance='primary'
            size='L'
            loading={isFormSubmitting}
            disabled={!isValid}
            onClick={handleSubmitClick}
            className={styles.button}
            fullWidth
          >
            {t('Submit')}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  )
}

interface OuterProps {
  accountNciAvailablePriorities: ClientNciPriorityDto

  documentCategoryTypes: DocumentCategoryDto

  onSkip(): void

  onSkipDocuments(): void

  handleSubmitReason(reason: string): void

  handleSubmit(values: ClientCreateNciFinalHistory, documents: CreateClientDocument[]): void
}

function dataToSubmit(
  values: DocumentInfoPageFormValuesStep1,
  accountNciAvailablePriorities: ClientNciPriorityDto
): ClientCreateNciFinalHistory {
  return {
    documentTypeId: Number.parseInt(values.documentTypeId, 10),
    nciDocumentNumber: values.nciDocumentNumber,
    nciPriorityId: accountNciAvailablePriorities.id,
    countryId: accountNciAvailablePriorities.country.id,
    statusId: ClientNciHistoryStatus.PENDING,
  }
}

export const DocumentInfoNonNciPageStep1Form = withFormik<
  OuterProps,
  DocumentInfoPageFormValuesStep1
>({
  mapPropsToValues: ({ accountNciAvailablePriorities, documentCategoryTypes }) => {
    const [documentType] = accountNciAvailablePriorities.documentTypes
    return {
      nciDocumentNumber: '',
      documentCategoryType: documentCategoryTypes.category.id.toString() || '',
      files: [],
      documentTypeId: documentType ? documentType.documentTypeId.toString() : '',
      documents: [],
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      props.handleSubmit(
        dataToSubmit(values, props.accountNciAvailablePriorities),
        values.documents.map((x) => ({
          ...x,
          typeId: Number.parseInt(values.documentTypeId, 10),
        }))
      )
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, props) => {
    const errors: FormikErrors<DocumentInfoPageFormValuesStep1> = {}

    if (!values.documentCategoryType) {
      errors.documentCategoryType = t('Validation.Required')
    }

    const documents = values.documents.filter((x) => !!x?.filename).filter((x) => !x.error)
    if (
      documents.length <=
      (isDoubleSidedDocument(Number(values.documentCategoryType), Number(values.documentTypeId))
        ? 1
        : 0)
    ) {
      errors.documents = t('Validation.Required')
    }

    if (values.documents.some((doc) => doc.error)) {
      errors.documents = t('Validation.Required')
    }

    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(DocumentInfoNonCiPageStep1FormUI)
