import Cookies from 'js-cookie'

export type Theme = 'light' | 'dark' | 'auto'

export const AllThemes: Theme[] = ['light', 'dark', 'auto']

export const defaultTheme = 'light'

const themeCookieName = 'theme'

export function detectThemeClientSide(): Theme {
  const storedTheme = Cookies.get(themeCookieName) as Theme
  if (storedTheme && AllThemes.includes(storedTheme)) {
    return storedTheme
  }
  return defaultTheme
}

export function applyThemeClientSide(theme: Theme, applyTheme: boolean): void {
  document.documentElement.classList.remove(...AllThemes.map((t) => `theme-${t}`))
  if (!applyTheme) {
    return document.body.setAttribute('data-theme', 'light')
  }
  if (theme === 'auto') {
    const dark = window.matchMedia('(prefers-color-scheme: dark)')
    if (dark.matches) {
      document.body.setAttribute('data-theme', 'dark')
    } else {
      document.body.setAttribute('data-theme', 'light')
    }
  } else {
    document.body.setAttribute('data-theme', theme)
  }
}

export function storeThemeClientSide(theme: Theme, applyTheme: boolean) {
  Cookies.set(themeCookieName, theme, { expires: 999999 })
  applyThemeClientSide(theme, applyTheme)
}
