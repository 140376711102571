import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextH3 } from '../../ui/Typography/Typography'

import styles from './SubscriptionsTradingPlatforms.module.scss'

export const SubscriptionsTradingPlatformsHelpETDPage = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  const navigate = useNavigate()

  const { scrollIntoView } = useScrollToElementIds()
  scrollIntoView([ScrollToIds.SubscriptionsTradingPlatformsHeader], {
    skipElementsViewportVisibility: true,
  })

  const handleGoBack = () => {
    navigate('/dashboard/subscriptions/trading-platforms')
  }

  return (
    <div>
      <PageHeader
        title={title}
        backButton={handleGoBack}
        id={ScrollToIds.SubscriptionsTradingPlatformsHeader}
      />
      <div className={styles.content}>
        <TextH3>{title}</TextH3>
        <div className='pt-3'>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}
