export enum FormFieldType {
  SELECT = 'select',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  DISABLED = 'disabled',
  HIDDEN = 'hidden',
}

export const isFormFieldSelectType = (value: string): boolean => {
  return FormFieldType.SELECT === value
}

export const isFormFieldTextType = (value: string): boolean => {
  return FormFieldType.TEXT === value
}

export const isFormFieldTextAreaType = (value: string): boolean => {
  return FormFieldType.TEXTAREA === value
}

export const isFormFieldDisabledType = (value: string): boolean => {
  return FormFieldType.DISABLED === value
}

export const isFormFieldHiddenType = (value: string): boolean => {
  return FormFieldType.HIDDEN === value
}
