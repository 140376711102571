import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { SelectModal, SelectModalOption } from '../../global/field/SelectModal'
import { createFormField } from '../../global/formField/FormField'
import { Modal } from '../../global/modal/Modal'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { NameDto } from '../../model/NameDto'
import { Text } from '../../ui/Typography/Typography'
import { FormSubmitValues } from '../../utils/formValidation'

import styles from './AccountSettingsPage.module.scss'

export interface DateFormatFormValues {
  dateFormatTypeId: string
}

const FormField = createFormField<DateFormatFormValues>()

const DateFormatFormUI: React.FC<FormikProps<DateFormatFormValues> & OuterProps> = (props) => {
  const { dateFormats, values, setValues } = props

  const { handleSubmit, setSubmitting } = props
  const { t } = useTranslation()

  const [isDateFormatModalOpen, setDateFormatModalOpen] = useState(false)

  const handleChangeDateFormat = (data: NameDto) => {
    setSubmitting(true)
    setValues({ dateFormatTypeId: data.id.toString() })
    handleSubmit()
  }

  return (
    <>
      <Text>{t('Profile.Choose suitable date format')}</Text>
      {isDateFormatModalOpen && (
        <DateFormatModal
          dateFormats={dateFormats}
          onSelectOption={handleChangeDateFormat}
          onClose={() => setDateFormatModalOpen(false)}
        />
      )}
      <Form className={styles.form}>
        <FormField
          name='dateFormatTypeId'
          label={t('Profile.Date Format')}
          placeholder={t('Profile.Date Format')}
          value={
            dateFormats.find((x) => x.id.toString() === values?.dateFormatTypeId?.toString())?.name
          }
          rightIcon={<DropArrowDownIcon />}
          onClick={() => setDateFormatModalOpen(true)}
        />
      </Form>
    </>
  )
}

interface DateFormatModalProps {
  dateFormats: NameDto[]
  onSelectOption(data: NameDto): void
  onClose(): void
}

const DateFormatModal: React.FC<DateFormatModalProps> = (props) => {
  const { onSelectOption, onClose, dateFormats } = props

  const { t } = useTranslation()
  const { values } = useFormikContext<DateFormatFormValues>()

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Profile.Date Format')}
          renderOptions={() => (
            <div className='control'>
              {dateFormats.map((x) => (
                <SelectModalOption
                  label={x.name}
                  value={x.id.toString() === values?.dateFormatTypeId.toString()}
                  onClick={() => onSelectOption(x)}
                  key={x.id}
                />
              ))}
            </div>
          )}
        />
      )}
    />
  )
}

interface OuterProps {
  dateFormatTypeId?: number
  dateFormats: NameDto[]
  onSubmit(values: FormSubmitValues<DateFormatFormValues>): Promise<void>
}

export const DateFormatForm = withFormik<OuterProps, DateFormatFormValues>({
  mapPropsToValues: ({ dateFormatTypeId }) => {
    return {
      dateFormatTypeId: dateFormatTypeId?.toString() || '2',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<DateFormatFormValues> = {}
    if (!values.dateFormatTypeId) {
      errors.dateFormatTypeId = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
})(DateFormatFormUI)
