import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import * as XLSX from 'xlsx'

import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../hooks/useScrollToElementIds'
import { AccountSummaryDto } from '../../model/AccountSummaryDto'
import { BrokerSummaryDto } from '../../model/BrokerSummaryDto'
import { FilterOptionsDto } from '../../model/FilterOptionsDto'
import { NameDto } from '../../model/NameDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { PageQuery, SortOrder, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { getAppliedFiltersLength, setQuery } from '../../utils/filter.utils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { IAppendableFetchResult, useFetchAppendablePage, useFetchOne } from '../../utils/useFetch'
import { scrollToTop, useScrollToTop } from '../../utils/useScrollToTop'
import { IBClientsCards } from './IBClientCard'
import { IBClientsFilterModal } from './IBClientsFilterModal'
import { IBClientsTable } from './IBClientsTable'

const initialQuery = {
  ca_search_TextSearch: undefined,
}

const initialSearchTermQuery = {
  show: false,
  searchTerm: undefined,
}

export interface IBClientsQuery extends FilterQueryProps {
  Country?: string
}

export type IBClientSort =
  | 'login'
  | 'clientName'
  | 'accountType'
  | 'registrationDate'
  | 'volume'
  | 'amount'
  | 'country'
  | 'currency'
  | 'tickmillCompany.id'

interface AccountSummaryFetchProps {
  search: SearchResponseProps
}

const useAccountSummaryFetch = (props: AccountSummaryFetchProps) => {
  const { search } = props

  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const { callback: accountSummaryCallback, forceRefresh } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      return await apiClient.getIntroducingBrokerIncomeSummaryPerAccount(
        account?.clientIntroducingBroker?.id ?? '',
        {
          ...query,
          ...search.pageQuery,
          search: { ...search.search, ca_search_TextSearch: search.searchTerm.searchTerm },
          caller: 'ca',
          languageId: locale,
        }
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search.search, search.pageQuery, search.searchTerm.searchTerm, locale]
  )
  const query = useFetchAppendablePage(accountSummaryCallback)

  return { query, forceRefresh }
}

interface BrokerSummaryFetchProps {
  search: SearchResponseProps
}

const useBrokerSummaryFetch = (props: BrokerSummaryFetchProps) => {
  const { search } = props

  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [introducingBrokerSummary, setIntroducingBrokerSummary] = useState<
    BrokerSummaryDto | undefined
  >()

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true)
      apiClient
        .getIntroducingBrokerIncomeSummary({
          search: { ...search.search, ca_search_TextSearch: search.searchTerm.searchTerm },
          pageIndex: 1,
          caller: 'ca',
          languageId: locale,
        })
        .then((res) => {
          setIntroducingBrokerSummary(res)
        })
        .finally(() => setIsLoading(false))
    }, 500)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.search, search.searchTerm.searchTerm])

  return { introducingBrokerSummary, isLoading }
}

interface SearchResponseProps {
  clearSearch(callback: () => void): void
  search: FilterQueryProps
  setSearch(value: FilterQueryProps): void
  pageQuery: PageQuery | undefined
  setPageQuery(value: PageQuery): void
  isFilterModal: boolean
  setFilterModalOpen(value: boolean): void
  searchTerm: SearchTermState
  showSearchTerm(value: SearchTermState): void
}

const useSearch = (props: SortResponseProps): SearchResponseProps => {
  const { sort, sortOrder } = props

  const location = useLocation()
  const { clearFilter } = useDateFilterWriteContext()

  const [isFilterModal, setFilterModalOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<FilterQueryProps>(initialQuery)
  const [searchTerm, showSearchTerm] = useState<SearchTermState>(initialSearchTermQuery)
  const [pageQuery, setPageQuery] = useState<PageQuery>({ sort, sortOrder })

  useEffect(() => {
    setSearch(initialQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const clearSearch = (callback: () => void) => {
    callback()
    setSearch(initialQuery)
    clearFilter()
  }

  return {
    clearSearch,
    search,
    setSearch,
    searchTerm,
    showSearchTerm,
    pageQuery,
    setPageQuery,
    isFilterModal,
    setFilterModalOpen,
  }
}

interface SortResponseProps {
  getSortName(): string | undefined
  closeSortModal(): void
  isSortModalOpen: boolean
  setSort(value: IBClientSort): void
  sort: IBClientSort | undefined
  sortOrder: SortOrder | undefined
  setSortOrder(value: SortOrder): void
  setSortModalOpen(value: boolean): void
  sortOptions: NameDto<string>[]
}

const useSort = (): SortResponseProps => {
  const { t } = useTranslation()

  const [sort, setSort] = useState<IBClientSort>()
  const [sortOrder, setSortOrder] = useState<SortOrder>()
  const [isSortModalOpen, setSortModalOpen] = useState(false)

  const sortOptions = [
    {
      id: 'Login',
      name: t('Trading Account.Trading Account'),
    },
    {
      id: 'ClientName',
      name: t('IB.Client Name'),
    },
    {
      id: 'AccountType',
      name: t('IB.Account Type'),
    },
    {
      id: 'RegistrationDate',
      name: t('IB.Registration Date'),
    },
    {
      id: 'Volume',
      name: t('IB.Volume'),
    },
    { id: 'Amount', name: t('IB.Commission') },
    {
      id: 'Currency',
      name: t('IB.Currency'),
    },
    {
      id: 'Country',
      name: t('IB.Country'),
    },
    {
      id: 'TickmillCompany',
      name: t('IB.Entity'),
    },
  ]

  const getSortName = () => {
    return sortOptions.find((x) => x.id === sort)?.name
  }

  const closeSortModal = () => {
    setSortModalOpen(false)
  }

  return {
    getSortName,
    closeSortModal,
    isSortModalOpen,
    setSort,
    sort,
    sortOrder,
    setSortOrder,
    setSortModalOpen,
    sortOptions,
  }
}

interface GenerateDocumentFileProps {
  accountSummary: AccountSummaryDto[]
}

const useGenerateDocumentFile = (props: GenerateDocumentFileProps) => {
  const { accountSummary } = props

  const { t } = useTranslation()

  const exportFilename = 'clients_report'

  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const { formatNumber } = useFormatNumber()
  const [isOptionsModalOpen, setOptionsModalOpen] = useState<boolean>(false)

  const handleGeneratePDF = () => {
    const data = tableBody()
    generatePDFTable({ data, fileName: exportFilename })
    setOptionsModalOpen(false)
  }

  const handleGenerateExcel = () => {
    const data = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(data)

    XLSX.utils.book_append_sheet(wb, ws, exportFilename)
    XLSX.writeFile(wb, `${exportFilename}.xlsx`)

    setOptionsModalOpen(false)
  }

  const tableBody = () => {
    const headerCsvData = [
      [
        t('Trading Account.Trading Account'),
        t('IB.Client Name'),
        t('IB.Account Type'),
        t('IB.Registration Date'),
        t('IB.Volume'),
        t('IB.Commission'),
        t('IB.Currency'),
        t('IB.Country'),
        t('IB.Entity'),
      ],
    ]
    return accountSummary.reduce((previousValue, currentValue) => {
      return previousValue.concat([
        [
          currentValue?.login?.toString(),
          currentValue.clientName,
          currentValue.accountType,
          formatDate(currentValue.registrationDate, dateFormat),
          formatNumber(currentValue.volume),
          formatNumber(currentValue.amount),
          currentValue.currency,
          currentValue.country,
          currentValue.tickmillCompany?.name || 'N/A',
        ],
      ])
    }, headerCsvData)
  }

  return {
    handleGenerateExcel,
    handleGeneratePDF,
    tableBody,
    isOptionsModalOpen,
    exportFilename,
    setOptionsModalOpen,
  }
}

export const IBClientsPage: React.FC = () => {
  useScrollToTop()
  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  const isMobile = useWindowResize()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const sort = useSort()
  const search = useSearch(sort)
  const ibAccountSummary = useAccountSummaryFetch({ search })
  const brokerSummary = useBrokerSummaryFetch({ search })

  const generateDocumentFile = useGenerateDocumentFile({
    accountSummary: ibAccountSummary.query.data,
  })

  const callbackForFilters = useCallback(
    async () => {
      if (account?.clientIntroducingBroker?.id) {
        return await apiClient.getIBClientFilterOptions(account.clientIntroducingBroker.id)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const { data: filterOptions } = useFetchOne(callbackForFilters)

  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.IBClientsHeader,
    isPaginationEntrySelected,
    ibAccountSummary.query.isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(
    ScrollToIds.IBClientsHeader,
    ibAccountSummary.query.isLoading,
    ibAccountSummary.query.meta?.pageSize
  )

  const handlePageChange = (
    pageIndex: number,
    pageSize: number,
    pagingEventType?: PagingEventType
  ) => {
    if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
      setIsPaginationEntrySelected(true)
    }
    search.setPageQuery({
      ...search.pageQuery,
      pageIndex,
      pageSize,
    })
  }

  const handleClearSearch = () => {
    search.clearSearch(() => {
      ibAccountSummary.query.setPageQuery?.(undefined)
    })
  }

  return (
    <React.Fragment>
      {search.isFilterModal && (
        <Modal
          closeModal={() => search.setFilterModalOpen(false)}
          render={({ closeModal }) => (
            <FilterModal {...search} filterOptions={filterOptions} onClose={closeModal} />
          )}
        />
      )}
      {generateDocumentFile.isOptionsModalOpen && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              fileName={generateDocumentFile.exportFilename}
              csvData={generateDocumentFile.tableBody()}
              onCloseModal={closeModal}
              onExportToCSV={closeModal}
              onExportToPdf={generateDocumentFile.handleGeneratePDF}
              onExportToExcel={generateDocumentFile.handleGenerateExcel}
            />
          )}
          closeModal={() => generateDocumentFile.setOptionsModalOpen(false)}
        />
      )}
      {isMobile && sort.isSortModalOpen && (
        <Modal
          closeModal={sort.closeSortModal}
          render={() => <SortModal sort={sort} search={search} />}
        />
      )}
      <Header
        query={ibAccountSummary.query}
        brokerSummary={brokerSummary.introducingBrokerSummary}
        sort={sort}
        search={search}
        clearSearch={handleClearSearch}
        onOptionsModalOpen={() => generateDocumentFile.setOptionsModalOpen(true)}
        onFilterModalOpen={() => search.setFilterModalOpen(true)}
        onSortModalOpen={() => sort.setSortModalOpen(true)}
      />
      <Loading isLoading={ibAccountSummary.query.isLoading} showLoadingIcon>
        {!isMobile && (
          <IBClientsTable
            data={ibAccountSummary.query.data}
            search={search}
            sortOptions={sort.sortOptions}
          />
        )}
        {isMobile && <IBClientsCards data={ibAccountSummary.query.data} search={search} />}
        {ibAccountSummary.query.meta && ibAccountSummary.query.hasInitialResults && (
          <Paging
            scrollToHeaderId={ScrollToIds.IBClientsHeader}
            maxPageSize={500}
            pageData={ibAccountSummary.query.meta}
            isLoading={ibAccountSummary.query.isLoading}
            onPageChanged={handlePageChange}
          />
        )}
      </Loading>
    </React.Fragment>
  )
}

interface HeaderProps {
  query: IAppendableFetchResult<AccountSummaryDto[]>
  brokerSummary: BrokerSummaryDto | undefined
  sort: SortResponseProps
  search: SearchResponseProps
  clearSearch(): void
  onOptionsModalOpen(): void
  onFilterModalOpen(): void
  onSortModalOpen(): void
}

const Header: React.FC<HeaderProps> = (props) => {
  const {
    query,
    brokerSummary,
    sort,
    search,
    onOptionsModalOpen,
    onFilterModalOpen,
    onSortModalOpen,
    clearSearch,
  } = props
  const location = useLocation()
  const { t } = useTranslation()
  const { formatNumber } = useFormatNumber()

  useLayoutEffect(() => {
    if (location.state?.IBClients) {
      scrollToTop()
    }
  }, [location, query.isLoading])

  if (query.hasInitialResults) {
    return (
      <PageHeader
        id={ScrollToIds.IBClientsHeader}
        title={t('IB.Clients')}
        search={{
          show: search.searchTerm.show,
          searchTerm: search.searchTerm.searchTerm,
          setShow: (v) => search.showSearchTerm({ ...search.searchTerm, show: v }),
          setSearchTerm: (v) => search.showSearchTerm({ ...search.searchTerm, searchTerm: v }),
        }}
        optionsToggle={onOptionsModalOpen}
        calculations={[
          {
            title: t('IB.Total Volume'),
            value: formatNumber(brokerSummary?.volume),
          },
          {
            title: t('IB.Total Commission'),
            value: formatNumber(brokerSummary?.amount),
          },
        ]}
        filterToggles={{
          openFilterModal: onFilterModalOpen,
          sortLabel: normalizeSortLabel(t, sort?.sortOptions, search?.pageQuery?.sort),
          openSortModal: onSortModalOpen,
          resetFilters: clearSearch,
          activeFilters: getAppliedFiltersLength({ ...search.search }),
        }}
      />
    )
  }

  return <PageHeader title={t('IB.Clients')} id={ScrollToIds.IBClientsHeader} />
}

interface FilterModalProps extends SearchResponseProps {
  filterOptions?: FilterOptionsDto
  onClose(): void
}

const FilterModal = (props: FilterModalProps) => {
  const { search, filterOptions, setSearch, pageQuery, setPageQuery, onClose } = props

  return (
    <IBClientsFilterModal
      filterOptions={filterOptions}
      currentQuery={search}
      onConfirm={({ searchFilters, currentFilter }) => {
        let q: IBClientsQuery = {
          ...setQuery(currentFilter),
          ...searchFilters,
        }
        if (!currentFilter) {
          q = Object.keys(q)
            .filter((key) => key !== 'DateCreated' && key !== 'DateFrom' && key !== 'DateTo')
            .reduce((acc, key) => {
              return Object.assign(acc, {
                [key]: q[key],
              })
            }, {})
        }
        setSearch(q)
        setPageQuery?.({
          ...pageQuery,
          pageIndex: 1,
        })
        onClose()
      }}
    />
  )
}

interface SortModalProps {
  sort: SortResponseProps
  search: SearchResponseProps
}

const SortModal: React.FC<SortModalProps> = (props) => {
  const { sort, search } = props

  return (
    <SortByModal
      onConfirm={(iBContestSort: IBClientSort, sortOrder: SortOrder) => {
        search.setPageQuery?.({
          ...search.pageQuery,
          sort: iBContestSort,
          sortOrder: sortOrder,
        })
        sort.closeSortModal()
      }}
      options={sort.sortOptions}
      onCancel={sort.closeSortModal}
    />
  )
}
