import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikProps, withFormik } from 'formik'

import { createFormField } from '../../global/formField/FormField'
import {
  LandingPageModal,
  LandingPageModalItem,
  LandingPageProductModal,
  LandingPageProductModalItem,
} from '../../global/modal/LandingPageModal'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import {
  hasProductActivatedStatus,
  isFuturesValidationDenied,
} from '../../model/AccountDetailedDto'
import { ClientLandingPageDto, ClientLandingPageSettingDto } from '../../model/LandingPageDto'
import { TickmillProductType } from '../../model/TickmillProductType'
import { Text } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { FormSubmitValues } from '../../utils/formValidation'
import { useScrollToTop } from '../../utils/useScrollToTop'

export interface LandingPageFormValues {
  landingPageId?: number
}

const FormField = createFormField<LandingPageFormValues>()

interface OuterProps {
  landingPageOptions: ClientLandingPageSettingDto[]
  landingPage?: number
  onSubmit(values: FormSubmitValues<LandingPageFormValues>): Promise<void>
}

const LandingPageFormUI: FC<FormikProps<LandingPageFormValues> & OuterProps> = (props) => {
  useScrollToTop()
  const { landingPageOptions, setFieldValue, setSubmitting } = props
  const { handleSubmit, values } = props
  const { account } = useAccountReadContext()
  const product = landingPageOptions.find((x) =>
    x.landingPages.some(({ id }) => id === values.landingPageId)
  )

  useEffect(() => {
    setSelectedLandingPageProduct(product)
    setNewLandingPageProduct(product)
  }, [product])

  const showProductSelector = account?.companyConfiguration.showProductSwitcher
  const { t } = useTranslation()
  const [landingPageModal, setLandingPageModal] = useState(false)
  const [subLandingPageModal, setSubLandingPageModal] = useState(false)
  const [selectedLandingPageProduct, setSelectedLandingPageProduct] = useState<
    ClientLandingPageSettingDto | undefined
  >(product)
  const [newLandingPageProduct, setNewLandingPageProduct] = useState<
    ClientLandingPageSettingDto | undefined
  >(product)
  const [newLandingPage, setNewLandingPage] = useState<number | undefined>(props.landingPage)

  const isFuturesDenied = isFuturesValidationDenied(account)

  const activeProducts = useMemo(
    () => landingPageOptions.filter((x) => hasProductActivatedStatus(account, x.tickmillProductId)),
    [landingPageOptions, account]
  )
  const handleSelectOption = (landingPage: ClientLandingPageDto) => {
    setNewLandingPageProduct(
      landingPageOptions.find((x) => x.landingPages.some(({ id }) => id === landingPage.id))
    )
    setNewLandingPage(landingPage.id)
  }

  const updateLandingPage = (landingPage: ClientLandingPageDto) => {
    handleSelectOption(landingPage)
    const newProduct = landingPageOptions.find((x) =>
      x.landingPages.some(({ id }) => id === landingPage.id)
    )

    setLandingPageModal(false)
    setSubLandingPageModal(false)
    setSelectedLandingPageProduct(newProduct)
    setFieldValue('landingPageId', landingPage.id)
    setSubmitting(true)
    handleSubmit()
  }

  const onSubmit = () => {
    setLandingPageModal(false)
    setSubLandingPageModal(false)
    setSelectedLandingPageProduct(newLandingPageProduct)
    setFieldValue('landingPageId', newLandingPage)
    setSubmitting(true)
    handleSubmit()
  }

  const getCurrentValues = useMemo(() => {
    const landingPages = selectedLandingPageProduct?.landingPages
    const page = landingPages?.find(({ id }) => id === values.landingPageId)?.name
    return page ?? undefined
  }, [selectedLandingPageProduct?.landingPages, values.landingPageId])

  const getLabel = (tickmillProductId: TickmillProductType) => {
    if (tickmillProductId === TickmillProductType.CFD) {
      return t('Profile.CFD Dashboard')
    } else if (tickmillProductId === TickmillProductType.ETD) {
      return t('Profile.Futures Dashboard')
    }
    return ''
  }

  return (
    <>
      {landingPageModal && (
        <LandingPageProductModal
          products={activeProducts}
          renderItem={({ item }) => {
            if (isFuturesDenied && item.tickmillProductId === TickmillProductType.ETD) {
              return null
            }
            return (
              <LandingPageProductModalItem
                label={getLabel(item.tickmillProductId)}
                subModal={
                  newLandingPageProduct?.tickmillProductId === item.tickmillProductId
                    ? {
                        required: true,
                        disabled: !item.landingPages.length,
                        renderValue: newLandingPageProduct?.landingPages?.find(
                          ({ id }) => id === newLandingPage
                        )?.name,
                        label: t('Profile.Select Landing Page'),
                        onClick: () => {
                          setSubLandingPageModal(true)
                          setLandingPageModal(false)
                        },
                      }
                    : undefined
                }
                selected={newLandingPageProduct?.tickmillProductId === item.tickmillProductId}
                product={item}
                onSelectOption={() => handleSelectOption(item.landingPages[0])}
                key={item.tickmillProductId}
              />
            )
          }}
          onSubmit={onSubmit}
          onClose={() => {
            setLandingPageModal(false)
            setSubLandingPageModal(false)
          }}
          title={t('Profile.Landing Page')}
        />
      )}
      {subLandingPageModal && newLandingPageProduct?.landingPages && (
        <LandingPageModal
          landingPages={newLandingPageProduct?.landingPages}
          renderItem={({ item }) => (
            <LandingPageModalItem
              selected={landingPageOptions.some((x) => item.id === newLandingPage)}
              landingPage={item}
              onSelectOption={() => updateLandingPage(item)}
              key={item.id}
            />
          )}
          onClose={() => {
            setSubLandingPageModal(false)
            setLandingPageModal(false)
          }}
          title={`${t('Profile.Select Landing Page')} / ${getLabel(
            newLandingPageProduct?.tickmillProductId
          )}`}
        />
      )}
      <Text>{t('Profile.Choose which page welcomes you every time you log in to Tickmill')}</Text>

      <FormField
        name='landingPageId'
        label={t('Profile.Landing Page')}
        placeholder={t('Profile.Landing Page')}
        rightIcon={<DropArrowDownIcon />}
        disabled={!landingPageOptions.length || !activeProducts.length}
        value={getCurrentValues}
        onClick={() =>
          showProductSelector ? setLandingPageModal(true) : setSubLandingPageModal(true)
        }
      />
    </>
  )
}

export const LandingPageForm = withFormik<OuterProps, LandingPageFormValues>({
  mapPropsToValues: ({ landingPage }) => {
    return {
      landingPageId: landingPage,
    }
  },
  validate: () => {},
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
})(LandingPageFormUI)
