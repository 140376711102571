import React, { useState } from 'react'
import { useFormikContext } from 'formik'

import { Loading } from '../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import {
  PaymentProviderDepositModalItem,
  PaymentProviderModal,
} from '../../../global/modal/PaymentProviderModal'
import { PaymentProvider } from '../../../model/PaymentProviderDto'
import { WalletPaymentProvider } from '../../../model/WalletPaymentProvider'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../utils/useFetch'
import { TradingAccountDepositFormValues } from './TradingAccountDepositForm/TradingAccountDepositForm'
import { useTradingAccountPaymentProviderFactory } from './useTradingAccountPaymentProviderFactory'

interface TradingAccountDepositPaymentProviderModalProps {
  onSelectOption(option: PaymentProvider, walletPaymentProvider: WalletPaymentProvider): void
  onClose(): void
}

export const TradingAccountDepositPaymentProviderModal: React.FC<
  TradingAccountDepositPaymentProviderModalProps
> = ({ onSelectOption, onClose }) => {
  const { values } = useFormikContext<TradingAccountDepositFormValues>()

  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const [isSelectLoading, setSelectLoading] = useState<boolean>(false)

  const { callback } = useCallbackWithForceRefresh(async () => {
    return apiClient.getWalletDepositMethods(locale, values.tradingAccount.wallet.id)
  }, [locale])

  const { data: paymentProviders = [], isLoading } = useFetchOne(callback)

  const { createPaymentProvider } = useTradingAccountPaymentProviderFactory()

  const handleSelectOption = async (walletPaymentProvider: WalletPaymentProvider) => {
    setSelectLoading(true)
    if (values.tradingAccount.currency) {
      const paymentProvider = await createPaymentProvider(
        walletPaymentProvider,
        values.tradingAccount
      )

      onSelectOption(paymentProvider, walletPaymentProvider)
    }
  }

  return (
    <PaymentProviderModal
      render={() => (
        <Loading isLoading={isLoading || isSelectLoading} showLoadingIcon>
          {paymentProviders.map((x) => (
            <PaymentProviderDepositModalItem
              item={x}
              onSelectOption={handleSelectOption}
              key={x.method.id}
            />
          ))}
        </Loading>
      )}
      onClose={onClose}
    />
  )
}
