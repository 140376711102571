import React, { ReactNode, useRef } from 'react'
import classNames from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import { FieldConfig as FormikFieldConfig } from 'formik/dist/Field'

import { InfoIcon } from '../../icons/InfoIcon'
import { TextTiny } from '../../ui/Typography/Typography'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'
import { DeepNonNullable } from '../types/DeepNonNullable'
import { DeepRequired } from '../types/DeepRequired'
import { TypedFieldProps } from '../types/TypedFieldProps'
import { ValidateField } from '../types/ValidateField'
import { getFieldValid, getLabel, getPlaceholder, isFocus } from './helpers'

import styles from './FormSelectField.module.scss'

type FieldConfig = Omit<FormikFieldConfig, 'name'>

interface FormFieldProps extends FieldConfig {
  namePrefix?: string
  helpText?: string
  highlighted?: boolean
  icon?: React.ReactNode
  leftIcon?: React.ReactNode
  hint?: string
  tooltip?: string
  onChange?(value: React.ChangeEvent<HTMLInputElement>): void
  onClick?(): void

  renderTooltip?(): ReactNode
}

export function RawSelectFormField<FormValues>(
  props: FormFieldProps & TypedFieldProps<FormValues>
) {
  const {
    namePrefix = '',
    name = '',
    icon,
    leftIcon,
    hint,
    className,
    children,
    tooltip,
    showLabel,
    renderTooltip,
    ...inputProps
  } = props
  const fieldRef = useRef<HTMLDivElement>(null)
  const context = useFormikContext<FormValues>()

  const value = getValue(context.values, props.value, name)
  const error = getIn(context.errors, name)
  const touched = getIn(context.touched, name)

  const label = getLabel(props)
  const placeholder = getPlaceholder(props, value)
  const isValid = getFieldValid(error, touched)
  const isArabic = useArabicSessionLanguage()

  const tooltipNode = renderTooltip ? (
    renderTooltip()
  ) : tooltip ? (
    <span data-tooltip={tooltip}>
      {' '}
      <InfoIcon />
    </span>
  ) : null

  return (
    <div className={classNames('field', styles.field, className)} ref={fieldRef}>
      {(showLabel || isFocus({ value, label })) && !!label && (
        <label
          className={classNames('label', styles.label, {
            'has-text-danger': !isValid,
          })}
        >
          {label}
          {tooltipNode}
        </label>
      )}

      <div dir={isArabic ? 'rtl' : ''} className={classNames('control', styles.isSelect)}>
        <Field
          name={namePrefix + name}
          component='select'
          {...inputProps}
          label={label}
          placeholder={placeholder}
          value={value}
          className={classNames('input', styles.input, {
            [styles.inputFocus]: isFocus({ value, label }),
            [styles.inputError]: !isValid,
            [styles.isIconLeft]: !!leftIcon,
          })}
        >
          <option value='' disabled hidden>
            {placeholder}
          </option>
          {children}
        </Field>

        <span
          className={classNames(styles.icon, styles.iconLeft, {
            [styles.inputFocus]: isFocus({ value, label }),
          })}
        >
          {leftIcon}
        </span>
      </div>

      {hint && <TextTiny className={styles.hint}>{hint}</TextTiny>}

      {!isValid && (
        <TextTiny className={classNames(styles.errorMessage, 'is-danger')}>{error}</TextTiny>
      )}
    </div>
  )
}

const getValue = <FormValues,>(values: FormValues, value: string, name: string) => {
  const fieldValue = getIn(values, name)

  return value || fieldValue || ''
}

export function createFormSelectField<FormValues>() {
  return function TypedField<Name extends ValidateField<DeepNonNullable<DeepRequired<FormValues>>>>(
    props: FormFieldProps & TypedFieldProps<DeepNonNullable<DeepRequired<FormValues>>, Name>
  ) {
    return <RawSelectFormField {...props} />
  }
}
