import { PropsWithChildren } from 'react'
import classNames from 'classnames'

import styles from './SignupWrapper.module.scss'

interface Props {
  className?: string
  reversed?: boolean
  noBackground?: boolean
}

export const SignupWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  reversed,
  noBackground,
}) => {
  return (
    <div
      className={classNames(styles.signupWrapper, className, {
        [styles.reversed]: reversed,
        [styles.noBackground]: noBackground,
      })}
    >
      {children}
    </div>
  )
}
