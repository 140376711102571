import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { useEntitySettings } from '../../../../../global/context/EntityContext'
import { useLegalLinksContext } from '../../../../../global/context/LegalLinksContext'
import { useSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { SignupFormValues, useSignup } from '../../../../../global/context/SignupContext'
import { useFetchRegulators } from '../../../../../hooks/useFetchRegulators'
import { LegalDocumentDto } from '../../../../../model/CompanyLegalDocumentDto'
import { LeadMembersEnum } from '../../../../../model/CreateLead'
import { RegulatorDto, RegulatorOptionDto } from '../../../../../model/RegulatorOptionDto'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import {
  TickmillCompaniesEnum,
  isTickmillPartnerType,
} from '../../../../../utils/companyName.utils'
import { dispatchEntitySelected } from '../../../../../utils/cookie.utils'
import { filterDuplicatesById } from '../../../../../utils/duplicates'
import { getLocationCountry } from '../../../../../utils/geolocation'
import { PersonalDetailsStep2Form, PersonalInfoStep2Template } from './PersonalDetailsStep2Form'

interface OuterProps {
  step: number
  goBack(): void
  totalSteps: number
  onSubmit(values: SignupFormValues): void
}

export const PersonalDetailsStep2Page: React.FC<OuterProps> = (props) => {
  const { step, goBack, totalSteps, onSubmit } = props
  const { signupData } = useSignup()
  const locale = useSessionLanguage()

  const regulator = useRegulator()
  const regulators = useFetchRegulators()
  const countryLocation = useFetchCountryLocation(regulators.data)

  const { documents, isLoading: isDocumentsLoading } = useLegalLinksContext({
    countryId: countryLocation.countrySelected,
    tickmillCompanyId: regulator?.tickmillCompanyId || signupData.values?.tickmillCompanyId,
  })

  const handleRegulatorSelected = (regulatorValue: RegulatorDto | undefined) => {
    regulator.setRegulatorSelected(regulatorValue)
    dispatchEntitySelected(
      regulatorValue?.tickmillCompanyId || TickmillCompaniesEnum.GENERIC,
      locale,
      signupData.values?.email,
      signupData.values?.firstName
    )
  }

  const handleCountrySelected = (country: string | undefined) => {
    countryLocation.setCountrySelected(country)
  }

  const isLoading = [
    regulators.isLoading,
    isDocumentsLoading,
    countryLocation.isLoading,
    !countryLocation.isReady,
  ].some(Boolean)
  if (isLoading) {
    return <PersonalInfoStep2Template {...props} isLoading goBack={goBack} />
  }

  return (
    <PersonalDetailsStep2Form
      step={step}
      totalSteps={totalSteps}
      formValues={signupData.values}
      groupReferences={groupReferences(documents)}
      passedDocuments={documents
        .filter(filterDocumentsByClient)
        .filter(filterDuplicatesById)
        .sort((a, b) => a.order - b.order)}
      regulatorSelected={regulator.regulatorSelected}
      regulatorOptions={regulators.data}
      countryId={countryLocation.countrySelected}
      tickmillCompanyId={regulator.tickmillCompanyId}
      onSubmit={onSubmit}
      onCountrySelectedChange={handleCountrySelected}
      onRegulatorSelectedChange={handleRegulatorSelected}
      goBack={goBack}
    />
  )
}

interface FetchCountryLocationProps {
  countrySelected: string | undefined
  setCountrySelected(value: string | undefined): void
  isLoading: boolean
  isReady: boolean
  isFailed: boolean
}

const useFetchCountryLocation = (
  regulators: RegulatorOptionDto[] = []
): FetchCountryLocationProps => {
  const searchParams = new URLSearchParams(window.location.search)
  const { signupData } = useSignup()

  const [params] = useSearchParams()
  const location = useLocation()
  const locationCountry = getLocationCountry(location)
  const apiClient = useApiClient(ClientApiClient)

  const countryId =
    signupData.values?.countryId ||
    locationCountry ||
    searchParams.get(LeadMembersEnum.COUNTRY_ID) ||
    ''

  const [countrySelected, setCountrySelected] = useState<string | undefined>(countryId)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isFailed, setFailed] = useState<boolean>(false)

  const isReady = useMemo(() => {
    return (!isLoading && !!countrySelected) || isFailed
  }, [isLoading, countrySelected, isFailed])

  useEffect(() => {
    if (!countryId && regulators.length >= 1) {
      if (locationCountry) {
        const getCountryByGeolocation = regulators.find(
          (x) => x.country.id.toLowerCase() === locationCountry.toLowerCase()
        )

        if (getCountryByGeolocation) {
          setCountrySelected(getCountryByGeolocation.country.id)
        }
        setLoading(false)
      } else {
        apiClient
          .getVisitorGeolocation()
          .then((d) => {
            const getCountryByGeolocation = regulators.find(
              (x) => x.country.id === d.country_code_iso3
            )
            if (getCountryByGeolocation && !params.get('redir') && !params.get('source')) {
              setCountrySelected(getCountryByGeolocation.country.id)
            } else {
              setCountrySelected(regulators[0].country.id) // fallback to first country
            }
            setLoading(false)
          })
          .catch((e: unknown) => {
            console.error(e)
            setCountrySelected(undefined)
            setFailed(true)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    } else {
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regulators])

  return { countrySelected, setCountrySelected, isLoading, isFailed, isReady }
}

interface RegulatorProps {
  tickmillCompanyId?: TickmillCompaniesEnum
  isPartnerType: boolean
  isRegulatorSelected: boolean
  regulatorSelected: RegulatorDto | undefined
  setRegulatorSelected(value: RegulatorDto | undefined): void
}

const useRegulator = (): RegulatorProps => {
  const [entity] = useEntitySettings()

  const [regulatorSelected, setRegulatorSelected] = useState<RegulatorDto | undefined>()

  const tickmillCompanyId = regulatorSelected?.tickmillCompanyId
  const isRegulatorSelected = !!tickmillCompanyId
  const isPartnerType = isTickmillPartnerType(entity.entity)

  if (isTickmillPartnerType(entity.entity)) {
    return {
      tickmillCompanyId: TickmillCompaniesEnum.TICKMILL_PA,
      isPartnerType,
      isRegulatorSelected: true,
      regulatorSelected,
      setRegulatorSelected,
    }
  }

  return {
    tickmillCompanyId,
    isPartnerType,
    isRegulatorSelected,
    regulatorSelected,
    setRegulatorSelected,
  }
}

const groupReferences = (documents: LegalDocumentDto[] = []): (string | null)[] => {
  const groupReferences = documents.map((x) => x.groupReference)

  return groupReferences.filter(function (item, pos) {
    return groupReferences.indexOf(item) === pos
  })
}

const filterDocumentsByClient = (document: LegalDocumentDto): boolean => {
  return document.step === 'lead'
}
