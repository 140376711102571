import { useMemo } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { useProductReadContext } from '../global/context/ProductContext'
import {
  accountHasIntroducingBroker,
  hasClearedAppropriatenessTest,
  hasConsentedToLegalDocuments,
  hasProductActivatedStatus,
  hasProductClosedStatus,
  hasProductDormantStatus,
  hasProductEmailVerifiedStatus,
  hasSubmittedAppropriatenessTest,
  isAccountDocumentManagement1MonthExpired,
  isAccountDocumentManagementExpired,
  showClosedAccountPage,
  showInactiveAccountPage,
} from '../model/AccountDetailedDto'
import {
  TickmillProductType,
  isTickmillProductTypeCFD,
  isTickmillProductTypeETD,
} from '../model/TickmillProductType'
import { useAccountReadContext } from './AccountContextContext'
import { isTickmillPartner } from './companyName.utils'
import { isTradersRoomRouteAllowed } from './permission.utils'
import { useLandingPage } from './useLandingPage'

export const noAuthPaths = [
  '/login',
  '/logout',
  '/support',
  '/forgot-password',
  '/sign-up',
  '/marketing-form',
]

export const outDatedBrowserRoutes = [
  '/dashboard/traders-room/wallets',
  '/dashboard/traders-room/trading-accounts',
  '/dashboard/traders-room/transaction-history',
  '/dashboard/traders-room/balances',
  '/dashboard/traders-room/transaction-history',
  '/dashboard/subscriptions/market-data',
  '/dashboard/subscriptions/trading-platforms',
  '/dashboard/subscriptions/history',
  '/dashboard/statements/daily',
]

export const isSignUpPath = (path: string): boolean => {
  const signUpPaths = [
    '/sign-up',
    '/logout',
    '/login',
    '/support',
    '/forgot-password',
    '/users/reset-password',
  ]
  return signUpPaths.filter((filteredPath) => path.includes(filteredPath)).length > 0
}

export const isSignUpFirstStepsPath = (path: string): boolean => {
  const signUpPaths = ['sign-up', '/users/register2']
  return signUpPaths.filter((filteredPath) => path.includes(filteredPath)).length > 0
}

export const isRegisterPath = (path: string): boolean => {
  const registerPaths = ['/users/register2', '/users/register3', '/users/register4']
  return registerPaths.filter((filteredPath) => path.includes(filteredPath)).length > 0
}

export const isVerifyPhoneEmailPath = (path: string): boolean => {
  const verifyPhoneEmailPaths = [
    '/sign-up/personal-details/verify-phone/',
    '/sign-up/personal-details/verify-email/',
  ]
  return verifyPhoneEmailPaths.filter((filteredPath) => path.includes(filteredPath)).length > 0
}

export const isNoTabsPath = (path: string, extraTabs?: string[]): boolean => {
  const noTabsPaths = [
    '/deposit',
    '/withdrawal',
    '/transfer',
    '/conversion',
    '/trading-accounts/new',
    '/trading-accounts/new-demo',
    '/change-password',
    '/demo/add-balance',
    // '/sign-up/first-time-deposit',
    '/dashboard/product-registration/appropriateness-test',
  ]
  const mergedNoTabsPaths = noTabsPaths.concat(extraTabs || [])
  return mergedNoTabsPaths.some((filteredPath) => path.includes(filteredPath))
}

export const getPath = (
  path: string
): {
  main: string
  sub: string
  tab?: string
} => {
  const paths = path.split('/')
  return {
    main: paths[1],
    sub: paths[2],
    tab: paths[3],
  }
}

enum CommonPaths {
  ClosedAccount = '/dashboard/closed-account',
  InactiveAccount = '/dashboard/inactive-account',
  // FirstDimeDeposit = '/profile/first-time-deposit',
  DocumentManagement = '/profile/document-management',
  IntroducingBroker = '/dashboard/introducing-broker/wallets',
  TradersRoomWallets = 'dashboard/traders-room/wallets',
  TradersRoomBalances = '/dashboard/traders-room/balances',
  Dashboard = '/dashboard',
  LegalDocs = '/dashboard/product-registration/legal-documents',
}

enum BasePaths {
  AppTest = '/profile/appropriateness-test',
  TakeAppTest = '/dashboard/take-app-test',
}

export const useBasePath = (): string => {
  const { account } = useAccountReadContext()

  const { getLandingPage } = useLandingPage()
  const { product } = useProductReadContext()

  if (!account) {
    return CommonPaths.Dashboard
  }
  if (!hasConsentedToLegalDocuments(account, product)) {
    return CommonPaths.LegalDocs
  } else if (
    hasProductEmailVerifiedStatus(account, product) ||
    !hasClearedAppropriatenessTest(account, product)
  ) {
    if (
      hasSubmittedAppropriatenessTest(account, product) ||
      hasProductDormantStatus(account, product) ||
      hasProductClosedStatus(account, product)
    ) {
      return BasePaths.AppTest
    } else {
      return BasePaths.TakeAppTest
    }
    // } else if (showClosedAccountPage(account, product)) return CommonPaths.ClosedAccount
    // else if (showInactiveAccountPage(account, product)) return CommonPaths.InactiveAccount
  } else if (!hasProductActivatedStatus(account, product)) {
    return CommonPaths.DocumentManagement
  } else if (isAccountDocumentManagement1MonthExpired(account)) {
    return CommonPaths.DocumentManagement
  } else if (isAccountDocumentManagementExpired(account)) {
    return CommonPaths.DocumentManagement
  } else if (isTradersRoomRouteAllowed(account)) {
    return getLandingPage()
  } else if (accountHasIntroducingBroker(account)) {
    return CommonPaths.IntroducingBroker
  } else if (isTickmillPartner(account)) {
    return CommonPaths.IntroducingBroker
  }

  return CommonPaths.Dashboard
}

export const useProductSwitchPath = (): {
  navigateToNextPath: (product: TickmillProductType) => void
} => {
  const { account } = useAccountReadContext()

  const navigate = useNavigate()
  const { getLandingPage } = useLandingPage()
  const location = useLocation()

  const getPath = (product: TickmillProductType): string => {
    if (!account) {
      return CommonPaths.Dashboard
    } else if (!hasConsentedToLegalDocuments(account, product)) {
      return CommonPaths.LegalDocs
    } else if (hasConsentedToLegalDocuments(account, product)) {
      if (isTickmillProductTypeETD(product)) {
        return CommonPaths.TradersRoomBalances
      } else if (isTickmillProductTypeCFD(product)) {
        return getLandingPage(product)
      }
      return CommonPaths.Dashboard
    } else if (showClosedAccountPage(account, product)) {
      return CommonPaths.ClosedAccount
    } else if (showInactiveAccountPage(account, product)) {
      return CommonPaths.InactiveAccount
    } else if (!hasProductActivatedStatus(account, product)) {
      return CommonPaths.DocumentManagement
    } else if (isAccountDocumentManagement1MonthExpired(account)) {
      return CommonPaths.DocumentManagement
    } else if (isAccountDocumentManagementExpired(account)) {
      return CommonPaths.DocumentManagement
    } else if (isTradersRoomRouteAllowed(account)) {
      return getLandingPage(product)
    } else if (accountHasIntroducingBroker(account)) {
      return CommonPaths.IntroducingBroker
    } else if (isTickmillPartner(account)) {
      return CommonPaths.IntroducingBroker
    }

    return CommonPaths.Dashboard
  }

  return {
    navigateToNextPath: (product) => navigate(getPath(product)),
  }
}

export const useActionPage = (): boolean => {
  const location = useLocation()
  const { isDefaultCFDProductType } = useProductReadContext()
  const paths = useMemo(
    () =>
      isDefaultCFDProductType()
        ? [
            'dashboard/traders-room/wallets/:id/deposit',
            'dashboard/traders-room/wallets/deposit',
            'dashboard/traders-room/wallets/:id/transfer',
            'dashboard/traders-room/wallets/transfer',
            'dashboard/traders-room/wallets/:id/withdrawal',
            'dashboard/traders-room/wallets/withdrawal',
            'dashboard/traders-room/trading-accounts/:id/demo/add-balance',
            'dashboard/traders-room/trading-accounts/new',
            'dashboard/traders-room/trading-accounts/new-demo',
            'profile/bank-accounts/new',
          ]
        : [
            'dashboard/traders-room/wallets/:id/deposit',
            'dashboard/traders-room/wallets/deposit',
            'dashboard/traders-room/wallets/:id/transfer',
            'dashboard/traders-room/balances/:id/transfer',
            'dashboard/traders-room/wallets/transfer',
            'dashboard/traders-room/wallets/:id/withdrawal',
            'dashboard/traders-room/wallets/withdrawal',
            'dashboard/traders-room/transactions/conversion',
            'profile/bank-accounts/new',
          ],
    [isDefaultCFDProductType]
  )
  return useMemo(
    () => paths.some((path) => matchPath({ path }, location.pathname)),
    [location, paths]
  )
}
