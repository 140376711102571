import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { Text } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'

export const DynamicLeverageModal: React.FC<{ closeModal(): void }> = ({ closeModal }) => {
  const { t } = useTranslation()
  const locale = useSessionLanguage()
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()
  const apiClient = useApiClient(ClientApiClient)
  const legalDocumentsCallback = useCallback(async () => {
    return await apiClient.getLegalDocuments(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: docs, isLoading: isDocumentLoading } = useFetchOne(legalDocumentsCallback)

  const dynamicLeverageInfoUrl =
    docs
      ?.find((doc) => doc.tickmillCompanyId === account?.tickmillCompany.id)
      ?.productCountryLegalDocuments.find((countryDoc) => countryDoc.tickmillProductId === product)
      ?.legalDocuments.find((legalDoc) => legalDoc.code === 'dynamic_leverage_info')?.url ?? ''

  return (
    <Modal
      closeModal={closeModal}
      render={({ closeModal }) => (
        <InfoModal
          onCancel={closeModal}
          title={t('Trading Account.Dynamic Leverage')}
          renderBody={() => (
            <section className='modal-card-body'>
              <Text>
                {t('Trading Account.Dynamic Leverage is a tool')}{' '}
                {!isDocumentLoading ? (
                  <a target='_blank' href={dynamicLeverageInfoUrl} rel='noopener noreferrer'>
                    {t('here')}
                  </a>
                ) : (
                  <span className='is-link'>{t('here')}</span>
                )}
                <span>.</span>
              </Text>
            </section>
          )}
          renderFooter={() => (
            <button className='button' onClick={closeModal} type='button'>
              {t('Got It')}
            </button>
          )}
          onConfirm={closeModal}
        />
      )}
    />
  )
}
