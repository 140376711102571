import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { Loading } from '../../../../global/Loading/Loading'
import { useSessionEntity } from '../../../../global/context/EntityContext'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { useFormatNumber } from '../../../../hooks/useFormatNumber'
import {
  CreatePspToWalletDepositTransaction,
  denormalizeCreatePspToWalletDepositTransaction,
} from '../../../../model/CreatePspToWalletDepositTransaction'
import {
  MasterTransaction,
  isTransactionFormRedirect,
  isTransactionHistory,
  isTransactionSuccessful,
  isTransactionUnSuccessful,
  isTransactionUrlRedirect,
} from '../../../../model/MasterTransaction'
import { PaymentProvider } from '../../../../model/PaymentProviderDto'
import { isPaymentProviderBankType } from '../../../../model/PaymentProviderType'
import { TradingAccountDetailDto } from '../../../../model/TradingAccountDetailDto'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { TradingAccountRestrictions, isRestricted } from '../../../../utils/trading-account.utils'
import { useCallbackWithForceRefresh } from '../../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../../utils/useFetch'
import { useMutation } from '../../../../utils/useMutation'
import {
  TradingAccountDepositForm,
  TradingAccountDepositFormValues,
} from '../TradingAccountDepositForm/TradingAccountDepositForm'

interface TradingAccountDepositFetchProps {
  onCancel(): void
}

export const useTradingAccountDepositFetch = (
  props: TradingAccountDepositFetchProps
): {
  data: TradingAccountDetailDto | undefined
  isLoading: boolean
} => {
  const { tradingAccountId } = useParams<{ tradingAccountId?: string }>()

  const apiClient = useApiClient(ClientApiClient)

  const { callback } = useCallbackWithForceRefresh(async () => {
    if (tradingAccountId) {
      return apiClient.getTradingAccountById(tradingAccountId)
    }
  }, [tradingAccountId])

  const { data, isLoading } = useFetchOne(callback)

  useEffect(() => {
    if (isRestricted(TradingAccountRestrictions.TRANSFER_FROM, data?.restrictions)) {
      props.onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.restrictions])

  return { data, isLoading }
}

interface TradingAccountDepositMutateProps {
  onSubmit(data: MasterTransaction, values: TradingAccountDepositFormValues): void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTradingAccountDepositMutate = (props: TradingAccountDepositMutateProps) => {
  const { onSubmit } = props
  const locale = useSessionLanguage()

  const apiClient = useApiClient(ClientApiClient)

  const handleSuccess = async (
    data: MasterTransaction,
    values: TradingAccountDepositFormValues
  ) => {
    if (isTransactionHistory(data)) {
      onSubmit(data, values)
    }

    if (isPaymentProviderBankType(values.paymentProvider.providerCategory.id)) {
      onSubmit(data, values)
    }

    if (isTransactionUrlRedirect(data)) {
      onSubmit(data, values)
    }

    if (isTransactionFormRedirect(data)) {
      onSubmit(data, values)
    }

    if (isTransactionSuccessful(data)) {
      onSubmit(data, values)
    }

    if (isTransactionUnSuccessful(data)) {
      onSubmit(data, values)
    }
  }

  const handleError = (error: AxiosError<{ message: string }>) => {
    // TODO: add error notification
  }

  return useMutation(
    async (values: TradingAccountDepositFormValues) => {
      return apiClient.transactionDeposit(
        denormalizeCreatePspToWalletDepositTransaction(
          createPspToWalletDepositTransaction(values),
          locale
        )
      )
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  )
}

interface DepositPageProps {
  tradingAccount?: TradingAccountDetailDto
  paymentProvider?: PaymentProvider
  onSubmit(data: MasterTransaction, values: TradingAccountDepositFormValues): void
  onCancel(): void
}

export const TradingAccountDepositPage: React.FC<DepositPageProps> = (props) => {
  const { tradingAccount, paymentProvider, onSubmit, onCancel } = props

  const { t } = useTranslation()
  const entity = useSessionEntity()
  const { formatMoney } = useFormatNumber()

  const mutation = useTradingAccountDepositMutate({ onSubmit })

  const handleSubmit = async (values: TradingAccountDepositFormValues) => {
    await mutation.mutate(values)
  }

  const handlePageExitConfirmation = () => {
    onCancel()
  }

  return (
    <React.Fragment>
      {!mutation.isLoading && (
        <TradingAccountDepositForm
          formatMoney={formatMoney}
          entity={entity}
          tradingAccount={tradingAccount}
          paymentProvider={paymentProvider}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
        />
      )}
      {mutation.isLoading && (
        <Loading isLoading={mutation.isLoading} showLoadingIcon text={t('Wallet.Depositing ...')} />
      )}
    </React.Fragment>
  )
}

const createPspToWalletDepositTransaction = (
  values: TradingAccountDepositFormValues
): CreatePspToWalletDepositTransaction => {
  return {
    fromPaymentProvider: {
      id: values.paymentProvider.id,
      currency: {
        id: values.paymentProvider.currency.id,
      },
    },
    toWallet: {
      id: values.tradingAccount.wallet.id,
      currency: {
        id: values.tradingAccount.currency.id,
      },
    },
    tradingAccount: {
      id: values.tradingAccount.id,
      campaign: {
        id: values.tradingAccount.campaign.id,
      },
    },
    amount: values.amount,
    comment: values.comment,
    terms: values.terms.paymentOperations,
    form: values.paymentProvider.parameters.form || [],
    fields: values.fields,
  }
}
