import React from 'react'

import { useSignup } from '../../../../../global/context/SignupContext'
import { SignupWarningMessages } from '../../../Components/SignupWarningMessages'
import { PersonalDetailsTemplate } from '../PersonalDetailsTemplate'
import { PersonalDetailsStep5Form } from './PersonalDetailsStep5Form'

export interface PersonalDetailsStep5FormValues {
  firstName: string
  password: string
  confirmPassword: string
}

interface PersonalDetailsStep5PageProps {
  step: number
  totalSteps: number
  onSubmit(values: PersonalDetailsStep5FormValues): void
  goBack(): void
}

export const PersonalDetailsStep5Page: React.FC<PersonalDetailsStep5PageProps> = (props) => {
  const { step, totalSteps, goBack, onSubmit } = props
  const { signupData } = useSignup()

  if (!signupData.lead) {
    return null
  }

  return (
    <PersonalDetailsTemplate
      lead={signupData.lead}
      step={step}
      isLoading={false}
      totalSteps={totalSteps}
      goBack={goBack}
      reversed
    >
      <SignupWarningMessages />

      <PersonalDetailsStep5Form
        initialValues={
          {
            ...signupData.values,
            firstName: signupData.lead?.firstName,
          } as PersonalDetailsStep5FormValues
        }
        leadData={{ id: signupData.lead?.id, token: signupData.lead?.token }}
        onSubmit={onSubmit}
      />
    </PersonalDetailsTemplate>
  )
}
