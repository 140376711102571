import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextSmall } from '../../ui/Typography/Typography'
import { TickmillCompaniesEnum } from '../../utils/companyName.utils'
import { dispatchCloseCookieBanner } from '../../utils/cookie.utils'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { ExternalLink } from '../ExternalLink'
import { Button } from '../button/Button'
import { useSessionEntity } from '../context/EntityContext'
import { useLegalLinksContext } from '../context/LegalLinksContext'

import styles from './CookieConsent.module.scss'

export const CookieStorageKey = 'cookieConsent'

export const CookieConsent: React.FC = () => {
  const [isVisible, setVisible] = useState(true)
  const { t } = useTranslation()
  const { docLinks } = useLegalLinksContext()
  const tickmillEntity = useSessionEntity()
  const [isPersistentlyClosed, setPersistentlyClosed] = useLocallyPersistedState(
    CookieStorageKey,
    false
  )

  const backupLink = useMemo(() => {
    switch (tickmillEntity) {
      case TickmillCompaniesEnum.TICKMILL_AS:
        return 'https://d1fyjtrsl71uh.cloudfront.net/documents/legal-documents/labuan/cookies-policy_labuan.pdf'
      case TickmillCompaniesEnum.TICKMILL_EU:
        return 'https://d1fyjtrsl71uh.cloudfront.net/documents/legal-documents/cysec/cookies-policy-april-2023.pdf'
      case TickmillCompaniesEnum.TICKMILL_UK:
        return 'https://d1fyjtrsl71uh.cloudfront.net/documents/legal-documents/fca/cookies-policy-april-2023.pdf'
      default:
        return 'https://d1fyjtrsl71uh.cloudfront.net/documents/legal-documents/fsa/cookies-policy.pdf'
    }
  }, [tickmillEntity])

  const isBannerShown = isVisible && !isPersistentlyClosed

  const onAccept = () => {
    onHide()
    setPersistentlyClosed(true)
  }

  const onHide = () => {
    dispatchCloseCookieBanner()
    setVisible((v) => !v)
  }

  return (
    <div className={styles.banner} suppressHydrationWarning={true}>
      {isBannerShown && (
        <div className='container level'>
          <TextSmall isParagraph className={styles.body}>
            {t('Cookie Message')}{' '}
            <ExternalLink className='is-link' url={docLinks?.cookieConsent ?? backupLink}>
              <u>{t('Cookie Policy')}</u>
            </ExternalLink>
          </TextSmall>
          <div className='level-right'>
            <Button appearance='secondary' size='S' state='focus' onClick={onAccept}>
              {t('Accept')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
