import { useCallback, useEffect } from 'react'

import { debounce } from '../utils/debounce'

export const useResizeEffect = (callback: () => void): void => {
  const handleResize = useCallback(() => {
    callback()
  }, [callback])

  useEffect(() => {
    const debouncedHandleResize = debounce(() => handleResize(), 100)
    debouncedHandleResize()
    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [handleResize])
}
