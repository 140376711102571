import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { WalletModal, WalletModalItem } from '../../../global/modal/WalletModal'
import { WalletDto } from '../../../model/WalletDto'
import { WalletRestrictions } from '../../../utils/wallet.utils'
import { WalletDepositFormValues } from './WalletDepositForm/WalletDepositForm'

interface WalletModalProps {
  onSelectOption(option: WalletDto): void

  onClose(): void
}

export const WalletDepositModal: React.FC<WalletModalProps> = ({ onSelectOption, onClose }) => {
  const { values } = useFormikContext<WalletDepositFormValues>()

  const handleSelectOption = (wallet: WalletDto) => {
    onSelectOption(wallet)
  }
  const { t } = useTranslation()
  return (
    <WalletModal
      title={t('Wallet.Deposit To')}
      render={({ items }) =>
        items.map((x) => {
          return (
            <WalletModalItem
              item={x}
              restrictionType={WalletRestrictions.DEPOSIT}
              selected={x?.id === values.wallet?.id}
              onSelectOption={handleSelectOption}
              key={x.id}
            />
          )
        })
      }
      onClose={onClose}
    />
  )
}
