import { isTickmillUK } from '../utils/companyName.utils'
import { AccountDetailedDto, hasProductActivatedStatus } from './AccountDetailedDto'
import { TickmillProductType, isTickmillProductTypeETD } from './TickmillProductType'

export enum AccountType {
  Individual = 1,
  Corporate = 2,
}

export const isAccountTypeIndividual = (
  accountType: AccountType
): accountType is AccountType.Individual => {
  return accountType === AccountType.Individual
}

export const isAccountTypeCorporate = (
  accountType: AccountType
): accountType is AccountType.Corporate => {
  return accountType === AccountType.Corporate
}

export const isAccountIndividual = (account: AccountDetailedDto | undefined): boolean => {
  if (account?.type?.id === undefined || account?.type?.id === null) {
    return false
  }

  return isAccountTypeIndividual(account.type.id)
}

export const isAccountCorporate = (account?: AccountDetailedDto): boolean => {
  if (account?.type?.id === undefined || account?.type?.id === null) {
    return false
  }

  return isAccountTypeCorporate(account.type.id)
}

export const showMarketDataClassification = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean =>
  !!account?.marketDataClassification &&
  isTickmillUK(account) &&
  hasProductActivatedStatus(account, product) &&
  isTickmillProductTypeETD(product)
