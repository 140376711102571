import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { MasterTransaction } from '../../../../model/MasterTransaction'
import {
  isPaymentProviderBankType,
  isPaymentProviderCardProviderType,
  isPaymentProviderPSPType,
  isPaymentProviderPaymentAgentType,
} from '../../../../model/PaymentProviderType'
import { useScrollToTop } from '../../../../utils/useScrollToTop'
import { WalletDepositTransactionFailedPage } from './WalletDepositTransactionFailedPage'
import { WalletDepositTransactionSuccessBankPage } from './WalletDepositTransactionSuccessBankPage'
import { WalletDepositTransactionSuccessPSPPage } from './WalletDepositTransactionSuccessPSPPage'
import { WalletDepositTransactionSuccessPaymentAgentPage } from './WalletDepositTransactionSuccessPaymentAgentPage'

type WalletDepositTransactionSuccessState = {
  data: MasterTransaction
  providerCategoryId: number
}
type WalletDepositTransactionSuccessPageProps = {
  onCancel(): void
}
type WalletDepositFormTransactionSuccessValues = WalletDepositTransactionSuccessState

export const WalletDepositTransactionSuccessPage: React.FC<
  WalletDepositTransactionSuccessPageProps
> = (props) => {
  useScrollToTop()
  const { onCancel } = props
  const location = useLocation()

  const [values] = useState<WalletDepositFormTransactionSuccessValues>(location.state)
  const providerCategoryId = values?.providerCategoryId

  if (isPaymentProviderBankType(providerCategoryId, values?.data)) {
    return <WalletDepositTransactionSuccessBankFactoryPage onCancel={onCancel} />
  }

  if (isPaymentProviderPSPType(providerCategoryId)) {
    return <WalletDepositTransactionSuccessPSPPage onCancel={onCancel} />
  }

  if (isPaymentProviderCardProviderType(providerCategoryId)) {
    return <WalletDepositTransactionSuccessPSPPage onCancel={onCancel} />
  }

  if (isPaymentProviderPaymentAgentType(providerCategoryId)) {
    return <WalletDepositTransactionSuccessPaymentAgentFactoryPage onCancel={onCancel} />
  }

  if (!values) {
    return <WalletDepositTransactionFailedPage onCancel={onCancel} />
  }

  return null
}

const WalletDepositTransactionSuccessBankFactoryPage: React.FC<{
  onCancel(): void
}> = ({ onCancel }) => {
  const { transaction } = useWalletDepositTransactionSuccess()

  return <WalletDepositTransactionSuccessBankPage transaction={transaction} onCancel={onCancel} />
}

const WalletDepositTransactionSuccessPaymentAgentFactoryPage: React.FC<{
  onCancel(): void
}> = ({ onCancel }) => {
  const { transaction } = useWalletDepositTransactionSuccess()

  return (
    <WalletDepositTransactionSuccessPaymentAgentPage
      transaction={transaction}
      onCancel={onCancel}
    />
  )
}

const useWalletDepositTransactionSuccess = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [values] = useState<WalletDepositFormTransactionSuccessValues>(location.state)

  useEffect(() => {
    navigate({ ...location }, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { transaction: values.data }
}
