import { TFunction } from 'i18next'

import { Locale } from '../global/locale/Locale'

export const addQuestionMark = (text: string, locale: Locale): string =>
  locale === 'ar' ? `${text}؟` : `${text}?`

export const addSwapFreePostfix = (isSwapFreeTA: boolean, accountType: string, t: TFunction) => {
  return isSwapFreeTA ? `${accountType} ${t('Trading Account.swap-free')}` : accountType
}
