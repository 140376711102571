import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { isString } from 'formik'

import { TextSmall } from '../../ui/Typography/Typography'
import { RadioBoxProps } from './RadioButton'

import styles from './RadioButton.module.scss'

interface RadioGroupProps {
  value: string | number | boolean | undefined
  horizontal?: boolean
  error?: string
  onChange(value: React.ChangeEvent<HTMLInputElement>): void
}

export const RadioGroup: React.FunctionComponent<PropsWithChildren<RadioGroupProps>> = (props) => {
  const { value, error, horizontal = false, onChange, children } = props

  const handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...event,
      target: {
        ...event.target,
        value: getValue(event.target.value) as string,
      },
    })
  }

  return (
    <div>
      <div
        className={classNames(styles.radioGroupWrapper, {
          [styles.isRadioButtonHorizontal]: horizontal,
        })}
        onChange={handleRadio}
      >
        {React.Children.map(children, (child: React.ReactNode) => {
          if (!React.isValidElement<RadioBoxProps>(child)) {
            return child
          }
          return <div>{child}</div>
        })}
      </div>
      {!!error && (
        <TextSmall isParagraph className={styles.errorMessage}>
          {error}
        </TextSmall>
      )}
    </div>
  )
}

const getValue = (value: string | number | boolean | undefined) => {
  if (value === undefined || value === null) {
    return undefined
  }

  if (isNumber(value)) {
    return Number.parseFloat(value.toString())
  }

  if (isBoolean(value)) {
    return value
  }

  if (isStringBoolean(value)) {
    return getBoolean(value)
  }

  if (isString(value)) {
    return value
  }

  return undefined
}

const isNumber = (value: string | number | boolean | undefined): value is number => {
  if (value === undefined || value === null || isBoolean(value) || isStringBoolean(value)) {
    return false
  }

  if (typeof value === 'number') {
    return true
  }

  if (value.indexOf('-')) {
    return false
  }

  return !!Number.parseFloat(value)
}

const getBoolean = (value: string | number | boolean | undefined): boolean | undefined => {
  if (value === undefined || value === null) {
    return undefined
  }

  if (isBoolean(value)) {
    return value
  }

  if (isStringBoolean(value)) {
    return value === 'true'
  }

  return undefined
}

const isBoolean = (value: string | number | boolean | undefined): value is boolean => {
  return typeof value === 'boolean'
}

const isStringBoolean = (value: string | number | boolean | undefined): value is boolean => {
  return value === 'true' || value === 'false'
}
