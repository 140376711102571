import React from 'react'

import { Tel3Client, Tel3ClientContext } from '../../utils/Tel3Client'
import { TrainingVideosPage } from './TrainingVideosPage'

export const TrainingPage = () => {
  const apiClient = new Tel3Client()

  return (
    <Tel3ClientContext.Provider value={apiClient}>
      <TrainingVideosPage />
    </Tel3ClientContext.Provider>
  )
}
