import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { ToastContext, successToast } from '../../global/toast/Toast'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { Loyalty } from './Loyalty'

export const LoyaltyPage = () => {
  useScrollToTop()
  const setToast = useContext(ToastContext)
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()

  const apiClient = useApiClient(ClientApiClient)

  const { callback, forceRefresh } = useCallbackWithForceRefresh(async () => {
    if (account?.clientIntroducingBroker?.id) {
      return await apiClient.getIntroducingBrokerById(locale, account?.clientIntroducingBroker?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, account?.clientIntroducingBroker?.id])
  const { data, isLoading } = useFetchOne(callback)

  const onSubmitReward = async (rewardId: string) => {
    await apiClient.consumeLoyaltyReward(rewardId)
    setToast(successToast(t('IB.Your reward was successfully redeemed')))
    forceRefresh()
  }

  return (
    <Loading showLoadingIcon isLoading={isLoading}>
      <Loyalty
        loyaltyTiers={data?.loyaltyDetails.tiers || []}
        introducingBroker={data || null}
        onSubmitReward={onSubmitReward}
      />
    </Loading>
  )
}
