import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { BackButton } from '../../../global/backButton/BackButton'
import { TextH3 } from '../../../ui/Typography/Typography'

import styles from './DocumentTemplate.module.scss'

interface CommandPageTemplateProps {
  title?: string | null
  goBack?(): void
}

export const DocumentTemplate: React.FunctionComponent<
  PropsWithChildren<CommandPageTemplateProps>
> = ({ children, title, goBack }) => {
  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.header)}>
        {goBack && <BackButton onClick={goBack} />}
        <TextH3>{title}</TextH3>
        <div className={styles.emptyBlock} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
