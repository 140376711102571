import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { SelectField } from '../../../global/field/SelectField'
import IconButton from '../../../global/iconButton/IconButton'
import { ScrollToIds, useScrollToElementIds } from '../../../hooks/useScrollToElementIds'
import { CheckCircledIcon } from '../../../icons/CheckCircledIcon'
import { SyncIcon } from '../../../icons/SyncIcon'
import { LandingPageDto } from '../../../model/LandingPageDto'
import { Text, TextH3, TextSmall } from '../../../ui/Typography/Typography'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { copyToClipboard } from '../../../utils/navigator.utils'
import { useIBReferralCodes } from '../../../utils/useIBReferralCodes'
import { useScrollToTop } from '../../../utils/useScrollToTop'
import { LandingPageCards } from '../LandingPageCards'
import { Header } from '../ReferralParts'
import { useLandingPageFetch } from '../hooks/useLandingPageFetch'
import { useLanguagesFetch } from '../hooks/useLanguagesFetch'
import { useSelectLanguage } from '../hooks/useSelectLanguage'

import styles from './LinksPage.module.scss'

const useSelectCopy = () => {
  const [copyLink, setCopyLink] = useState(false)
  const [copyHtmlCode, setCopyHtmlCode] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopyLink(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [copyLink])

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopyHtmlCode(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [copyHtmlCode])

  return {
    copyLink,
    setCopyLink,
    copyHtmlCode,
    setCopyHtmlCode,
  }
}

interface useSelectReferralLinkParams {
  selectedLandingPage?: LandingPageDto
  referralCode?: string
  setReferralCode: (value?: string) => void
  ibCode?: string
  mtCode?: string
  language: string
}

const useSelectReferralLink = ({
  selectedLandingPage,
  referralCode,
  ibCode,
  mtCode,
  setReferralCode,
  language,
}: useSelectReferralLinkParams) => {
  const domain = window.location.host

  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const [url, setUrl] = useState('')
  const [htmlCode, setHtmlCode] = useState('')

  const [isShortLinkGenerated, setIsShortLinkGenerated] = useState(false)
  const [isFullLinkGenerated, setIsFullLinkGenerated] = useState(false)

  useEffect(() => {
    setIsFullLinkGenerated(false)
    setUrl('')
    setHtmlCode('')
  }, [referralCode, language, locale])

  useEffect(() => {
    if (selectedLandingPage?.id) {
      generateFullLink()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLandingPage?.id])

  useEffect(() => {
    if (ibCode || mtCode) {
      setReferralCode(ibCode || mtCode)
    }
  }, [ibCode, mtCode])

  const generateFullLink = async () => {
    setIsShortLinkGenerated(false)
    getReferralLink(false).then((referralFullLink) => {
      const htmlCode = `<a href="${referralFullLink}" target="_blank">Tickmill</a>`
      referralFullLink && setUrl(referralFullLink)
      referralFullLink && setHtmlCode(htmlCode)
      setIsFullLinkGenerated(true)
    })
  }

  const generateShortLink = async () => {
    if (!isShortLinkGenerated) {
      getReferralLink(true).then((referralShortLink) => {
        const htmlCode = `<a href="${referralShortLink}" target="_blank">Tickmill</a>`
        referralShortLink && setUrl(referralShortLink)
        referralShortLink && setHtmlCode(htmlCode)
        setIsShortLinkGenerated(true)
      })
    }
  }

  const getReferralLink = async (generateShortenUrl = false): Promise<string | undefined> => {
    if (selectedLandingPage !== undefined) {
      const response = await apiClient.getReferralLandingPageLink(
        language,
        selectedLandingPage.id,
        domain,
        referralCode,
        generateShortenUrl
      )
      return response.shortUrl || response.url
    }
  }

  return {
    url,
    htmlCode,
    isFullLinkGenerated,
    generateShortLink,
    generateFullLink,
  }
}

export const LinksPage: React.FC = () => {
  useScrollToTop()
  const { t } = useTranslation()

  const languagesProps = useLanguagesFetch()
  const { languages, isLoadingLanguages } = languagesProps
  const { scrollIntoView } = useScrollToElementIds()
  const ibReferralCodes = useIBReferralCodes()
  const locale = useSessionLanguage()

  const [referralCode, setReferralCode] = useState(ibReferralCodes.ibCode || ibReferralCodes.mtCode)

  const { referralCodes, isLoading: isLoadingReferralCodes } = ibReferralCodes

  const selectCopyProps = useSelectCopy()
  const { setCopyLink, copyHtmlCode, setCopyHtmlCode, copyLink } = selectCopyProps
  const selectLanguageProps = useSelectLanguage({
    languages,
    referralCode,
  })
  const { selectedLandingPage, setSelectedLandingPage, language, setLanguage } = selectLanguageProps
  const { url, htmlCode, isFullLinkGenerated, generateShortLink } = useSelectReferralLink({
    selectedLandingPage,
    referralCode,
    setReferralCode,
    ibCode: ibReferralCodes.ibCode,
    mtCode: ibReferralCodes.mtCode,
    language,
  })

  const landingPagesProps = useLandingPageFetch(language)
  const { landingPages, isLoadingLandingPages } = landingPagesProps

  const isLoading = isLoadingLandingPages || isLoadingLanguages || isLoadingReferralCodes
  const hasLandingPages = landingPages && landingPages.items.length > 0

  useEffect(() => {
    scrollIntoView([ScrollToIds.ReferralMaterialsHeader], { skipElementsViewportVisibility: true })
  }, [landingPages, isLoading, locale])

  const handleLandingPageClick = async (page: LandingPageDto) => {
    setSelectedLandingPage(page)
  }

  const handleSetLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value)
  }

  const handleCopyHtmlCode = () => {
    copyToClipboard(htmlCode)
    setCopyHtmlCode(true)
  }

  const handleCopyLink = () => {
    copyToClipboard(url)
    setCopyLink(true)
  }

  return (
    <Loading isLoading={isLoading}>
      <Header name={t('Referral Materials.Get link')} />
      <div className={styles.selectorWrapper}>
        <SelectField
          className={styles.selector}
          name='language'
          label={t('Referral Materials.Select Landing Page Language')}
          onChange={handleSetLanguage}
          value={language}
          options={languages.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
        />
        <SelectField
          className={styles.selector}
          onChange={(v) => setReferralCode(v.target.value)}
          value={referralCode}
          disabled={referralCodes.length < 2}
          label={t('IB.Your Referral Code')}
          options={referralCodes.map(({ code }) => ({
            value: code,
            label: code,
          }))}
        />
      </div>
      {hasLandingPages && (
        <>
          <LandingPageCards
            landingPages={landingPages.items}
            selectedPage={selectedLandingPage}
            opPageSelect={handleLandingPageClick}
          />

          {isFullLinkGenerated && (
            <div className={styles.codeWrapper}>
              <div className='column is-half'>
                <ReferralMaterial
                  title={
                    <span className='is-flex is-justify-content-space-between'>
                      <TextH3>{t('Referral Materials.URL:')}</TextH3>
                      <IconButton onClick={generateShortLink} className={styles.inheritTextColor}>
                        <SyncIcon />
                        <span className='pl-2'>{t('Referral Materials.Generate short URL')}</span>
                      </IconButton>
                    </span>
                  }
                  description={t(
                    'Referral Materials.Copy your unique URL and share it with the world, inviting people to'
                  )}
                  value={url}
                  isCopied={copyLink}
                  onClick={handleCopyLink}
                />
              </div>
              <div className='column is-half'>
                <ReferralMaterial
                  title={<TextH3>{t('Referral Materials.HTML Code:')}</TextH3>}
                  description={t(
                    'Referral Materials.This is a piece of HTML code, which you should paste'
                  )}
                  value={htmlCode}
                  isCopied={copyHtmlCode}
                  onClick={handleCopyHtmlCode}
                />
              </div>
            </div>
          )}
        </>
      )}
    </Loading>
  )
}

interface ReferralMaterialProps {
  title: React.ReactNode
  description: string
  value: string
  isCopied: boolean
  onClick(): void
}

const ReferralMaterial = (props: ReferralMaterialProps) => {
  const { title, description, value, isCopied, onClick } = props

  const { t } = useTranslation()

  return (
    <>
      {title}
      <div className={styles.code}>
        <Text isParagraph>{value}</Text>
      </div>
      <Button appearance='secondary' size='L' onClick={onClick}>
        {isCopied ? (
          <React.Fragment>
            {t('Copied')} <CheckCircledIcon circleColor={'success'} checkColor={'white'} />
          </React.Fragment>
        ) : (
          `${t('Referral Materials.Copy To Clipboard')}`
        )}
      </Button>
      <TextSmall isParagraph className={styles.info}>
        {description}
      </TextSmall>
    </>
  )
}
