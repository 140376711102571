import { useCallback, useEffect, useRef, useState } from 'react'

import { useArabicSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { debounce } from '../../../utils/debounce'
import { toSentenceCase } from '../../../utils/format.utils'
import { TooltipDirection } from '../Bubble/Bubble'

interface InfoCardWidth {
  boxRef: React.RefObject<HTMLDivElement>
  newDirection: TooltipDirection
}

const getHorizontal = (direction?: TooltipDirection) => {
  if (direction?.toLowerCase().includes('left')) {
    return 'left'
  }
  if (direction?.toLowerCase().includes('right')) {
    return 'right'
  }
  return ''
}

const getVertical = (direction?: TooltipDirection) => {
  if (direction?.includes('top')) {
    return 'top'
  }
  if (direction?.includes('bottom')) {
    return 'bottom'
  }
  return ''
}

export const useInfoCardMove = (direction?: TooltipDirection): InfoCardWidth => {
  const boxRef = useRef<HTMLDivElement>(null)
  const [newDirection, setNewDirection] = useState<TooltipDirection>(direction ?? 'top')
  const borderOffset = 50
  const bubbleOffset = 10
  const isArabic = useArabicSessionLanguage()

  const initialHorizontal = getHorizontal(direction)
  const initialVertical = getVertical(direction)

  const handleChange = useCallback(async () => {
    const screenWidth = window.innerWidth
    const screenHeight = window.innerHeight
    const currentHorizontal = getHorizontal(newDirection)
    const currentVertical = getVertical(newDirection)

    const positionToSet = { horizontal: currentHorizontal, vertical: currentVertical }

    const rightPosition = screenWidth - (boxRef.current?.getBoundingClientRect().right ?? 0)
    const leftPosition = boxRef.current?.getBoundingClientRect().left ?? 0

    const newPositions = {
      right: isArabic ? leftPosition : rightPosition,
      left: isArabic ? rightPosition : leftPosition,
      top: boxRef.current?.getBoundingClientRect().top ?? 0,
      bottom: screenHeight - (boxRef.current?.getBoundingClientRect().bottom ?? 0),
      width: boxRef.current?.getBoundingClientRect().width ?? 0,
      height: boxRef.current?.getBoundingClientRect().height ?? 0,
    }

    if (newPositions.top < borderOffset) {
      positionToSet.vertical = 'bottom'
    }

    if (newPositions.bottom < borderOffset) {
      positionToSet.vertical = 'top'
    }

    if (screenHeight < newPositions.height + bubbleOffset * 2 + borderOffset * 3) {
      positionToSet.vertical = ''
    } else if (!positionToSet.vertical) {
      positionToSet.vertical = initialVertical
    }

    if (newPositions.left < borderOffset && newPositions.right < borderOffset) {
      positionToSet.horizontal = ''
    } else if (newPositions.left < borderOffset) {
      positionToSet.horizontal = 'right'
    } else if (newPositions.right < borderOffset) {
      positionToSet.horizontal = 'left'
    }

    if (screenWidth < 2 * (newPositions.width + bubbleOffset)) {
      if (newPositions.right < 0 || newPositions.left < 0) {
        positionToSet.horizontal = ''
      }
    } else if (!positionToSet.horizontal) {
      positionToSet.horizontal = initialHorizontal
    } else if (newPositions.right < borderOffset && newPositions.left < borderOffset) {
      positionToSet.horizontal = ''
    }

    setNewDirection(
      `${positionToSet.vertical}${
        positionToSet.vertical ? toSentenceCase(positionToSet.horizontal) : positionToSet.horizontal
      }` as TooltipDirection
    )
  }, [initialHorizontal, initialVertical, isArabic, newDirection])

  useEffect(() => {
    const debouncedHandleResizeAndScroll = debounce(() => handleChange(), 100)
    debouncedHandleResizeAndScroll()
    window.addEventListener('resize', debouncedHandleResizeAndScroll)
    window.addEventListener('scroll', debouncedHandleResizeAndScroll)

    return () => {
      window.removeEventListener('resize', debouncedHandleResizeAndScroll)
      window.removeEventListener('scroll', debouncedHandleResizeAndScroll)
    }
  }, [handleChange])

  return {
    boxRef,
    newDirection,
  }
}
