import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../../global/Paging/Paging'
import { ExpandableRow, SubTableRow, Table, TableCell } from '../../../global/table/Table'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../../hooks/useScrollToElementIds'
import { PageHeader } from '../../../ui/Table/Header/PageHeader'
import { SearchTermState } from '../../../ui/Table/Header/PageHeaderParts'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { PageQuery, useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useWindowResize } from '../../../utils/domUtils'
import { getCentralRowIdConditionally, getScrollToCardId } from '../../../utils/getItemId'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage } from '../../../utils/useFetch'
import { isZero } from '../../../utils/validations'
import { MultiTierNetworkCard } from '../MultiTierCards/MultiTierNetworkCard'
import { NetworkTableInfoModal } from '../MultiTierModals/NetworkTableInfoModal'

import styles from './MultiTierParts.module.scss'

export const MultiTierNetwork: FC = () => {
  const { t } = useTranslation()
  const [infoModal, setInfoModal] = useState(false)
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()
  const isMobile = useWindowResize()
  const [expandedRow, setExpandedRow] = useState<string>()
  const introducingBrokerId = account?.clientIntroducingBroker?.id || ''
  const [searchTerm, showSearchTerm] = useState<SearchTermState>({
    show: false,
    searchTerm: undefined,
  })
  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)

  const { callback } = useCallbackWithForceRefresh(async (query?: PageQuery) => {
    return await apiClient.getIBNetworkData(introducingBrokerId, {
      ...query,
      caller: 'ca',
    })
  }, [])

  const { data, meta, setPageQuery, pageQuery, isLoading } = useFetchAppendablePage(callback)

  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.IBMultitierNetworkHeader,
    isPaginationEntrySelected,
    isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(ScrollToIds.IBMultitierNetworkHeader, isLoading, meta?.pageSize)

  useEffect(() => {
    const timer = setTimeout(() => {
      searchTerm.searchTerm !== undefined &&
        setPageQuery!((oldPageQuery: PageQuery) => ({
          ...oldPageQuery,
          pageIndex: 1,
          ca_search_TextSearch: searchTerm.searchTerm,
        }))
    }, 500)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm.searchTerm])

  return (
    <div>
      {infoModal && <NetworkTableInfoModal setInfoModal={setInfoModal} />}
      <PageHeader
        id={ScrollToIds.IBMultitierNetworkHeader}
        search={{
          show: searchTerm.show,
          searchTerm: searchTerm.searchTerm,
          placeholder: t('Search Referral Code'),
          setShow: (v) => showSearchTerm({ ...searchTerm, show: v }),
          setSearchTerm: (v) => {
            showSearchTerm({ ...searchTerm, searchTerm: v })
          },
        }}
        title={t('IB.MultiTier.Multi-tier Network')}
        titleInfoToggle={() => setInfoModal(true)}
      />
      {data?.map((x, idx) => (
        <Table key={x.introducingBroker.id} className={styles.networkTable} spacing='none'>
          {isZero(idx) && (
            <thead>
              <tr>
                <th />
                <th className={styles.indentedColumn}>{t('IB.MultiTier.Accounts')}</th>
                <th align='center'>{t('IB.MultiTier.Level')}</th>
                <th align='center'>{t('IB.MultiTier.Clients')}</th>
              </tr>
            </thead>
          )}

          <tbody>
            <ExpandableRow
              rowId={x.introducingBroker.id}
              {...getCentralRowIdConditionally(idx, data.length, 'ib-multitier-network')}
              setExpandedRow={setExpandedRow}
              isExpanded={x.introducingBroker.id === expandedRow}
            >
              <td className={styles.indentedColumn}>{x.introducingBroker.name}</td>
              <td width={150} align='center'>
                {x.level}
              </td>
              <td width={150} align='center'>
                {x.numberOfClients}
              </td>
            </ExpandableRow>
            {x.introducingBroker.id === expandedRow &&
              x.subAccounts.map((subAccount, idx) => (
                <SubTableRow
                  key={subAccount.introducingBroker.id}
                  isLastRow={idx === x.subAccounts.length - 1}
                >
                  <TableCell noPadding cellClassName={styles.indentedColumn}>
                    {subAccount.introducingBroker.name}
                  </TableCell>
                  <TableCell noPadding align='center'>
                    {subAccount.level}
                  </TableCell>
                  <TableCell noPadding align='center'>
                    {subAccount.numberOfClients}
                  </TableCell>
                </SubTableRow>
              ))}
          </tbody>
        </Table>
      ))}
      {isMobile &&
        data?.map((x, idx) => (
          <MultiTierNetworkCard
            key={x.introducingBroker.id}
            data={x}
            cardId={getScrollToCardId(idx, data.length, 'ib-multitier-network')}
          />
        ))}

      <Loading isLoading={isLoading} showLoadingIcon />
      {meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.IBMultitierNetworkHeader}
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsPaginationEntrySelected(true)
            }
            return setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </div>
  )
}
