import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../global/button/Button'
import { createFormPasswordField } from '../global/formField/FormPasswordField'
import { usePasswordValidation } from '../ui/PasswordValidation/PasswordValidation'
import { FormSubmitValues } from '../utils/formValidation'
import { ResetPasswordCompleteFormValues, ResetPasswordFormValues } from './ResetPasswordPage'

import styles from './ForgotPasswordPage.module.scss'

const FormField = createFormPasswordField<ResetPasswordFormValues>()

const ResetPasswordFormCompleteUI: React.FC<FormikProps<ResetPasswordFormValues> & OuterProps> = (
  props
) => {
  const { handleSubmit, setSubmitting, isValid, dirty, values, email, token } = props
  const { password, confirmPassword } = values
  const { isPasswordValid, validationComponent } = usePasswordValidation({
    password,
    confirmPassword,
    passwordResetData: { email, token },
  })
  const handleSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  const { t } = useTranslation()

  return (
    <Form className={styles.form} onSubmit={handleSubmitForm}>
      <FormField
        name='password'
        type='password'
        autoComplete='new-password'
        showLabel={true}
        label={t('New Password')}
        required
      />
      <FormField
        name='confirmPassword'
        type='password'
        autoComplete='new-password'
        showLabel={true}
        label={t('Confirm Password')}
        required
      />

      {validationComponent}
      <Button
        disabled={!(isValid && dirty && isPasswordValid)}
        type='submit'
        appearance='primary'
        size='L'
        fullWidth
        className={styles.submitButton}
      >
        {t('Reset Password')}
      </Button>
    </Form>
  )
}

interface OuterProps {
  onSubmit(values: FormSubmitValues<ResetPasswordFormValues>): Promise<void>
  email: string
  token: string
}

export const ResetPasswordFormComplete = withFormik<OuterProps, ResetPasswordFormValues>({
  mapPropsToValues: () => {
    return {
      password: '',
      confirmPassword: '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<ResetPasswordCompleteFormValues> = {}
    if (!values.password) {
      errors.password = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
})(ResetPasswordFormCompleteUI)
