import React from 'react'
import classNames from 'classnames'

import { InfoIconWrapper } from '../../global/InfoIconWrapper/InfoIconWrapper'
import { TextH3 } from '../../ui/Typography/Typography'

import styles from './AccountSettingsPage.module.scss'

interface Props {
  children: React.ReactNode
  title: string
  icon?: JSX.Element
  infoToggle?: () => void
  isList?: boolean
  className?: string
}

export const AccountSettingsBox = (props: Props): JSX.Element => {
  const { children, title, icon, infoToggle, isList, className } = props

  return (
    <div className={classNames(styles.box, className)}>
      <div className={styles.boxTitle}>
        {icon}
        <TextH3>{title}</TextH3>
        {infoToggle && <InfoIconWrapper onClick={infoToggle} />}
      </div>
      {isList ? <ul className={styles.boxList}>{children}</ul> : children}
    </div>
  )
}
