import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../global/button/Button'
import { SelectField } from '../global/field/SelectField'
import { OptionSelectModal } from '../global/modal/OptionSelectModal'
import { ReportIcon } from '../icons/ReportIcon'
import { StatementType } from '../model/Statements'
import { TextH3 } from '../ui/Typography/Typography'
import { getDayOptions, getMonthOptions } from '../utils/date.utils'
import { AvailableStatementDates, getYearSplitter } from '../utils/statements'
import { useScrollToTop } from '../utils/useScrollToTop'

import styles from './Statement.module.scss'

interface StatementProps {
  onSubmit: (type: StatementType, month?: number, day?: number) => void
  availableDates?: AvailableStatementDates
  type: StatementType
}

export const StatementPage: React.FC<StatementProps> = ({ onSubmit, availableDates, type }) => {
  useScrollToTop(type)
  const { t } = useTranslation()
  const [monthModalOpen, setMonthModalOpen] = useState(false)
  const [month, setMonth] = useState<number>()
  const [dayModalOpen, setDayModalOpen] = useState(false)
  const [day, setDay] = useState<number>()
  const isDaily = useMemo(() => type === StatementType.Daily, [type])
  const dayOptions = useMemo(
    () => availableDates?.availableDayOptions.find((option) => option.month === month)?.days || [],
    [availableDates, month]
  )

  return (
    <div className={styles.statement}>
      {monthModalOpen && (
        <OptionSelectModal
          splitter={getYearSplitter(availableDates?.availableMonthOptions || [])}
          options={availableDates?.availableMonthOptions || []}
          title={t('Statements.Select Month')}
          onClose={() => setMonthModalOpen(false)}
          onSelectOption={setMonth}
          selectedOption={month}
        />
      )}
      {dayModalOpen && month && (
        <OptionSelectModal
          options={dayOptions}
          title={t('Statements.Select Day')}
          onClose={() => setDayModalOpen(false)}
          onSelectOption={setDay}
          selectedOption={day}
        />
      )}
      <div className={styles.reportIconWrapper}>
        <ReportIcon size={64} color='disabled' />
      </div>
      <TextH3 className={styles.title}>{t('Statements.Download Statement')}</TextH3>
      <div className={styles.selectorsWrapper}>
        <SelectField
          options={getMonthOptions(t)?.map(({ id, label }) => ({
            value: id,
            label,
          }))}
          radio
          disabled={!availableDates?.availableMonthOptions.length || availableDates?.lockMonths}
          value={month}
          className={styles.selectorField}
          onClick={() => setMonthModalOpen(true)}
          label={t('Statements.Select Month')}
        />
        {isDaily && (
          <SelectField
            options={getDayOptions(month)?.map(({ id, label }) => ({
              value: id,
              label,
            }))}
            radio
            value={day}
            disabled={!month}
            className={styles.selectorField}
            onClick={() => setDayModalOpen(true)}
            label={t('Statements.Select Day')}
          />
        )}
      </div>
      <Button
        disabled={!month || (isDaily && !day)}
        className={styles.button}
        appearance='primary'
        size='L'
        onClick={() => onSubmit(type, month, day)}
      >
        {t('Statements.Download Statement')}
      </Button>
    </div>
  )
}
