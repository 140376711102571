import { ReactNode, useState } from 'react'

import { Modal } from '../../global/modal/Modal'
import { Grid } from '../../ui/Layouts/Grid/Grid'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextStrong } from '../../ui/Typography/Typography'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { VendorModal } from './VendorModal'

import styles from './Vendors.module.scss'

export type VendorType = {
  name?: string
  imageUrl?: string
  description?: ReactNode
  url?: string
}

interface VendorsProps {
  vendors: VendorType[]
  title: string
}

export const Vendors = ({ vendors, title }: VendorsProps): JSX.Element => {
  useScrollToTop()
  const [showModal, setShowModal] = useState<VendorType>()

  return (
    <>
      {showModal && (
        <Modal
          size='large'
          closeModal={() => setShowModal(undefined)}
          render={({ closeModal }) => <VendorModal data={showModal} closeModal={closeModal} />}
        />
      )}
      <PageHeader title={title} />
      <Grid>
        {vendors?.map((platfomVendor) => (
          <div onClick={() => setShowModal(platfomVendor)} className={styles.card}>
            <div className={styles.cardImage}>
              <img src={platfomVendor.imageUrl} alt={platfomVendor.name} />
            </div>
            <TextStrong className={styles.title}>{platfomVendor.name}</TextStrong>
          </div>
        ))}
      </Grid>
    </>
  )
}
