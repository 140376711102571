import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { DateFilterContextWrapper } from '../IntroducingBroker/DateFilterContextWrapper'
import { DepositRedirectToProviderPage } from '../PaymentProviderRedirect/DepositRedirectToProviderPage/DepositRedirectToProviderPage'
import { PageNotFound } from '../global/notFound/PageNotFound'
import { ProfilePage } from './Profile/ProfilePage'
import { PaymentAgentTransactionHistoryPage } from './TransactionHistory/PaymentAgentTransactionHistoryPage'
import { PaymentAgentWalletTransferPage } from './Wallets/PaymentAgentTransfer/PaymentAgentWalletTransferPage'
import { PaymentAgentWalletDepositPage } from './Wallets/PaymentAgentWalletDepositPage'
import { PaymentAgentWalletDepositTransactionSuccessPage } from './Wallets/PaymentAgentWalletDepositTransactionSuccessPage'
import { PaymentAgentWalletWithdrawPage } from './Wallets/PaymentAgentWalletWithdrawPage'
import { PaymentAgentWalletWithdrawTransactionSuccessPage } from './Wallets/PaymentAgentWalletWithdrawTransactionSuccessPage'
import { PaymentAgentWalletsPage } from './Wallets/PaymentAgentWalletsPage'
import { PaymentProviderWalletDepositTransactionCompletedPage } from './Wallets/PaymentProviderWalletDepositTransactionCompletedPage'
import { PaymentProviderWalletDepositTransactionFailedPage } from './Wallets/PaymentProviderWalletDepositTransactionFailedPage'
import { PaymentProviderWalletWithdrawTransactionCompletedPage } from './Wallets/PaymentProviderWalletWithdrawTransactionCompletedPage'
import { PaymentProviderWalletWithdrawTransactionFailedPage } from './Wallets/PaymentProviderWalletWithdrawTransactionFailedPage'

interface PaymentAgentRoutesProps {
  tab?: string
}

export const PaymentAgentRoutes: React.FunctionComponent<PaymentAgentRoutesProps> = ({ tab }) => {
  if (tab === 'profile-info') {
    return (
      <Routes>
        <Route index element={<ProfilePage />} />
      </Routes>
    )
  }

  if (tab === 'wallets') {
    return (
      <Routes>
        <Route path=':walletId/deposit' element={<PaymentAgentWalletDepositPage />} />
        <Route path=':walletId/withdrawal' element={<PaymentAgentWalletWithdrawPage />} />
        <Route path=':walletId/transfer' element={<PaymentAgentWalletTransferPage />} />
        <Route
          path=':walletId/deposit/transaction/succeed'
          element={<PaymentAgentWalletDepositTransactionSuccessPage />}
        />
        <Route
          path=':walletId/deposit/transaction/completed'
          element={<PaymentProviderWalletDepositTransactionCompletedPage />}
        />
        <Route
          path=':walletId/deposit/transaction/failed'
          element={<PaymentProviderWalletDepositTransactionFailedPage />}
        />
        <Route path=':walletId/deposit/redirect' element={<DepositRedirectToProviderPage />} />
        <Route
          path=':walletId/withdraw/transaction/succeed'
          element={<PaymentAgentWalletWithdrawTransactionSuccessPage />}
        />
        <Route
          path=':walletId/withdraw/transaction/completed'
          element={<PaymentProviderWalletWithdrawTransactionCompletedPage />}
        />
        <Route
          path=':walletId/withdraw/transaction/failed'
          element={<PaymentProviderWalletWithdrawTransactionFailedPage />}
        />
        <Route index element={<PaymentAgentWalletsPage />} />
      </Routes>
    )
  }

  if (tab === 'transaction-history') {
    return (
      <DateFilterContextWrapper>
        <Routes>
          <Route index element={<PaymentAgentTransactionHistoryPage />} />
        </Routes>
      </DateFilterContextWrapper>
    )
  }

  return <PageNotFound />
}
