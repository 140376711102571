import { IconProps, iconColor } from '../../../../icons/IconProps'

export function IOSLogo(props: IconProps): JSX.Element {
  const width = props.width ?? 24
  const height = props.height ?? 24
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M16.3664 6.41406C14.4764 6.41406 13.6776 7.31594 12.3614 7.31594C11.0118 7.31594 9.98245 6.42062 8.34464 6.42062C6.74151 6.42062 5.03198 7.39937 3.94636 9.06672C2.42198 11.418 2.68073 15.8463 5.14964 19.6188C6.03276 20.9692 7.21214 22.4838 8.75901 22.5002H8.78714C10.1315 22.5002 10.5309 21.6198 12.381 21.6095H12.4092C14.2317 21.6095 14.5973 22.495 15.936 22.495H15.9642C17.511 22.4786 18.7537 20.8005 19.6368 19.4552C20.2724 18.4877 20.5087 18.002 20.9962 16.9075C17.4248 15.5519 16.851 10.4889 20.3831 8.54781C19.305 7.19781 17.7899 6.41594 16.3617 6.41594L16.3664 6.41406Z'
        fill={iconColor(props)}
      />
      <path
        d='M15.9486 1.5C14.8236 1.57641 13.5111 2.29266 12.7423 3.22781C12.0448 4.07531 11.4711 5.3325 11.6961 6.55172H11.7861C12.9842 6.55172 14.2105 5.83031 14.9267 4.90594C15.6167 4.02609 16.1398 2.77922 15.9486 1.5Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
