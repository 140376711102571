import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { SelectableModal } from '../../global/field/SelectableModal'
import { Modal } from '../../global/modal/Modal'
import { useHomePageUrl } from '../../hooks/useHomePageUrl'
import { NameDto } from '../../model/NameDto'
import { VipAccountTypeModal } from './VipModal'

import styles from './TradingAccountsPage.module.scss'

interface Props {
  accountType?: NameDto
  isDemo?: boolean
  introducingBrokerId?: string
  onClose(): void
  accountTypes: NameDto<number>[]
  onSelect(leverage: NameDto): void
}

export const AccountTypeModal: React.FC<Props> = ({
  onSelect,
  onClose,
  accountType,
  accountTypes,
  isDemo,
}) => {
  const [vipContent, setVipContent] = useState(false)

  const homePageUrl = useHomePageUrl()

  const onSelectAccountType = (typeDto?: NameDto) => {
    if (!typeDto) {
      return
    }
    if (typeDto.name === 'VIP' && !isDemo) {
      return setVipContent(true)
    }
    return onSelect(typeDto)
  }

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={() => onClose()}
      render={({ closeModal }) => {
        if (vipContent) {
          return (
            <VipAccountTypeModal
              closeModal={closeModal}
              onSelectAccountType={onSelectAccountType}
              accountTypes={accountTypes}
            />
          )
        }
        return (
          <SelectableModal
            onCancel={closeModal}
            title={t('Trading Account.Account Type')}
            renderOptions={() => (
              <div className='control'>
                {accountTypes.map((type) => {
                  return (
                    <label
                      key={type.id}
                      className={classNames(styles.text, 'radio column is-full-desktop radio')}
                    >
                      <input
                        className={styles.radio}
                        onClick={() => onSelectAccountType(type)}
                        defaultChecked={accountType?.id === type.id}
                        type='radio'
                        value={type.id}
                        name='option'
                      />{' '}
                      {type.name}
                    </label>
                  )
                })}
              </div>
            )}
            renderFooter={() => (
              <React.Fragment>
                <button
                  className='button'
                  onClick={() => {
                    window.open(`${homePageUrl}/trading/accounts-overview`, '_blank')
                    closeModal()
                  }}
                  type='button'
                >
                  {t('Learn More')}
                </button>

                <button className='button' onClick={closeModal} type='button'>
                  {t('Cancel')}
                </button>
              </React.Fragment>
            )}
          />
        )
      }}
    />
  )
}
