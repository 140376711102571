import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { NotFoundIcon } from '../../icons/NotFoundIcon'
import { TextHero } from '../../ui/Typography/Typography'
import { AuthSessionContext } from '../../utils/AuthContext'
import { usePathHistoryContext } from '../../utils/PathHistoryContext'
import { BackButton } from '../backButton/BackButton'

import colors from '../../layout/_variables.scss'
import styles from './NotFound.module.scss'

export const PageNotFound = () => {
  const { navigateToPreviousPath } = usePathHistoryContext()

  const { t } = useTranslation()

  const [auth] = useContext(AuthSessionContext)

  return (
    <div className={styles.wrapper}>
      <div>
        <div className='is-flex is-link mb-2' onClick={() => navigateToPreviousPath()}>
          <BackButton fillColor={colors.textInteractive} />
          <span className={styles.backButton}>{t('Go back')}</span>
        </div>
        <TextHero isParagraph className={styles.title}>
          404
        </TextHero>
        <h2 className='my-4'>{t('errors.Something went wrong')}</h2>
        <h4 className='text-secondary'>
          {t('errors.The page maybe broken, or the link may have been removed.')}
        </h4>
        {!auth && (
          <div className='is-flex mt-4'>
            <span>
              <Link className='is-link' to='/sign-up'>
                {t('Sign-up')}
              </Link>
            </span>
            <span className='mx-2'>{t('or')}</span>
            <span>
              <Link className='is-link' to='/login'>
                {t('Login')}
              </Link>
            </span>
          </div>
        )}

        <div className='is-flex mt-4'>
          <span className='mr-1'>{t('Having an issue?')}</span>
          <span>
            <Link className='is-link' to={auth ? '/contact-us' : '/support'}>
              {t('Contact us')}
            </Link>
          </span>
        </div>
      </div>
      <div className='is-flex is-justify-content-center is-align-items-center ml-3'>
        <NotFoundIcon />
      </div>
    </div>
  )
}
