import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import {
  overrideForZHToCNLanguage,
  useSessionLanguage,
} from '../global/context/SessionSettingsContext'
import { Tabs, defineTab } from '../global/tabs/Tabs'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { isNoTabsPath } from '../utils/path'
import { GettingStartedETDPage } from './GettingStarted/GettingStartedETDPage'
import { LearningVideos } from './LearningVideos/LearningVideos'

import styles from './Learning.module.scss'

export type Tab = 'getting-started' | 'cqg-education-videos' | 'tutorial-videos'

export const LearningETD: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { tab } = useParams<{ tab?: Tab }>()
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const languageId = overrideForZHToCNLanguage(locale)

  function* getTabs() {
    yield {
      ...defineTab('getting-started', t('Learning.Getting Started')),
    }
    yield {
      ...defineTab('cqg-education-videos', t('Learning.CQG Education Videos')),
    }
    yield {
      ...defineTab('tutorial-videos', t('Learning.Tutorial Videos')),
    }
  }

  const TabsDefinition = [...getTabs()]

  return (
    <div>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={tab || ''}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/learning/${tab}`)}
          />
        </div>
      )}
      {tab === 'getting-started' && <GettingStartedETDPage />}
      {tab === 'cqg-education-videos' && (
        <LearningVideos
          title={t('Learning.CQG Education Videos')}
          fetchVideos={() => apiClient.getEducationalVideos(languageId)}
        />
      )}
      {tab === 'tutorial-videos' && (
        <LearningVideos
          title={t('Learning.Tutorial Videos')}
          showGroups
          fetchVideos={() => apiClient.getTutorialVideos(languageId)}
        />
      )}
    </div>
  )
}
