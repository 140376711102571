import { isSubscriptionTimeInForceImmediate } from '../Subscriptions/MarketData/subscriptionUtils'
import { NameDto } from './NameDto'
import { SubscriptionBundleDto } from './SubscriptionBundleDto'
import { SubscriptionRequestState } from './SubscriptionRequestState'
import { SubscriptionRequestTypeEnum } from './SubscriptionRequestTypeEnum'
import { SubscriptionStatus } from './SubscriptionsDto'
import {
  ActiveSubscriptionRequest,
  SubscriptionRequestBadgeState,
  SubscriptionRequestType,
  TradingPlatformSubscriptionItem,
  TradingPlatformsItemProps,
  TradingPlatformsPackagesProps,
} from './TradingPlatformsDto'
import { SubscriptionTimeInForce } from './UpdateSubscriptionRequest'

export interface Subscription {
  id: string
  name: string
  description: string
  details: SubscriptionDetail[]
  subscriptionRequest?: SubscriptionRequest
  activeSubscriptionRequest?: ActiveSubscriptionRequest
}

export interface ActiveViaBundleSubscriptionRequest {
  createdDate: string
  dueDate: string
  id: string
  state: NameDto
  subscriptionDetailId: string
  subscriptionTimeInForce: NameDto
  type: NameDto
}

export interface SubscriptionDetail {
  id: string
  name: string
  description: string
  amount: number
  type: NameDto
  subscriptionRequest?: SubscriptionRequest
  excludedBySubscriptionDetailId?: string
  activeViaBundleSubscriptionRequest?: ActiveViaBundleSubscriptionRequest
  activeSubscriptionRequest?: ActiveSubscriptionRequest
}

export interface SubscriptionRequest {
  id: string
  state: NameDto
  subscriptionDetailId: string
  dueDate: string
  type: NameDto
  subscriptionTimeInForce?: NameDto
}

export enum SubscriptionDetailTypeEnum {
  Level_1 = 'Level 1',
  Level_2 = 'Level 2',
}

export const isPendingCancellation = (subscriptionRequest?: SubscriptionRequest): boolean =>
  (subscriptionRequest?.state.name !== SubscriptionRequestState.Pending &&
    subscriptionRequest?.type.name === SubscriptionRequestTypeEnum.CancelExistingSubscription) ||
  subscriptionRequest?.state.name === SubscriptionRequestState.Cancelled ||
  subscriptionRequest?.state.name === SubscriptionRequestState.InProcessCancel

export const isSubscriptionActive = (subscription?: Subscription | SubscriptionDetail): boolean =>
  !!subscription?.activeSubscriptionRequest &&
  !(
    subscription?.subscriptionRequest?.state.name === SubscriptionRequestState.Pending &&
    subscription?.subscriptionRequest.type.name ===
      SubscriptionRequestTypeEnum.CancelExistingSubscription
  )

export const isBundleActive = (bundle?: SubscriptionBundleDto): boolean =>
  bundle?.subscriptionRequest?.state.name === SubscriptionRequestState.Active ||
  bundle?.subscriptionRequest?.type.name === SubscriptionRequestTypeEnum.AddNewSubscription

export const isSubscriptionItemNotCompleted = (
  subscription?: TradingPlatformsItemProps | TradingPlatformsPackagesProps
): boolean =>
  subscription?.subscriptionRequest?.state?.name === SubscriptionRequestState.Completed &&
  !subscription?.activeSubscriptionRequest

export const isSubscriptionItemNotActive = (
  subscription?: TradingPlatformsItemProps | TradingPlatformsPackagesProps
): boolean =>
  subscription?.subscriptionRequest?.state?.name === SubscriptionRequestState.Active &&
  !subscription?.activeSubscriptionRequest

export const isSubscriptionItemCompleted = (
  subscription?: TradingPlatformsItemProps | TradingPlatformsPackagesProps
): boolean =>
  subscription?.subscriptionRequest?.state?.name === SubscriptionRequestState.Completed &&
  !!subscription?.activeSubscriptionRequest

export const isSubscriptionItemActive = (
  subscription?: TradingPlatformsItemProps | TradingPlatformsPackagesProps
): boolean => {
  return (
    subscription?.subscriptionRequest?.state?.name === SubscriptionRequestState.Active &&
    !!subscription?.activeSubscriptionRequest
  )
}

export const isSubscriptionPending = (
  subscriptionRequest: SubscriptionRequest | undefined
): boolean => subscriptionRequest?.state.name === SubscriptionRequestState.Pending

export const isSubscriptionImmediateAndPending = (
  subscriptionRequest: SubscriptionRequest | undefined
) => {
  const isSubscriptionRequestImmediate = isSubscriptionTimeInForceImmediate(subscriptionRequest)
  return (
    isSubscriptionRequestImmediate &&
    subscriptionRequest?.state.name === SubscriptionRequestState.Pending
  )
}

export const isSubscriptionDeductingFundOrInProcess = (
  subscriptionRequest: SubscriptionRequest | undefined
): boolean =>
  subscriptionRequest?.state.name === SubscriptionRequestState.DeductingFunds ||
  subscriptionRequest?.state.name === SubscriptionRequestState.InProcess

export const isCancellationRequested = (
  subscriptionRequest: SubscriptionRequest | undefined
): boolean =>
  subscriptionRequest?.state.name === SubscriptionRequestState.Pending &&
  subscriptionRequest.type.name === SubscriptionRequestTypeEnum.CancelExistingSubscription

export const isSubscriptionCancel = (subscriptionRequest?: SubscriptionRequest): boolean =>
  !!subscriptionRequest?.dueDate &&
  subscriptionRequest?.state?.name === SubscriptionRequestState.Pending &&
  subscriptionRequest?.subscriptionTimeInForce?.id !== SubscriptionTimeInForce.Immediate

export const isSubscriptionPendingAndProcessing = (
  subscriptionRequest?: SubscriptionRequest
): boolean =>
  !!subscriptionRequest?.dueDate &&
  subscriptionRequest?.state?.name === SubscriptionRequestState.Pending &&
  subscriptionRequest?.subscriptionTimeInForce?.id === SubscriptionTimeInForce.Immediate

export const getPendingAndProcessingSubscription = (
  subscription?: TradingPlatformSubscriptionItem
): SubscriptionRequestType | undefined => {
  const subscriptionRequest = subscription?.subscriptionRequest

  if (
    subscription?.activeSubscriptionRequest &&
    subscriptionRequest &&
    isPendingCancellation(subscriptionRequest)
  ) {
    return {
      ...subscriptionRequest,
      state: {
        id: SubscriptionStatus.ACTIVE,
        name: SubscriptionRequestState.Active,
      },
    }
  }

  if (
    isPendingCancellation(subscriptionRequest) ||
    subscriptionRequest?.state?.id === SubscriptionStatus.FAILED
  ) {
    return undefined
  }

  if (subscriptionRequest && isSubscriptionPendingAndProcessing(subscriptionRequest)) {
    return {
      ...subscriptionRequest,
      state: {
        id: SubscriptionStatus.IN_PROCESS,
        name: SubscriptionRequestState.InProcess,
      },
    }
  }

  return subscriptionRequest
}
