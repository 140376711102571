import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import IconButton from '../../global/iconButton/IconButton'
import { ConfirmationModal } from '../../global/modal/ConfirmationModal'
import { Modal } from '../../global/modal/Modal'
import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { InfoIcon } from '../../icons/InfoIcon'
import { isDocumentManagement2MonthExpired } from '../../model/AccountDetailedDto'
import {
  isDocumentCategoryAdditionalType,
  isDocumentCategoryAddressType,
  isDocumentCategoryAppropriatenessTestType,
  isDocumentCategoryPaymentsType,
  isDocumentCategoryPersonalType,
} from '../../model/DocumentCategories'
import {
  DocumentClientAreaDto,
  isDocumentClientAreaExpiredStatus,
} from '../../model/DocumentClientAreaDto'
import { Tooltip } from '../../ui/Popups/Tooltip/Tooltip'
import { TextSmallStrong } from '../../ui/Typography/Typography'
import { useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { DocumentBadgeStatus } from './DocumentBadgeStatus'
import { UploadedDocumentStatusHistoryModal } from './UploadedDocumentStatusHistory'
import { DeleteButton, DocumentLink, DownloadButton, RefreshButton } from './UploadedDocumentsTable'

import styles from './UploadedDocumentCard.module.scss'

interface Props {
  documents: DocumentClientAreaDto[]
  forceRefresh(): void
  readOnlyDocumentIds: string[]
}

export const UploadedDocumentsCards: React.FC<Props> = (props) => {
  const { documents = [], forceRefresh, readOnlyDocumentIds } = props

  return (
    <div className={styles.documentCards}>
      {documents.map((x, index) => (
        <UploadedDocumentCard
          document={x}
          forceRefresh={forceRefresh}
          readOnlyDocumentIds={readOnlyDocumentIds}
          key={`uploadedDocumentCard-${index}`}
          cardId={`uploaded-document-card-${index + 1}`}
        />
      ))}
    </div>
  )
}

interface CardProps {
  document: DocumentClientAreaDto
  forceRefresh(): void
  readOnlyDocumentIds: string[]
  cardId: string
}

const UploadedDocumentCard: React.FC<CardProps> = (props) => {
  const { document, forceRefresh, cardId } = props

  const { t } = useTranslation()
  const { scrollIntoView } = useScrollToElementIds()
  const navigate = useNavigate()
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const [isExpanded, setIsExpanded] = useState(false)
  const [isDocumentDeleteConfirmationModalOpen, setIsDocumentDeleteConfirmationModalOpen] =
    useState(false)
  const [isUploadedDocumentStatusHistoryModalOpen, setIsUploadedDocumentStatusHistoryModalOpen] =
    useState(false)

  const goTo = (path: string) => {
    navigate(path, {
      state: {
        document,
        documentCategory: document.category,
        documentCategoryType: document.type,
      },
    })
  }

  const handleDocumentRenew = () => {
    if (document) {
      const { id } = document.category
      if (isDocumentCategoryPersonalType(id)) {
        goTo('/profile/document-management/identification')
      } else if (isDocumentCategoryAddressType(id)) {
        goTo('/profile/document-management/proof-of-address')
      } else if (isDocumentCategoryAppropriatenessTestType(id)) {
        goTo('/profile/document-management/company-proof')
      } else if (isDocumentCategoryAdditionalType(id)) {
        goTo('/profile/document-management/additional')
      } else if (isDocumentCategoryPaymentsType(id)) {
        goTo('/profile/document-management/payments')
      }
    }
  }

  const handleDocumentDelete = async () => {
    if (document) {
      try {
        await apiClient.deleteDocument(document?.id)
      } finally {
        handleCloseConfirmationModal()
        forceRefresh()
      }
    }
  }

  const handleIsExpandedOnClick = (cardId: string) => {
    if (!isExpanded) {
      scrollIntoView([cardId])
    }
    setIsExpanded((prev) => !prev)
  }

  const handleConfirmationModalOpen = () => {
    setIsDocumentDeleteConfirmationModalOpen(true)
  }

  const handleCloseConfirmationModal = () => {
    setIsDocumentDeleteConfirmationModalOpen(false)
  }

  const handleUploadedDocumentStatusHistoryModalClose = () => {
    setIsUploadedDocumentStatusHistoryModalOpen(false)
  }

  const stopEventPropagationCallback = (callback?: () => void) => (event: React.MouseEvent) => {
    event.stopPropagation()
    callback?.()
  }

  return (
    <>
      {isUploadedDocumentStatusHistoryModalOpen && (
        <UploadedDocumentStatusHistoryModal
          documentClientArea={document}
          onClose={handleUploadedDocumentStatusHistoryModalClose}
        />
      )}
      {isDocumentDeleteConfirmationModalOpen && (
        <Modal
          closeModal={handleCloseConfirmationModal}
          render={({ closeModal }) => (
            <DocumentDeleteConfirmationModal
              onConfirm={handleDocumentDelete}
              onClose={closeModal}
            />
          )}
        />
      )}
      <div className={styles.card} onClick={() => handleIsExpandedOnClick(cardId)} id={cardId}>
        {!isExpanded && (
          <>
            <div className={styles.title}>
              <TextSmallStrong>{document.type.name}</TextSmallStrong>
              <IconButton className={styles.badge}>
                <DocumentBadgeStatus {...document.status} />
              </IconButton>
            </div>
            <div className={styles.subtitle}>
              <span className={styles.secondary}>{t('Profile.Upload date')}</span>
              <span>{formatDate(document.uploadedDate, dateFormat)}</span>
            </div>
          </>
        )}
        {isExpanded && (
          <>
            <div className={styles.title}>
              <TextSmallStrong>{document.type.name}</TextSmallStrong>
            </div>
            <div className={styles.subtitle}>
              <span className={styles.secondary}>{t('Profile.Document Name')}</span>
              <span
                className={classNames(styles.contrastMax, styles.trimmedName)}
                onClick={stopEventPropagationCallback()}
              >
                <DocumentLink document={document} />
              </span>
            </div>
            <div className={styles.subtitle}>
              <span className={styles.secondary}>{t('Profile.Upload date')}</span>
              <span>{formatDate(document.uploadedDate, dateFormat)}</span>
            </div>
            <div className={styles.subtitle}>
              <span className={classNames('is-flex is-flex-direction-row', styles.secondary)}>
                {t('Profile.Expiry date')}
                {!isDocumentClientAreaExpiredStatus(document) && (
                  <ExpiryWarning document={document} onClick={stopEventPropagationCallback()} />
                )}
              </span>
              <span
                className={classNames({
                  [styles.error]: isDocumentClientAreaExpiredStatus(document),
                })}
              >
                {document.expiryDate && formatDate(document.expiryDate, dateFormat)}
              </span>
            </div>
            <div className={styles.subtitle}>
              <span className={styles.secondary}> {t('Profile.Status')}</span>
              <IconButton
                onClick={stopEventPropagationCallback(() =>
                  setIsUploadedDocumentStatusHistoryModalOpen(true)
                )}
              >
                <DocumentBadgeStatus {...document.status} />
              </IconButton>
            </div>
            <div className={classNames(styles.subtitle, 'mt-2')}>
              <RefreshButton
                document={document}
                onClick={stopEventPropagationCallback(handleDocumentRenew)}
              />
              <DownloadButton document={document} onClick={stopEventPropagationCallback()} />
              <DeleteButton
                {...props}
                document={document}
                onClick={stopEventPropagationCallback(handleConfirmationModalOpen)}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

interface ExpiryWarningProps {
  document: DocumentClientAreaDto
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export const ExpiryWarning: React.FC<ExpiryWarningProps> = ({ document, onClick }) => {
  const { expiryDate } = document
  const { t } = useTranslation()

  if (!expiryDate) {
    return null
  }

  if (isDocumentManagement2MonthExpired(expiryDate)) {
    return (
      <div
        className={classNames('is-flex is-align-items-center pl-2', styles.expirationWarning)}
        onClick={onClick}
      >
        <Tooltip autoHide={2000} content={t('Expires soon')} direction={'topRight'}>
          <InfoIcon size={12} />
        </Tooltip>
      </div>
    )
  }

  return null
}

interface DocumentDeleteConfirmationModalProps {
  onConfirm(): void
  onClose(): void
}

const DocumentDeleteConfirmationModal: React.FC<DocumentDeleteConfirmationModalProps> = (props) => {
  const { onConfirm, onClose } = props

  const { t } = useTranslation()

  return (
    <ConfirmationModal
      title={t('Profile.Uploaded Document')}
      renderFooter={() => (
        <React.Fragment>
          <button className='button' onClick={onClose} type='button'>
            {t('Cancel')}
          </button>
          <button className='button' onClick={onConfirm} type='button'>
            {t('Confirm')}
          </button>
        </React.Fragment>
      )}
      onConfirm={onConfirm}
      onCancel={onClose}
    >
      <p>{t('Profile.Do you really want to delete this document?')}</p>
    </ConfirmationModal>
  )
}
