import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import IconButton from '../../global/iconButton/IconButton'
import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table, TableBody, TableCell } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../icons/ChevronUpIcon'
import { DotsIcon } from '../../icons/DotsIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import { NameDto } from '../../model/NameDto'
import { PlatformTypeEnum } from '../../model/PlatformTypeEnum'
import {
  TradingAccountTransactionDto,
  TransactionDto,
  WalletTransactionDto,
  isTransactionTradingAccountBalanceConversion,
} from '../../model/TransactionDto'
import { TransactionStatus } from '../../model/TransactionStatus'
import { CurrencyType } from '../../model/WalletDto'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { PageQuery } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { getCentralRowIdConditionally } from '../../utils/getItemId'
import {
  TransactionFields,
  TransactionType,
  getClientTransactionFieldsByTransactionType,
  getTransactionType,
  isTradingAccountTransactionType,
} from '../../utils/transaction.utils'
import { isOne, isZero } from '../../utils/validations'
import { TransactionBadgeStatus } from './TransactionBadgeStatus'

import styles from './TransactionHistoryPage.module.scss'

interface Props {
  data: TransactionDto[]

  isLoading: boolean
  pageQuery?: PageQuery
  activeFilters: number
  setPageQuery: ((pageQuery?: PageQuery | undefined) => void) | undefined

  setSelectedTransaction(id: string): void

  setHistoryModal(v: boolean): void

  setOptionModal({
    data,
    visible,
    step,
  }: {
    data?: TransactionDto
    visible: boolean
    step: number
  }): void
}

export const getCreditTransaction = (
  transactionType: TransactionType,
  transaction: TransactionDto
): WalletTransactionDto | TradingAccountTransactionDto | undefined => {
  let result
  switch (transactionType) {
    case TransactionType.TradingAccountBalanceConversion:
      result = transaction.tradingAccountTransactions.find(
        (transaction) => transaction.directionType.name === 'Credit'
      )
      break
    case TransactionType.WalletTransfer:
      result = transaction.walletTransactions.find(
        (transaction) => transaction.directionType.name === 'Credit'
      )
      break
    case TransactionType.WalletDeposit:
      result = transaction.walletTransactions.find(
        (transaction) => transaction.directionType.name === 'Credit'
      )
      break
    case TransactionType.WalletWithdrawal:
      result = transaction.walletTransactions.find(
        (transaction) => transaction.directionType.name === 'Credit'
      )
      break
    default:
      result = undefined
  }
  return result
}

export const getDebitTransaction = (
  transactionType: TransactionType,
  transaction: TransactionDto
): WalletTransactionDto | TradingAccountTransactionDto | undefined => {
  let result
  switch (transactionType) {
    case TransactionType.TradingAccountBalanceConversion:
      result = transaction.tradingAccountTransactions.find(
        (transaction) => transaction.directionType.name === 'Debit'
      )
      break
    case TransactionType.WalletTransfer:
      result = transaction.walletTransactions.find(
        (transaction) => transaction.directionType.name === 'Debit'
      )
      break
    case TransactionType.WalletDeposit:
      result = transaction.walletTransactions.find(
        (transaction) => transaction.directionType.name === 'Debit'
      )
      break
    case TransactionType.WalletWithdrawal:
      result = transaction.walletTransactions.find(
        (transaction) => transaction.directionType.name === 'Debit'
      )
      break
    default:
      result = undefined
  }
  return result
}

export const TransactionHistoryTable: React.FC<Props> = ({
  activeFilters,
  pageQuery,
  setPageQuery,
  isLoading,
  data,
  setHistoryModal,
  setSelectedTransaction,
  setOptionModal,
}) => {
  const [expandedRow, setExpandedRow] = useState('')
  const [infoModal, setInfoModal] = useState(false)

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const { isDefaultCFDProductType } = useProductReadContext()
  const { formatMoney, formatNumber } = useFormatNumber()
  const { scrollIntoView } = useScrollToElementIds()

  const handleSetExpandedRow = (id: string) => {
    const newId = id === expandedRow ? '' : id

    if (newId) {
      scrollIntoView([`transaction-history-card-${newId}`])
    }

    setExpandedRow(newId)
  }

  const handleSetTransactionHistoryModal = (id: string) => {
    setSelectedTransaction(id)
    setHistoryModal(true)
  }

  const getReversedTransactionFields = (fields: TransactionFields): TransactionFields => {
    return {
      ...fields,
      from: fields.to,
      to: fields.from,
    }
  }

  const getPlatformTypeName = (platformType: NameDto<PlatformTypeEnum>) => {
    return platformType.id === PlatformTypeEnum.TickmillTrader
      ? t('Sign up.Tickmill Trader')
      : platformType.name
  }

  const getTAPlatform = (transaction: TransactionDto) => {
    if (
      transaction.tradingAccountTransactions.length &&
      transaction.tradingAccountTransactions[0].tradingAccount
    ) {
      const tradingAccount = transaction.tradingAccountTransactions[0].tradingAccount
      const platformTypeName = getPlatformTypeName(tradingAccount.platformType)
      return (
        <div>
          <span className={styles.secondaryTextSmall}>{t('Trading Account.Platform')}: </span>
          <span className={styles.textSmall}>{platformTypeName}</span>
        </div>
      )
    }

    return null
  }

  return (
    <React.Fragment>
      {infoModal && (
        <Modal
          closeModal={() => setInfoModal(false)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('IB.Transaction exchange rate')}
              renderBody={() => (
                <section className='modal-card-body'>
                  <p
                    className={styles.text}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'IB.Amounts based on currencies which are denominated in foreign currencies'
                      ),
                    }}
                  />
                </section>
              )}
              onConfirm={() => {
                setInfoModal(false)
              }}
            />
          )}
        />
      )}
      <Table spacing='none' className={styles.table}>
        <Loading showLoadingIcon isLoading={isLoading}>
          <thead>
            {!data.length ? (
              <tr>
                <th>{t('Type')}</th>
                <th>{t('TransactionHistory.Request Date')}</th>
                <th>{t('From')}</th>
                <th>{t('To')}</th>
                <th>{t('Wallet.Reference number')}</th>
                <th>{t('Status')}</th>
                <th className={styles.emptyAmountRow}>{t('Amount')}</th>
              </tr>
            ) : (
              <tr>
                <SortHeader
                  id='TransactionType.name'
                  sort={(pageQuery && pageQuery!.sort) || undefined}
                  sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
                  setSort={(sort, sortOrder) =>
                    setPageQuery!({
                      ...pageQuery,
                      sort,
                      sortOrder,
                    })
                  }
                  className={styles.customRow}
                >
                  {t('Type')}
                </SortHeader>
                <SortHeader
                  id='CreatedDate'
                  sort={(pageQuery && pageQuery!.sort) || undefined}
                  sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
                  setSort={(sort, sortOrder) =>
                    setPageQuery!({
                      ...pageQuery,
                      sort,
                      sortOrder,
                    })
                  }
                >
                  {t('TransactionHistory.Request Date')}
                </SortHeader>
                <th className={styles.dateRow}>{t('From')}</th>
                <th className={styles.dateRow}>{t('To')}</th>
                <th className={styles.nameRow}>{t('Wallet.Reference number')}</th>
                <SortHeader
                  id='TransactionState.name'
                  align='center'
                  sort={(pageQuery && pageQuery!.sort) || undefined}
                  sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
                  setSort={(sort, sortOrder) =>
                    setPageQuery!({
                      ...pageQuery,
                      sort,
                      sortOrder,
                    })
                  }
                  className='is-flex is-justify-content-center'
                >
                  {t('Status')}
                </SortHeader>
                <th className={styles.amountRow}>{t('Amount')}</th>
                <th />
              </tr>
            )}
          </thead>
          {!data.length ? (
            <tr>
              <td align='center' colSpan={8}>
                <NoResults
                  subtitle={activeFilters > 0 ? t('No results') : undefined}
                  hideLink={activeFilters > 0}
                  link={{ path: '/dashboard/traders-room/wallets', state: { wallets: true } }}
                />
              </td>
            </tr>
          ) : (
            data.map((transaction, index) => {
              const isReversed = transaction.type.id === TransactionType.Reversed

              const reversedTransactionType = transaction.reversedTransactions?.[0].type.id

              const transactionType =
                isReversed && reversedTransactionType
                  ? getTransactionType(reversedTransactionType)
                  : getTransactionType(transaction.type.id)
              let fields = getClientTransactionFieldsByTransactionType(
                transactionType,
                transaction,
                isDefaultCFDProductType(),
                isArabic,
                auth?.id
              )

              if (isReversed) {
                fields = getReversedTransactionFields(fields)
              }

              const showMoreMenu =
                (transaction.type.id === TransactionType.WithdrawToPaymentAgent ||
                  transaction.type.id === TransactionType.WalletWithdrawal) &&
                transaction.state.id === TransactionStatus.AwaitingApproval

              const isCrypto = fields.currency === 'BTC'

              const rate = Math.round(fields.rate * 100) / 100

              const shouldHideRate = isOne(rate) || isZero(rate)

              const isDebitTransaction = transactionType === TransactionType.WalletWithdrawal

              const showConvertedFromTo =
                transactionType === TransactionType.TradingAccountBalanceConversion

              const creditTransaction = transaction.walletTransactions.find(
                (x) => x.directionType.name === 'Credit'
              )
              const cryptoCurrencyName = creditTransaction?.otherCurrency.name
              const showCurrency =
                isCrypto &&
                cryptoCurrencyName &&
                transaction.state.id === TransactionStatus.Completed

              const isExpandable =
                !shouldHideRate || isTradingAccountTransactionType(transactionType)

              const isExpanded = expandedRow === transaction.id && isExpandable

              return (
                <TableBody
                  margined
                  spacing='none'
                  key={transaction.id}
                  id={`transaction-history-card-${transaction.id}`}
                >
                  <tr
                    {...getCentralRowIdConditionally(index, data.length, 'transaction-history')}
                    className={classNames({
                      [styles.expandableParent]: isExpanded,
                    })}
                  >
                    <td
                      className={classNames(styles.nameRow, {
                        ['has-cursor-pointer']: isExpandable,
                      })}
                      onClick={() => handleSetExpandedRow(transaction.id)}
                    >
                      <span className={styles.transactionTypeName}>
                        {isExpandable && (
                          <span className='is-flex pr-2'>
                            {isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        )}
                        <strong title={transaction.type.name}>{transaction.type.name}</strong>
                      </span>
                    </td>
                    <td className={styles.dateRow}>
                      {formatDate(transaction.createdDate, dateFormat)}
                    </td>
                    <td
                      className={classNames(styles.trimShort, styles.dateRow)}
                      title={fields.from}
                      dir={isArabic ? 'ltr' : ''}
                    >
                      {fields.from}
                    </td>
                    <td className={styles.trimShort} title={fields.to} dir={isArabic ? 'ltr' : ''}>
                      {fields.to}
                    </td>
                    <td className={styles.trimLong}>{transaction.referenceId}</td>
                    <td
                      align='center'
                      className={classNames('has-cursor-pointer', styles.customRow)}
                    >
                      <span onClick={() => handleSetTransactionHistoryModal(transaction.id)}>
                        <TransactionBadgeStatus {...transaction.state} />
                      </span>
                    </td>
                    <td className={styles.amountRow}>
                      {formatMoney(fields.amount, fields.currency as CurrencyType)}
                    </td>
                    {showMoreMenu ? (
                      <td>
                        <IconButton
                          appearance={!isDefaultCFDProductType() ? 'hoverable' : undefined}
                          onClick={() =>
                            setOptionModal({
                              step: 1,
                              visible: true,
                              data: transaction,
                            })
                          }
                        >
                          <DotsIcon />
                        </IconButton>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                  {isExpanded && (
                    <tr className={styles.expandable}>
                      <TableCell expanded colSpan={8}>
                        <div className={classNames('px-4 py-4', styles.container)}>
                          {showConvertedFromTo && (
                            <div className={styles.box}>
                              <span className={styles.secondaryTextSmall}>
                                {t('IB.Converted from')}:{' '}
                              </span>
                              <span className={styles.textSmall}>
                                {isDebitTransaction
                                  ? formatMoney(fields.amount, fields.currency as CurrencyType)
                                  : formatMoney(
                                      getCreditTransaction(transactionType, transaction)
                                        ?.otherAmount,
                                      getCreditTransaction(transactionType, transaction)
                                        ?.otherCurrency.id
                                    )}
                              </span>
                            </div>
                          )}
                          {showConvertedFromTo && (
                            <div className={styles.box}>
                              <span className={styles.secondaryTextSmall}>
                                {t('IB.Converted to')}:{' '}
                              </span>
                              <span className={styles.textSmall}>
                                {isDebitTransaction
                                  ? formatNumber(
                                      getDebitTransaction(transactionType, transaction)?.otherAmount
                                    )
                                  : formatNumber(
                                      getCreditTransaction(transactionType, transaction)?.amount
                                    )}
                              </span>
                            </div>
                          )}
                          {getTAPlatform(transaction)}
                          {!shouldHideRate && (
                            <div className={styles.box}>
                              <span className={styles.secondaryTextSmall}>
                                {t('IB.Transaction exchange rate')}:{' '}
                              </span>
                              <span className={styles.textSmall}>
                                {rate}
                                {!isTransactionTradingAccountBalanceConversion(transaction) && (
                                  <IconButton
                                    className={styles.infoIcon}
                                    onClick={() => setInfoModal(true)}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                )}
                              </span>
                            </div>
                          )}
                          {showCurrency && (
                            <div>
                              <span className='p-2 text-secondary'>{t('Currency')}:</span>{' '}
                              {cryptoCurrencyName}
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </tr>
                  )}
                </TableBody>
              )
            })
          )}
        </Loading>
      </Table>
    </React.Fragment>
  )
}
