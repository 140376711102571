import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Loading } from '../../../global/Loading/Loading'
import { useSessionEntity } from '../../../global/context/EntityContext'
import { Modal } from '../../../global/modal/Modal'
import { AccountGroupType } from '../../../model/AccountGroupType'
import { MasterTransactionDto } from '../../../model/MasterTransactionDto'
import { TradingAccount } from '../../../model/TradingAccount'
import { TransferTypeEnum } from '../../../model/TransactionDto'
import { TransactionLimitDto } from '../../../model/TransactionLimitDto'
import { WalletDto, WalletTypeEnum } from '../../../model/WalletDto'
import { Operator, PageData, useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'
import { useScrollToTop } from '../../../utils/useScrollToTop'
import { TransferForm, TransferValues, isWallet } from './TransferForm'
import { TransferSuccessModal } from './TransferSuccessModal'

import styles from './TransferForm.module.scss'

interface MatchProps {
  walletId?: string
  tradingAccountId?: string
}

export interface TransferPageProps {
  title: string
  limits: TransactionLimitDto[]
  description?: React.ReactNode
  wallets: WalletDto[]
  tradingAccounts?: PageData<TradingAccount> | undefined
  walletTypeFrom: WalletTypeEnum
  walletTypeTo: WalletTypeEnum[]

  onSubmit(): void

  onCancel(): void

  isFromCampaign?: boolean
  transferFromLabel?: string
}

export const WalletTransferPage: React.FC<TransferPageProps> = (props) => {
  const {
    title,
    description,
    wallets = [],
    tradingAccounts = undefined,
    walletTypeFrom = WalletTypeEnum['FX/CFD'],
    walletTypeTo = [WalletTypeEnum['FX/CFD']],
    onSubmit,
    isFromCampaign,
    limits,
    transferFromLabel,
    onCancel,
  } = props

  const { walletId, tradingAccountId }: MatchProps = useParams()
  const apiClient = useApiClient(ClientApiClient)

  const [masterTransaction, setMasterTransaction] = useState<MasterTransactionDto | undefined>()
  const [isSubmitting, setSubmitting] = useState(false)
  const [transferType, setTransferType] = useState<TransferTypeEnum>(
    TransferTypeEnum.WALLET_TO_WALLET
  )
  const entity = useSessionEntity()

  const processTransfer = (values: TransferValues) => {
    const { transferFrom, transferTo } = values

    if (isWallet(transferFrom) && isWallet(transferTo)) {
      return walletToWalletTransfer(values)
    } else if (isWallet(transferFrom)) {
      return walletToTradingAccountTransfer(values)
    } else if (!isWallet(transferFrom)) {
      return tradingAccountToWalletTransfer(values)
    }
  }

  const walletToWalletTransfer = ({ transferFrom, transferTo, fromAmount }: TransferValues) => {
    setTransferType(TransferTypeEnum.WALLET_TO_WALLET)
    return apiClient.transferWalletToWallet({
      caller: 'ca',
      FromWalletId: transferFrom.id,
      ToWalletId: transferTo.id,
      FromAmount: fromAmount,
      clientIpAddress: '',
      referrer_url: '',
    })
  }

  const walletToTradingAccountTransfer = ({
    transferFrom,
    transferTo,
    fromAmount,
  }: TransferValues) => {
    setTransferType(TransferTypeEnum.WALLET_TO_TA)
    return apiClient.transferWalletToTradingAccount({
      FromWalletId: transferFrom.id,
      ToTaId: transferTo.id,
      Amount: fromAmount,
      clientIpAddress: '',
      referrer_url: '',
    })
  }

  const tradingAccountToWalletTransfer = ({
    transferFrom,
    transferTo,
    fromAmount,
  }: TransferValues) => {
    setTransferType(TransferTypeEnum.TA_TO_WALLET)
    return apiClient.transferTradingAccountToWallet({
      ToWalletId: transferTo.id,
      FromTaId: transferFrom.id,
      Amount: fromAmount,
      clientIpAddress: '',
      referrer_url: '',
    })
  }

  const handleSubmit = async (values: TransferValues) => {
    try {
      setSubmitting(true)
      const masterTransaction = await processTransfer(values)
      setSubmitting(false)
      setMasterTransaction(masterTransaction)
    } catch (e: unknown) {
      // alert(error.response?.response?.data.code)
    } finally {
      setSubmitting(false)
    }
  }

  const handleCloseTransferSuccessModal = () => {
    onSubmit()
  }

  const { t } = useTranslation()

  if (masterTransaction) {
    return (
      <Modal
        size='xsmall'
        cardClassName={styles.modalContainer}
        closeModal={handleCloseTransferSuccessModal}
        render={() => (
          <TransferSuccessModal
            masterTransaction={masterTransaction}
            onCancel={handleCloseTransferSuccessModal}
            transferType={transferType}
          />
        )}
      />
    )
  }

  return (
    <Loading text={t('Wallet.Transferring...')} isLoading={isSubmitting} showLoadingIcon>
      <TransferForm
        title={title}
        entity={entity}
        description={description}
        limits={limits}
        wallets={wallets}
        tradingAccounts={tradingAccounts}
        walletId={walletId}
        walletTypeFrom={walletTypeFrom}
        walletTypeTo={walletTypeTo}
        tradingAccountId={tradingAccountId}
        handleFormSubmit={handleSubmit}
        isFromCampaign={isFromCampaign}
        transferFromLabel={transferFromLabel}
      />
    </Loading>
  )
}

export const useWalletTransferFetch = () => {
  const apiClient = useApiClient(ClientApiClient)
  const callback = useCallback(async () => {
    return Promise.all([
      apiClient.getWallets(),
      apiClient.getTransactionLimits(),
      apiClient.getTradingAccounts({
        pageSize: 150,
        search: {
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: 3,
            operator: Operator.NOT_EQUAL,
          },
          Status: {
            value: 1,
            operator: Operator.EQUAL,
          },
        },
      }),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data = [], isLoading } = useFetchOne(callback)
  const [wallets = [], limits, tradingAccounts] = data

  return { wallets, tradingAccounts, limits, isLoading }
}

interface TradersRoomWalletTransferPageProps {
  walletType: WalletTypeEnum
}

export const TradersRoomWalletTransferPage: React.FC<TradersRoomWalletTransferPageProps> = ({
  walletType,
}) => {
  useScrollToTop()

  const navigate = useNavigate()
  const { t } = useTranslation()

  const { wallets, tradingAccounts, isLoading, limits = [] } = useWalletTransferFetch()

  const handleSubmit = () => {
    navigate('/dashboard/traders-room/transaction-history')
  }

  const handlePageExitConfirmation = () => {
    navigate('/dashboard/traders-room/wallets')
  }

  return (
    <Loading isLoading={isLoading}>
      <WalletTransferPage
        limits={limits}
        title={''}
        wallets={wallets}
        tradingAccounts={tradingAccounts}
        walletTypeFrom={walletType}
        walletTypeTo={[walletType]}
        onSubmit={handleSubmit}
        onCancel={handlePageExitConfirmation}
      />
    </Loading>
  )
}

interface TradersRoomWalletStartTransferPageProps {
  walletType: WalletTypeEnum
}

export const TradersRoomWalletStartTransferPage: React.FC<
  TradersRoomWalletStartTransferPageProps
> = (props) => {
  const { walletType } = props

  const navigate = useNavigate()

  const { wallets, tradingAccounts, isLoading, limits = [] } = useWalletTransferFetch()

  const handleSubmit = () => {
    navigate('/dashboard/traders-room/transaction-history')
  }

  const handlePageExitConfirmation = () => {
    navigate('/dashboard/traders-room/wallets')
  }

  return (
    <Loading isLoading={isLoading}>
      <WalletTransferPage
        title={''}
        limits={limits}
        wallets={wallets}
        tradingAccounts={tradingAccounts}
        walletTypeFrom={walletType}
        walletTypeTo={[walletType]}
        onSubmit={handleSubmit}
        onCancel={handlePageExitConfirmation}
      />
    </Loading>
  )
}

export const CampaignWalletTransferPage: React.FC = () => {
  const navigate = useNavigate()

  const { wallets, tradingAccounts, isLoading, limits = [] } = useWalletTransferFetch()

  const handleSubmit = () => {
    navigate('/dashboard/traders-room/trading-accounts')
  }

  const handlePageExitConfirmation = () => {
    navigate('/dashboard/traders-room/wallets')
  }

  const { t } = useTranslation()

  return (
    <Loading isLoading={isLoading}>
      <WalletTransferPage
        title={''}
        limits={limits}
        wallets={wallets}
        tradingAccounts={tradingAccounts}
        walletTypeFrom={WalletTypeEnum['FX/CFD']}
        walletTypeTo={[WalletTypeEnum['FX/CFD']]}
        onSubmit={handleSubmit}
        onCancel={handlePageExitConfirmation}
        isFromCampaign={true}
        transferFromLabel={t('Wallet.Transfer From Campaign Account')}
      />
    </Loading>
  )
}
