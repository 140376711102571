import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { TransactionSuccessDialogueTemplate } from '../../../../global/templates/TransactionSuccessDialogueTemplate'
import { TextStrong } from '../../../../ui/Typography/Typography'
import { WalletDepositFormTransactionSuccessState } from './TradersRoomWalletDepositTransactionSuccessPage'

interface WalletDepositTransactionSuccessProps {
  onCancel(): void
}

export const WalletDepositTransactionSuccessPSPPage: React.FC<
  WalletDepositTransactionSuccessProps
> = ({ onCancel }) => {
  const location = useLocation()
  const { t } = useTranslation()

  const stateValue = (location.state as WalletDepositFormTransactionSuccessState) || {}
  const walletTransaction = stateValue.transaction.walletTransactions?.[0] || {}

  return (
    <TransactionSuccessDialogueTemplate title={t('Deposit Completed')} goBack={onCancel}>
      <div className='columns is-gapless is-mobile'>
        <div className='column'>{t('Wallet.To')}</div>
        <div className='column has-text-right'>
          <TextStrong>
            {walletTransaction?.wallet.currency.id} {t('Wallet.Wallet')}
          </TextStrong>
        </div>
      </div>

      <div className='columns is-gapless is-mobile'>
        <div className='column'>{t('Wallet.Amount')}</div>
        <div className='column has-text-right'>
          <TextStrong>
            {walletTransaction?.otherAmount} {walletTransaction?.otherCurrency.id}
          </TextStrong>
        </div>
      </div>

      <div className='columns is-gapless is-mobile'>
        <div className='column'>{t('Wallet.Payment Method')}</div>
        <div className='column has-text-right'>
          <TextStrong>
            {walletTransaction?.paymentProviderTransaction.paymentProvider.name}
          </TextStrong>
        </div>
      </div>
    </TransactionSuccessDialogueTemplate>
  )
}
