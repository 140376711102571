import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { useSnackbar } from '../../../global/context/SnackbarContext'
import { ConversionIcon } from '../../../icons/ConversionIcon'
import { InfoIcon } from '../../../icons/InfoIcon'
import { WithdrawIcon } from '../../../icons/WithdrawIcon'
import { WalletDto } from '../../../model/WalletDto'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { WalletRestrictions, isRestricted } from '../../../utils/wallet.utils'

import styles from './WalletBalancesOptions.module.scss'

interface WalletBalancesOptionsProps {
  wallet: WalletDto
  setWalletInformationModalOpen: (value: boolean) => void
  setWalletOptionsData?: (wallet: WalletDto) => void
}

export const WalletBalancesOptions: React.FC<WalletBalancesOptionsProps> = (props) => {
  const { wallet, setWalletOptionsData, setWalletInformationModalOpen } = props
  const { addSnackbar } = useSnackbar()

  const navigate = useNavigate()
  const { t } = useTranslation()

  const isWithdrawRestricted = isRestricted(WalletRestrictions.WITHDRAW, wallet.restrictions)
  const isTransferToRestricted = isRestricted(WalletRestrictions.TRANSFER_FROM, wallet.restrictions)

  const handleWithdrawToBankAccount = () => {
    if (isWithdrawRestricted) {
      addSnackbar.error({ message: t('This action is not available') })
    } else {
      navigate(`/dashboard/traders-room/wallets/${wallet.id}/withdrawal`)
    }
  }

  const handleConversion = () => {
    if (isTransferToRestricted) {
      addSnackbar.error({ message: t('This action is not available') })
    } else {
      navigate(`/dashboard/traders-room/transactions/conversion`, {
        state: {
          walletId: wallet.id,
        },
      })
    }
  }

  return (
    <ul className={styles.options}>
      <li
        className={classNames(styles.option, { [styles.isDisabled]: isWithdrawRestricted })}
        onClick={handleWithdrawToBankAccount}
      >
        <WithdrawIcon
          color={isWithdrawRestricted ? 'disabled' : 'text'}
          strokeWidth={1.5}
          size={24}
        />
        <AlertIconWrapper spaced isActive={isWithdrawRestricted}>
          <span className={styles.label}>{t('Wallet.Withdraw')}</span>
        </AlertIconWrapper>
      </li>
      <li
        className={classNames(styles.option, { [styles.isDisabled]: isTransferToRestricted })}
        onClick={handleConversion}
      >
        <ConversionIcon
          color={isTransferToRestricted ? 'disabled' : 'text'}
          strokeWidth={1.5}
          size={24}
        />
        <AlertIconWrapper spaced isActive={isTransferToRestricted}>
          <span className={styles.label}>{t('Wallet.Convert')}</span>
        </AlertIconWrapper>
      </li>
      <li
        className={styles.option}
        onClick={() => {
          setWalletInformationModalOpen(true)
          setWalletOptionsData?.(wallet)
        }}
      >
        <InfoIcon color='text' strokeWidth={1.5} size={24} />
        <span className={styles.label}>{t('Wallet.Info')}</span>
      </li>
    </ul>
  )
}
