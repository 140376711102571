import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { RadioButtonOffIcon } from '../../icons/RadioButtonOffIcon'
import { RadioButtonOnIcon } from '../../icons/RadioButtonOnIcon'
import { TextSmall } from '../../ui/Typography/Typography'

import styles from './RadioButton.module.scss'

export interface RadioBoxProps {
  name?: string
  label?: string | React.ReactNode
  value?: string | number | boolean
  checked?: boolean
  disabled?: boolean
  required?: boolean
  hint?: string
  noContentWrapper?: boolean
  onChange?(value: React.ChangeEvent<HTMLInputElement>): void
  onClick?(value: React.MouseEvent<HTMLInputElement>): void
  className?: string
}

export const RadioButton: React.FunctionComponent<PropsWithChildren<RadioBoxProps>> = (props) => {
  const {
    name,
    label,
    value = undefined,
    checked = undefined,
    disabled = false,
    required = false,
    noContentWrapper,
    hint,
    onChange = () => {},
    onClick,
    children,
    className,
  } = props

  const hasTitle = label || hint || children

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event)
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    event.stopPropagation()
    onClick?.(event)
  }

  return (
    <React.Fragment>
      <div className={classNames(styles.wrapper, 'is-flex is-align-items-center', className)}>
        <span className={styles.radioButton}>
          <input
            name={name}
            type='radio'
            value={getValue(value)}
            checked={checked}
            disabled={disabled}
            required={required}
            onChange={handleChange}
            onClick={handleClick}
            className={classNames(styles.input, {
              [styles.disabled]: disabled,
            })}
          />
          <span
            className={classNames(styles.icon, {
              [styles.active]: !!value,
              [styles.disabled]: disabled,
            })}
          >
            <span className={styles.radioOn}>
              <RadioButtonOnIcon />
            </span>
            <span className={styles.radioOff}>
              <RadioButtonOffIcon />
            </span>
          </span>
        </span>

        {hasTitle && (
          <span
            className={classNames({
              [styles.disabled]: disabled,
              [styles.contentWrapper]: !noContentWrapper,
            })}
          >
            {label && <TextSmall className={styles.label}>{label}</TextSmall>}
            {hint && <TextSmall className={styles.hint}>{hint}</TextSmall>}
            {children && <TextSmall className={styles.label}>{children}</TextSmall>}
          </span>
        )}
      </div>
    </React.Fragment>
  )
}

const getValue = (value: string | number | boolean | undefined) => {
  if (value === undefined || value === null) {
    return undefined
  }

  return value?.toString() || undefined
}
