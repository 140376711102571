export enum TestQuestionWidget {
  SELECT = 'select',
  SHORT_TEXT = 'text',
  TEXT = 'short_text',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export const isTestQuestionWidgetSelect = (value: string | undefined | null) => {
  if (!value) {
    return false
  }
  return TestQuestionWidget.SELECT === value
}

export const isTestQuestionWidgetText = (value: string | undefined | null) => {
  if (!value) {
    return false
  }
  return TestQuestionWidget.TEXT === value || TestQuestionWidget.SHORT_TEXT === value
}

export const isTestQuestionWidgetRadio = (value: string | undefined | null) => {
  if (!value) {
    return false
  }
  return TestQuestionWidget.RADIO === value
}

export const isTestQuestionWidgetCheckbox = (value: string | undefined | null) => {
  if (!value) {
    return false
  }
  return TestQuestionWidget.CHECKBOX === value
}
