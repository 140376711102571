import { RefObject, useEffect, useRef, useState } from 'react'

interface FlexWrapped {
  flexParentRef: RefObject<HTMLDivElement>
  isWrapped: boolean
}

export const useFlexWrapped = (): FlexWrapped => {
  const flexParentRef = useRef<HTMLDivElement>(null)
  const [isWrapped, setIsWrapped] = useState(false)

  const checkFlexWrap = () => {
    const flexParent = flexParentRef.current
    if (!flexParent) {
      return setIsWrapped(false)
    }

    const firstChild = flexParent.children[0] as HTMLElement

    for (let i = 1; i < flexParent.children.length; i++) {
      const child = flexParent.children[i] as HTMLElement
      if (child.offsetTop > firstChild.offsetTop) {
        return setIsWrapped(true)
      }
    }

    setIsWrapped(false)
  }

  useEffect(() => {
    window.addEventListener('resize', checkFlexWrap)
    checkFlexWrap() // Initial check on load

    return () => {
      window.removeEventListener('resize', checkFlexWrap)
    }
  }, [])

  return { flexParentRef, isWrapped }
}
