import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { UAParser } from 'ua-parser-js'

import { AppStoreIcon } from '../../icons/AppStoreIcon'
import { GooglePlayIcon } from '../../icons/GooglePlayIcon'
import { PlatformInfoDto } from '../../model/PlatformInfoDto'
import {
  OperatingSystemEnum,
  OperatingSystemNameEnum,
  PlatformTypeEnum,
} from '../../model/PlatformTypeEnum'
import { Grid } from '../../ui/Layouts/Grid/Grid'
import { Text, TextH2, TextLarge, TextSmall, TextStrong } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { AndroidLogo } from '../images/TickmillTrader/Icons/AndroidLogo'
import { DownloadAPKIcon } from '../images/TickmillTrader/Icons/DownloadAPKIcon'
import { IOSLogo } from '../images/TickmillTrader/Icons/IOSLogo'
import tmtApp from '../images/TickmillTrader/Images/tmtApp.webp'
import tmtQRCode from '../images/TickmillTrader/Images/tmtQRCode.webp'
import { PlatformDownloadButton } from './PlatformIcon'
import { TickmillTraderVideoData, tickmillTraderVideosData } from './tickmillTraderData'

import styles from './TickmillTrader.module.scss'

type LinkType = 'androidLink' | 'iOSLink'

const getDownloadLink = (
  platformInfo: PlatformInfoDto[],
  osId: OperatingSystemEnum
): string | undefined => {
  return platformInfo
    .find((p) => p.platfromType.id === PlatformTypeEnum.TickmillTrader)
    ?.platformLinks.find((link) => link.operatingSystem.id === osId)?.productLink
}

interface Props {
  platformInfo: PlatformInfoDto[]
}

export const TickmillTraderPage: React.FC<Props> = ({ platformInfo }) => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()

  const parser = new UAParser()
  const { device, os } = parser.getResult()

  const googlePlayLink = getDownloadLink(platformInfo, OperatingSystemEnum.ANDROID)
  const appStoreLink = getDownloadLink(platformInfo, OperatingSystemEnum.IOS)
  const apkLink = getDownloadLink(platformInfo, OperatingSystemEnum.ANDROID_APK)

  const renderImages = (data: TickmillTraderVideoData[], linkType: LinkType) => {
    return (
      <Grid className={styles.imageContainer}>
        {data.map((item, index) => (
          <div key={index} className={styles.imageCard}>
            <PlatformDownloadButton to={item[linkType]}>
              <img src={isMobile ? item.imgMobile : item.imgDesktop} alt={item.title} />
            </PlatformDownloadButton>
            <div className={styles.imageText}>
              <TextStrong isParagraph>{t(item.title)}</TextStrong>
              <TextSmall isParagraph>{t(item.text)}</TextSmall>
            </div>
          </div>
        ))}
      </Grid>
    )
  }

  if (isMobile) {
    return (
      <>
        <img src={tmtApp} className={styles.hasMarginBottom} />
        <TextH2 isParagraph className={styles.hasMarginBottom}>
          {t('ToolsPlatforms.Download Your Tickmill Trader')}
        </TextH2>
        <Text className={styles.textSecondary} isParagraph>
          {t('ToolsPlatforms.One of the most powerful trading platforms for mobile devices')}
        </Text>
        <Text isParagraph className={classNames(styles.textSecondary, styles.hasMarginTop)}>
          {t('ToolsPlatforms.Trade on the go with Tickmill Trader (for iOS and Android)')}
        </Text>
        <div className={styles.linksSection}>
          {(os.name === OperatingSystemNameEnum.Android || !device.type) && (
            <>
              {googlePlayLink && (
                <PlatformDownloadButton className={styles.hasMarginRight} to={googlePlayLink}>
                  <GooglePlayIcon width={135} height={40} />
                </PlatformDownloadButton>
              )}
              {apkLink && (
                <PlatformDownloadButton className={styles.hasMarginRight} to={apkLink}>
                  <DownloadAPKIcon />
                </PlatformDownloadButton>
              )}
            </>
          )}
          {(os.name === OperatingSystemNameEnum.iOS || !device.type) && (
            <>
              {appStoreLink && (
                <PlatformDownloadButton className={styles.hasMarginRight} to={appStoreLink}>
                  <AppStoreIcon width={119} height={40} />
                </PlatformDownloadButton>
              )}
            </>
          )}
        </div>
        <div className={styles.aboutSection}>
          <TextH2 isParagraph className={styles.hasMarginBottom}>
            {t('ToolsPlatforms.Learn more about Tickmill Trader')}
          </TextH2>
          <Text isParagraph className={styles.textSecondary}>
            {t(
              'ToolsPlatforms.Discover the features and functionalities of the Tickmill Trader app'
            )}
          </Text>
        </div>
        <div className={styles.videosSection}>
          {(os.name === OperatingSystemNameEnum.Android || !device.type) &&
            renderImages(tickmillTraderVideosData, 'androidLink')}
          {(os.name === OperatingSystemNameEnum.iOS || !device.type) &&
            renderImages(tickmillTraderVideosData, 'iOSLink')}
        </div>
      </>
    )
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.downloadSection}>
          <div className={styles.textSection}>
            <TextH2 isParagraph>{t('ToolsPlatforms.Download Your Tickmill Trader')}</TextH2>
            <Text className={styles.textSecondary} isParagraph>
              {t('ToolsPlatforms.One of the most powerful trading platforms for mobile devices')}
            </Text>
            <Text className={styles.textSecondary} isParagraph>
              {t('ToolsPlatforms.Trade on the go with Tickmill Trader (for iOS and Android)')}
            </Text>
            <div className={styles.linksSection}>
              <div className={styles.hasMarginRight}>
                <img src={tmtQRCode} className={styles.qrCode} />
              </div>
              {appStoreLink && (
                <PlatformDownloadButton className={styles.hasMarginRight} to={appStoreLink}>
                  <AppStoreIcon width={119} height={40} />
                </PlatformDownloadButton>
              )}
              {googlePlayLink && (
                <PlatformDownloadButton className={styles.hasMarginRight} to={googlePlayLink}>
                  <GooglePlayIcon width={135} height={40} />
                </PlatformDownloadButton>
              )}
              {apkLink && (
                <PlatformDownloadButton className={styles.hasMarginRight} to={apkLink}>
                  <DownloadAPKIcon />
                </PlatformDownloadButton>
              )}
            </div>
          </div>
          <div className={styles.tmtImageContainer}>
            <img src={tmtApp} />
          </div>
        </div>
      </div>
      <div className={styles.aboutSection}>
        <TextH2 isParagraph>{t('ToolsPlatforms.Learn more about Tickmill Trader')}</TextH2>
        <Text isParagraph className={styles.textSecondary}>
          {t('ToolsPlatforms.Discover the features and functionalities of the Tickmill Trader app')}
        </Text>
      </div>
      <div className={styles.videosSection}>
        <div>
          <AndroidLogo />
          <TextLarge className={styles.highlight}>{t('Android')}</TextLarge>
        </div>
        <div>{renderImages(tickmillTraderVideosData, 'androidLink')}</div>
      </div>
      <div className={styles.divider} />
      <div className={styles.videosSection}>
        <div>
          <IOSLogo />
          <TextLarge className={styles.highlight}>{t('iOS')}</TextLarge>
        </div>
        <div>{renderImages(tickmillTraderVideosData, 'iOSLink')}</div>
      </div>
    </div>
  )
}
