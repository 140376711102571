import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { AccountGroupType } from '../../model/AccountGroupType'
import { PlatformTypeEnum } from '../../model/PlatformTypeEnum'
import {
  PendingUnSubscriptionsRequestType,
  SubscriptionCartItem,
} from '../../model/SubscriptionCart'
import { SubscriptionRequestState } from '../../model/SubscriptionRequestState'
import { isSubscriptionPending } from '../../model/SubscriptionTypes'
import {
  SubscriptionHistoryItem,
  SubscriptionStatus,
  SubscriptionType,
} from '../../model/SubscriptionsDto'
import { SubscriptionRequestModificationType } from '../../model/UpdateSubscriptionRequest'
import { Operator, PageQuery, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'
import { scrollToTop } from '../../utils/useScrollToTop'
import { useLocallyPersistedState } from '../../utils/useStorage'

const DEFAULT_PAGE_SIZE = 50
export const CartPersistedKey = 'hasCartItems'
export const CartLinkPersistedKey = 'cartLink'

interface useCartItemsDataProps {
  isExpanded: boolean
  setIsExpanded: Dispatch<SetStateAction<boolean>>
  scrollToCart: () => void
  forceRefreshCartItems: () => void
  cartLink: string
  noneActiveSubscriptions: SubscriptionCartItem[]
  activeSubscriptions: SubscriptionHistoryItem[]
  pendingUnsubscriptions: {
    items: SubscriptionHistoryItem[]
    itemsCount: number
  }
  pendingUnsubscriptionsData: SubscriptionHistoryItem[]
  isPendingUnsubscriptionsLoading: boolean
  isActiveSubscriptionsLoading: boolean
  isNoneActiveSubscriptionsLoading: boolean
  isTradingAccountsLoading: boolean
  availableToWithdraw?: number
  hasSubscriptions: boolean
  upgradeOrDowngradeSubscriptionId: any
}

const useCartItemsData = (): useCartItemsDataProps => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [, setHasCartItems] = useLocallyPersistedState<boolean>(CartPersistedKey, false)
  const [, setCartLink] = useLocallyPersistedState<string>(CartLinkPersistedKey, '')
  const clientApiClient = useApiClient(ClientApiClient)

  const tradingAccountsCallback = useCallback(async () => {
    const [tradingAccount] = (
      await clientApiClient.getTradingAccounts({
        search: {
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: PlatformTypeEnum.CQGCAST,
            operator: Operator.EQUAL,
          },
          Status: {
            value: 1,
            operator: Operator.EQUAL,
          },
        },
      })
    ).items
    return tradingAccount?.platformOverview
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: tradingAccount, isLoading: isTradingAccountsLoading } =
    useFetchOne(tradingAccountsCallback)

  const availableToWithdraw = useMemo(
    () => tradingAccount?.netLiquidationValueUSD,
    [tradingAccount]
  )

  const {
    callback: noneActiveSubscriptionsCallback,
    forceRefresh: noneActiveSubscriptionsRefresh,
  } = useCallbackWithForceRefresh(() => {
    return clientApiClient.getSubscriptionCartItems()
  }, [])

  const { data: noneActiveSubscriptions = [], isLoading: isNoneActiveSubscriptionsLoading } =
    useFetchOne(noneActiveSubscriptionsCallback)

  const { callback: activeSubscriptionsCallback, forceRefresh: activeSubscriptionsRefresh } =
    useCallbackWithForceRefresh(async (query?: PageQuery) => {
      return await clientApiClient.getActiveSubscriptions({
        ...query,
        pageSize: DEFAULT_PAGE_SIZE,
        search: `(StateId == ${SubscriptionStatus.PENDING}) OR (StateId == ${SubscriptionStatus.DEDUCTING_FUNDS}) OR (StateId == ${SubscriptionStatus.IN_PROCESS}) OR (StateId == ${SubscriptionStatus.ACTIVE})`,
      })
    }, [])

  const { data: getActiveSubscriptions = { items: [] }, isLoading: isActiveSubscriptionsLoading } =
    useFetchOne(activeSubscriptionsCallback)

  const activeSubscriptions = useMemo(() => {
    const uniqueActiveSubscriptionId = new Set<string>()
    return getActiveSubscriptions?.items.filter((item) => {
      if (uniqueActiveSubscriptionId.has(item.subscriptionDetailId)) {
        return false
      }
      uniqueActiveSubscriptionId.add(item.subscriptionDetailId)
      return true
    })
  }, [getActiveSubscriptions])

  const getActiveSubscriptionsDetailId = useMemo(
    () => activeSubscriptions.map((item) => item?.subscriptionDetailId),
    [activeSubscriptions]
  )

  const getNonActiveSubscriptionsDetailId = useMemo(
    () => noneActiveSubscriptions.map((item) => item?.subscriptionDetail?.id),
    [noneActiveSubscriptions]
  )

  const hasSubscriptions = useMemo(() => {
    const subscriptionNoneActiveIds = noneActiveSubscriptions.map(
      (x) => x?.subscription?.subscriptionGroupType?.id
    )

    const subscriptionActiveIds = activeSubscriptions.map((x) => x?.subscriptionGroupType?.id)

    const subscriptionIds = subscriptionNoneActiveIds.concat(subscriptionActiveIds)

    const hasSubscriptionPlatform = subscriptionIds?.some((subscriptionsId) => {
      return subscriptionsId === SubscriptionType.PLATFORM
    })

    const hasSubscriptionMarketData = subscriptionIds?.some((subscriptionsId) => {
      return subscriptionsId === SubscriptionType.MARKET_DATA
    })

    return hasSubscriptionPlatform && hasSubscriptionMarketData
  }, [noneActiveSubscriptions, activeSubscriptions])

  const getSubscriptionsDetailId = [
    ...getActiveSubscriptionsDetailId,
    ...getNonActiveSubscriptionsDetailId,
  ]

  const {
    callback: pendingUnsubscriptionsRequestCallback,
    forceRefresh: pendingUnsubscriptionsRefresh,
  } = useCallbackWithForceRefresh(async (query?: PageQuery) => {
    return await clientApiClient.getActiveSubscriptions({
      ...query,
      search: {
        StateId: PendingUnSubscriptionsRequestType.StateId,
        RequestTypeId: PendingUnSubscriptionsRequestType.RequestTypeId,
      },
      pageSize: DEFAULT_PAGE_SIZE,
      SubscriptionsDetailId: getSubscriptionsDetailId.join(','),
    })
  }, [])

  const {
    data: pendingUnsubscriptions = { items: [], itemsCount: 0 },
    isLoading: isPendingUnsubscriptionsLoading,
  } = useFetchOne(pendingUnsubscriptionsRequestCallback)

  const pendingUnsubscriptionsData = pendingUnsubscriptions?.items

  const scrollToCart = () => {
    scrollToTop()
    !isExpanded && setIsExpanded(true)
  }

  const forceRefreshCartItems = () => {
    pendingUnsubscriptionsRefresh()
    noneActiveSubscriptionsRefresh()
    activeSubscriptionsRefresh()
  }

  const cartLink = useMemo(() => {
    if (noneActiveSubscriptions?.length) {
      const hasMarketData = noneActiveSubscriptions.some(
        ({ subscription }) =>
          subscription.subscriptionGroupType?.id === SubscriptionType.MARKET_DATA
      )
      const hasTradingPlatform = noneActiveSubscriptions.some(
        ({ subscription }) => subscription.subscriptionGroupType?.id === SubscriptionType.PLATFORM
      )
      return hasTradingPlatform && !hasMarketData
        ? '/dashboard/subscriptions/trading-platforms'
        : '/dashboard/subscriptions/market-data'
    }

    if (activeSubscriptions?.length) {
      const hasMarketData = activeSubscriptions.some(
        (sub) => sub.subscriptionGroupType.id === SubscriptionType.MARKET_DATA
      )
      const hasTradingPlatform = activeSubscriptions.some(
        (sub) => sub.subscriptionGroupType.id === SubscriptionType.PLATFORM
      )
      return hasTradingPlatform && !hasMarketData
        ? '/dashboard/subscriptions/trading-platforms'
        : '/dashboard/subscriptions/market-data'
    }

    return '/dashboard/subscriptions/market-data'
  }, [activeSubscriptions, noneActiveSubscriptions])

  const isUpgradeOrDowngrade = (subscriptionHistoryItem: SubscriptionHistoryItem) =>
    subscriptionHistoryItem.state.name === SubscriptionRequestState.Pending &&
    (subscriptionHistoryItem.type.id === SubscriptionRequestModificationType.Upgrade ||
      subscriptionHistoryItem.type.id === SubscriptionRequestModificationType.Downgrade)

  const upgradeOrDowngradeSubscriptionId = useMemo(
    () =>
      activeSubscriptions
        .filter((activeSubscription) => isUpgradeOrDowngrade(activeSubscription))
        .map((activeSubscription) => activeSubscription.subscription.id),
    [activeSubscriptions]
  )

  useEffect(() => {
    setHasCartItems(
      activeSubscriptions.filter(isSubscriptionPending).length > 0 ||
        noneActiveSubscriptions.length > 0
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubscriptions, noneActiveSubscriptions])

  useEffect(() => setCartLink(cartLink), [cartLink, setCartLink])

  return {
    isExpanded,
    setIsExpanded,
    scrollToCart,
    forceRefreshCartItems,
    cartLink,
    noneActiveSubscriptions,
    activeSubscriptions,
    pendingUnsubscriptions,
    pendingUnsubscriptionsData,
    isPendingUnsubscriptionsLoading,
    isActiveSubscriptionsLoading,
    isNoneActiveSubscriptionsLoading,
    isTradingAccountsLoading,
    availableToWithdraw,
    hasSubscriptions,
    upgradeOrDowngradeSubscriptionId: new Set<string>(upgradeOrDowngradeSubscriptionId),
  }
}

export default useCartItemsData
