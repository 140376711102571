import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { Loading } from '../../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { useFormatNumber } from '../../../../hooks/useFormatNumber'
import {
  CreateWalletToPspWithdrawTransaction,
  denormalizeCreateWalletToPspWithdrawTransaction,
} from '../../../../model/CreateWalletToPspWithdrawTransaction'
import { DocumentCategoryType } from '../../../../model/DocumentCategories'
import { DocumentCategoryOptionType } from '../../../../model/DocumentCategoryOptionType'
import { MasterTransaction } from '../../../../model/MasterTransaction'
import { isPaymentProviderBankType } from '../../../../model/PaymentProviderType'
import { WalletDto, WalletTypeEnum } from '../../../../model/WalletDto'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { sleep } from '../../../../utils/transaction.utils'
import { useCallbackWithForceRefresh } from '../../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../../utils/useFetch'
import { useMutation } from '../../../../utils/useMutation'
import { useScrollToTop } from '../../../../utils/useScrollToTop'
import { WalletRestrictions, isRestricted } from '../../../../utils/wallet.utils'
import {
  WalletWithdrawForm,
  WalletWithdrawFormValues,
} from '../WalletWithdrawForm/WalletWithdrawForm'

interface WalletWithdrawFetchProps {
  onCancel(): void
}

interface UseWalletWithdrawFetch {
  data: WalletDto | undefined
  isLoading: boolean
}

export const useWalletWithdrawFetch = (props: WalletWithdrawFetchProps): UseWalletWithdrawFetch => {
  const { walletId } = useParams<{ walletId?: string }>()

  const apiClient = useApiClient(ClientApiClient)

  const { callback } = useCallbackWithForceRefresh(async () => {
    if (walletId) {
      return apiClient.getWalletById(walletId)
    }
  }, [walletId])

  const { data, isLoading } = useFetchOne(callback)

  useEffect(() => {
    if (isRestricted(WalletRestrictions.WITHDRAW, data?.restrictions)) {
      props.onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.restrictions])

  return { data, isLoading }
}

interface WalletWithdrawMutateProps {
  onSubmit(data: MasterTransaction, values: WalletWithdrawFormValues): void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useWalletWithdrawMutate = (props: WalletWithdrawMutateProps) => {
  const { onSubmit } = props
  const locale = useSessionLanguage()

  const apiClient = useApiClient(ClientApiClient)
  const documentMutation = useDocumentUploadMutate()

  const handleSuccess = async (data: MasterTransaction, values: WalletWithdrawFormValues) => {
    onSubmit(data, values)
  }

  const handleError = (error: AxiosError<{ message: string }>) => {
    // TODO: add error notification
    // we have global error handler, check if this works
    // alert(error?.response?.data?.message)
  }

  return useMutation(
    async (values: WalletWithdrawFormValues) => {
      const isBankTransfer = isPaymentProviderBankType(values.paymentProvider.providerCategory.id)
      const [document] =
        values.isBankAccountsList || !isBankTransfer ? [] : await documentMutation.mutate(values)

      await sleep(1000)

      const result = await apiClient.transactionWithdraw(
        denormalizeCreateWalletToPspWithdrawTransaction(
          createPspToWalletWithdrawTransaction({
            ...values,
            fields: {
              ...values.fields,
              documentId: document?.id,
            },
          }),
          locale
        )
      )

      return result
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  )
}

const useDocumentUploadMutate = () => {
  const apiClient = useApiClient(ClientApiClient)

  return useMutation(async (values: WalletWithdrawFormValues) => {
    const documents = [
      {
        categoryId: DocumentCategoryType.Payment,
        typeId: DocumentCategoryOptionType.BankStatement,
        file: values.bankDepositProof.base64Content,
        filename: values.bankDepositProof.fileName,
      },
    ]

    return apiClient.uploadDocuments({ documents })
  })
}

interface WalletWithdrawPageProps {
  wallet?: WalletDto
  walletType: WalletTypeEnum

  onSubmit(data: MasterTransaction, values: WalletWithdrawFormValues): void

  onCancel(): void
  title?: string
}

export const WalletWithdrawPage: React.FC<WalletWithdrawPageProps> = (props) => {
  const { wallet, walletType, onSubmit, onCancel, title } = props

  useScrollToTop()

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const mutation = useWalletWithdrawMutate({ onSubmit })

  const handleSubmit = async (values: WalletWithdrawFormValues) => {
    await mutation.mutate(values)
  }

  const handlePageExitConfirmation = () => {
    onCancel()
  }

  return (
    <React.Fragment>
      {!mutation.isLoading && (
        <WalletWithdrawForm
          formatMoney={formatMoney}
          wallet={wallet}
          walletType={walletType}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
          title={title}
        />
      )}
      {mutation.isLoading && (
        <Loading isLoading={mutation.isLoading} showLoadingIcon text={t('Withdrawing')} />
      )}
    </React.Fragment>
  )
}

const createPspToWalletWithdrawTransaction = (
  values: WalletWithdrawFormValues
): CreateWalletToPspWithdrawTransaction => {
  return {
    fromWallet: {
      id: values.wallet.id,
      currency: {
        id: values.wallet.currency?.id,
      },
    },
    toPaymentProvider: {
      id: values.paymentProvider.id,
      currency: {
        id: values.paymentProvider.currency.id,
      },
    },
    amount: values.amount,
    comment: values.comment,
    terms: values.terms.paymentOperations,
    form: values.paymentProvider.parameters.form,
    fields: values.fields,
  }
}
