import {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

import { PageData, PageMetaData, PageQuery } from './ApiClient'
import { useHasChanged } from './usePrevious'

interface ResolvedAction<T> {
  type: 'resolved'
  data: T
  meta?: PageMetaData
}

interface FailedAction {
  type: 'failed'
}

interface ResetAction {
  type: 'reset'
}

export interface IFetchResult<T> {
  isLoading: boolean
  data: T
  meta?: PageMetaData
  pageQuery?: PageQuery
  skipCount?: boolean
  setPageQuery?: (pageQuery?: PageQuery) => void
}

export interface IAppendableFetchResult<T> extends IFetchResult<T> {
  resetState: () => void
  hasInitialResults: boolean
}

type FetchAction<T> = ResolvedAction<T> | FailedAction | ResetAction
type FetchReducer<T> = Reducer<IFetchResult<T>, FetchAction<T>>

function createFetchOneReducer<T>(resetOnFailure: boolean): FetchReducer<T | undefined> {
  return function fetchReducer<T>(
    state: IFetchResult<T | undefined>,
    action: FetchAction<T | undefined>
  ) {
    switch (action.type) {
      case 'resolved':
        return {
          isLoading: false,
          data: action.data,
          meta: action.meta,
        }

      case 'failed':
        return {
          ...(resetOnFailure ? { data: undefined } : state),
          isLoading: false,
        }

      case 'reset':
        return {
          isLoading: false,
          data: undefined,
        }
    }
  }
}

function createFetchPageReducer<T>(append: boolean, resetOnFailure: boolean): FetchReducer<T[]> {
  return function fetchReducer<T>(state: IFetchResult<T[]>, action: FetchAction<T[]>) {
    switch (action.type) {
      case 'reset':
        return {
          isLoading: true,
          data: [],
        }

      case 'resolved':
        return {
          isLoading: false,
          data: append ? [...state.data, ...action.data] : action.data,
          meta: action.meta,
        }

      case 'failed':
        return {
          isLoading: false,
          data: !resetOnFailure ? state.data : [],
        }
    }
  }
}

function useFetchReducer<T>(
  fetch: () => Promise<{ data: T; meta?: PageMetaData }>,
  [state, dispatch]: [ReducerState<FetchReducer<T>>, Dispatch<ReducerAction<FetchReducer<T>>>],
  initialState: ReducerState<FetchReducer<T>>,
  skipLoadingFlag?: boolean
): IFetchResult<T> {
  useEffect(() => {
    let isCancelled = false

    async function fetchData() {
      try {
        const { data, meta } = await fetch()
        if (!isCancelled) {
          dispatch({
            type: 'resolved',
            data,
            meta,
          })
        }
      } catch (err: unknown) {
        if (!isCancelled) {
          dispatch({
            type: 'failed',
          })
        }
      }
    }

    fetchData()

    return () => {
      isCancelled = true
    }
  }, [fetch, dispatch, skipLoadingFlag])

  // synchronously force reset state to avoid initial rerender with stale state
  if (useHasChanged(fetch) && !skipLoadingFlag) {
    return initialState
  }

  return state
}

export function useFetchAppendablePage<T>(
  fetch: (pageData?: PageQuery) => Promise<PageData<T>>
): IAppendableFetchResult<T[]> {
  const initialState = {
    isLoading: true,
    data: [],
  }

  const reducer = useReducer(createFetchPageReducer<T>(false, false), initialState)
  const [hasInitialResults, setHasInitialResults] = useState(false)

  const [pageQuery, setPageQuery] = useState<PageQuery>()
  const fetchCallback = useCallback(() => {
    return fetch(pageQuery).then(
      ({ items, pageIndex, pageSize, totalPages, totalResults, itemsCount }) => {
        if (!pageQuery?.pageIndex) {
          if (items.length > 0) {
            setHasInitialResults(true)
          }
        }
        return {
          data: items,
          meta: {
            pageIndex,
            pageSize,
            totalPages,
            totalResults,
            itemsCount,
          },
        }
      }
    )
  }, [fetch, pageQuery])

  const state = useFetchReducer(fetchCallback, reducer, initialState, false)

  return {
    ...state,
    pageQuery,
    setPageQuery,
    hasInitialResults,
    resetState: () => reducer[1]({ type: 'reset' }),
  }
}
const DefaultFetchOptions = {
  skipLoadingFlag: false,
  resetOnFailure: true,
}
type FetchOptions = Partial<typeof DefaultFetchOptions>

export function useFetchOne<T>(
  fetch: () => Promise<T | undefined>,
  options?: FetchOptions
): IFetchResult<T | undefined> {
  const resolvedOptions = useMemo(() => ({ ...DefaultFetchOptions, ...options }), [options])

  const initialState = {
    isLoading: true,
    data: undefined,
  }

  const reducer = useReducer(
    createFetchOneReducer<T | undefined>(resolvedOptions.resetOnFailure),
    initialState
  )
  const fetchCallback = useCallback(
    () => fetch().then((data) => ({ data, meta: undefined })),
    [fetch]
  )
  return useFetchReducer(fetchCallback, reducer, initialState, resolvedOptions.skipLoadingFlag)
}
