import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CheckCircledIcon } from '../../icons/CheckCircledIcon'
import { LandingPageDto } from '../../model/LandingPageDto'
import { TextStrong } from '../../ui/Typography/Typography'

import styles from './LandingPageCards.module.scss'

interface LandingPageCardsProps {
  landingPages: LandingPageDto[]
  selectedPage?: LandingPageDto
  opPageSelect: (page: LandingPageDto) => void
}

export const LandingPageCards: React.FC<LandingPageCardsProps> = (props) => {
  const { landingPages, selectedPage, opPageSelect } = props

  const { t } = useTranslation()

  return (
    <>
      <TextStrong className={styles.title}>
        {t('Referral Materials.Select Landing Page:')}
      </TextStrong>

      <div className={styles.landingPageCards}>
        {landingPages.map((page) => {
          const isSelected = selectedPage === page

          return (
            <LandingPageCard
              key={page.id}
              page={page}
              isSelected={isSelected}
              onClick={opPageSelect}
            />
          )
        })}
      </div>
    </>
  )
}

interface LandingPageCardProps {
  page: LandingPageDto
  isSelected: boolean
  onClick: (page: LandingPageDto) => void
}

export const LandingPageCard: React.FC<LandingPageCardProps> = (props) => {
  const { page, isSelected, onClick } = props

  return (
    <div className={styles.landingPageCardWrapper} onClick={() => onClick(page)}>
      <div
        className={classNames(styles.landingPageCard, {
          [styles.selected]: isSelected,
        })}
      >
        <img
          src={page.imagePath}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.remove()
          }}
          alt={'img'}
        />

        <TextStrong isParagraph>{page.name}</TextStrong>

        {isSelected && (
          <span className={styles.checkIconWrapper}>
            <CheckCircledIcon
              className={styles.checkIcon}
              circleColor='success'
              checkColor='white'
            />
          </span>
        )}
      </div>
    </div>
  )
}
