import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { Modal } from '../../global/modal/Modal'
import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { SubscriptionRequestTypeEnum } from '../../model/SubscriptionRequestTypeEnum'
import {
  getPendingAndProcessingSubscription,
  isSubscriptionItemActive,
  isSubscriptionItemCompleted,
} from '../../model/SubscriptionTypes'
import { SubscriptionHistoryItem, SubscriptionStatus } from '../../model/SubscriptionsDto'
import {
  TradingPlaformsDataProps,
  TradingPlatformsItemProps,
  TradingPlatformsPackagesProps,
} from '../../model/TradingPlatformsDto'
import {
  SubscriptionRequestType,
  SubscriptionTimeInForce,
  UpdateSubscriptionRequest,
} from '../../model/UpdateSubscriptionRequest'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextH3 } from '../../ui/Typography/Typography'
import { ResponseError, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { isLastBusinessDay } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { isZero } from '../../utils/validations'
import { Cart } from '../Cart'
import {
  UnsubscribeConfirmationModal,
  UnsubscribeOpenPositionModal,
} from '../MarketData/UnsubscribeConfirmationModal'
import { PaymentDoneModal } from '../PaymentDoneModal'
import { SubscriptionCQGInfoModal } from '../SubscriptionCQGInfoModal'
import { SubscriptionsETDTemplate } from '../SubscriptionsETDTemplate'
import { UnsubscribeAttentionConfirmationModal } from '../UnsubscribeAttentionModal'
import useCartItemsData from '../hooks/useCartItemsData'
import { SubscriptionsTradingPlatformsTable } from './SubscriptionsTradingPlatformsTable'

import subscriptionsStyles from '../SubscriptionsETD.module.scss'

export const PLATFORM_TYPE_CQG = 'cqg'
export const PLATFORM_TYPE_THIRD_PARTY = 'third-party'

type SubscriptionDetailWithDueDate = {
  subscriptionDetailId: string
  dueDate: string
}

export const SubscriptionsTradingPlatformsETDPage: React.FC = () => {
  useScrollToTop()
  const clientApiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [unsubscribeAttentionConfirmation, setUnsubscribeAttentionConfirmation] =
    useState<SubscriptionDetailWithDueDate>()
  const [unsubscribeConfirmation, setUnsubscribeConfirmation] =
    useState<SubscriptionDetailWithDueDate>()

  const [subscriptionWithOpenPosition, setSubscriptionWithOpenPosition] = useState<
    SubscriptionDetailWithDueDate | undefined
  >()
  const [subscriptionInfoModalOpen, setSubscriptionInfoModalOpen] = useState(false)

  const isMobile = useWindowResize()
  const [paymentDoneModalVisible, setPaymentDoneModalVisible] = useState(false)

  const [cQGProductsData, setCQGProductsData] = useState<TradingPlatformsItemProps[] | []>([])
  const [thirdPartyProductsData, setThirdPartyProductsData] = useState<
    TradingPlatformsItemProps[] | []
  >([])

  const [subscriptionsCQG, setCQGSubscriptions] = useState<TradingPlatformsPackagesProps[] | []>([])

  const [subscriptionsThirdParty, setThirdPartySubscriptions] = useState<
    TradingPlatformsPackagesProps[] | []
  >([])

  const {
    isExpanded,
    setIsExpanded,
    scrollToCart,
    pendingUnsubscriptions,
    noneActiveSubscriptions,
    activeSubscriptions,
    forceRefreshCartItems,
    pendingUnsubscriptionsData,
    isPendingUnsubscriptionsLoading,
    isActiveSubscriptionsLoading,
    isNoneActiveSubscriptionsLoading,
    isTradingAccountsLoading,
    availableToWithdraw,
    hasSubscriptions,
    upgradeOrDowngradeSubscriptionId,
  } = useCartItemsData()
  const { scrollIntoView } = useScrollToElementIds()

  const getActiveSubscriptionRequest = (subscriptions: TradingPlatformsPackagesProps[]) =>
    subscriptions?.find((subscription) => subscription?.activeSubscriptionRequest)

  const isActiveAndHasActiveSubscriptionRequest = (
    subscriptions: TradingPlatformsPackagesProps[]
  ) =>
    subscriptions?.find(
      (subscription) =>
        subscription?.activeSubscriptionRequest &&
        subscription.subscriptionRequest?.state?.id === SubscriptionStatus.ACTIVE
    )

  const isCancellationRequested = (subscriptions: TradingPlatformsPackagesProps[]) =>
    subscriptions?.find(
      (subscription) =>
        (subscription.subscriptionRequest?.state?.id === SubscriptionStatus.PENDING &&
          subscription.subscriptionRequest?.type.name ===
            SubscriptionRequestTypeEnum.CancelExistingSubscription) ||
        subscription.subscriptionRequest?.state?.id === SubscriptionStatus.IN_PROCESS_CANCEL
    )

  const getPendingSubscriptionRequest = (subscriptions: TradingPlatformsPackagesProps[]) =>
    subscriptions?.find(
      (subscription) =>
        subscription.subscriptionRequest?.state?.id === SubscriptionStatus.PENDING &&
        subscription.subscriptionRequest?.type?.name ===
          SubscriptionRequestTypeEnum.AddNewSubscription &&
        subscription.subscriptionRequest?.subscriptionTimeInForce?.id !==
          SubscriptionTimeInForce.Immediate
    )

  const getPendingCancelSubscriptionRequest = (subscriptions: TradingPlatformsPackagesProps[]) =>
    subscriptions?.find(
      (subscription) =>
        subscription.subscriptionRequest?.state?.id === SubscriptionStatus.PENDING &&
        subscription.subscriptionRequest?.type?.name ===
          SubscriptionRequestTypeEnum.CancelExistingSubscription
    )

  const getDeductingFundOrInProcessSubscriptionRequest = (
    subscriptions: TradingPlatformsPackagesProps[]
  ) =>
    subscriptions?.find(
      (subscription) =>
        subscription.subscriptionRequest?.state?.id === SubscriptionStatus.DEDUCTING_FUNDS ||
        subscription.subscriptionRequest?.state?.id === SubscriptionStatus.IN_PROCESS
    )

  const mappedProductsData = (
    item: TradingPlatformsItemProps,
    productsData: TradingPlatformsItemProps[]
  ) => {
    return productsData?.map((product) => {
      if (product.id === item.id) {
        return {
          ...item,
          isOpen: !product?.isOpen,
        }
      }
      return product
    })
  }
  const handleExpandItem = (item: TradingPlatformsItemProps, type: string) => {
    if (type === PLATFORM_TYPE_CQG) {
      const mappedCQGProductsData = mappedProductsData(item, cQGProductsData)
      setCQGProductsData(mappedCQGProductsData)
    }

    if (type === PLATFORM_TYPE_THIRD_PARTY) {
      const mappedThirdPartyProductsData = mappedProductsData(item, thirdPartyProductsData)
      setThirdPartyProductsData(mappedThirdPartyProductsData)
    }

    scrollIntoView([`subscription-item-${item.id}`])
  }

  const refreshSubscriptionsTableData = () => {
    cqgForceRefresh()
    thirdPartyRefresh()
  }

  const handleOnUpdateSubscription = async (
    data: UpdateSubscriptionRequest,
    selectedActiveSubscriptionCartItem?: SubscriptionHistoryItem | undefined
  ) => {
    onUpdateSubscription(data, selectedActiveSubscriptionCartItem)
  }

  const onUpdateSubscription = async (
    data: UpdateSubscriptionRequest,
    selectedActiveSubscriptionCartItem?: SubscriptionHistoryItem | undefined
  ) => {
    try {
      await clientApiClient.updateSubscriptionRequest(data)
      forceRefreshCartItems()
      refreshSubscriptionsTableData()
    } catch (error: unknown) {
      if (
        error instanceof ResponseError &&
        error.response.response?.data.code === 'trading_account_has_open_positions'
      ) {
        return setSubscriptionWithOpenPosition(
          unsubscribeConfirmation ||
            unsubscribeAttentionConfirmation ||
            selectedActiveSubscriptionCartItem
        )
      }
    } finally {
      setUnsubscribeConfirmation(undefined)
      setUnsubscribeAttentionConfirmation(undefined)
      setIsSubmitting(false)
    }
  }

  const handleOnUnsubscribeProduct = async () => {
    setIsSubmitting(true)

    const item = unsubscribeConfirmation || unsubscribeAttentionConfirmation

    onUpdateSubscription({
      subscriptions: [
        {
          subscriptionDetailId: item?.subscriptionDetailId,
          subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
        },
      ],

      subscriptionTimeInForceId: SubscriptionTimeInForce.BeginningOfNextMonth,
    })

    setSubscriptionWithOpenPosition(undefined)
  }

  const handleOnClickUnsubscribeWithOpenPosition = (): void => {
    setIsSubmitting(true)

    onUpdateSubscription({
      subscriptions: [
        {
          subscriptionDetailId: subscriptionWithOpenPosition?.subscriptionDetailId,
          subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
        },
      ],
      overrideOpenPositionsForCancel: true,
      subscriptionTimeInForceId: SubscriptionTimeInForce.BeginningOfNextMonth,
    })

    setSubscriptionWithOpenPosition(undefined)
  }

  const handleOnClickPay = async () => {
    const noneActiveSubscriptionsMapped = noneActiveSubscriptions?.map((item) => ({
      subscriptionDetailId: item.subscriptionDetail?.id || '',
      subscriptionRequestTypeId: SubscriptionRequestType.Add,
    }))

    await clientApiClient.updateSubscriptionRequest({
      subscriptions: noneActiveSubscriptionsMapped,
      subscriptionTimeInForceId: SubscriptionTimeInForce.Immediate,
    })

    refreshSubscriptionsTableData()
    forceRefreshCartItems()
    setPaymentDoneModalVisible(true)
  }

  const handleOnRemoveCartItem = async (id: string) => {
    await clientApiClient.deleteSubscriptionCartItem(id)
    refreshSubscriptionsTableData()
    forceRefreshCartItems()
  }
  const handleUnsubscribeConfirmationModal = (
    item: TradingPlatformsItemProps | TradingPlatformsPackagesProps
  ) => {
    if (isLastBusinessDay()) {
      setUnsubscribeAttentionConfirmation(item?.subscriptionRequest)
    } else {
      setUnsubscribeConfirmation(item?.subscriptionRequest)
    }
  }

  const { callback: cqgSubscriptionsDataCallback, forceRefresh: cqgForceRefresh } =
    useCallbackWithForceRefresh(() => {
      return clientApiClient.getClientProductSubscriptions(PLATFORM_TYPE_CQG, locale)
    }, [locale])

  const { data: clientSubscriptions, isLoading: isLoadingCQGData } = useFetchOne(
    cqgSubscriptionsDataCallback
  )

  const { callback: thirdPartyPlatformCallback, forceRefresh: thirdPartyRefresh } =
    useCallbackWithForceRefresh(() => {
      return clientApiClient.getClientProductSubscriptions(PLATFORM_TYPE_THIRD_PARTY, locale)
    }, [locale])

  const { data: thirdPartyPlatform, isLoading: isLoadingThirdPartyPlatform } = useFetchOne(
    thirdPartyPlatformCallback
  )

  const getSubscriptionsData = (clientSubscriptions: TradingPlaformsDataProps[]) => {
    const tradingPlatformsData: TradingPlatformsItemProps[] = []
    let subscriptionsData: TradingPlatformsPackagesProps[] = []

    for (const clientSubscription of clientSubscriptions) {
      const { subscriptions, id, ...product } = clientSubscription

      const getSubscriptionStatus =
        isActiveAndHasActiveSubscriptionRequest(subscriptions) ||
        getActiveSubscriptionRequest(subscriptions) ||
        getPendingSubscriptionRequest(subscriptions) ||
        getPendingCancelSubscriptionRequest(subscriptions) ||
        isCancellationRequested(subscriptions) ||
        getDeductingFundOrInProcessSubscriptionRequest(subscriptions)

      const defaultSubscription = subscriptions?.find((subscription) => {
        const statusId = subscription?.subscriptionRequest?.state?.id

        return (
          isSubscriptionItemActive(subscription) ||
          statusId === SubscriptionStatus.PENDING ||
          statusId === SubscriptionStatus.DEDUCTING_FUNDS ||
          statusId === SubscriptionStatus.IN_PROCESS ||
          statusId === SubscriptionStatus.IN_PROCESS_CANCEL ||
          isSubscriptionItemCompleted(subscription)
        )
      })

      const subscription = getSubscriptionStatus || defaultSubscription

      tradingPlatformsData.push({
        id,
        subscriptions,
        ...product,
        type: id,
        isOpen: false,
        subscriptionRequest: getPendingAndProcessingSubscription(subscription),
      })

      const getMappedSubscriptionsData: TradingPlatformsPackagesProps[] = subscriptions?.map(
        (subscription) => {
          const detail = subscription?.details?.find(
            (detail) => detail?.amount || isZero(detail?.amount)
          )

          const bundle = subscription?.details?.find(
            ({ activeViaBundleSubscriptionRequest }) => activeViaBundleSubscriptionRequest
          )

          return {
            ...subscription,
            subscriptionRequest: getPendingAndProcessingSubscription(subscription),
            price: detail?.amount,
            productType: id,
            isActiveViaPackage: !!bundle?.activeViaBundleSubscriptionRequest,
          }
        }
      )

      subscriptionsData = subscriptionsData.concat(getMappedSubscriptionsData)
    }

    return {
      tradingPlatformsData,
      subscriptionsData,
    }
  }

  const getSubscriptionsCallback = useCallback(() => {
    const cqgSubscriptionsData = getSubscriptionsData(clientSubscriptions || [])

    setCQGSubscriptions(cqgSubscriptionsData?.subscriptionsData)
    setCQGProductsData(cqgSubscriptionsData?.tradingPlatformsData)

    const thirdPartySubscriptionsData = getSubscriptionsData(thirdPartyPlatform || [])

    setThirdPartySubscriptions(thirdPartySubscriptionsData.subscriptionsData)
    setThirdPartyProductsData(thirdPartySubscriptionsData.tradingPlatformsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, clientSubscriptions, thirdPartyPlatform])

  useEffect(() => {
    getSubscriptionsCallback()
  }, [locale, getSubscriptionsCallback])

  const isCartEmpty = isZero(noneActiveSubscriptions?.length) && isZero(activeSubscriptions?.length)

  const isCartItemsLoading =
    isTradingAccountsLoading ||
    isNoneActiveSubscriptionsLoading ||
    isActiveSubscriptionsLoading ||
    isPendingUnsubscriptionsLoading

  return (
    <>
      {paymentDoneModalVisible && (
        <Modal
          cardClassName={subscriptionsStyles.paymentModal}
          size='xsmall'
          closeModal={() => setPaymentDoneModalVisible(false)}
          render={() => <PaymentDoneModal onCancel={() => setPaymentDoneModalVisible(false)} />}
        />
      )}
      {unsubscribeConfirmation && (
        <UnsubscribeConfirmationModal
          dueDate={unsubscribeConfirmation?.dueDate}
          onClickUnsubscribe={handleOnUnsubscribeProduct}
          setUnsubscribeConfirmationModal={() => setUnsubscribeConfirmation(undefined)}
        />
      )}
      {unsubscribeAttentionConfirmation && (
        <UnsubscribeAttentionConfirmationModal
          onConfirm={handleOnUnsubscribeProduct}
          onCancel={() => setUnsubscribeAttentionConfirmation(undefined)}
        />
      )}
      {subscriptionWithOpenPosition && (
        <UnsubscribeOpenPositionModal
          onClickUnsubscribe={handleOnClickUnsubscribeWithOpenPosition}
          setUnsubscribeOpenPositionModal={() => setSubscriptionWithOpenPosition(undefined)}
        />
      )}
      {subscriptionInfoModalOpen && (
        <SubscriptionCQGInfoModal onCancel={() => setSubscriptionInfoModalOpen(false)} />
      )}

      <SubscriptionsETDTemplate
        subscriptionsHeader={<TradingPlatformsHeader />}
        subscriptionsTable={
          <SubscriptionsTradingPlatformsTable
            subscriptions={subscriptionsCQG}
            productsTradingPlatformsData={cQGProductsData}
            type={PLATFORM_TYPE_CQG}
            isLoading={isLoadingCQGData || isSubmitting}
            forceRefresh={cqgForceRefresh}
            scrollToCart={scrollToCart}
            expandItem={handleExpandItem}
            forceRefreshCartItems={forceRefreshCartItems}
            onUnsubscribeConfirmationModal={handleUnsubscribeConfirmationModal}
            onSubscriptionInfoModalOpen={() => setSubscriptionInfoModalOpen(true)}
            noneActiveSubscriptions={noneActiveSubscriptions}
          />
        }
        thirdPartySubscriptionsHeader={<ThirdPartyTradingPlatformsHeader />}
        thirdPartyTradingSubscriptionsTable={
          <SubscriptionsTradingPlatformsTable
            subscriptions={subscriptionsThirdParty}
            productsTradingPlatformsData={thirdPartyProductsData}
            type={PLATFORM_TYPE_THIRD_PARTY}
            isLoading={isLoadingThirdPartyPlatform || isSubmitting}
            forceRefresh={thirdPartyRefresh}
            expandItem={handleExpandItem}
            scrollToCart={scrollToCart}
            forceRefreshCartItems={forceRefreshCartItems}
            onUnsubscribeConfirmationModal={handleUnsubscribeConfirmationModal}
            onSubscriptionInfoModalOpen={() => setSubscriptionInfoModalOpen(true)}
            noneActiveSubscriptions={noneActiveSubscriptions}
          />
        }
        isCartEmpty={isMobile ? isCartEmpty : isCartEmpty && !isCartItemsLoading}
        hasSubscriptions={hasSubscriptions}
        isCartItemsLoading={isCartItemsLoading}
        sidebar={[
          <Cart
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            cartItemsIsLoading={isCartItemsLoading}
            handleOnUpdateSubscription={handleOnUpdateSubscription}
            handleOnClickPay={handleOnClickPay}
            onRemoveCartItem={handleOnRemoveCartItem}
            activeSubscriptions={activeSubscriptions}
            hasSubscriptions={hasSubscriptions}
            hasPendingUnsubscriptionRequest={pendingUnsubscriptions?.itemsCount > 0}
            pendingUnsubscriptionsData={pendingUnsubscriptionsData}
            noneActiveSubscriptions={noneActiveSubscriptions}
            availableToWithdraw={availableToWithdraw}
            upgradeOrDowngradeSubscriptionId={upgradeOrDowngradeSubscriptionId}
          />,
        ]}
      />
    </>
  )
}

const TradingPlatformsHeader = () => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const path = '/dashboard/subscriptions/trading-platforms/why-trading-platforms'
  const title = t('Subscriptions.Why and how to use a Trading Platform')

  return (
    <PageHeader
      className='is-flex is-flex-wrap-wrap'
      renderTitle={() =>
        isMobile ? (
          <Link to={path}>{title}</Link>
        ) : (
          <TextH3>{t('Subscriptions.Trading Platforms')}</TextH3>
        )
      }
      rightRender={!isMobile && <Link to={path}>{title}</Link>}
    />
  )
}

const ThirdPartyTradingPlatformsHeader = () => {
  const { t } = useTranslation()

  return (
    <PageHeader
      className='is-flex is-flex-wrap-wrap'
      title={t('Subscriptions.Third Party Trading Platforms')}
      rightRender={
        <Link
          to='/dashboard/subscriptions/trading-platforms/why-third-party-trading-platforms'
          className='is-link'
        >
          {t('Subscriptions.Why and how to use API Connections')}
        </Link>
      }
    />
  )
}
