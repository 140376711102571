import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react'
import { NavigateOptions, useNavigate } from 'react-router-dom'

type PathHistoryContextType = {
  pathHistory: string[]
  setPathHistory: (historyPath: string[]) => void
  navigateToPreviousPath: (navigateOptions?: NavigateOptions) => void
}

const PathHistoryContext = createContext<PathHistoryContextType>({
  pathHistory: [],
  setPathHistory: () => {},
  navigateToPreviousPath: () => {},
})

const previousPathIndex = 0

export const PathHistoryProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const navigate = useNavigate()
  const [pathHistory, setPathHistory] = useState<string[]>([])

  const previousPath = useMemo(() => {
    const hasPreviousPath = pathHistory.length === 2

    if (hasPreviousPath) {
      return pathHistory[previousPathIndex]
    }
  }, [pathHistory])

  const navigateToPreviousPath = (navigateOptions?: NavigateOptions) => {
    if (previousPath) {
      navigate(previousPath, navigateOptions)
    } else {
      navigate(-1)
    }
  }

  return (
    <PathHistoryContext.Provider value={{ pathHistory, setPathHistory, navigateToPreviousPath }}>
      {children}
    </PathHistoryContext.Provider>
  )
}

export const usePathHistoryContext = (): PathHistoryContextType => {
  const { pathHistory, setPathHistory, navigateToPreviousPath } = useContext(PathHistoryContext)

  if (pathHistory === undefined) {
    throw new Error('usePathHistory must be used within a PathHistoryProvider')
  }

  return { pathHistory, setPathHistory, navigateToPreviousPath }
}
