import React from 'react'
import { useNavigate } from 'react-router-dom'

import { NotificationPreview } from '../../Notifications/NotificationPreview'
import { Notification } from '../../model/Notification'
import { Carousel } from '../../ui/Carousel/Carousel'
import { CarouselChild } from '../../ui/Carousel/CarouselItem'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useNotificationUnread } from '../../utils/notifications'
import { isOne, isZero } from '../../utils/validations'

import styles from './NotificationSlider.module.scss'

interface NotificationSliderProps {
  notifications: Notification[]
  hideNotifications: () => void
}

export const NotificationSlider = ({
  notifications,
  hideNotifications,
}: NotificationSliderProps) => {
  const { refreshAllNotifications } = useNotificationUnread()
  const isMobile = useWindowResize()
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()

  const readNotification = async (notification: Notification) => {
    if (!notification.isRead) {
      apiClient.readNotification(notification.id)
      refreshAllNotifications()
    }
  }

  const openNotification = (id: string) => navigate(`/notifications/${id}`)

  if (isZero(notifications.length)) {
    return null
  }
  if (isOne(notifications.length)) {
    const notification = notifications[0]
    return (
      <NotificationPreview
        read={() => readNotification(notification)}
        open={() => openNotification(notification.id)}
        hide={hideNotifications}
        notification={notification}
        externalMode
      />
    )
  }

  if (isMobile) {
    return (
      <div className={styles.mobileCarousel}>
        {notifications.map((notification) => (
          <NotificationPreview
            hide={hideNotifications}
            read={() => readNotification(notification)}
            open={() => openNotification(notification.id)}
            externalMode
            notification={notification}
          />
        ))}
      </div>
    )
  }

  return (
    <Carousel loop showArrows className={styles.carousel} interval={5000}>
      {notifications.map((notification) => (
        <CarouselChild key={notification.id} className={styles.carouselChild}>
          <NotificationPreview
            hide={hideNotifications}
            read={() => readNotification(notification)}
            open={() => openNotification(notification.id)}
            externalMode
            notification={notification}
          />
        </CarouselChild>
      ))}
    </Carousel>
  )
}
