import { IconProps, iconColor } from '../../../../icons/IconProps'

export function DownloadAPKIcon(props: IconProps): JSX.Element {
  const width = props.width ?? 99
  const height = props.height ?? 40
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox='0 0 99 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1.16406'
        y='0.5'
        width='97'
        height='39'
        rx='4.5'
        fill='black'
        stroke='#848484'
        strokeWidth='0.8'
      />
      <path
        d='M85.2666 14.2368H83.9519L83.9604 13.3956H85.2666C85.647 13.3956 85.9654 13.3124 86.2219 13.1462C86.4811 12.9799 86.6756 12.7418 86.8052 12.4318C86.9377 12.1218 87.0039 11.7526 87.0039 11.3243V10.9903C87.0039 10.6578 86.9658 10.3633 86.8898 10.1068C86.8165 9.85039 86.7066 9.63481 86.56 9.46009C86.4163 9.28536 86.2388 9.15291 86.0274 9.06273C85.8189 8.97255 85.578 8.92746 85.3046 8.92746H83.9266V8.08203H85.3046C85.7132 8.08203 86.0866 8.15107 86.4248 8.28916C86.7629 8.42443 87.0546 8.62029 87.2998 8.87674C87.5478 9.13319 87.738 9.44036 87.8704 9.79826C88.0029 10.1562 88.0691 10.5563 88.0691 10.9988V11.3243C88.0691 11.7667 88.0029 12.1669 87.8704 12.5248C87.738 12.8827 87.5478 13.1899 87.2998 13.4463C87.0518 13.6999 86.7559 13.8958 86.4121 14.0339C86.0711 14.1691 85.6893 14.2368 85.2666 14.2368ZM84.5395 8.08203V14.2368H83.4785V8.08203H84.5395Z'
        fill='white'
      />
      <path
        d='M80.1341 8.9021L78.2953 14.2368H77.1836L79.5 8.08203H80.2102L80.1341 8.9021ZM81.6727 14.2368L79.8297 8.9021L79.7494 8.08203H80.4638L82.7887 14.2368H81.6727ZM81.584 11.9541V12.7953H78.2361V11.9541H81.584Z'
        fill='white'
      />
      <path
        d='M76.7684 10.9928V11.331C76.7684 11.796 76.7078 12.2131 76.5866 12.5822C76.4655 12.9514 76.2921 13.2656 76.0667 13.5249C75.8441 13.7842 75.5764 13.9828 75.2636 14.1209C74.9508 14.2562 74.6041 14.3238 74.2237 14.3238C73.8461 14.3238 73.5009 14.2562 73.1881 14.1209C72.8781 13.9828 72.609 13.7842 72.3807 13.5249C72.1524 13.2656 71.9749 12.9514 71.8481 12.5822C71.7241 12.2131 71.6621 11.796 71.6621 11.331V10.9928C71.6621 10.5278 71.7241 10.1122 71.8481 9.74582C71.9721 9.37665 72.1468 9.06243 72.3723 8.80316C72.6005 8.54108 72.8696 8.3424 73.1796 8.20713C73.4924 8.06904 73.8376 8 74.2153 8C74.5957 8 74.9423 8.06904 75.2551 8.20713C75.5679 8.3424 75.837 8.54108 76.0625 8.80316C76.2879 9.06243 76.4612 9.37665 76.5824 9.74582C76.7064 10.1122 76.7684 10.5278 76.7684 10.9928ZM75.7074 11.331V10.9844C75.7074 10.6406 75.6736 10.3376 75.606 10.0755C75.5411 9.81064 75.4439 9.58941 75.3143 9.41187C75.1875 9.23151 75.0311 9.09624 74.8451 9.00607C74.6591 8.91307 74.4492 8.86657 74.2153 8.86657C73.9814 8.86657 73.7728 8.91307 73.5896 9.00607C73.4065 9.09624 73.2501 9.23151 73.1204 9.41187C72.9936 9.58941 72.8964 9.81064 72.8288 10.0755C72.7611 10.3376 72.7273 10.6406 72.7273 10.9844V11.331C72.7273 11.6748 72.7611 11.9792 72.8288 12.2441C72.8964 12.509 72.995 12.733 73.1247 12.9162C73.2571 13.0966 73.4149 13.2332 73.5981 13.3262C73.7813 13.4164 73.9898 13.4615 74.2237 13.4615C74.4604 13.4615 74.6704 13.4164 74.8535 13.3262C75.0367 13.2332 75.1917 13.0966 75.3185 12.9162C75.4453 12.733 75.5411 12.509 75.606 12.2441C75.6736 11.9792 75.7074 11.6748 75.7074 11.331Z'
        fill='white'
      />
      <path
        d='M71.3219 13.3956V14.2368H68.2319V13.3956H71.3219ZM68.5278 8.08203V14.2368H67.4668V8.08203H68.5278Z'
        fill='white'
      />
      <path
        d='M66.2006 8.08203V14.2368H65.1396L62.3794 9.82785V14.2368H61.3184V8.08203H62.3794L65.1481 12.4994V8.08203H66.2006Z'
        fill='white'
      />
      <path
        d='M55.2374 12.8545L56.4632 8.08203H57.1057L57.0339 9.31214L55.7235 14.2368H55.0556L55.2374 12.8545ZM54.3201 8.08203L55.3135 12.8165L55.4022 14.2368H54.6921L53.2676 8.08203H54.3201ZM58.4965 12.8038L59.4771 8.08203H60.5339L59.1094 14.2368H58.3992L58.4965 12.8038ZM57.3551 8.08203L58.5683 12.8672L58.7458 14.2368H58.078L56.7887 9.31214L56.7211 8.08203H57.3551Z'
        fill='white'
      />
      <path
        d='M52.6336 10.9928V11.331C52.6336 11.796 52.573 12.2131 52.4519 12.5822C52.3307 12.9514 52.1574 13.2656 51.9319 13.5249C51.7093 13.7842 51.4416 13.9828 51.1288 14.1209C50.816 14.2562 50.4694 14.3238 50.0889 14.3238C49.7113 14.3238 49.3661 14.2562 49.0533 14.1209C48.7433 13.9828 48.4742 13.7842 48.2459 13.5249C48.0177 13.2656 47.8401 12.9514 47.7133 12.5822C47.5893 12.2131 47.5273 11.796 47.5273 11.331V10.9928C47.5273 10.5278 47.5893 10.1122 47.7133 9.74582C47.8373 9.37665 48.012 9.06243 48.2375 8.80316C48.4658 8.54108 48.7349 8.3424 49.0449 8.20713C49.3577 8.06904 49.7029 8 50.0805 8C50.4609 8 50.8075 8.06904 51.1203 8.20713C51.4331 8.3424 51.7023 8.54108 51.9277 8.80316C52.1532 9.06243 52.3265 9.37665 52.4476 9.74582C52.5716 10.1122 52.6336 10.5278 52.6336 10.9928ZM51.5726 11.331V10.9844C51.5726 10.6406 51.5388 10.3376 51.4712 10.0755C51.4064 9.81064 51.3092 9.58941 51.1795 9.41187C51.0527 9.23151 50.8963 9.09624 50.7103 9.00607C50.5243 8.91307 50.3144 8.86657 50.0805 8.86657C49.8466 8.86657 49.6381 8.91307 49.4549 9.00607C49.2717 9.09624 49.1153 9.23151 48.9857 9.41187C48.8589 9.58941 48.7616 9.81064 48.694 10.0755C48.6264 10.3376 48.5926 10.6406 48.5926 10.9844V11.331C48.5926 11.6748 48.6264 11.9792 48.694 12.2441C48.7616 12.509 48.8603 12.733 48.9899 12.9162C49.1224 13.0966 49.2802 13.2332 49.4633 13.3262C49.6465 13.4164 49.855 13.4615 50.0889 13.4615C50.3257 13.4615 50.5356 13.4164 50.7188 13.3262C50.9019 13.2332 51.0569 13.0966 51.1838 12.9162C51.3106 12.733 51.4064 12.509 51.4712 12.2441C51.5388 11.9792 51.5726 11.6748 51.5726 11.331Z'
        fill='white'
      />
      <path
        d='M43.8564 14.2368H42.5418L42.5502 13.3956H43.8564C44.2368 13.3956 44.5553 13.3124 44.8117 13.1462C45.071 12.9799 45.2654 12.7418 45.3951 12.4318C45.5275 12.1218 45.5937 11.7526 45.5937 11.3243V10.9903C45.5937 10.6578 45.5557 10.3633 45.4796 10.1068C45.4063 9.85039 45.2964 9.63481 45.1499 9.46009C45.0062 9.28536 44.8286 9.15291 44.6173 9.06273C44.4087 8.97255 44.1678 8.92746 43.8944 8.92746H42.5164V8.08203H43.8944C44.3031 8.08203 44.6765 8.15107 45.0146 8.28916C45.3528 8.42443 45.6445 8.62029 45.8896 8.87674C46.1376 9.13319 46.3278 9.44036 46.4603 9.79826C46.5927 10.1562 46.6589 10.5563 46.6589 10.9988V11.3243C46.6589 11.7667 46.5927 12.1669 46.4603 12.5248C46.3278 12.8827 46.1376 13.1899 45.8896 13.4463C45.6416 13.6999 45.3457 13.8958 45.0019 14.0339C44.661 14.1691 44.2791 14.2368 43.8564 14.2368ZM43.1294 8.08203V14.2368H42.0684V8.08203H43.1294Z'
        fill='white'
      />
      <path
        d='M68.8564 18.3242V31.1211H66.6504V18.3242H68.8564ZM76.5469 18.3242L71.3613 24.4854L68.4082 27.6143L68.0215 25.4258L70.1309 22.8242L73.8574 18.3242H76.5469ZM74.1738 31.1211L69.9639 25.0566L71.4844 23.5537L76.793 31.1211H74.1738Z'
        fill='white'
      />
      <path
        d='M60.0146 26.3486H56.6836V24.5996H60.0146C60.5947 24.5996 61.0635 24.5059 61.4209 24.3184C61.7783 24.1309 62.0391 23.873 62.2031 23.5449C62.373 23.2109 62.458 22.8301 62.458 22.4023C62.458 21.998 62.373 21.6201 62.2031 21.2686C62.0391 20.9111 61.7783 20.624 61.4209 20.4072C61.0635 20.1904 60.5947 20.082 60.0146 20.082H57.3604V31.1211H55.1543V18.3242H60.0146C61.0049 18.3242 61.8457 18.5 62.5371 18.8516C63.2344 19.1973 63.7646 19.6777 64.1279 20.293C64.4912 20.9023 64.6729 21.5996 64.6729 22.3848C64.6729 23.2109 64.4912 23.9199 64.1279 24.5117C63.7646 25.1035 63.2344 25.5576 62.5371 25.874C61.8457 26.1904 61.0049 26.3486 60.0146 26.3486Z'
        fill='white'
      />
      <path
        d='M48.2031 20.0293L44.3799 31.1211H42.0684L46.8848 18.3242H48.3613L48.2031 20.0293ZM51.4023 31.1211L47.5703 20.0293L47.4033 18.3242H48.8887L53.7227 31.1211H51.4023ZM51.2178 26.375V28.124H44.2568V26.375H51.2178Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.4651 8C20.1762 8 19.8993 8.11473 19.695 8.31896C19.4908 8.52319 19.3761 8.80018 19.3761 9.089V11.267H12.8421C12.2644 11.267 11.7104 11.4965 11.302 11.9049C10.8935 12.3134 10.6641 12.8674 10.6641 13.445V27.602C10.6641 28.1796 10.8935 28.7336 11.302 29.1421C11.7104 29.5505 12.2644 29.78 12.8421 29.78H28.0881C28.6657 29.78 29.2197 29.5505 29.6281 29.1421C30.0366 28.7336 30.2661 28.1796 30.2661 27.602V13.445C30.2661 12.8674 30.0366 12.3134 29.6281 11.9049C29.2197 11.4965 28.6657 11.267 28.0881 11.267H21.5541V9.089C21.5541 8.80018 21.4393 8.52319 21.2351 8.31896C21.0309 8.11473 20.7539 8 20.4651 8ZM21.5541 11.267V20.8807L23.5448 18.89C23.749 18.6857 24.026 18.5708 24.3148 18.5707C24.6037 18.5706 24.8808 18.6853 25.0851 18.8895C25.2895 19.0937 25.4043 19.3707 25.4044 19.6595C25.4045 19.9484 25.2899 20.2255 25.0857 20.4298L21.4266 24.0878C21.1714 24.3428 20.8253 24.4861 20.4645 24.4861C20.1037 24.4861 19.7576 24.3428 19.5024 24.0878L15.8444 20.4298C15.7433 20.3287 15.6631 20.2086 15.6084 20.0764C15.5538 19.9442 15.5256 19.8026 15.5257 19.6595C15.5257 19.5165 15.554 19.3749 15.6087 19.2427C15.6635 19.1106 15.7438 18.9906 15.845 18.8895C15.9462 18.7883 16.0663 18.7082 16.1984 18.6535C16.3306 18.5988 16.4722 18.5707 16.6153 18.5707C16.7583 18.5708 16.9 18.599 17.0321 18.6538C17.1642 18.7085 17.2843 18.7888 17.3854 18.89L19.3761 20.8807V11.267H21.5541Z'
        fill='white'
      />
    </svg>
  )
}
