import React, { useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { SearchIcon } from '../../icons/SearchIcon'
import { NameDto } from '../../model/NameDto'
import { TextSmall } from '../../ui/Typography/Typography'
import { getCountryCode } from '../../utils/Countries'
import { useAutoselect } from '../../utils/autoSelect'
import { inMemorySort } from '../../utils/useSortBy'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'

import styles from './CountrySelectModal.module.scss'

interface Props {
  title: string
  info?: string
  options: NameDto<string>[]
  onCancel: () => void
  handleOnChangeOption: (regulator: NameDto<string>) => void
  renderFooter?: () => React.ReactNode
  selectedCountry: string | undefined
}

export const CountrySelectModal: React.FC<Props> = ({
  renderFooter,
  onCancel,
  handleOnChangeOption,
  title,
  info,
  options,
  selectedCountry,
}) => {
  const [search, setSearch] = useState('')
  const [countryList, setCountryList] = useState(options)
  const { t } = useTranslation()

  const isArabic = useArabicSessionLanguage()

  const searchRef = useAutoselect<HTMLInputElement>(true)

  const handleSetSearch = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target
    setSearch(value)
    setCountryList(
      options.filter((c) => c.name.toLowerCase().trim().includes(value.toLowerCase().trim()))
    )
  }

  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{title}</p>
      </header>

      <section className={classNames('modal-card-body', styles.body)}>
        <ul>
          <li>
            <div className={styles.searchBlock}>
              <div
                className={classNames('control', {
                  'has-icons-right': isArabic,
                  'has-icons-left': !isArabic,
                })}
              >
                <input
                  className={classNames('input', styles.input)}
                  type='text'
                  placeholder={t('Search')}
                  onChange={handleSetSearch}
                  value={search}
                  ref={searchRef}
                />
                <span
                  className={classNames('icon is-small', {
                    'is-right': isArabic,
                    'is-left': !isArabic,
                  })}
                >
                  <SearchIcon />
                </span>
              </div>
            </div>
          </li>
          {inMemorySort(countryList, 'name').map((country) => (
            <li
              key={country.id}
              onClick={() => handleOnChangeOption(country)}
              className={classNames(styles.labelWrapper, {
                'has-background-light': country.name === selectedCountry,
              })}
            >
              <ReactCountryFlag
                className={styles.countryFlag}
                svg
                countryCode={getCountryCode(country.id === 'LFT' ? 'MYS' : country.id) || ''}
              />
              <TextSmall>{country.name}</TextSmall>
            </li>
          ))}
        </ul>
      </section>
      <footer className='modal-card-foot'>
        {!renderFooter ? (
          <button className='button' onClick={onCancel} type='button'>
            {t('Cancel')}
          </button>
        ) : (
          renderFooter()
        )}
      </footer>
    </>
  )
}
