import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CountrySelectModal } from '../../../../../global/modal/CountrySelectModal'
import { InfoModal } from '../../../../../global/modal/InfoModal'
import { InformationModal } from '../../../../../global/modal/InformationModal'
import { Modal } from '../../../../../global/modal/Modal'
import { NameDto } from '../../../../../model/NameDto'
import {
  RegulatorDto,
  RegulatorOptionDto,
  RegulatorProductOption,
} from '../../../../../model/RegulatorOptionDto'
import { Text } from '../../../../../ui/Typography/Typography'

import styles from '../PersonalDetailsPage.module.scss'

interface CountryOfResidenceModalProps {
  regulators: RegulatorOptionDto[]
  selectedCountry?: string
  onChangeOption(option: NameDto<string>): void
  closeModal(): void
  title?: string
}

export const CountryOfResidenceModal: React.FC<CountryOfResidenceModalProps> = (props) => {
  const { selectedCountry, regulators, onChangeOption, closeModal, title } = props

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={closeModal}
      cardClassName={styles.modal}
      render={() => (
        <CountrySelectModal
          title={title || t('Sign up.Country of Residence')}
          options={regulators.map((regulator) => regulator.country)}
          selectedCountry={selectedCountry}
          onCancel={closeModal}
          handleOnChangeOption={onChangeOption}
        />
      )}
    />
  )
}

export const ProductInfoModal: React.FC<{ closeModal(): void; product: RegulatorProductOption }> = (
  props
) => {
  const { closeModal, product } = props

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={closeModal}
      render={({ closeModal }) => (
        <InformationModal
          onCancelText={t('Got it!')}
          title={t('Sign up.General Information')}
          onCancel={closeModal}
        >
          {product.optionPopup.map((p, idx) => (
            <Text key={idx} isParagraph>
              {p}
            </Text>
          ))}
        </InformationModal>
      )}
    />
  )
}

interface RegulatorInfoModalProps {
  regulator?: RegulatorDto
  visible: boolean
}

export const RegulatorInfoModal: React.FC<RegulatorInfoModalProps & { closeModal(): void }> = (
  props
) => {
  const { regulator, closeModal } = props

  const { t } = useTranslation()

  return (
    <Modal
      cardClassName={styles.modal}
      closeModal={closeModal}
      render={({ closeModal }) => (
        <InfoModal
          onCancel={closeModal}
          title={regulator?.title || ''}
          renderBody={() => (
            <section className='modal-card-body'>
              <Text isParagraph className='mb-5'>
                {regulator?.name}
              </Text>
              <ul className={classNames('pl-4', styles.bulletList)}>
                {regulator?.optionsPopup.map((p, idx) => (
                  <li key={'optionsPopup-' + idx}>
                    <Text
                      isParagraph
                      dangerouslySetInnerHTML={{
                        __html: p,
                      }}
                    />
                  </li>
                ))}
              </ul>
            </section>
          )}
          renderFooter={() => {
            return (
              <React.Fragment>
                <button className='button' onClick={closeModal} type='button'>
                  <b>{t('Got It')}</b>
                </button>
              </React.Fragment>
            )
          }}
          onConfirm={closeModal}
        />
      )}
    />
  )
}

export const RegulatorPopUpModal: React.FC<
  RegulatorInfoModalProps & { onSubmit(): void; closeModal(): void }
> = (props) => {
  const { regulator, onSubmit, closeModal } = props

  const { t } = useTranslation()

  return (
    <Modal
      cardClassName={styles.modal}
      closeModal={closeModal}
      render={({ closeModal }) => (
        <InfoModal
          onCancel={closeModal}
          title={regulator?.title || ''}
          renderBody={() => (
            <div className='modal-card-body'>
              <ul>
                {regulator?.popupText?.map((p) => (
                  <React.Fragment>
                    <Text
                      isParagraph
                      dangerouslySetInnerHTML={{
                        __html: p,
                      }}
                    />
                    <br />
                  </React.Fragment>
                ))}
              </ul>
            </div>
          )}
          renderFooter={() => {
            if (regulator?.redirect) {
              return (
                <button className='button' onClick={onSubmit} type='button'>
                  <b>{t('Got It')}</b>
                </button>
              )
            }
            return (
              <button className='button' onClick={onSubmit} type='button'>
                <b>{t('Got It')}</b>
              </button>
            )
          }}
          onConfirm={closeModal}
        />
      )}
    />
  )
}

export const EntityInfoModal: React.FC<{ closeModal(): void }> = (props) => {
  const { closeModal } = props

  const { t } = useTranslation()

  return (
    <Modal
      cardClassName={styles.modal}
      closeModal={closeModal}
      render={({ closeModal }) => (
        <InfoModal
          onCancel={closeModal}
          title={t('Sign up.US Citizen in tax purposes')}
          renderBody={() => (
            <div className='modal-card-body'>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Sign up.In case you are US citizen You unfortunately can’t use our services'
                  ),
                }}
              />
            </div>
          )}
          renderFooter={() => (
            <React.Fragment>
              <button className='button' onClick={closeModal} type='button'>
                <b>{t('Got It')}!</b>
              </button>
            </React.Fragment>
          )}
          onConfirm={closeModal}
        />
      )}
    />
  )
}
