import React from 'react'

import { NotificationType } from '../../model/Notification'
import {
  useHideNotifications,
  useNotificationAlerts,
  useNotificationFunctionality,
} from '../../utils/notifications'
import { SystemNotification } from '../systemNotification/SystemNotification'

export const PlatformNotification = () => {
  const { enableNotifications } = useNotificationFunctionality()
  const { hideNotifications } = useHideNotifications()
  const { platformNotifications } = useNotificationAlerts()

  if (!platformNotifications.length || !enableNotifications) {
    return null
  }
  const notification = platformNotifications[0]

  return (
    <SystemNotification
      id={notification.id}
      title={notification.title}
      close={() => hideNotifications([NotificationType.Platform])}
    />
  )
}
