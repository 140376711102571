import React from 'react'

import {
  LegalDocumentDto,
  isLegalDocumentWidgetCheckbox,
  isLegalDocumentWidgetReadAndAgree,
} from '../../../../../model/CompanyLegalDocumentDto'
import { DocumentFieldItem } from '../../../Components/DocumentReadAndAgreeField'
import {
  PersonalDetailsDocumentsFactory,
  filterDocumentByGroupReference,
  isDocumentCheckboxReferences,
  isDocumentReadAndAgreeReferences,
} from '../PersonalDetailsDocumentsFactory'

interface DocumentGroupsFactoryProps {
  groupReferences: GroupReference[]
  documents: LegalDocumentDto[]
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

type GroupReference = string | null

export const DocumentGroupsFactory: React.FC<DocumentGroupsFactoryProps> = (props) => {
  const { documents, groupReferences, renderItem } = props

  return (
    <div className='mt-4'>
      {isDocumentReadAndAgreeReferences(groupReferences, documents) && (
        <div className='is-flex is-flex-direction-row mt-4'>
          <div className='is-align-items-start is-flex-grow-1'>
            {groupReferences.map((groupReference) => (
              <PersonalDetailsDocumentsFactory
                groupReference={groupReference}
                documents={documents
                  .filter(isLegalDocumentWidgetReadAndAgree)
                  .filter(filterDocumentByGroupReference(groupReference))}
                renderItem={renderItem}
                key={groupReference}
              />
            ))}
          </div>
        </div>
      )}
      {isDocumentCheckboxReferences(groupReferences, documents) && (
        <div className='is-flex is-flex-direction-row is-flex-grow-1 mt-4'>
          <div className='is-align-items-start is-flex-grow-1 pt-2'>
            {groupReferences.map((groupReference) => (
              <PersonalDetailsDocumentsFactory
                groupReference={groupReference}
                documents={documents
                  .filter(isLegalDocumentWidgetCheckbox)
                  .filter(filterDocumentByGroupReference(groupReference))}
                renderItem={renderItem}
                key={groupReference}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
