import { Path, Rect, Svg } from '@react-pdf/renderer'

export const CheckBoxOnSVG = (): JSX.Element => (
  <Svg width='12' height='12' viewBox='0 0 12 12' fill='none'>
    <Path
      fillRule='evenodd'
      d='M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.895431 11.1046 0 10 0H2ZM9.4 2.66667L10.3333 3.6L5.93333 8L5 8.93333L4.06667 8L1.66667 5.6L2.6 4.66667L5 7.06667L9.4 2.66667Z'
      fill='#3C3C3C'
    />
  </Svg>
)

export const CheckBoxOffSVG = (): JSX.Element => (
  <Svg viewBox='175.581 124.419 11.628 11.628' width='11.628' height='11.628'>
    <Rect
      x='175.581'
      y='124.419'
      width='11.628'
      height='11.628'
      style={{ fill: 'rgb(216, 216, 216)', stroke: 'rgb(0, 0, 0)' }}
    />
  </Svg>
)

export const TMDocLogoPDF = (): JSX.Element => (
  <Svg width='118' height='25' viewBox='0 0 118 25' fill='none'>
    <Path
      d='M33.8442 7.89976V18.632H30.8542V7.89976H27.0547V5.31055H37.6437V7.89976H33.8442Z'
      fill='#3C3C3C'
    />
    <Path d='M39.8281 5.33643H42.8246V18.632H39.8281V5.33643Z' fill='#3C3C3C' />
    <Path
      d='M52.2851 16.0103C52.9811 16.023 53.6703 15.8725 54.2966 15.571C54.9228 15.2695 55.4683 14.8257 55.8888 14.2755L57.8081 16.2175C57.175 16.9629 56.3954 17.5725 55.5168 18.0093C54.6381 18.446 53.679 18.7006 52.6978 18.7575C51.7167 18.8145 50.7341 18.6725 49.8101 18.3404C48.8861 18.0083 48.04 17.493 47.3236 16.8259C46.0321 15.5061 45.313 13.7374 45.3204 11.8985C45.3277 10.0596 46.0608 8.29658 47.3628 6.98692C48.0221 6.34875 48.8037 5.8481 49.6615 5.51456C50.5193 5.18102 51.4359 5.02136 52.3569 5.04502C53.4073 5.0283 54.4482 5.24321 55.4045 5.67421C56.3608 6.10521 57.2085 6.74151 57.8865 7.53713L56.0259 9.62792C55.6169 9.08213 55.084 8.6395 54.4704 8.33587C53.8569 8.03224 53.1798 7.87613 52.494 7.8802C51.4249 7.85646 50.3889 8.2492 49.6085 8.97414C49.2153 9.34879 48.9075 9.8026 48.7059 10.305C48.5044 10.8075 48.4137 11.3469 48.4399 11.887C48.4147 12.4333 48.5018 12.979 48.696 13.4909C48.8902 14.0028 49.1873 14.4701 49.5693 14.8646C49.92 15.2282 50.3416 15.5173 50.8085 15.7143C51.2753 15.9112 51.7777 16.0119 52.2851 16.0103Z'
      fill='#3C3C3C'
    />
    <Path
      d='M60.1953 5.33643H63.1853V10.7932L68.2513 5.33643H71.9463L66.5996 11.2398C67.061 11.8742 67.9118 13.0566 69.1522 14.787L71.9333 18.6709H68.4406L64.5236 13.4212L63.1788 14.9036V18.6709H60.1953V5.33643Z'
      fill='#3C3C3C'
    />
    <Path
      d='M85.8012 10.2624L82.178 17.5316H80.3892L76.7856 10.2624V18.632H73.7891V5.33643H77.8171L81.2705 12.638L84.7436 5.33643H88.7781V18.632H85.7816L85.8012 10.2624Z'
      fill='#3C3C3C'
    />
    <Path d='M92.0938 5.33643H95.0903V18.632H92.0938V5.33643Z' fill='#3C3C3C' />
    <Path d='M98.3906 18.632V5.33643H101.387V15.991H107.099V18.632H98.3906Z' fill='#3C3C3C' />
    <Path d='M109.289 18.632V5.33643H112.279V15.991H117.998V18.632H109.289Z' fill='#3C3C3C' />
    <Path
      d='M5.35325 9.45342C5.36073 9.31359 5.40126 9.17747 5.47157 9.05603C5.54188 8.9346 5.64 8.83125 5.758 8.75433L10.1777 6.21691C10.3018 6.15333 10.4395 6.12016 10.5792 6.12016C10.7189 6.12016 10.8566 6.15333 10.9807 6.21691L14.8324 8.43068L20.2771 5.32363L20.1595 5.23948L11.5487 0.235834C11.2459 0.0808647 10.9101 0 10.5694 0C10.2287 0 9.89291 0.0808647 9.59015 0.235834L0.992309 5.2589C0.701287 5.4537 0.460074 5.71308 0.287822 6.01647C0.115569 6.31985 0.0170197 6.65887 0 7.00662V16.9298L5.35325 13.8292V9.45342Z'
      fill='#CC0000'
    />
    <Path
      d='M15.7962 10.1394V14.541C15.789 14.6793 15.7492 14.8139 15.68 14.9342C15.6109 15.0545 15.5143 15.157 15.398 15.2336L10.9653 17.7775C10.8411 17.8411 10.7035 17.8743 10.5638 17.8743C10.4241 17.8743 10.2864 17.8411 10.1623 17.7775L6.30402 15.5573L0.859375 18.6708L0.983413 18.755L9.58125 23.7716C9.88401 23.9265 10.2198 24.0074 10.5605 24.0074C10.9012 24.0074 11.237 23.9265 11.5398 23.7716L20.1376 18.755C20.4272 18.5587 20.6673 18.299 20.8394 17.9959C21.0115 17.6928 21.1109 17.3546 21.1299 17.0073V7.07764L15.7962 10.1394Z'
      fill='#CC0000'
    />
  </Svg>
)
