import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { WalletDepositFormTransactionSuccessState } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositTransactionPage/TradersRoomWalletDepositTransactionSuccessPage'
import { WalletDepositTransactionSuccessPage } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositTransactionPage/WalletDepositTransactionSuccessPage'
import { useProductReadContext } from '../../global/context/ProductContext'

export const PaymentAgentWalletDepositTransactionSuccessPage: React.FC = (props) => {
  const navigate = useNavigate()
  const { isDefaultCFDProductType } = useProductReadContext()
  const location = useLocation()

  const stateValue = (location.state as WalletDepositFormTransactionSuccessState) || {}

  const getNavigationPath = () => {
    if (isDefaultCFDProductType()) {
      return stateValue?.navigateToTransactionHistory
        ? '/dashboard/payment-agent/transaction-history'
        : '/dashboard/payment-agent/wallets'
    }
    return '/dashboard/traders-room/balances'
  }

  const handleCancel = () => {
    navigate(getNavigationPath())
  }

  return <WalletDepositTransactionSuccessPage {...props} onCancel={handleCancel} />
}
