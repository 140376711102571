import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { SelectModal, SelectModalOption } from '../../../global/field/SelectModal'
import { createFormField } from '../../../global/formField/FormField'
import { createFormNumericField } from '../../../global/formField/FormNumericField'
import { CancelActionModal } from '../../../global/modal/CancleActionModal'
import { Modal } from '../../../global/modal/Modal'
import { FormTemplate } from '../../../global/templates/FormTemplate'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DropArrowDownIcon } from '../../../icons/DropArrowDownIcon'
import { NameDto } from '../../../model/NameDto'
import { TradingAccount } from '../../../model/TradingAccount'
import { CurrencyType, WalletDto } from '../../../model/WalletDto'
import { Text, TextSmall, TextTinyStrong } from '../../../ui/Typography/Typography'
import { usePathHistoryContext } from '../../../utils/PathHistoryContext'
import { currencyToSymbol } from '../../../utils/currency'
import { TradingAccountRestrictions } from '../../../utils/trading-account.utils'
import { useRedirectToSupport } from '../../../utils/useRedirectToSupport'
import { useScrollToTop } from '../../../utils/useScrollToTop'
import { WalletRestrictions, isRestricted } from '../../../utils/wallet.utils'
import { getRestrictionMessage } from './Balance'

import styles from './TransferForm.module.scss'

const FormNumericField = createFormNumericField()
type DisplayType = 'wallet' | 'tradingAccount'

interface WalletOrTradingAccountOption {
  id: string
  name: string
  currency: CurrencyType
  type: string
  balance: number | null
  displayType: DisplayType

  restrictions: NameDto[]
}

enum TransferFromType {
  Wallet = 'wallet',
  TA = 'tradingAccount',
}

interface MatrixOption {
  wallet: WalletOrTradingAccountOption
  tradingAccount: WalletOrTradingAccountOption
}

const getMatrixOptions = (wallets: WalletDto[], tradingAccounts: TradingAccount[]) => {
  const { id, name, platformOverview, restrictions } = tradingAccounts?.[0] || {}

  return wallets.reduce<Partial<Record<CurrencyType, MatrixOption>>>((options, wallet) => {
    const balanceObj = platformOverview?.balances.find((b) => b.currency === wallet.currency.id)

    const balance = balanceObj?.balance || null

    options[wallet.currency.id] = {
      wallet: {
        id: wallet.id,
        name: wallet.name,
        currency: wallet.currency.id,
        balance: wallet.balance,
        type: t('Wallet.Wallet'),
        displayType: 'wallet',
        restrictions: wallet.restrictions,
      },
      tradingAccount: {
        id,
        name,
        currency: wallet.currency.id,
        balance,
        type: t('Trading Account.Trading Account'),
        displayType: 'tradingAccount',
        restrictions,
      },
    }

    return options
  }, {})
}

export interface TransferValues {
  transferFrom: WalletOrTradingAccountOption | null
  transferTo: WalletOrTradingAccountOption | null
  fromAmount: number | undefined
}

interface OuterProps {
  wallets: WalletDto[]
  currency?: CurrencyType
  fetchingAccounts: boolean
  tradingAccounts: TradingAccount[]
  preselectedWallet?: WalletDto
  preselectedTradingAccount?: TradingAccount
  handleFormSubmit: (values: TransferValues) => void
}

const FormField = createFormField<TransferValues>()

type TransferFormProps = FormikProps<TransferValues> & OuterProps

const TransferFormETDUI: React.FC<TransferFormProps> = (props) => {
  const {
    wallets,
    tradingAccounts,
    values,
    isValid,
    setFieldValue,
    handleSubmit,
    fetchingAccounts,
  } = props
  useScrollToTop()
  const { transferFrom, fromAmount } = values

  const [exitConfirmationModal, setExitConfirmationModal] = useState(false)
  const [transferFromModal, setTransferFromModal] = useState(false)
  const showTANotice = useMemo(() => {
    if (!tradingAccounts.length) {
      return false
    }
    if (values.transferFrom?.displayType !== 'wallet') {
      return false
    }
    return !tradingAccounts.some(({ platformOverview }) =>
      platformOverview.balances?.some((b) => b.currency === transferFrom?.currency)
    )
  }, [tradingAccounts, transferFrom?.currency, values.transferFrom?.displayType])

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const { navigateToPreviousPath } = usePathHistoryContext()
  const { supportUrl } = useRedirectToSupport()

  const matrixOptions = useMemo<Partial<Record<CurrencyType, MatrixOption>>>(
    () => getMatrixOptions(wallets, tradingAccounts),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wallets, tradingAccounts, t]
  )

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit()
  }

  const handleExitPageConfirmationOpen = () => {
    setExitConfirmationModal(true)
  }

  const locale = useSessionLanguage()

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const handleExitPageConfirmationModal = () => {
    setExitConfirmationModal(false)
  }

  const getBalance = (walletOrTa: WalletOrTradingAccountOption) => {
    const balanceText = t('Trading Account.Balance')

    return (
      <span>{`${balanceText}: ${formatMoney(walletOrTa.balance ?? 0, walletOrTa.currency)}`}</span>
    )
  }

  const handleSetTransferFrom = (walletOrTa: WalletOrTradingAccountOption) => {
    setFieldValue('transferFrom', walletOrTa)
    setTransferFromModal(false)
    handleSetTransferTo(walletOrTa)
  }

  const handleSetTransferTo = (walletOrTa: WalletOrTradingAccountOption) => {
    const transferToOption =
      walletOrTa.displayType === 'wallet'
        ? matrixOptions[walletOrTa.currency]?.tradingAccount
        : matrixOptions[walletOrTa.currency]?.wallet

    setFieldValue('transferTo', transferToOption)
  }

  const hasTransferToRestriction = values.transferTo?.restrictions?.some(
    (restriction: NameDto) => restriction.id === TradingAccountRestrictions.TRANSFER_TO
  )

  const renderOption = (
    currency: string,
    wallet: WalletOrTradingAccountOption,
    tradingAccount: WalletOrTradingAccountOption
  ) => {
    const isWalletDisabled = isRestricted(WalletRestrictions.TRANSFER_FROM, wallet.restrictions)
    const isTADisabled = isRestricted(WalletRestrictions.TRANSFER_FROM, tradingAccount.restrictions)

    return (
      <React.Fragment key={wallet.id}>
        <Text className={styles.currency} isParagraph>
          {currency}
        </Text>
        <SelectModalOption
          label={`${wallet.currency} ${wallet.type}`}
          value={wallet.id === values.transferFrom?.id}
          onClick={() => handleSetTransferFrom(wallet)}
          disabled={isWalletDisabled}
        >
          <>
            {getBalance(wallet)}
            {isWalletDisabled && (
              <>
                <Text className={styles.primaryText} isParagraph>
                  {getRestrictionMessage(true, false, true, t)}
                </Text>
                <div>
                  {t('Please')}{' '}
                  <Link to={supportUrl} target='_blank' rel='noreferrer'>
                    {t('Contact Support').toLowerCase()}
                  </Link>{' '}
                  {t('for more info.')}
                </div>
              </>
            )}
          </>
        </SelectModalOption>
        {(tradingAccount.balance ?? -1) >= 0 && (
          <SelectModalOption
            label={`${tradingAccount.currency} ${tradingAccount.type}`}
            value={
              tradingAccount.currency === values.transferFrom?.currency &&
              tradingAccount.id === values.transferFrom?.id
            }
            onClick={() => handleSetTransferFrom(tradingAccount)}
            key={tradingAccount.id}
            disabled={isTADisabled}
          >
            <>
              <span>{getBalance(tradingAccount)}</span>
              {isTADisabled && (
                <>
                  <Text className={styles.primaryText} isParagraph>
                    {getRestrictionMessage(true, false, false, t)}
                  </Text>
                  <div>
                    {t('Please')}{' '}
                    <Link to={supportUrl} target='_blank' rel='noreferrer'>
                      {t('Contact Support').toLowerCase()}
                    </Link>{' '}
                    {t('for more info.')}
                  </div>
                </>
              )}
            </>
          </SelectModalOption>
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {exitConfirmationModal && (
        <Modal
          closeModal={handleExitPageConfirmationModal}
          render={() => (
            <CancelActionModal
              onConfirm={navigateToPreviousPath}
              onCancel={handleExitPageConfirmationModal}
            />
          )}
        />
      )}
      {transferFromModal && (
        <Modal
          closeModal={() => setTransferFromModal(false)}
          render={({ closeModal }) => (
            <SelectModal
              onCancel={closeModal}
              title={t('Wallet.Transfer From')}
              renderOptions={() => (
                <div className='control'>
                  {Object.entries(matrixOptions).map(([currency, { wallet, tradingAccount }]) =>
                    renderOption(currency, wallet, tradingAccount)
                  )}
                </div>
              )}
            />
          )}
        />
      )}
      <FormTemplate title={t('Wallet.Transfer')} goBack={handleExitPageConfirmationOpen}>
        <Form className={styles.wrapper} onSubmit={handleSubmitForm} autoComplete='off'>
          <Text className={classNames(styles.info)}>
            <span className='danger'>{t('Trading Account.Important')}</span>{' '}
            {t(
              'Transactions.You can only transfer between Wallets and Trading Accounts of the same currency'
            )}
          </Text>
          <Loading showLoadingIcon isLoading={fetchingAccounts}>
            <FormField
              wrapperClassname={styles.noMargin}
              name='transferFrom.name'
              label={t('Wallet.Transfer From')}
              placeholder={t('Wallet.Transfer From')}
              rightIcon={<DropArrowDownIcon />}
              value={
                values.transferFrom
                  ? `${values.transferFrom.currency} ${values.transferFrom.type}`
                  : ''
              }
              readOnly
              hint={
                values.transferFrom &&
                `${t('Trading Account.Balance')} ${formatMoney(
                  values.transferFrom.balance ?? 0,
                  values.transferFrom.currency
                )}`
              }
              required
              onClick={() => setTransferFromModal(true)}
            />
            <div className={styles.amountWrapper}>
              <div className={styles.first}>
                <FormNumericField
                  name='fromAmount'
                  label={t('Wallet.Sending Amount')}
                  placeholder={t('Wallet.Sending Amount')}
                  decimalPlaces={2}
                  disabled={!transferFrom?.id}
                  required
                  step='any'
                  autoComplete='false'
                />
              </div>
              <div className={styles.last}>
                <FormField
                  name='transferFrom.currency'
                  label={t('Wallet.Currency')}
                  placeholder={t('Wallet.Currency')}
                  className={styles.last}
                  value={transferFrom && transferFrom.currency}
                  disabled
                />
              </div>
            </div>
            <div className={styles.transferTo}>
              <FormField
                disabled
                name={'transferTo.id'}
                label={t('Wallet.Transfer To')}
                placeholder={t('Wallet.Transfer To')}
                value={
                  values.transferTo ? `${values.transferTo.currency} ${values.transferTo.type}` : ''
                }
                hint={
                  values.transferTo &&
                  `${t('Trading Account.Balance')} ${formatMoney(
                    values.transferTo.balance ?? 0,
                    values.transferTo.currency
                  )}`
                }
                rightIcon={<DropArrowDownIcon />}
                readOnly
                required
              />
              {hasTransferToRestriction && (
                <TextSmall className={styles.primaryText} isParagraph>
                  {t('Transfer restricted to.TM Trading Account')}
                </TextSmall>
              )}
            </div>
          </Loading>
          {showTANotice && (
            <div className={styles.walletNotice}>
              <div className={styles.symbolWrapper}>
                <TextTinyStrong className={styles.symbol}>
                  {currencyToSymbol(values.transferTo?.currency)}
                </TextTinyStrong>
              </div>
              <TextSmall>
                {t('Wallet.A new currency balance will be added to your Trading Account', {
                  currency: values.transferTo?.currency,
                })}
              </TextSmall>
            </div>
          )}
          <div className={styles.action}>
            <Button
              appearance='secondary'
              type='button'
              onClick={handleExitPageConfirmationOpen}
              size='L'
            >
              {t('Cancel')}
            </Button>
            <Button
              disabled={!isValid || hasTransferToRestriction}
              type='submit'
              appearance='primary'
              size='L'
            >
              {t('Confirm')}
            </Button>
          </div>
        </Form>
      </FormTemplate>
    </React.Fragment>
  )
}

export const TransferFormETD = withFormik<OuterProps, TransferValues>({
  mapPropsToValues: (props) => {
    const { preselectedWallet, preselectedTradingAccount, wallets, currency, tradingAccounts } =
      props

    const transferFrom = preselectedWallet
      ? {
          id: preselectedWallet.id,
          currency: preselectedWallet.currency.id,
          balance: preselectedWallet.balance,
          name: preselectedWallet.name,
          type: t('Wallet.Wallet'),
          displayType: TransferFromType.Wallet,
          restrictions: preselectedWallet.restrictions,
        }
      : preselectedTradingAccount &&
        currency &&
        (preselectedTradingAccount?.platformOverview.balances.find(
          (balance) => balance.currency === currency
        )?.balance ?? -1) >= 0
      ? {
          id: preselectedTradingAccount.id,
          currency: currency,
          balance:
            preselectedTradingAccount.platformOverview.balances.find(
              (balance) => balance.currency === currency
            )?.balance || 0,
          name: preselectedTradingAccount.name,
          type: t('Trading Account.Trading Account'),
          displayType: TransferFromType.TA,
          restrictions: preselectedTradingAccount.restrictions,
        }
      : null

    const matrixOptions = getMatrixOptions(wallets, tradingAccounts)
    const transferTo = transferFrom
      ? transferFrom.displayType === 'wallet'
        ? matrixOptions[transferFrom.currency]?.tradingAccount || null
        : matrixOptions[transferFrom.currency]?.wallet || null
      : null

    return {
      transferFrom,
      transferTo,
      fromAmount: undefined,
    }
  },

  handleSubmit: async (values, { props }) => {
    props.handleFormSubmit(values)
  },
  validate: (values) => {
    const errors: FormikErrors<TransferValues> = {}
    if (!values.transferFrom) {
      errors.transferFrom = t('Validation.Required')
    }

    if (isValidMinAmount(values)) {
      errors.fromAmount = t('Validation.Amount should be greater than 0.00')
    }

    if (isValidMaxAmount(values)) {
      if (isTransferFromWallet(values)) {
        errors.fromAmount = t('Validation.There is not enough balance in your wallet')
      }
      if (isTransferFromTA(values)) {
        errors.fromAmount = t('Validation.There is not enough balance in your trading account')
      }
    }

    if (!values.transferTo) {
      errors.transferTo = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(TransferFormETDUI)

const isTransferFromWallet = (values: TransferValues) => {
  return values.transferFrom?.displayType === TransferFromType.Wallet
}

const isTransferFromTA = (values: TransferValues) => {
  return values.transferFrom?.displayType === TransferFromType.TA
}

const isValidMinAmount = (values: TransferValues): boolean => {
  const amount = values?.fromAmount ?? 0
  return amount <= 0
}

const isValidMaxAmount = (values: TransferValues): boolean => {
  const maxAmount = getBalance(values)
  const amount = values?.fromAmount ?? 0
  return amount > maxAmount
}

const getBalance = (values: TransferValues) => {
  return values?.transferFrom?.balance ?? 0
}
