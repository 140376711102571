import React from 'react'

import {
  PersonalDetailsStep1Form,
  PersonalDetailsStep1FormValues,
} from './PersonalDetailsStep1Form'

import 'react-datepicker/dist/react-datepicker.css'
import { useSignup } from '../../../../../global/context/SignupContext'
import { SignupWarningMessages } from '../../../Components/SignupWarningMessages'
import { PersonalDetailsTemplate } from '../PersonalDetailsTemplate'

interface PersonalDetailsStep1Props {
  goBack(): void
  step: number
  totalSteps: number
  onSubmit(values: PersonalDetailsStep1FormValues): void
}

export const PersonalDetailsStep1Page: React.FC<PersonalDetailsStep1Props> = (props) => {
  const { goBack, step, totalSteps, onSubmit } = props
  const { signupData } = useSignup()

  return (
    <PersonalDetailsTemplate step={step} totalSteps={totalSteps} goBack={goBack}>
      <SignupWarningMessages />

      <PersonalDetailsStep1Form
        values={{
          middleName: signupData.values?.middleName,
          firstName: signupData.values?.firstName,
          lastName: signupData.values?.lastName,
          nativeName: signupData.values?.nativeName,
          genderId: signupData.values?.genderId,
          monthOfBirth: signupData.values?.monthOfBirth,
          dayOfBirth: signupData.values?.dayOfBirth,
          yearOfBirth: signupData.values?.yearOfBirth,
        }}
        onSubmit={onSubmit}
      />
    </PersonalDetailsTemplate>
  )
}
