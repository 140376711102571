import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import { useEntitySettings } from '../global/context/EntityContext'
import { Modal } from '../global/modal/Modal'
import { ToastContext, successToast } from '../global/toast/Toast'
import { AuthUser, LoginResponse, TwoFactor, TwoFactorProviders } from '../model/User'
import { ResponseError, useApiClient } from '../utils/ApiClient'
import { AuthSessionContext } from '../utils/AuthContext'
import { ClientApiClient } from '../utils/clientApi'
import { entityPerTickmillCompany } from '../utils/companyName.utils'
import { loginToAuth } from '../utils/loginToAuth'
import { TokenStorage } from '../utils/tokenStorage'
import { wait } from '../utils/wait'
import { RecoveryCodeModal } from './RecoveryCodeModal'
import { TrustModal } from './TrustModal'
import { Resend2FAError } from './TwoFactorAuthPage'

interface UseTwoFactorAuthReturn {
  twoFactor: TwoFactor | undefined
  resendCode: () => Promise<void>
  handlevVerifyCode: (code: string) => Promise<void>
  error: string | undefined
  status: 'success' | 'error' | undefined
  setRecoveryCodeModal: (val: boolean) => void
  disableResend: boolean
  renderTrustModal: () => JSX.Element
  renderRecoveryModal: () => JSX.Element
  initialCountdown: number
  resendError: Resend2FAError
  handleTimer: () => {
    startResetTimer: () => (() => void) | undefined
    startInitialTimer: () => () => void
  }
  stateChecker: () => {
    effect: () => void
    dependants: (TwoFactorAuthLocationState | NavigateFunction)[]
  }
}

export enum TwoFactorErrors {
  InvalidToke = 'two_factor_authentication_invalid_token',
  TooManyAttempts = 'two_factor_auth_too_many_resend_code_attempts',
  TooManyInputs = 'two_factor_auth_too_many_insert_code_attempts',
  TooManySends = 'two_factor_auth_too_many_insert_resend_code_attempts',
  TokenExpired = 'two_factor_authentication_expired_token',
}

export interface TwoFactorAuthLocationState {
  twoFactorAuthData?: LoginResponse
  email?: string
}

export const useTwoFactorAuth = (): UseTwoFactorAuthReturn => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [, setEntity] = useEntitySettings()
  const setToast = useContext(ToastContext)
  const apiClient = useApiClient(ClientApiClient)
  const [, setAuth] = useContext(AuthSessionContext)
  const locationState = location.state as TwoFactorAuthLocationState

  const [error, setError] = useState<string>()
  const [trustModal, setTrustModal] = useState(false)
  const [loginData, setLoginData] = useState<AuthUser>()
  const [twoFactor, setTwoFactor] = useState<TwoFactor>()
  const [disableResend, setDisableResend] = useState(false)
  const [initialCountdown, setInitialCountdown] = useState(30)
  const [recoveryCodeModal, setRecoveryCodeModal] = useState(false)
  const [status, setStatus] = useState<'success' | 'error' | undefined>()
  const [showAgain, setShowAgain] = useState(Cookies.get('showTrustAgain') !== 'false')
  const defaultResendErrorState = { hideResend: false, errorTimer: 0, tooManyInputs: false }
  const [resendError, setResendError] = useState<Resend2FAError>(defaultResendErrorState)

  const stateChecker = () => {
    const effect = () => {
      if (locationState?.twoFactorAuthData?.twoFactor?.length) {
        setTwoFactor(locationState?.twoFactorAuthData.twoFactor[0])
      }
    }

    const dependants = [locationState, navigate]

    return { effect, dependants }
  }

  const handleTimer = () => {
    const startResetTimer = () => {
      if (resendError.hideResend) {
        let secondsElapsed = 0
        const totalSeconds = 30 * 60
        const timer = setTimeout(() => {
          setResendError((prevState) => ({ ...prevState, hideResend: false }))
        }, totalSeconds * 1000)

        const interval = setInterval(() => {
          secondsElapsed += 1
          const remainingSeconds = totalSeconds - secondsElapsed
          setResendError((prevState) => ({ ...prevState, errorTimer: remainingSeconds }))
        }, 1000)

        return () => {
          clearTimeout(timer)
          clearInterval(interval)
        }
      }
    }

    const startInitialTimer = () => {
      const interval = setInterval(() => {
        setInitialCountdown((prevSeconds) => {
          if (prevSeconds <= 1) {
            clearInterval(interval)
            return 0
          }
          return prevSeconds - 1
        })
      }, 1000)

      return () => clearInterval(interval)
    }

    return { startResetTimer, startInitialTimer }
  }

  const resendCode = async () => {
    if (!twoFactor) {
      return
    }
    const typeId = twoFactor?.provider.id ?? TwoFactorProviders.Email
    const { token } = twoFactor
    try {
      setDisableResend(true)
      const twoFAData = await apiClient.resend2FACode({ typeId, token })
      setToast(successToast(t('Sign up.Your new code has been sent')))
      setInitialCountdown(30)
      if (twoFAData.length) {
        setTwoFactor(twoFAData[0])
      }
    } catch (e: unknown) {
      const error = e as ResponseError
      if (error instanceof ResponseError) {
        const { data: errorData } = error.response.response || {}
        if (
          errorData?.code === TwoFactorErrors.TooManyAttempts ||
          errorData?.code === TwoFactorErrors.TooManySends
        ) {
          const errorTimer = (Number(errorData.properties?.CooldownInMinutes) || 30) * 60
          setResendError({ errorTimer, hideResend: true, tooManyInputs: false })
        } else if (
          errorData?.code === TwoFactorErrors.InvalidToke ||
          errorData?.code === TwoFactorErrors.TokenExpired
        ) {
          navigate('/login')
        } else {
          return
        }
      }
    } finally {
      setDisableResend(false)
    }
  }

  const handlevVerifyCode = async (code: string) => {
    if (!twoFactor) {
      return
    }

    try {
      const { token, provider } = twoFactor
      const response = await apiClient.verify2FACode({ typeId: provider.id, code, token: token })
      const authData = loginToAuth(response)
      if (!authData) {
        return
      }

      setLoginData(authData)
      setStatus('success')
      await wait()
      showAgain ? setTrustModal(true) : handleLogin(authData)
    } catch (e: unknown) {
      const error = e as ResponseError
      if (error instanceof ResponseError) {
        const { data: errorData } = error.response.response || {}
        if (errorData?.code === TwoFactorErrors.TooManyInputs) {
          setError(undefined)
          setResendError({ errorTimer: 0, hideResend: false, tooManyInputs: true })
          navigate('/login/2fa/expired')
          throw error
        } else if (errorData?.code === TwoFactorErrors.TokenExpired) {
          navigate('/login')
        }
      }
      setStatus('error')
      setError(t('Validation.Wrong code Verification failed'))
      throw error
    }
  }

  const handleRecoveryCode = async (recoveryCode: string) => {
    const email =
      locationState?.twoFactorAuthData?.email ||
      locationState?.email ||
      localStorage.getItem('2fa.email')
    if (!email) {
      return
    }
    setRecoveryCodeModal(false)

    try {
      const response = await apiClient.verifyRecoveryCode({ email, recoveryCode })
      localStorage.removeItem('2fa.email')
      const authData = loginToAuth(response)
      if (!authData) {
        return
      }

      setLoginData(authData)
      handleLogin(authData)
    } catch (error: unknown) {
      return
    }
  }

  const handleTrustDevice = async () => {
    if (loginData) {
      await handleLogin(loginData)
    }
    const { token } = twoFactor || {}
    const { sessionId } = loginData || {}
    if (token && sessionId) {
      const { trustUserDeviceToken } = await apiClient.trustDevice2FA({ token, sessionId })
      TokenStorage.storeToken('trustDevice', trustUserDeviceToken)
    }
    if (loginData) {
      navigate('/dashboard')
    }
  }

  const handleLogin = async (authData: AuthUser) => {
    setAuth(authData)
    if (
      authData.tickmillCompany &&
      !window.env.APP_ENTITY.includes(entityPerTickmillCompany[authData.tickmillCompany.id]) &&
      process.env.NODE_ENV !== 'development'
    ) {
      navigate('/wrong-entity')
      return
    } else {
      localStorage.setItem('auth', JSON.stringify(authData))
      if (authData.accessToken) {
        TokenStorage.storeToken('access', authData.accessToken)
      }
      if (authData.refreshToken) {
        TokenStorage.storeToken('refresh', authData.refreshToken)
      }
      if (authData.sessionId) {
        TokenStorage.storeSession(authData.sessionId)
      }
      if (authData.tickmillCompany) {
        setEntity({ entity: authData.tickmillCompany.id })
      }
      navigate('/dashboard')
    }
  }

  const handleShowAgain = (val: boolean) => {
    Cookies.set('showTrustAgain', val.toString(), { expires: 30 })
    setShowAgain(val)
  }

  const renderTrustModal = () =>
    trustModal ? (
      <Modal
        closeModal={() => {
          setTrustModal(false)
          if (loginData) {
            handleLogin(loginData)
          }
          navigate('/dashboard')
        }}
        render={({ closeModal }) => (
          <TrustModal
            dontShowAgain={!showAgain}
            setShowAgain={(e) => handleShowAgain(!e.target.checked)}
            onConfirm={() => handleTrustDevice()}
            onCancel={closeModal}
          />
        )}
      />
    ) : (
      <></>
    )

  const renderRecoveryModal = () =>
    recoveryCodeModal ? (
      <Modal
        closeModal={() => setRecoveryCodeModal(false)}
        render={() => <RecoveryCodeModal onConfirm={handleRecoveryCode} />}
      />
    ) : (
      <></>
    )

  return {
    twoFactor,
    renderRecoveryModal,
    resendCode,
    handlevVerifyCode,
    error,
    status,
    setRecoveryCodeModal,
    disableResend,
    renderTrustModal,
    initialCountdown,
    resendError,
    handleTimer,
    stateChecker,
  }
}
