import React from 'react'

import {
  LegalDocumentDto,
  isLegalDocumentWidgetCheckbox,
  isLegalDocumentWidgetReadAndAgree,
  isLegalDocumentsWidgetCheckbox,
  isLegalDocumentsWidgetReadAndAgree,
} from '../../../../model/CompanyLegalDocumentDto'
import { LeadDto } from '../../../../model/LeadDto'
import { DocumentCheckBoxGroupField } from '../../Components/DocumentCheckBoxField'
import { AgreedLegalDocument, DocumentFieldItem } from '../../Components/DocumentReadAndAgreeField'
import { DocumentFieldFactory } from './DocumentItemFactory'

export interface FormValuesLegalDocs {
  lead?: LeadDto
  shouldReceiveNewsletter: boolean
  agreedLegalDocuments: AgreedLegalDocument[]
  documents?: { [key: string]: boolean }
  readDocument: LegalDocumentDto | undefined
  readDocumentMarketData: ReadDocumentMarketData
  marketDataClassificationId?: number
}

interface ReadDocumentMarketData {
  marketDataClassificationId: number
  isDocumentMarketDataRead: boolean
  isDocumentMarketDataAccepted: boolean
}

type GroupReference = string | null

interface PersonalDetailsDocumentsFactoryFactoryProps {
  groupReference: GroupReference
  documents: LegalDocumentDto[]
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

export const PersonalDetailsDocumentsFactory: React.FC<
  PersonalDetailsDocumentsFactoryFactoryProps
> = (props) => {
  const { groupReference, documents, renderItem } = props

  if (groupReference) {
    return (
      <DocumentGroupFieldFactory
        groupReference={groupReference}
        documents={documents}
        renderItem={renderItem}
      />
    )
  }

  return (
    <React.Fragment>
      {documents.map((x) => (
        <DocumentFieldFactory documents={[x]} renderItem={renderItem} key={x.id} />
      ))}
    </React.Fragment>
  )
}

interface DocumentGroupFieldFactoryProps {
  groupReference: string
  documents: LegalDocumentDto[]
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

const DocumentGroupFieldFactory: React.FC<DocumentGroupFieldFactoryProps> = (props) => {
  const { groupReference, documents, renderItem } = props

  if (isLegalDocumentsWidgetCheckbox(documents)) {
    return (
      <DocumentCheckBoxGroupField
        groupReference={groupReference}
        documents={documents}
        renderItem={renderItem}
      />
    )
  }

  if (isLegalDocumentsWidgetReadAndAgree(documents)) {
    return null
  }

  return null
}

export const isDocumentReadAndAgreeReferences = (
  groupReferences: GroupReference[],
  documents: LegalDocumentDto[]
): boolean => {
  const filteredDocuments = groupReferences.map((groupReference) =>
    documents
      .filter(isLegalDocumentWidgetReadAndAgree)
      .filter(filterDocumentByGroupReference(groupReference))
  )

  return filteredDocuments.flat().length >= 1
}

export const isDocumentCheckboxReferences = (
  groupReferences: GroupReference[],
  documents: LegalDocumentDto[]
): boolean => {
  const filteredDocuments = groupReferences.map((groupReference) =>
    documents
      .filter(isLegalDocumentWidgetCheckbox)
      .filter(filterDocumentByGroupReference(groupReference))
  )

  return filteredDocuments.flat().length >= 1
}

export const filterDocumentByGroupReference =
  (groupReference: GroupReference) =>
  (document: LegalDocumentDto): boolean =>
    groupReference === document?.groupReference
