import React, { FormEventHandler, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'

import { Loading } from '../../../../../global/Loading/Loading'
import { Button } from '../../../../../global/button/Button'
import { createFormField } from '../../../../../global/formField/FormField'
import { useCountdown } from '../../../../../hooks/useCountdown'
import { CheckCircledIcon } from '../../../../../icons/CheckCircledIcon'
import { FailureExpressionIcon } from '../../../../../icons/FailureExpressionIcon'
import { LeadDto } from '../../../../../model/LeadDto'
import { Text, TextH3, TextSmall } from '../../../../../ui/Typography/Typography'
import { instanceOfAxiosError, useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { preventPaste } from '../../../../../utils/input.utils'
import { useFetchOne } from '../../../../../utils/useFetch'
import { useScrollToTop } from '../../../../../utils/useScrollToTop'
import { isZero } from '../../../../../utils/validations'
import { UpdatePhoneForm } from './UpdatePhoneForm'

import styles from './PersonalDetailsStepMobileValidation.module.scss'

const FormField = createFormField<FormValuesStepMobileVerification>()

export interface FormValuesStepMobileVerification {
  phoneCode1st: string
  phoneCode2nd: string
  phoneCode3rd: string
  phoneCode4th: string
}

const fieldKeys: (keyof FormValuesStepMobileVerification)[] = [
  'phoneCode1st',
  'phoneCode2nd',
  'phoneCode3rd',
  'phoneCode4th',
]

export interface OuterProps {
  lead: LeadDto
  phoneVerificationId: string
  handleNextStep(): void
  resendCode(): void
  onReceivePhoneCall(): void
  refreshLead(): void
}

interface StepBackProps {
  event: {
    key: string
    preventDefault: () => void
  }
  name: string
  value: string
}

const usePhoneValidate = (props: FormikProps<FormValuesStepMobileVerification> & OuterProps) => {
  const { values, handleSubmit } = props

  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)

  const [validationError, setValidationError] = useState('')
  const [verificationCodeError, setVerificationCodeError] = useState('')
  const [verificationSuccess, setVerificationSuccess] = useState(false)

  const [disabled, setDisabled] = useState(false)
  const [borderClass, setBorderClass] = useState<string>('')

  const handleValidatePhoneCode = useCallback(
    async (code: string) => {
      try {
        await apiClient.validatePhoneCode(props.lead.id, props.phoneVerificationId, code)
        setVerificationSuccess(true)
        setBorderClass(styles.verificationBoxSuccess)
        setDisabled(true)
        setTimeout(() => {
          setBorderClass('')
          props.handleNextStep()
        }, 1000)
        setValidationError('')
      } catch (e: unknown) {
        if (!instanceOfAxiosError(e as Error)) {
          return
        }
        const error = e as AxiosError
        if (error.response?.data.code === 'phone_verification_max_attempts_reached') {
          setBorderClass(styles.verificationBoxError)
          setTimeout(() => {
            setBorderClass('')
          }, 1000)
          setValidationError(t('Sign up.Wrong code/Phone Verification failed'))
          setVerificationCodeError(error.response.data.code)
          setDisabled(true)
          setTimeout(() => {
            props.resetForm()
            handleSubmit()
          }, 3000)
        } else {
          props.resetForm()
          setValidationError(t('Sign up.Wrong code/Phone Verification failed'))
          setVerificationCodeError(error.response?.data.code)
        }
      }
    },
    [apiClient, handleSubmit, props, t]
  )

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout
    if (values.phoneCode1st && values.phoneCode2nd && values.phoneCode3rd && values.phoneCode4th) {
      setValidationError('')
      const { phoneCode1st, phoneCode2nd, phoneCode3rd, phoneCode4th } = values
      const code = phoneCode1st + phoneCode2nd + phoneCode3rd + phoneCode4th
      delayDebounceFn = setTimeout(() => handleValidatePhoneCode(code), 1000)
    }
    return () => clearTimeout(delayDebounceFn)
  }, [handleValidatePhoneCode, values])

  return {
    validationError,
    setValidationError,
    verificationCodeError,
    setVerificationCodeError,
    verificationSuccess,
    setVerificationSuccess,
    disabled,
    setDisabled,
    borderClass,
    setBorderClass,
  }
}

export const INITIAL_COUNTER_SECONDS = 15

const PersonalDetailsStepMobileValidationFormUI: React.FC<
  FormikProps<FormValuesStepMobileVerification> & OuterProps
> = (props) => {
  const { handleSubmit, refreshLead } = props

  useScrollToTop()

  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)

  const { counter, setCounter } = useCountdown(INITIAL_COUNTER_SECONDS)
  const { validationError, verificationCodeError, verificationSuccess, disabled, borderClass } =
    usePhoneValidate(props)

  const callback = useCallback(async () => apiClient.getCountries(), [apiClient])

  const { data: countries = [], isLoading } = useFetchOne(callback)

  const [editPhone, setEditPhone] = useState<boolean>(false)

  const handleUpdatePhone = async (phoneNumber: string, countryCode: string) => {
    await apiClient.updateLeadPhoneNumber(props.lead.id, phoneNumber, countryCode)
    refreshLead()
    setEditPhone(false)
  }

  const handleCancelUpdatePhone = () => {
    setEditPhone(false)
  }

  const handleSkip: FormEventHandler = (event) => {
    event.preventDefault()
    handleSubmit()
  }

  const handleResend = (event: React.FormEvent) => {
    event.preventDefault()
    setCounter(15)
    props.resendCode()
  }

  const handleReceivePhoneCall = (event: React.FormEvent) => {
    event.preventDefault()
    setCounter(15)
    props.onReceivePhoneCall()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    props.setFieldValue(name, value)
    let nextField: string | undefined
    if (name === 'phoneCode1st') {
      nextField = 'phoneCode2nd'
    } else if (name === 'phoneCode2nd') {
      nextField = 'phoneCode3rd'
    } else {
      nextField = 'phoneCode4th'
    }
    const next = document.getElementById(nextField)
    if (next && !!value) {
      next.focus()
    }
  }

  const handleStepBack = (stepBackProps: StepBackProps) => {
    const { event, name, value } = stepBackProps

    event.preventDefault()
    let previousField: string | undefined
    if (name === 'phoneCode2nd') {
      previousField = 'phoneCode1st'
    } else if (name === 'phoneCode3rd') {
      previousField = 'phoneCode2nd'
    } else if (name === 'phoneCode4th') {
      previousField = 'phoneCode3rd'
    }
    if (previousField) {
      const previous = document.getElementById(previousField)
      if (previous && !value) {
        props.setFieldValue(previousField, '')
        previous?.focus()
      }
    }
  }

  if (editPhone) {
    return (
      <Loading showLoadingIcon isLoading={isLoading}>
        <UpdatePhoneForm
          countries={countries}
          onSubmit={handleUpdatePhone}
          onCancel={handleCancelUpdatePhone}
          countryCode={props.lead.countryCode}
        />
      </Loading>
    )
  }

  return (
    <React.Fragment>
      <Form className={styles.mobileVerificationWrapper} autoComplete='off' onSubmit={handleSkip}>
        <div>
          <TextH3>{t('Sign up.Enter 4-digit code')}</TextH3>
          {verificationSuccess && <CheckCircledIcon circleColor={'success'} checkColor={'white'} />}
          {isVerificationCodeError(verificationCodeError) && <FailureExpressionIcon />}
        </div>
        <Text isParagraph>
          {t('Sign up.Code was sent', {
            phoneNumber: `${props.lead.countryCode || ''} ${props.lead.phoneNumber || ''}`,
          })}{' '}
          <Button onClick={() => setEditPhone(true)} appearance='link'>
            {t('change')}
          </Button>
        </Text>
        <div className={styles.codeBox}>
          <div className={styles.codeField}>
            {fieldKeys.map((fieldKey: keyof FormValuesStepMobileVerification) => (
              <span className={styles.fieldBlock}>
                <FormField
                  id={fieldKey}
                  name={fieldKey}
                  type='text'
                  value={props.values[fieldKey] ? '*' : ''}
                  disabled={disabled}
                  fullWidth
                  onPaste={preventPaste}
                  onChange={handleChange}
                  onFocus={(event) => event.currentTarget.select()}
                  onKeyDown={(event) => {
                    const value = props.values[fieldKey] ? '*' : ''
                    if (event.key === 'Backspace' && !value) {
                      handleStepBack({ event, name: fieldKey, value })
                    }
                    if (['e', 'E', '+', '-'].includes(event.key)) {
                      return event.preventDefault()
                    }
                  }}
                  className={`${styles.verificationBox} ${borderClass}`}
                />
              </span>
            ))}
          </div>
          {!!validationError && <div className={styles.validationError}>{validationError}</div>}
        </div>
        {isZero(counter) ? (
          <React.Fragment>
            <Text className={styles.text}>
              {t('Sign up.Not get code')}{' '}
              <Button onClick={handleResend} appearance='link'>
                {t('Sign up.Resend')}
              </Button>
            </Text>

            <Button onClick={handleReceivePhoneCall} appearance='link'>
              {t('Sign up.Receive phone call')}
            </Button>
          </React.Fragment>
        ) : (
          <TextSmall>
            {t('Sign up.Please allow at least 15 seconds for code to reach your phone...', {
              counter: `0:${String(counter).padStart(2, '0')}`,
            })}
          </TextSmall>
        )}

        <div className={styles.skipButtonBox}>
          <Button fullWidth type='submit' size='L' appearance='secondary' disabled={counter > 0}>
            {t('Skip')}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  )
}

const isVerificationCodeError = (verificationCodeError?: string) => {
  return (
    verificationCodeError === 'phone_verification_max_attempts_reached' ||
    verificationCodeError === 'max_new_verification_code_requests_reached'
  )
}

export const PersonalDetailsStepMobileValidationForm = withFormik<
  OuterProps,
  FormValuesStepMobileVerification
>({
  mapPropsToValues: () => {
    return {
      phoneCode4th: '',
      phoneCode1st: '',
      phoneCode3rd: '',
      phoneCode2nd: '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.handleNextStep()
    } finally {
      setSubmitting(false)
    }
  },
  validate: () => {
    const errors: FormikErrors<FormValuesStepMobileVerification> = {}
    return errors
  },
})(PersonalDetailsStepMobileValidationFormUI)
