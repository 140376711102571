import React from 'react'

import IconButton from '../../../global/iconButton/IconButton'
import { DotsIcon } from '../../../icons/DotsIcon'
import { AssignedCommissionPlan } from '../../../model/MultiTierDto'
import { TextSmall, TextSmallStrong } from '../../../ui/Typography/Typography'

import styles from './MultiTierCards.module.scss'

interface MultiTierCommisionCardProps {
  data: AssignedCommissionPlan

  setActionsModal: React.Dispatch<React.SetStateAction<AssignedCommissionPlan | undefined>>
}

export const MultiTierCommisionCard: React.FC<MultiTierCommisionCardProps> = ({
  data,
  setActionsModal,
}) => {
  return (
    <div className={styles.card}>
      <header className={styles.cardHeader}>
        <TextSmallStrong className={styles.cardTitle}>{data.name}</TextSmallStrong>

        {!data.isDefault && (
          <IconButton onClick={() => setActionsModal(data)}>
            <DotsIcon />
          </IconButton>
        )}
      </header>
      {data.commissionPlans.map((plan) => (
        <TextSmall isParagraph className={styles.title}>
          <span className={styles.label}>{plan.tradingAccountType.name}</span>
          <TextSmallStrong className={styles.value}>{plan.amount}</TextSmallStrong>
        </TextSmall>
      ))}
    </div>
  )
}
