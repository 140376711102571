import { getIn, isObject } from 'formik'

import { isZero } from '../../utils/validations'
import { TypedFieldProps } from '../types/TypedFieldProps'

export const getName = <FormValues>(prefix: string, props: TypedFieldProps<FormValues>): string => {
  return `${prefix || ''}${props.name}`
}

export const getLabel = <FormValues>(props: TypedFieldProps<FormValues>): string => {
  if (!props.label) {
    return ''
  }

  return `${props.label || ''}${props.required ? ' *' : ''}`
}

export const getPlaceholder = <FormValues>(
  props: TypedFieldProps<FormValues>,
  value?: string | number
): string | undefined => {
  const placeholder = !value ? props.placeholder : undefined
  const required = props.required ? ' *' : ''
  if (placeholder) {
    return `${placeholder || ''}${required}`
  }
  return undefined
}

export const getError = <FormValues>(
  errors: TypedFieldProps<FormValues>,
  error: string | undefined,
  name: string
): boolean => {
  const fieldError = getIn(errors, name)

  return error || fieldError || ''
}

export const getFieldValid = <FormValues>(
  error: FormValues,
  touched: FormValues,
  value?: FormValues
): boolean => {
  return !(!!error && (touched || value) && !isObject(error) && !isObject(touched))
}

interface isFocusProps {
  value?: string | number
  label: string | undefined
  renderValue?: React.ReactNode
}

export const isFocus = ({ value, renderValue, label }: isFocusProps): boolean => {
  if (isZero(value)) {
    return true
  }
  return (!!value || !!renderValue) && !!label
}

export const getValue = <FormValues>(values: FormValues, value: string, name?: string): string => {
  if (value) {
    return value
  }
  if (name) {
    return getIn(values, name) || ''
  }
  return ''
}
