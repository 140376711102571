import React, { FormEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { Loading } from '../../../../global/Loading/Loading'
import { Button } from '../../../../global/button/Button'
import { useSessionEntity } from '../../../../global/context/EntityContext'
import { DocumentFileUploads } from '../../../../global/fileUpload/DocumentFileUploads'
import { FileData } from '../../../../global/fileUpload/FileUpload'
import { removeUploadedFile } from '../../../../global/fileUpload/FileUploads'
import { createFormField } from '../../../../global/formField/FormField'
import { ConfirmationModal } from '../../../../global/modal/ConfirmationModal'
import { Modal } from '../../../../global/modal/Modal'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import { CreateClientDocument } from '../../../../model/CreateClientDocuments'
import { DocumentCategoryType } from '../../../../model/DocumentCategories'
import { DocumentCategoryDto } from '../../../../model/DocumentDto'
import { Text, TextH3 } from '../../../../ui/Typography/Typography'
import { TickmillCompaniesEnum } from '../../../../utils/companyName.utils'
import { useScrollToTop } from '../../../../utils/useScrollToTop'
import { DocumentTypeModal } from './DocumentTypeModal'

import styles from './DocumentsInfoPage.module.scss'

export interface DocumentInfoPageProofOfAddressStepFormValues {
  documentType: { id: string; name: string }
  documentTypes?: DocumentCategoryDto
  files: FileData[]
  documents: DocumentManagementDocument[]
}

interface DocumentManagementDocument {
  categoryId: number
  typeId: number
  file: string
  filename: string
  error: boolean
}

const FormField = createFormField<DocumentInfoPageProofOfAddressStepFormValues>()

const DocumentInfoPageProofOfAddressStepFormUI: React.FC<
  FormikProps<DocumentInfoPageProofOfAddressStepFormValues> & OuterProps
> = (props) => {
  useScrollToTop()
  const { documentTypes, onSkip, handleSubmit, isSubmitting, onSkipDocuments } = props
  const { t } = useTranslation()

  const handleSubmitForm: FormEventHandler = (event) => {
    event.preventDefault()
    handleSubmit()
  }

  return (
    <Form onSubmit={handleSubmitForm}>
      {!isSubmitting ? (
        <FormFieldsFactory
          documentTypes={documentTypes}
          onSkipDocuments={onSkipDocuments}
          onCancel={onSkip}
        />
      ) : (
        <Loading
          isLoading={isSubmitting}
          showLoadingIcon
          text={t('Sign up.Submitting Proof of Address...')}
        />
      )}
    </Form>
  )
}

interface FormFieldsFactoryProps {
  documentTypes?: DocumentCategoryDto

  onSkipDocuments(): void
  onCancel(): void
}

const FormFieldsFactory: React.FC<FormFieldsFactoryProps> = (props) => {
  const { onSkipDocuments } = props
  const { values, isValid, setValues } =
    useFormikContext<DocumentInfoPageProofOfAddressStepFormValues>()

  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false)
  const [isFileConfirmationModalOpen, setFileConfirmationModalOpen] = useState(false)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const { t } = useTranslation()
  const [file, setFile] = useState<FileData>()

  const entity = useSessionEntity()

  const handleDocumentModalOpen = () => {
    setDocumentModalOpen(true)
  }

  const handleDocumentModalClose = () => {
    setDocumentModalOpen(false)
  }

  useEffect(() => {
    setValues({
      ...values,
      documents: [],
      files: [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.documentType])

  const handleDocumentUploads = (filesData: FileData[]) => {
    setValues({
      ...values,
      files: filesData,
      documents: filesData.map((x) => ({
        ...x,
        categoryId: DocumentCategoryType.Address,
        typeId: Number.parseInt(values.documentType.id, 10),
        filename: x.fileName,
        file: x.base64Content,
      })),
    })
  }

  const handleDocumentBeforeRemove = (file: FileData) => {
    setFile(file)
    setFileConfirmationModalOpen(true)
  }

  const handleDocumentRemove = () => {
    const files = removeUploadedFile(values.files, file)
    setValues({
      ...values,
      files,
      documents: files.map((x) => ({
        ...x,
        categoryId: DocumentCategoryType.Address,
        typeId: Number.parseInt(values.documentType.id, 10),
        filename: x.fileName,
        file: x.base64Content,
      })),
    })
    setFileConfirmationModalOpen(false)
  }

  const handleFileConfirmationModalClose = () => {
    setFileConfirmationModalOpen(false)
  }

  const handleConfirmationModalOpen = () => {
    setConfirmationModalOpen(true)
  }

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const getEntityInfoText = () => {
    if (entity === TickmillCompaniesEnum.TICKMILL_UK) {
      return t('Profile.Proof of address must be no more than', {
        length: 90,
      })
    } else if (entity === TickmillCompaniesEnum.TICKMILL_EU) {
      return t('Profile.Proof of address must be no more than', {
        length: 180,
      })
    }

    return t('Profile.Proof of address must be no more than', {
      length: 180,
    })
  }

  return (
    <React.Fragment>
      {isFileConfirmationModalOpen && (
        <Modal
          closeModal={handleFileConfirmationModalClose}
          cardClassName={styles.modal}
          render={() => (
            <ConfirmationModal
              title={t('Sign up.Remove Document')}
              renderFooter={() => (
                <React.Fragment>
                  <button
                    className='button'
                    onClick={handleFileConfirmationModalClose}
                    type='button'
                  >
                    {t('Cancel')}{' '}
                  </button>
                  <button className='button' onClick={handleDocumentRemove} type='button'>
                    {t('Yes')}
                  </button>
                </React.Fragment>
              )}
              onConfirm={handleDocumentRemove}
              onCancel={handleFileConfirmationModalClose}
            >
              <p>{t('Sign up.Do you really want to delete this document?')}</p>
            </ConfirmationModal>
          )}
        />
      )}
      {isDocumentModalOpen && (
        <DocumentTypeModal
          onSelectOption={handleDocumentModalClose}
          onClose={handleDocumentModalClose}
        />
      )}
      {isConfirmationModalOpen && (
        <Modal
          closeModal={handleConfirmationModalClose}
          cardClassName={styles.modal}
          render={() => (
            <ConfirmationModal
              title={t('Sign up.Skip document upload?')}
              renderFooter={() => (
                <React.Fragment>
                  <button className='button' onClick={handleConfirmationModalClose} type='button'>
                    {t('Sign up.Back to Upload')}
                  </button>
                  <button className='button' onClick={onSkipDocuments} type='button'>
                    {t('Sign up.Skip upload')}
                  </button>
                </React.Fragment>
              )}
              onConfirm={handleConfirmationModalOpen}
              onCancel={handleConfirmationModalClose}
            >
              <p>
                {t(
                  'Sign up.You can skip document upload, but uploading documents is required step to become Tickmill customer.'
                )}
              </p>
            </ConfirmationModal>
          )}
        />
      )}
      <TextH3>{t('Sign up.Proof Of Address')} * </TextH3>
      <Text isParagraph className='mb-5'>
        {getEntityInfoText()}
      </Text>

      <FormField
        placeholder={t('Sign up.Document Type')}
        label={t('Sign up.Document Type')}
        value={values.documentType.name}
        name='documentType.name'
        rightIcon={<DropArrowDownIcon />}
        required
        onClick={handleDocumentModalOpen}
      />

      {values.documentType.id && (
        <div className='pb-3'>
          <DocumentFileUploads
            documentCategory={DocumentCategoryType.Address}
            files={values.files}
            onChange={handleDocumentUploads}
            onBeforeRemove={handleDocumentBeforeRemove}
          />
        </div>
      )}
      <div className='is-flex is-justify-content-center'>
        <Button
          onClick={handleConfirmationModalOpen}
          className={styles.button}
          type='button'
          fullWidth
          appearance='secondary'
          size='L'
        >
          {t('Skip')}
        </Button>
        <Button
          type='submit'
          appearance='primary'
          fullWidth
          size='L'
          className={styles.button}
          disabled={!isValid}
        >
          {t('Submit')}
        </Button>
      </div>
    </React.Fragment>
  )
}

interface OuterProps {
  documentTypes?: DocumentCategoryDto

  onSkip(): void
  onSkipDocuments(): void

  handleSubmit(documents: CreateClientDocument[]): Promise<void>
}

export const DocumentInfoPageProofOfAddressStepForm = withFormik<
  OuterProps,
  DocumentInfoPageProofOfAddressStepFormValues
>({
  mapPropsToValues: (props) => {
    return {
      files: [],
      documentType: { id: '', name: '' },
      documentTypes: props.documentTypes,
      documents: [],
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.handleSubmit(values.documents)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<DocumentInfoPageProofOfAddressStepFormValues> = {}

    const documents = values.documents.filter((x) => !!x?.filename).filter((x) => !x.error)
    if (documents?.length <= 0) {
      errors.documents = t('Validation.Required')
    }

    return errors
  },
  enableReinitialize: true,
  validateOnMount: true,
})(DocumentInfoPageProofOfAddressStepFormUI)
