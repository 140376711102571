import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TextField } from '../../../../../global/field/TextField'
import { Modal } from '../../../../../global/modal/Modal'
import { isZero } from '../../../../../utils/validations'

import styles from '../DocumentsInfoPage.module.scss'

interface DocumentReasonModalProps {
  onSubmit(reason: string): void
  onCancel(): void
  documentType?: string
}

export const DocumentReasonModal: React.FC<DocumentReasonModalProps> = ({
  onSubmit,
  onCancel,
  documentType,
}) => {
  const { t } = useTranslation()
  const [reason, setReason] = useState<string | undefined>()
  const isError = isZero(reason?.trim()?.length)
  return (
    <Modal
      closeModal={onCancel}
      cardClassName={styles.modal}
      render={({ closeModal }) => (
        <React.Fragment>
          <header className='modal-card-head'>
            <p className='modal-card-title'>{t('Sign up.Tell Us The Reason?')}</p>
          </header>
          <div className={classNames('modal-card-body', styles.body)}>
            <p className='mb-4'>
              {t("Sign up.Please tell us the reason why you can't provide us", { documentType })}
            </p>
            <div className='field'>
              <div className={classNames('control')}>
                <TextField
                  error={isError ? t('Validation.Required') : undefined}
                  type='text'
                  onChange={(e) => setReason(e)}
                  value={reason}
                  label={t('Sign up.Reason')}
                />
              </div>
            </div>
          </div>
          <footer className='modal-card-foot'>
            <button className='button' onClick={closeModal} type='button'>
              {t('Cancel')}
            </button>
            <button
              className='button'
              type='button'
              onClick={() => {
                if (reason && !isError) {
                  onSubmit(reason as string)
                } else {
                  setReason('')
                }
              }}
            >
              {t('Submit')}
            </button>
          </footer>
        </React.Fragment>
      )}
    />
  )
}
