import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { CreateDemoTradingAccountFormValues } from '../../Traders-Room/Trading-Accounts/CreateDemoTradingAccount/CreateDemoTradingAccountForm'
import { SelectModal, SelectModalOption } from '../../global/field/SelectModal'
import { createFormField } from '../../global/formField/FormField'
import { Modal } from '../../global/modal/Modal'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { ClientAreaLanguageDto } from '../../model/ClientAreaLanguageDto'
import { Text } from '../../ui/Typography/Typography'
import { FormSubmitValues } from '../../utils/formValidation'

import styles from './AccountSettingsPage.module.scss'

const FormField = createFormField<CommunicationLanguageFormValues>()

export interface CommunicationLanguageFormValues {
  communicationLanguageId: string
}

const CommunicationLanguageFormUI: React.FC<
  FormikProps<CommunicationLanguageFormValues> & OuterProps
> = (props) => {
  const { languages, values, setValues, handleSubmit, setSubmitting } = props
  const { t } = useTranslation()

  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false)

  const handleChangeLanguage = (data: ClientAreaLanguageDto) => {
    setSubmitting(true)
    setValues({ communicationLanguageId: data.id })
    handleSubmit()
  }

  return (
    <>
      <Text>{t('Profile.Choose the main language to communicate with support')}</Text>
      {isLanguageModalOpen && (
        <SelectLanguageModal
          languages={languages}
          onSelectOption={handleChangeLanguage}
          onClose={() => setLanguageModalOpen(false)}
        />
      )}
      <Form className={styles.form}>
        <FormField
          name='communicationLanguageId'
          label={t('Communication language')}
          placeholder={t('Communication language')}
          value={languages.find((x) => x.id === values.communicationLanguageId)?.name}
          rightIcon={<DropArrowDownIcon />}
          onClick={() => setLanguageModalOpen(true)}
        />
      </Form>
    </>
  )
}

interface SelectLanguageModalProps {
  languages: ClientAreaLanguageDto[]
  onSelectOption(data: ClientAreaLanguageDto): void
  onClose(): void
}

const SelectLanguageModal: React.FC<SelectLanguageModalProps> = (props) => {
  const { onSelectOption, onClose, languages } = props

  const { t } = useTranslation()
  const { values } = useFormikContext<CreateDemoTradingAccountFormValues>()

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Communication language')}
          renderOptions={() => (
            <div className='control'>
              {languages.map((x) => (
                <SelectModalOption
                  label={x.name}
                  value={x.id.toString() === values.classificationType?.id.toString()}
                  onClick={() => onSelectOption(x)}
                  key={x.id}
                />
              ))}
            </div>
          )}
        />
      )}
    />
  )
}

interface OuterProps {
  communicationLanguageId?: string
  languages: ClientAreaLanguageDto[]
  onSubmit(values: FormSubmitValues<CommunicationLanguageFormValues>): Promise<void>
}

export const CommunicationLanguageForm = withFormik<OuterProps, CommunicationLanguageFormValues>({
  mapPropsToValues: ({ communicationLanguageId }) => {
    return {
      communicationLanguageId: communicationLanguageId || '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<CommunicationLanguageFormValues> = {}
    if (!values.communicationLanguageId) {
      errors.communicationLanguageId = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
})(CommunicationLanguageFormUI)
