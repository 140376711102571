import React, { useContext } from 'react'
import { t } from 'i18next'

import { Loading } from '../../global/Loading/Loading'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { Table } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { CorporateActionDto } from '../../model/CorporateActionDto'
import { CurrencyType } from '../../model/WalletDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'

import styles from './StockDividendsPage.module.scss'

interface Props {
  data: CorporateActionDto[]
  isLoading: boolean
}

export const StockDividendsTable: React.FC<Props> = ({ data, isLoading }) => {
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const isArabic = useArabicSessionLanguage()
  const { formatMoney } = useFormatNumber()

  return (
    <Table>
      <thead>
        <tr>
          <th>{t('Stock Dividends.Name')}</th>
          <th>{t('Stock Dividends.Symbol')}</th>
          <th align='center'>{t('Stock Dividends.Dividend Ex Date')}</th>
          <th align='center'>{t('Stock Dividends.Gross Dividend Amount')}</th>
        </tr>
      </thead>
      <tbody id='body'>
        {!data.length ? (
          <td align='center' colSpan={6}>
            {t('No results')}
          </td>
        ) : (
          data.map((corporateAction) => {
            return (
              <React.Fragment key={corporateAction.id}>
                <tr>
                  <td>{corporateAction.instrument.name}</td>
                  <td>
                    <div className={styles.symbolTag}>{corporateAction.instrument.symbol}</div>
                  </td>
                  <td align={isArabic ? 'right' : 'center'}>
                    {corporateAction.date && formatDate(corporateAction.date, dateFormat)}
                  </td>
                  <td align={isArabic ? 'right' : 'center'}>
                    {formatMoney(
                      corporateAction.corporateActionDividend?.rate ?? 0,
                      corporateAction.corporateActionDividend.currencyId ?? ('USD' as CurrencyType)
                    )}
                  </td>
                </tr>
              </React.Fragment>
            )
          })
        )}
      </tbody>
    </Table>
  )
}
