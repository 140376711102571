import React, {
  ChangeEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import { Field, Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { getReferralCookies } from '../../../../../Referral/helpers'
import { Loading } from '../../../../../global/Loading/Loading'
import { Button } from '../../../../../global/button/Button'
import { CheckBox } from '../../../../../global/checkBox/CheckBox'
import { useEntitySettings } from '../../../../../global/context/EntityContext'
import { useArabicSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { SignupFormValues } from '../../../../../global/context/SignupContext'
import { createFormCheckBoxField } from '../../../../../global/formField/FormCheckBoxField'
import { createFormField } from '../../../../../global/formField/FormField'
import IconButton from '../../../../../global/iconButton/IconButton'
import { BackIcon } from '../../../../../icons/BackIcon'
import { CheckIcon } from '../../../../../icons/CheckIcon'
import { CloseIcon } from '../../../../../icons/CloseIcon'
import { DropArrowDownIcon } from '../../../../../icons/DropArrowDownIcon'
import { ForwardIcon } from '../../../../../icons/ForwardIcon'
import { InfoIcon } from '../../../../../icons/InfoIcon'
import { WarningIcon } from '../../../../../icons/WarningIcon'
import { LegalDocumentDto } from '../../../../../model/CompanyLegalDocumentDto'
import { LeadMembersEnum } from '../../../../../model/CreateLead'
import { NameDto } from '../../../../../model/NameDto'
import {
  RegulatorDto,
  RegulatorOptionDto,
  RegulatorOptionItemDto,
  RegulatorProductOption,
  isRegulatorOptionErrorType,
  isRegulatorOptionSuccessType,
} from '../../../../../model/RegulatorOptionDto'
import { TickmillProductType } from '../../../../../model/TickmillProductType'
import { Text, TextH3, TextSmall, TextTiny } from '../../../../../ui/Typography/Typography'
import {
  TickmillCompaniesEnum,
  isTickmillEUType,
  isTickmillPartnerType,
  isTickmillSCType,
  isTickmillUKType,
} from '../../../../../utils/companyName.utils'
import { minTwoDigits } from '../../../../../utils/validations'
import { wait } from '../../../../../utils/wait'
import {
  AgreedLegalDocument,
  DocumentFieldItem,
  ReadDocumentPage,
} from '../../../Components/DocumentReadAndAgreeField'
import { useProducts } from '../../../hooks/useProducts'
import { PersonalDetailsTemplate } from '../PersonalDetailsTemplate'
import { DocumentGroupsFactory } from './DocumentGroupsFactory'
import {
  CountryOfResidenceModal,
  EntityInfoModal,
  ProductInfoModal,
  RegulatorInfoModal,
  RegulatorPopUpModal,
} from './PersonalDetailsModal'

import styles from '../PersonalDetailsPage.module.scss'

export interface PersonalDetailsStep2FormValues {
  countryId: string
  tickmillCompanyId: number
  productIds: number[]
  optIn: boolean
  readDocument?: LegalDocumentDto | undefined
  documents?: { [key: string]: boolean }
  agreedLegalDocuments?: AgreedLegalDocument[]
}

type GroupReference = string | null

interface OuterProps {
  step: number
  totalSteps: number

  countryId?: string
  tickmillCompanyId?: TickmillCompaniesEnum

  regulatorSelected: RegulatorDto | undefined
  regulatorOptions: RegulatorOptionDto[]
  groupReferences: GroupReference[]
  passedDocuments: LegalDocumentDto[]

  formValues?: SignupFormValues

  goBack(): void

  onSubmit(values: PersonalDetailsStep2FormValues): void

  onCountrySelectedChange(regulator: string | undefined): void

  onRegulatorSelectedChange(regulator: RegulatorDto | undefined): void
}

interface RegulatorInfoModalProps {
  regulator?: RegulatorDto
  visible: boolean
}

const FormField = createFormField<PersonalDetailsStep2FormValues>()
const FormCheckBoxField = createFormCheckBoxField<PersonalDetailsStep2FormValues>()

const PersonalDetailsStep2FormUI: React.FC<
  FormikProps<PersonalDetailsStep2FormValues> & OuterProps
> = (props) => {
  const { values, isSubmitting, isValid, handleSubmit, setFieldValue, errors, touched } = props
  const { regulatorSelected, regulatorOptions, groupReferences, passedDocuments } = props

  const { t } = useTranslation()
  const [entity] = useEntitySettings()
  const UKPolicyRef = useRef<HTMLDivElement>(null)

  const goBackFromDoc = async () => {
    setFieldValue('readDocument', undefined)
    wait(300).then(() => {
      if (UKPolicyRef.current) {
        UKPolicyRef.current.scrollIntoView()
      } else {
        wait(200).then(() => {
          if (UKPolicyRef.current) {
            UKPolicyRef.current.scrollIntoView()
          }
        })
      }
    })
  }

  const [regulatorInfoModal, setRegulatorInfoModal] = useState<RegulatorInfoModalProps>()
  const [regulatorPopupModal, setRegulatorPopupModal] = useState<RegulatorInfoModalProps>()

  const [isEntityInfoModalOpen, setEntityInfoModalOpen] = useState(false)
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false)
  const { setSelectedProductIds } = useProducts()
  const [isSelectedProductInfo, setSelectedProductInfo] = useState<{
    product?: RegulatorProductOption
    visible: boolean
  }>()

  const regulator = useRegulator(props, setSelectedProductIds)
  const redirection = useRedirection(props)

  useValidationEffect(props)
  useCompanyChangeEffect(props, regulator)
  const { countryName } = useSelectCountryEffect(props)

  const handleRegulatorSelected = (data: RegulatorDto | undefined) => {
    regulator.handleRegulatorSelected(data)
  }

  const handleChangeProductIds = (checked: boolean, productId: TickmillProductType) => {
    const productIds = checked
      ? [...values.productIds, productId]
      : values.productIds.filter((p) => p !== productId)
    setFieldValue('productIds', productIds)
    setSelectedProductIds(productIds)
  }

  const handleCountryChangeOption = (country: NameDto<string>) => {
    handleRegulatorSelected(undefined)
    setFieldValue('countryId', country.id)
    setFieldValue('tickmillCompanyId', undefined)
    resetPrivacyPolicy()
    handleCountryModalClose()
    props.onCountrySelectedChange(country.id)
    setFieldValue('agreedLegalDocuments', [])
  }

  const handleRegulatorChange = (regulator: RegulatorDto) => {
    if (regulator.redirect) {
      handleRegulatorSelected(regulator)
      if (regulator.popup) {
        setRegulatorPopupModal({
          regulator,
          visible: true,
        })
      } else {
        redirection.redirect(regulator)
      }
    } else {
      handleRegulatorSelected(regulator)
      setFieldValue('tickmillCompanyId', regulator.tickmillCompanyId)
      setFieldValue('agreedLegalDocuments', [])
      resetPrivacyPolicy()
    }
  }

  const handleRegulatorInfoModalOpen = (regulator: RegulatorDto) => {
    setRegulatorInfoModal({
      regulator,
      visible: true,
    })
  }

  const handleRegulatorPopUpModalSubmit = () => {
    if (regulatorSelected?.redirect) {
      redirection.redirect(regulatorSelected)
      setRegulatorInfoModal({
        regulator: undefined,
        visible: false,
      })
    } else {
      regulator.handleRegulatorSelected(regulatorPopupModal?.regulator)
      setFieldValue('tickmillCompanyId', regulatorPopupModal?.regulator?.tickmillCompanyId)
      handleRegulatorPopUpModalClose()
    }
  }

  const handleRegulatorPopUpModalClose = () => {
    setRegulatorInfoModal({
      regulator: undefined,
      visible: false,
    })
  }

  const handleRegulatorInfoModalClose = () => {
    setRegulatorInfoModal({
      regulator: undefined,
      visible: false,
    })
  }

  const handleProductModalOpen = (product: RegulatorProductOption) => {
    return setSelectedProductInfo({ visible: true, product })
  }

  const handleProductModalClose = () => {
    return setSelectedProductInfo({ visible: false, product: undefined })
  }

  const handleCountryModalOpen = () => {
    setIsCountryModalOpen(true)
  }

  const handleCountryModalClose = () => {
    setIsCountryModalOpen(false)
  }

  const handleEntityInfoModalClose = () => {
    setEntityInfoModalOpen(false)
  }

  const resetPrivacyPolicy = () => {
    setFieldValue('documents', {})
    setFieldValue('optIn', false)
  }

  const hasProductIdsError = errors.productIds && touched.productIds

  const UKPrivacyPolicy = useMemo(
    () =>
      (values.tickmillCompanyId || regulatorSelected?.tickmillCompanyId) ===
      TickmillCompaniesEnum.TICKMILL_UK
        ? passedDocuments.find((d) => d.code === 'privacy_policy')
        : undefined,
    [passedDocuments, regulatorSelected?.tickmillCompanyId, values.tickmillCompanyId]
  )

  const regularDocuments = useMemo(
    () => passedDocuments.filter(({ id }) => id !== UKPrivacyPolicy?.id),
    [UKPrivacyPolicy, passedDocuments.map((x) => x.id)]
  )

  if (values?.readDocument) {
    return <ReadDocumentPage goBackFromDoc={goBackFromDoc} />
  }

  return (
    <>
      {isCountryModalOpen && (
        <CountryOfResidenceModal
          regulators={regulatorOptions}
          selectedCountry={values.countryId}
          onChangeOption={handleCountryChangeOption}
          closeModal={handleCountryModalClose}
        />
      )}
      {isSelectedProductInfo?.product && isSelectedProductInfo.visible && (
        <ProductInfoModal
          product={isSelectedProductInfo.product}
          closeModal={handleProductModalClose}
        />
      )}
      {regulatorInfoModal?.visible && regulatorInfoModal.regulator && (
        <RegulatorInfoModal
          regulator={regulatorInfoModal.regulator}
          visible={regulatorInfoModal.visible}
          closeModal={handleRegulatorInfoModalClose}
        />
      )}
      {regulatorPopupModal?.visible && regulatorSelected?.popup && (
        <RegulatorPopUpModal
          regulator={regulatorPopupModal.regulator}
          visible={regulatorPopupModal.visible}
          onSubmit={handleRegulatorPopUpModalSubmit}
          closeModal={handleRegulatorPopUpModalClose}
        />
      )}
      {isEntityInfoModalOpen && <EntityInfoModal closeModal={handleEntityInfoModalClose} />}
      <PersonalInfoStep2Template {...props}>
        <Form className={styles.form} onSubmit={handleSubmit}>
          <TextH3>{t('Sign up.Country of Residence')}</TextH3>
          <IconButton onClick={handleCountryModalOpen} fullWidth>
            <FormField
              name='countryId'
              label={t('Sign up.Country')}
              placeholder={t('Sign up.Country')}
              value={countryName}
              rightIcon={<DropArrowDownIcon />}
              readOnly
              required
              fullWidth
            />
          </IconButton>
          {!isTickmillPartnerType(entity.entity) && (
            <React.Fragment>
              <TextH3>{t('Sign up.Legal Entity')}</TextH3>
              <Text isParagraph>
                {t(
                  'Sign up.Based on your country of residence, you can register under the following entities'
                )}
                .
              </Text>
              {regulator.regulators.map((regulator, index) => (
                <RegulatorField
                  regulator={regulator}
                  onChange={handleRegulatorChange}
                  onRegulatorInfoModalOpen={handleRegulatorInfoModalOpen}
                  key={index}
                />
              ))}
            </React.Fragment>
          )}
          {regulatorSelected?.products?.length !== undefined && (
            <React.Fragment>
              <React.Fragment>
                <TextH3>{t('Sign up.Select Product')}</TextH3>
                <div
                  className={classNames(styles.textSmall, 'control', {
                    'has-error': hasProductIdsError,
                  })}
                >
                  <div className={styles.productContainer}>
                    {regulatorSelected.products.map((product, index) => (
                      <ProductField
                        key={index}
                        tickmillProduct={product}
                        onChangeProductIds={handleChangeProductIds}
                        minDeposit={product.depositLimitText}
                        onProductModalOpen={handleProductModalOpen}
                      >
                        <Text>{product.name}</Text>
                      </ProductField>
                    ))}
                  </div>
                  {hasProductIdsError && <span className='has-error'>{errors.productIds}</span>}
                </div>
              </React.Fragment>
            </React.Fragment>
          )}
          <TextH3>{t('Sign up.Privacy Policy')}</TextH3>
          {UKPrivacyPolicy?.title && (
            <DocumentGroupsFactory
              renderItem={(props: DocumentFieldItem) => (
                <div ref={UKPolicyRef} className={styles.ukTerms}>
                  <CheckBox
                    onClick={props.isAccepted ? () => undefined : props.onClick}
                    value={props.isAccepted}
                  />
                  <Text>
                    {t('Validation.I have read and accepted the')}{' '}
                    <Button
                      appearance='link'
                      type='button'
                      onClick={
                        props.isAccepted
                          ? () => window.open(UKPrivacyPolicy.url || '', '_blank', 'noreferrer')
                          : props.onClick
                      }
                    >
                      {UKPrivacyPolicy.title}
                    </Button>
                  </Text>
                </div>
              )}
              groupReferences={groupReferences}
              documents={[UKPrivacyPolicy]}
            />
          )}
          {regularDocuments.length >= 1 && (
            <DocumentGroupsFactory groupReferences={groupReferences} documents={regularDocuments} />
          )}
          <div className='control pt-1'>
            <FieldCheckBoxOptInField />
          </div>
          <Button
            type='submit'
            appearance='primary'
            size='L'
            renderRightIcon={() => <SubmitIcon />}
            disabled={isSubmitting || !isValid}
            className={styles.button}
          >
            {t('Next')}
          </Button>
        </Form>
      </PersonalInfoStep2Template>
    </>
  )
}

interface PersonalDetailsTemplateProps extends PropsWithChildren {
  step: number
  totalSteps: number
  isLoading?: boolean
  goBack(): void
}

export const PersonalInfoStep2Template: React.FC<PersonalDetailsTemplateProps> = (props) => {
  const { step, totalSteps, isLoading = false, children } = props
  const { goBack } = props

  return (
    <PersonalDetailsTemplate step={step} totalSteps={totalSteps} goBack={goBack} reversed>
      <Loading showLoadingIcon isLoading={isLoading}>
        {children}
      </Loading>
    </PersonalDetailsTemplate>
  )
}

interface RegulatorFieldProps {
  regulator: RegulatorDto

  onChange(regulator: RegulatorDto): void

  onRegulatorInfoModalOpen(regulator: RegulatorDto): void
}

const RegulatorField: React.FC<RegulatorFieldProps> = (props) => {
  const { regulator, onChange, onRegulatorInfoModalOpen } = props

  const { values } = useFormikContext<PersonalDetailsStep2FormValues>()

  const handleClick = (e: React.MouseEvent<HTMLDivElement | HTMLSelectElement, MouseEvent>) => {
    onChange(regulator)
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <div className={classNames('field', styles.regulator)} onClick={handleClick}>
      <div
        className={classNames('control', {
          [styles.isActive]: values.tickmillCompanyId === regulator.tickmillCompanyId,
        })}
      >
        <label className='is-flex is-align-items-center'>
          <Field
            name='tickmillCompanyId'
            required
            type='radio'
            onChange={(e: React.MouseEvent<HTMLSelectElement>) => {
              e.stopPropagation()
              handleClick(e)
            }}
            checked={values.tickmillCompanyId === regulator.tickmillCompanyId}
            key={regulator.name}
            className={classNames('radio')}
          />
          <span className='ml-4'>{regulator.title}</span>
        </label>
        <TextSmall isParagraph className='text-secondary'>
          {regulator.name}
        </TextSmall>
        {regulator.options.length > 0 && (
          <div className={styles.regulatorDetails}>
            {regulator.options.map((x, index) => {
              return (
                <div className={styles.optionWrapper} key={index}>
                  {x.showIcon && (
                    <span className={styles.textSmallStrong}>
                      <RegulatorOptionIcon {...x} />
                    </span>
                  )}
                  {x.showHeaderString && (
                    <span className={styles.textSmallStrong}>{x.headerString}</span>
                  )}
                  <TextTiny className={styles.centerSmallText}>{x.optionText}</TextTiny>
                </div>
              )
            })}
          </div>
        )}
        <div>
          {regulator.warnings.map((warning) => (
            <TextSmall className='pt-2' key={warning}>
              <WarningIcon size={20} color={'warning'} /> {warning}
            </TextSmall>
          ))}
        </div>
      </div>
    </div>
  )
}

interface ProductFieldProps {
  tickmillProduct: RegulatorProductOption
  disabled?: boolean
  minDeposit: string

  onChangeProductIds(value: boolean, tickmillProductType: TickmillProductType): void

  onProductModalOpen(p: RegulatorProductOption): void
}

const ProductField: React.FC<PropsWithChildren<ProductFieldProps>> = (props) => {
  const {
    tickmillProduct,
    disabled = false,
    onChangeProductIds,
    minDeposit: string,
    onProductModalOpen,
    children,
  } = props

  const { values } = useFormikContext<PersonalDetailsStep2FormValues>()
  return (
    <div className={classNames('is-flex', styles.itemContainer)}>
      <div className='is-flex'>
        <Field
          name='productIds'
          type='checkbox'
          checked={values.productIds.includes(tickmillProduct.id)}
          disabled={disabled}
          required={!values.productIds.length}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChangeProductIds(event.target.checked, tickmillProduct.id)
          }}
          className={classNames(styles.hasIncreasedSize, styles.checkbox)}
        />
        <span className={styles.item}>{children}</span>
      </div>
      <div className='is-flex'>
        <div className={styles.label}>
          <Text>{props.minDeposit}</Text>
        </div>
        <IconButton
          onClick={() => onProductModalOpen(tickmillProduct)}
          className='is-flex is-align-items-center'
        >
          <InfoIcon size={16} />
        </IconButton>
      </div>
    </div>
  )
}

const FieldCheckBoxOptInField = (): JSX.Element => {
  const { t } = useTranslation()
  const [entity] = useEntitySettings()

  return (
    <FormCheckBoxField name='optIn' className={styles.hasIncreasedSize}>
      {isTickmillEUType(entity.entity) && (
        <Text>
          {t('Sign up.I agree to submit my information to Tickmill Europe Ltd to communicate')}
        </Text>
      )}
      {isTickmillUKType(entity.entity) && (
        <Text>
          {t('Sign up.I agree to submit my information to Tickmill UK Ltd to communicate')}
        </Text>
      )}
      {isTickmillSCType(entity.entity) && (
        <Text>
          {t(
            'Sign up.I agree to receiving marketing communication from Tickmill, which I can opt-out at any time'
          )}
        </Text>
      )}
      {!isTickmillSCType(entity.entity) &&
        !isTickmillEUType(entity.entity) &&
        !isTickmillUKType(entity.entity) && (
          <Text>{t('Sign up.I agree to submit my information to Tickmill to communicate')}</Text>
        )}
    </FormCheckBoxField>
  )
}

const SubmitIcon = () => {
  const isArabic = useArabicSessionLanguage()
  return (
    <span className='is-flex is-align-items-center'>
      {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
    </span>
  )
}

const RegulatorOptionIcon: React.FC<RegulatorOptionItemDto> = (props) => (
  <React.Fragment>
    {isRegulatorOptionSuccessType(props) && <CheckIcon size={18} color={'success'} />}
    {isRegulatorOptionErrorType(props) && <CloseIcon size={18} color={'error'} />}
  </React.Fragment>
)

const initialState = (props: OuterProps): PersonalDetailsStep2FormValues => {
  const searchParams = new URLSearchParams(window.location.search)

  const countryId = props.countryId || ''
  const tickmillCompanyId =
    props?.formValues?.tickmillCompanyId ||
    Number(searchParams.get(LeadMembersEnum.TICKMILL_COMPANY_ID)) ||
    0
  const optIn = props?.formValues?.optIn || false
  const productIds = props?.formValues?.productIds || []
  const agreedLegalDocuments: AgreedLegalDocument[] = []
  const readDocument = undefined
  const documents = {}

  return {
    ...props?.formValues,
    countryId,
    tickmillCompanyId,
    optIn,
    productIds,
    agreedLegalDocuments,
    readDocument,
    documents,
  }
}

export const PersonalDetailsStep2Form = withFormik<OuterProps, PersonalDetailsStep2FormValues>({
  mapPropsToValues: (props) => {
    return initialState(props)
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, { passedDocuments, regulatorSelected }) => {
    const errors: FormikErrors<PersonalDetailsStep2FormValues> = {}
    const { agreedLegalDocuments, productIds } = values

    if (passedDocuments.length <= 0) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    if (!isDocumentsPositiveValue(values.documents)) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    if (!isDocumentsAgreed(passedDocuments, agreedLegalDocuments)) {
      errors.agreedLegalDocuments = t('Validation.Required')
    }

    if (
      !isTickmillPartner(regulatorSelected) &&
      !isProductSelected(regulatorSelected, productIds)
    ) {
      errors.productIds = t('Validation.Please select product(s)')
    }

    return errors
  },
  validateOnMount: true,
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: false,
})(PersonalDetailsStep2FormUI)

const isTickmillPartner = (regulatorSelected?: RegulatorDto) => {
  return isTickmillPartnerType(regulatorSelected?.tickmillCompanyId)
}

const isProductSelected = (regulatorSelected?: RegulatorDto, productIds: number[] = []) => {
  if (regulatorSelected?.products?.length !== undefined) {
    return productIds.length
  }
  return true
}

const isDocumentsPositiveValue = (documents: { [key: string]: boolean } = {}) => {
  return Object.entries(documents || []).every(([_, value]) => value)
}

const isDocumentsAgreed = (
  documents: LegalDocumentDto[] = [],
  agreedLegalDocuments: AgreedLegalDocument[] = []
) => {
  return documents.length === agreedLegalDocuments.length
}

interface RegulatorProps {
  handleRegulatorSelected(regulator: RegulatorDto | undefined, callback?: () => void): void

  regulators: RegulatorDto[]
}

const useRegulator = (
  props: FormikProps<PersonalDetailsStep2FormValues> & OuterProps,
  setProductIds: React.Dispatch<React.SetStateAction<TickmillProductType[]>>
): RegulatorProps => {
  const { values, setFieldValue, regulatorOptions } = props

  const [, setEntity] = useEntitySettings()

  const handleRegulatorSelected = useCallback(
    (regulator: RegulatorDto | undefined) => {
      props.onRegulatorSelectedChange(regulator)
      if (regulator) {
        setEntity({ entity: regulator.tickmillCompanyId })
        setFieldValue('productIds', [])
        setFieldValue('tickmillCompanyId', regulator.tickmillCompanyId)
        setProductIds([])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEntity, setFieldValue, props.onRegulatorSelectedChange, setProductIds]
  )

  const getRegulator = useCallback(() => {
    return (
      (regulatorOptions || []).find(
        (x) =>
          x.country.id.toUpperCase() === values.countryId.toUpperCase() ||
          x.country.shortName?.toUpperCase() === values.countryId.toUpperCase()
      )?.regulators || []
    )
  }, [regulatorOptions, values.countryId])

  useEffect(() => {
    if (!props.regulatorSelected && values.tickmillCompanyId >= 1) {
      const matchingRegulatorOption = regulatorOptions.find((regulatorOption) => {
        return (
          (regulatorOption.country.id.toUpperCase() === values.countryId.toUpperCase() ||
            regulatorOption.country.shortName?.toUpperCase() === values.countryId.toUpperCase()) &&
          regulatorOption.regulators.some(
            (regulator) => regulator.tickmillCompanyId === values.tickmillCompanyId
          )
        )
      })
      const matchingRegulator = matchingRegulatorOption?.regulators.find(
        (regulator) => regulator.tickmillCompanyId === values.tickmillCompanyId
      )
      if (matchingRegulator) {
        handleRegulatorSelected(matchingRegulator)
      }
    }
  }, [
    values.tickmillCompanyId,
    regulatorOptions,
    props.regulatorSelected,
    handleRegulatorSelected,
    values.countryId,
  ])

  return { handleRegulatorSelected, regulators: getRegulator() }
}

const useCompanyChangeEffect = (
  props: FormikProps<PersonalDetailsStep2FormValues> & OuterProps,
  regulator: RegulatorProps,
  regulatorSelected?: RegulatorDto
) => {
  const { setFieldValue } = props
  const { handleRegulatorSelected } = regulator

  const [entity] = useEntitySettings()

  useEffect(() => {
    if (entity.entity === TickmillCompaniesEnum.TICKMILL_PA) {
      setFieldValue('tickmillCompanyId', TickmillCompaniesEnum.TICKMILL_PA)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.entity, setFieldValue])

  useEffect(() => {
    const preSelected = regulator.regulators.find((x) => x.preSelected)
    if (preSelected && !regulatorSelected) {
      handleRegulatorSelected(preSelected)
      setFieldValue('tickmillCompanyId', preSelected.tickmillCompanyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regulator.regulators])
}

const useSelectCountryEffect = (
  props: FormikProps<PersonalDetailsStep2FormValues> & OuterProps
) => {
  const { values, regulatorOptions } = props
  const country = useMemo(() => {
    const regulator = regulatorOptions?.find(
      (x) =>
        x.country.id === values.countryId.toUpperCase() ||
        x.country.shortName === values.countryId.toUpperCase()
    )
    return regulator?.country
  }, [regulatorOptions, values.countryId])
  return { countryId: country?.id, countryName: country?.name }
}

const useValidationEffect = (props: FormikProps<PersonalDetailsStep2FormValues> & OuterProps) => {
  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.optIn, props.values.documents])
}

const useRedirection = (props: FormikProps<PersonalDetailsStep2FormValues> & OuterProps) => {
  const { values, formValues } = props

  const [params] = useSearchParams()

  const getRedirectionLink = (link: string) => {
    const referral = getReferralCookies()
    params.set('countryId', values.countryId || formValues?.countryId || '')

    params.set('tickmillCompanyId', formValues?.tickmillCompanyId?.toString() || '')
    params.set(LeadMembersEnum.GENDER_ID, formValues?.genderId || '')
    params.set(LeadMembersEnum.TYPE_ID, formValues?.typeId.toString() || '')
    params.set(LeadMembersEnum.FIRST_NAME, formValues?.firstName || '')
    params.set(LeadMembersEnum.MIDDLE_NAME, formValues?.middleName || '')
    params.set(LeadMembersEnum.LAST_NAME, formValues?.lastName || '')
    params.set(LeadMembersEnum.NATIVE_NAME, formValues?.nativeName || '')
    params.set(
      LeadMembersEnum.COUNTRY_CODE,
      formValues?.countryCode || params.get(LeadMembersEnum.COUNTRY_CODE) || ''
    )
    params.set(
      LeadMembersEnum.PHONE_NUMBER,
      formValues?.phoneNumber || params.get(LeadMembersEnum.PHONE_NUMBER) || ''
    )
    params.set(
      LeadMembersEnum.BIRTHDAY,
      `${formValues?.yearOfBirth}-${minTwoDigits(formValues?.monthOfBirth)}-${minTwoDigits(
        formValues?.dayOfBirth
      )}` || ''
    )
    params.set(LeadMembersEnum.EMAIL, formValues?.email || params.get(LeadMembersEnum.EMAIL) || '')
    params.set(
      LeadMembersEnum.LANGUAGE_ID,
      formValues?.languageId || params.get(LeadMembersEnum.LANGUAGE_ID) || ''
    )

    Object.entries(referral).map(([k, v]) => params.set(k.toString(), v.toString()))

    return `${link}&${params.toString()}`
  }

  const redirect = (regulator?: RegulatorDto, regulatorSelected?: RegulatorDto) => {
    window.location.replace(
      getRedirectionLink(regulator ? regulator.link : regulatorSelected?.link || '')
    )
  }

  return { getRedirectionLink, redirect }
}
