import React, { FormEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'

import { useLinksConfig } from '../../../../../configs/configs'
import { Button } from '../../../../../global/button/Button'
import { useArabicSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { FieldGroup } from '../../../../../global/form/FieldGroup'
import { createFormDatePickerField } from '../../../../../global/formField/FieldDatePicker'
import { createFormField } from '../../../../../global/formField/FormField'
import IconButton from '../../../../../global/iconButton/IconButton'
import { CountrySelectModal } from '../../../../../global/modal/CountrySelectModal'
import { InfoModal } from '../../../../../global/modal/InfoModal'
import { Modal } from '../../../../../global/modal/Modal'
import { BackIcon } from '../../../../../icons/BackIcon'
import { CloseIcon } from '../../../../../icons/CloseIcon'
import { DropArrowDownIcon } from '../../../../../icons/DropArrowDownIcon'
import { ForwardIcon } from '../../../../../icons/ForwardIcon'
import { InfoIcon } from '../../../../../icons/InfoIcon'
import { NameDto } from '../../../../../model/NameDto'
import { RegulatorOptionDto } from '../../../../../model/RegulatorOptionDto'
import { InfoCard } from '../../../../../ui/Popups/InfoCard/InfoCard'
import { Text, TextH3 } from '../../../../../ui/Typography/Typography'
import { EnglishLettersPattern } from '../../../../../utils/formValidation'
import { OwnershipStructureLegalRepresentationModal } from './OwnershipStructureLegalRepresentationModal'
import { OwnershipStructureModal } from './OwnershipStructureModal'
import { OwnershipStructureShareholdersModal } from './OwnershipStructureShareholdersModal'
import { PrimaryContactModal } from './PrimaryContactModal'

import styles from '../CompanyDetailsStep.module.scss'

const FormField = createFormField()
const FormDatePickerField = createFormDatePickerField()

export interface CompanyDetailsStep2FormValues {
  roleInCompanyType: string
  primaryContactRoleInCompanyType: string
  legalRepresentationBasis: NameDto
  ownershipStructure: NameDto
  companyOwners: CompanyOwner[]
  numberOfOwners: number
  personResponsibleCompletingApplicationCopy: boolean
}

interface CompanyOwner {
  name: string
  ownership: string
  birthday: string
  street: string
  city: string
  state: string
  zipCode: string
  country: NameDto<string>
  taxIdentificationNumber: string
}

interface OuterProps {
  legalBases: NameDto[]
  ownershipStructures: NameDto[]
  regulators: RegulatorOptionDto[]
  initialValues: CompanyDetailsStep2FormValues | undefined

  onSubmit(values: CompanyDetailsStep2FormValues): void
}

const CompanyDetailsStep2FormUI: React.FC<
  FormikProps<CompanyDetailsStep2FormValues> & OuterProps
> = (props) => {
  const {
    handleSubmit,
    validateForm,
    values,
    setFieldValue,
    setSubmitting,
    isSubmitting,
    legalBases = [],
    ownershipStructures = [],
    regulators = [],
  } = props

  const [isPrimaryContactModalOpen, setPrimaryContactModalOpen] = useState(false)
  const [
    isOwnershipStructureLegalRepresentationModalOpen,
    setOwnershipStructureLegalRepresentationModalOpen,
  ] = useState(false)
  const [isOwnershipStructureShareholdersModalOpen, setOwnershipStructureShareholdersModalOpen] =
    useState(false)
  const [isOwnershipStructureModalOpen, setOwnershipStructureModalOpen] = useState(false)

  const handlePrimaryContactModalClose = () => {
    setPrimaryContactModalOpen(false)
  }

  const handleOwnershipStructureLegalRepresentationModalOpen = () => {
    setOwnershipStructureLegalRepresentationModalOpen(true)
  }

  const handleOwnershipStructureModalOpen = () => {
    setOwnershipStructureModalOpen(true)
  }

  const handleOwnershipStructureModalClose = () => {
    setOwnershipStructureModalOpen(false)
  }

  const handleOwnershipStructureLegalRepresentationModalClose = () => {
    setOwnershipStructureLegalRepresentationModalOpen(false)
  }

  const handleOwnershipStructureShareholdersModalOpen = () => {
    setOwnershipStructureShareholdersModalOpen(true)
  }

  const handleOwnershipStructureShareholdersModalClose = () => {
    setOwnershipStructureShareholdersModalOpen(false)
  }

  const handleAddNumberOfOwners = () => {
    setFieldValue('numberOfOwners', values.numberOfOwners + 1)
  }

  const handleSubmitForm: FormEventHandler = (e) => {
    setSubmitting(true)
    e.preventDefault()
    handleSubmit()
  }

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()

  return (
    <React.Fragment>
      {isPrimaryContactModalOpen && (
        <PrimaryContactModal onClose={handlePrimaryContactModalClose} />
      )}
      {isOwnershipStructureLegalRepresentationModalOpen && (
        <OwnershipStructureLegalRepresentationModal
          legalBases={legalBases}
          onSelectOption={handleOwnershipStructureLegalRepresentationModalClose}
          onClose={handleOwnershipStructureLegalRepresentationModalClose}
        />
      )}
      {isOwnershipStructureShareholdersModalOpen && (
        <OwnershipStructureShareholdersModal
          onSelectOption={handleOwnershipStructureShareholdersModalClose}
          onClose={handleOwnershipStructureShareholdersModalClose}
        />
      )}
      {isOwnershipStructureModalOpen && (
        <OwnershipStructureModal
          ownershipStructures={ownershipStructures}
          onSelectOption={handleOwnershipStructureModalClose}
          onClose={handleOwnershipStructureModalClose}
        />
      )}
      <Form className={styles.form} onSubmit={handleSubmitForm}>
        <TextH3>{t('Sign up.Ownership Structure')}</TextH3>
        <FormField
          name='legalRepresentationBasis.name'
          label={t('Sign up.Legal Representation Basis')}
          rightIcon={<DropArrowDownIcon />}
          required
          showLabel
          onChange={(event: React.ChangeEvent) => event.preventDefault()}
          onClick={handleOwnershipStructureLegalRepresentationModalOpen}
        />
        <FormField
          name='ownershipStructure.name'
          label={t('Sign up.Ownership Structure')}
          rightIcon={<DropArrowDownIcon />}
          required
          showLabel
          onChange={(event: React.ChangeEvent) => event.preventDefault()}
          onClick={handleOwnershipStructureModalOpen}
        />
        <FormField
          name='numberOfOwners'
          label={t('Sign up.Number of Owners / Shareholders')}
          rightIcon={<DropArrowDownIcon />}
          required
          showLabel
          readOnly
          onClick={handleOwnershipStructureShareholdersModalOpen}
        />
        <>
          {Array.from({ length: values.numberOfOwners }).map((_, id) => {
            return (
              <Shareholder
                id={id}
                name={`companyOwners[${[id]}]`}
                item={values.companyOwners[id]}
                regulators={regulators}
                key={id}
              />
            )
          })}
        </>
        <Button
          className='pt-4 p-0'
          type='button'
          appearance='plain'
          size='S'
          onClick={handleAddNumberOfOwners}
        >
          {t('Sign up.+ Add Representative')}
        </Button>

        <Button
          className={styles.button}
          type='submit'
          appearance='primary'
          size='L'
          onClick={() => validateForm()}
          renderRightIcon={() => (
            <span className='is-flex is-align-items-center'>
              {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
            </span>
          )}
          disabled={isSubmitting || !props.isValid}
        >
          {t('Next')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

interface ShareholderProps {
  id: number
  name: string
  item: CompanyOwner
  regulators: RegulatorOptionDto[]
}

const Shareholder: React.FC<ShareholderProps> = (props) => {
  const { id, name, item, regulators } = props
  const countryOptions = regulators.map((regulator) => regulator.country)
  const { t } = useTranslation()
  const { validateCity, validateState, validateStreet, validateCountry, validateZipCode } =
    useFormValidation()
  const minEighteenYearsOldDate = new Date()
  minEighteenYearsOldDate.setFullYear(minEighteenYearsOldDate.getFullYear() - 18)
  const [isTINModalOpen, setTINModalOpen] = useState(false)

  const { links } = useLinksConfig()
  const { values, setFieldValue } = useFormikContext<CompanyDetailsStep2FormValues>()

  const [isShareholderCountryModalOpen, setShareholderCountryModalOpen] = useState(false)

  const handleShareholderCountryModalOpen = () => {
    setShareholderCountryModalOpen(true)
  }

  const handleShareholderCountryModalClose = () => {
    setShareholderCountryModalOpen(false)
  }

  const handleRemoveNumberOfOwners = () => {
    setFieldValue('numberOfOwners', values.numberOfOwners - 1)
    setFieldValue(
      'companyOwners',
      values.companyOwners.filter((_, index) => index !== id)
    )
  }

  const handleCountryOption = (country: NameDto<string>) => {
    setFieldValue(`${name}.country`, country)
    setShareholderCountryModalOpen(false)
  }

  return (
    <React.Fragment>
      {isShareholderCountryModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={handleShareholderCountryModalClose}
          render={() => (
            <CountrySelectModal
              title={t('Sign up.Country of Residence')}
              options={countryOptions}
              selectedCountry={item?.country?.name}
              handleOnChangeOption={handleCountryOption}
              onCancel={handleShareholderCountryModalClose}
            />
          )}
        />
      )}
      {isTINModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setTINModalOpen(false)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('Sign up.Tax Identification Number (TIN)')}
              renderBody={() => (
                <section className='modal-card-body'>
                  <Text
                    isParagraph
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Sign up.Here we'll need your individual Tax Identification Number",
                        {
                          eu_link: links.TIN.EuropeanCommission,
                          worldwide_link: links.TIN.OECD,
                        }
                      ),
                    }}
                  />
                </section>
              )}
              renderFooter={() => (
                <React.Fragment>
                  <button
                    className='button'
                    onClick={() => {
                      setTINModalOpen(false)
                    }}
                    type='button'
                  >
                    <b>{t('Got It')}</b>
                  </button>
                </React.Fragment>
              )}
              onConfirm={() => setTINModalOpen(false)}
            />
          )}
        />
      )}
      <TextH3 className='is-flex is-align-items-center'>
        {t('Sign up.Shareholder')} {id + 1}{' '}
        <InfoCard
          className={styles.infoCard}
          text={t(
            'Sign up.Each Director, and any Shareholder who owns more than 25% of the shares must'
          )}
        >
          <InfoIcon />
        </InfoCard>
        {id >= 1 && (
          <span className='is-flex is-align-items-center ml-1'>
            <IconButton onClick={handleRemoveNumberOfOwners}>
              <CloseIcon color={'error'} />
            </IconButton>
          </span>
        )}
      </TextH3>
      <FormField
        name={`${name}.name`}
        label={t('Sign up.Name')}
        type='text'
        max={100}
        min={1}
        required
        showLabel
        pattern='.*\S+.*'
      />
      <FormDatePickerField
        name={`${name}.birthday`}
        label={t('Sign up.Date of Birth')}
        required
        showLabel
        showYearDropdown
        maxDate={minEighteenYearsOldDate}
      />

      <FieldGroup>
        <FormField
          name={`${name}.country`}
          label={t('Sign up.Country of Residence')}
          value={item?.country?.name}
          rightIcon={<DropArrowDownIcon />}
          required
          showLabel
          onChange={(event: React.ChangeEvent) => event.preventDefault()}
          onClick={handleShareholderCountryModalOpen}
          validate={validateCountry}
        />
        <FormField
          name={`${name}.ownership`}
          label={t('Sign up.Ownership (%)')}
          type='number'
          min='0'
          required
          showLabel
        />
      </FieldGroup>

      <FormField
        name={`${name}.street`}
        label={t('Sign up.Street Name, Street Number, Apt. Number')}
        validate={validateStreet}
        required
        showLabel
        pattern='.*\S+.*'
      />
      <FormField
        name={`${name}.city`}
        label={t('Sign up.City')}
        validate={validateCity}
        required
        showLabel
        pattern='.*\S+.*'
      />

      <FieldGroup>
        <FormField
          name={`${name}.state`}
          label={t('Sign up.State/Region')}
          validate={validateState}
          required
          showLabel
          pattern='.*\S+.*'
        />
        <FormField
          name={`${name}.zipCode`}
          label={t('Sign up.ZIP/Postal Code')}
          type='text'
          validate={validateZipCode}
          required
          showLabel
          pattern='.*\S+.*'
        />
      </FieldGroup>
      <TextH3>
        {t('Sign up.Tax Identification Number (TIN)')}
        <button onClick={() => setTINModalOpen(true)} type='button' className={styles.plainButton}>
          <InfoIcon />
        </button>
      </TextH3>
      <FormField
        name={`${name}.taxIdentificationNumber`}
        label={t('Sign up.Tax Identification Number (TIN)')}
        type='text'
        showLabel
      />
    </React.Fragment>
  )
}

const useFormValidation = () => {
  const { t } = useTranslation()

  const validateStreet = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid street')}`
    }
    return undefined
  }

  const validateCity = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid city')}`
    }
  }

  const validateState = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid state')}`
    }
  }

  const validateZipCode = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
    if (value.length > 32) {
      return `${t('Validation.Maximum character limit is 32')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid postal code')}`
    }
  }

  const validateCountry = (value: string) => {
    if (!value) {
      return `${t('Validation.Required')}`
    }
  }

  return { validateStreet, validateCity, validateState, validateCountry, validateZipCode }
}

export const CompanyDetailsStep2Form = withFormik<OuterProps, CompanyDetailsStep2FormValues>({
  mapPropsToValues: ({ initialValues }) => {
    return {
      ...initialValues,
      companyOwners: initialValues?.companyOwners || [],
      roleInCompanyType: initialValues?.roleInCompanyType || '',
      legalRepresentationBasis: initialValues?.legalRepresentationBasis || { id: 0, name: '' },
      ownershipStructure: initialValues?.ownershipStructure || {
        id: 0,
        name: '',
      },
      primaryContactRoleInCompanyType: initialValues?.primaryContactRoleInCompanyType || '',
      numberOfOwners: initialValues?.numberOfOwners || 1,
      personResponsibleCompletingApplicationCopy:
        initialValues?.personResponsibleCompletingApplicationCopy || true,
    }
  },
  validate: () => {
    const errors: FormikErrors<CompanyDetailsStep2FormValues> = {}

    return errors
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit({
        ...values,
        companyOwners: values.companyOwners.filter((x) => !!x),
        primaryContactRoleInCompanyType: values.personResponsibleCompletingApplicationCopy
          ? values.roleInCompanyType
          : values.primaryContactRoleInCompanyType,
      })
    } finally {
      setSubmitting(false)
    }
  },
  isInitialValid: false,
})(CompanyDetailsStep2FormUI)
