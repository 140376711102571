import React, { FormEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'
import { v4 as uuid } from 'uuid'

import { Button } from '../../../../../global/button/Button'
import { useSessionEntity } from '../../../../../global/context/EntityContext'
import { useArabicSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { SelectModal, SelectModalOption } from '../../../../../global/field/SelectModal'
import { createFormDatePickerField } from '../../../../../global/formField/FieldDatePicker'
import { createFormCheckBoxField } from '../../../../../global/formField/FormCheckBoxField'
import { createFormField } from '../../../../../global/formField/FormField'
import { RawSelectFormField } from '../../../../../global/formField/FormSelectField'
import { createFormRadioField } from '../../../../../global/formField/RadioGroupField'
import { InfoModal } from '../../../../../global/modal/InfoModal'
import { Modal } from '../../../../../global/modal/Modal'
import { PhoneCodeSelectModal } from '../../../../../global/modal/PhoneCodeModal'
import { RadioButton } from '../../../../../global/radioButton/RadioButton'
import { useFetchRegulators } from '../../../../../hooks/useFetchRegulators'
import { BackIcon } from '../../../../../icons/BackIcon'
import { DropArrowDownIcon } from '../../../../../icons/DropArrowDownIcon'
import { ForwardIcon } from '../../../../../icons/ForwardIcon'
import { InfoIcon } from '../../../../../icons/InfoIcon'
import { CountryPreferenceStrippedDto } from '../../../../../model/CountryDto'
import { NameDto } from '../../../../../model/NameDto'
import {
  TestSectionAnswerDto,
  TestSectionDto,
  TestSectionQuestionDto,
} from '../../../../../model/TestSectionsDto'
import { CountryCodeFlagIcon } from '../../../../../ui/CountryCodeFlagIcon/CountryCodeFlagIcon'
import { Text, TextH3 } from '../../../../../ui/Typography/Typography'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../../../../utils/companyName.utils'
import { EmailPattern, EnglishLettersPattern } from '../../../../../utils/formValidation'
import { preventPaste } from '../../../../../utils/input.utils'
import { validateUrl } from '../../../../../utils/validations'
import { CountryOfResidenceModal } from '../../PersonalDetails/PersonalDetailsStep2/PersonalDetailsModal'

import styles from '../CompanyDetailsStep.module.scss'

const FormField = createFormField<CompanyDetailsStep1FormValues>()
const FormCheckBoxField = createFormCheckBoxField<CompanyDetailsStep1FormValues>()
const FormDatePickerField = createFormDatePickerField<CompanyDetailsStep1FormValues>()
const FormRadioGroupField = createFormRadioField<CompanyDetailsStep1FormValues>()

export interface CompanyDetailsStep1FormValues {
  companyName: string
  companyAddressCountry: string
  companyRegistrationDate: string
  companyRegistrationNumber: string
  companyTypeId: string
  companyPhoneNumberCountryCode: string
  companyPhoneNumber: string
  companyEmail: string
  registrationAddress: {
    companyAddressPostalCode: string
    companyAddressStreet: string
    companyAddressCity: string
    companyAddressState: string
  }

  physicalAddress: {
    companyAddressPostalCode: string
    companyAddressStreet: string
    companyAddressCity: string
    companyAddressState: string
  }

  additionalInfo: {
    companyLEICode: string
    registeredLEICountry: {
      value: string
      id: string
    }
    companyfinancialCounterParty: {
      value: string
      id: string
    }
    companyIndustry: {
      value: string
      id: string
    }
    isPerformEMIRSelected: string
    isReportingCounterParty: string
    website: string
    otherIndustry: string
  }

  registrationAddressCopy: boolean

  clientTests?: {
    category: string
    testId: string
    selectedAnswers?: string[]
    freeAnswers?: { TestQuestionId?: string; Answer?: string }[]
  }[]
}

interface OuterProps {
  companyTypes: NameDto[]
  countries: CountryPreferenceStrippedDto[]

  phoneCodes: string[]
  section?: TestSectionDto
  reportingCounterPartyData?: TestSectionQuestionDto
  industryData?: TestSectionQuestionDto
  leiCodeData?: TestSectionQuestionDto
  websiteData?: TestSectionQuestionDto
  otherIndustryQuestion?: TestSectionQuestionDto
  testId: string
  entity: TickmillCompaniesEnum
  otherIndustryData?: TestSectionAnswerDto

  initialValues: CompanyDetailsStep1FormValues | undefined
  onSubmit(values: CompanyDetailsStep1FormValues): void
}

const CompanyDetailsStep1FormUI: React.FC<
  FormikProps<CompanyDetailsStep1FormValues> & OuterProps
> = (props) => {
  const entity = useSessionEntity()
  const {
    handleSubmit,
    validateForm,
    values,
    setFieldValue,
    setSubmitting,
    errors,
    isSubmitting,
    isValid,
  } = props
  const [modalVisible, setModalVisible] = useState(false)
  const [isCountryModalOpen, setIsCountryModelOpen] = useState(false)
  const [isLEICountryModalOpen, setIsLEICountryModalOpen] = useState(false)
  const [isFinancialCounterPartyModal, setIsFinancialCounterPartyModal] = useState(false)
  const [isCompanyIndustryModal, setIsCompanyIndustryModal] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<CountryPreferenceStrippedDto>()
  const apiClient = useApiClient(ClientApiClient)
  const regulators = useFetchRegulators()
  const onSubmit: FormEventHandler = (e) => {
    validateForm()
    e.preventDefault()
    if (!Object.keys(errors).length) {
      setSubmitting(true)
      handleSubmit()
    }
  }

  useEffect(() => {
    const country = (props.countries || []).find(
      (x) => x.phoneCode === values.companyPhoneNumberCountryCode
    )

    if (country) {
      setSelectedCountry(country)
    }
  }, [props.countries, setFieldValue, values.companyPhoneNumberCountryCode])

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const { validateCity, validateStreet, validateState } = useFormValidation()

  useEffect(() => {
    if (
      !values.companyPhoneNumber ||
      !values.companyPhoneNumberCountryCode ||
      values.companyPhoneNumber.toString().length < 4 ||
      errors.companyPhoneNumber
    ) {
      return
    }

    setIsLoading(true)

    const timeOut: NodeJS.Timeout = setTimeout(async () => {
      try {
        await apiClient.validateBasePhoneNumber(
          values.companyPhoneNumber,
          values.companyPhoneNumberCountryCode
        )
        setPhoneNumberError('')
      } catch {
        setPhoneNumberError(t('Sign up.Please check your phone number'))
      } finally {
        setIsLoading(false)
      }
    }, 1000)

    return () => clearTimeout(timeOut)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.companyPhoneNumberCountryCode, values.companyPhoneNumber, errors.companyPhoneNumber])

  const leiCountryData = props?.section?.questions?.find(
    (sectionQuestion) => sectionQuestion?.question?.widgetName === 'lei_country'
  )

  const counterPartyData = props?.section?.questions?.find(
    (sectionQuestion) => sectionQuestion?.question?.widgetName === 'counterparty'
  )

  const reportingAgreedData = props?.section?.questions?.find(
    (sectionQuestion) => sectionQuestion?.question?.widgetName === 'reporting_agreed'
  )
  const companyCountryCode = props.values.companyPhoneNumberCountryCode

  const handleOnChangeOption = (country: CountryPreferenceStrippedDto) => {
    props.setFieldValue('companyPhoneNumberCountryCode', country.phoneCode)
    setSelectedCountry(country)
    setIsCountryModelOpen(false)
  }

  return (
    <React.Fragment>
      {modalVisible && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setModalVisible(false)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('Sign up.Legal Identity Identifier (LEI)')}
              renderBody={() => (
                <section className='modal-card-body'>
                  <Text>
                    {t('Sign up.A LEI is a required 20-digit alphanumeric reference code')}{' '}
                  </Text>
                  <a
                    href='https://leiworldwide.com/lei_search'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='is-link'
                  >
                    {t('here')}
                  </a>
                  .
                </section>
              )}
              renderFooter={() => (
                <React.Fragment>
                  <button
                    className='button'
                    onClick={() => {
                      setModalVisible(false)
                    }}
                    type='button'
                  >
                    <b>{t('Got It')}</b>
                  </button>
                </React.Fragment>
              )}
              onConfirm={() => setModalVisible(false)}
            />
          )}
        />
      )}
      {isCountryModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setIsCountryModelOpen(false)}
          render={() => (
            <PhoneCodeSelectModal
              title={t('Sign up.Phone Code')}
              options={props.countries}
              onCancel={() => setIsCountryModelOpen(false)}
              handleOnChangeOption={handleOnChangeOption}
              selectedCountry={values.companyPhoneNumberCountryCode}
            />
          )}
        />
      )}

      {isLEICountryModalOpen && (
        <CountryOfResidenceModal
          title={leiCountryData?.question?.name}
          regulators={regulators?.data}
          selectedCountry={values.additionalInfo.registeredLEICountry?.value}
          onChangeOption={(option) => {
            setFieldValue('additionalInfo.registeredLEICountry', {
              value: option.name,
              id: leiCountryData?.question?.id,
            })

            setIsLEICountryModalOpen(false)
          }}
          closeModal={() => {
            setIsLEICountryModalOpen(false)
          }}
        />
      )}

      {isFinancialCounterPartyModal && (
        <Modal
          closeModal={() => setIsFinancialCounterPartyModal(false)}
          render={({ closeModal }) => (
            <SelectModal
              onCancel={closeModal}
              title={counterPartyData?.question?.name || ''}
              renderOptions={() => (
                <div className='control'>
                  {counterPartyData?.answers?.map(({ id, name }) => (
                    <SelectModalOption
                      label={name}
                      value={id === values.additionalInfo?.companyfinancialCounterParty?.value}
                      onClick={() => {
                        setFieldValue('additionalInfo.companyfinancialCounterParty', {
                          value: name,
                          id,
                        })
                        setIsFinancialCounterPartyModal(false)
                      }}
                      key={id}
                    />
                  ))}
                </div>
              )}
            />
          )}
        />
      )}

      {isCompanyIndustryModal && (
        <Modal
          closeModal={() => setIsCompanyIndustryModal(false)}
          render={({ closeModal }) => (
            <SelectModal
              onCancel={closeModal}
              title={props?.industryData?.question?.name || ''}
              renderOptions={() => (
                <div className='control'>
                  {(props?.industryData?.answers || [])?.map(({ id, name }) => (
                    <SelectModalOption
                      label={name}
                      value={id === values.additionalInfo?.companyIndustry?.value}
                      onClick={() => {
                        setFieldValue('additionalInfo.companyIndustry', {
                          value: name,
                          id,
                        })

                        setIsCompanyIndustryModal(false)
                      }}
                      key={id}
                    />
                  ))}
                </div>
              )}
            />
          )}
        />
      )}

      <Form className={styles.form} onSubmit={onSubmit} autoComplete='off'>
        <TextH3>{t('Sign up.Company')}</TextH3>
        <FormField
          type='text'
          required
          showLabel
          name='companyName'
          label={t('Sign up.Company Name')}
        />
        <RawSelectFormField
          showLabel
          component='select'
          name={'companyAddressCountry'}
          label={t('Sign up.Country of Registration')}
          required
        >
          {(props.countries || []).map((country) => (
            <option value={country.country.id} key={country.country.id}>
              {country.country.name}
            </option>
          ))}
        </RawSelectFormField>
        <div className='field'>
          <FormDatePickerField
            name='companyRegistrationDate'
            label={t('Sign up.Registration Date')}
            maxDate={new Date()}
            required
            showLabel
            showYearDropdown
          />
        </div>
        <FormField
          type='text'
          required
          showLabel
          name='companyRegistrationNumber'
          label={t('Sign up.Registration Number')}
        />
        <RawSelectFormField
          showLabel
          component='select'
          name={'companyTypeId'}
          label={t('Sign up.Company Type')}
          required
        >
          {(props.companyTypes || []).map((companyType) => (
            <option value={companyType.id} key={companyType.id}>
              {companyType.name}
            </option>
          ))}
        </RawSelectFormField>
        <TextH3>{t('Sign up.Company Registration Address')}</TextH3>
        <FormField
          type='text'
          required
          showLabel
          name='registrationAddress.companyAddressStreet'
          label={t('Sign up.Street Name, Street Number, Apt. Number')}
          validate={validateStreet}
        />
        <FormField
          type='text'
          required
          showLabel
          name='registrationAddress.companyAddressCity'
          label={t('Sign up.City')}
          validate={validateCity}
        />
        <div className={styles.row}>
          <FormField
            type='text'
            required
            showLabel
            name='registrationAddress.companyAddressState'
            label={t('Sign up.State/Region')}
            validate={validateState}
          />
          <FormField
            type='text'
            name='registrationAddress.companyAddressPostalCode'
            label={t('Sign up.ZIP/Postal Code')}
            required
            showLabel
          />
        </div>
        <TextH3>{t(`Sign up.Company's Physical Address`)}</TextH3>
        <FormCheckBoxField name='registrationAddressCopy'>
          {t('Sign up.Same as registration address')}
        </FormCheckBoxField>
        {!values.registrationAddressCopy && (
          <React.Fragment>
            <FormField
              name='physicalAddress.companyAddressStreet'
              label={t('Sign up.Street Name, Street Number, Apt. Number')}
              type='text'
              validate={validateStreet}
              required
              showLabel
            />
            <FormField
              name='physicalAddress.companyAddressCity'
              label={t('Sign up.City')}
              type='text'
              validate={validateCity}
              required
              showLabel
            />
            <div className={styles.row}>
              <FormField
                name='physicalAddress.companyAddressState'
                label={t('Sign up.State/Region')}
                type='text'
                validate={validateState}
                required
                showLabel
              />
              <FormField
                type='text'
                name='physicalAddress.companyAddressPostalCode'
                label={t('Sign up.ZIP/Postal Code')}
                required
                showLabel
              />
            </div>
          </React.Fragment>
        )}
        <TextH3>{t('Sign up.Phone and Email')}</TextH3>
        <div className={styles.rowPhone}>
          <FormField
            showLabel
            label={t('Sign up.Country Code')}
            autoComplete={`companyCountryCode-${uuid()}`}
            name='companyPhoneNumberCountryCode'
            leftIcon={
              companyCountryCode !== '' ? (
                <CountryCodeFlagIcon
                  countries={props.countries}
                  selectedCountry={selectedCountry}
                />
              ) : null
            }
            onClick={() => setIsCountryModelOpen(true)}
            rightIcon={<DropArrowDownIcon />}
            required
          />
          <div className='is-flex-direction-column'>
            <FormField
              type='tel'
              disabled={!values.companyPhoneNumberCountryCode}
              required
              onPaste={preventPaste}
              onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
              name='companyPhoneNumber'
              showLabel
              label={t('PhoneNumbers.Phone Number')}
              autoComplete={`companyPhoneNumber-${uuid()}`}
              error={errors.companyPhoneNumber || phoneNumberError}
              validateOnType
            />
          </div>
        </div>
        <FormField type='email' showLabel required name='companyEmail' label={t('Sign up.Email')} />

        {(entity === TickmillCompaniesEnum.TICKMILL_EU ||
          entity === TickmillCompaniesEnum.TICKMILL_UK) && (
          <>
            <TextH3>{t('Sign up.Additional Info')}</TextH3>
            <div>
              <div>
                <div>
                  {reportingAgreedData?.question?.name}

                  <FormRadioGroupField
                    name='additionalInfo.isPerformEMIRSelected'
                    value={values.additionalInfo.isPerformEMIRSelected}
                    error={errors?.additionalInfo?.isPerformEMIRSelected}
                    required={reportingAgreedData?.isMandatory}
                    key='isPerformEMIRSelected'
                  >
                    <div className='columns mt-1 is-mobile'>
                      {reportingAgreedData?.answers?.map((x) => (
                        <div className='column is-3 pb-4'>
                          <RadioButton
                            name={x.id}
                            label={x.name}
                            value={x.id}
                            checked={x.id === values?.additionalInfo?.isPerformEMIRSelected}
                            key={x.id}
                            noContentWrapper
                          />
                        </div>
                      ))}
                    </div>
                  </FormRadioGroupField>
                </div>

                <div className='pt-4'>
                  {props?.reportingCounterPartyData?.question?.name}

                  <FormRadioGroupField
                    name='additionalInfo.isReportingCounterParty'
                    value={values.additionalInfo.isReportingCounterParty}
                    required={props?.reportingCounterPartyData?.isMandatory}
                    error={errors?.additionalInfo?.isReportingCounterParty}
                    key='isReportingCounterParty'
                  >
                    <div className='columns mt-1 is-mobile'>
                      {props?.reportingCounterPartyData?.answers?.map((x) => (
                        <div className='column is-3 pb-4'>
                          <RadioButton
                            name={x.id}
                            label={x.name}
                            value={x.id}
                            checked={x.id === values?.additionalInfo?.isReportingCounterParty}
                            key={x.id}
                            noContentWrapper
                          />
                        </div>
                      ))}
                    </div>
                  </FormRadioGroupField>
                </div>
              </div>

              <div className='pt-4'>
                <FormField
                  type='text'
                  required={props?.leiCodeData?.isMandatory}
                  name='additionalInfo.companyLEICode'
                  error={errors?.additionalInfo?.companyLEICode}
                  renderLabel={
                    <>
                      {t('Sign up.LEI Code')} {props?.leiCodeData?.isMandatory && '*'}
                      <InfoIcon
                        className='has-cursor-pointer ml-1'
                        onClick={() => setModalVisible(true)}
                      />
                    </>
                  }
                />

                <FormField
                  name='additionalInfo.registeredLEICountry.value'
                  label={leiCountryData?.question?.name}
                  value={values.additionalInfo.registeredLEICountry?.value}
                  rightIcon={<DropArrowDownIcon />}
                  required={leiCountryData?.isMandatory}
                  showLabel
                  error={errors?.additionalInfo?.registeredLEICountry?.value}
                  onChange={(event: React.ChangeEvent) => event.preventDefault()}
                  onClick={() => {
                    setIsLEICountryModalOpen(true)
                  }}
                />

                <FormField
                  name='additionalInfo.companyfinancialCounterParty.value'
                  label={counterPartyData?.question?.name}
                  value={values.additionalInfo.companyfinancialCounterParty?.value}
                  rightIcon={<DropArrowDownIcon />}
                  required={counterPartyData?.isMandatory}
                  showLabel
                  error={errors?.additionalInfo?.companyfinancialCounterParty?.value}
                  onChange={(event: React.ChangeEvent) => event.preventDefault()}
                  onClick={() => {
                    setIsFinancialCounterPartyModal(true)
                  }}
                />

                <FormField
                  name='additionalInfo.companyIndustry.value'
                  value={values.additionalInfo?.companyIndustry?.value}
                  rightIcon={<DropArrowDownIcon />}
                  showLabel
                  label={props?.industryData?.question?.name}
                  required={props?.industryData?.isMandatory}
                  error={errors?.additionalInfo?.companyIndustry?.value}
                  onChange={(event: React.ChangeEvent) => event.preventDefault()}
                  onClick={() => {
                    setIsCompanyIndustryModal(true)
                  }}
                />

                {values?.additionalInfo?.companyIndustry?.id === props?.otherIndustryData?.id && (
                  <FormField
                    type='text'
                    showLabel
                    required
                    name='additionalInfo.otherIndustry'
                    label={props?.otherIndustryQuestion?.question?.name}
                    error={errors?.additionalInfo?.companyIndustry?.value}
                  />
                )}
                <FormField
                  type='text'
                  showLabel
                  required={props?.websiteData?.isMandatory}
                  name='additionalInfo.website'
                  label={t('Sign up.Website')}
                  error={errors?.additionalInfo?.website}
                />
              </div>
            </div>
          </>
        )}

        <Button
          className={styles.button}
          type='submit'
          appearance='primary'
          size='L'
          onClick={() => validateForm()}
          renderRightIcon={() => (
            <span className='is-flex is-align-items-center'>
              {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
            </span>
          )}
          disabled={isSubmitting || !isValid || isLoading || !!phoneNumberError}
        >
          {t('Next')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

const useFormValidation = () => {
  const { t } = useTranslation()

  const validateStreet = (value: string) => {
    if (!value.trim()) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid street')}`
    }
    return undefined
  }

  const validateCity = (value: string) => {
    if (!value.trim()) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid city')}`
    }
  }

  const validateState = (value: string) => {
    if (!value.trim()) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid state')}`
    }
  }

  return { validateStreet, validateCity, validateState }
}

export const CompanyDetailsStep1Form = withFormik<OuterProps, CompanyDetailsStep1FormValues>({
  mapPropsToValues: ({ reportingCounterPartyData, initialValues }) => {
    const reportingCounterPartyDefault = reportingCounterPartyData?.answers?.find(
      (data) => data.key === 'no'
    )

    return {
      companyAddressCountry: initialValues?.companyAddressCountry || '',
      companyEmail: initialValues?.companyEmail || '',
      companyName: initialValues?.companyName || '',
      companyPhoneNumber: initialValues?.companyPhoneNumber || '',
      companyPhoneNumberCountryCode: initialValues?.companyPhoneNumberCountryCode || '',
      companyRegistrationDate: initialValues?.companyRegistrationDate || '',
      companyRegistrationNumber: initialValues?.companyRegistrationNumber || '',
      companyTypeId: initialValues?.companyTypeId || '',

      registrationAddress: {
        companyAddressPostalCode:
          initialValues?.registrationAddress?.companyAddressPostalCode || '',
        companyAddressState: initialValues?.registrationAddress?.companyAddressState || '',
        companyAddressStreet: initialValues?.registrationAddress?.companyAddressStreet || '',
        companyAddressCity: initialValues?.registrationAddress?.companyAddressCity || '',
      },

      physicalAddress: {
        companyAddressPostalCode: initialValues?.physicalAddress?.companyAddressPostalCode || '',
        companyAddressStreet: initialValues?.physicalAddress?.companyAddressStreet || '',
        companyAddressCity: initialValues?.physicalAddress?.companyAddressCity || '',
        companyAddressState: initialValues?.physicalAddress?.companyAddressState || '',
      },

      additionalInfo: {
        companyLEICode: initialValues?.additionalInfo?.companyLEICode || '',
        registeredLEICountry: {
          value: initialValues?.additionalInfo?.registeredLEICountry?.value || '',
          id: initialValues?.additionalInfo?.registeredLEICountry?.id || '',
        },
        companyfinancialCounterParty: {
          value: initialValues?.additionalInfo?.companyfinancialCounterParty?.value || '',
          id: initialValues?.additionalInfo?.companyfinancialCounterParty?.id || '',
        },
        companyIndustry: {
          value: initialValues?.additionalInfo?.companyIndustry?.value || '',
          id: initialValues?.additionalInfo?.companyIndustry?.id || '',
        },
        isPerformEMIRSelected: initialValues?.additionalInfo?.isPerformEMIRSelected || '',
        isReportingCounterParty:
          initialValues?.additionalInfo?.isReportingCounterParty ||
          reportingCounterPartyDefault?.id ||
          '',
        otherIndustry: initialValues?.additionalInfo?.otherIndustry || '',
        website: initialValues?.additionalInfo?.website || '',
      },

      registrationAddressCopy: initialValues?.registrationAddressCopy || false,
    }
  },

  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)

      const additionalInfoValues = values?.additionalInfo

      const getNonEmptyAnswersId = [
        additionalInfoValues?.companyIndustry?.id || '',
        additionalInfoValues?.companyfinancialCounterParty?.id || '',
        additionalInfoValues?.isPerformEMIRSelected || '',
        additionalInfoValues?.isReportingCounterParty || '',
      ].filter((item) => item)

      const getOtherIndustryAnswer = additionalInfoValues?.otherIndustry
        ? [
            {
              TestQuestionId: props?.otherIndustryQuestion?.question?.id,
              Answer: additionalInfoValues?.otherIndustry,
            },
          ]
        : []

      const getNonEmptyFreeTestQuestions = [
        {
          TestQuestionId: props?.leiCodeData?.question.id,
          Answer: additionalInfoValues?.companyLEICode,
        },
        {
          TestQuestionId: additionalInfoValues?.registeredLEICountry.id,
          Answer: additionalInfoValues?.registeredLEICountry.value,
        },
        {
          TestQuestionId: props?.websiteData?.question.id,
          Answer: additionalInfoValues?.website,
        },
      ].filter((item) => item?.TestQuestionId)

      const clientTests =
        (props?.entity === TickmillCompaniesEnum.TICKMILL_EU ||
          props?.entity === TickmillCompaniesEnum.TICKMILL_UK) &&
        props?.section
          ? [
              {
                category: 'Corporate test',
                testId: props.testId,
                selectedAnswers: getNonEmptyAnswersId,
                freeAnswers: getNonEmptyFreeTestQuestions.concat(getOtherIndustryAnswer),
              },
            ]
          : []

      props.onSubmit({
        ...values,
        physicalAddress: {
          companyAddressPostalCode: values?.registrationAddressCopy
            ? values.registrationAddress.companyAddressPostalCode
            : values.physicalAddress.companyAddressPostalCode,
          companyAddressStreet: values?.registrationAddressCopy
            ? values.registrationAddress.companyAddressStreet
            : values.physicalAddress.companyAddressStreet,
          companyAddressCity: values?.registrationAddressCopy
            ? values.registrationAddress.companyAddressCity
            : values.physicalAddress.companyAddressCity,
          companyAddressState: values?.registrationAddressCopy
            ? values.registrationAddress.companyAddressState
            : values.physicalAddress.companyAddressState,
        },
        clientTests,
      })
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, props) => {
    const errors: FormikErrors<CompanyDetailsStep1FormValues> = {}
    if (!values.companyTypeId) {
      errors.companyTypeId = t('Validation.Required')
    }

    if (!values.companyAddressCountry) {
      errors.companyAddressCountry = t('Validation.Required')
    }

    // RegistrationAddress
    if (!values.registrationAddress.companyAddressPostalCode.trim()) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressPostalCode = t('Validation.Required')
      } else {
        errors.registrationAddress = {
          companyAddressPostalCode: t('Validation.Required'),
        }
      }
    }
    if (values.registrationAddress.companyAddressPostalCode.length > 32) {
      errors.registrationAddress = {
        ...errors.registrationAddress,
        companyAddressPostalCode: t('Validation.Maximum character limit is 32'),
      }
    }
    if (!EnglishLettersPattern.test(values.registrationAddress.companyAddressPostalCode)) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressPostalCode = t('Validation.Invalid postal code')
      } else {
        errors.registrationAddress = {
          companyAddressPostalCode: t('Validation.Invalid postal code'),
        }
      }
    }
    if (!EnglishLettersPattern.test(values.registrationAddress.companyAddressState.trim())) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressState = t('Validation.Invalid state')
      } else {
        errors.registrationAddress = {
          companyAddressState: t('Validation.Invalid state'),
        }
      }
    }
    if (!values.registrationAddress.companyAddressState) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressState = t('Validation.Required')
      } else {
        errors.registrationAddress = {
          companyAddressState: t('Validation.Required'),
        }
      }
    }

    if (!EnglishLettersPattern.test(values.registrationAddress.companyAddressCity)) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressCity = t('Validation.Invalid city')
      } else {
        errors.registrationAddress = {
          companyAddressCity: t('Validation.Invalid city'),
        }
      }
    }
    if (!values.registrationAddress.companyAddressCity) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressCity = t('Validation.Required')
      } else {
        errors.registrationAddress = {
          companyAddressCity: t('Validation.Required'),
        }
      }
    }

    if (!EnglishLettersPattern.test(values.registrationAddress.companyAddressStreet)) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressStreet = t('Validation.Invalid street')
      } else {
        errors.registrationAddress = {
          companyAddressStreet: t('Validation.Invalid street'),
        }
      }
    }
    if (!values.registrationAddress.companyAddressStreet) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressStreet = t('Validation.Required')
      } else {
        errors.registrationAddress = {
          companyAddressStreet: t('Validation.Required'),
        }
      }
    }

    // PhysicalAddress
    if (!values.registrationAddressCopy) {
      if (!values.physicalAddress.companyAddressPostalCode.trim()) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressPostalCode = t('Validation.Required')
        } else {
          errors.physicalAddress = {
            companyAddressPostalCode: t('Validation.Required'),
          }
        }
      }

      if (values.physicalAddress.companyAddressPostalCode.length > 32) {
        errors.physicalAddress = {
          ...errors.physicalAddress,
          companyAddressPostalCode: t('Validation.Maximum character limit is 32'),
        }
      }
      if (!EnglishLettersPattern.test(values.physicalAddress.companyAddressPostalCode)) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressPostalCode = t('Validation.Invalid postal code')
        } else {
          errors.physicalAddress = {
            companyAddressPostalCode: t('Validation.Invalid postal code'),
          }
        }
      }

      if (!EnglishLettersPattern.test(values.physicalAddress.companyAddressState)) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressState = t('Validation.Invalid state')
        } else {
          errors.physicalAddress = {
            companyAddressState: t('Validation.Invalid state'),
          }
        }
      }
      if (!values.physicalAddress.companyAddressState) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressState = t('Validation.Required')
        } else {
          errors.physicalAddress = {
            companyAddressState: t('Validation.Required'),
          }
        }
      }

      if (!EnglishLettersPattern.test(values.physicalAddress.companyAddressCity)) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressCity = t('Validation.Invalid city')
        } else {
          errors.physicalAddress = {
            companyAddressCity: t('Validation.Invalid city'),
          }
        }
      }
      if (!values.physicalAddress.companyAddressCity) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressCity = t('Validation.Required')
        } else {
          errors.physicalAddress = {
            companyAddressCity: t('Validation.Required'),
          }
        }
      }

      if (!EnglishLettersPattern.test(values.physicalAddress.companyAddressStreet)) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressStreet = t('Validation.Invalid street')
        } else {
          errors.physicalAddress = {
            companyAddressStreet: t('Validation.Invalid street'),
          }
        }
      }
      if (!values.physicalAddress.companyAddressStreet) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressStreet = t('Validation.Required')
        } else {
          errors.physicalAddress = {
            companyAddressStreet: t('Validation.Required'),
          }
        }
      }
    }

    //Additional Info
    if (
      props.entity === TickmillCompaniesEnum.TICKMILL_EU ||
      props.entity === TickmillCompaniesEnum.TICKMILL_UK
    ) {
      if (!values?.additionalInfo?.isPerformEMIRSelected) {
        errors.additionalInfo = {
          ...errors.additionalInfo,
          isPerformEMIRSelected: t('Validation.Required'),
        }
      }

      if (!values?.additionalInfo?.isReportingCounterParty) {
        errors.additionalInfo = {
          ...errors.additionalInfo,
          isReportingCounterParty: t('Validation.Required'),
        }
      }

      if (!values?.additionalInfo?.companyLEICode) {
        errors.additionalInfo = {
          ...errors.additionalInfo,
          companyLEICode: t('Validation.Required'),
        }
      }

      if (
        values?.additionalInfo?.companyLEICode?.length > 0 &&
        values?.additionalInfo?.companyLEICode?.length !== 20
      ) {
        errors.additionalInfo = {
          ...errors.additionalInfo,
          companyLEICode: t('Validation.LEI should have 20 characters'),
        }
      }

      if (
        values?.additionalInfo?.companyLEICode &&
        !/^[a-zA-Z0-9]+$/.test(values?.additionalInfo?.companyLEICode)
      ) {
        errors.additionalInfo = {
          ...errors.additionalInfo,
          companyLEICode: t('Validation.Only alphanumeric characters are allowed'),
        }
      }

      if (!values?.additionalInfo?.registeredLEICountry?.value) {
        errors.additionalInfo = {
          ...errors.additionalInfo,
          registeredLEICountry: {
            value: t('Validation.Required'),
          },
        }
      }

      if (!values?.additionalInfo?.companyIndustry?.value) {
        errors.additionalInfo = {
          ...errors.additionalInfo,
          companyIndustry: {
            value: t('Validation.Required'),
          },
        }
      }

      if (
        values?.additionalInfo?.companyIndustry?.id === props?.otherIndustryData?.id &&
        !values?.additionalInfo?.otherIndustry
      ) {
        errors.additionalInfo = {
          ...errors.additionalInfo,
          otherIndustry: t('Validation.Required'),
        }
      }

      if (!values?.additionalInfo?.companyfinancialCounterParty?.value) {
        errors.additionalInfo = {
          ...errors.additionalInfo,
          companyfinancialCounterParty: {
            value: t('Validation.Required'),
          },
        }
      }

      if (!values?.additionalInfo?.website) {
        errors.additionalInfo = {
          ...errors.additionalInfo,
          website: t('Validation.Required'),
        }
      }
    }

    if (values?.additionalInfo?.website && validateUrl(values.additionalInfo.website)) {
      errors.additionalInfo = {
        ...errors.additionalInfo,
        website: t('Validation.Invalid website URL'),
      }
    }

    if (!values.companyEmail) {
      errors.companyEmail = t('Validation.Required')
    } else if (values.companyEmail && !EmailPattern.test(values.companyEmail)) {
      errors.companyEmail = t('Validation.Invalid email address')
    }

    if (!values.companyEmail) {
      errors.companyEmail = t('Validation.Required')
    }
    if (!values.companyName.trim()) {
      errors.companyName = t('Validation.Required')
    }

    if (!values.companyPhoneNumber) {
      errors.companyPhoneNumber = t('Validation.Required')
    }

    if (!values.companyPhoneNumberCountryCode) {
      errors.companyPhoneNumberCountryCode = t('Validation.Required')
    }
    if (!values.companyRegistrationDate) {
      errors.companyRegistrationDate = t('Validation.Required')
    }
    if (!values.companyRegistrationNumber.trim()) {
      errors.companyRegistrationNumber = t('Validation.Required')
    }
    if (!values.companyTypeId) {
      errors.companyTypeId = t('Validation.Required')
    }

    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(CompanyDetailsStep1FormUI)
