import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Field, FieldProps, useFormikContext } from 'formik'

import { PDFDocument } from '../../../global/PDFDocument/PDFDocument'
import { Button } from '../../../global/button/Button'
import { WarningIcon } from '../../../icons/WarningIcon'
import { LegalDocumentDto } from '../../../model/CompanyLegalDocumentDto'
import { Text } from '../../../ui/Typography/Typography'
import { validateIsTrue } from '../../../utils/formValidation'
import { useScrollToTop } from '../../../utils/useScrollToTop'
import { DocumentTemplate } from './DocumentTemplate'

import styles from './DocumentReadAndAgreeField.module.scss'

export interface AgreedLegalDocument {
  id: string
  openedOn: Date
  agreedOn: Date
}

interface DocumentFieldProps {
  documents: LegalDocumentDto[]
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

export interface DocumentFieldItem {
  title: string
  isAccepted: boolean
  onClick(): void
  documents: LegalDocumentDto[]
  component: React.ReactNode
}

interface FormValues {
  documents: { [key: string]: boolean }
  agreedLegalDocuments: AgreedLegalDocument[]
  readDocument: LegalDocumentDto | undefined
}

export const DocumentReadAndAgreeField: React.FC<DocumentFieldProps> = (props) => {
  const { documents = [], renderItem } = props

  const { setFieldValue } = useFormikContext<FormValues>()

  const handleDocumentModalOpen = (data: LegalDocumentDto) => {
    setFieldValue('readDocument', data)
  }

  return (
    <ReadAndAgreeField
      documents={documents}
      onDocumentModalOpen={handleDocumentModalOpen}
      renderItem={renderItem}
    />
  )
}

interface ReadAndAgreeFieldProps extends DocumentFieldProps {
  onDocumentModalOpen(document: LegalDocumentDto): void
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

const ReadAndAgreeField: React.FC<ReadAndAgreeFieldProps> = (props) => {
  const { documents = [], onDocumentModalOpen, renderItem } = props
  const [document] = documents

  const { t } = useTranslation()

  return (
    <Field name={`documents.${document.id}`} validate={validateIsTrue(t('Validation.Required'))}>
      {(props: FieldProps<boolean>) => {
        const defaultComponent = (
          <ReadAndAgree
            title={document.title || ''}
            isAccepted={props.field.value}
            onClick={() => onDocumentModalOpen(document)}
          />
        )

        return (
          <React.Fragment>
            {renderItem
              ? renderItem({
                  title: document.title || '',
                  isAccepted: props.field.value,
                  onClick: () => onDocumentModalOpen(document),
                  documents,
                  component: defaultComponent,
                })
              : defaultComponent}
          </React.Fragment>
        )
      }}
    </Field>
  )
}

interface ReadAndAgreeProps {
  title: string
  isAccepted: boolean
  onClick(): void
}

export const ReadAndAgree: React.FC<ReadAndAgreeProps> = (props) => {
  const { title, isAccepted, onClick } = props

  const { t } = useTranslation()

  return (
    <div className='is-flex is-align-items-center'>
      {isAccepted ? (
        <div className='is-flex'>
          <span className='is-flex is-align-items-center pr-1'>
            <Text>{title}</Text>
          </span>
          <Text>
            <Trans i18nKey='Sign up.has been accepted'>
              has been <span className='has-text-success'>accepted</span>
            </Trans>
          </Text>
        </div>
      ) : (
        <div className='is-flex'>
          <span className='pr-1'>
            <Button appearance='link' type='button' onClick={onClick}>
              <Text>{t('Sign up.Read & Accept')}</Text>
            </Button>
          </span>
          <span className='is-flex is-align-items-center'>
            <Text>{title}</Text>
          </span>
        </div>
      )}
    </div>
  )
}

export const ReadDocumentPage: React.FC<{
  goBackFromDoc?: () => Promise<void>
}> = ({ goBackFromDoc }) => {
  const { t } = useTranslation()
  useScrollToTop()

  const { values, setValues, setFieldValue } = useFormikContext<FormValues>()

  const [isLoading, setLoading] = useState(true)
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const readDocument = values?.readDocument

  useEffect(() => {
    if (readDocument && !isAgreedLegalDocumentExists(values.agreedLegalDocuments, readDocument)) {
      setValues({
        ...values,
        documents: {
          ...values.documents,
          [readDocument.id]: false,
        },
        agreedLegalDocuments: values.agreedLegalDocuments
          .filter(filterAgreedLegalDocumentsByDocument(readDocument))
          .concat(createAgreedLegalReadAndAgreeDocument(readDocument))
          .filter(filterAgreedLegalDocumentsDuplicates),
      })
    }
  }, [readDocument, values, setValues])

  const handleAccept = () => {
    if (readDocument?.code) {
      const [agreedLegalDocument] = values.agreedLegalDocuments.filter(
        (x) => x.id === readDocument.id
      )

      setValues({
        ...values,
        documents: {
          ...values.documents,
          [readDocument.id]: true,
        },
        agreedLegalDocuments: values.agreedLegalDocuments
          .filter(filterAgreedLegalDocumentsByDocument(readDocument))
          .concat(createAgreedLegalReadAndAgreeDocument(readDocument, agreedLegalDocument))
          .filter(filterAgreedLegalDocumentsDuplicates),
      })
      handleDocumentModalClose()
    }
  }

  const handleDocumentModalClose = () => {
    if (goBackFromDoc) {
      goBackFromDoc()
    } else {
      setFieldValue('readDocument', undefined)
    }
  }

  const handlePdfDocumentLoadSuccess = () => {
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }

  const docNote = useMemo(() => {
    const docNote = t('Validation.Please read and accept the') + ' '
    const title = readDocument?.title
    return docNote + title
  }, [readDocument?.title, t])

  if (readDocument?.code) {
    return (
      <DocumentTemplate title={readDocument.title} goBack={handleDocumentModalClose}>
        <div className={styles.documentNote}>
          <WarningIcon color='warning' />
          <span>{docNote}</span>
        </div>
        <PDFDocument
          setScrolledToBottom={setScrolledToBottom}
          file={readDocument.url}
          onLoadSuccess={handlePdfDocumentLoadSuccess}
        />
        <div className={styles.agreeButtons}>
          <Button size='L' appearance='secondary' onClick={handleDocumentModalClose}>
            {t('Cancel')}
          </Button>
          <Button
            size='L'
            appearance='primary'
            onClick={handleAccept}
            disabled={!scrolledToBottom || isLoading}
          >
            {t('Accept')}
          </Button>
        </div>
      </DocumentTemplate>
    )
  }

  return null
}

const isAgreedLegalDocumentExists = (
  agreedLegalDocuments: AgreedLegalDocument[] = [],
  document: LegalDocumentDto
) => {
  const [agreedLegalDocument] = agreedLegalDocuments.filter((x) => x.id === document.id)
  return !!agreedLegalDocument
}

const filterAgreedLegalDocumentsByDocument =
  (document: LegalDocumentDto) => (x: AgreedLegalDocument) => {
    return x.id !== document.id
  }

const createAgreedLegalReadAndAgreeDocument = (
  document: LegalDocumentDto,
  agreedLegalDocument?: AgreedLegalDocument
) => {
  return {
    id: document.id,
    openedOn: agreedLegalDocument?.openedOn || new Date(),
    agreedOn: new Date(),
  }
}

const filterAgreedLegalDocumentsDuplicates = (
  value: AgreedLegalDocument,
  index: number,
  self: AgreedLegalDocument[]
) => {
  return index === self.findIndex((t) => t.id === value.id && t.id === value.id)
}
