import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../global/button/Button'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DotsIcon } from '../../../icons/DotsIcon'
import { NameDto } from '../../../model/NameDto'
import { CurrencyBalance } from '../../../model/TradingAccount'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { TextSmall } from '../../../ui/Typography/Typography'
import { TradingAccountRestrictions, isRestricted } from '../../../utils/trading-account.utils'

import styles from './TradingAccountBalanceCard.module.scss'

interface TradingAccountBalanceCardProps {
  balance: CurrencyBalance
  restrictions?: NameDto[]
  handleSetSelectedTradingAccount(): void
  initiateTransfer: () => void
  setModalVisible(v: boolean): void
}

export const TradingAccountBalanceCard: React.FC<TradingAccountBalanceCardProps> = ({
  balance,
  handleSetSelectedTradingAccount,
  initiateTransfer,
  restrictions,
  setModalVisible,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const isTransferFromRestricted = isRestricted(
    TradingAccountRestrictions.TRANSFER_FROM,
    restrictions
  )

  const handleRestrictionModalOpen = () => {
    if (isTransferFromRestricted) {
      setModalVisible(true)
    }
  }

  return (
    <div className={styles.card}>
      <TextSmall className='text-secondary'>
        {balance.currency}
        <span className='is-pulled-right'>
          <span onClick={handleSetSelectedTradingAccount} className='has-cursor-pointer'>
            <DotsIcon />
          </span>
        </span>
      </TextSmall>
      <h2 className='mb-4 mt-4 has-text-weight-semibold'>
        {formatMoney(balance.balance, balance.currency)}
      </h2>

      <div className='mb-4'>
        {isTransferFromRestricted && (
          <AlertIconWrapper fullWidth spaced isActive={isTransferFromRestricted}>
            <Button
              onClick={handleRestrictionModalOpen}
              size='M'
              className={styles.button}
              appearance='secondary'
              state='disabled'
            >
              {t('Transfer')}
            </Button>
          </AlertIconWrapper>
        )}
        {!isTransferFromRestricted && (
          <Button
            onClick={initiateTransfer}
            size='M'
            className={styles.button}
            appearance='secondary'
          >
            {t('Transfer')}
          </Button>
        )}
      </div>
    </div>
  )
}
