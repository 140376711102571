import { useEffect, useState } from 'react'

export const useCountdown = (
  initial: number
): {
  counter: number
  setCounter: (value: number) => void
} => {
  const [counter, setCounter] = useState(initial)

  useEffect(() => {
    const timer = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1)
      } else {
        clearInterval(timer)
      }
    }, 1000)
    return () => clearInterval(timer)
  }, [counter])

  return { counter, setCounter }
}
