import { TFunction } from 'i18next'

import { NameDto } from '../model/NameDto'

export const normalizeSortLabel = (
  t: TFunction,
  data: NameDto<string>[],
  labelId?: string | null
): string => {
  if (!labelId) {
    return `${t('SortBy')}`
  }
  const dataLabel = data.find((x) => x.id === labelId)?.name
  return `${t('SortBy')}: ${dataLabel}`
}

export const toSentenceCase = (str?: string | null): string | undefined | null => {
  if (!str) {
    return str
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}
