import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Loading } from '../../global/Loading/Loading'
import { Modal } from '../../global/modal/Modal'
import { AccountGroupType } from '../../model/AccountGroupType'
import { MasterTransactionDto } from '../../model/MasterTransactionDto'
import { PlatformTypeEnum } from '../../model/PlatformTypeEnum'
import { TransferTypeEnum } from '../../model/TransactionDto'
import { CurrencyType, WalletTypeEnum } from '../../model/WalletDto'
import { Operator, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { TransferSuccessModal } from '../Wallets/WalletTransfer/TransferSuccessModal'
import {
  ConversionDirection,
  ConversionForm,
  ConversionType,
  CreateConversionFormSubmitValues,
  PresetAccount,
} from './ConversionForm'

import styles from '../Wallets/WalletTransfer/TransferForm.module.scss'

const useConversionFetch = () => {
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => {
    return Promise.all([
      apiClient.getWallets({
        walletTypeId: WalletTypeEnum['ETD'],
      }),
      apiClient.getTransactionLimits(),
      apiClient.getTradingAccounts({
        pageSize: 150,
        search: {
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: PlatformTypeEnum.CQGCAST, // it's for ETD
            operator: Operator.EQUAL,
          },
          Status: {
            value: 1,
            operator: Operator.EQUAL,
          },
        },
      }),
      apiClient.getCurrencies(PlatformTypeEnum.CQGCAST),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { data, isLoading } = useFetchOne(callback)
  const [wallets = [], limits = [], tradingAccounts, availableTACurrencies = []] = data || []

  return {
    wallets,
    limits,
    tradingAccounts: tradingAccounts?.items || [],
    availableTACurrencies,
    isLoading,
  }
}

export const ConversionPage = () => {
  useScrollToTop()

  const apiClient = useApiClient(ClientApiClient)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [tradingAccountId] = useState<string | undefined>(state?.tradingAccountId)
  const [currency] = useState<CurrencyType | undefined>(state?.currency)
  const [walletId] = useState<string | undefined>(state?.walletId)

  const [currentRate, setCurrentRate] = useState(0)
  const [pageState, setPageState] = useState<'loading' | 'submit' | 'initial'>('initial')
  const [transferType, setTransferType] = useState<TransferTypeEnum>(
    TransferTypeEnum.WALLET_TO_WALLET
  )
  const [masterTransaction, setMasterTransaction] = useState<MasterTransactionDto | undefined>()
  const [rateLoading, setRateLoadng] = useState(false)

  const { wallets, tradingAccounts, availableTACurrencies, limits, isLoading } =
    useConversionFetch()

  const getPresetAccount = (): PresetAccount | undefined => {
    if (tradingAccountId && currency) {
      const account = tradingAccounts?.find((x) => x.id === tradingAccountId)
      if (account) {
        return {
          account,
          currency,
          type: ConversionType.TradingAccount,
          displayType: t('Trading Account.Trading Account'),
        }
      }
    }
    if (walletId) {
      const wallet = wallets.find((item) => item.id === walletId)
      if (wallet) {
        return {
          wallet,
          type: ConversionType.Wallet,
          displayType: t('Wallet.Wallet'),
        }
      }
    }
  }

  const closeModal = () => {
    navigate('/dashboard/traders-room/transaction-history')
  }

  const onSubmit = async (values: CreateConversionFormSubmitValues) => {
    try {
      setPageState('loading')
      if (values.fromType === 'wallet' && values.toType === 'wallet') {
        const isFrom = values.direction === ConversionDirection.From
        const conversionDirection = {
          [isFrom ? 'FromAmount' : 'ToAmount']: isFrom ? values.fromAmount : values.toAmount,
        }
        const response = await apiClient.transferWalletToWallet({
          ...conversionDirection,
          caller: 'ca',
          FromWalletId: values.fromId,
          ToWalletId: values.toId,
          clientIpAddress: '',
          referrer_url: '',
        })
        setTransferType(TransferTypeEnum.WALLET_TO_WALLET)
        setMasterTransaction(response)
        setPageState('submit')
      } else if (
        values.fromType === 'tradingAccount' &&
        values.toType === 'tradingAccount' &&
        values.fromCurrencyId &&
        values.toCurrencyId
      ) {
        const isFrom = values.direction === ConversionDirection.From
        const conversionDirection = {
          [isFrom ? 'fromAmount' : 'toAmount']: isFrom ? values.fromAmount : values.toAmount,
        }
        const response = await apiClient.convertTradingAccountBalance({
          ...conversionDirection,
          tradingAccountId: values.fromId || values.toId,
          fromCurrencyId: values.fromCurrencyId,
          toCurrencyId: values.toCurrencyId,
          comment: null,
        })
        setMasterTransaction(response)
        setTransferType(TransferTypeEnum.TA_TO_TA)
        setMasterTransaction(response)
        setPageState('submit')
      } else {
        setPageState('initial')
      }
    } catch (error: unknown) {
      console.error(error)
      setPageState('initial')
    }
  }

  const getRate = async (BaseCcy: CurrencyType, QuoteCcy: CurrencyType) => {
    try {
      setRateLoadng(true)
      const { rate } = await apiClient.getExchangeRate({
        BaseCcy,
        QuoteCcy,
        direction: 'sell',
      })
      setCurrentRate(rate)
    } finally {
      setRateLoadng(false)
    }
  }

  return (
    <Loading showLoadingIcon isLoading={pageState === 'loading'}>
      {pageState === 'initial' && (
        <Loading showLoadingIcon isLoading={isLoading}>
          <ConversionForm
            limits={limits}
            rateLoading={rateLoading}
            setState={setPageState}
            onSubmit={onSubmit}
            fetchRate={getRate}
            exchangeRate={currentRate}
            wallets={wallets}
            tradingAccounts={tradingAccounts}
            availableTACurrencies={availableTACurrencies}
            fromAccount={getPresetAccount()}
          />
        </Loading>
      )}
      {pageState === 'submit' && (
        <Modal
          closeModal={() => setPageState('initial')}
          cardClassName={styles.modalContainer}
          render={() => (
            <TransferSuccessModal
              isConversion
              masterTransaction={masterTransaction}
              onCancel={closeModal}
              transferType={transferType}
            />
          )}
        />
      )}
    </Loading>
  )
}
