import React from 'react'
import classNames from 'classnames'

import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { ChevronLeftIcon } from '../../icons/ChevronLeftIcon'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'
import { WalletDto } from '../../model/WalletDto'
import { hasChildren } from '../../utils/hasChildren'
import { isZero } from '../../utils/validations'
import { CarouselChild } from './CarouselItem'
import { UseCarouselProps, useCarousel } from './useCarousel'

import styles from './Carousel.module.scss'

export type CarouselProps = {
  interval?: number
  slidesPresented?: number
  className?: string
  showArrows?: boolean
  hideDots?: boolean
  showPreview?: boolean
  loop?: boolean
} & RenderProps

type RenderProps =
  | { children: (props?: CarouselHandlersProps) => React.ReactNode }
  | { children: React.ReactNode }

const useCarouselCalculate = (props: CarouselProps) => {
  const { slidesPresented = 1, showArrows } = props

  const children = getChildren(props)
  const sliders = React.Children.toArray(children())
  const length = sliders.length || 1
  const slideActiveId = Math.min(length, slidesPresented)

  const beforeIndices = makeIndices(length - 1, -1, slideActiveId)
  const afterIndices = makeIndices(0, +1, slideActiveId)

  return {
    slidesPresented,
    children,
    length,
    slideActiveId,
    beforeIndices,
    afterIndices,
    isLeftArrowVisible: (active: number) => showArrows && active > 0,
    isRightArrowVisible: (active: number, length: number) => showArrows && active < length - 1,
  }
}

export const Carousel: React.FC<CarouselProps> = (props) => {
  const { interval = 500, className, showArrows, hideDots, showPreview, loop } = props

  const isArabic = useArabicSessionLanguage()

  const carouselCalculateProps = useCarouselCalculate(props)
  const {
    length,
    slideActiveId,
    beforeIndices,
    afterIndices,
    isLeftArrowVisible,
    isRightArrowVisible,
  } = carouselCalculateProps

  const carouselProps = useCarousel({
    length,
    interval,
    options: {
      slidesPresented: slideActiveId,
    },
    invert: isArabic,
    showPreview,
    loop,
  })
  const { active, setActive, handlers, style } = carouselProps
  const isFirstSlide = isZero(active)
  const isLastSlide = active === length - 1

  const handleActive = (sliderId: number) => {
    setActive(sliderId)
  }

  const handleScrollPrev = () => {
    if (isFirstSlide) {
      setActive(length - 1)
    } else {
      setActive(active - 1)
    }
  }

  const handleScrollNext = () => {
    if (isLastSlide) {
      setActive(0)
    } else {
      setActive(active + 1)
    }
  }

  const slides = getSlides(
    { ...props, isFirstSlide, isLastSlide, carouselLength: length },
    carouselProps,
    {
      onScrollActive: handleActive,
      onScrollNext: handleScrollNext,
      onScrollPrev: handleScrollPrev,
    }
  )

  if (length > 0) {
    return (
      <div className={classNames('is-flex-direction-column', styles.carouselWrapper)}>
        <div
          className={classNames(styles.carouseInnerlWrapper, {
            [styles.spaceLeft]: showArrows && !isLeftArrowVisible(active),
            [styles.spaceRight]: showArrows && !isRightArrowVisible(active, length),
          })}
        >
          {isLeftArrowVisible(active) && <CarouselLeftArrowButton onClick={handleScrollPrev} />}
          <div className={classNames(styles.carousel, className)}>
            <div className={styles.carouselContent} {...handlers} style={style}>
              {beforeIndices.map((i) => (
                <CarouselChild key={i}>{slides[i]}</CarouselChild>
              ))}
              {slides.map((slide, index) => (
                <CarouselChild key={index}>{slide}</CarouselChild>
              ))}
              {afterIndices.map((i) => (
                <CarouselChild key={i}>{slides[i]}</CarouselChild>
              ))}
            </div>
          </div>
          {isRightArrowVisible(active, length) && (
            <CarouselRightArrowButton onClick={handleScrollNext} />
          )}
        </div>
        {!hideDots && <CarouselDots active={active} slides={slides} onActive={handleActive} />}
      </div>
    )
  }

  return null
}

interface CarouselDotsProps {
  active?: number | string
  slides?: React.ReactNode[]
  onActive?(sliderId: number): void
}

export const CarouselDots: React.FC<CarouselDotsProps> = (props) => {
  const { active, slides = [], onActive } = props

  const handleActive = (sliderId: number) => {
    onActive && onActive(sliderId)
  }

  return (
    <ol className={styles.carouselIndicators}>
      {slides.map((_, index) => (
        <li
          onClick={() => handleActive(index)}
          key={index}
          className={classNames(styles.carouselIndicator, {
            [styles.active]: active === index,
          })}
        />
      ))}
    </ol>
  )
}

export const CarouselNumberDots: React.FC<CarouselDotsProps> = (props) => {
  const { active, slides = [], onActive } = props

  const handleActive = (sliderId: number) => {
    onActive && onActive(sliderId)
  }

  return (
    <ol className={styles.carouselNumberIndicators}>
      {slides.map((_, index) => (
        <li
          onClick={() => handleActive(index)}
          key={index}
          className={classNames(styles.carouselNumberIndicator, {
            [styles.active]: active === index,
          })}
        >
          {index + 1}
        </li>
      ))}
    </ol>
  )
}

interface CarouselArrowButtonProps {
  className?: string
  onClick?(): void
}

const CarouselLeftArrowButton: React.FC<CarouselArrowButtonProps> = (props) => {
  const { onClick = () => {} } = props
  return <ChevronLeftIcon onClick={onClick} className={classNames(styles.arrow, props.className)} />
}

const CarouselRightArrowButton: React.FC<CarouselArrowButtonProps> = (props) => {
  const { onClick = () => {} } = props
  return (
    <ChevronRightIcon onClick={onClick} className={classNames(styles.arrow, props.className)} />
  )
}

const makeIndices = (start: number, delta: number, num: number) => {
  const indices: Array<number> = []

  while (indices.length < num) {
    indices.push(start)
    start += delta
  }

  return indices
}

interface CarouselScrollHandlersProps {
  onScrollPrev(): void
  onScrollNext(): void
  onScrollActive(slideId: number): void
}

const getSlides = (
  props: CarouselProps & {
    isFirstSlide: boolean
    isLastSlide: boolean
    carouselLength: number
  },
  carouselProps: UseCarouselProps,
  handlers: CarouselScrollHandlersProps
) => {
  const { active } = carouselProps

  const initialCarouselHandlers = {
    carouselLength: props.carouselLength,
    isFirstSlide: props.isFirstSlide,
    isLastSlide: props.isLastSlide,
    active: 0,
    items: [],
    ...handlers,
  }

  const children = getChildren(props)
  const items = React.Children.toArray(getChildren(props)(initialCarouselHandlers))

  const slides = React.Children.toArray(
    children({
      ...initialCarouselHandlers,
      active,
      items,
    })
  )

  return slides
}

export interface CarouselHandlersProps extends CarouselScrollHandlersProps {
  isFirstSlide: boolean
  isLastSlide: boolean
  carouselLength: number
  active: number
  items: React.ReactNode[]
  defaultWallet?: WalletDto | undefined
  onScrollPrev(): void
  onScrollNext(): void
  onScrollActive(slideId: number): void
}

const getChildren =
  (props: CarouselProps) =>
  (handlersProps?: CarouselHandlersProps): React.ReactNode => {
    if (hasChildren<CarouselProps>(props)) {
      return props.children(handlersProps) as React.ReactNode
    }

    if (props?.children) {
      return props?.children as React.ReactNode
    }

    return undefined
  }
