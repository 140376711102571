import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { InformationModal } from '../modal/InformationModal'
import { Modal } from '../modal/Modal'

interface Props {
  onCloseModal(): void
}
export const CoolOffPeriodModal: FC<Props> = ({ onCloseModal }) => {
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onCloseModal}
      render={() => (
        <InformationModal
          onCancel={onCloseModal}
          title={t('Restricted Access')}
          onCancelText={t('Got it!')}
        >
          <p className='text'>
            {t(
              'Access is restricted to this area due to you failing your appropriateness test 3 times'
            )}
          </p>
        </InformationModal>
      )}
    />
  )
}
