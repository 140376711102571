import { useEffect } from 'react'

type ScrollToTopProps = number | string | {} | boolean | []

export const scrollToTop = (options?: ScrollToOptions): void => {
  window.scrollTo({ top: 0, behavior: 'smooth', ...options })
}

export const useScrollToTop = (effect?: ScrollToTopProps): null => {
  useEffect(() => {
    // Prevent the browser from restoring the scroll position when navigating back,
    // allowing scrollToTop to work without interference.
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    scrollToTop()
    return () => {
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'auto'
      }
    }
  }, [effect])

  return null
}
