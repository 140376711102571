import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { InformationModal } from '../global/modal/InformationModal'
import { Modal } from '../global/modal/Modal'

interface Props {
  onCancel(): void
}

export const SubscriptionCQGInfoModal: React.FC<Props> = (props) => {
  const { onCancel } = props

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onCancel}
      render={({ closeModal }) => (
        <InformationModal
          title={t('Subscriptions.CQG Integrated Client')}
          onCancelText={t('Got it!')}
          onCancel={closeModal}
        >
          <p className='mb-2'>
            {t('Subscriptions.CQGs Flagship Product - accurate global market data')}
          </p>
          <p>
            <Trans i18nKey='Subscriptions.Additional fees may apply. For more Information on CQG Integrated client pricing'>
              Additional fees may apply. For more Information on CQG Integrated client pricing and
              options or features click
              <a href='https://www.cqg.com/products/cqg-integrated-client' target='_blank'>
                here
              </a>
              or
              <a
                href='https://partners.cqg.com/marketing-resources/monthly-price-sheet'
                target='_blank'
              >
                here
              </a>
              .
            </Trans>
          </p>
        </InformationModal>
      )}
    />
  )
}
