import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikProps, withFormik } from 'formik'

import { Loading } from '../../../../../global/Loading/Loading'
import { Button } from '../../../../../global/button/Button'
import { useArabicSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { createFormPasswordField } from '../../../../../global/formField/FormPasswordField'
import { BackIcon } from '../../../../../icons/BackIcon'
import { ForwardIcon } from '../../../../../icons/ForwardIcon'
import { usePasswordValidation } from '../../../../../ui/PasswordValidation/PasswordValidation'
import { Text, TextH3 } from '../../../../../ui/Typography/Typography'

import styles from '../Generic.module.scss'

export interface PersonalDetailsStep5FormValues {
  firstName: string
  password: string
  confirmPassword: string
}

const PasswordFormField = createFormPasswordField<PersonalDetailsStep5FormValues>()

const PersonalDetailsStep5FormUI: React.FC<
  FormikProps<PersonalDetailsStep5FormValues> & OuterProps
> = (props) => {
  const { handleSubmit, values, isSubmitting, leadData } = props
  const { t } = useTranslation()
  const { password, confirmPassword } = values

  const { isPasswordValid, validationComponent } = usePasswordValidation({
    password,
    confirmPassword,
    leadData,
  })

  const isArabic = useArabicSessionLanguage()

  return (
    <React.Fragment>
      <Form className={styles.form} onSubmit={handleSubmit}>
        <TextH3>{t('Password')}</TextH3>
        <Text className='pb-2'>
          {t(
            'Sign up.Please create your new password, ensuring you use numbers, uppercase and lowercase letters.'
          )}
        </Text>
        <PasswordFormField
          name={'password'}
          className='mt-2'
          label={t('Sign up.Enter Password')}
          showLabel
          type='password'
          required
        />
        <PasswordFormField
          name={'confirmPassword'}
          className='mt-2'
          label={t('Confirm Password')}
          showLabel
          type='password'
          required
        />
        {validationComponent}

        <Button
          className={styles.button}
          type='submit'
          appearance='primary'
          size='L'
          renderRightIcon={() => (
            <span className='is-flex is-align-items-center'>
              {isSubmitting ? null : isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
            </span>
          )}
          loading={isSubmitting}
          disabled={isSubmitting || !isPasswordValid}
        >
          <span>{t('Next')}</span>
        </Button>
      </Form>
    </React.Fragment>
  )
}

interface OuterProps {
  initialValues: PersonalDetailsStep5FormValues | undefined
  leadData: {
    id: string
    token: string
  }
  onSubmit(values: PersonalDetailsStep5FormValues): void
}

export const PersonalDetailsStep5Form = withFormik<OuterProps, PersonalDetailsStep5FormValues>({
  mapPropsToValues: ({ initialValues }) => {
    return {
      firstName: initialValues?.firstName || '',
      password: initialValues?.password || '',
      confirmPassword: initialValues?.password || '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
})(PersonalDetailsStep5FormUI)
