import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { BackIcon } from '../../icons/BackIcon'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'
import { ForwardIcon } from '../../icons/ForwardIcon'
import { Text, TextH3, TextStrong } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { TickmillCompaniesEnum } from '../../utils/companyName.utils'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { ExperiencedClassificationTest } from './ExperiencedClassificationTest/ExperiencedClassificationTest'
import ProfessionalClassificationTest from './ProfessionalClassificationTest/ProfessionalClassificationTest'

import styles from './ClientClassificationPage.module.scss'

const PolishClassificationTest: React.FC<ClassificationProps> = ({
  selectedTest,
  setSelectedTest,
  setAlreadyUploadedModal,
}) => {
  const { t } = useTranslation()

  if (selectedTest === 'Professional') {
    return (
      <ProfessionalClassificationTest
        handleOkOnClick={() => setSelectedTest(undefined)}
        setAlreadyUploadedModal={setAlreadyUploadedModal}
      />
    )
  } else if (selectedTest === 'Experienced') {
    return (
      <ExperiencedClassificationTest
        handleOkOnClick={() => setSelectedTest(undefined)}
        setAlreadyUploadedModal={setAlreadyUploadedModal}
      />
    )
  }

  return (
    <>
      <div className={styles.header} onClick={() => setSelectedTest('Professional')}>
        <TextStrong isParagraph>
          {t('Profile.Application Form to be Classified as a Professional Client')}
        </TextStrong>
        <ChevronRightIcon />
      </div>
      <div className={styles.header} onClick={() => setSelectedTest('Experienced')}>
        <TextStrong isParagraph>
          {t('Profile.Application Form to be Classified as an Experienced Retail Client')}
        </TextStrong>
        <ChevronRightIcon />
      </div>
    </>
  )
}

type ClassificationTestType = 'Professional' | 'Experienced' | undefined

interface ClassificationProps {
  selectedTest: ClassificationTestType | undefined
  setSelectedTest: (selectedTest: ClassificationTestType) => void
  setAlreadyUploadedModal: (visible: boolean) => void
}

const ClassificationTest: React.FC<ClassificationProps> = ({
  selectedTest,
  setSelectedTest,
  setAlreadyUploadedModal,
}) => {
  const { t } = useTranslation()

  if (selectedTest) {
    return (
      <ProfessionalClassificationTest
        handleOkOnClick={() => setSelectedTest(undefined)}
        setAlreadyUploadedModal={setAlreadyUploadedModal}
      />
    )
  }
  return (
    <div
      className={classNames(styles.header, 'is-clickable mt-4')}
      onClick={() => setSelectedTest('Professional')}
    >
      <TextStrong isParagraph>
        {t('Profile.Application Form to be Classified as a Professional Client')}
      </TextStrong>
      <ChevronRightIcon />
    </div>
  )
}
export const ClientClassificationPage = () => {
  useScrollToTop()
  const [selectedTest, setSelectedTest] = useState<ClassificationTestType>()
  const [alreadyUploadedModal, setAlreadyUploadedModal] = useState(false)

  const { account } = useAccountReadContext()

  const isPolish =
    account?.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_EU &&
    !!account?.addresses.length &&
    account.addresses[0].country?.id === 'POL'

  const handleSetSelectedTest = (testType: ClassificationTestType) => {
    setSelectedTest(testType)
  }

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const handleCloseErrorModal = () => setAlreadyUploadedModal(false)

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerGray}>
        <div className={styles.title}>
          {selectedTest && (
            <span onClick={() => setSelectedTest(undefined)}>
              {isArabic ? <ForwardIcon /> : <BackIcon />}
            </span>
          )}
          <TextH3>{t('Profile.Client Classification')}</TextH3>
        </div>
      </div>
      {isPolish ? (
        <PolishClassificationTest
          setSelectedTest={handleSetSelectedTest}
          selectedTest={selectedTest}
          setAlreadyUploadedModal={setAlreadyUploadedModal}
        />
      ) : (
        <ClassificationTest
          setSelectedTest={handleSetSelectedTest}
          selectedTest={selectedTest}
          setAlreadyUploadedModal={setAlreadyUploadedModal}
        />
      )}
      {alreadyUploadedModal && (
        <Modal
          closeModal={handleCloseErrorModal}
          render={() => (
            <InformationModal
              onCancel={handleCloseErrorModal}
              onCancelText={t('Got It')}
              title={t('Upload failed')}
            >
              <Text isParagraph>{t('The document has been already uploaded')}</Text>
            </InformationModal>
          )}
        />
      )}
    </div>
  )
}
