import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ClientBaseIcon } from '../../icons/ClientBaseIcon'
import { EarnFromTradesIcon } from '../../icons/EarnFromTradesIcon'
import { ManageFundsIcon1 } from '../../icons/ManageFundsIcon1'
import { ManageFundsIcon2 } from '../../icons/ManageFundsIcon2'
import { RewardsIcon } from '../../icons/RewardsIcon'
import { Carousel } from '../../ui/Carousel/Carousel'
import { CarouselChild } from '../../ui/Carousel/CarouselItem'
import { TextLarge } from '../../ui/Typography/Typography'

import styles from './IBCarousel.module.scss'

export const IBMultiTierCarousel: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Carousel loop className={styles.carousel} interval={4000}>
      <CarouselChild className={styles.carouselChild}>
        <div className={styles.iconContainer}>
          <div className={styles.subWrapper}>
            <ClientBaseIcon className={styles.clientBaseIcon} />
          </div>
          <div className={styles.subWrapper}>
            <ClientBaseIcon className={styles.clientBaseIcon} />
          </div>
          <div className={styles.subWrapper}>
            <ClientBaseIcon className={styles.clientBaseIcon} />
          </div>
        </div>
        <h2 className={styles.title}>{t('IB.MultiTierCarousel.Build your client base')}</h2>
        <TextLarge
          isParagraph
          dangerouslySetInnerHTML={{
            __html: t('IB.MultiTierCarousel.Recommend Tickmill to other traders', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </CarouselChild>
      <CarouselChild className={styles.carouselChild}>
        <div className={styles.iconWrapper}>
          <EarnFromTradesIcon className={styles.icon} />
        </div>
        <h2 className={styles.title}>{t('IB.MultiTierCarousel.Earn from trades')}</h2>
        <TextLarge isParagraph>
          {t('IB.The more your clients trade, the bigger is your reward. The reward is calculated')}
        </TextLarge>
      </CarouselChild>
      <CarouselChild className={styles.carouselChild}>
        <div className={classNames(styles.iconWrapper, styles.dualIcon)}>
          <ManageFundsIcon1 className={styles.icon} />
          <ManageFundsIcon2 className={styles.icon} />
        </div>
        <h2 className={styles.title}>{t('IB.MultiTierCarousel.Manage your funds')}</h2>
        <TextLarge
          isParagraph
          dangerouslySetInnerHTML={{
            __html: t('IB.MultiTierCarousel.Access and manage', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </CarouselChild>
      <CarouselChild className={styles.carouselChild}>
        <div className={classNames(styles.iconWrapper, styles.smallPad)}>
          <RewardsIcon className={styles.icon} />
        </div>
        <h2 className={styles.title}>
          {t('IB.MultiTierCarousel.Earn cash prizes for your loyalty')}
        </h2>
        <TextLarge
          isParagraph
          dangerouslySetInnerHTML={{
            __html: t('IB.MultiTierCarousel.Join Tickmills IB Loyalty Program', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
        <TextLarge isParagraph className={styles.terms}>
          {t('IB.MultiTierCarousel.terms')}
        </TextLarge>
      </CarouselChild>
    </Carousel>
  )
}
