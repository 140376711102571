import React from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'

import { FormatCSVIcon } from '../../icons/FormatCSVIcon'
import { FormatExcelIcon } from '../../icons/FormatExcelIcon'
import { FormatPDFIcon } from '../../icons/FormatPDFIcon'
import { TextSmall } from '../../ui/Typography/Typography'
import { SelectableModal } from '../field/SelectableModal'

import styles from './ExportModal.module.scss'

interface ExportModalProps {
  csvData: object[]

  onCloseModal(): void

  onExportToPdf(): void

  onExportToCSV(): void

  onExportToExcel(): void

  fileName?: string
}

export const ExportModal: React.FC<ExportModalProps> = ({
  onCloseModal,
  onExportToCSV,
  onExportToPdf,
  onExportToExcel,
  csvData,
  fileName,
}) => {
  const { t } = useTranslation()
  return (
    <SelectableModal
      title={t('Export')}
      onCancel={onCloseModal}
      renderOptions={() => (
        <React.Fragment>
          <div className='control'>
            <div>
              <button onClick={onExportToExcel} className={styles.button}>
                <TextSmall className={styles.iconWrap}>
                  <FormatExcelIcon /> {t('Export as Excel')}
                </TextSmall>
              </button>
            </div>
            <button onClick={onExportToPdf} className={styles.button}>
              <TextSmall className={styles.iconWrap}>
                <FormatPDFIcon /> {t('Export as PDF')}
              </TextSmall>
            </button>
            <CSVLink
              title={t('Transaction History')}
              data={csvData}
              filename={fileName || 'transaction_report'}
              onClick={onExportToCSV}
              className={styles.csvButton}
            >
              <button onClick={onExportToCSV} className={styles.button}>
                <TextSmall className={styles.iconWrap}>
                  <FormatCSVIcon /> {t('Export as CSV')}
                </TextSmall>
              </button>
            </CSVLink>
          </div>
        </React.Fragment>
      )}
    />
  )
}
