import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { ExternalLink } from '../global/ExternalLink'
import { Button } from '../global/button/Button'
import { CloseIcon } from '../icons/CloseIcon'
import { NotificationIcon } from '../icons/NotificationIcon'
import { PinIcon } from '../icons/PinIcon'
import { WarningIcon } from '../icons/WarningIcon'
import { Notification, NotificationType } from '../model/Notification'
import { AlertIconWrapper } from '../ui/AlertIconWrapper/AlertIconWrapper'
import { Text, TextSmall } from '../ui/Typography/Typography'
import { getAgeOfDate } from '../utils/date.utils'
import { useWindowResize } from '../utils/domUtils'
import { NotificationText, useNotificationUnread } from '../utils/notifications'
import { isFullUrl } from '../utils/validations'

import styles from './NotificationPreview.module.scss'

export interface NotificationPreviewProps {
  notification: Notification
  open?: () => void
  remove?: () => void
  read?: () => void
  hide?: () => void
  externalMode?: boolean
  className?: string
  expanded?: boolean
  cardId?: string
}

const SideInfo = ({
  isPinned,
  externalMode,
  startDateTime,
}: {
  isPinned: boolean
  externalMode?: boolean
  startDateTime: string
}) => {
  return isPinned && !externalMode ? (
    <PinIcon size={22} color='primary' />
  ) : (
    <div>
      <TextSmall isParagraph className={styles.date}>
        {getAgeOfDate(startDateTime)}
      </TextSmall>
    </div>
  )
}

export const NotificationPreview: React.FC<NotificationPreviewProps> = (props) => {
  const { open, remove, externalMode, notification, read, hide, expanded, cardId } = props
  const { imageUrl, isPinned, ctaText, ctaUrl, isRead } = notification
  const { startDateTime, title, notificationType, text } = notification
  const { refreshAllNotifications } = useNotificationUnread()
  const isMobile = useWindowResize()
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    if (ctaUrl) {
      const prefix = !isFullUrl(ctaUrl) ? 'https://' : ''
      setUrl(`${prefix}${ctaUrl}`)
    }
  }, [ctaUrl])

  const showCta = ctaText && url
  const allowDelete = (!isPinned && isRead && !!remove) || !!hide
  const overflown = text.length > 200 && !!open
  const showSideInfo = isMobile && !hide

  const openNotification = () => {
    if (!open) {
      return
    }
    if (overflown) {
      return open()
    }
    if (read) {
      read()
    }
    if (externalMode) {
      refreshAllNotifications()
    }
  }

  const NotificationIconPicker = () => {
    if (notificationType.id === NotificationType.Warning) {
      return (
        <AlertIconWrapper isActive={!isRead && !!open}>
          <WarningIcon size={22} color='warning' />
        </AlertIconWrapper>
      )
    }
    if (notificationType.id === NotificationType.Error) {
      return (
        <AlertIconWrapper isActive={!isRead && !!open}>
          <WarningIcon size={22} color='error' />
        </AlertIconWrapper>
      )
    }
    if (notificationType.id === NotificationType.Platform) {
      return (
        <AlertIconWrapper isActive={!isRead && !!open}>
          <WarningIcon size={22} color='error' />
        </AlertIconWrapper>
      )
    }
    return (
      <AlertIconWrapper isActive={!isRead && !!open}>
        <NotificationIcon size={22} />
      </AlertIconWrapper>
    )
  }

  return (
    <div
      className={classNames(styles.notification, props.className, {
        [styles.external]: externalMode,
        [styles.expanded]: expanded,
        [styles.warning]: notificationType.id === NotificationType.Warning,
        [styles.error]: notificationType.id === NotificationType.Error,
      })}
      id={cardId}
    >
      {!!imageUrl && (
        <img
          src={imageUrl}
          alt={title}
          className={classNames(styles.promoImage, {
            [styles.expanded]: !open,
          })}
        />
      )}
      {!imageUrl && <NotificationIconPicker />}
      <div className={styles.textBox}>
        <div
          className={classNames(styles.titleBox, {
            [styles.promo]: notificationType.id === NotificationType.Promotional,
          })}
        >
          <p
            data-test='open-notification'
            onClick={openNotification}
            className={classNames(styles.title, {
              [styles.active]: open,
            })}
          >
            {title}
          </p>
          <div className={styles.infoWrap}>
            {allowDelete && (
              <div
                data-test='remove-notification'
                className={styles.closeIcon}
                onClick={hide || remove}
              >
                <CloseIcon size={14} />
              </div>
            )}
            {showSideInfo && (
              <div className={styles.datePin}>
                <SideInfo
                  isPinned={isPinned}
                  externalMode={externalMode}
                  startDateTime={startDateTime}
                />
              </div>
            )}
          </div>
        </div>
        {!!imageUrl && (
          <img
            src={imageUrl}
            alt={title}
            className={classNames(styles.mobileImage, {
              [styles.expanded]: !open,
            })}
          />
        )}
        <Text isParagraph className={styles.text}>
          <NotificationText
            overflown={overflown}
            text={text}
            open={open}
            className={styles.readMore}
          />
        </Text>
        {showCta && (
          <div className={styles.buttonBox}>
            <ExternalLink className={styles.externalLink} url={url}>
              <Button
                appearance='primary'
                size='M'
                data-test='notification-cta-open'
                className={styles.close}
              >
                {ctaText}
              </Button>
            </ExternalLink>
          </div>
        )}
      </div>
      {!externalMode && (
        <div className='is-hidden-mobile'>
          <SideInfo isPinned={isPinned} externalMode={externalMode} startDateTime={startDateTime} />
        </div>
      )}
    </div>
  )
}
