import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import IconButton from '../../global/iconButton/IconButton'
import { useFlexWrapped } from '../../hooks/useFlexWrapped'
import { CloseIcon } from '../../icons/CloseIcon'
import { Text, TextH3 } from '../../ui/Typography/Typography'
import { VendorType } from './Vendors'

import styles from './VendorModal.module.scss'

interface VendorModalProps {
  data: VendorType
  closeModal: () => void
}

export const VendorModal: React.FC<VendorModalProps> = (props) => {
  const { t } = useTranslation()
  const { data, closeModal } = props
  const { flexParentRef, isWrapped } = useFlexWrapped()

  return (
    <div className={styles.vendorModal} ref={flexParentRef}>
      <div className={styles.logoBox}>
        <img className={styles.logo} src={data.imageUrl} alt={data.name} />
      </div>
      <div
        className={classNames(styles.contentBox, {
          [styles.parentWrapped]: isWrapped,
        })}
      >
        <IconButton className={styles.closeButton} onClick={closeModal}>
          <CloseIcon size={14} />
        </IconButton>

        <div className={styles.textBox}>
          <TextH3>{data.name}</TextH3>
          <Text className={styles.description}>{data.description}</Text>
        </div>

        <Button appearance='primary' size='L' onClick={() => window.open(data.url)} type='button'>
          {t('Platform Vendors.action')}
        </Button>
      </div>
    </div>
  )
}
