import React from 'react'
import classNames from 'classnames'

import { CompletedStepIcon } from '../../icons/CompletedStepIcon'
import { FailedStepIcon } from '../../icons/FailedStepIcon'
import { TextSmall } from '../../ui/Typography/Typography'

import styles from './StepCircle.module.scss'

export interface StepCircleProps {
  isActive: boolean
  section: number
  isRed?: boolean
  isSmall?: boolean
  isCompleted?: boolean
  isFailed?: boolean
  subStep?: {
    current: number
    total: number
  }
  isHidden?: boolean
}

export const StepCircle: React.FC<StepCircleProps> = ({
  section,
  isActive,
  isRed,
  isSmall,
  isCompleted,
  isFailed,
  subStep,
  isHidden,
}) => {
  const hiddenStepSection = 3

  if (isFailed) {
    return (
      <span className={styles.completedWrapper}>
        <FailedStepIcon />
      </span>
    )
  }

  if (isCompleted) {
    return (
      <span className={styles.failedWrapper}>
        <CompletedStepIcon />
      </span>
    )
  }

  const showSubStep = isActive && !!subStep?.current && !(section === hiddenStepSection && isHidden)

  return (
    <TextSmall
      className={classNames(styles.numberCircle, {
        [styles.isActive]: isActive,
        [styles.isRed]: isRed,
        [styles.isSmall]: isSmall,
        [styles.extended]: showSubStep,
      })}
    >
      {section}
      {showSubStep && (
        <span className={styles.subStep}>
          {subStep.current}/{subStep.total}
        </span>
      )}
    </TextSmall>
  )
}
