import DOMPurify from 'dompurify'

import { isZero } from './validations'

const config = {
  ALLOWED_TAGS: [
    'ul',
    'li',
    'b',
    'strong',
    'em',
    'br',
    'p',
    'a',
    'u',
    'ol',
    'table',
    'th',
    'tr',
    'td',
    'i',
  ],
  ALLOWED_ATTR: ['href', 'target'], // I've added target here
}

export const sanitizeHtml = (html: string, maxLength?: number): string => {
  html = DOMPurify.sanitize(html, config)

  if (maxLength === undefined) {
    return html
  }

  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  // Helper function to traverse the DOM tree and extract text
  const traverse = (node: Node, text: string): string => {
    // If the current node is just text, append its text to the accumulated text
    if (node.nodeType === Node.TEXT_NODE) {
      text += (node as Text).data
    }
    // If it's a node, go through its child nodes
    else if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element
      for (const child of Array.from(element.childNodes)) {
        // Recursively call the traverse function on each child node
        text = traverse(child, text)
        if (text.length >= maxLength) {
          break
        }
      }
    }
    return text
  }

  const previewText = traverse(doc.documentElement, '').slice(0, maxLength)

  if (isZero(doc.body.children.length)) {
    return previewText + '...'
  } else {
    // Create a range object to extract a portion of the DOM tree
    const range = document.createRange()
    range.selectNodeContents(doc.body)

    // Helper function to find the node and offset at a given text position
    const findNodeOffset = (node: Node, offset: number): [Node, number] => {
      // If the current node is a text node, return the node and the offset
      if (node.nodeType === Node.TEXT_NODE) {
        return [node as Text, Math.min(offset, (node as Text).length)]
      }
      // If the current node is an element node, traverse its child nodes
      else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as Element
        for (const child of Array.from(element.childNodes)) {
          // Calculate the text length of the child node
          const childLength = traverse(child, '').length
          // If the remaining offset is within the child node, recursively call findNodeOffset
          if (offset <= childLength) {
            return findNodeOffset(child, offset)
          }
          // Reduce the remaining offset by the text length of the child node
          offset -= childLength
        }
      }
      return [node, 0]
    }

    // Find the end node and end offset for the text preview
    const [endNode, endOffset] = findNodeOffset(doc.body, previewText.length)
    // Set the end point of the range using the end node and end offset
    range.setEnd(endNode, endOffset)

    const fragment = range.cloneContents()
    const children = Array.from(fragment.children)

    const lastChild = children[children.length - 1]
    if (lastChild) {
      lastChild.innerHTML += '...'
    }

    const previewHtml = children.map((child) => child.outerHTML).join('')

    return previewHtml
  }
}
