import React from 'react'
import { useTranslation } from 'react-i18next'

import IconButton from '../../../global/iconButton/IconButton'
import { DotsIcon } from '../../../icons/DotsIcon'
import { SubIBAccount } from '../../../model/MultiTierDto'
import { TextSmall, TextSmallStrong } from '../../../ui/Typography/Typography'

import styles from './MultiTierCards.module.scss'

interface MultiTierSubAccountsCardProps {
  data: SubIBAccount
  cardId: string

  setActionsModal: React.Dispatch<React.SetStateAction<SubIBAccount | undefined>>
}

export const MultiTierSubAccountsCard: React.FC<MultiTierSubAccountsCardProps> = ({
  data,
  setActionsModal,
  cardId,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.card} id={cardId}>
      <header className={styles.cardHeader}>
        <TextSmallStrong className={styles.cardTitle}>{data.account.name}</TextSmallStrong>

        <IconButton onClick={() => setActionsModal(data)}>
          <DotsIcon />
        </IconButton>
      </header>

      <TextSmall isParagraph className={styles.title}>
        <span className={styles.label}>{t('IB.MultiTier.Plan')}</span>
        <TextSmallStrong className={styles.value}>{data.commissionPlanGroup.name}</TextSmallStrong>
      </TextSmall>
    </div>
  )
}
