import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccountInfo } from '../../global/context/AccountInfoContext/AccountInfoContext.Provider'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useSupportContacts } from '../../utils/SupportContactsContext'
import { isTickmillASType, isTickmillSCType } from '../../utils/companyName.utils'

export const useTradingAccountsMessage = (): string | null => {
  const { accountInfoLimits } = useAccountInfo()

  const entity = useSessionEntity()
  const { t } = useTranslation()

  const { supportEmail } = useSupportContacts()

  const message = useMemo(() => {
    if (!accountInfoLimits) {
      return null
    }
    if (accountInfoLimits.hasReachedMaxTradingAccounts) {
      return t('Trading Account.You have reached the maximum amount of live trading accounts', {
        maxTotalLimit: accountInfoLimits.maxTotalLimit,
        email: supportEmail,
      })
    } else {
      if ((isTickmillASType(entity) || isTickmillSCType(entity)) && accountInfoLimits.tmt > 0) {
        return t(
          'Trading Account.You can hold a maximum of max Live Trading Accounts for tickmillTrader',
          {
            maxMT4Accounts: accountInfoLimits.mt4,
            maxMT5Accounts: accountInfoLimits.mt5,
            maxTMTAccounts: accountInfoLimits.tmt,
          }
        )
      } else {
        return t('Trading Account.You can hold a maximum of max Live Trading Accounts for', {
          maxMT4Accounts: accountInfoLimits.mt4,
          maxMT5Accounts: accountInfoLimits.mt5,
        })
      }
    }
  }, [accountInfoLimits, t, entity])

  return message
}
