import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'
import { GoodIcon } from '../../icons/GoodIcon'
import { IntroducingBrokerDetailsDto } from '../../model/IntroducingBrokerDetailsDto'
import { InfoCard } from '../../ui/Popups/InfoCard/InfoCard'
import { TextSmall, TextTiny } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { LoyaltyMappedType, formatNumberToLots } from './Loyalty'

import styles from './LoyaltyDetailsCard.module.scss'

interface Props {
  isFirstOne: boolean
  isLastOne: boolean
  data: LoyaltyMappedType
  introducingBroker: IntroducingBrokerDetailsDto
  isExpanded: boolean
  onExpand(): void
  onSubmitReward(data: LoyaltyMappedType): Promise<void>
  isLoading: boolean
  cardId: string
}

export const LoyaltyDetailsCard: React.FC<Props> = ({
  data,
  introducingBroker,
  isExpanded,
  onExpand,
  onSubmitReward,
  isLastOne,
  isFirstOne,
  isLoading,
  cardId,
}) => {
  const isMobile = useWindowResize()
  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const { formatNumber } = useFormatNumber()

  const getTierState = useMemo(() => {
    // 3 Rejected, 4 Reversal, 5 Reversed, 6 Redeemed ->
    // Deactivated with no hover text
    if (!introducingBroker.loyaltyProgramEligible || !introducingBroker.loyaltyProgramEnabled) {
      return undefined
    }
    if (data.isRedeemed) {
      return undefined
    }
    // Approved = Activated
    if (data.isApproved) {
      return t(
        'Loyalty Program.Redeem your prize to get your cash reward transferred to your IB Wallet'
      )
    }
    if (data.isPendingTransaction) {
      return t(
        'Loyalty Program.Almost there! Your prize transfer is being processed and will arrive in your wallet soon'
      )
    }
    // Created = isDeactivated
    if (data.isCreated) {
      return t('Loyalty Program.The button will be activated soon, or our team will get in touch')
    }
    if (data.isDeactivated) {
      return undefined
    }
  }, [
    introducingBroker.loyaltyProgramEligible,
    introducingBroker.loyaltyProgramEnabled,
    data.isRedeemed,
    data.isApproved,
    data.isCreated,
    data.isDeactivated,
    data.isPendingTransaction,
    t,
  ])

  if (isMobile) {
    return (
      <div
        id={cardId}
        className={classNames(styles.card, {
          [styles.isDisabled]:
            data.isDeactivated ||
            !introducingBroker?.loyaltyProgramEligible ||
            !introducingBroker.loyaltyProgramEnabled,
          [styles.isCurrentTier]: data.isCurrentTier,
        })}
      >
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <span>{data.isRedeemed ? <GoodIcon /> : data.icon && <data.icon />}</span>
            <TextSmall isParagraph className={styles.tier}>
              <span className={styles.tierName}>{data.label}</span>
              <span
                className={styles.tierAmount}
                dangerouslySetInnerHTML={{
                  __html: t('Loyalty Program.Win Cash', { amount: formatNumber(data.amount) }),
                }}
              />
            </TextSmall>
            <span
              className={classNames('has-cursor-pointer', styles.expandIcon)}
              onClick={() => (data.isRedeemed ? undefined : onExpand())}
            >
              {!isExpanded ? <ChevronRightIcon size={12} /> : <ChevronDownIcon />}
            </span>
          </div>
          {isExpanded && (
            <div className={styles.expanded}>
              {introducingBroker.loyaltyDetails.currentTier &&
                introducingBroker.loyaltyProgramEligible &&
                introducingBroker.loyaltyProgramEnabled && (
                  <TextTiny isParagraph className={styles.info}>
                    <span>
                      {t('Loyalty Program.Your current IB tier')}{' '}
                      <b>
                        {introducingBroker.loyaltyDetails.currentTier.label.toLocaleUpperCase()}
                      </b>
                    </span>
                    <br />
                    <span>
                      {t('Loyalty Program.Your current volume is of')}{' '}
                      <b>
                        {formatNumberToLots(
                          introducingBroker?.loyaltyDetails.loyaltyLots as number,
                          t,
                          true
                        )}
                      </b>
                    </span>
                  </TextTiny>
                )}
              {!data.isRedeemed && (
                <TextTiny
                  isParagraph
                  className={styles.info}
                  dangerouslySetInnerHTML={{
                    __html:
                      data.htmlPrizeText ??
                      t('Loyalty Program.Reach to unlock the tier', {
                        minLots: data.minLots,
                        name: data.name,
                      }),
                  }}
                />
              )}
              <button
                disabled={
                  !data.isApproved ||
                  !introducingBroker?.loyaltyProgramEligible ||
                  !introducingBroker.loyaltyProgramEnabled
                }
                onClick={() => {
                  onSubmitReward(data)
                }}
                className={classNames(styles.button, {
                  [styles.isDisabled]:
                    !data.isApproved ||
                    !introducingBroker?.loyaltyProgramEligible ||
                    !introducingBroker.loyaltyProgramEnabled,
                  [styles.isActive]:
                    data.isApproved &&
                    introducingBroker.loyaltyProgramEligible &&
                    introducingBroker.loyaltyProgramEnabled,
                })}
                type='button'
              >
                {t('Loyalty Program.Get Prize')}
              </button>
              {data.isCreated &&
                introducingBroker.loyaltyProgramEligible &&
                introducingBroker.loyaltyProgramEnabled && (
                  <TextTiny className={styles.info}>
                    {t(
                      'Loyalty Program.The button will be activated soon, or our team will get in touch'
                    )}
                  </TextTiny>
                )}
              {data.isPendingTransaction &&
                introducingBroker.loyaltyProgramEligible &&
                introducingBroker.loyaltyProgramEnabled && (
                  <TextTiny className={styles.info}>
                    {t(
                      'Loyalty Program.Almost there! Your prize transfer is being processed and will arrive in your wallet soon'
                    )}
                  </TextTiny>
                )}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      onClick={() => (isMobile ? onExpand() : undefined)}
      dir={isArabic ? 'rtl' : ''}
      className={classNames(styles.cardWrapper, {
        [styles.isFirstItem]: isFirstOne,
        [styles.isLastItem]: isLastOne,
        [styles.isDisabled]:
          !data.isApproved ||
          !introducingBroker?.loyaltyProgramEligible ||
          !introducingBroker.loyaltyProgramEnabled,
        [styles.isCurrentTier]: data.isCurrentTier,
      })}
    >
      <InfoCard direction='top' disabled={data.isRedeemed} text={data.htmlPrizeText}>
        {data.isRedeemed ? <GoodIcon /> : data.icon && <data.icon />}
      </InfoCard>

      <TextSmall isParagraph className={styles.tier}>
        <span className={styles.tierName}>{data.label}</span>
        <span
          className={styles.tierAmount}
          dangerouslySetInnerHTML={{
            __html: t('Loyalty Program.Win Cash', { amount: formatNumber(data.amount) }),
          }}
        />
      </TextSmall>
      <span className={styles.minTierLots}>{data.minLots}</span>
      <button
        data-tooltip={getTierState}
        disabled={
          isLoading ||
          !data.isApproved ||
          !introducingBroker.loyaltyProgramEligible ||
          !introducingBroker.loyaltyProgramEnabled
        }
        onClick={() => {
          onSubmitReward(data)
        }}
        className={classNames(styles.button, {
          [styles.isDisabled]:
            !data.isApproved ||
            !introducingBroker.loyaltyProgramEligible ||
            !introducingBroker.loyaltyProgramEnabled,
          [styles.isActive]:
            data.isApproved &&
            introducingBroker.loyaltyProgramEligible &&
            introducingBroker.loyaltyProgramEnabled,
          'has-tooltip-top': true,
        })}
        type='button'
      >
        {t('Loyalty Program.Get Prize')}
      </button>
      <span className={classNames(styles.tierAmount, 'has-text-weight-bold')}>
        $ {formatNumber(data.amount)}
      </span>
    </div>
  )
}
