import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikProps, useFormikContext, withFormik } from 'formik'
import { FieldValidator, FormikErrors } from 'formik/dist/types'
import { t } from 'i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { FileData } from '../../../global/fileUpload/FileUpload'
import { createFormCheckBoxField } from '../../../global/formField/FormCheckBoxField'
import { CancelActionModal } from '../../../global/modal/CancleActionModal'
import { Modal } from '../../../global/modal/Modal'
import { FormTemplate } from '../../../global/templates/FormTemplate'
import { TransactionFormActionButtonTemplate } from '../../../global/templates/TransactionFormActionButtonTemplate'
import { NameDto } from '../../../model/NameDto'
import { UploadDocumentTypeEnum } from '../../../model/UploadDocumentTypeEnum'
import { Text } from '../../../ui/Typography/Typography'
import { isDoubleSidedDocument } from '../../../utils/uploadDocument'
import { scrollToTop, useScrollToTop } from '../../../utils/useScrollToTop'
import { DocumentFileUploadsFactory } from '../DocumentFileUploadsFactory'
import { useDocumentText } from './useDocumentText'

import styles from './DocumentManagementNonNCIForm.module.scss'

const FormCheckBoxField = createFormCheckBoxField<DocumentManagementNonNCIFormValues>()

export interface DocumentManagementNonNCIFormValues {
  documentCategory: NameDto
  documentCategoryType: NameDto
  documents: DocumentManagementDocument[]
  terms: boolean
}

interface DocumentManagementDocument {
  categoryId: number
  typeId: number
  file: string
  filename: string
  error: boolean
}

export const DocumentManagementNonNCIFormUI: React.FC<
  FormikProps<DocumentManagementNonNCIFormValues> & OuterProps
> = (props) => {
  useScrollToTop()
  const { documentTitle, documentCategoryTypeName, onCancel, showTerms, documentId } = props

  const { t } = useTranslation()
  const [isPageExitConfirmation, setPageExitConfirmation] = useState(false)

  const handlePageExitConfirmation = () => {
    onCancel()
  }

  const handleExitPageConfirmationModalOpen = () => {
    setPageExitConfirmation(true)
  }

  const handleExitPageConfirmationModalClose = () => {
    setPageExitConfirmation(false)
    scrollToTop()
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    props.handleSubmit()
  }

  return (
    <FormTemplate title={t('Sign up.Add Document')} goBack={handleExitPageConfirmationModalOpen}>
      {isPageExitConfirmation && (
        <Modal
          closeModal={handleExitPageConfirmationModalClose}
          render={() => (
            <CancelActionModal
              children={t('Do you really like to cancel document adding process')}
              title={t('Cancel Adding Document')}
              cancelLabel={t('Back to Action')}
              confirmLabel={t('Yes, Cancel')}
              onConfirm={handlePageExitConfirmation}
              onCancel={handleExitPageConfirmationModalClose}
            />
          )}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <FormFields
          documentId={documentId}
          documentTitle={documentTitle}
          documentCategoryTypeName={documentCategoryTypeName}
          showTerms={showTerms}
          onCancel={handleExitPageConfirmationModalOpen}
        />
      </Form>
    </FormTemplate>
  )
}

export interface DocumentManagementNonNCIFormFieldsFactoryProps {
  documentId?: UploadDocumentTypeEnum
  documentTitle: string
  documentCategoryTypeName: string
  showTerms: boolean
  onCancel(): void
}

const FormFields: React.FC<DocumentManagementNonNCIFormFieldsFactoryProps> = (props) => {
  const { documentTitle, showTerms, onCancel, documentCategoryTypeName } = props
  const { t } = useTranslation()
  const { values, isValid, setValues } = useFormikContext<DocumentManagementNonNCIFormValues>()
  const documentCategoryDescription = useDocumentText(props)

  const handleDocumentUploads = (fileData: FileData[]) => {
    setValues({
      ...values,
      documents: fileData.map((x) => ({
        ...x,
        categoryId: values.documentCategory.id,
        typeId: values.documentCategoryType.id,
        filename: x.fileName,
        file: x.base64Content,
      })),
    })
  }

  return (
    <Loading isLoading={!props.documentId} showLoadingIcon>
      <div className='pb-2'>
        <h4 className={styles.textStrong}>{documentTitle}</h4>
        <Text isParagraph>{documentCategoryTypeName}</Text>
      </div>

      <div className='pb-5'>
        <div className='pb-3'>{documentCategoryDescription}</div>
        <div>
          <div className='pb-3'>
            <DocumentFileUploadsFactory
              buttonText={t('Profile.Upload File')}
              documentTypeId={values.documentCategoryType.id}
              documentCategory={values.documentCategory.id}
              onChange={handleDocumentUploads}
            />
          </div>
        </div>
      </div>

      {showTerms && (
        <div className='pb-5'>
          <FormCheckBoxField
            name='terms'
            label={t('Profile.I confirm that document has more than 3 months of validity.')}
            validate={(value: FieldValidator) => {
              if (!value) {
                return t('Validation.Required')
              }
              return undefined
            }}
          />
        </div>
      )}

      <TransactionFormActionButtonTemplate>
        <Button appearance='secondary' size='L' type='button' onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button appearance='primary' size='L' type='submit' disabled={!isValid}>
          {t('Confirm')}
        </Button>
      </TransactionFormActionButtonTemplate>
    </Loading>
  )
}

interface OuterProps {
  documentTitle: string
  documentCategoryTypeName: string
  documentId?: UploadDocumentTypeEnum
  documentCategory: NameDto
  documentCategoryType: NameDto
  showTerms: boolean
  onSubmit(values: DocumentManagementNonNCIFormValues): void
  onCancel(): void
}

interface StateProps {
  documentCategory: NameDto
  documentCategoryType: NameDto
}

const initialState = (props: StateProps): DocumentManagementNonNCIFormValues => {
  const { documentCategory, documentCategoryType } = props || {}

  return {
    documentCategory: {
      id: documentCategory.id,
      name: documentCategory.name,
    },
    documentCategoryType: {
      id: documentCategoryType.id,
      name: documentCategoryType.name,
    },
    documents: [],
    terms: false,
  }
}

export const DocumentManagementNonNCIForm = withFormik<
  OuterProps,
  DocumentManagementNonNCIFormValues
>({
  mapPropsToValues: (props) => {
    return initialState(props)
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      props.onSubmit(values)
    } catch (e: unknown) {
      console.error(e)
      throw e
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, props) => {
    const errors: FormikErrors<DocumentManagementNonNCIFormValues> = {}

    const documents = values.documents.filter((x) => !!x?.filename).filter((x) => !x.error)

    const doubleSidedDocumentSize = isDoubleSidedDocument(
      props.documentCategory.id,
      values.documentCategoryType.id
    )
      ? 1
      : 0

    if (documents.length <= doubleSidedDocumentSize) {
      errors.documents = t('Validation.Required')
    }

    if (values.documents.some((doc) => doc.error)) {
      errors.documents = t('Validation.Required')
    }

    return errors
  },
  enableReinitialize: true,
  validateOnMount: true,
})(DocumentManagementNonNCIFormUI)
