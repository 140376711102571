import { NameDto } from './NameDto'

export enum TutorialGroupEnum {
  IntroducingToFutures = 1,
  UnderstandingFutures = 2,
  KeyEconomic = 3,
  ExchangeInstrument = 5,
}

export interface VideoTutorial {
  title: string
  tutorialLink: string
  duration?: string
}

export interface LearningVideoDto {
  tutorialGroup: NameDto<TutorialGroupEnum>
  tutorials?: VideoTutorial[]
}
