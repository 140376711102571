import { useOnClickedOutside } from '../../utils/useOnClickedOutside'

import styles from './Dropdown.module.scss'

export interface DropdownProps {
  className?: string
  children?: React.ReactNode
  onClose: () => void
}

export const Dropdown: React.FC<DropdownProps> = ({ className, children, onClose }) => {
  const onClickOutside = useOnClickedOutside<HTMLUListElement>(onClose)

  return (
    <ul ref={onClickOutside} className={className || styles.dropdownList}>
      {children}
    </ul>
  )
}
