import { useArabicSessionLanguage } from '../global/context/SessionSettingsContext'

function wrapLTR(text: string) {
  // this LTRM thing is a unicode character that tells the browser to render the text as LTR: https://unicode-explorer.com/c/200E
  const LTRM = '\u200E'
  return `${LTRM}${text}${LTRM}`
}

export const useMixedText = (): { parseMixedText: (text?: string) => string } => {
  const isArabic = useArabicSessionLanguage()

  const parseMixedText = (text?: string) => {
    if (!text) {
      return ''
    }
    if (!isArabic) {
      return text
    }

    const ltrRegex = /[A-Za-z0-9./:\-\s]+/g

    return text.replace(ltrRegex, (match) => wrapLTR(match))
  }

  return { parseMixedText }
}
