import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosResponseHeaders } from 'axios'

import { Loading } from '../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { InformationModal } from '../../../global/modal/InformationModal'
import { Modal } from '../../../global/modal/Modal'
import { TestStatusEnum } from '../../../model/ClientTestAnswerDto'
import { ClientTestSubcategoryEnum } from '../../../model/ClientTestSubcategoryEnum'
import { CreateClientDocuments } from '../../../model/CreateClientDocuments'
import { CreateClientTest } from '../../../model/CreateClientTest'
import { DocumentPropertyType } from '../../../model/DocumentPropertyType'
import { UploadDocumentCategoryEnum } from '../../../model/UploadDocumentCategoryEnum'
import { UploadDocumentTypeEnum } from '../../../model/UploadDocumentTypeEnum'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'
import { DeclinedClassificationTest } from '../DeclinedClassificationTest'
import { PendingClassificationTest } from '../PendingClassificationTest'
import {
  ExperiencedClassificationTestForm,
  ExperiencedClassificationTestValues,
} from './ExperiencedClassificationTestForm'

interface Props {
  handleOkOnClick: () => void
  setAlreadyUploadedModal: (visible: boolean) => void
}

export const ExperiencedClassificationTest: React.FC<Props> = ({
  handleOkOnClick,
  setAlreadyUploadedModal,
}) => {
  const apiClient = useApiClient(ClientApiClient)

  const [applicationModal, setApplicationModal] = useState(false)
  const locale = useSessionLanguage()

  const testSubCategoryId = ClientTestSubcategoryEnum.ClassificationExperiencedRetail

  const testAnswerCallback = useCallback(async () => {
    return await apiClient.getClientClassificationTestAnswers({
      subCategoryId: testSubCategoryId,
      languageId: locale,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: testAnswers, isLoading: isTestAnswersLoading } = useFetchOne(testAnswerCallback)

  const testCallback = useCallback(async () => {
    return await apiClient.getClassificationTest({
      subCategoryId: testSubCategoryId,
      languageId: locale,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: testData, isLoading: isTestLoading } = useFetchOne(testCallback)

  const checkRetakeClassificationTestAvailability = useCallback(
    () => apiClient.isRetakeClassificationTestEnabled(testSubCategoryId),
    // eslint-disable-next-line react-hooks/exhaustive-deps

    []
  )

  const { data: isFormEnabled, isLoading: isTestAvailabilityLoading } = useFetchOne(
    checkRetakeClassificationTestAvailability
  )

  const { account } = useAccountReadContext()

  const preparePayload = (values: ExperiencedClassificationTestValues): CreateClientTest => {
    const testAnswers = {
      category: 'classification',
      clientId: account?.id || '',
      testId: testData?.id || '',
      dateTaken: new Date().toISOString(),
      selectedAnswers: values.selectedAnswers.map((a) => a.answer),
      freeAnswers: values.freeAnswers,
    }
    return testAnswers
  }
  const getTestId = (
    headers:
      | AxiosResponseHeaders
      | Partial<
          Record<string, string> & {
            'set-cookie'?: string[] | undefined
          }
        >
  ) => {
    const parts = headers.location?.split('/')
    if (parts) {
      return parts[parts.length - 1]
    }
    return ''
  }

  const prepareUploadPayload = (
    values: ExperiencedClassificationTestValues,
    testId: string
  ): CreateClientDocuments => {
    const docs: CreateClientDocuments = {
      documents: [],
    }

    values.tradeSizeProofs.map((proof) =>
      docs.documents.push({
        categoryId: UploadDocumentCategoryEnum.Additional,
        typeId: UploadDocumentTypeEnum.Client_Classification,
        file: proof.base64Content,
        filename: proof.fileName,
        properties: {
          [DocumentPropertyType.ClientTestId]: testId,
        },
      })
    )

    values.knowledgeProofs.map((proof) =>
      docs.documents.push({
        categoryId: UploadDocumentCategoryEnum.Additional,
        typeId: UploadDocumentTypeEnum.Client_Classification,
        file: proof.base64Content,
        filename: proof.fileName,
        properties: {
          [DocumentPropertyType.ClientTestId]: testId,
        },
      })
    )

    return docs
  }

  const uploadDocs = async (values: ExperiencedClassificationTestValues, testId: string) => {
    const payload = prepareUploadPayload(values, testId)
    try {
      await apiClient.uploadDocuments(payload)
    } catch {
      setAlreadyUploadedModal(true)
    }
  }

  const submitForm = async (values: ExperiencedClassificationTestValues) => {
    try {
      const payload = preparePayload(values)
      if (payload) {
        const response = await apiClient.addClientClassificationTest(payload)
        const testId = getTestId(response.headers)
        await uploadDocs(values, testId)
        setApplicationModal(true)
      }
    } catch (error: unknown) {
      console.error(error)
    }
  }

  const handleSetApplicationModal = () => {
    setApplicationModal(false)
    handleOkOnClick()
  }

  const { t } = useTranslation()

  if (testAnswers?.testStatus.id === TestStatusEnum.Pending) {
    return <PendingClassificationTest />
  } else if (testAnswers?.testStatus.id === TestStatusEnum.Declined && !testAnswers.allowRetake) {
    return <DeclinedClassificationTest handleOkOnClick={handleOkOnClick} />
  }
  return (
    <Loading isLoading={isTestLoading || isTestAnswersLoading || isTestAvailabilityLoading}>
      {applicationModal && (
        <Modal
          closeModal={handleSetApplicationModal}
          render={() => (
            <InformationModal
              onCancel={handleSetApplicationModal}
              title={t('Application received')}
              onCancelText={t('Ok')}
            >
              <p>{t('Profile.We have received your application to change classification.')}</p>
            </InformationModal>
          )}
        />
      )}
      {testData && (
        <ExperiencedClassificationTestForm
          classificationName={account?.classification.name}
          testData={testData}
          handleFormSubmit={submitForm}
          isFormEnabled={!!isFormEnabled}
          testAnswers={testAnswers}
          account={account}
        />
      )}
    </Loading>
  )
}
