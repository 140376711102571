import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { ChevronDownIcon } from '../../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../../icons/ChevronUpIcon'
import {
  hasProductAppropriatenessTestSubmittedStatus,
  hasProductEmailVerifiedStatus,
  hasProductPendingDocumentVerificationStatus,
} from '../../../model/AccountDetailedDto'
import { ClientCampaignDto } from '../../../model/ClientCampaignDto'
import { TextStrong } from '../../../ui/Typography/Typography'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { useIBCampaignsResultsReadContext } from '../../../utils/IBCampaignsResultsContext'
import { ClientApiClient } from '../../../utils/clientApi'
import { isTickmillPartner, isTickmillUK } from '../../../utils/companyName.utils'
import { isLearningRouteAllowed, isTradersRoomRouteAllowed } from '../../../utils/permission.utils'
import { useFetchOne } from '../../../utils/useFetch'
import { useIBPrograms } from '../../../utils/useIBPrograms'
import { useLocallyPersistedState } from '../../../utils/useStorage'
import { InfoIconWrapper } from '../../InfoIconWrapper/InfoIconWrapper'
import { Button } from '../../button/Button'
import { useAccountInfo } from '../../context/AccountInfoContext/AccountInfoContext.Provider'
import { useProductReadContext } from '../../context/ProductContext'
import { ConfirmationModal } from '../../modal/ConfirmationModal'
import { Modal } from '../../modal/Modal'
import {
  NavTabType,
  NavTabsType,
  isIBType,
  isLearningType,
  isPAType,
  isStatementsType,
  isSubscriptionsType,
  isToolsType,
  isTradersRoomType,
} from './MobileNavTabsType'

import styles from './MobileNav.module.scss'

const useTab = () => {
  const location = useLocation()

  const [tabName, setTab] = useState<NavTabType>()

  useEffect(() => {
    setTab(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleTab = (tab: NavTabsType) => {
    if (tab === tabName) {
      setTab(undefined)
    } else {
      setTab(tab)
    }
  }

  return { tabName, setTab, handleTab }
}

export const MobileNavTabs: React.FC = () => {
  const { t } = useTranslation()
  const { product } = useProductReadContext()

  const { ibCampaignsResults } = useIBCampaignsResultsReadContext()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { account } = useAccountReadContext()
  const { accountInfoLimits } = useAccountInfo()

  const navigate = useNavigate()
  const { multiTierProgram } = useIBPrograms()

  const { tabName, handleTab } = useTab()

  const [luckyDrawEligible] = useLocallyPersistedState('luckyDrawEligible', false)
  const [isCampaignModalOpen, setCampaignModalOpen] = useState<boolean>(false)

  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => apiClient.getClientCampaigns(), [apiClient])
  const listCallback = useCallback(async () => apiClient.getClientCampaignsList(), [apiClient])

  const { data: campaigns } = useFetchOne(callback)
  const { data: campaignsList } = useFetchOne(listCallback)

  // TODO: use to "hide" a links, when the rest of the pages will be ready.
  const isAccountPending =
    hasProductAppropriatenessTestSubmittedStatus(account, product) ||
    hasProductPendingDocumentVerificationStatus(account, product) ||
    hasProductEmailVerifiedStatus(account, product)

  if (isDefaultCFDProductType()) {
    return (
      <>
        {!isAccountPending && (
          <>
            {isCampaignModalOpen && !!campaigns?.length && (
              <Modal
                closeModal={() => setCampaignModalOpen(false)}
                render={() => (
                  <ConfirmationModal
                    onConfirm={() => navigate(`/dashboard/traders-room/trading-accounts/new`)}
                    title={t('Campaign Account')}
                    renderFooter={() => (
                      <React.Fragment>
                        <button
                          className='button'
                          onClick={() => setCampaignModalOpen(false)}
                          type='button'
                        >
                          {t('Not now')}
                        </button>
                        <button
                          disabled={accountInfoLimits?.hasReachedMaxTradingAccounts}
                          className='button'
                          onClick={() => {
                            setCampaignModalOpen(false)
                            navigate(`/dashboard/traders-room/trading-accounts/new?campaign=true`)
                          }}
                          type='button'
                        >
                          <b>{t('Create Campaign Account')}</b>
                        </button>
                      </React.Fragment>
                    )}
                    onCancel={() => setCampaignModalOpen(false)}
                  >
                    <p>
                      {t(
                        'A Campaign Trading Account is a MT4 or MT5 trading account linked to a specific campaign'
                      )}{' '}
                      {t('Do you wish to create a Campaign Trading Account now')}
                    </p>
                  </ConfirmationModal>
                )}
              />
            )}
            {isTradersRoomRouteAllowed(account) && (
              <React.Fragment>
                <NavTabLink
                  isActive={isTradersRoomType(tabName)}
                  onClick={() => handleTab(NavTabsType.TradersRoom)}
                >
                  {t('Tabs.Traders room')}
                </NavTabLink>
                {isTradersRoomType(tabName) && (
                  <React.Fragment>
                    <NavSubTabLink to='/dashboard/traders-room/wallets'>
                      {t('Tabs.Wallets')}
                    </NavSubTabLink>
                    <NavSubTabLink to='/dashboard/traders-room/transactions'>
                      {t('Tabs.Transactions')}
                    </NavSubTabLink>
                    <NavSubTabLink to='/dashboard/traders-room/trading-accounts'>
                      {t('Tabs.Trading Accounts')}
                    </NavSubTabLink>
                    <NavSubTabLink to='/dashboard/traders-room/transaction-history'>
                      {t('Tabs.Transaction History')}
                    </NavSubTabLink>
                    <NavSubTabLink to='/dashboard/traders-room/stock-dividends'>
                      {t('Tabs.Stock Dividends')}
                    </NavSubTabLink>
                    {!!campaignsList?.items.length && (
                      <NavSubTabLink to='/dashboard/traders-room/campaign-results'>
                        {t('Tabs.Campaign Results')}
                      </NavSubTabLink>
                    )}
                    {!!campaigns?.length && (
                      <div className='is-flex is-justify-content-center is-align-items-center'>
                        <NavSubTabLink
                          disabled={accountInfoLimits?.hasReachedMaxTradingAccounts}
                          to='/dashboard/traders-room/trading-accounts/new?campaign=true'
                        >
                          {t('Tabs.Add Campaign Account')}
                        </NavSubTabLink>
                        <InfoIconWrapper onClick={() => setCampaignModalOpen(true)} />
                      </div>
                    )}
                    {luckyDrawEligible && (
                      <NavSubTabLink
                        className={classNames('has-text-danger')}
                        to='/dashboard/traders-room/wallets'
                        state={{ luckyDrawModalActive: true }}
                      >
                        {t('Tabs.Lucky Draw')}
                      </NavSubTabLink>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {account?.companyConfiguration.hasIBRoom && (
              <>
                {!isTickmillPartner(account) && !account?.clientIntroducingBroker?.id && (
                  <NavTabLink
                    mainLink
                    onClick={() => navigate('/dashboard/introducing-broker/wallets')}
                  >
                    {t('Tabs.Introducing broker')}
                  </NavTabLink>
                )}
                {!isTickmillPartner(account) && !!account?.clientIntroducingBroker?.id && (
                  <NavTabLink
                    isActive={isIBType(tabName)}
                    onClick={() => handleTab(NavTabsType.IB)}
                  >
                    {t('Tabs.Introducing broker')}
                  </NavTabLink>
                )}

                {isTickmillPartner(account) && !account?.clientIntroducingBroker?.id && (
                  <NavTabLink
                    mainLink
                    onClick={() => navigate('/dashboard/introducing-broker/wallets')}
                  >
                    {t('Tabs.Partners')}
                  </NavTabLink>
                )}
                {isTickmillPartner(account) && !!account?.clientIntroducingBroker?.id && (
                  <NavTabLink
                    isActive={isIBType(tabName)}
                    onClick={() => handleTab(NavTabsType.IB)}
                  >
                    {t('Tabs.Partners')}
                  </NavTabLink>
                )}

                {isIBType(tabName) && !!account?.clientIntroducingBroker?.id && (
                  <React.Fragment>
                    <NavSubTabLink to='/dashboard/introducing-broker/wallets'>
                      {t('Tabs.Wallets')}
                    </NavSubTabLink>
                    <NavSubTabLink to='/dashboard/introducing-broker/clients'>
                      {t('Tabs.Clients')}
                    </NavSubTabLink>
                    <NavSubTabLink to='/dashboard/introducing-broker/income'>
                      {t('Tabs.Income')}
                    </NavSubTabLink>
                    <NavSubTabLink to='/dashboard/introducing-broker/reports'>
                      {t('Tabs.Reports')}
                    </NavSubTabLink>
                    <NavSubTabLink to='/dashboard/introducing-broker/transaction-history'>
                      {t('Tabs.Transaction History')}
                    </NavSubTabLink>
                    {!isTickmillPartner(account) && (
                      <NavSubTabLink to='/dashboard/introducing-broker/referral-materials'>
                        {t('Tabs.Referral Materials')}
                      </NavSubTabLink>
                    )}
                    <NavSubTabLink to='/dashboard/introducing-broker/loyalty-program'>
                      {t('Tabs.Loyalty Program')}
                    </NavSubTabLink>

                    {multiTierProgram && account?.companyConfiguration.hasMultiTier && (
                      <NavSubTabLink to='/dashboard/introducing-broker/multi-tier'>
                        {t('Tabs.Multi-Tier')}
                      </NavSubTabLink>
                    )}
                    <NavSubTabLink to='/dashboard/introducing-broker/legal-documents'>
                      {t('Tabs.Legal Documents')}
                    </NavSubTabLink>
                    {!!ibCampaignsResults.length && (
                      <NavSubTabLink to='/dashboard/introducing-broker/ib-contest'>
                        {t('Tabs.IB Contest')}
                      </NavSubTabLink>
                    )}
                  </React.Fragment>
                )}
              </>
            )}

            {!!account?.clientPaymentAgent && account.clientPaymentAgent.isActive && (
              <>
                <NavTabLink isActive={isPAType(tabName)} onClick={() => handleTab(NavTabsType.PA)}>
                  {t('Tabs.Payment Agent')}
                </NavTabLink>
                {isPAType(tabName) && (
                  <React.Fragment>
                    <NavSubTabLink to='/dashboard/payment-agent/wallets'>
                      {t('Tabs.Wallets')}
                    </NavSubTabLink>
                    <NavSubTabLink to='/dashboard/payment-agent/transaction-history'>
                      {t('Tabs.Transaction History')}
                    </NavSubTabLink>
                    <NavSubTabLink to='/dashboard/payment-agent/profile-info'>
                      {t('Tabs.Profile Info')}
                    </NavSubTabLink>
                  </React.Fragment>
                )}
              </>
            )}

            {!!account?.companyConfiguration.hasAutochartist && (
              <>
                <NavTabLink
                  isActive={isToolsType(tabName)}
                  onClick={() => handleTab(NavTabsType.Tools)}
                >
                  {t('Tabs.Tools')}
                </NavTabLink>
                {isToolsType(tabName) && (
                  <React.Fragment>
                    <NavSubTabLink to='/dashboard/tools/platforms'>{t('Platforms')}</NavSubTabLink>
                    {/* <NavSubTabLink to='/dashboard/tools/autochartist'>
                      {t('Autochartist.Autochartist')}
                    </NavSubTabLink> */}
                  </React.Fragment>
                )}
              </>
            )}

            {isLearningRouteAllowed(account) && (
              <React.Fragment>
                <NavTabLink
                  isActive={isLearningType(tabName)}
                  onClick={() => handleTab(NavTabsType.Learning)}
                >
                  {t('Tabs.Learning')}
                </NavTabLink>
                {isLearningType(tabName) && (
                  <React.Fragment>
                    {!isTickmillPartner(account) && (
                      <NavSubTabLink to='/dashboard/learning/getting-started'>
                        {t('Learning.Getting Started')}
                      </NavSubTabLink>
                    )}
                    <NavSubTabLink to='/dashboard/learning/trainings'>
                      {t('Learning.Video Tutorials')}
                    </NavSubTabLink>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <>
      {!isAccountPending && (
        <>
          <NavTabLink
            isActive={isTradersRoomType(tabName)}
            onClick={() => handleTab(NavTabsType.TradersRoom)}
          >
            {t('Tabs.Traders room')}
          </NavTabLink>
          {isTradersRoomType(tabName) && (
            <>
              <NavSubTabLink to='/dashboard/traders-room/balances'>
                {t('Tabs.Balances')}
              </NavSubTabLink>
              <NavSubTabLink to='/dashboard/traders-room/transactions'>
                {t('Tabs.Transactions')}
              </NavSubTabLink>
              <NavSubTabLink to='/dashboard/traders-room/transaction-history'>
                {t('Tabs.Transaction History')}
              </NavSubTabLink>
              {luckyDrawEligible && (
                <NavSubTabLink
                  className={classNames('has-text-danger')}
                  to='/dashboard/traders-room/wallets'
                  state={{ luckyDrawModalActive: true }}
                >
                  {t('Tabs.Lucky Draw')}
                </NavSubTabLink>
              )}
            </>
          )}

          <NavTabLink
            isActive={isSubscriptionsType(tabName)}
            onClick={() => handleTab(NavTabsType.Subscriptions)}
          >
            {t('Tabs.Subscriptions')}
          </NavTabLink>
          {isSubscriptionsType(tabName) && (
            <>
              <NavSubTabLink to='/dashboard/subscriptions/trading-platforms'>
                {t('Subscriptions.Trading Platforms')}
              </NavSubTabLink>
              <NavSubTabLink to='/dashboard/subscriptions/market-data'>
                {t('Market Data.title')}
              </NavSubTabLink>
              <NavSubTabLink to='/dashboard/subscriptions/history'>{t('History')}</NavSubTabLink>
            </>
          )}
          <NavTabLink
            isActive={isStatementsType(tabName)}
            onClick={() => handleTab(NavTabsType.Statements)}
          >
            {t('Tabs.Statements')}
          </NavTabLink>
          {isStatementsType(tabName) && (
            <React.Fragment>
              <NavSubTabLink to='/dashboard/statements/daily'>
                {t('Statements.Daily')}
              </NavSubTabLink>
              <NavSubTabLink to='/dashboard/statements/monthly'>
                {t('Statements.Monthly')}
              </NavSubTabLink>
            </React.Fragment>
          )}

          <NavTabLink isActive={isToolsType(tabName)} onClick={() => handleTab(NavTabsType.Tools)}>
            {t('Tabs.Tools')}
          </NavTabLink>
          {isToolsType(tabName) && (
            <React.Fragment>
              <NavSubTabLink to='/dashboard/tools/platform-vendors'>
                {t('Platform Vendors.title')}
              </NavSubTabLink>
              <NavSubTabLink to='/dashboard/tools/market-data-vendors'>
                {t('Market Data.Market Data Vendors')}
              </NavSubTabLink>
            </React.Fragment>
          )}

          {isLearningRouteAllowed(account) && (
            <React.Fragment>
              <NavTabLink
                isActive={isLearningType(tabName)}
                onClick={() => handleTab(NavTabsType.Learning)}
              >
                {t('Tabs.Learning')}
              </NavTabLink>
              {isLearningType(tabName) && (
                <React.Fragment>
                  {!isTickmillPartner(account) && (
                    <NavSubTabLink to='/dashboard/learning/getting-started'>
                      {t('Learning.Getting Started')}
                    </NavSubTabLink>
                  )}
                  {isTickmillUK(account) && (
                    <NavSubTabLink to='/dashboard/learning/cqg-education-videos'>
                      {t('Learning.CQG Education Videos')}
                    </NavSubTabLink>
                  )}
                  {isTickmillUK(account) && (
                    <NavSubTabLink to='/dashboard/learning/tutorial-videos'>
                      {t('Learning.Tutorial Videos')}
                    </NavSubTabLink>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </>
      )}
    </>
  )
}

interface NavTabLinkProps {
  isActive?: boolean
  children: React.ReactNode
  onClick(): void
  mainLink?: boolean
}

const NavTabLink: React.FC<NavTabLinkProps> = (props) => {
  const { isActive, onClick, children, mainLink } = props

  return (
    <Button
      appearance='plain'
      size='S'
      className='has-text-weight-bold has-text-grey is-uppercase'
      onClick={onClick}
    >
      <span className='is-flex is-align-items-center'>
        <TextStrong className='text'>{children}</TextStrong>
        {!mainLink && (
          <span className={styles.arrow}>{isActive ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
        )}
      </span>
    </Button>
  )
}

interface NavSubTabLinkProps extends NavLinkProps {
  disabled?: boolean
}

const NavSubTabLink: React.FC<NavSubTabLinkProps> = (props) => (
  <NavLink
    {...props}
    onClick={props.disabled ? (e) => e.preventDefault() : (e) => e}
    className={({ isActive }) =>
      classNames(styles.navbarItem, {
        [styles.isActive]: isActive,
      })
    }
  >
    {props.children}
  </NavLink>
)
