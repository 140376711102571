import React from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmationModal } from '../../../../../global/modal/ConfirmationModal'
import { Modal } from '../../../../../global/modal/Modal'

import styles from '../DocumentsInfoPage.module.scss'

interface DocumentReasonModalProps {
  onConfirm(): void
  onCancel(): void
}

export const DocumentFileConfirmationModal: React.FC<DocumentReasonModalProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onCancel}
      cardClassName={styles.modal}
      render={() => (
        <ConfirmationModal
          title={t('Sign up.Remove Document')}
          renderFooter={() => (
            <React.Fragment>
              <button className='button' onClick={onCancel} type='button'>
                {t('Cancel')}
              </button>
              <button className='button' onClick={onConfirm} type='button'>
                {t('Yes')}
              </button>
            </React.Fragment>
          )}
          onConfirm={onConfirm}
          onCancel={onCancel}
        >
          <p>{t('Sign up.Do you really want to delete this document?')}</p>
        </ConfirmationModal>
      )}
    />
  )
}
