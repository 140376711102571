import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '../../global/button/Button'
import { Table } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { WarningIcon } from '../../icons/WarningIcon'
import { AlertIconWrapper } from '../../ui/AlertIconWrapper/AlertIconWrapper'
import { TextTinyStrong } from '../../ui/Typography/Typography'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../utils/wallet.utils'
import { IntroducingBrokerMappedDto } from './IBWalletsPage'

interface IBWalletsTableProps {
  data: IntroducingBrokerMappedDto[]

  setModalVisible(visible: boolean): void
}
export const IBWalletsTable: React.FC<IBWalletsTableProps> = ({ data, setModalVisible }) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  return (
    <Table>
      <thead>
        <tr>
          <th>{t('Wallet.Wallet')}</th>
          <th>{t('Trading Account.Balance')}</th>
          <th>{t('IB.Incoming Reserved')}</th>
          <th>{t('IB.Outgoing Reserved')}</th>
          <th>{t('IB.All Time Reward')}</th>
          <th align='center'>{t('Wallet.Transaction History')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {!data.length ? (
          <>
            <td align='center' colSpan={8}>
              {t('No results')}
            </td>
          </>
        ) : (
          data.map((wallet, idx) => {
            const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
            const isTransferFromRestricted = isRestricted(
              WalletRestrictions.TRANSFER_FROM,
              wallet.restrictions
            )
            const isWithdrawRestricted = isRestricted(
              WalletRestrictions.WITHDRAW,
              wallet.restrictions
            )

            return (
              <tr key={wallet.id} onClick={() => hasAllRestrictions && setModalVisible(true)}>
                <td onClick={() => hasAllRestrictions && setModalVisible(true)}>
                  <div className='is-flex is-flex-direction-column'>
                    <span>
                      {hasAllRestrictions && <WarningIcon size={20} color={'error'} />}
                      {wallet.currency.id} {t('Wallet.IB Wallet Name')}
                    </span>
                    <span className='text-secondary'>{wallet.name}</span>
                  </div>
                </td>
                <td>
                  <strong>{formatMoney(wallet.balance, wallet.currency.id)}</strong>
                </td>
                <td>{formatMoney(wallet.incomingReserved, wallet.currency.id)}</td>
                <td>{formatMoney(wallet.reserved, wallet.currency.id)}</td>
                <td>{formatMoney(wallet.allTimeReward, wallet.currency.id)}</td>

                <td align='center'>
                  <Link
                    className='is-link is-right'
                    to='/dashboard/introducing-broker/transaction-history'
                    state={{ walletId: wallet.id }}
                  >
                    {t('View')}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      isWithdrawRestricted
                        ? '#'
                        : `/dashboard/introducing-broker/wallets/${wallet.id}/withdrawal`
                    }
                  >
                    <AlertIconWrapper spaced isActive={!hasAllRestrictions && isWithdrawRestricted}>
                      <Button
                        onClick={() =>
                          !hasAllRestrictions && isWithdrawRestricted && setModalVisible(true)
                        }
                        size='XS'
                        appearance='secondary'
                        state={isWithdrawRestricted ? 'disabled' : 'normal'}
                      >
                        <TextTinyStrong>{t('Withdrawal')}</TextTinyStrong>
                      </Button>
                    </AlertIconWrapper>
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      isTransferFromRestricted
                        ? '#'
                        : `/dashboard/introducing-broker/wallets/${wallet.id}/transfer`
                    }
                  >
                    <AlertIconWrapper
                      spaced
                      isActive={!hasAllRestrictions && isTransferFromRestricted}
                    >
                      <Button
                        onClick={() =>
                          !hasAllRestrictions && isTransferFromRestricted && setModalVisible(true)
                        }
                        size='XS'
                        appearance='secondary'
                        state={isTransferFromRestricted ? 'disabled' : 'normal'}
                      >
                        <TextTinyStrong>{t('Transfer')}</TextTinyStrong>
                      </Button>
                    </AlertIconWrapper>
                  </Link>
                </td>
              </tr>
            )
          })
        )}
      </tbody>
    </Table>
  )
}
