import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import WheelFrameAR from '../../images/wheel-frame-ar.png'
import WheelFrameEN from '../../images/wheel-frame-en.png'
import WheelFramePT from '../../images/wheel-frame-pt.png'
import WheelFrameVI from '../../images/wheel-frame-vi.png'
import WheelFrameZH from '../../images/wheel-frame-zh.png'
import { Sector } from '../../model/LuckyDrawDto'
import { useSessionLanguage } from '../context/SessionSettingsContext'

import styles from './FortuneWheel.module.scss'

interface FortuneWheelProps {
  winnerIndex: number
  sectors: Sector[]
  setWon: () => void
}

export interface WheelRef {
  startSpinning(): void
}

interface SpinData {
  tot: number
  dia: number
  rad: number
  pi: number
  tau: number
  arc: number
  friction: number
  angVelMin: number
  angVelMax: number
  angVel: number
  ang: number
  rounds: number
}

export const FortuneWheel = forwardRef<WheelRef, FortuneWheelProps>(
  ({ winnerIndex, sectors, setWon }, ref) => {
    const { t } = useTranslation()
    const locale = useSessionLanguage()

    const wheelFrame = useMemo(() => {
      switch (locale) {
        case 'zh':
          return WheelFrameZH
        case 'vi':
          return WheelFrameVI
        case 'pt':
          return WheelFramePT
        case 'ar':
          return WheelFrameAR
        default:
          return WheelFrameEN
      }
    }, [locale])

    const wheelRef = useRef<HTMLCanvasElement>(null)
    const spinData = useRef<SpinData>({
      tot: sectors.length,
      dia: 0,
      rad: 0,
      pi: Math.PI,
      tau: 2 * Math.PI,
      arc: (2 * Math.PI) / sectors.length,
      friction: 0.995,
      angVelMin: 0.002,
      angVelMax: 0,
      angVel: 0,
      ang: 0,
      rounds: 8,
    })
    const started = useRef<boolean>(false)

    const isAccelerating = useRef(false)
    const isSpinning = useRef(false)
    const onWinner = useRef(false)
    const animationFrameId = useRef<number | null>(null)

    const getIndex = () => {
      const { tot, ang, tau } = spinData.current
      if (tot && ang && tau) {
        return Math.floor(tot - (ang / tau) * tot) % tot
      } else {
        return 0
      }
    }

    const drawSector = useCallback(
      (sector: Sector, i: number, ctx: CanvasRenderingContext2D) => {
        if (!ctx || !spinData.current) {
          return
        }
        const { arc, rad } = spinData.current
        const ang = arc * i
        ctx.save()
        // COLOR
        ctx.beginPath()
        ctx.fillStyle = i % 2 ? '#bb0000' : '#fdfefe'
        ctx.moveTo(rad, rad)
        ctx.arc(rad, rad, rad, ang, ang + arc)
        ctx.lineTo(rad, rad)
        ctx.fill()
        // TEXT
        ctx.translate(rad, rad)
        ctx.rotate(ang + arc / 2)
        ctx.textAlign = 'right'
        ctx.fillStyle = i % 2 ? '#fdfefe' : '#c70009'
        ctx.font = 'bold 20px sans-serif'
        const prizeText = sector?.currency?.id
          ? t(sector?.currency?.id, { amount: sector?.amount.toString() })
          : sector?.amount.toString()
        ctx.fillText(prizeText, rad - 10, 10)

        ctx.restore()
      },
      [t]
    )

    const rotate = (ctx: CanvasRenderingContext2D) => {
      if (!ctx || !spinData.current) {
        return
      }
      const { ang, pi } = spinData.current
      ctx.canvas.style.transform = `rotate(${ang - pi / 2}rad)`
    }

    const frame = useCallback(() => {
      if (!spinData.current || !isSpinning.current) {
        return
      }
      const ctx = wheelRef.current?.getContext('2d')
      if (!ctx) {
        return
      }

      const data = spinData.current

      if (data.angVel >= data.angVelMax) {
        isAccelerating.current = false
      }

      if (getIndex() !== winnerIndex) {
        onWinner.current = false
      }
      if (getIndex() === winnerIndex) {
        if (!onWinner.current) {
          data.rounds--
        }
        onWinner.current = true
        if (data.rounds < 1) {
          isAccelerating.current = false
          data.angVel *= 0.94
        }
      }

      // Accelerate
      if (isAccelerating.current) {
        data.angVel ||= data.angVelMin // Initial velocity kick
        data.angVel *= 1.06 // Accelerate
      } else {
        data.angVel *= data.friction // Decelerate by friction

        // SPIN END:
        if (data.angVel < data.angVelMin) {
          if (started.current) {
            setWon()
          }
          isSpinning.current = false
          data.angVel = 0
          data.rounds = 8
        }
      }

      data.ang += data.angVel // Update angle
      data.ang %= data.tau // Normalize angle

      rotate(ctx) // CSS rotate!
    }, [setWon, winnerIndex])

    useImperativeHandle(ref, () => ({
      startSpinning() {
        if (isSpinning.current) {
          return
        }
        started.current = true
        isSpinning.current = true
        isAccelerating.current = true
        spinData.current.angVelMax = 0.25
      },
    }))

    const renderPrizeSectors = useCallback(
      (ctx: CanvasRenderingContext2D) => {
        sectors.forEach((sector, i) => drawSector(sector, i, ctx))
      },
      [drawSector, sectors]
    )

    useEffect(() => {
      if (!wheelRef.current) {
        return
      }
      const ctx = wheelRef.current.getContext('2d')
      if (!ctx) {
        return
      }

      // Update spinData with the latest sectors
      spinData.current.tot = sectors.length
      spinData.current.arc = (2 * Math.PI) / sectors.length

      spinData.current.dia = ctx.canvas.width
      spinData.current.rad = ctx.canvas.width / 2

      renderPrizeSectors(ctx)
      rotate(ctx)

      const engine = () => {
        frame()
        animationFrameId.current = requestAnimationFrame(engine)
      }

      if (!animationFrameId.current) {
        engine() // Start engine!
      }

      return () => {
        if (animationFrameId.current) {
          cancelAnimationFrame(animationFrameId.current)
          animationFrameId.current = null
        }
      }
    }, [frame, renderPrizeSectors, sectors])

    return (
      <div className={styles.wheelOfFortune} id='wheelOfFortune'>
        <canvas
          ref={wheelRef}
          id='wheel'
          className={styles.wheel}
          width='300'
          height='300'
        ></canvas>
        <img src={wheelFrame} alt='Wheel frame' className={styles.wheelFrame} />
      </div>
    )
  }
)
