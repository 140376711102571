const getCentralIndexRoundToFloor = (length: number, index: number): boolean => {
  return Math.floor(length / 2) === index + 1
}

export const getScrollToCardId = (
  index: number,
  length: number,
  idValue: string,
  scrollToPos: 'middle' | 'end' = 'middle'
): string => {
  if (scrollToPos === 'end') {
    return length === index + 1 ? `${idValue}-item-last` : `${idValue}-card-${index + 1}`
  }
  return Math.floor(length / 2) === index + 1
    ? `${idValue}-item-middle`
    : `${idValue}-card-${index + 1}`
}

export const getCentralRowIdConditionally = (
  index: number,
  length: number,
  idValue: string
): { id?: string } => {
  return getCentralIndexRoundToFloor(length, index) ? { id: `${idValue}-item-middle` } : {}
}
