import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CorporateFormValues, useSignup } from '../../../../global/context/SignupContext'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { isNumber } from '../../../../utils/validations'
import { SignupWarningMessages } from '../../Components/SignupWarningMessages'
import { useCreateClient } from '../../hooks/useCreateClient'
import { CompanyDetailsStep1Page } from './CompanyDetailsStep1/CompanyDetailsStep1Page'
import { CompanyDetailsStep2Page } from './CompanyDetailsStep2/CompanyDetailsStep2Page'
import { CompanyDetailsStep3FormValues } from './CompanyDetailsStep3/CompanyDetailsStep3Form'
import { CompanyDetailsStep3Page } from './CompanyDetailsStep3/CompanyDetailsStep3Page'
import { CompanyDetailsStep4FormValues } from './CompanyDetailsStep4/CompanyDetailsStep4Form'
import { CompanyDetailsStep4Page } from './CompanyDetailsStep4/CompanyDetailsStep4Page'
import { CompanyDetailsTemplate } from './CompanyDetailsTemplate'

export const CompanyDetailsOrchestrator: React.FC = () => {
  const { step } = useParams<{ step: string }>()
  const [subStep, setSubStep] = useState(Number(step ?? 1) ?? 1)
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()
  const { denormalize, signInAndMoveToFinancialInfo } = useCreateClient()

  const { signupData, updateCorpValues } = useSignup()

  const totalSteps = signupData.totalSteps.companyDetails

  useEffect(() => {
    if (Object.keys(signupData.values).length < 2) {
      navigate('/sign-up/')
    }
    if (step && !isNumber(step)) {
      setSubStep(1)
    }
    if (step && Number(step) > totalSteps) {
      setSubStep(1)
    }
    if (step) {
      navigate('/sign-up/company-details/')
    }
  }, [navigate, signupData.values, step, totalSteps])

  const nextStep = (values?: Partial<CorporateFormValues>) => {
    updateCorpValues(values)
    setSubStep(subStep + 1)
  }

  const handleSubmit = async (values: Partial<CompanyDetailsStep3FormValues>) => {
    try {
      const corporateData = {
        ...signupData.corporateValues,
        ...values,
      } as CorporateFormValues
      updateCorpValues(corporateData)
      if (!signupData.lead) {
        return
      }
      await apiClient.createClient(denormalize(signupData.lead, signupData.values, corporateData))
      await signInAndMoveToFinancialInfo(signupData.values)
    } catch (e: unknown) {
      console.log(e)
    }
  }

  const getPage = () => {
    if (subStep === 1) {
      return <CompanyDetailsStep1Page onSubmit={nextStep} />
    }
    if (subStep === 2) {
      return <CompanyDetailsStep2Page onSubmit={nextStep} />
    }
    if (subStep === 3) {
      return <CompanyDetailsStep3Page onSubmit={handleSubmit} />
    }
    // if (subStep === 4) return <CompanyDetailsStep4Page onSubmit={handleSubmit} />
  }

  return (
    <CompanyDetailsTemplate
      lead={signupData.lead}
      step={subStep}
      goBack={subStep > 1 ? () => setSubStep(subStep - 1) : undefined}
      totalSteps={totalSteps}
    >
      <SignupWarningMessages leadTypeId={signupData.lead?.type?.id} />
      {getPage()}
    </CompanyDetailsTemplate>
  )
}
