import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosResponseHeaders } from 'axios'

import { Loading } from '../../../global/Loading/Loading'
import { useProductReadContext } from '../../../global/context/ProductContext'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { InformationModal } from '../../../global/modal/InformationModal'
import { Modal } from '../../../global/modal/Modal'
import { TestStatusEnum } from '../../../model/ClientTestAnswerDto'
import { ClientTestSubcategoryEnum } from '../../../model/ClientTestSubcategoryEnum'
import { CreateClientDocuments } from '../../../model/CreateClientDocuments'
import { CreateClientTest } from '../../../model/CreateClientTest'
import { DocumentPropertyType } from '../../../model/DocumentPropertyType'
import { UploadDocumentCategoryEnum } from '../../../model/UploadDocumentCategoryEnum'
import { UploadDocumentTypeEnum } from '../../../model/UploadDocumentTypeEnum'
import { Text } from '../../../ui/Typography/Typography'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'
import { DeclinedClassificationTest } from '../DeclinedClassificationTest'
import { PendingClassificationTest } from '../PendingClassificationTest'
import {
  ProfessionalClassificationTestForm,
  ProfessionalClassificationTestValues,
} from './ProfessionalClassificationTestForm'

interface Props {
  handleOkOnClick: () => void
  setAlreadyUploadedModal: (visible: boolean) => void
}

export const ProfessionalClassificationTest: React.FC<Props> = ({
  handleOkOnClick,
  setAlreadyUploadedModal,
}) => {
  const [tradeSizeModal, setTradeSizeModal] = useState(false)
  const [portfolioModal, setPortfolioModal] = useState(false)
  const [applicationModal, setApplicationModal] = useState(false)
  const { product } = useProductReadContext()

  const { t } = useTranslation()

  const handleTradeSizeModalOpen = () => setTradeSizeModal(true)
  const handlePortfolioModalOpen = () => setPortfolioModal(true)

  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const testSubCategoryId = ClientTestSubcategoryEnum.ClassificationProfessional

  const testAnswerCallback = useCallback(async () => {
    return await apiClient.getClientClassificationTestAnswers({
      subCategoryId: testSubCategoryId,
      languageId: locale,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: testAnswers, isLoading: isTestAnswersLoading } = useFetchOne(testAnswerCallback)

  const legalDocumentsCallback = useCallback(async () => {
    return await apiClient.getLegalDocuments(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: docs, isLoading: isDocumentLoading } = useFetchOne(legalDocumentsCallback)

  const testCallback = useCallback(async () => {
    return await apiClient.getClassificationTest({
      subCategoryId: testSubCategoryId,
      languageId: locale,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: testData, isLoading: isTestLoading } = useFetchOne(testCallback)

  const checkRetakeClassificationTestAvailability = useCallback(
    () => apiClient.isRetakeClassificationTestEnabled(testSubCategoryId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { data: isFormEnabled, isLoading: isTestAvailabilityLoading } = useFetchOne(
    checkRetakeClassificationTestAvailability
  )

  const { account } = useAccountReadContext()

  const preparePayload = (values: ProfessionalClassificationTestValues): CreateClientTest => {
    const testAnswers = {
      category: 'classification',
      clientId: account?.id || '',
      testId: testData?.id || '',
      dateTaken: new Date().toISOString(),
      selectedAnswers: values.selectedAnswers.map((a) => a.answer),
      freeAnswers: values.freeAnswers,
    }
    return testAnswers
  }

  const getTestId = (
    headers:
      | AxiosResponseHeaders
      | Partial<
          Record<string, string> & {
            'set-cookie'?: string[] | undefined
          }
        >
  ) => {
    const parts = headers.location?.split('/')
    if (parts) {
      return parts[parts.length - 1]
    }
    return ''
  }

  const prepareUploadPayload = (
    values: ProfessionalClassificationTestValues,
    testId: string
  ): CreateClientDocuments => {
    const docs: CreateClientDocuments = {
      documents: [],
    }

    values.significantTradeSize === '1' &&
      values.tradeSizeProofs.map((proof) =>
        docs.documents.push({
          categoryId: UploadDocumentCategoryEnum.Additional,
          typeId: UploadDocumentTypeEnum.Client_Classification,
          file: proof.base64Content,
          filename: proof.fileName,
          properties: {
            [DocumentPropertyType.ClientTestId]: testId,
          },
        })
      )

    values.significantPortfolio === '1' &&
      values.portfolioProofs.map((proof) =>
        docs.documents.push({
          categoryId: UploadDocumentCategoryEnum.Additional,
          typeId: UploadDocumentTypeEnum.Client_Classification,
          file: proof.base64Content,
          filename: proof.fileName,
          properties: {
            [DocumentPropertyType.ClientTestId]: testId,
          },
        })
      )

    return docs
  }

  const uploadDocs = async (values: ProfessionalClassificationTestValues, testId: string) => {
    const payload = prepareUploadPayload(values, testId)
    try {
      await apiClient.uploadDocuments(payload)
    } catch {
      setAlreadyUploadedModal(true)
    }
  }

  const submitForm = async (values: ProfessionalClassificationTestValues) => {
    try {
      const payload = preparePayload(values)
      if (payload) {
        const response = await apiClient.addClientClassificationTest(payload)
        const testId = getTestId(response.headers)
        await uploadDocs(values, testId)
        setApplicationModal(true)
      }
    } catch (error: unknown) {
      console.error(error)
    }
  }

  const handleSetApplicationModal = () => {
    setApplicationModal(false)
    handleOkOnClick()
  }

  if (testAnswers?.testStatus.id === TestStatusEnum.Pending) {
    return <PendingClassificationTest />
  } else if (testAnswers?.testStatus.id === TestStatusEnum.Declined && !testAnswers.allowRetake) {
    return <DeclinedClassificationTest handleOkOnClick={handleOkOnClick} />
  }

  return (
    <Loading
      isLoading={
        isTestLoading || isDocumentLoading || isTestAnswersLoading || isTestAvailabilityLoading
      }
    >
      {applicationModal && (
        <Modal
          closeModal={handleSetApplicationModal}
          render={() => (
            <InformationModal
              onCancel={handleSetApplicationModal}
              title={t('Profile.Application received')}
              onCancelText={t('Ok')}
            >
              <p>{t('Profile.We have received your application to change classification.')}</p>
            </InformationModal>
          )}
        />
      )}
      {tradeSizeModal && (
        <Modal
          closeModal={() => setTradeSizeModal(false)}
          render={() => (
            <InformationModal
              onCancel={() => setTradeSizeModal(false)}
              title={t('Profile.A significant sized trade')}
              onCancelText={t('Got It')}
            >
              <p>
                {t(
                  'Profile.A significant sized trade is equal to $/Euro/£ 40,000 notional (e.g. 0.4 of a lot of EURUSD)'
                )}
              </p>
            </InformationModal>
          )}
        />
      )}
      {portfolioModal && (
        <Modal
          closeModal={() => setPortfolioModal(false)}
          render={() => (
            <InformationModal
              onCancel={() => setPortfolioModal(false)}
              title={t('Profile.Financial Investment Portfolio')}
              onCancelText={t('Got It')}
            >
              <Text isParagraph className='mb-2'>
                {t('Profile.Examples of Investment Portfolios we can accept')}
              </Text>
              <Text isParagraph>
                {t('Profile.Examples of Investment Portfolios we can not accept when')}
              </Text>
            </InformationModal>
          )}
        />
      )}
      {testData && (
        <ProfessionalClassificationTestForm
          clientCategorizationPolicy={
            docs
              ?.find((doc) => doc.tickmillCompanyId === account?.tickmillCompany.id)
              ?.productCountryLegalDocuments.find(
                (countryDoc) => countryDoc.tickmillProductId === product
              )
              ?.legalDocuments.find((legalDoc) => legalDoc.code === 'client_categorisation_policy')
              ?.url ?? ''
          }
          handlePortfolioModalOpen={handlePortfolioModalOpen}
          handleTradeSizeModalOpen={handleTradeSizeModalOpen}
          testData={testData}
          handleFormSubmit={submitForm}
          isFormEnabled={!!isFormEnabled}
          testAnswers={testAnswers}
          classificationName={account?.classification.name}
        />
      )}
    </Loading>
  )
}

export default ProfessionalClassificationTest
