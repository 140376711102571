import React from 'react'
import classNames from 'classnames'

import {
  LegalDocumentDto,
  isLegalDocumentWidgetCheckbox,
  isLegalDocumentWidgetReadAndAgree,
} from '../../../../model/CompanyLegalDocumentDto'
import { DocumentCheckBoxField } from '../../Components/DocumentCheckBoxField'
import {
  DocumentFieldItem,
  DocumentReadAndAgreeField,
} from '../../Components/DocumentReadAndAgreeField'

interface DocumentFieldFactoryProps {
  documents: LegalDocumentDto[]
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

export const DocumentFieldFactory: React.FC<DocumentFieldFactoryProps> = (props) => {
  const { documents = [], renderItem } = props

  if (isLegalDocumentWidgetReadAndAgree(documents)) {
    return (
      <div
        className={classNames({
          ['mb-5']: !renderItem,
        })}
      >
        <DocumentReadAndAgreeField documents={documents} renderItem={renderItem} />
      </div>
    )
  }

  if (isLegalDocumentWidgetCheckbox(documents)) {
    return (
      <div
        className={classNames({
          ['mb-4']: !renderItem,
        })}
      >
        <DocumentCheckBoxField documents={documents} renderItem={renderItem} />
      </div>
    )
  }

  return null
}
