import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { RegulatorOptionDto } from '../model/RegulatorOptionDto'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { getTickmillCompanyByHostname } from '../utils/companyName.utils'
import { getLocationCountry } from '../utils/geolocation'
import { IFetchResult, useFetchOne } from '../utils/useFetch'

export const useFetchRegulators = (): IFetchResult<RegulatorOptionDto[]> => {
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const location = useLocation()
  const locationCountry = getLocationCountry(location)

  const callback = useCallback(async () => {
    if (locale) {
      return await apiClient.getRegulators(
        getTickmillCompanyByHostname(true),
        locale,
        locationCountry
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, locationCountry])

  const response = useFetchOne(callback)

  return { ...response, data: response.data || [] }
}
