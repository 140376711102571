import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'

import { useSignup } from '../../../global/context/SignupContext'
import { LeadDto } from '../../../model/LeadDto'
import { TickmillProductType } from '../../../model/TickmillProductType'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'
import { useLocallyPersistedState } from '../../../utils/useStorage'

interface UseProducts {
  setSelectedProductIds: Dispatch<SetStateAction<TickmillProductType[]>>
  selectedProductIds: TickmillProductType[]
}

export const useProducts = (): UseProducts => {
  const { signupData } = useSignup()
  const [selectedProductIds, setSelectedProductIds] = useLocallyPersistedState<
    TickmillProductType[]
  >('selectedProductIdsKey', [])

  useEffect(() => {
    if (signupData.lead) {
      setSelectedProductIds(signupData.lead.products.map((product) => product.id))
    }
  }, [signupData.lead, setSelectedProductIds])

  return { selectedProductIds, setSelectedProductIds }
}

export const useLeadProducts = (lead?: LeadDto): void => {
  const { setSelectedProductIds } = useProducts()
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => {
    if (!lead?.id || !lead?.token) {
      return
    }
    const products = await apiClient.getClientProductsByToken(lead?.id, lead?.token)

    if (products.length) {
      setSelectedProductIds(products.map((product) => product.id))
    }
  }, [apiClient, lead?.id, lead?.token, setSelectedProductIds])

  useFetchOne(callback)
}
