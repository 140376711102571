import { useCallback, useEffect, useState } from 'react'

import { useReferral } from '../../../../../Referral/Referral'
import { useSessionEntity } from '../../../../../global/context/EntityContext'
import { useSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { useSignup } from '../../../../../global/context/SignupContext'
import { NameDto } from '../../../../../model/NameDto'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { isTickmillSCType } from '../../../../../utils/companyName.utils'
import { useFetchOne } from '../../../../../utils/useFetch'
import { SignupWarningMessages } from '../../../Components/SignupWarningMessages'
import { useLeadProducts } from '../../../hooks/useProducts'
import { PersonalDetailsTemplate } from '../PersonalDetailsTemplate'
import {
  PersonalDetailsStep4Form,
  PersonalDetailsStep4FormValues,
} from './PersonalDetailsStep4Form'

interface PersonalDetailsStep4PageProps {
  step: number
  onSubmit(values: PersonalDetailsStep4FormValues): void
  totalSteps: number
  goBack(): void
}

export const PersonalDetailsStep4Page: React.FC<PersonalDetailsStep4PageProps> = (props) => {
  const { step, onSubmit, totalSteps, goBack } = props

  const { signupData } = useSignup()
  const [taxUnavailableReasons, setTaxUnavailableReasons] = useState<NameDto[]>([])
  const apiClient = useApiClient(ClientApiClient)
  useLeadProducts(signupData.lead)
  const locale = useSessionLanguage()
  const entity = useSessionEntity()
  const referral = useReferral()

  const getTaxUnavailableReasons = async () => {
    const resp = await apiClient.getTaxUnavailableReasons(locale)
    setTaxUnavailableReasons(resp)
  }

  useEffect(() => {
    getTaxUnavailableReasons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const nationalitiesCallback = useCallback(async () => {
    return apiClient.getNationalities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: nationalities = [] } = useFetchOne(nationalitiesCallback)

  if (!signupData.lead) {
    return null
  }
  const { referral_main_ib_code, aff_token } = referral.getReferralTokenValues(signupData.lead)

  return (
    <PersonalDetailsTemplate
      lead={signupData.lead}
      step={step}
      goBack={goBack}
      totalSteps={totalSteps}
      reversed
    >
      <SignupWarningMessages />

      <PersonalDetailsStep4Form
        lead={signupData.lead}
        entity={signupData.lead?.tickmillCompany?.id ?? entity}
        initialValues={
          {
            ...signupData.values,
            mainIbCode: referral_main_ib_code,
            affiliateToken: aff_token,
          } as PersonalDetailsStep4FormValues
        }
        nationalities={nationalities}
        country={signupData.lead.country}
        taxReasons={taxUnavailableReasons}
        onSubmit={onSubmit}
        requireTaxId={!isTickmillSCType(entity)}
      />
    </PersonalDetailsTemplate>
  )
}
