import React, { PropsWithChildren, createContext, useCallback, useContext, useMemo } from 'react'

import { CompanyLegalDocumentDto, LegalDocumentDto } from '../../model/CompanyLegalDocumentDto'
import { TickmillProductType } from '../../model/TickmillProductType'
import { useProducts } from '../../pages/Signup/hooks/useProducts'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../utils/companyName.utils'
import { useFetchOne } from '../../utils/useFetch'
import { useSessionEntity } from './EntityContext'
import { useProductReadContext } from './ProductContext'
import { useSessionLanguage } from './SessionSettingsContext'

enum LegalDocuments {
  websiteTermsAndConditions = 'terms_of_use_of_the_website',
  termsOfBusiness = 'terms_of_business',
  termsOfBusinessWebsite = 'terms_of_business_website',
  riskDisclosure = 'risk_disclosure',
  cookieConsent = 'cookie_consent_url',
  clientAgreement = 'client_agreement',
  privacyPolicy = 'privacy_policy',
  bestExecutionPolicy = 'best_execution_policy',
  cysec = 'cysec',
  financialConductAuthority = 'financial_conduct_authority',
  financialServicesRegister = 'financial_services_register',
  dynamicLeverageInfo = 'dynamic_leverage_info',
}

interface LegalLinksContextType {
  documents: CompanyLegalDocumentDto[]
  docLinks: LegalDocumentLink | null
  isLoading: boolean
}

type LegalDocumentLink = {
  [K in keyof typeof LegalDocuments]?: string
}

const LegalLinksContext = createContext<LegalLinksContextType>({
  documents: [],
  docLinks: {},
  isLoading: true,
})

export const LegalLinksProvider: React.FC<PropsWithChildren<{}>> = (props) => {
  const { children } = props

  const { documents = [], isLoading } = useDocumentsFetch()
  const { docLinks } = useDocumentLinks(documents)

  return (
    <LegalLinksContext.Provider value={{ documents, docLinks, isLoading }}>
      {children}
    </LegalLinksContext.Provider>
  )
}

const useDocumentsFetch = () => {
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  const fetchLegalDocuments = useCallback(async () => {
    return await apiClient.getLegalDocuments(locale)
  }, [locale])
  const { data: documents, isLoading } = useFetchOne(fetchLegalDocuments)

  return { documents, isLoading }
}

const useDocumentLinks = (documents: CompanyLegalDocumentDto[] = []) => {
  const tickmillCompanyId = useSessionEntity()
  const { productIds } = useProduct()

  const docFinder = useCallback(
    (code: LegalDocuments) => {
      const filteredDocuments = filterDocumentsByCountry(tickmillCompanyId, productIds)(documents)
      return (filteredDocuments || []).find((document) => document.code === code)?.url
    },
    [tickmillCompanyId, productIds, documents]
  )

  const docLinks = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(LegalDocuments).map((docKey) => [
          docKey,
          docFinder(LegalDocuments[docKey as keyof typeof LegalDocuments]),
        ])
      ) as LegalDocumentLink,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tickmillCompanyId, productIds, documents]
  )

  return { docLinks }
}

interface LegalLinksContextProps {
  countryId?: string
  tickmillCompanyId?: TickmillCompaniesEnum
}

interface LegalLinksContextTypeResponse {
  documents: LegalDocumentDto[]
  docLinks: LegalDocumentLink | null
  isLoading: boolean
}

export const useLegalLinksContext = (
  props?: LegalLinksContextProps
): LegalLinksContextTypeResponse => {
  const context = useContext(LegalLinksContext)
  if (!context) {
    throw new Error('useLegalLinksContext must be used within a LegalLinksProvider')
  }

  const { countryId, tickmillCompanyId } = props || {}

  const { selectedProductIds } = useProducts()

  const documents = useMemo(() => {
    return filterDocumentsByCountry(
      tickmillCompanyId,
      selectedProductIds,
      countryId
    )(context.documents)
  }, [tickmillCompanyId, selectedProductIds, countryId, context.documents])

  return { ...context, documents: documents || [] }
}

const useProduct = () => {
  const { isDefaultCFDProductType } = useProductReadContext()
  const isDefaultCFDProduct = isDefaultCFDProductType()

  const productIds = useMemo(
    () => [isDefaultCFDProduct ? TickmillProductType.CFD : TickmillProductType.ETD],
    [isDefaultCFDProduct]
  )

  return { productIds }
}

const filterDocumentsByCountry =
  (tickmillCompanyId?: number, tickmillProductIds: number[] = [], countryId?: string) =>
  (productLegalDocuments: CompanyLegalDocumentDto[]) => {
    if (tickmillCompanyId && tickmillProductIds?.length >= 1 && countryId) {
      const [productCountryLegalDocument] = productLegalDocuments.filter(
        (x) => x.tickmillCompanyId === tickmillCompanyId
      )
      const productCountryLegalDocuments =
        productCountryLegalDocument?.productCountryLegalDocuments || []

      const result: LegalDocumentDto[] = productCountryLegalDocuments
        .filter((x) => tickmillProductIds.includes(x.tickmillProductId))
        .reduce((previousValue: LegalDocumentDto[], currentValue) => {
          return previousValue.concat(currentValue.legalDocuments)
        }, [])
        .filter((x) => {
          if (!x.showForAllCountries && !x.requiredForAllCountries) {
            return !!x.availableCountries.find((x) => x?.country?.id === countryId)
          }

          if (!x.showForAllCountries && x.requiredForAllCountries) {
            return !!x.availableCountries.find((x) => x?.country?.id === countryId)
          }

          return x
        })

      return result
    }

    if (tickmillCompanyId && tickmillProductIds?.length >= 1) {
      const [productCountryLegalDocument] = productLegalDocuments.filter(
        (x) => x.tickmillCompanyId === tickmillCompanyId
      )
      const productCountryLegalDocuments =
        productCountryLegalDocument?.productCountryLegalDocuments || []

      const result: LegalDocumentDto[] = productCountryLegalDocuments
        .filter((x) => tickmillProductIds.includes(x.tickmillProductId))
        .reduce((previousValue: LegalDocumentDto[], currentValue) => {
          return previousValue.concat(currentValue.legalDocuments)
        }, [])

      return result
    }

    if (tickmillCompanyId) {
      const [productCountryLegalDocument] = productLegalDocuments.filter(
        (x) => x.tickmillCompanyId === tickmillCompanyId
      )
      const productCountryLegalDocuments =
        productCountryLegalDocument?.productCountryLegalDocuments || []

      const result: LegalDocumentDto[] = productCountryLegalDocuments
        .reduce((previousValue: LegalDocumentDto[], currentValue) => {
          return previousValue.concat(currentValue.legalDocuments)
        }, [])
        .filter((x) => x.showForAllCountries && x.requiredForAllCountries)
      return result
    }

    return []
  }
