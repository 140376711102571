import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CloseIcon } from '../../icons/CloseIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { TextSmall, TextStrong } from '../../ui/Typography/Typography'
import { Button } from '../button/Button'
import IconButton from '../iconButton/IconButton'
import { createFileData } from './FileUpload'
import { FileExtension } from './types'

import styles from './FileUpload.module.scss'

interface FileUploadSelectProps {
  file?: FileData
  title?: string
  acceptType?: FileExtension[]
  maxFileSize: number
  disabled?: boolean

  onUpload(file: FileData): void

  onRemovePlaceholder?(): void

  buttonText?: string
}

interface FileData {
  fileName: string
  fileType: string
  fileSize: number
  fileSizeBytes: number
  fileSizeKb: string
  fileSizeMb: string
  data: File
  buttonText?: string
}

export const FileUploadSelect: React.FC<FileUploadSelectProps> = (props) => {
  const {
    file,
    title,
    acceptType = [],
    maxFileSize,
    disabled,
    onUpload,
    onRemovePlaceholder,
    buttonText,
  } = props

  const { t } = useTranslation()

  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [selectedFile] = event.target.files || []
    onUpload({
      ...file,
      ...createFileData(selectedFile),
    })
  }

  const handleClick = () => {
    if (hiddenFileInput?.current?.click) {
      hiddenFileInput.current.click()
    }
  }

  const handleRemovePlaceholder = () => {
    onRemovePlaceholder?.()
  }

  return (
    <div>
      {title && <TextStrong className='mb-5'>{title}</TextStrong>}
      <div className={styles.content}>
        {onRemovePlaceholder && (
          <div className={styles.fileClose}>
            <IconButton onClick={handleRemovePlaceholder}>
              <CloseIcon color={'error'} />
            </IconButton>
          </div>
        )}
        <TextSmall className={styles.fileSelectButtonWrapper}>
          <Button
            appearance='secondary'
            size='M'
            type='button'
            onClick={handleClick}
            disabled={disabled}
            className={styles.fileSelectButton}
          >
            <TextStrong className={styles.buttonWrapper}>
              {buttonText || t('Select File')}
            </TextStrong>
          </Button>
          <span className={styles.label}>{t('or Drag & Drop')}</span>
        </TextSmall>
        <div className={styles.fileWarningInfo}>
          <p className='is-flex is-flex-direction-column is-align-items-center mt-5'>
            <WarningIcon color={'warning'} size={20} />
            <span className='mt-2'>{t('File name must include English characters only.')}</span>
          </p>
          {/* <p>Color images only! Max {maxFileSize}MB per file.</p> */}
          <p className='mt-2'>
            {t('Only color images accepted, up to', {
              maxFileSize,
            })}
          </p>
          <p className='mt-2'>
            <span className={styles.formats}>
              {t('Supported formats:')} {acceptType.map((x) => x.toUpperCase()).join(', ')}
            </span>
          </p>
        </div>
      </div>
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={handleUpload}
        disabled={disabled}
        className={styles.hiddenFileInput}
      />
    </div>
  )
}
