import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'

import { usePeriodic } from '../../../hooks/usePeriodic'
import {
  isMT4PlatformType,
  isMT5PlatformType,
  isTMTPlatformType,
} from '../../../model/PlatformTypeEnum'
import { TickmillProductType } from '../../../model/TickmillProductType'
import { TradingAccountInfo } from '../../../model/TradingAccountInfo'
import { useApiClient } from '../../../utils/ApiClient'
import { AuthSessionContext } from '../../../utils/AuthContext'
import { ClientApiClient } from '../../../utils/clientApi'
import { useProductReadContext } from '../ProductContext'
import {
  AccountInfoContextHook,
  AccountInfoContextValue,
  AccountInfoLimits,
  AccountInfoLimitsAction,
  AccountInfoLimitsActionKind,
  AccountInfoLimitsState,
  initialAccountInfoLimitsState,
} from './AccountInfoContext.types'

const AccountInfoLimitsContext = createContext<AccountInfoContextValue | undefined>(undefined)

export const AccountInfoLimitsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [data, dispatch] = useReducer(reducer, initialAccountInfoLimitsState)
  const apiClient = useApiClient(ClientApiClient)
  const { product } = useProductReadContext()
  const [auth] = useContext(AuthSessionContext)

  const processAccountInfo = useCallback(
    (accountInfo: TradingAccountInfo[], product?: TickmillProductType): AccountInfoLimits => ({
      mt4:
        accountInfo?.find((info) => isMT4PlatformType(info.platform.id))?.maxAccountsAllowed ?? 0,
      mt5:
        accountInfo?.find((info) => isMT5PlatformType(info.platform.id))?.maxAccountsAllowed ?? 0,
      tmt:
        accountInfo?.find((info) => isTMTPlatformType(info.platform.id))?.maxAccountsAllowed ?? 0,
      maxTotalLimit:
        accountInfo
          .filter((i) => i.product.id === product)
          ?.reduce((acc, info) => acc + info.maxAccountsAllowed, 0) ?? 0,
      hasReachedMaxTradingAccounts: !accountInfo
        .filter((x) => product === x.product.id || !product)
        .some((x) => x.allowToOpenAccount),
      isDemoTickmillTraderCreateAllowed: accountInfo?.some(
        (account) => isTMTPlatformType(account.platform.id) && account.allowToOpenDemoAccount
      ),
      isMTCreateAllowed: accountInfo?.some(
        (account) =>
          (isMT4PlatformType(account.platform.id) || isMT5PlatformType(account.platform.id)) &&
          account.allowToOpenAccount
      ),
      isTickmillTraderCreateAllowed: accountInfo?.some(
        (account) => isTMTPlatformType(account.platform.id) && account.allowToOpenAccount
      ),
    }),
    []
  )

  const reloadByProduct = useCallback(() => {
    if (!data.accountInfo || !data.accountInfo.length) {
      return
    }
    dispatch({
      type: AccountInfoLimitsActionKind.UPDATE_DATA_BY_PRODUCT,
      data: processAccountInfo(data.accountInfo, product),
      accountInfo: data.accountInfo,
    })
  }, [data.accountInfo, processAccountInfo, product])

  const refreshLimits = useCallback(async () => {
    try {
      if (!auth?.id) {
        return
      }
      dispatch({ type: AccountInfoLimitsActionKind.FETCH_DATA })
      const accountInfo = await apiClient.getTradingAccountsAccountInfo()
      dispatch({
        type: AccountInfoLimitsActionKind.UPDATE_DATA,
        data: processAccountInfo(accountInfo),
        accountInfo,
      })
      dispatch({ type: AccountInfoLimitsActionKind.RESET_TIMER })
    } catch (e: unknown) {
      dispatch({
        type: AccountInfoLimitsActionKind.FAILED,
        error: 'Failed to fetch account info',
      })
    }
  }, [apiClient, auth?.id, processAccountInfo])

  useEffect(() => {
    reloadByProduct()
  }, [reloadByProduct])

  useEffect(() => {
    refreshLimits()
  }, [refreshLimits])

  usePeriodic(refreshLimits, { minutes: 10 })

  return (
    <AccountInfoLimitsContext.Provider value={{ data, refreshLimits }}>
      {children}
    </AccountInfoLimitsContext.Provider>
  )
}

export const useAccountInfo = (): AccountInfoContextHook => {
  const context = useContext(AccountInfoLimitsContext)
  if (context === undefined) {
    throw new Error('useAccountInfo must be used within a AccountInfoLimitsContextProvider')
  }

  return {
    accountInfoLimits: context.data.accountInfoLimits,
    accountInfo: context.data.accountInfo,
    refreshLimits: context.refreshLimits,
  }
}

const reducer = (
  state: AccountInfoLimitsState,
  action: AccountInfoLimitsAction
): AccountInfoLimitsState => {
  switch (action.type) {
    case AccountInfoLimitsActionKind.FETCH_DATA:
      return { ...state, isLoading: true, error: null }
    case AccountInfoLimitsActionKind.UPDATE_DATA:
      return {
        ...state,
        isLoading: false,
        accountInfoLimits: action.data,
        accountInfo: action.accountInfo ?? [],
        error: null,
      }
    case AccountInfoLimitsActionKind.UPDATE_DATA_BY_PRODUCT:
      return { ...state, isLoading: false, accountInfoLimits: action.data, error: null }
    case AccountInfoLimitsActionKind.FAILED:
      return { ...state, isLoading: false, error: action.error }
    case AccountInfoLimitsActionKind.RESET_TIMER:
      return { ...state, lastUpdate: new Date() }
    default:
      return state
  }
}
