import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import * as XLSX from 'xlsx'

import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { useProductReadContext } from '../../global/context/ProductContext'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../../global/context/SessionSettingsContext'
import { SelectableModal } from '../../global/field/SelectableModal'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { ConfirmationModal } from '../../global/modal/ConfirmationModal'
import { ExportModal } from '../../global/modal/ExportModal'
import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
  useScrollToElementIds,
} from '../../hooks/useScrollToElementIds'
import { CloseIcon } from '../../icons/CloseIcon'
import {
  isPlatformTypeCFD,
  isPlatformTypeETD,
  isTickmillProductTypeETD,
} from '../../model/TickmillProductType'
import { TradingAccount } from '../../model/TradingAccount'
import { TransactionDto } from '../../model/TransactionDto'
import { WalletTypeEnum, isWalletETDType, isWalletFX_CFDType } from '../../model/WalletDto'
import { FirstTimeGuideCarousel } from '../../ui/FirstTimeGuideCarousel/FirstTimeGuideCarousel'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { Operator, PageQuery, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { useShowFirstTimeGuide } from '../../utils/FirstTimeGuideContext'
import { SharedContext } from '../../utils/SharedContext'
import { ClientApiClient } from '../../utils/clientApi'
import { AppENV, enableByEnv } from '../../utils/config'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import {
  getTransactionHistoryFiltersLength,
  prefixFilter,
  setQuery,
} from '../../utils/filter.utils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { getScrollToCardId } from '../../utils/getItemId'
import { filterObjectByKeys } from '../../utils/object-filter'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import {
  getClientTransactionFieldsByTransactionType,
  getTransactionType,
} from '../../utils/transaction.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage, useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { isOne } from '../../utils/validations'
import { TransactionFilterModal } from './TransactionFilterModal'
import { TransactionHistoryCard } from './TransactionHistoryCard'
import { TransactionHistoryTable } from './TransactionHistoryTable'
import { TransactionStatusHistoryModal } from './TransactionStatusHistoryModal'

import styles from './TransactionHistoryPage.module.scss'

interface SearchProps extends FilterQueryProps {
  ca_search_ClientAmountFrom?: string
  ca_search_ClientAmountTo?: string
}

export const TransactionHistoryPage: React.FC<{
  walletType?: WalletTypeEnum
  walletId?: string
  tradingAccountId?: string
}> = ({ walletType = WalletTypeEnum['FX/CFD'] }) => {
  useScrollToTop()
  const location = useLocation()
  const [historyModal, setHistoryModal] = useState<boolean>(false)
  const [optionsModal, setOptionsModal] = useState(false)
  const [searchTerm, showSearchTerm] = useState<SearchTermState>({
    show: false,
    searchTerm: undefined,
  })
  const [transactionHistoryInfoModal, setTransactionHistoryInfoModal] = useState(false)
  const [optionModal, setOptionModal] = useState<{
    step: number
    visible: boolean
    data?: TransactionDto
  }>({ step: 1, visible: false })
  const [filterModal, setFilterModalOpen] = useState<boolean>(false)
  const [sortModalOpen, setSortModalOpen] = useState<boolean>(false)
  const [sharedState, setSharedState] = useContext(SharedContext)
  const [tradingAccounts, setTradingAccounts] = useState<TradingAccount[]>([])
  const [filterstLoading, setFiltersLoading] = useState(false)

  const { t } = useTranslation()

  const { isDefaultCFDProductType, product } = useProductReadContext()
  const { formatNumber } = useFormatNumber()
  const { scrollIntoView } = useScrollToElementIds()

  const sortOptions = [
    {
      id: 'TransactionType.name',
      name: t('Transactions.Transaction Type'),
    },
    { id: 'CreatedDate', name: t('Created Date') },
    {
      id: 'TransactionState.name',
      name: t('Transactions.Transaction State'),
    },
  ]

  const [selectedTransaction, setSelectedTransaction] = useState('')
  const { walletId, tradingAccountId } = location.state || {}
  const apiClient = useApiClient(ClientApiClient)
  const [auth] = useContext(AuthSessionContext)
  const locale = useSessionLanguage()
  const isArabic = useArabicSessionLanguage()
  const isMobile = useWindowResize()
  const { account } = useAccountReadContext()
  const dateFormat = auth?.dateFormatType?.name
  const [tradingAccountsFetched, setTradingAccountsFetched] = useState(false)
  const productMappedTradingAccounts = useMemo(
    () =>
      tradingAccounts.filter((account) =>
        isTickmillProductTypeETD(product)
          ? isPlatformTypeETD(account.platformType.id)
          : isPlatformTypeCFD(account.platformType.id)
      ),
    [product, tradingAccounts]
  )

  const initialQuery = {
    TransactionStateId: '',
    TransactionTypeId: '',
    TaId: tradingAccountId || '',
    DateCreated: undefined,
    DateFrom: undefined,
    DateTo: undefined,
  }
  const [search, setSearch] = useState<SearchProps>(initialQuery)

  useEffect(() => {
    setSearch(initialQuery)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletId, tradingAccountId])

  const fetchTradingAccounts = async () => {
    try {
      if (tradingAccountsFetched) {
        return
      }
      setFiltersLoading(true)
      const tradingAccounts = await apiClient.getTradingAccounts()
      setTradingAccounts(tradingAccounts?.items)
    } finally {
      setTradingAccountsFetched(true)
      setFiltersLoading(false)
    }
  }

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      const { ca_search_ClientAmountFrom, ca_search_ClientAmountTo, ...clearedSearch } = search

      return await apiClient.getTransactions({
        ...query,
        search: { ...clearedSearch },
        caller: 'ca',
        ca_search_ClientWalletType: prefixFilter({
          prefix: account?.id,
          value: walletType.toString(),
        }),
        ca_search_ClientAmountFrom: prefixFilter({
          prefix: account?.id,
          value: ca_search_ClientAmountFrom,
        }),
        ca_search_ClientAmountTo: prefixFilter({
          prefix: account?.id,
          value: ca_search_ClientAmountTo,
        }),
        ca_search_TaId: tradingAccountId || '',
        ca_search_WalletId: walletId || search?.WalletId || '',
        languageId: locale,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, locale]
  )

  const callbackForFilters = useCallback(
    async () => {
      if (!account?.id || !walletType) {
        return
      }
      const clientWalletType = prefixFilter({
        prefix: account.id,
        value: walletType.toString(),
      })
      if (!clientWalletType) {
        return
      }
      return await apiClient.getTransactionHistoryFilterOptions(clientWalletType, locale)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )

  const { data: filterOptions } = useFetchOne(callbackForFilters)

  const { data, meta, setPageQuery, pageQuery, isLoading, hasInitialResults } =
    useFetchAppendablePage(callback)

  const onCancelTransaction = async (transaction: TransactionDto) => {
    await apiClient.cancelTransaction(transaction.id)
    setOptionModal({ data: undefined, visible: false, step: 1 })
    forceRefresh()
  }

  const onGenerateExcel = () => {
    const table = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(table)

    XLSX.utils.book_append_sheet(wb, ws, 'transactions_report')

    XLSX.writeFile(wb, 'transactions_report.xlsx')
  }

  const onGeneratePDF = () => {
    const data = tableBody()
    generatePDFTable({ data, title: t('Wallet.Transaction History') })
    setOptionsModal(false)
  }

  const tableBody = () => {
    const headerCsvData = [
      [
        t('Type'),
        t('TransactionHistory.Request Date'),
        t('From'),
        t('To'),
        t('Wallet.Reference number'),
        t('Status'),
        t('Amount'),
      ],
    ]
    return data.reduce((previousValue, currentValue) => {
      const transactionType = getTransactionType(currentValue.type.id)
      const fields = getClientTransactionFieldsByTransactionType(
        transactionType,
        currentValue,
        isDefaultCFDProductType(),
        isArabic,
        auth?.id
      )
      return previousValue.concat([
        [
          currentValue.type.name,
          formatDate(currentValue.createdDate, dateFormat),
          fields.from,
          fields.to,
          currentValue.referenceId,
          currentValue.state.name,
          formatNumber(fields.amount),
        ],
      ])
    }, headerCsvData)
  }

  useEffect(() => {
    if (location.state?.tradingAccountId || location.state?.walletId) {
      scrollIntoView([ScrollToIds.TradersRoomTransactionHistoryHeader])
    }
  }, [location])

  useEffect(() => {
    ;(async () => {
      let { wallets } = sharedState

      const walletsResponse = await apiClient.getWallets()
      wallets = walletsResponse
        .filter((x) =>
          isDefaultCFDProductType()
            ? isWalletFX_CFDType(x.walletType.id)
            : isWalletETDType(x.walletType.id)
        )
        .map((w) => ({
          id: w.id,
          name: w.name,
          currency: w.currency,
        }))

      setSharedState((currentState) => ({
        ...currentState,
        wallets,
      }))
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  useEffect(() => {
    ;(async () => {
      let { transactionStates, tradingAccounts } = sharedState
      const getTradingAccountsResponse = await apiClient.getTradingAccounts({
        search: {
          AccountGroupType: {
            value: 2,
            operator: Operator.EQUAL,
          },
          Platform: { value: 3, operator: Operator.NOT_EQUAL },
          Status: { value: 1, operator: Operator.EQUAL },
        },
      })

      tradingAccounts = getTradingAccountsResponse.items.map((w) => ({
        id: w.id,
        name: w.name,
      }))

      transactionStates = await apiClient.getTransactionStates(locale)

      setSharedState((currentState) => ({
        ...currentState,
        transactionStates,
        tradingAccounts,
      }))
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, product])

  const showFirstTimeGuid = useShowFirstTimeGuide()

  const { clearFilter } = useDateFilterWriteContext()

  const clearSearch = () => {
    setPageQuery?.(undefined)
    setSearch(initialQuery)
    showSearchTerm({ searchTerm: '', show: false })
    clearFilter()
  }

  useEffect(
    () => {
      const timer = setTimeout(
        () =>
          searchTerm.searchTerm !== undefined &&
          setPageQuery!({
            ...pageQuery,
            pageIndex: 1,
            ca_search_TextSearch: searchTerm.searchTerm,
          }),
        500
      )
      return () => clearTimeout(timer)
    },
    [searchTerm?.searchTerm] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.TradersRoomTransactionHistoryHeader,
    isPaginationEntrySelected,
    isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(ScrollToIds.TradersRoomTransactionHistoryHeader, isLoading, meta?.pageSize)

  const activeFilters = getTransactionHistoryFiltersLength({
    ...search,
    ca_search_ClientWalletType: undefined,
  })

  return (
    <>
      {showFirstTimeGuid && <FirstTimeGuideCarousel />}
      {transactionHistoryInfoModal && (
        <Modal
          closeModal={() => setTransactionHistoryInfoModal(false)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('TransactionHistory.Transaction History')}
              renderBody={() => (
                <section className='modal-card-body'>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'TransactionHistory.Includes transactions related to your funds, not to your trading activity'
                      ),
                    }}
                  />
                </section>
              )}
              renderFooter={() => (
                <button className='button' onClick={closeModal} type='button'>
                  <b>{t('Got It')}</b>
                </button>
              )}
              onConfirm={closeModal}
            />
          )}
        />
      )}
      {historyModal && (
        <Modal
          closeModal={() => setHistoryModal(false)}
          render={({ closeModal }) => (
            <TransactionStatusHistoryModal id={selectedTransaction} onCancel={closeModal} />
          )}
        />
      )}
      {optionsModal && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              onCloseModal={closeModal}
              csvData={tableBody()}
              onExportToCSV={closeModal}
              onExportToPdf={onGeneratePDF}
              onExportToExcel={onGenerateExcel}
            />
          )}
          closeModal={() => setOptionsModal(false)}
        />
      )}
      {optionModal.visible && (
        <Modal
          closeModal={() =>
            setOptionModal({
              visible: false,
              data: undefined,
              step: 1,
            })
          }
          render={({ closeModal }) => {
            if (isOne(optionModal.step)) {
              return (
                <SelectableModal
                  title={t('Transaction')}
                  onCancel={closeModal}
                  renderOptions={() => (
                    <button
                      onClick={() =>
                        setOptionModal({
                          ...optionModal,
                          step: 2,
                        })
                      }
                      className={classNames(styles.text, 'radio column is-full-desktop radio')}
                    >
                      <div className={styles.error}>
                        <CloseIcon color={'error'} /> {t('Transactions.Cancel Transaction')}
                      </div>
                    </button>
                  )}
                />
              )
            }
            return (
              <ConfirmationModal
                title={t('Transactions.Cancel Confirmation')}
                onCancel={closeModal}
                onConfirm={() => {}}
                renderFooter={() => (
                  <React.Fragment>
                    <button
                      className='button'
                      onClick={() =>
                        setOptionModal({
                          visible: false,
                          step: 1,
                          data: undefined,
                        })
                      }
                      type='button'
                    >
                      {t('Transactions.Back to Transaction History')}
                    </button>
                    <button
                      className='button'
                      onClick={() => onCancelTransaction(optionModal.data as TransactionDto)}
                      type='button'
                    >
                      {t('Transactions.Yes, Cancel Transaction')}
                    </button>
                  </React.Fragment>
                )}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('Transactions.Cancel Confirmation'),
                  }}
                />
              </ConfirmationModal>
            )
          }}
        />
      )}
      {filterModal && (
        <Modal
          cardClassName={styles.hasPositionTop}
          closeModal={() => setFilterModalOpen(false)}
          render={({ closeModal }) => (
            <TransactionFilterModal
              fetchTradingAccounts={fetchTradingAccounts}
              tradingAccounts={productMappedTradingAccounts}
              sharedState={sharedState}
              isLoading={filterstLoading}
              filterOptions={filterOptions}
              currentQuery={search}
              onConfirm={({ searchFilters = initialQuery, currentFilter }) => {
                let q: FilterQueryProps = {
                  ...searchFilters,
                  ...setQuery(currentFilter),
                }
                if (!currentFilter) {
                  q = filterObjectByKeys(q)
                }

                setSearch(q)

                setPageQuery?.({
                  ...pageQuery,
                  pageIndex: 1,
                })
                closeModal()
              }}
              onCancel={closeModal}
            />
          )}
        />
      )}
      {sortModalOpen && (
        <Modal
          closeModal={() => setSortModalOpen(false)}
          render={({ closeModal }) => (
            <SortByModal
              onCancel={closeModal}
              options={sortOptions}
              onConfirm={(option, sortBy) => {
                setPageQuery?.({
                  ...pageQuery,
                  sort: option,
                  sortOrder: sortBy,
                })
                closeModal()
              }}
            />
          )}
        />
      )}
      {hasInitialResults ? (
        <PageHeader
          id={ScrollToIds.TradersRoomTransactionHistoryHeader}
          title={t('TransactionHistory.Transaction History')}
          search={{
            show: searchTerm.show,
            placeholder: t('Search reference'),
            searchTerm: searchTerm.searchTerm,
            setShow: (v) => showSearchTerm({ ...searchTerm, show: v }),
            setSearchTerm: (v) => showSearchTerm({ ...searchTerm, searchTerm: v }),
          }}
          titleInfoToggle={
            enableByEnv([AppENV.development]) && !isDefaultCFDProductType()
              ? () => setTransactionHistoryInfoModal(true)
              : undefined
          }
          optionsToggle={() => setOptionsModal(true)}
          filterToggles={{
            openFilterModal: () => setFilterModalOpen(true),
            sortLabel: normalizeSortLabel(t, sortOptions, pageQuery?.sort),
            openSortModal: () => setSortModalOpen(true),
            resetFilters: clearSearch,
            activeFilters,
          }}
        />
      ) : (
        <PageHeader
          title={t('TransactionHistory.Transaction History')}
          id={ScrollToIds.TradersRoomTransactionHistoryHeader}
        />
      )}
      {isMobile ? (
        !data.length && !isLoading ? (
          <NoResults
            subtitle={activeFilters > 0 ? t('No results') : undefined}
            hideLink={activeFilters > 0}
            link={{ path: '/dashboard/traders-room/wallets', state: { wallets: true } }}
          />
        ) : (
          <Loading showLoadingIcon isLoading={isLoading}>
            {data.map((trans, index) => (
              <TransactionHistoryCard
                key={trans.id}
                cardId={getScrollToCardId(index, data.length, 'transaction-history')}
                data={trans}
                setSelectedTransaction={setSelectedTransaction}
                setHistoryModal={setHistoryModal}
                setOptionModal={setOptionModal}
                setPageQuery={setPageQuery}
                pageQuery={pageQuery}
                isLoading={isLoading}
              />
            ))}
          </Loading>
        )
      ) : (
        <TransactionHistoryTable
          data={data}
          isLoading={isLoading}
          pageQuery={pageQuery}
          setPageQuery={setPageQuery}
          activeFilters={activeFilters}
          setSelectedTransaction={setSelectedTransaction}
          setHistoryModal={setHistoryModal}
          setOptionModal={setOptionModal}
        />
      )}
      {meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.TradersRoomTransactionHistoryHeader}
          maxPageSize={500}
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsPaginationEntrySelected(true)
            }
            return setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </>
  )
}
