import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import * as XLSX from 'xlsx'

import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../hooks/useScrollToElementIds'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { ClientIbContestCampaignDto } from '../../model/ClientIbContestCampaignDto'
import { ClientIbContestCampaignResultDto } from '../../model/ClientIbContestCampaignResultDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { PageQuery, SortOrder, useApiClient } from '../../utils/ApiClient'
import { useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { ClientApiClient, emptyPaginationResponse } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { IAppendableFetchResult, useFetchAppendablePage } from '../../utils/useFetch'
import { IBContestResultsCards } from './IBContestResultsCard'
import { IBContestResultsTable } from './IBContestResultsTable'

type IBContestSort = 'Rank' | 'IntroducingBroker.Name' | 'ClientCount' | 'ContestPoints'

const initialQuery = {
  From: undefined,
  To: undefined,
}

const initialSearchTerm = {
  show: false,
  searchTerm: undefined,
}

interface CampaignFetchProps {
  campaign: ClientIbContestCampaignDto
  search: SearchResponseProps
}

const useIbCampaignResultsFetch = (props: CampaignFetchProps) => {
  const { campaign, search } = props

  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      if (account?.id) {
        return apiClient.getIbContestByIdCampaignResults(campaign.id, {
          ...query,
          ...search.pageQuery,
          search: search.search,
          caller: 'ca',
          languageId: locale,
        })
      }
      return Promise.resolve(emptyPaginationResponse)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search.search, search.pageQuery, locale]
  )
  const query = useFetchAppendablePage(callback)

  return { query, forceRefresh }
}

interface SearchResponseProps {
  useSearchTimer(callback: () => void): void
  clearSearch(callback: () => void): void
  search: FilterQueryProps
  setSearch(value: FilterQueryProps): void
  searchTerm: SearchTermState
  showSearchTerm(value: SearchTermState): void
  pageQuery: PageQuery
  setPageQuery(value: PageQuery): void
}

const useSearch = (props: SortResponseProps): SearchResponseProps => {
  const { sort, sortOrder } = props

  const location = useLocation()
  const { clearFilter } = useDateFilterWriteContext()

  const [search, setSearch] = useState<FilterQueryProps>(initialQuery)
  const [searchTerm, showSearchTerm] = useState<SearchTermState>(initialSearchTerm)
  const [pageQuery, setPageQuery] = useState<PageQuery>({ sort, sortOrder })

  useEffect(() => {
    setSearch(initialQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const useSearchTimer = (callback: () => void) => {
    useEffect(
      () => {
        const timer = setTimeout(() => {
          if (searchTerm.searchTerm !== undefined) {
            callback()
          }
        }, 500)
        return () => clearTimeout(timer)
      },
      [searchTerm?.searchTerm, pageQuery?.sortOrder, pageQuery?.sort] // eslint-disable-line react-hooks/exhaustive-deps
    )
  }

  const clearSearch = (callback: () => void) => {
    callback()
    setSearch(initialQuery)
    showSearchTerm({ searchTerm: '', show: false })
    clearFilter()
  }

  return {
    useSearchTimer,
    clearSearch,
    search,
    setSearch,
    searchTerm,
    showSearchTerm,
    pageQuery,
    setPageQuery,
  }
}

interface SortResponseProps {
  getSortName(): string | undefined
  closeSortModal(): void
  isSortModalOpen: boolean
  setSort(value: IBContestSort): void
  sort: IBContestSort
  sortOrder: SortOrder
  setSortOrder(value: SortOrder): void
  setSortModalOpen(value: boolean): void
  sortOptions: { id: string; name: string }[]
}

const useSort = (): SortResponseProps => {
  const { t } = useTranslation()

  const [sort, setSort] = useState<IBContestSort>('Rank')
  const [sortOrder, setSortOrder] = useState<SortOrder>('ASC')
  const [isSortModalOpen, setSortModalOpen] = useState(false)

  const sortOptions = [
    { id: 'Rank', name: t('IB.Ranking') },
    { id: 'IntroducingBroker.Name', name: t('IB.IB Username') },
    { id: 'ClientCount', name: t('IB.Number of Clients') },
    { id: 'ContestPoints', name: t('IB.Contest Points') },
  ]

  const getSortName = () => {
    return sortOptions.find((x) => x.id === sort)?.name
  }

  const closeSortModal = () => {
    setSortModalOpen(false)
  }

  return {
    getSortName,
    closeSortModal,
    isSortModalOpen,
    setSort,
    sort,
    sortOrder,
    setSortOrder,
    setSortModalOpen,
    sortOptions,
  }
}

interface GenerateDocumentFileProps {
  query: IAppendableFetchResult<ClientIbContestCampaignResultDto[]>
}

const useGenerateDocumentFile = (props: GenerateDocumentFileProps) => {
  const { query } = props

  const { t } = useTranslation()

  const exportFilename = 'ib_contest-campaign_results'

  const [isOptionsModalOpen, setOptionsModalOpen] = useState<boolean>(false)

  const handleGenerateExcel = () => {
    const table = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(table)

    XLSX.utils.book_append_sheet(wb, ws, exportFilename)
    XLSX.writeFile(wb, `${exportFilename}.xlsx`)

    setOptionsModalOpen(false)
  }

  const handleGeneratePDF = () => {
    const data = tableBody()
    generatePDFTable({ data, fileName: exportFilename })
    setOptionsModalOpen(false)
  }

  const tableBody = () => {
    const headerCsvData = [
      [t('IB.Ranking'), t('IB.IB Username'), t('IB.Number of Clients'), t('IB.Contest Points')],
    ]
    return query.data.reduce((previousValue, x) => {
      return previousValue.concat([
        [`#${x.ranking}`, `${x.ibCode}`, x.numberOfClients.toString(), x.points.toString()],
      ])
    }, headerCsvData)
  }

  return {
    handleGenerateExcel,
    handleGeneratePDF,
    tableBody,
    isOptionsModalOpen,
    setOptionsModalOpen,
    exportFilename,
  }
}

interface Props {
  campaign: ClientIbContestCampaignDto
  onGoBack(): void
}

export const IBContestResultsPage: React.FC<Props> = (props) => {
  const { campaign, onGoBack } = props

  const isMobile = useWindowResize()

  const sort = useSort()
  const search = useSearch(sort)
  const ibCampaignResults = useIbCampaignResultsFetch({
    search,
    campaign,
  })

  search.useSearchTimer(() => {
    ibCampaignResults.query.setPageQuery!({
      ...ibCampaignResults.query.pageQuery,
      ...search.pageQuery,
      pageIndex: 1,
      ca_search_IbCode: search.searchTerm.searchTerm,
    })
  })

  const handleSearch = () => {
    search.clearSearch(() => {
      ibCampaignResults.query.setPageQuery?.(undefined)
    })
  }

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.IBContestResultsHeader,
    isPaginationEntrySelected,
    ibCampaignResults.query.isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(
    ScrollToIds.IBContestResultsHeader,
    ibCampaignResults.query.isLoading,
    ibCampaignResults.query.meta?.pageSize
  )

  const handlePageChange = (
    pageIndex: number,
    pageSize: number,
    pagingEventType?: PagingEventType
  ) => {
    if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
      setIsPaginationEntrySelected(true)
    }
    ibCampaignResults.query.setPageQuery!({
      ...ibCampaignResults.query.pageQuery,
      ...search.pageQuery,
      pageIndex,
      pageSize,
    })
  }

  return (
    <>
      {isMobile && sort.isSortModalOpen && (
        <Modal
          closeModal={() => sort.setSortModalOpen(false)}
          render={({ closeModal }) => (
            <SortByModal
              onCancel={closeModal}
              options={sort.sortOptions}
              onConfirm={(option, sortBy) => {
                search.setPageQuery?.({
                  ...search.pageQuery,
                  sort: option,
                  sortOrder: sortBy,
                })
                closeModal()
              }}
            />
          )}
        />
      )}
      <Header
        {...search}
        campaign={campaign}
        query={ibCampaignResults.query}
        sort={sort}
        clearSearch={handleSearch}
        onGoBack={onGoBack}
      />
      <Loading showLoadingIcon isLoading={ibCampaignResults.query.isLoading}>
        {!isMobile && (
          <IBContestResultsTable
            data={ibCampaignResults.query.data}
            pageQuery={search.pageQuery}
            setPageQuery={search.setPageQuery}
          />
        )}
        {isMobile && (
          <IBContestResultsCards
            sortName={sort.getSortName()}
            setSortModalOpen={sort.setSortModalOpen}
            data={ibCampaignResults.query.data}
          />
        )}
      </Loading>
      {ibCampaignResults.query.meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.IBContestResultsHeader}
          pageData={ibCampaignResults.query.meta}
          isLoading={ibCampaignResults.query.isLoading}
          onPageChanged={handlePageChange}
        />
      )}
    </>
  )
}

interface HeaderProps {
  campaign: ClientIbContestCampaignDto
  query: IAppendableFetchResult<ClientIbContestCampaignResultDto[]>
  search?: FilterQueryProps
  pageQuery?: PageQuery
  searchTerm: SearchTermState
  sort: SortResponseProps
  setSearch(value: FilterQueryProps): void
  setPageQuery(pageQuery?: PageQuery): void
  showSearchTerm(value: SearchTermState): void
  clearSearch(): void
  onGoBack(): void
}

const Header: React.FC<HeaderProps> = (props) => {
  const { campaign, query, sort, searchTerm, showSearchTerm, onGoBack, pageQuery } = props

  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const isMobile = useWindowResize()
  const generateDocumentFile = useGenerateDocumentFile({ query })
  const rankingNumber = getRankingNumber(account, query.data)

  if (query.hasInitialResults) {
    return (
      <>
        {generateDocumentFile.isOptionsModalOpen && (
          <Modal
            render={({ closeModal }) => (
              <ExportModal
                fileName={generateDocumentFile.exportFilename}
                onCloseModal={closeModal}
                csvData={generateDocumentFile.tableBody()}
                onExportToCSV={closeModal}
                onExportToPdf={generateDocumentFile.handleGeneratePDF}
                onExportToExcel={generateDocumentFile.handleGenerateExcel}
              />
            )}
            closeModal={() => generateDocumentFile.setOptionsModalOpen(false)}
          />
        )}
        {!isMobile && (
          <PageHeader
            id={ScrollToIds.IBContestResultsHeader}
            title={campaign.name}
            darkTitle='Campaigns / '
            optionsToggle={() => generateDocumentFile.setOptionsModalOpen(true)}
            backButton={onGoBack}
            search={{
              show: searchTerm.show,
              placeholder: t('Search reference'),
              searchTerm: searchTerm.searchTerm,
              setShow: (v) => showSearchTerm({ ...searchTerm, show: v }),
              setSearchTerm: (v) => showSearchTerm({ ...searchTerm, searchTerm: v }),
            }}
            middleRender={
              <span className='is-flex is-justify-content-flex-end is-flex-grow-1 mr-4'>
                {rankingNumber && `${t('IB.Current Ranking')}: #${rankingNumber}`}
              </span>
            }
          />
        )}
        {isMobile && (
          <PageHeader
            id={ScrollToIds.IBContestResultsHeader}
            headTitle={rankingNumber && `${t('IB.Current Ranking')}: #${rankingNumber}`}
            title={campaign.name}
            optionsToggle={() => generateDocumentFile.setOptionsModalOpen(true)}
            backButton={onGoBack}
            search={{
              show: searchTerm.show,
              placeholder: t('Search reference'),
              searchTerm: searchTerm.searchTerm,
              setShow: (v) => showSearchTerm({ ...searchTerm, show: v }),
              setSearchTerm: (v) => showSearchTerm({ ...searchTerm, searchTerm: v }),
            }}
            filterToggles={{
              openSortModal: () => sort.setSortModalOpen(true),
              sortLabel: normalizeSortLabel(t, sort?.sortOptions, pageQuery?.sort),
            }}
          />
        )}
      </>
    )
  }

  return (
    <PageHeader
      id={ScrollToIds.IBContestResultsHeader}
      darkTitle='Campaigns / '
      title={campaign.name}
      backButton={onGoBack}
    />
  )
}

const getRankingNumber = (
  account?: AccountDetailedDto,
  data: ClientIbContestCampaignResultDto[] = []
) => {
  return data.find((x) => account?.clientIntroducingBroker?.name === x.ibCode)?.ranking
}
