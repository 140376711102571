export enum AccountGroupType {
  None = 0,
  Demo = 1,
  Live = 2,
  Archived = 3,
  Disabled = 5,
  Deleted = 6,
}

export enum AccountTypeEnum {
  Pro = 1,
  VIP = 5,
  Classic = 2,
}

export const isAccountGroupLive = (
  accountGroupTypeId: number
): accountGroupTypeId is AccountGroupType.Live => accountGroupTypeId === AccountGroupType.Live
