import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import IconButton from '../../global/iconButton/IconButton'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { useTraderUrlByPlatform } from '../../hooks/useTraderUrlByPlatform'
import { DotsIcon } from '../../icons/DotsIcon'
import { DummyIcon } from '../../icons/DummyIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import { PlatformType } from '../../model/PlatformTypeDto'
import { isMT5PlatformType, isTMTPlatformType } from '../../model/PlatformTypeEnum'
import { DemoTradingAccount, isTradingAccountStatusApproved } from '../../model/TradingAccount'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { TickmillTraderUsername } from './TradingAccountCard'

import styles from './TradingAccountCard.module.scss'

interface DemoTradingAccountCardProps {
  tradingAccount: DemoTradingAccount
  isActive?: boolean
  handleInfoModal: (title: string) => void
  setRestrictionModal(v: boolean): void
  platformTypes?: PlatformType[]
  handleSetSelectedTradingAccount?: (ta: DemoTradingAccount) => void
  onDisplayDynamicLeverageInfo?(visible: boolean): void
  cardId?: string
}

export const DemoTradingAccountCard: React.FC<DemoTradingAccountCardProps> = ({
  tradingAccount,
  handleInfoModal,
  isActive,
  handleSetSelectedTradingAccount,
  onDisplayDynamicLeverageInfo,
  cardId,
}) => {
  const [expanded, setExpanded] = useState(false)

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const { scrollIntoView } = useScrollToElementIds()
  const { formatMoney, formatNumber } = useFormatNumber()
  const tradeUrl = useTraderUrlByPlatform(tradingAccount)
  const isMT5 = isMT5PlatformType(tradingAccount.platformType.id)

  const handleDotsClick = () => {
    if (isTMTPlatformType(tradingAccount.platformType.id)) {
      handleSetSelectedTradingAccount?.(tradingAccount)
    } else {
      handleInfoModal(`${tradingAccount.currency.id}-${tradingAccount.name}`)
    }
  }

  return (
    <div className={styles.card} id={cardId}>
      <div className={styles.titleWrapper}>
        <DummyIcon />
        <TextSmall className='text-secondary'>
          {tradingAccount.currency.id}-{tradingAccount.name}
        </TextSmall>
        {isActive ? (
          <IconButton onClick={() => handleDotsClick()}>
            <DotsIcon />
          </IconButton>
        ) : (
          <DummyIcon />
        )}
      </div>
      <h2
        className={classNames(styles.balance, {
          [styles.inactive]: !isActive,
        })}
      >
        {formatMoney(tradingAccount.platformOverview.balance, tradingAccount.currency.id)}
      </h2>
      {expanded && (
        <Fragment>
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>{t('Trading Account.Platform')}</span>
            <span className={styles.value}>{tradingAccount.platformType.name}</span>
          </div>
          {isTMTPlatformType(tradingAccount?.platformType.id) && (
            <TickmillTraderUsername tradingAccount={tradingAccount} />
          )}
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>{t('Trading Account.Account Type')}</span>
            <span className={styles.value}>{tradingAccount.accountType.name}</span>
          </div>
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>{t('Trading Account.Leverage')}</span>
            <span className={styles.value}>
              <div className='is-flex'>
                {tradingAccount.leverage.name}
                {tradingAccount.platformType.dynamicLeverage && (
                  <IconButton
                    className={classNames({
                      'ml-2 mr-0': isArabic,
                      'ml-2': !isArabic,
                    })}
                    onClick={() => onDisplayDynamicLeverageInfo?.(true)}
                  >
                    <InfoIcon color='warning' size={14} />
                  </IconButton>
                )}
              </div>
            </span>
          </div>
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>{t('Trading Account.Balance')}</span>
            <span className={styles.value}>
              {formatMoney(tradingAccount.platformOverview.balance, tradingAccount.currency.id)}
            </span>
          </div>
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>{t('Trading Account.Equity')}</span>
            <span className={styles.value}>
              {formatNumber(tradingAccount.platformOverview.equity)}
            </span>
          </div>
        </Fragment>
      )}
      {isActive ? (
        <>
          <Link
            to={
              isTradingAccountStatusApproved(tradingAccount.status)
                ? `/dashboard/traders-room/trading-accounts/${tradingAccount.id}/demo/add-balance`
                : '#'
            }
          >
            <Button
              size='M'
              appearance='secondary'
              disabled={!isTradingAccountStatusApproved(tradingAccount.status)}
              className={classNames(styles.button, 'mb-4', {
                'mt-4': expanded,
              })}
            >
              <TextSmallStrong>
                {t('Trading Account.Add')} {t('Trading Account.Balance')}
              </TextSmallStrong>
            </Button>
          </Link>
          {!!tradeUrl && (
            <a className='is-link' target='_blank' href={tradeUrl} rel='noreferrer'>
              <Button size='M' appearance='primary' className={classNames(styles.button, 'mb-4')}>
                <TextSmallStrong>{t('Trade')}</TextSmallStrong>
              </Button>
            </a>
          )}
        </>
      ) : null}

      <div
        className={classNames(styles.textSmall, 'is-link has-text-centered')}
        onClick={() => {
          if (!expanded && cardId) {
            scrollIntoView([cardId])
          }
          setExpanded((prevState) => !prevState)
        }}
      >
        {!expanded ? t('Trading Account.See more') : t('Trading Account.See less')}
      </div>
    </div>
  )
}
